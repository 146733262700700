import React, { ForwardedRef, forwardRef, MutableRefObject, useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { className, tokens } from '@vp/swan';
import { Loader } from '../../../components/Loader';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';
import { useAdjustContrast, useAdjustContrastCanvas } from '../hooks';

const singleColorImagePreviewStyle = (isSmall: boolean) => css`
    display: grid;
    place-items: center;
    position: relative;
    margin-bottom: ${isSmall ? tokens.SwanSemSpace4 : tokens.SwanSemSpace5};

    .single-color-image-preview__image {
        max-width: 100%;
        object-fit: contain;
        grid-area: 1/ 1;

        max-height: ${isSmall ? '30vh' : 'calc(80vh - 420px)'};
        height: ${isSmall ? '30vh' : 'unset'};
        min-height: 100px;
    }
`;

export const SingleColorImagePreview = forwardRef((props, imageCanvasRef: ForwardedRef<HTMLCanvasElement>) => {
    const { activeImage, threshold, isInverted, previewImageUrl, previewImage, isImageLoaded, selectedColor } =
        useAdjustContrast();
    const { drawCanvasAndReplaceImage, drawThresholdResult, getPreviewBackground } = useAdjustContrastCanvas();
    const [previewImageHeight, setPreviewImageHeight] = useState(0);
    const [previewImageWidth, setPreviewImageWidth] = useState(0);
    const [background, setBackground] = useState({
        backgroundImage: '',
        backgroundSize: '',
        backgroundPosition: '',
    });
    const { isSmall } = useScreenLayout();

    // set dimension for showing substrate color as a background in adjust contrast modal
    const imageContainerRef = (node: HTMLDivElement) => {
        if (node != null) {
            setPreviewImageHeight(node.getBoundingClientRect().height);
            setPreviewImageWidth(node.getBoundingClientRect().width);
        }
    };

    // on mount draw canvas in contrast modal and update main canvas with single color image
    useEffect(() => {
        const canvas = (imageCanvasRef as MutableRefObject<HTMLCanvasElement>)?.current;
        drawCanvasAndReplaceImage(canvas);
    }, [activeImage, previewImage]);

    useEffect(() => {
        // trigger re-draws modal canvas on threshold, invert or color update
        if (isImageLoaded) {
            const canvas = (imageCanvasRef as MutableRefObject<HTMLCanvasElement>)?.current;
            drawThresholdResult(canvas);
        }
    }, [threshold, isInverted, selectedColor, isImageLoaded]);

    // adds product substrate like background on contrast modal canvas
    useEffect(() => {
        const background = getPreviewBackground(previewImageWidth, previewImageHeight);
        if (background) {
            setBackground(background);
        }
    }, [previewImageWidth, previewImageHeight]);

    return (
        <div
            className={className('single-color-image-preview', singleColorImagePreviewStyle(isSmall))}
            ref={imageContainerRef}
            style={background}
        >
            <canvas className="single-color-image-preview__image" ref={imageCanvasRef} />
            {!isImageLoaded ? (
                <div className="single-color-image-preview__loading-container">
                    <img className="single-color-image-preview__image" src={previewImageUrl} alt={'design preview'} />
                    <Loader accessibilityLabel={text('processImage')} />
                </div>
            ) : null}
        </div>
    );
});

SingleColorImagePreview.displayName = 'SingleColorImagePreview';
