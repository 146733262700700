import React, { ComponentProps, useState } from 'react';
import { i18nColorName, isValidHex, isValidRGB } from '@design-stack-vista/i18n-color-name';
import {
    ColorSwatch,
    ColorSwatches,
    FlexBox,
    SelectedValue,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
} from '@vp/swan';
import classNames from 'classnames';
import { ShowMoreButton } from './ShowMoreButton';
import { hash } from './utils/hash';

export interface BaseColorSwatchPickerProps {
    className?: string;
    collapse?: boolean;
    colorSwatchesProps?: Partial<ComponentProps<typeof ColorSwatches>>;
    entityId?: string;
    maxColorSwatches: number;
    locale: string;
    defaultColorLabel: string;
}

export type ColorWithStock = {
    primaryColor: string;
    secondaryColor?: string;
    title?: string;
    disabled?: boolean;
};

export interface ColorSwatchPickerProps extends BaseColorSwatchPickerProps {
    colorSwatches: Record<string, ColorWithStock>;
    selectedColor: string;
    onColorSwatchChange: (selectedValue: SelectedValue) => void;
}

function getRegionIdBasedOnColors(colorSwatches: Record<string, ColorWithStock>): string {
    return hash(Object.values(colorSwatches).join(''));
}

export const ColorSwatchPicker = (props: ColorSwatchPickerProps): JSX.Element => {
    const {
        className,
        collapse = false,
        colorSwatches,
        colorSwatchesProps = {},
        entityId = null,
        maxColorSwatches,
        onColorSwatchChange,
        selectedColor,
        defaultColorLabel,
        locale,
    } = props;

    // If props.entityId is not provided, hash the available color strings.
    const colorRegionId = `colors-${entityId || getRegionIdBasedOnColors(colorSwatches)}`;
    const colorSwatchesEntries = Object.entries(colorSwatches);
    const entriesCount = colorSwatchesEntries.length;

    const shouldShowMore = collapse && colorSwatchesEntries.length > maxColorSwatches;

    const [showMore, setShowMore] = useState(!collapse);

    const visibleSwatches =
        showMore || !shouldShowMore ? colorSwatchesEntries : colorSwatchesEntries.slice(0, maxColorSwatches - 1);

    const toggleShowMore = (): void => {
        const toggledShowMore = !showMore;

        setShowMore(toggledShowMore);
    };

    return (
        <>
            {entriesCount <= 1 && null}
            {entriesCount > 1 && (
                <FlexBox
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap="nowrap"
                    className={classNames('color-swatch-picker', className)}
                >
                    {shouldShowMore && (
                        <ShowMoreButton
                            active={showMore}
                            aria-controls={colorRegionId}
                            aria-expanded={showMore}
                            onClick={toggleShowMore}
                        >
                            {`+ ${entriesCount - maxColorSwatches + 1}`}
                        </ShowMoreButton>
                    )}
                    <SelectionSet
                        selectedValue={selectedColor}
                        variant="single-select"
                        onSelectedValueChange={onColorSwatchChange}
                    >
                        <ColorSwatches id={colorRegionId} {...colorSwatchesProps}>
                            {visibleSwatches.map(([colorKey, color]) => {
                                const derivedColorName =
                                    isValidHex(color.primaryColor) || isValidRGB(color.primaryColor)
                                        ? i18nColorName(color.primaryColor, {
                                              culture: locale,
                                              colorPalette: 'gallery',
                                          })
                                        : defaultColorLabel;
                                const colorName = colorSwatches[colorKey].title;

                                return (
                                    <SelectionSetInput
                                        aria-label={colorName || derivedColorName}
                                        disabled={color.disabled}
                                        key={colorKey}
                                        value={colorKey}
                                    >
                                        <SelectionSetLabel>
                                            <ColorSwatch {...color} title={colorName || derivedColorName} />
                                        </SelectionSetLabel>
                                    </SelectionSetInput>
                                );
                            })}
                        </ColorSwatches>
                    </SelectionSet>
                </FlexBox>
            )}
        </>
    );
};
