import React from 'react';
import { css } from '@emotion/css';
import { Button, className, FlexBox, tokens, Typography } from '@vp/swan';
import { useScreenLayout } from '../../hooks/useScreenLayout';

interface Props {
    Icon: React.ReactNode | React.ReactNode[];
    buttonLabel: string;
    disabled?: boolean;
    onClick?: () => void;
    dataTestId?: string;
}

const getQuickToolbuttonStyle = (isSmall: boolean) => css`
    &.swan-button,
    &.swan-button:disabled {
        // This may not be required after upgrading to swan 3.VL
        border-width: ${tokens.SwanSemBorderWidthNone};
        overflow: initial;
        min-width: auto;

        &.swan-button-with-icon-left {
            .swan-icon {
                margin: 0;
            }
        }

        svg {
            width: ${isSmall ? '20px' : '24px'};
            height: ${isSmall ? '20px' : '24px'};
        }
    }
`;

const textStyle = css`
    max-width: 95px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const QuickToolItem = ({ disabled, dataTestId, Icon, buttonLabel, onClick }: Props) => {
    const { isSmall } = useScreenLayout();

    return (
        <Button
            iconPosition="left"
            skin="tertiary"
            onClick={onClick}
            disabled={disabled}
            data-testid={dataTestId}
            px={{ xs: '4', sm: '5' }}
            py={{ xs: 0, sm: '3' }}
            className={className(getQuickToolbuttonStyle(isSmall))}
        >
            <FlexBox
                flexDirection={isSmall ? 'column' : 'row'}
                gap={isSmall ? '2' : '3'}
                alignItems="center"
                flexWrap="nowrap"
            >
                {Icon}
                <Typography
                    fontWeight={isSmall ? 'normal' : 'bold'}
                    fontSize={isSmall ? 'xsmall' : 'standard'}
                    className={className({ [textStyle]: isSmall })}
                >
                    {buttonLabel}
                </Typography>
            </FlexBox>
        </Button>
    );
};

QuickToolItem.displayName = 'QuickToolItem';
