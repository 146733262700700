import { createDocument } from '@design-stack-ct/documents-sdk';
import { CimDoc } from '@design-stack-vista/cdif-types';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useProductConfiguration } from './calcifer';
import { ProductData } from './calcifer/useProductConfiguration';
import { useGetDesignDocument } from './useGetDesignDocument';
import { useDebugFlag } from '../features/Debug/useDebugFlag';
import { useWaitForInstantUpload } from '../features/InstantUpload';
import { isVCSConsumer } from '../features/VCS/utils/isVCSConsumer';
import { useProductParams, useUpsellPrice } from '../providers';
import { useFullScreenLoader } from '../providers/FullScreenLoaderProvider';
import { useSaveWork } from '../providers/SaveWorkProvider';
import { WorkEntityResponse } from '../types/workEntityTypes';
import { LOCAL_STORAGE_KEY, localStorageUtility } from '../utils';
import { addDesignDialogMetadata } from '../utils/addDesignDialogMetadata';
import { getCareAgentQueryParams } from '../utils/envUtils';
import { getPlaceholder } from '../utils/getPlaceholder';
import { SurfaceUpsellMetadata } from '../utils/upsell';
import { createOrUpdateWorkEntity } from '../utils/workEntityClient/createOrUpdateWorkEntity';

interface SaveWorkParams {
    cimDoc: CimDoc;
    authorizationHeader: string;
    productData: ProductData;
    locale: string;
    workId?: string;
    disableMailingServiceModal?: boolean;
}

const saveWork = async ({
    cimDoc,
    productData,
    authorizationHeader,
    locale,
    workId,
    disableMailingServiceModal,
}: SaveWorkParams): Promise<WorkEntityResponse | undefined> => {
    const udsResponse = await createDocument({
        cimDoc,
        headers: {
            Authorization: authorizationHeader,
        },
    });
    const response = await createOrUpdateWorkEntity({
        productKey: productData.productKey,
        productVersion: productData.productVersion,
        customerSelectedOptions: productData.customerSelectedOptions,
        selectedOptions: productData.selectedOptions,
        quantity: productData.quantity,
        mpvId: productData.mpvId,
        locale: locale,
        editInStudioUrl: new URL(globalThis.location.href),
        udsResponse,
        authorizationHeader: authorizationHeader,
        workId,
        workName: productData.productName,
        disableMailingServiceModal,
    });

    return response;
};

export const useWorkEntityService = () => {
    const { locale, returnPath } = useProductParams();
    const designEngine = useDesignEngine();
    const { isFlagEnabled } = useDebugFlag();
    const prodConfig = useProductConfiguration();
    const { isUpsellPanelAdded } = useUpsellPrice();
    const { identity, auth: vpAuth } = useIdentityContext();
    const { setFullScreenLoaderData } = useFullScreenLoader();
    const { waitForInstantUpload } = useWaitForInstantUpload();
    const { setLastWorkEntity, setIsWorkSaved } = useSaveWork();
    const { getDocumentWithoutPlaceholders } = useGetDesignDocument();

    const isSaveAndMyProjectEnabled = isFlagEnabled('quad_save_and_my_project');
    const authorizationHeader = vpAuth.getAuthorizationHeader();

    const saveCurrentProgress = async () => {
        if (!prodConfig.productData || !authorizationHeader) {
            return;
        }
        if (prodConfig.productData) {
            await waitForInstantUpload();
            const surfaceUpsells: SurfaceUpsellMetadata[] = [];

            designEngine.cimDocStore.panels.forEach((panel) => {
                if (isUpsellPanelAdded(panel.id)) {
                    surfaceUpsells.push({
                        upsellOffered: true,
                        panelName: panel.panelProperties.name,
                    });
                }
            });

            const designDocument = getDocumentWithoutPlaceholders();
            const metadataWithDesignDialog = addDesignDialogMetadata(
                designDocument.metadata,
                designDocument.document.panels
            );

            const placeholders = await getPlaceholder({ panels: designEngine.cimDocStore.panels, locale });
            const documentMetadata = {
                surfaceUpsells,
                placeholders,
                ...metadataWithDesignDialog,
            };
            const { getQuadFlags } = localStorageUtility;
            const documentWithUpsellMetadata = { ...designDocument, metadata: documentMetadata };
            const disableMailingModalFlag = Boolean(getQuadFlags(LOCAL_STORAGE_KEY.NOT_SHOW_MAILING_MODAL_CHECKED));
            const workEntity = await saveWork({
                cimDoc: documentWithUpsellMetadata,
                productData: prodConfig.productData,
                authorizationHeader,
                locale,
                workId: prodConfig.workId,
                disableMailingServiceModal: identity.isSignedIn ? disableMailingModalFlag : undefined,
            });
            if (workEntity) {
                if (!prodConfig.workId) {
                    /**
                     * Before moving to pdc/next page from QUAD
                     * replacing the product query params with workId
                     *
                     * So when we return back with browser back action our
                     * latest design get's loaded
                     */

                    /**
                     * NO TO: Not adding startingCanvas here, as this is a place where we move
                     * QUAD to pdc pages.
                     */

                    const historyState = workEntity.workId;
                    let queryParams = `?workId=${workEntity.workId}${getCareAgentQueryParams()}`;
                    if (isVCSConsumer()) {
                        queryParams += `&returnPath=${returnPath}`;
                    }
                    window.history.replaceState({ historyState }, '', queryParams);
                }
                prodConfig.setWorkId(workEntity.workId);

                if (!identity.isSignedIn && isSaveAndMyProjectEnabled) {
                    setFullScreenLoaderData({ showLoader: false });
                    await vpAuth.signIn();
                }
                setIsWorkSaved(true);
                setLastWorkEntity(workEntity);
            }

            return workEntity;
        }
    };

    return { saveCurrentProgress };
};
