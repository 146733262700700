import { useCallback } from 'react';
import { getImageAsPromise, hex2rgb } from '@design-stack-ct/utility-core';
import { CimDoc } from '@design-stack-vista/cdif-types';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useFileMethods } from '@design-stack-vista/upload-components';
import { useAdjustContrast } from './useAdjustContrast';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useFullScreenLoader } from '../../../providers';
import { noticeError } from '../../../utils';
import { text } from '../../../utils/localization';
import { ColorPaletteEntry } from '../../quad/types/colorPalette';
import { replaceSingleColorImage, updateSingleColorImageColor } from '../commands';
import { adjustContrastStore } from '../store';
import {
    createVector,
    drawSingleColor,
    getPreviewBackgroundStyle,
    needsVectorization,
    searchForSimilarPaletteColor,
} from '../utils';

// This hook returns the util functions to draw canvas and update single color image in cimdoc.
export function useAdjustContrastCanvas() {
    const {
        activeImage,
        threshold,
        isInverted,
        setPreviewImageUrl,
        previewImage,
        setPreviewImage,
        setIsImageLoaded,
        getImageMetadata,
        setSelectedColor,
        getImageOverlayData,
        selectedColor,
        availablePaletteColors,
    } = useAdjustContrast();

    const {
        executeCommand,
        cimDocStore: { metadata },
        idaStore: { activeDesignPanelId },
    } = useDesignEngine();
    const { uploadFile } = useFileMethods();
    const { productData } = useProductConfiguration();
    const { setFullScreenLoaderData } = useFullScreenLoader();
    const { setShowAdjustContrastModal } = adjustContrastStore;

    const drawCanvasAndReplaceImage = (canvas: HTMLCanvasElement) => {
        if (activeImage) {
            if (previewImage) {
                setIsImageLoaded(true);
            } else {
                const {
                    model: { previewUrl, originalSourceUrl },
                } = activeImage;

                const imageSrc = previewUrl || originalSourceUrl;

                if (imageSrc) {
                    setPreviewImageUrl(imageSrc);
                    setFullScreenLoaderData({ showLoader: true, loaderMessage: text('processImage') });
                    clientsideThreshold(canvas, imageSrc);
                }
            }
        }
    };

    // create vector image only for first time, to be shown on main canvas.
    const clientsideThreshold = (canvas: HTMLCanvasElement, url: string) => {
        getImageAsPromise(url)
            .then((image: HTMLImageElement) => {
                if (image) {
                    setPreviewImage(image);
                }

                let matchingPaletteColor: ColorPaletteEntry = selectedColor;
                if (canvas && activeImage) {
                    // find the similar palette color as default on new image add
                    const { imageData, similarPaletteColor } = searchForSimilarPaletteColor(
                        image,
                        canvas,
                        availablePaletteColors
                    );
                    if (similarPaletteColor) {
                        matchingPaletteColor = similarPaletteColor;
                        setSelectedColor(matchingPaletteColor);
                    }
                    //just draw the canvas of contrast modal
                    const color = hex2rgb(matchingPaletteColor.cssBackground);
                    drawSingleColor(canvas, color, threshold, isInverted, image, imageData);
                    setIsImageLoaded(true);

                    replaceImageOnMainCanvas(canvas, matchingPaletteColor);
                }
            })
            .catch((error) => {
                noticeError(error, {
                    method: 'clientsideThreshold',
                    requestUrl: url,
                });
            });
    };

    const replaceImageOnMainCanvas = (canvas: HTMLCanvasElement, matchingPaletteColor?: ColorPaletteEntry) => {
        // updates single color image to main/product canvas
        const color = matchingPaletteColor || selectedColor;
        if (canvas && activeImage) {
            createVector(canvas, threshold, async (svgPath) => {
                const blob = new Blob([svgPath], { type: 'image/svg+xml' });
                const file = new File([blob], 'singlecolorimage.svg', { type: 'image/svg+xml' });
                const asset = await uploadFile(file, { hidden: true });
                const mimeType = getImageMetadata('mimeType') as string;
                if (asset) {
                    const previewUrl = asset.webPreview.getUrl({ includeSignature: true });
                    const printUrl = asset.print.getUrl({ includeSignature: true });
                    executeCommand((cimDoc: CimDoc) => {
                        replaceSingleColorImage(
                            cimDoc,
                            {
                                id: activeImage.id,
                                imageAttributes: {
                                    overlays: [
                                        {
                                            previewUrl,
                                            printUrl,
                                            color: color.cdifValue,
                                        },
                                    ],
                                    mimeType,
                                },
                            },
                            {
                                threshold,
                                inverted: isInverted,
                            }
                        );
                    }, {});
                }
            });
        }
    };

    // re-draws canvas in adjust contrast modal on user action
    const drawThresholdResult = useCallback(
        (canvas: HTMLCanvasElement) => {
            if (!threshold) {
                return;
            }

            if (canvas && previewImage) {
                const color = hex2rgb(selectedColor.cssBackground);
                drawSingleColor(canvas, color, threshold, isInverted, previewImage);
            }
        },
        [threshold, previewImage, isInverted, selectedColor]
    );

    const getPreviewBackground = (previewImageWidth: number, previewImageHeight: number) => {
        const scenesConfiguration = productData?.scenesConfiguration;

        if (scenesConfiguration) {
            const [underlayScene] = scenesConfiguration.underlay;
            if (underlayScene) {
                const background = getPreviewBackgroundStyle(underlayScene, previewImageWidth, previewImageHeight);
                return background;
            }
        }
        return null;
    };

    const isAnyAttributeChanged = () => {
        const initialThreshold = getImageMetadata('threshold');
        const initialInvertFlag = getImageMetadata('inverted');
        const initialColor = getImageOverlayData('color');
        return Boolean(
            (initialThreshold && initialThreshold !== threshold) ||
                (initialInvertFlag != undefined && initialInvertFlag !== isInverted) ||
                (initialColor && initialColor !== selectedColor.cdifValue)
        );
    };

    const applyColor = () => {
        if (activeDesignPanelId && activeImage) {
            executeCommand((cimDoc) => {
                updateSingleColorImageColor(cimDoc, {
                    panelId: activeDesignPanelId,
                    id: activeImage.id,
                    color: selectedColor.cdifValue,
                });
            }, {});
        }
    };

    const applyChangesToCanvas = (canvas: HTMLCanvasElement | null) => {
        if (isAnyAttributeChanged()) {
            setFullScreenLoaderData({ showLoader: true, loaderMessage: text('processImage') });
            // need to create new image if there is any update in initial threshold or inverted value.
            const needVectorization = needsVectorization(metadata, activeImage, threshold, isInverted);
            if (activeImage && canvas && needVectorization) {
                replaceImageOnMainCanvas(canvas);
            }
            applyColor();
        }
    };

    const onApplyContrastToCanvas = (canvas: HTMLCanvasElement | null) => {
        applyChangesToCanvas(canvas);
        setShowAdjustContrastModal(false);
        setIsImageLoaded(false);
    };

    return {
        clientsideThreshold,
        drawCanvasAndReplaceImage,
        drawThresholdResult,
        replaceImageOnMainCanvas,
        getPreviewBackground,
        isAnyAttributeChanged,
        onApplyContrastToCanvas,
    };
}
