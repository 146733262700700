import { useCallback, useMemo } from 'react';
import { usePreviewSelectors } from '@design-stack-vista/preview-components';
import { usePreview } from '../../providers/PreviewProvider';

export const use2DPreviewSwitchers = () => {
    const { previewPropGetters, selectedPreviewLabel, setSelectedPreviewLabel } = usePreviewSelectors();
    const { setActivePreview, setSelectedPreviewKey } = usePreview();

    const switcherConfig: Record<string, string> = useMemo(
        () =>
            previewPropGetters.reduce((acc, getter) => {
                const { key } = getter;
                const label = getter().children;

                return {
                    ...acc,
                    [key]: label as string,
                };
            }, {}),
        [previewPropGetters]
    );

    const selectedValue = useMemo(() => {
        return Object.entries(switcherConfig).find(([, value]) => value === selectedPreviewLabel)?.[0];
    }, [switcherConfig, selectedPreviewLabel]);

    const handleSelection = useCallback(
        (newSelectedValue: string | undefined | null) => {
            if (newSelectedValue && switcherConfig[newSelectedValue]) {
                setSelectedPreviewLabel(switcherConfig[newSelectedValue]);
                setActivePreview(switcherConfig[newSelectedValue]);
                setSelectedPreviewKey(newSelectedValue);
            }
        },
        [switcherConfig]
    );

    return {
        switcherConfig,
        selectedValue,
        handleSelection,
    };
};
