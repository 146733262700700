import React, { createContext, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';

interface MaskLegendProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

export type MaskLegendContext = {
    showBleedLegend: boolean;
    showSafeAreaLegend: boolean;
    handleBleedLegend: (show: boolean) => void;
    handleSafeAreaLegend: (show: boolean) => void;
    closeAllLegend: () => void;
};

export const maskLegendContext = createContext<MaskLegendContext | null>(null);

export const MaskLegendProvider = observer((props: MaskLegendProviderProps) => {
    const { children } = props;
    const [showBleedLegend, setShowBleedLegend] = useState(false);
    const [showSafeAreaLegend, setShowSafeAreaLegend] = useState(false);

    const closeAllLegend = () => {
        setShowBleedLegend(false);
        setShowSafeAreaLegend(false);
    };

    const handleBleedLegend = (show: boolean) => {
        if (show) {
            setShowBleedLegend(true);
            setShowSafeAreaLegend(false);
        } else {
            closeAllLegend();
        }
    };

    const handleSafeAreaLegend = (show: boolean) => {
        if (show) {
            setShowBleedLegend(false);
            setShowSafeAreaLegend(true);
        } else {
            closeAllLegend();
        }
    };

    return (
        <maskLegendContext.Provider
            value={{
                showBleedLegend,
                showSafeAreaLegend,
                handleBleedLegend,
                handleSafeAreaLegend,
                closeAllLegend,
            }}
        >
            {children}
        </maskLegendContext.Provider>
    );
});

export const useMaskLegend = () => {
    const value = useContext(maskLegendContext);
    if (!value) {
        throw new Error('useMaskLegend must be used within a MaskLegendProvider');
    }
    return value;
};
