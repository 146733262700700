import { useCallback } from 'react';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useShapeUtils } from './useShapeUtils';
import { ProductAttributes } from '../../../config/constant';
import { ProductData } from '../../../hooks/calcifer/useProductConfiguration';
import { usePricingData } from '../../../hooks/usePricingData';
import { StudioSecondaryConfigResponse } from '../../../types/secondaryConfigTypes';
import { ProductChoiceGroups } from '../../Flexibility/common';
import { ShapeKey } from '../constants';
import { shapeStore } from '../ShapeStore';
import { getShapeChoiceGroup } from '../utils';

export interface ShapeOptionsAndPricesProps {
    productData: ProductData | undefined;
    secondaryConfig: StudioSecondaryConfigResponse | undefined;
    userSelectedSize: string;
}

export const useShapePrice = () => {
    const { hasShapes } = useShapeUtils();
    const { selectedShape, shapeOptionsPrices, setShapeOptions, setShapeOptionsPrices } = shapeStore;
    const { getAllCompatibleOptionsPriceData } = usePricingData();
    const { auth: vpAuth } = useIdentityContext();
    const authHeader = vpAuth.getAuthorizationHeader();

    const updateShapeOptionsAndPrices = useCallback(
        async ({ productData, secondaryConfig, userSelectedSize }: ShapeOptionsAndPricesProps) => {
            if (productData && secondaryConfig && userSelectedSize && hasShapes) {
                const productAttributes = secondaryConfig.productAttributeAndValues;
                const productShapeChoiceGroups: ProductChoiceGroups = await getShapeChoiceGroup(
                    productData,
                    productAttributes,
                    userSelectedSize,
                    authHeader
                );
                setShapeOptions(productShapeChoiceGroups);
                const userSelectedOptions = {
                    ...productData.selectedOptions,
                    [ProductAttributes.Size]: userSelectedSize,
                };
                const shapeOptionsPrices = await getAllCompatibleOptionsPriceData(
                    productShapeChoiceGroups,
                    userSelectedOptions
                );

                if (shapeOptionsPrices) {
                    setShapeOptionsPrices(shapeOptionsPrices);
                }
            }
        },
        [hasShapes, getAllCompatibleOptionsPriceData]
    );

    const getShapePriceChange = useCallback(
        (shape: ShapeKey) => {
            if (selectedShape !== shape && shapeOptionsPrices[shape]?.differentialDiscountPrice) {
                return shapeOptionsPrices[shape].differentialDiscountPrice;
            }
        },
        [selectedShape, shapeOptionsPrices]
    );

    return { updateShapeOptionsAndPrices, getShapePriceChange };
};
