import { useState } from 'react';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { getAllCartItems, getItemInCart } from '../api/cart';
import { fetchWorkEntity } from '../api/workEntity';
import { DEFAULT_STARTING_CANVAS, REQUESTOR } from '../config/constant';
import { getLocale as getVcsLocale } from '../features/VCS/utils/getLocale';
import { isVCSConsumer } from '../features/VCS/utils/isVCSConsumer';
import { currentLocale } from '../utils/localeUtils';
import QueryStringUtils from '../utils/queryStringUtils';

export function useQueryProductParams() {
    const [paramInitialized, setParamInitialized] = useState(false);

    const [locale, setLocale] = useState('');
    const [productKey, setProductKey] = useState('');
    const [productVersion, setProductVersion] = useState(0);
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [documentUrl, setDocumentUrl] = useState('');
    const [customerSelectedOptions, setCustomerSelectedOptions] = useState({});

    const workId = QueryStringUtils.getValueByKey('workId') ?? '';
    const { auth: vpAuth, identity } = useIdentityContext();

    const startingCanvas = QueryStringUtils.getValueByKey('startingCanvas') ?? DEFAULT_STARTING_CANVAS;

    const { isSignedIn, shopperId, anonymousUserId } = identity;

    const [isItemInCart, setIsItemInCart] = useState<boolean>(false);
    const [cartQuantity, setCartQuantity] = useState(0);

    /**
     * @param returnPath: defines the Path to land back on VCS ProShop
     * after completing the design
     */
    const returnPath = QueryStringUtils.getValueByKey('returnPath') ?? '';
    /**
     * @param mpvUrl : resolve the `locale` via MPV URL
     * @returns `locale`
     *
     * For VCS, mpvUrl is not consumed, thus we rely on
     * `query param` OR `proshop locale`
     * @returns locale using `query param` OR `ProShop locale`
     */
    const getLocaleLang = (mpvUrl?: string) => {
        if (isVCSConsumer()) {
            return getVcsLocale();
        }
        return (
            (mpvUrl &&
                mpvUrl
                    .toLowerCase()
                    .split('/')
                    .find((section) => /^[a-z]{2}-[a-z]{2}$/.test(section))) ||
            ''
        );
    };

    const initializeProductFromQuery = () => {
        setProductKey(QueryStringUtils.getValueByKey('key') ?? '');
        setLocale(QueryStringUtils.getValueByKey('locale') ?? currentLocale);
        const productVersionString = QueryStringUtils.getValueByKey('productVersion');
        setProductVersion(productVersionString ? parseInt(productVersionString, 10) : 0);
        const quantityString = QueryStringUtils.getValueByKey('qty');
        setSelectedQuantity(quantityString ? parseInt(quantityString, 10) : 1);
        setDocumentUrl(QueryStringUtils.getValueByKey('documentUrl') ?? '');
        const encodedSelectedProductOptions = QueryStringUtils.getValueByKey('selectedOptions');
        setCustomerSelectedOptions(
            encodedSelectedProductOptions ? JSON.parse(decodeURIComponent(encodedSelectedProductOptions)) : {}
        );

        setParamInitialized(true);
    };

    const checkItemInCartItems = async (authorizationHeader: string, localeLang: string) => {
        const allCartItems = await getAllCartItems(authorizationHeader, shopperId || anonymousUserId, localeLang);
        if (allCartItems) {
            const itemInCart = getItemInCart(allCartItems, workId);
            setIsItemInCart(!!itemInCart);
            if (itemInCart) {
                setCartQuantity(itemInCart.quantity);
            }
        } else {
            setIsItemInCart(false);
        }

        setParamInitialized(true);
    };

    const fetchValueFromWorkEntity = async (authorizationHeader: string, workId: string, signal?: AbortSignal) => {
        const res = await fetchWorkEntity(authorizationHeader, REQUESTOR, workId, signal);
        if (res) {
            setProductKey(res.product.key);
            setProductVersion(res.product.version ?? 0);
            setSelectedQuantity(res.merchandising.quantity);
            const localeLang = getLocaleLang(res.merchandising.mpvUrl);
            setLocale(localeLang);
            setDocumentUrl(res.design.designUrl);
            setCustomerSelectedOptions(res.merchandising.merchandisingSelections);

            // if VCS, then skip using Vista Cart
            if (isVCSConsumer()) {
                setParamInitialized(true);
                return;
            }
            checkItemInCartItems(authorizationHeader, localeLang);
        }
    };

    useAsyncEffect(
        (helpers) => {
            if (paramInitialized) {
                return;
            }

            if (!workId) {
                initializeProductFromQuery();
            }

            if (vpAuth && workId) {
                const authorizationHeader = vpAuth.getAuthorizationHeader();
                fetchValueFromWorkEntity(authorizationHeader, workId, helpers.abortSignal);
            }
        },
        [vpAuth, paramInitialized, isSignedIn]
    );

    return {
        paramInitialized,
        locale,
        productKey,
        productVersion,
        selectedQuantity,
        customerSelectedOptions,
        documentUrl,
        workId,
        startingCanvas,
        setCustomerSelectedOptions,
        isItemInCart,
        cartQuantity,
        returnPath,
    };
}
