import { CimDoc } from '@design-stack-ct/cdif-types';
import { executeCommand, PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { getPreviewUrl } from './getPreviewUrl';
import { noticeError } from '../../../utils';
import { setColorToPanel } from '../commands/setColorToPanel';
import { TemplateThumbDimension } from '../constant';

export const getSingleColorTemplatePreviews = ({
    colors,
    panel,
    cimDoc,
}: {
    colors: string[];
    panel: PanelState;
    cimDoc: CimDoc;
}) => {
    try {
        const singleColorTemplates = colors.map((color) => {
            const newCimDoc = executeCommand(
                cimDoc,
                (cimDoc) => {
                    setColorToPanel({ cimDoc, panel, color });
                },
                {}
            ).cimDoc;
            const previewUrl = getPreviewUrl({
                panel,
                cimDoc: newCimDoc,
                width: TemplateThumbDimension.imageWidth,
                page: 2,
            });
            return { color: color, preview: previewUrl };
        });
        return singleColorTemplates;
    } catch (error) {
        noticeError(error, {
            method: 'getSingleColorTemplatePreviews',
        });
    }
};
