import React from 'react';
import { Icon } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { QuickToolItem } from './QuickToolItem';
import { useActivePanel } from '../../hooks/useActivePanel';
import { text } from '../../utils/localization';
import { useQuickToolProps } from '../quad/recipes/useQuickToolProps';

export const QuickToolDelete = observer((): JSX.Element | null => {
    const panel = useActivePanel();
    const { handleDelete } = useQuickToolProps();

    if (!panel) {
        return null;
    }

    return (
        <QuickToolItem
            Icon={<Icon iconType="delete" />}
            buttonLabel={text('deleteButton')}
            onClick={handleDelete}
            dataTestId="delete"
        />
    );
});
