import { useDesignEngine } from '@design-stack-vista/core-features';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { PanelSides } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { hasSingleImageItem, hasSingleShapeItem } from '../../../utils';
import { ACTION_NAME, GA, PageSection } from '../../TrackingEvents/constant';
import { GA_EVENTS } from '../../TrackingEvents/GAEvents';
import { getPanelByName } from '../../UploadPanel/components/UploadBackDesignModal/helper';

export const useBackDesignEvents = () => {
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const { productData } = useProductConfiguration();
    const { fireEvent } = useCustomEventPropagation();
    const backPanel = getPanelByName(panels, PanelSides.Back);
    const isSingleImage = backPanel && hasSingleImageItem(backPanel);
    const isSingleShape = backPanel && hasSingleShapeItem(backPanel);
    const isMultipleItems = backPanel && backPanel.items.length > 1;
    const { QUAD_BACK_DESIGN_FLY_OUT } = GA_EVENTS;
    const { PAGE_SECTION, PAGE_STAGE, PAGE_NAME, PAGE_DEPT } = GA;

    const fireAddTemplateEvent = ({ layoutId, completionPercent }: { layoutId: string; completionPercent: number }) => {
        fireEvent({
            name: ACTION_NAME.FLY_OUT_CLICKED,
            extraData: {
                label: QUAD_BACK_DESIGN_FLY_OUT,
                eventDetail: `smartbackside:select:template:layoutId_${layoutId}:completionPercent_${completionPercent}`,
                pageSection: PAGE_SECTION,
                pageStage: PAGE_STAGE,
                pageName: `${PAGE_NAME.replace('[Product ID]', productData?.mpvId || '')}`,
                pageDept: PAGE_DEPT,
                category: PageSection.FlyOut,
            },
        });
    };

    const fireAddColorEvent = () => {
        fireEvent({
            name: ACTION_NAME.FLY_OUT_CLICKED,
            extraData: {
                label: QUAD_BACK_DESIGN_FLY_OUT,
                eventDetail: 'smartbackside:select:solidcolor',
                pageSection: PAGE_SECTION,
                pageStage: PAGE_STAGE,
                pageName: `${PAGE_NAME.replace('[Product ID]', productData?.mpvId || '')}`,
                pageDept: PAGE_DEPT,
                category: PageSection.FlyOut,
            },
        });
    };

    const fireDeleteBacksideEvent = () => {
        let item = '';

        if (isSingleImage) {
            item = 'image';
        } else if (isSingleShape) {
            item = 'solidcolor';
        } else if (isMultipleItems) {
            item = 'template';
        }

        fireEvent({
            name: ACTION_NAME.FLY_OUT_CLICKED,
            extraData: {
                label: QUAD_BACK_DESIGN_FLY_OUT,
                eventDetail: `smartbackside:delete:${item}`,
                pageSection: PAGE_SECTION,
                pageStage: PAGE_STAGE,
                pageName: `${PAGE_NAME.replace('[Product ID]', productData?.mpvId || '')}`,
                pageDept: PAGE_DEPT,
                category: PageSection.FlyOut,
            },
        });
    };

    return { fireAddTemplateEvent, fireAddColorEvent, fireDeleteBacksideEvent };
};
