import { FlagType } from './FlagsDebugTool';
import { localStorageUtility } from '../../utils';
import { windowExists } from '../../utils/envUtils';
import { getQueryParams } from '../Flexibility/common/utils/browserUtils';

export const DEBUG_FLAG_PREFIX = 'DEBUG_FLAG_';

export function useDebugFlag() {
    const { getQuadFlags, addQuadFlags, removeQuadFlag } = localStorageUtility;
    function isFlagEnabled(flagName: FlagType) {
        if (!windowExists()) {
            return false;
        }
        const urlParams = getQueryParams();

        const paramValue = urlParams[flagName];

        const localStorageFlagKey = DEBUG_FLAG_PREFIX + flagName;

        if ((paramValue === 'false' || paramValue === '0') && getQuadFlags(localStorageFlagKey)) {
            removeQuadFlag(localStorageFlagKey);
        }

        if (paramValue === 'true' || paramValue === '1') {
            addQuadFlags(localStorageFlagKey, 'true');
        }
        return getQuadFlags(localStorageFlagKey) === 'true';
    }

    return { isFlagEnabled };
}
