import { REQUESTOR, STUDIO_PREVIEW_PURPOSE } from '../config/constant';
import { fetchWrapper } from '../utils';

const previewWidth = 800;

interface PurcsParams {
    purpose: string;
    locale: string;
    previewInstructionsUrl: string;
    optionSelections: Record<string, string>;
    productVersion: number;
    productKey: string;
    width: number;
}

export function getPurcsPreviewUrl({
    productKey,
    purpose,
    width,
    optionSelections,
    previewInstructionsUrl,
    productVersion,
    locale,
}: PurcsParams) {
    const url = `https://lookup.previews.cimpress.io/api/v1/vistaprint-prod/${encodeURIComponent(
        locale
    )}/product/${encodeURIComponent(productKey)}/allViews?purpose=${encodeURIComponent(
        purpose
    )}&width=${encodeURIComponent(width)}&previewInstructionsUri=${encodeURIComponent(
        previewInstructionsUrl
    )}&optionSelections=${encodeURIComponent(JSON.stringify(optionSelections))}&productVersion=${encodeURIComponent(
        productVersion
    )}&requestor=${REQUESTOR}`;
    return url;
}

export interface PurcsUrlParams {
    previewInstructionsUrl: string;
    locale: string;
    productKey: string;
    productVersion: number;
    optionSelections: Record<string, string>;
    width?: number;
    signal?: AbortSignal;
}

export const getPurcsUrl = async ({
    previewInstructionsUrl,
    locale,
    productKey,
    productVersion,
    optionSelections,
    width,
    signal,
}: PurcsUrlParams): Promise<string[]> => {
    const purcsUrl = getPurcsPreviewUrl({
        productKey,
        purpose: STUDIO_PREVIEW_PURPOSE,
        width: width ?? previewWidth,
        previewInstructionsUrl,
        optionSelections,
        productVersion,
        locale,
    });

    const response = await fetchWrapper(purcsUrl, {
        method: 'GET',
        requestInitiator: 'useGenerateFlexibilityPreviewUrl',
        signal,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const previewUrls = response ? response.views.map((view: any) => view._links.image.href) : [];
    return previewUrls;
};
