import type { FontConfiguration } from '@design-stack-vista/core-features';

export const FONT_REPOSITORY_URL =
    'https://fonts.documents.cimpress.io/v1/repositories/0a3b1fba-8fa6-43e1-8c3e-a018a5eb8150/published';

export const defaultFontConfiguration: FontConfiguration = {
    fontRepositoryUrl: 'https://fonts.documents.cimpress.io/prod',
    fontFamilies: [
        'Alegreya Sans',
        'Alfa Slab One',
        'Amatic SC',
        'Archivo Narrow',
        'Bach Condensed',
        'BenchNine',
        'Bree Serif',
        'Cherry Swash',
        'Cookie',
        'Courgette',
        'Crete Round',
        'Didact Gothic',
        'Doppio One',
        'EB Garamond',
        'Gabriela',
        'Great Vibes',
        'Handlee',
        'Life Savers',
        'Marmelad',
        'Neuton',
        'News Cycle',
        'Noto Sans',
        'Offside',
        'Oleo Script',
        'Patua One',
        'Rancho',
        'Satisfy',
        'Sergel Old Style',
        'Slabo 27px',
        'Text Me One',
        'Yesteryear',
    ],
    defaultFontFamily: 'Alegreya Sans',
};
