import React, { useCallback } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { UPLOAD_BACK_DESIGN_MODAL_TYPES, useUploadBackDesignModal } from './components/UploadBackDesignModal';
import { UploadButton } from './UploadButton';
import { PanelSides } from '../../config/constant';
import { useActivePanel } from '../../hooks/useActivePanel';
import { useUploadModal } from '../../providers';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../TrackingEvents/constant';

type UploadDesignProps = {
    onPointerDown?: (event: PointerEvent) => void;
};

export const UploadDesign = ({ onPointerDown }: UploadDesignProps) => {
    const panel = useActivePanel();
    const { fireEvent } = useCustomEventPropagation();
    const { setShowUploadModal } = useUploadModal();
    const { showUploadBackDesignModal } = useUploadBackDesignModal();
    const openUploadModal = useCallback(() => {
        if (panel) {
            const eventName = QUAD_TRACKING_EVENTS.CLICK_UPLOAD_CTA.replace(
                '${PANEL_NAME}',
                panel.panelProperties.name
            );
            fireEvent({
                name: ACTION_NAME.DESIGN_TOOL_USED,
                extraData: {
                    label: eventName,
                    pageSection: PageSection.QuickUploadAndDesign,
                    pageStage: PageStage.Design,
                    experimentDetail: {
                        productSurfaceId: panel.id,
                        side: panel.panelProperties.name,
                    },
                },
            });
        }
        setShowUploadModal(true);
    }, [panel, fireEvent]);

    const isBackPanel = panel?.panelProperties.name.toLocaleLowerCase() === PanelSides.Back;

    const onUploadButtonClick = () =>
        isBackPanel ? showUploadBackDesignModal(UPLOAD_BACK_DESIGN_MODAL_TYPES.UPLOAD_ASSET) : openUploadModal();

    return <UploadButton onPointerDown={onPointerDown} openUploadModal={onUploadButtonClick} />;
};
