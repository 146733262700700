import { Coordinates } from '@design-stack-vista/ida-framework';
import { text } from '../../../utils/localization';

export enum ValidationTypes {
    LowResolution = 'lowResolution',
    BetweenBound = 'betweenBound',
}

export const ValidationMessage = {
    LowResolution: text('lowResolutionWarning'),
    betweenBound: text('betweenBoundWarning'),
};

export type ValidationInfo = {
    level: 'ERROR' | 'WARNING' | 'INFO';
    message: string;
    iconPlacement: Coordinates;
};

export interface Validation extends ValidationInfo {
    panelId: string;
    itemId: string;
    validationType: ValidationTypes;
}
