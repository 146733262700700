import { CimDoc } from '@design-stack-vista/cdif-types';
import { fetchWrapper } from '../utils/fetchWrapper';

/**
 *
 * @description This function fetches the cimdoc with the provided document-URL
 *
 * @param documentUrl
 * @returns Promise<CIMDOC>
 */
export const fetchDocument = async (documentUrl: string) => {
    const response = await fetchWrapper(documentUrl, {
        method: 'GET',
        requestInitiator: 'fetchDocument',
    });

    return response as CimDoc | undefined;
};
