import React from 'react';
import { Icon } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { QuickToolItem } from './QuickToolItem';
import { useActivePanel } from '../../hooks/useActivePanel';
import { text } from '../../utils/localization';
import { useQuickToolProps } from '../quad/recipes/useQuickToolProps';

export const QuickToolRotate = observer((): JSX.Element | null => {
    const panel = useActivePanel();
    const { disabled, iconColor, handleRotate } = useQuickToolProps();

    if (!panel) {
        return null;
    }

    return (
        <QuickToolItem
            Icon={<Icon iconType="rotate" color={iconColor} />}
            buttonLabel={text('rotateItem')}
            onClick={handleRotate}
            disabled={disabled}
            dataTestId="rotate-item"
        />
    );
});
