import { REQUESTOR } from '../config/constant';
import { fetchWrapper } from '../utils';

export const getBookendHeaderData = async (locale: string, signal?: AbortSignal) => {
    const response = await fetchWrapper(
        `https://bookends.cdn.vpsvc.com/v3/vistaprint/${locale}/header/slim?hideVat=undefined&hideSearch=undefined&requestor=${REQUESTOR}`,
        {
            method: 'GET',
            requestInitiator: 'getBookendHeaderData',
            signal,
        }
    );
    return response;
};
