import { useEffect, useReducer } from 'react';
import { useEventCallback } from '@design-stack-vista/utility-react';

export interface KeyboardShortcutArgs {
    shortcutKeys: string[];
    callback: (keys?: Record<string, boolean>) => void;
    isEnabled?: () => boolean;
    callbackWithKeysUp?: boolean;
}

// We don't want the keyboard hook to interact with these HTML elements.
const blacklistedTargets = ['INPUT', 'TEXTAREA', 'SELECT'];

function validateArgs(shortcutKeys: string[]) {
    if (!Array.isArray(shortcutKeys)) {
        throw new Error(
            'The first parameter to `useKeyboardShortcut` must be an ordered array of `KeyboardEvent.key` strings.'
        );
    }

    if (!shortcutKeys.length) {
        throw new Error(
            'The first parameter to `useKeyboardShortcut` must contain atleast one `KeyboardEvent.key` string.'
        );
    }
}

enum KeyActionTypes {
    Down = 'setKeyDown',
    Up = 'setKeyUp',
}

function keysReducer(state: Record<string, boolean>, action: { type: string; key: string }) {
    switch (action.type) {
        case KeyActionTypes.Down:
            return { ...state, [action.key]: true };
        case KeyActionTypes.Up:
            return { ...state, [action.key]: false };
        default:
            return state;
    }
}

function hasTarget(target: EventTarget | null) {
    if (!target) {
        return false;
    }

    if (
        target instanceof Element &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (blacklistedTargets.includes(target.tagName) || (target.attributes as any).contentEditable)
    ) {
        return false;
    }

    return true;
}

export function useKeyboardShortcut({
    shortcutKeys,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback = () => {},
    isEnabled = () => true,
    callbackWithKeysUp = false,
}: KeyboardShortcutArgs) {
    validateArgs(shortcutKeys);

    const initialKeyMapping = shortcutKeys.reduce(
        (currentKeys: { [key: string]: boolean }, key) => ({ ...currentKeys, [key.toLowerCase()]: false }),
        {}
    );

    const [keys, setKeys] = useReducer(keysReducer, initialKeyMapping);

    const keyListener = useEventCallback(
        (event: KeyboardEvent, type: string) => {
            // An earlier keyDown might have caused isEnabled() to change from true to false,
            // so we need to run keyUp unconditionally in order to reset the internal pressed state of the key.
            if (type === KeyActionTypes.Down && !isEnabled()) {
                return;
            }

            const { key, repeat, target } = event;

            if (!hasTarget(target)) return;

            const loweredKey = key.toLowerCase();

            if (keys[loweredKey] === undefined) return;

            // Prevent other effects (like scrolling) from happening
            event.preventDefault();

            if (type === KeyActionTypes.Down && repeat) return;

            if (type === KeyActionTypes.Down && !keys[loweredKey]) {
                setKeys({ type, key: loweredKey });
            }
            if (type === KeyActionTypes.Up && keys[loweredKey]) {
                setKeys({ type, key: loweredKey });
            }
        },
        [keys]
    );

    useEffect(() => {
        if (!Object.values(keys).filter((value) => !value).length || callbackWithKeysUp) {
            callback(keys);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keys]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => keyListener(e, KeyActionTypes.Down);
        const handleKeyUp = (e: KeyboardEvent) => keyListener(e, KeyActionTypes.Up);

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [keyListener]);
}
