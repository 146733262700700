import React from 'react';
import { css } from '@emotion/css';
import { Icon, Link, Typography } from '@vp/swan';
import { text } from '../../../utils/localization';
import { Tooltip } from '../../quad/components/Tooltip';
import { MARKETING_MATERIAL_URL } from '../postcardConstants';

const mailingIconStyle = css`
    display: flex;
`;

const tooltipContainerStyle = css`
    // Reason - Behind modal has 10000 z-index on mobile
    z-index: 10001;
`;

export const PostCardMailingInfoToolTip = () => {
    return (
        <Tooltip
            mouseLeaveDelay={300}
            content={
                <Typography as="div" fontSize="small" data-testid="postcard-switching-block-tooltip-content">
                    {`${text('oneStopShopPostcard')} `}
                    <Link target="_blank" href={MARKETING_MATERIAL_URL}>
                        {text('seeDetails')}
                    </Link>
                </Typography>
            }
            placement="top"
            tooltipContainerClassName={tooltipContainerStyle}
        >
            <Icon iconType="info" size="16p" className={mailingIconStyle} />
        </Tooltip>
    );
};
