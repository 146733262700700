import qs from 'qs';
import { REQUESTOR, STUDIO_CALCIFER_URL } from '../config/constant';
import { CalciferFetchOptions, CalciferResponse } from '../types/calcifer';
import { StudioSecondaryConfigResponse } from '../types/secondaryConfigTypes';
import { fetchWrapper } from '../utils';

export async function getSecondaryConfigFromCalciferV2(
    productKey: string,
    productVersion: number,
    studioSelectedProductOptions: Record<string, string>,
    locale: string,
    isFullBleed: boolean,
    signal?: AbortSignal
) {
    const query = {
        productKey,
        productVersion: productVersion.toString(),
        selectedOptions: JSON.stringify(studioSelectedProductOptions),
        locale,
        requestor: REQUESTOR,
        isFullBleed: isFullBleed ? 'true' : 'false',
    };
    const url = `${STUDIO_CALCIFER_URL}/v2/secondaryStudioConfig/`;

    const response = await fetchWrapper<StudioSecondaryConfigResponse>(url, {
        method: 'GET',
        query,
        requestInitiator: 'getSecondaryConfigFromCalciferV2',
        signal,
    });

    return response;
}

export async function getSubstrateColorsWithStockInfoFromCalciferV2<T>(
    mpvId: string,
    locale: string,
    productKey: string,
    productVersion: number,
    selectedOptions: Record<string, string> | undefined,
    productOptionName: string,
    signal?: AbortSignal
): Promise<T | undefined> {
    const query = qs.stringify({
        mpvId,
        locale,
        productKey,
        productVersion: productVersion.toString(),
        selectedOptions,
        requestor: REQUESTOR,
        productOptionName,
    });

    const url = `${STUDIO_CALCIFER_URL}/v2/substrateColorsWithStockInfo/`;

    const response = await fetchWrapper<T>(url, {
        method: 'GET',
        query,
        requestInitiator: 'getSubstrateColorsWithStockInfoFromCalciferV2',
        signal,
    });

    return response;
}

export const queryProductConfiguration = async (
    fetchParams: CalciferFetchOptions,
    onError: () => void,
    signal?: AbortSignal
) => {
    const queryString = ((obj: CalciferFetchOptions) =>
        Object.keys(obj).reduce((acc, next, index) => {
            if (typeof obj[next as keyof typeof obj] === 'undefined') return acc;
            return `${acc}${index > 0 ? '&' : ''}${next}=${encodeURIComponent(
                typeof obj[next as keyof typeof obj] === 'object'
                    ? JSON.stringify(obj[next as keyof typeof obj]) ?? ''
                    : obj[next as keyof typeof obj] ?? ''
            )}`;
        }, '?'))(fetchParams);
    const path = `${STUDIO_CALCIFER_URL}/v2/initializedStudioConfig`;
    const url = `${path}${queryString}`;

    const productConfiguration = await fetchWrapper(url, {
        method: 'GET',
        requestInitiator: 'queryProductConfiguration',
        signal,
        onError,
    });

    return productConfiguration as CalciferResponse | undefined;
};

interface Props {
    productKey: string;
    quantity: number;
    locale: string;
}

export async function getDefaultProduct({ productKey, quantity, locale }: Props) {
    const requestParams = {
        productKey,
        locale,
        requestor: REQUESTOR,
        quantity,
    };

    const queryString = qs.stringify(requestParams);
    const url = `${STUDIO_CALCIFER_URL}/v2/productDefaults/?${queryString}`;
    const defaults = await fetchWrapper(url, {
        method: 'GET',
        requestInitiator: 'getDefaultProduct',
    });

    return defaults;
}
