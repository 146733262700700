import { UdsResponse } from '@design-stack-ct/documents-sdk';
import { createWorkEntity, fetchWorkEntity, updateWorkEntity } from '../../api/workEntity';
import { REQUESTOR } from '../../config/constant';
import { WorkEntity, WorkEntityResponse } from '../../types/workEntityTypes';

type CreateWorkPayloadFromConfig = (config: {
    productKey: string;
    productVersion: number;
    customerSelectedOptions: Record<string, string>;
    selectedOptions?: Record<string, string>;
    quantity: number;
    mpvId?: string;
    locale: string;
    editInStudioUrl: URL;
    udsResponse: UdsResponse;
    authorizationHeader: string;
    workId?: string;
    workName: string;
    disableMailingServiceModal?: boolean;
}) => Promise<WorkEntity>;

const MERCHANDISING_BASE_PATH = 'https://merchandising-product-service.cdn.vpsvc.com/api/v3';

export const createWorkPayloadFromConfig: CreateWorkPayloadFromConfig = async (createWorkConfig) => {
    const { authorizationHeader, workId } = createWorkConfig;

    const workEntity = workId ? await fetchWorkEntity(authorizationHeader, REQUESTOR, workId) : {};

    // clear out the search params
    createWorkConfig.editInStudioUrl.search = '';

    // add placeholder for the work entity id.  NOT using searchParams.append here because
    //it will always URI-encode the parameters that you add, leading to a URI-encoded ${workId}
    //placeholder (%24%7BworkId%7D) that WES can't recognize and replace with the workId
    const editUrl = `${createWorkConfig.editInStudioUrl.toString()}?workId=\${workId}`;

    const updatedWorkEntity: WorkEntity = {
        merchandising: {
            merchandisingSelections: createWorkConfig.customerSelectedOptions || {},
            quantity: createWorkConfig.quantity,
            mpvUrl: createWorkConfig.mpvId
                ? `${MERCHANDISING_BASE_PATH}/mpv/vistaprint/${createWorkConfig.locale}/${createWorkConfig.mpvId}`
                : undefined,
        },
        product: {
            key: createWorkConfig.productKey,
            version: createWorkConfig.productVersion,
        },
        resources: {
            qty: createWorkConfig.quantity,
            disableMailingServiceModal: createWorkConfig.disableMailingServiceModal,
        },
        design: {
            /* eslint-disable no-underscore-dangle */
            designUrl: createWorkConfig.udsResponse._links.documentRevision.href,
            manufactureUrl: createWorkConfig.udsResponse._links.drawingInstructions.href,
            displayUrl: createWorkConfig.udsResponse._links.previewInstructions.href,
            metadata: {
                productKey: createWorkConfig.productKey,
                studioSelectedProductOptions: JSON.stringify(createWorkConfig.customerSelectedOptions),
            },
            editUrl,
        },
        workName: createWorkConfig.workName,
    };

    return { ...workEntity, ...updatedWorkEntity };
};

export type CreateOrUpdateWorkEntity = (
    saveWorkConfig: Parameters<CreateWorkPayloadFromConfig>[0] & {
        authorizationHeader: string;
    }
) => Promise<WorkEntityResponse>;

export const createOrUpdateWorkEntity: CreateOrUpdateWorkEntity = async (saveWorkConfig) => {
    const { authorizationHeader, workId } = saveWorkConfig;

    const workEntity = await createWorkPayloadFromConfig(saveWorkConfig);

    if (workId) {
        return updateWorkEntity(workEntity, authorizationHeader);
    } else {
        return createWorkEntity(workEntity, authorizationHeader);
    }
};
