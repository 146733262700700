import React, { useCallback, useMemo, useRef, useState } from 'react';
import { upgradeCimDoc } from '@design-stack-vista/cimdoc-state-manager';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { useProductParams } from './ProductParamsProvider';
import { getSecondaryConfigFromCalciferV2 } from '../api/calcifer';
import { fetchDocument } from '../api/cimDoc';
import { updateDocumentDimensions } from '../api/designViews';
import { ProductConfigurationStatus, REQUESTOR } from '../config/constant';
import { FONT_REPOSITORY_URL } from '../config/defaultFontConfiguration';
import { UpdateProductConfig } from '../features/Flexibility/common';
import { isVCSConsumer } from '../features/VCS/utils/isVCSConsumer';
import { ProductConfigurationContext, ProductData } from '../hooks/calcifer/useProductConfiguration';
import { sortOptions, useQueryProductConfiguration } from '../hooks/calcifer/useQueryProductConfiguration';
import { CalciferResponse } from '../types/calcifer';
import { PriceDetails } from '../types/price';
import { StudioSecondaryConfigResponse } from '../types/secondaryConfigTypes';
import { getProductPricingData } from '../utils';
import { getCountryFromLocale } from '../utils/getCountryFromLocale';
import { updateCimdocForPanelName } from '../utils/productLoadingUtil';

export interface ProductConfigurationProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

export const ProductConfigurationProvider = (props: ProductConfigurationProviderProps) => {
    const { children } = props;

    const productParams = useProductParams();
    const { getProductConfiguration } = useQueryProductConfiguration();

    const [workId, setWorkId] = useState('');
    const [baseProductSelections, setBaseProductSelections] = useState<Record<string, string> | null>(null);
    const [productConfigurationData, setProductConfigurationData] = useState<CalciferResponse | undefined>(undefined);
    const [secondaryConfig, setSecondaryConfig] = useState<StudioSecondaryConfigResponse | undefined>(undefined);
    const [productPrice, setProductPrice] = useState<PriceDetails | undefined>(undefined);

    const productConfigurationStatus: React.MutableRefObject<ProductConfigurationStatus | undefined> = useRef();

    useAsyncEffect(
        (helpers) => {
            async function initializeProductConfiguration() {
                const calciferResponse = await getProductConfiguration(
                    {
                        locale: productParams.locale,
                        productKey: productParams.productKey,
                        market: getCountryFromLocale(productParams.locale),
                        selectedOptions: JSON.stringify(sortOptions(productParams.customerSelectedOptions || {})),
                        productVersion: productParams.productVersion,
                        requestor: REQUESTOR,
                        qty: productParams.selectedQuantity,
                        documentUrl: productParams.documentUrl,
                    },
                    () => {
                        productConfigurationStatus.current = ProductConfigurationStatus.Error;
                    },
                    helpers.abortSignal
                );
                if (calciferResponse) {
                    // vcs calcifer response does not contain quantity, thus setting the value from params.
                    if (isVCSConsumer()) {
                        calciferResponse.quantity = calciferResponse.quantity ?? productParams.selectedQuantity;
                    }
                    // fetch cimdoc from documentUrl
                    const cimdoc = productParams.documentUrl
                        ? await fetchDocument(productParams.documentUrl)
                        : undefined;

                    // fetch secondary data
                    const secondaryConfigData = await getSecondaryConfigFromCalciferV2(
                        calciferResponse.productKey,
                        calciferResponse.productVersion,
                        calciferResponse.selectedOptions,
                        productParams.locale,
                        true,
                        helpers.abortSignal
                    );

                    if (productParams.documentUrl) {
                        if (cimdoc) {
                            const normalisedCimdoc = updateCimdocForPanelName(
                                cimdoc,
                                calciferResponse.designViews.designViews
                            );
                            const { productKey, productVersion, selectedOptions } = calciferResponse;
                            const updatedCimdoc = await updateDocumentDimensions({
                                productKey,
                                productVersion,
                                selectedOptions,
                                designDocument: normalisedCimdoc,
                                locale: productParams.locale,
                            });
                            setWorkId(productParams.workId);
                            // we have to set the font repository to the one that we use, otherwise fonts will fail
                            // cimdocs generated by cimpress services will generally use their own font repos, which are no good for us
                            // we hardcode the fonts that we want, so text will be useless unless we use our own repository
                            updatedCimdoc.fontRepositoryUrl = FONT_REPOSITORY_URL;
                            setProductConfigurationData({
                                ...calciferResponse,
                                // upgradeCimDoc is called to ensure loading an old design document containing surfaces(instead of panels) loads correctly
                                designDocument: upgradeCimDoc(updatedCimdoc),
                            });
                        }
                    } else {
                        setProductConfigurationData(calciferResponse);
                    }

                    setSecondaryConfig(secondaryConfigData);
                    setBaseProductSelections(productParams.customerSelectedOptions ?? {});
                } else {
                    productConfigurationStatus.current = ProductConfigurationStatus.Error;
                }
            }
            if (productParams.paramInitialized && productParams.locale) {
                initializeProductConfiguration();
            }
        },
        [productParams.paramInitialized]
    );

    const updateProductConfiguration = useCallback(
        async (updateParams: UpdateProductConfig) => {
            if (updateParams.productConfig && updateParams.newCimDoc) {
                const secondaryConfigData = await getSecondaryConfigFromCalciferV2(
                    updateParams.productConfig.productKey,
                    updateParams.productConfig.productVersion,
                    updateParams.productConfig.selectedOptions,
                    productParams.locale,
                    true
                );

                setProductConfigurationData({
                    ...updateParams.productConfig,
                    designDocument: updateParams.newCimDoc,
                });
                setSecondaryConfig(secondaryConfigData);
            }
        },
        [productParams.locale]
    );

    // Updates the customerSelectedOptions in productData when users adds image to back
    // from Backsides: Blank to Backsides: Color
    const updateCustomerSelectedOptions = useCallback((partialSelectedOptions: Record<string, string>) => {
        setProductConfigurationData((prev) => {
            if (prev) {
                const newCustomerSelectedOptions = {
                    ...prev.customerSelectedOptions,
                    ...partialSelectedOptions,
                };
                return { ...prev, customerSelectedOptions: newCustomerSelectedOptions };
            }
        });
    }, []);

    useAsyncEffect(
        (helpers) => {
            (async () => {
                const productPriceData = await getProductPricingData(
                    productConfigurationData as ProductData,
                    productParams.locale,
                    helpers.abortSignal
                );
                if (productPriceData) {
                    setProductPrice(productPriceData);
                }
            })();
        },
        [productConfigurationData, productParams.locale]
    );

    const contextValue = useMemo(() => {
        return {
            productData: productConfigurationData,
            workId,
            setWorkId,
            updateCustomerSelectedOptions,
            updateProductConfiguration,
            secondaryConfig,
            productConfigurationStatus: productConfigurationStatus.current,
            productPrice,
            baseProductSelections,
        };
    }, [
        productConfigurationData,
        workId,
        secondaryConfig,
        productConfigurationStatus.current,
        productPrice,
        baseProductSelections,
        updateCustomerSelectedOptions,
        updateProductConfiguration,
    ]);

    return <ProductConfigurationContext.Provider value={contextValue}>{children}</ProductConfigurationContext.Provider>;
};
