import React from 'react';
import { css } from '@emotion/css';
import { Button, Icon } from '@vp/swan';
import { useScreenLayout } from '../../../hooks/useScreenLayout';

const iconStyle = css`
    padding-bottom: 2px;
`;

interface Props {
    onBack: () => void;
    buttonText: string;
}

export const BackNavigationalButton = ({ onBack, buttonText }: Props) => {
    const { isSmall } = useScreenLayout();
    const iconType = isSmall ? 'chevronLeft' : 'arrowLeft';
    return (
        <Button
            data-testid="back-navigational-button"
            skin="unstyled"
            iconPosition="left"
            onClick={onBack}
            fontSize="small"
            fontWeight="bold"
        >
            <Icon className={iconStyle} iconType={iconType} mr="2" size="20p" />
            {buttonText}
        </Button>
    );
};
