/**
 * Reference - {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/master/src/studioFive/ProductActions.js#L32}
 */
import { CimDoc } from '@design-stack-vista/cdif-types';
import keyBy from 'lodash.keyby';
import { SurfaceUpsell } from './types';
import { getDifferentialPricing } from '../../api/pricing';
import { PricingType } from '../../config/constant';
import { noticeError } from '../newRelicUtils';
import { isVatInclusive } from '../pricingUtilities';

interface ProductData {
    productKey: string;
    selectedOptions: Record<string, string>;
    productVersion: number;
    quantity: number;
}

export const getPricedSurfaceUpsell = async (
    providedDocument: CimDoc,
    generatedSurfaceUpsells: SurfaceUpsell[],
    productData: ProductData,
    signal?: AbortSignal
) => {
    const { productKey, quantity, productVersion, selectedOptions } = productData;
    const document = providedDocument;

    const { metadata } = document;

    const existingSurfaceUpsells = metadata ? metadata.surfaceUpsells : undefined;

    const studioSelectedProductOptions = selectedOptions;
    const surfaceUpsells = generatedSurfaceUpsells.map((generatedUpsell) => ({
        ...generatedUpsell,
        upsellOffered:
            existingSurfaceUpsells?.find(
                (existingSurfaceUpsell: { panelName: string }) =>
                    existingSurfaceUpsell.panelName === generatedUpsell.panelName
            )?.upsellOffered || false,
    }));

    try {
        if (surfaceUpsells && surfaceUpsells.length > 0) {
            const pricedSurfaceUpsells = await Promise.all(
                surfaceUpsells.map(async (surfaceUpsell) => {
                    const newSurfaceUpsell = { ...surfaceUpsell };
                    if (newSurfaceUpsell.optionName) {
                        const choiceGroups = {
                            [newSurfaceUpsell.colorOption]: {
                                [newSurfaceUpsell.optionName]: newSurfaceUpsell.colorOption,
                            },
                            [newSurfaceUpsell.blankOption]: {
                                [newSurfaceUpsell.optionName]: newSurfaceUpsell.blankOption,
                            },
                        };
                        // get the lowest overall price, so remove the current option
                        const filteredSelectedProductOptions = { ...studioSelectedProductOptions };
                        if (filteredSelectedProductOptions[newSurfaceUpsell.optionName]) {
                            delete filteredSelectedProductOptions[newSurfaceUpsell.optionName];
                        }

                        try {
                            const differentialPricingData = await getDifferentialPricing(
                                productKey,
                                filteredSelectedProductOptions,
                                quantity,
                                choiceGroups,
                                productVersion,
                                signal
                            );
                            newSurfaceUpsell.currency = differentialPricingData.currency;
                            const priceToUse = isVatInclusive() ? PricingType.Taxed : PricingType.Untaxed;

                            newSurfaceUpsell.pricing = Object.entries(differentialPricingData.choiceGroups).reduce(
                                (
                                    accumulator: Record<
                                        string,
                                        { differentialDiscountPrice: number; differentialListPrice: number }
                                    >,
                                    choice
                                ) => {
                                    accumulator[choice[0]] = {
                                        differentialDiscountPrice: choice[1].differentialDiscountPrice[priceToUse],
                                        differentialListPrice: choice[1].differentialListPrice[priceToUse],
                                    };
                                    return accumulator;
                                },
                                {}
                            );
                        } catch (error) {
                            noticeError(error, {
                                method: 'getPricedSurfaceUpsell - getDifferentialPricing',
                            });
                        }
                    }
                    return newSurfaceUpsell;
                })
            );
            return keyBy(pricedSurfaceUpsells, (upsell) => upsell.panelName) as Record<string, SurfaceUpsell>;
        }
    } catch (error) {
        noticeError(error, {
            method: 'getPricedSurfaceUpsell',
        });
    }
};
