import React, { useEffect, useRef } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { css } from '@emotion/css';
import {
    Button,
    Divider,
    ModalDialogBody,
    ModalDialogButtons,
    ModalDialogFooter,
    ModalDialogHeader,
    tokens,
    Typography,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { AdjustContrastColor } from './AdjustContrastColor';
import { AdjustContrastRange } from './AdjustContrastRange';
import { AdjustContrastSliderDescription } from './AdjustContrastSliderDescription';
import { ApplyContrastColor } from './ApplyContrastColor';
import { SingleColorImagePreview } from './SingleColorImagePreview';
import { DesktopExperience, MobileExperience } from '../../../components/Experiences';
import { StandardModal } from '../../../components/Modal/components/StandardModal';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { useAdjustContrast } from '../hooks';
import { adjustContrastStore } from '../store';

const getAdjustColorPanelStyle = (isSmall: boolean) => css`
    ${!isSmall &&
    `   .swan-modal-dialog.swan-modal-dialog-visible {
            padding-top: ${tokens.SwanSemSpace9};
        }
    `}
    ${isSmall &&
    `
        .swan-modal-dialog.swan-modal-dialog-panel .swan-modal-dialog-header {
            padding-left: ${tokens.SwanSemSpace4};
            margin: 0 0 ${tokens.SwanSemSpace4} 0;
        }
        .swan-modal-dialog.swan-modal-dialog-panel.swan-modal-dialog-panel-bottom .swan-modal-dialog-buttons {
            padding: 0 ${tokens.SwanSemSpace4};
            display: flex;
            gap: ${tokens.SwanSemSpace4};
            flex-wrap: nowrap;
        }
        .swan-modal-dialog .swan-modal-dialog-body {
            max-width: inherit;
        }
    `}
`;

const adjustContrastHeaderStyle = css`
    &.swan-modal-dialog-header {
        width: 100%;
        max-width: unset;
        text-align: center;
    }
`;

const getAdjustContrastFooterStyle = (isSmall: boolean) => css`
    &.swan-modal-dialog-footer {
        padding: ${tokens.SwanSemSpace5} 0 ${tokens.SwanSemSpace6};
        ${!isSmall && `border-top: 1px solid ${tokens.SwanSemColorBorderStandard};`}
        .swan-modal-dialog-buttons {
            display: flex;
            justify-content: center;
            gap: ${tokens.SwanSemSpace4};
            margin: 0;
            .swan-button {
                margin: 0;
            }
        }
    }
`;

export const AdjustContrastModal = observer(() => {
    const { isSmall } = useScreenLayout();
    const { showAdjustContrastModal, setShowAdjustContrastModal } = adjustContrastStore;
    const { resetContrastControls, isImageLoaded } = useAdjustContrast();
    const imageCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const { fireEvent } = useCustomEventPropagation();

    const onRequestDismiss = () => {
        setShowAdjustContrastModal(false);
        if (isImageLoaded) {
            resetContrastControls();
        }
    };

    useEffect(() => {
        if (showAdjustContrastModal) {
            fireEvent({
                name: ACTION_NAME.EXPERIMENT_CLICKED,
                extraData: {
                    label: QUAD_TRACKING_EVENTS.ADJUST_CONTRAST_MODAL_OPENED,
                    eventDetail: 'QUAD;QUAD;Adjust Contrast modal opened',
                    navigationDetail: 'AdjustContrast:Adjust Contrast modal opened',
                    pageSection: PageSection.AdjustContrast,
                    pageStage: PageStage.Design,
                    category: undefined,
                },
            });
        }
    }, [showAdjustContrastModal]);

    return (
        <StandardModal
            isOpen={showAdjustContrastModal}
            onClose={onRequestDismiss}
            className={getAdjustColorPanelStyle(isSmall)}
            ariaLabelledby={text('adjustContrastQuickToolLabel')}
            fullBleed={true}
            positionAbsoluteNavigation={!isSmall}
        >
            <ModalDialogHeader mt="0" mb="5" className={adjustContrastHeaderStyle}>
                <Typography fontSize="large" fontWeight="bold">
                    {text('adjustContrastAdjustImageContrast')}
                </Typography>
            </ModalDialogHeader>
            <ModalDialogBody>
                <DesktopExperience>
                    <AdjustContrastSliderDescription />
                </DesktopExperience>
                <SingleColorImagePreview ref={imageCanvasRef} />
                <DesktopExperience>
                    <>
                        <AdjustContrastColor />
                        <AdjustContrastRange />
                    </>
                </DesktopExperience>
                <MobileExperience>
                    <>
                        <AdjustContrastSliderDescription />
                        <AdjustContrastRange />
                        <Divider mb={'5'} />
                        <AdjustContrastColor />
                    </>
                </MobileExperience>
            </ModalDialogBody>
            <ModalDialogFooter pinned className={getAdjustContrastFooterStyle(isSmall)}>
                <ModalDialogButtons mb="2">
                    <Button
                        skin="secondary"
                        onClick={onRequestDismiss}
                        size="mini"
                        width={isSmall ? 'full-width' : 'standard'}
                    >
                        {text('cancelButton')}
                    </Button>
                    <ApplyContrastColor imageCanvasRef={imageCanvasRef} />
                </ModalDialogButtons>
            </ModalDialogFooter>
        </StandardModal>
    );
});
