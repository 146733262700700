import { PREMIUM_FINISH_ASSETS } from './assets';
import { FINISH_PREVIEW_FORMAT, FINISH_TYPE, FinishOption, LEVEL } from '../constants';
import { StaticData } from '../type';

const finishApplyOptions = [
    {
        id: FinishOption.TextOnly,
        title: 'textOnly',
        description: 'finishTextOnlyDescription',
    },
    {
        id: FinishOption.ImageOnly,
        title: 'imageOnly',
        description: 'finishImageOnlyDescription',
    },
    {
        id: FinishOption.TextAndImage,
        title: 'textAndImage',
        description: 'finishTextAndImageDescription',
    },
];

/**
 * This is the configuration object for the premium finishes.
 *
 * In the case of adding new finish. Couple of things are required:
 *
 * 1. There are 2 level's
 *      LEVEL-1: Contains information on the finish
 *      title - expects translation-key
 *      description - expects translation-key
 *      previewUrl - This is the preview that is shown on the left
 *
 *      LEVEL-2: Contains the options provided by the finish
 */
export const staticDataValue: StaticData = {
    [FINISH_TYPE.embossedGloss]: {
        [LEVEL.level1]: {
            title: 'embossedGlossTitle',
            description: 'embossedGlossDescription',
            previewUrl: {
                sourceUrl: PREMIUM_FINISH_ASSETS.embossedGlossPreviewVideo,
                type: FINISH_PREVIEW_FORMAT.VIDEO,
            },
            thumbnail: PREMIUM_FINISH_ASSETS.embossedGlossThumbnail,
        },
        [LEVEL.level2]: finishApplyOptions,
    },
    [FINISH_TYPE.foilAccent]: {
        [LEVEL.level1]: {
            title: 'foilAccentTitle',
            description: 'foilAccentDescription',
            previewUrl: {
                sourceUrl: PREMIUM_FINISH_ASSETS.foilAccentPreviewVideo,
                type: FINISH_PREVIEW_FORMAT.VIDEO,
            },
            thumbnail: PREMIUM_FINISH_ASSETS.foilAccentThumbnail,
        },
        [LEVEL.level2]: finishApplyOptions,
    },
    [FINISH_TYPE.none]: {
        [LEVEL.level1]: {
            title: 'noneTitle',
            description: 'noneDescription',
            previewUrl: {
                sourceUrl: PREMIUM_FINISH_ASSETS.noPremiumPreviewImage,
                type: FINISH_PREVIEW_FORMAT.IMAGE,
            },
            thumbnail: PREMIUM_FINISH_ASSETS.noPremiumThumbnail,
        },
        [LEVEL.level2]: [],
    },
};
