import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens } from '@vp/swan';
import { NotificationToasts } from '../../components/Notification';
import { VisiblePanels } from '../../components/VisiblePanels';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { BottomBar } from '../BottomBar';
import { CanvasSelectorMobile } from '../CanvasSelector/CanvasSelectorMobile';
import { QMPreview } from '../quad/components/QMPreview';
import { QuickTools } from '../QuickTools/QuickTools';

export const designAreaCanvasStyle = css`
    flex: 1;
    position: relative;
    height: 100%;
`;

export const getNotificationPropStyle = (isSmall: boolean) => css`
    padding: ${tokens.SwanSemSpace4} ${tokens.SwanSemSpace5};
    top: ${tokens.SwanSemSpace7};
    max-width: ${isSmall ? `100vw` : `100%`};
`;

export const DesignAreaCanvas = () => {
    const { isSmall } = useScreenLayout();

    return (
        <FlexBox flexDirection="column" alignItems="center" backgroundColor="strong" className={designAreaCanvasStyle}>
            <NotificationToasts propStyle={getNotificationPropStyle(isSmall)} />
            <VisiblePanels />
            <QuickTools />
            <BottomBar />
            <CanvasSelectorMobile />
            <QMPreview />
        </FlexBox>
    );
};
