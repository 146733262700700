import { Dimensions, Path, Point } from '@design-stack-vista/ida-framework';

interface Coordinates {
    x: number | undefined;
    y: number | undefined;
}

interface CoordinateObject {
    end: Coordinates;
    firstCurvePoint: Coordinates;
    secondCurvePoint: Coordinates;
}

interface PathInstructions {
    start?: string;
    directions: string[];
    closePath?: string;
}

const svgDirections = {
    moveTo: 'M',
    lineTo: 'L',
    curve: 'C',
    closePath: 'Z',
};

function createCoordinateInstructions({ x, y }: Coordinates) {
    return `${x},${y}`;
}

function mapCoordinates(data: Point) {
    return {
        end: {
            x: data.x,
            y: data.y,
        },
        firstCurvePoint: {
            x: data.controlPoints?.first.x ?? undefined,
            y: data.controlPoints?.first.y ?? undefined,
        },
        secondCurvePoint: {
            x: data.controlPoints?.second.x ?? undefined,
            y: data.controlPoints?.second.y ?? undefined,
        },
    };
}

function createInstructions(direction: string, coordinates: Coordinates[]) {
    const coordStrings = coordinates.map(createCoordinateInstructions);
    return `${direction} ${coordStrings.join(' ')}`;
}

function isCurve(coordinates: CoordinateObject) {
    return (
        coordinates.firstCurvePoint.x !== undefined &&
        coordinates.firstCurvePoint.y !== undefined &&
        coordinates.secondCurvePoint.x !== undefined &&
        coordinates.secondCurvePoint.y !== undefined
    );
}

function createPathDescription({ start = '', directions, closePath = '' }: PathInstructions) {
    return `${start} ${directions.join(' ')} ${closePath}`.trim();
}

function createPaths(pathData: Path[], closePath: boolean): string[] {
    return pathData.map((data) => {
        const start = createInstructions(svgDirections.moveTo, [{ x: data.anchor.x, y: data.anchor.y }]);

        const directions = data.points.map((p) => {
            const coordinateObject = mapCoordinates(p);
            return isCurve(coordinateObject)
                ? createInstructions(svgDirections.curve, [
                      coordinateObject.firstCurvePoint,
                      coordinateObject.secondCurvePoint,
                      coordinateObject.end,
                  ])
                : createInstructions(svgDirections.lineTo, [coordinateObject.end]);
        });

        return createPathDescription({
            start,
            directions,
            closePath: closePath ? `${svgDirections.closePath}` : '',
        });
    });
}

export function createMaskPath(paths: Path[], closePath = true): string {
    const directions = createPaths(paths, closePath);

    return createPathDescription({ directions });
}

export function createBorderInstructions({ width, height }: Dimensions) {
    const topLeft = { x: 0, y: 0 };
    const borderInstructions = [topLeft, { x: width, y: 0 }, { x: width, y: height }, { x: 0, y: height }];
    return {
        points: borderInstructions,
        anchor: topLeft,
    };
}
