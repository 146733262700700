import { Data } from '../providers/ABTestProvider';
import { QuadExperiment } from '../types/quadExperiment';

const DEFAULT_CONTROL_VARIATION = 'control';

export const getExperimentProperties = (experiment: QuadExperiment | undefined, ABTest: Data) => {
    const experimentKey = experiment?.experimentKey;
    const variationKey = ABTest.getVariation(experimentKey as string);

    const isControlVariation = experiment?.variations.some(
        (variation) => variation.toLowerCase() === DEFAULT_CONTROL_VARIATION && variation === variationKey
    );

    return { experimentKey, variationKey, isControlVariation };
};
