import React from 'react';
import { css } from '@emotion/css';
import { Callout, tokens } from '@vp/swan';
import { CalloutSkins } from '../../../../config/constant';
import { Tooltip } from '../Tooltip';

const toolTipStyle = css`
    color: ${tokens.SwanSemColorTextStandard};
    font-size: ${tokens.SwanSemFontSizeXsmall};
    background: ${tokens.SwanSemColorBgContainer};
    border: ${tokens.SwanSemBorderWidthFloating} solid ${tokens.SwanSemColorBorderContainer}
    border-radius: ${tokens.SwanSemBorderRadiusContainer};
`;

const labelContainerStyle = css`
    &:not(:first-of-type) {
        margin-left: ${tokens.SwanSemSpace4};
    }
    display: block;
    .quad-tooltip__trigger {
        display: block;
    }
`;

const labelStyle = css`
    pointer-events: auto;
`;
export interface AreaLabelProps {
    label: string;
    calloutSkin: CalloutSkins;
    tooltip?: string;
}

export const DesignAreaLabel = ({ label, calloutSkin, tooltip }: AreaLabelProps) => {
    const labelContent = (
        <Callout className={labelStyle} skin={calloutSkin} fontSize="xsmall">
            {label}
        </Callout>
    );

    return (
        <div className={labelContainerStyle}>
            {tooltip && (
                <Tooltip
                    content={tooltip}
                    placement="top"
                    triggerAriaLabel={label}
                    trigger={['hover', 'focus']}
                    mouseLeaveDelay={500}
                    tooltipContainerClassName={toolTipStyle}
                >
                    {labelContent}
                </Tooltip>
            )}
            {!tooltip && labelContent}
        </div>
    );
};
