import React from 'react';
import type { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { DesignLayer } from '@design-stack-vista/ida-framework';
import { css } from '@emotion/css';
import { tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { SimplePanelPreview } from './SimplePanelPreview';
import { PanelMasksDesignLayer } from '../quad/components/PanelMaskDesignLayer';
import { SimpleSceneLayer } from '../quad/components/SimpleScene';

const panelPreviewDesignLayerStyle = css`
    justify-content: center;
    display: flex;
    align-items: center;
    margin: auto;
    max-width: 75%;
    max-height: 75%;
`;

export const SimplePanelPreviewLayer = observer(({ panel }: { panel: PanelState }) => {
    const designEngine = useDesignEngine();
    return (
        <>
            <SimpleSceneLayer background={tokens.SwanSemColorBgStrong} boxShadow="none" />
            <DesignLayer className={panelPreviewDesignLayerStyle}>
                <SimplePanelPreview panel={panel} pixelSize={designEngine.layoutStore.zoom} />
            </DesignLayer>
            <PanelMasksDesignLayer className={panelPreviewDesignLayerStyle} panel={panel} color="transparent" />
        </>
    );
});
