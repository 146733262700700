import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const SquareIcon = (props: SvgProps) => {
    return (
        <svg width="82" height="82" viewBox="0 0 82 82" fill="none" {...props}>
            <rect x="29.85" y="29.85" width="22.3" height="22.3" rx="3.15" stroke="black" strokeWidth="1.7" />
        </svg>
    );
};
