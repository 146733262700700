import { SiteFlowParams, SiteFlowResponse } from '@design-stack-vista/siteflow-client';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ensureUserTestId } from '@vp/ab-test-cookie';
import Cookies from 'js-cookie';
import { queryGetNextStep, SITEFLOW_NEXT_STEP_QUERY_KEY } from '../api/siteFlow';
import { REQUESTOR } from '../config/constant';
import { useProductParams } from '../providers';

type QueryKey = [string, SiteFlowParams];

export const useSiteFlowNextStepUrl = (options?: UseQueryOptions<SiteFlowResponse, Error, string, QueryKey>) => {
    const { locale, productKey, selectedOptions } = useProductParams();

    const queryKey: QueryKey = [
        SITEFLOW_NEXT_STEP_QUERY_KEY,
        {
            locale,
            productKey,
            selectedOptions,
            nextStep: true,
            siteContext: Cookies.get('sci-scx'),
            optimizelyEndUserId: ensureUserTestId(),
            requestor: REQUESTOR,
        } as SiteFlowParams,
    ];

    return useQuery<SiteFlowResponse, Error, string, QueryKey>(queryKey, queryGetNextStep, {
        select: (data) => data?.url,
        ...options,
    });
};
