import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const ShapeIcon = (props: SvgProps) => (
    <svg fill="none" viewBox="0 0 17 16" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.33992 0.430176C1.55291 0.430176 0.914917 1.06817 0.914917 1.85517V9.53122C0.914917 10.3182 1.55291 10.9562 2.33992 10.9562H6.7308C7.03929 13.5075 9.21209 15.4845 11.8466 15.4845C14.6928 15.4845 17 13.1773 17 10.3311C17 7.48501 14.6928 5.17778 11.8466 5.17778C11.7101 5.17778 11.5748 5.18309 11.441 5.19351V1.85518C11.441 1.06817 10.803 0.430176 10.016 0.430176H2.33992ZM10.191 5.4495V1.85518C10.191 1.75853 10.1126 1.68018 10.016 1.68018H2.33992C2.24327 1.68018 2.16492 1.75853 2.16492 1.85517V9.53122C2.16492 9.62787 2.24327 9.70622 2.33992 9.70622H6.73078C6.9714 7.7157 8.34681 6.07477 10.191 5.4495ZM15.75 10.3311C15.75 12.4869 14.0024 14.2345 11.8466 14.2345C9.69087 14.2345 7.94327 12.4869 7.94327 10.3311C7.94327 8.17537 9.69087 6.42778 11.8466 6.42778C14.0024 6.42778 15.75 8.17537 15.75 10.3311Z"
            fill="black"
        />
    </svg>
);
