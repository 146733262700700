import { action, makeObservable, observable } from 'mobx';
import { FilteredStockInfo } from './types';

class StockInfoStore {
    @observable.struct stockInfo: FilteredStockInfo | undefined = undefined;
    @observable inStockDetail = '';
    @observable outOfStockDetail = '';
    @observable minimumOrderQuantity = 1;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setMinimumOrderQuantity(minimumQuantity: number) {
        this.minimumOrderQuantity = minimumQuantity;
    }

    @action.bound
    setStockInfoDetails(state: FilteredStockInfo) {
        const { sizesWithStock, sizesWithLowStock, sizesWithNoStock, lowQuantityInfo } = state;

        const inStockSizes = sizesWithStock.concat(sizesWithLowStock).sort();
        const inStockDetail = inStockSizes.reduce((previous, size) => {
            const nextString = sizesWithLowStock.includes(size) ? `${size}(${lowQuantityInfo[size]})` : size;
            previous += previous ? `, ${nextString}` : nextString;
            return previous;
        }, '');
        const outOfStockDetail = sizesWithNoStock.join(', ');

        this.stockInfo = state;
        this.inStockDetail = inStockDetail;
        this.outOfStockDetail = outOfStockDetail;
    }
}

export const stockInfoStore = new StockInfoStore();
