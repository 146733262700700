import type { ReactNode } from 'react';
import React from 'react';
import type { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { DesignLayer, Guide, StrokeMask } from '@design-stack-vista/ida-framework';
import { tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { getUnzoomedPanelDimensions } from '../../utils';
import { useGetExtension } from '../quad/Extensions';
import { PanelChromesExtension } from '../quad/Extensions/PanelChromesExtension';

export interface PanelGuidesDesignLayerProps {
    panel: PanelState;
    mapGuides?: (panel: PanelState) => ReactNode | ReactNode[];
}

export function MapGuidesDefault(panel: PanelState) {
    const { guides, strokeWidth } = useGetExtension(panel, PanelChromesExtension);
    const { width, height } = getUnzoomedPanelDimensions(panel);

    return guides.map((guide: Guide, index: number) => {
        switch (guide.type) {
            case 'CREASE':
            case 'FOLD':
            case 'SCORE':
                return (
                    <StrokeMask
                        key={`${guide.type}-${index}`}
                        width={width}
                        height={height}
                        mask={guide}
                        strokeWidth={strokeWidth}
                        dashLength={10}
                        strokeColor="#ff0000"
                        alternateStrokeColor={tokens.SwanBaseColorWhite}
                        shouldAlternate={true}
                        closePath={false}
                    />
                );
            case 'CUT':
            case 'KISSCUT':
            case 'NICK':
            case 'PARTIALCUT':
            case 'PERFORATE':
                return (
                    <StrokeMask
                        key={`${guide.type}-${index}`}
                        width={width}
                        height={height}
                        mask={guide}
                        strokeWidth={strokeWidth}
                        dashLength={10}
                        strokeColor="#0000ff"
                        alternateStrokeColor={tokens.SwanBaseColorWhite}
                        shouldAlternate={true}
                        closePath={false}
                    />
                );
            default:
                return (
                    <StrokeMask
                        key={`${guide.type}-${index}`}
                        width={width}
                        height={height}
                        mask={guide}
                        strokeWidth={strokeWidth}
                        closePath={false}
                    />
                );
        }
    });
}

export const PanelGuidesDesignLayer = observer(
    ({ panel, mapGuides = MapGuidesDefault }: PanelGuidesDesignLayerProps) => (
        <DesignLayer name="panel-guides">{mapGuides(panel)}</DesignLayer>
    )
);
