import React, { useCallback, useState } from 'react';

import { Icon } from '@vp/swan';
import { HeaderButton } from './HeaderButton';
import { PdfProofDownloadModalWrapper } from '../../../features/DesignPreview/PdfProofDownloadModalWrapper';
import { text } from '../../../utils/localization';

export const DownloadPDFProof = () => {
    const [showModalDialog, setShowModalDialog] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    const handleButtonClick = useCallback(() => {
        setShowModalDialog(true);
    }, []);

    const setShowModalClose = useCallback(() => {
        setShowModalDialog(false);
    }, []);

    return (
        <>
            <HeaderButton
                label={isGenerating ? text('downloadInProgress') : text('downloadPDF')}
                icon={<Icon iconType="download" size="24p" />}
                onClick={handleButtonClick}
                disabled={isGenerating}
            />
            <PdfProofDownloadModalWrapper
                showModalDialog={showModalDialog}
                setShowModalClose={setShowModalClose}
                isGenerating={isGenerating}
                setIsGenerating={setIsGenerating}
            />
        </>
    );
};
