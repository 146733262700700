import React from 'react';
import { css } from '@emotion/css';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';

const horizontalSetStyle = css`
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

interface HorizontalSetProps {
    children: React.ReactNode | React.ReactNode[];
}

export const HorizontalSet = ({ children }: HorizontalSetProps) => {
    const { isSmall } = useScreenLayout();

    return <div className={isSmall ? horizontalSetStyle : ''}>{children}</div>;
};
