import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { ProductAttributes } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { usePricingData } from '../../../hooks/usePricingData';
import { noticeError } from '../../../utils';
import {
    getCompatibleChoiceGroups,
    mapDesignAttributeToProductOption,
    ProductChoiceGroups,
} from '../../Flexibility/common';
import { decorationAreaStore } from '../store';

export const useInitializeDecorationAreaStore = () => {
    const { productData, secondaryConfig } = useProductConfiguration();
    const { getAllCompatibleOptionsPriceData } = usePricingData();
    const { setDecorationAreaData, resetDecorationAreaStore, setDecorationAreaPrices } = decorationAreaStore;

    useAsyncEffect(
        (helpers) => {
            const initDecorationArea = async () => {
                try {
                    if (!secondaryConfig || !productData) {
                        return;
                    }

                    const { designAttributes, compatibleOptions } = secondaryConfig;

                    const decorationAreaAttribute = mapDesignAttributeToProductOption(
                        designAttributes,
                        ProductAttributes.DecorationArea
                    );

                    const decorationAreaChoiceGroups: ProductChoiceGroups = getCompatibleChoiceGroups(
                        compatibleOptions,
                        decorationAreaAttribute
                    );

                    setDecorationAreaData({ decorationAreaAttribute, decorationAreaChoiceGroups });

                    if (Object.keys(decorationAreaChoiceGroups).length <= 1) {
                        return;
                    }

                    const pricingInfo = await getAllCompatibleOptionsPriceData(
                        decorationAreaChoiceGroups,
                        productData.selectedOptions,
                        helpers.abortSignal
                    );

                    setDecorationAreaPrices(pricingInfo ?? {});
                } catch (error) {
                    resetDecorationAreaStore();
                    noticeError(error, {
                        method: 'initDecorationArea - useInitializeDecorationAreaStore',
                    });
                }
            };

            initDecorationArea();
        },
        [secondaryConfig, productData?.selectedOptions, getAllCompatibleOptionsPriceData]
    );
};
