import { localStorageUtility } from '../../utils';
import { isBranch, isLocalhost, isStaging } from '../../utils/envUtils';

const TENANT_LOCAL_STORAGE_KEY = 'vp-dst-tenant';

let tenant: string;
let tenantOverride: string;

function isClient() {
    return typeof window !== 'undefined';
}

function getTenantQueryParam(tenant?: string) {
    return `tenant=${encodeURIComponent(tenant || getTenant())}`;
}

function getTenant() {
    if (tenant === undefined) {
        const tenantOverride = getTenantOverride();
        tenant = tenantOverride !== null ? tenantOverride : getDefaultTenant();
    }

    return tenant;
}

function getTenantOverride() {
    const { getQuadConfig, addQuadConfig } = localStorageUtility;
    if (tenantOverride === undefined && isClient()) {
        const tenantFromQuery = new URLSearchParams(window.location.search).get('tenant');
        if (tenantFromQuery !== null) {
            addQuadConfig(TENANT_LOCAL_STORAGE_KEY, tenantFromQuery);
            tenantOverride = tenantFromQuery;
        } else {
            // TODO: Needs to refactor localStorage utility to return null when key is not available and update this code accordingly
            tenantOverride = getQuadConfig(TENANT_LOCAL_STORAGE_KEY);
        }
    }

    return tenantOverride;
}

function getDefaultTenant() {
    return isDevelopmentMode() ? 'dev' : '';
}

function resetTenant() {
    if (!isClient()) {
        return;
    }
    const { removeQuadConfig } = localStorageUtility;
    // Remove the tenant from local storage
    removeQuadConfig(TENANT_LOCAL_STORAGE_KEY);

    // Remove the tenant from the URL
    const {
        location: { origin, pathname, hash, search },
    } = window;
    const searchParams = new URLSearchParams(search);

    if (searchParams.get('tenant')) {
        searchParams.delete('tenant');
        const queryString = searchParams.toString();
        window.location.href = `${origin}${pathname}${queryString ? `?${queryString}` : ''}${hash}`;
    } else {
        window.location.reload();
    }
}

function isDevelopmentMode() {
    let isNonProductionUrl = false;
    if (isClient()) {
        isNonProductionUrl = isLocalhost() || isStaging() || isBranch();
    }

    return process.env.NODE_ENV !== 'production' || isNonProductionUrl;
}

function isProdTenant() {
    if (getTenant() === 'default' || getTenant() === '') {
        return true;
    }
    return false;
}

export const tenantUtil = {
    getTenantQueryParam,
    getTenant,
    getTenantOverride,
    getDefaultTenant,
    resetTenant,
    isDevelopmentMode,
    isProdTenant,
};
