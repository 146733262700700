import React, { useCallback, useEffect } from 'react';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { className, ModalDialogBody, tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DesignReview } from './DesignReview';
import { DesignReviewDescription } from './DesignReviewDescription';
import { useTemplateData } from '../../features/BackDesignTemplate';
import { DesignPreview } from '../../features/DesignPreview/DesignPreview';
import { PreviewSwitcher } from '../../features/DesignPreview/PreviewSwitcher';
import { PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../features/TrackingEvents/constant';
import { useTrackNewPage } from '../../features/TrackingEvents/hooks';
import { useTrackExperimentClick } from '../../hooks';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { useDesignReviewContext } from '../../providers';
import { usePreview } from '../../providers/PreviewProvider';
import { text } from '../../utils/localization';
import { MobileExperience } from '../Experiences';
import { FullScreenLoader } from '../Loader';
import { StandardModal } from '../Modal/components/StandardModal';
import { NotificationSections, NotificationToasts } from '../Notification';

const designReviewPanel = css`
    .swan-modal-dialog {
        overflow-y: auto;
    }
`;

const scrollableContainerStyle = css`
    overflow: auto;
`;

const getDesignReviewWindowStyleForMobile = css`
    top: 0; // to counter unnecessary 2px inline top
    height: 100vh;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const getDesignReviewWindowStyle = css`
    top: 0; // to counter unnecessary 2px inline top
    position: fixed;
    bottom: 0;
    left: 0;
    height: calc(100% - 2px); //2px for borders
    width: 100%;
    display: grid;
    grid-template-columns: 60vw auto;
    grid-template-rows: 100%;
    grid-template-areas: 'reviewPreview reviewText';

    :global(.studio-preview-container) {
        height: 100%;
        display: flex;
        flex-direction: column;

        @media ${tokens.SwanSemMqLteXs} {
            .swan-selection-set.swan-selection-set-skin-buttons .swan-selection-set-label {
                margin-bottom: 0;
            }
        }
    }

    :global(.vortex-preview-container) {
        height: calc(100% - 100px);
        @media ${tokens.SwanSemMqLteXs} {
            max-height: 60vh;
        }
    }

    @media ${tokens.SwanSemMqLteXs} {
        grid-template-columns: 100%;
        grid-template-rows: 60vh auto;
        grid-template-areas:
            'reviewPreview'
            'reviewDivider'
            'reviewText';
    }
`;

const reviewPreviews = (isSmall: boolean) => css`
    grid-area: reviewPreview;
    width: 100%;
    ${!isSmall && 'height: 100%;'}
    background-color: ${tokens.SwanSemColorBgStrong};
`;

const getTopOffset = (is3DViewSupported: boolean) => (is3DViewSupported ? '48px' : '0px');

const getBottomOffset = (isSmall: boolean, panels: PanelState[]) =>
    isSmall ? (panels.length > 1 ? '96px' : '16px') : '94px';

const getPreviewStyle = (isSmall: boolean, is3DViewSupported: boolean, panels: PanelState[]) => {
    const topOffset = getTopOffset(is3DViewSupported);
    const bottomOffset = getBottomOffset(isSmall, panels);

    const previewSpace = isSmall ? '70vh' : '100vh';

    const previewHeight = `calc(${previewSpace} - ${topOffset} - ${bottomOffset})`;

    return css`
        width: ${isSmall ? 'auto' : '65vw'};
        height: ${previewHeight};
        .two-d-preview {
            width: auto;
            height: ${previewHeight};
            > div {
                width: ${isSmall ? 'auto' : '65vw'} !important;
                height: 100% !important;
            }
        }
    `;
};

const getDesignPreviewStyle = (isSmall: boolean) => css`
    padding: 0 ${isSmall ? tokens.SwanSemSpace1 : tokens.SwanSemSpace6};
    padding-bottom: ${isSmall ? tokens.SwanSemSpace5 : tokens.SwanSemSpace6};
`;

const messages = {
    ariaCloseLabel: {
        id: 'nextStep.reviewCloseLabel',
    },
    reviewModalAriaLabel: {
        id: 'nextStep.reviewModalAriaLabel',
    },
};

export const DesignReviewPanel = observer(() => {
    const { templateMetaData } = useTemplateData();
    const placeholderTextPresent = templateMetaData.length > 0;
    const { isOpenDesignReviewModal, setIsOpenDesignReviewModal } = useDesignReviewContext();
    const {
        cimDocStore: { panels },
    } = useDesignEngine();

    const { is3DViewSupported } = usePreview();
    const { isSmall } = useScreenLayout();
    const { trackNewPage } = useTrackNewPage();

    const handleDesignReviewClose = () => {
        setIsOpenDesignReviewModal(false);
        trackNewPage();
    };

    const onBackButtonEvent = useCallback(
        (e: PopStateEvent) => {
            if (isOpenDesignReviewModal) {
                e.preventDefault();
                trackExperimentClick({
                    label: QUAD_TRACKING_EVENTS.REVIEW,
                    eventDetail: 'QUAD;QUAD;click reviewPage closeReviewPage',
                    pageSection: PageSection.DesignReviewModal,
                    pageStage: PageStage.DesignReview,
                    experimentDetail: { closeMethod: 'Back Button' },
                });
            }
        },
        [isOpenDesignReviewModal]
    );

    useEffect(() => {
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, [onBackButtonEvent]);

    const { trackExperimentClick } = useTrackExperimentClick();

    const handleClose = () => {
        handleDesignReviewClose();
        trackExperimentClick({
            label: QUAD_TRACKING_EVENTS.REVIEW,
            eventDetail: 'QUAD;QUAD;click reviewPage closeReviewPage',
            pageSection: PageSection.DesignReviewModal,
            pageStage: PageStage.DesignReview,
            experimentDetail: { closeMethod: 'Request Dismiss' },
        });
    };

    const handleContinueEditingClick = () => {
        handleDesignReviewClose();
        trackExperimentClick({
            label: QUAD_TRACKING_EVENTS.REVIEW,
            eventDetail: 'QUAD;QUAD;click reviewPage editMyDesign',
            pageSection: PageSection.DesignReviewModal,
            pageStage: PageStage.DesignReview,
        });
    };

    return (
        <StandardModal
            isOpen={isOpenDesignReviewModal}
            onClose={handleClose}
            takeOver
            className={designReviewPanel}
            aria-label={text(messages.reviewModalAriaLabel.id)}
            fullBleed={true}
            contentTestId="reviewStepModalHeader"
            positionAbsoluteNavigation={true}
            hasBorderRadius={false}
        >
            <ModalDialogBody>
                <div className={isSmall ? getDesignReviewWindowStyleForMobile : getDesignReviewWindowStyle}>
                    <div className={className({ [scrollableContainerStyle]: isSmall })}>
                        <div className={reviewPreviews(isSmall)}>
                            {is3DViewSupported && (
                                <PreviewSwitcher
                                    testIdSuffix="review"
                                    pl={{ xs: '9', sm: '10' }}
                                    pt={{ xs: '5', sm: '6' }}
                                />
                            )}
                            <div className={getDesignPreviewStyle(isSmall)}>
                                <NotificationToasts section={NotificationSections.DESIGN_REVIEW} />
                                <DesignPreview
                                    style={getPreviewStyle(isSmall, is3DViewSupported, panels)}
                                    showBorderForSwitcher={false}
                                />
                            </div>
                        </div>
                        <MobileExperience>
                            <DesignReviewDescription
                                handleContinueEditingClick={handleContinueEditingClick}
                                placeholderTextPresent={placeholderTextPresent}
                                px="5"
                            />
                        </MobileExperience>
                    </div>
                    <DesignReview
                        handleContinueEditingClick={handleContinueEditingClick}
                        placeholderTextPresent={placeholderTextPresent}
                        hasPremiumFinishesCurrentCanvas={false}
                    />
                </div>
            </ModalDialogBody>
            <FullScreenLoader />
        </StandardModal>
    );
});

DesignReviewPanel.displayName = 'DesignReviewPanel';
