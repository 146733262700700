import React from 'react';
import { observer } from 'mobx-react-lite';
import { BackDesignTemplateList } from './BackDesignTemplateList';
import { DesignTemplateSkeleton } from './DesignTemplateSkeleton';
import { backDesignTemplateStore } from '../store/BackDesignTemplateStore';

export const BackDesignTemplate = observer(() => {
    const { isDesignTemplateLoading } = backDesignTemplateStore;

    return isDesignTemplateLoading ? <DesignTemplateSkeleton /> : <BackDesignTemplateList />;
});
