import React from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { Button, Icon } from '@vp/swan';
import { HeaderButton } from './HeaderButton';
import { ACTION_NAME, GA, PageSection, PageStage } from '../../../features/TrackingEvents/constant';
import { GA_EVENTS } from '../../../features/TrackingEvents/GAEvents';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { generateTestIdFromText } from '../../../utils/generateTestIdFromText';
import { useHeaderInfo } from '../HeaderInfoProvider';

const eventDetail = GA_EVENTS.CONTACT_US_HELP_LINK.replace('[pagePath]', 'quick-upload-and-design')
    .replace('[destinationPath]', 'customer-care/help-center')
    .replace('[CTA TEXT]', 'contactUs');

export const navigationDetails = {
    label: 'Header',
    pageSection: PageSection.Help,
    pageStage: PageStage.Discover,
    category: GA.NAVIGATION_CATEGORY,
    eventDetail,
    navigationDetail: 'contactUs',
};

export const HelpLink = (): JSX.Element => {
    const { fireEvent } = useCustomEventPropagation();
    const { headerData } = useHeaderInfo();
    const { isSmall } = useScreenLayout();

    const handleNavClick = () => {
        fireEvent({
            name: ACTION_NAME.NAVIGATION_CLICKED,
            extraData: { ...navigationDetails },
        });

        window.location.replace(headerData.base.helpLink.url);
    };

    return isSmall ? (
        <Button
            iconPosition="left"
            skin="unstyled"
            p="4"
            onClick={handleNavClick}
            data-testid={generateTestIdFromText(headerData.base.helpLink.text)}
        >
            <Icon size="20p" iconType="helpQuery" />
        </Button>
    ) : (
        <HeaderButton
            icon={<Icon size="24p" iconType="helpQuery" />}
            label={headerData.base.helpLink.text}
            onClick={handleNavClick}
        />
    );
};
