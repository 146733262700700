import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const TeamsIcon = (props: SvgProps) => {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4667 5.89343C12.4667 7.18468 11.4199 8.23145 10.1286 8.23145C8.83736 8.23145 7.79059 7.18468 7.79059 5.89343C7.79059 4.60217 8.83736 3.55539 10.1286 3.55539C11.4199 3.55539 12.4667 4.60217 12.4667 5.89343ZM11.4558 8.83564C12.576 8.32953 13.3556 7.20251 13.3556 5.89343C13.3556 4.11124 11.9108 2.6665 10.1286 2.6665C8.34644 2.6665 6.9017 4.11124 6.9017 5.89343C6.9017 7.20253 7.68124 8.32957 8.80145 8.83566C6.39367 9.44134 5.1884 11.6167 4.86097 12.7627C4.79353 12.9987 4.93019 13.2447 5.16621 13.3122C5.40222 13.3796 5.64822 13.2429 5.71565 13.0069C6.0499 11.8371 7.40707 9.56451 10.1287 9.56451C12.8503 9.56451 14.2075 11.8371 14.5417 13.0069C14.6092 13.2429 14.8551 13.3796 15.0912 13.3122C15.3272 13.2447 15.4638 12.9987 15.3964 12.7627C15.069 11.6166 13.8636 9.44129 11.4558 8.83564Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.83034 6.32218C4.83034 7.01938 4.26515 7.58457 3.56795 7.58457C2.87075 7.58457 2.30555 7.01938 2.30555 6.32218C2.30555 5.62498 2.87075 5.05979 3.56795 5.05979C4.26515 5.05979 4.83034 5.62498 4.83034 6.32218ZM4.62299 8.19742C5.27735 7.82848 5.71923 7.12693 5.71923 6.32218C5.71923 5.13406 4.75607 4.1709 3.56795 4.1709C2.37983 4.1709 1.41667 5.13406 1.41667 6.32218C1.41667 7.23007 1.97907 8.0066 2.77448 8.3224C2.10436 8.54458 1.56595 8.94441 1.15071 9.39573C0.52463 10.0762 0.163428 10.8872 0.0184603 11.4419C-0.0436117 11.6794 0.098586 11.9222 0.336068 11.9843C0.57355 12.0463 0.816386 11.9042 0.878458 11.6667C0.992443 11.2306 1.29156 10.5555 1.80486 9.99757C2.31029 9.44821 3.00762 9.02653 3.94429 9.02653C4.71564 9.02653 5.32468 9.3125 5.79791 9.72024C6.01892 9.5254 6.26103 9.34291 6.52511 9.17981C6.03734 8.71817 5.40677 8.33909 4.62299 8.19742Z"
                fill="black"
            />
        </svg>
    );
};
