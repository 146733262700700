import { useCallback } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { ProductAttributes } from '../../../config/constant';
import { useTrackExperimentClick } from '../../../hooks';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useActivePanel } from '../../../hooks/useActivePanel';
import { isGAProduct } from '../../TrackingEvents/Analytics';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { GA_EVENTS } from '../../TrackingEvents/GAEvents';
import { changeSizeStore } from '../store';
import { AutoRecognitionDataType } from '../types';
import { getAutoRecognitionCase, getSizeRanking } from '../utils';

export const useAutoRecognitionTracking = () => {
    const { sizeAttribute, autoRecognitionData } = changeSizeStore;
    const { productData, baseProductSelections } = useProductConfiguration();
    const { trackExperimentClick } = useTrackExperimentClick();
    const { fireEvent } = useCustomEventPropagation();
    const activePanel = useActivePanel();
    const panelName = activePanel?.panelProperties.name;

    const trackAutoRecognitionTriggered = ({
        autoRecognitionData,
    }: {
        autoRecognitionData: AutoRecognitionDataType;
    }) => {
        if (baseProductSelections) {
            trackExperimentClick({
                label: QUAD_TRACKING_EVENTS.AUTO_RECOGNITION_TRIGGERED,
                eventDetail: 'QUAD;QUAD;Auto Recognition',
                navigationDetail: 'Image Uploaded: Best Fit Generated',
                pageSection: PageSection.ChangeSize,
                pageStage: PageStage.Design,
                experimentDetail: {
                    autoRecognitionFlow: getAutoRecognitionCase(baseProductSelections, sizeAttribute),
                    initialSelection: {
                        [ProductAttributes.Orientation]:
                            baseProductSelections[ProductAttributes.Orientation] || undefined,
                        [sizeAttribute]: baseProductSelections[sizeAttribute] || undefined,
                    },
                    bestFits: autoRecognitionData.bestFits,
                    detectedOrientation: autoRecognitionData.detectedOrientation,
                },
            });
        }
    };

    const trackOpenChangeSizeModal = ({
        autoOpened,
        selectedOrientation,
        selectedSize,
    }: {
        autoOpened: boolean;
        selectedOrientation: string;
        selectedSize: string;
    }) => {
        fireEvent({
            name: ACTION_NAME.FLY_OUT_VIEWED,
            extraData: {
                label: GA_EVENTS.QUAD_CHANGE_SIZE_FLY_OUT,
                category: PageSection.FlyOut,
                eventDetail: `defaultSelection:${selectedOrientation}:${selectedSize}:${panelName}`,
                experimentDetail: {
                    autoOpened,
                },
            },
        });
    };

    const trackCloseChangeSizeModal = () => {
        fireEvent({
            name: ACTION_NAME.FLY_OUT_CLOSED,
            extraData: {
                label: GA_EVENTS.QUAD_CHANGE_SIZE_FLY_OUT,
                category: PageSection.FlyOut,
            },
        });
    };

    const trackChangeSize = useCallback(
        ({ selectedSize, selectedOrientation }: { selectedSize: string; selectedOrientation: string }) => {
            fireEvent({
                name: ACTION_NAME.FLY_OUT_CLICKED,
                extraData: {
                    label: GA_EVENTS.QUAD_CHANGE_SIZE_FLY_OUT,
                    eventDetail: `confirmSelection:${selectedOrientation}:${selectedSize}:${panelName}`,
                    category: PageSection.FlyOut,
                },
            });
        },
        [panelName]
    );

    const trackAutoRecognitionFinalSelection = useCallback(() => {
        if (baseProductSelections && productData) {
            const selectedSize = productData.customerSelectedOptions[sizeAttribute];
            const selectedSizeRank = getSizeRanking(autoRecognitionData, selectedSize);

            if (isGAProduct(productData.productKey)) {
                fireEvent({
                    name: ACTION_NAME.NAVIGATION_CLICKED,
                    extraData: {
                        navigationDetail: 'Moved To PDC',
                        label: 'Content:Text Link',
                    },
                });
            } else {
                trackExperimentClick({
                    label: QUAD_TRACKING_EVENTS.TRAVERSING_TO_PDC,
                    pageSection: PageSection.QuickUploadAndDesign,
                    pageStage: PageStage.Confirmation,
                    category: PageSection.QuickUploadAndDesign,
                    navigationDetail: 'Design Approval:1',
                    experimentDetail: {
                        autoRecognitionFlow: getAutoRecognitionCase(baseProductSelections, sizeAttribute),
                        initialSelection: {
                            [ProductAttributes.Orientation]:
                                baseProductSelections[ProductAttributes.Orientation] || undefined,
                            [sizeAttribute]: baseProductSelections[sizeAttribute] || undefined,
                        },
                        bestFits: autoRecognitionData?.bestFits,
                        detectedOrientation: autoRecognitionData?.detectedOrientation,
                        userSizeSelection: {
                            [ProductAttributes.Orientation]:
                                productData.customerSelectedOptions[ProductAttributes.Orientation],
                            [sizeAttribute]: selectedSize,
                        },
                        selectedSizeRank,
                    },
                });
            }
        }
    }, [baseProductSelections, autoRecognitionData, productData, sizeAttribute]);

    const trackSizeSelection = ({
        selectedSize,
        selectedOrientation,
    }: {
        selectedSize: string;
        selectedOrientation: string;
        userSelected: boolean;
    }) => {
        fireEvent({
            name: ACTION_NAME.FLY_OUT_CLICKED,
            extraData: {
                category: PageSection.FlyOut,
                label: GA_EVENTS.QUAD_CHANGE_SIZE_FLY_OUT,
                eventDetail: `userSelection:${selectedOrientation}:${selectedSize}:${panelName}`,
            },
        });
    };

    return {
        trackAutoRecognitionTriggered,
        trackOpenChangeSizeModal,
        trackCloseChangeSizeModal,
        trackChangeSize,
        trackAutoRecognitionFinalSelection,
        trackSizeSelection,
    };
};
