import { useCallback } from 'react';
import { CimDoc } from '@design-stack-vista/cdif-types';
import { getPurcsUrl } from '../../../../api/purcs';
import { useProductConfiguration } from '../../../../hooks/calcifer';
import { useProductParams } from '../../../../providers';
import { noticeError } from '../../../../utils';
import { getInstructionsUrl, getProductOptionsList } from '../utils';

export const useFlexibilityPreviews = () => {
    const { locale } = useProductParams();
    const { productData } = useProductConfiguration();

    const getFlexibilityPreviews = useCallback(
        async (cimDocs: CimDoc[], productAttributesList: Record<string, string>[], abortSignal?: AbortSignal) => {
            try {
                if (!productData) {
                    return [];
                }
                const instructionsUrls = cimDocs.map((cimDoc) => getInstructionsUrl(cimDoc));

                const productOptionsList = getProductOptionsList(productData, productAttributesList);

                const previewUrlPromises = productOptionsList.map((productOptions, index) =>
                    getPurcsUrl({
                        previewInstructionsUrl: instructionsUrls[index],
                        locale: locale,
                        productKey: productData.productKey,
                        productVersion: productData.productVersion,
                        optionSelections: productOptions,
                        signal: abortSignal,
                    })
                );

                const previewUrlsList = await Promise.all(previewUrlPromises);

                return previewUrlsList;
            } catch (error) {
                noticeError(error, {
                    method: 'getFlexibilityCimDocs - useFlexibilityCimDocs',
                });
                return [];
            }
        },
        [locale, productData]
    );

    return {
        getFlexibilityPreviews,
    };
};
