import React from 'react';
import { ZoomControls, ZoomControlsIn, ZoomControlsOut, ZoomControlsValue } from '@vp/swan';
import { text } from '../../utils/localization';

interface ZoomButtonsProps {
    onZoomIn: () => void;
    onZoomOut: () => void;
    zoomLevel: number;
}

export const ZoomControlButtons = ({ zoomLevel, onZoomIn, onZoomOut }: ZoomButtonsProps) => {
    return (
        <ZoomControls data-testid="2D-zoom-controls">
            <ZoomControlsIn accessibleText={text('ZoomIn')} onClick={onZoomIn} />
            <ZoomControlsOut accessibleText={text('ZoomOut')} onClick={onZoomOut} />
            <ZoomControlsValue accessibleText={text('CurrentZoom')}>{`${zoomLevel}%`}</ZoomControlsValue>
        </ZoomControls>
    );
};
