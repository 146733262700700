import React from 'react';
import { css } from '@emotion/css';
import { FlexBox } from '@vp/swan';
import { HorizontalSet } from './HorizontalSet';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';

const flexibilityOptionsStyle = css`
    width: max-content;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: start;
`;

interface FlexibilityOptionsProps {
    children: React.ReactNode | React.ReactNode[];
}

export const FlexibilityOptions = ({ children }: FlexibilityOptionsProps) => {
    const { isSmall } = useScreenLayout();

    return (
        <HorizontalSet>
            <FlexBox
                flexDirection={isSmall ? 'row' : 'column'}
                gap="4"
                className={isSmall ? flexibilityOptionsStyle : ''}
                data-testid="flexibility-options"
            >
                {children}
            </FlexBox>
        </HorizontalSet>
    );
};
