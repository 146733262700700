import { GA } from './constant';
import { AnalyticsPageData } from './type';

export const getAnalyticsPageData = (mvpId: string, trackingPage?: string | null): AnalyticsPageData => {
    const page = trackingPage ? `:${trackingPage}` : '';
    return {
        pageName: `${GA.PAGE_NAME.replace('[Product ID]', mvpId)}${page}`,
        pageSection: `${GA.PAGE_SECTION}${page}`,
        pageStage: GA.PAGE_STAGE,
        pageDept: GA.PAGE_DEPT,
    };
};
