import React from 'react';
import { css } from '@emotion/css';
import { tokens, Typography } from '@vp/swan';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';
import { text } from '../../../../utils/localization';
import { MobileExperience } from '../../../Experiences';

const getTeamsPromoteModalStepsDescItemStyle = (isSmall: boolean) => css`
    margin: ${isSmall ? 0 : tokens.SwanSemSpace4};
    margin-bottom: 0;
    text-align: center;
    gap: ${tokens.SwanSemSpace5};
    .promote-modal-image {
        margin: 0 auto;
        text-align: center;
        max-width: ${isSmall ? '143px' : '94px'};
        img {
            width: 100%;
        }
    }
    .promote-modal-desc {
        margin: 0 auto;
        margin-top: ${isSmall ? tokens.SwanSemSpace4 : tokens.SwanSemSpace5};
    }
`;
const TOTAL_STEPS = 3;
interface TeamsPromoteModalStepsDescProps {
    desc: string;
    step: number;
}

export const TeamsPromoteModalStepsDescItem = ({ desc, step }: TeamsPromoteModalStepsDescProps): JSX.Element => {
    const { isSmall } = useScreenLayout();
    return (
        <div className={getTeamsPromoteModalStepsDescItemStyle(isSmall)}>
            <div className="promote-modal-image">
                <img src={`${process.env.PUBLIC_URL}/images/teams-promote-${step}.png`} alt={desc} />
            </div>
            <MobileExperience>
                <Typography fontSize="small" className="promote-modal-desc">
                    {text('teamsPromoteStepsNumberActive', { step, totalSteps: TOTAL_STEPS })}
                </Typography>
            </MobileExperience>
            <Typography fontSize={isSmall ? 'standard' : 'small'} className="promote-modal-desc">
                {desc}
            </Typography>
        </div>
    );
};
