import React, { useEffect, useState } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { css } from '@emotion/css';
import { Button, FlexBox, SelectedValue, tokens, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { SubstrateColorPicker } from './SubstrateColorPicker';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';
import { StockInfo, useInitializeStockInfoStore } from '../../StockInfo';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { DESKTOP_SUBSTRATE_COLOR_SWATCHES } from '../constants';
import { useLoadNewSubstrateColor } from '../hooks';
import { substrateColorStore } from '../SubstrateColorStore';

const panelContainerStyle = css`
    margin-top: ${tokens.SwanSemSpace2};
`;

const selectedColorInfoStyle = css`
    span {
        margin-right: ${tokens.SwanSemSpace2};
        font-weight: ${tokens.SwanBaseFontWeightBold};
    }
`;

interface SubstrateColorPanelProps {
    renderedOnModalOpen?: boolean;
}

export const SubstrateColorPanel = observer(({ renderedOnModalOpen = false }: SubstrateColorPanelProps) => {
    const { isSmall } = useScreenLayout();
    const { productData } = useProductConfiguration();
    const { loadNewSubstrateColor } = useLoadNewSubstrateColor();
    const { setStockInfoByColor } = useInitializeStockInfoStore();
    const { substrateColorsWithStockInfo, substrateColorOptionName, setIsColorSwatchModalOpen } = substrateColorStore;
    const [selectedColor, setSelectedColor] = useState<string | undefined>(undefined);
    const { fireEvent } = useCustomEventPropagation();

    useEffect(() => {
        async function init() {
            const selectedColorOption =
                substrateColorOptionName && productData?.customerSelectedOptions
                    ? productData.customerSelectedOptions[substrateColorOptionName]
                    : undefined;
            setSelectedColor(selectedColorOption);
            if (selectedColorOption) {
                setStockInfoByColor(selectedColorOption);
            }
        }
        init();
    }, [substrateColorOptionName, productData?.customerSelectedOptions]);

    const onColorSwatchChange = async (newSelectedSubstrateColor: SelectedValue) => {
        fireEvent({
            name: ACTION_NAME.EXPERIMENT_CLICKED,
            extraData: {
                label: QUAD_TRACKING_EVENTS.CLICK_CHANGE_MATERIAL_COLOR,
                eventDetail: 'QUAD;QUAD;Change material color',
                navigationDetail: 'Material color:Change',
                pageSection: PageSection.MaterialColor,
                pageStage: PageStage.Design,
                category: undefined,
            },
        });
        if (isSmall) {
            const newSelectedColor = newSelectedSubstrateColor?.toString();
            setSelectedColor(newSelectedColor);
            if (newSelectedColor) {
                await setStockInfoByColor(newSelectedColor);
            }
        } else {
            await loadNewSubstrateColor(newSelectedSubstrateColor);
        }
    };

    const applyColorChange = async () => {
        if (selectedColor) {
            setIsColorSwatchModalOpen(false);
            await loadNewSubstrateColor(selectedColor);
        }
    };

    if (Object.entries(substrateColorsWithStockInfo).length === 0) {
        return null;
    }
    const extraColors = Object.entries(substrateColorsWithStockInfo).length - DESKTOP_SUBSTRATE_COLOR_SWATCHES;
    const showMoreColors = extraColors > 0 && !isSmall && !renderedOnModalOpen;
    const renderSubstrateColorsInfo = showMoreColors
        ? Object.assign(
              {},
              ...Object.entries(substrateColorsWithStockInfo)
                  .slice(0, DESKTOP_SUBSTRATE_COLOR_SWATCHES)
                  .map(([key, prop]) => ({ [key]: prop }))
          )
        : substrateColorsWithStockInfo;

    return selectedColor ? (
        <div className={panelContainerStyle}>
            <Typography mb={{ xs: '5', sm: '3' }} className={selectedColorInfoStyle}>
                <span>{renderedOnModalOpen ? `${text('selectedColor')}:` : `${text('materialColor')}:`}</span>
                {`${substrateColorsWithStockInfo[selectedColor].title}`}
            </Typography>
            <FlexBox alignItems="center" mb="5" gap="4">
                <SubstrateColorPicker
                    renderSubstrateColorsInfo={renderSubstrateColorsInfo}
                    onColorSwatchChange={onColorSwatchChange}
                    selectedColor={selectedColor}
                />
                {showMoreColors && (
                    <Button skin="unstyled" onClick={() => setIsColorSwatchModalOpen(true)}>{`+${extraColors}`}</Button>
                )}
            </FlexBox>
            <StockInfo />
            {isSmall && (
                <Button skin="primary" size="mini" width="full-width" onClick={applyColorChange}>
                    {text('apply')}
                </Button>
            )}
        </div>
    ) : null;
});
