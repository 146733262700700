import React from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { useVortexContext } from '@rendering/vortex-react';
import { FlexBox, FlexBoxProps, ToggleSwitch, ToggleSwitchOffLabel, ToggleSwitchOnLabel, Typography } from '@vp/swan';
import { useActivePanel } from '../../hooks/useActivePanel';
import { usePreview } from '../../providers/PreviewProvider';
import { text } from '../../utils/localization';
import { ACTION_NAME } from '../TrackingEvents/constant';

interface PreviewSwitcherProps extends FlexBoxProps {
    testIdSuffix?: string;
}

export const PreviewSwitcher = ({ testIdSuffix, ...rest }: PreviewSwitcherProps): JSX.Element => {
    const { is3DView, setIs3DView, is3DViewSupported } = usePreview();
    const { loadingModel, updatingCanvas } = useVortexContext();
    const { fireEvent } = useCustomEventPropagation();
    const activePanel = useActivePanel();

    const toggleThreeDClick = (activated: boolean) => {
        setIs3DView(activated);
        fireEvent({
            name: ACTION_NAME.DESIGN_TOOL_USED,
            extraData: {
                eventDetail: `toggle:previewDialog:${activated ? '3D' : '2D'}:${activePanel?.panelProperties.name}`,
            },
        });
    };

    return (
        <>
            {is3DViewSupported && (!(loadingModel || updatingCanvas) || (is3DViewSupported && !is3DView)) && (
                <FlexBox justifyContent="flex-start" alignItems="center" gap="3" {...rest}>
                    <ToggleSwitch
                        hideText
                        activated={is3DView}
                        data-testid={`vortex-preview-toggle-${testIdSuffix}`}
                        onRequestActivatedChange={toggleThreeDClick}
                        p="0"
                        m="0"
                    >
                        <ToggleSwitchOnLabel>{text('3dPreview')}</ToggleSwitchOnLabel>
                        <ToggleSwitchOffLabel>{text('2dPreview')}</ToggleSwitchOffLabel>
                    </ToggleSwitch>
                    <Typography fontSize="small" fontWeight="bold" className="switch-text">
                        {text('3dPreview')}
                    </Typography>
                </FlexBox>
            )}
        </>
    );
};
