import React from 'react';
import { addPageAction, noticeError } from '../utils/newRelicUtils';

type QUADErrorBoundaryProps = {
    children?: React.ReactNode;
    onComponentDidCatch?: (error: Error, errorInfo: React.ErrorInfo) => void;
};

type QUADErrorBoundaryState = {
    hasError: boolean;
    errorName: string;
    errorMsg: string;
    errorStack?: string;
    componentStack: string;
};

export class QUADErrorBoundary extends React.Component<QUADErrorBoundaryProps, QUADErrorBoundaryState> {
    constructor(props: QUADErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            errorName: '',
            errorMsg: '',
            errorStack: '',
            componentStack: '',
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState(
            {
                errorMsg: error.message,
                errorStack: error.stack,
                componentStack: errorInfo.componentStack ?? '',
                errorName: error.name,
            },
            () => {
                this.props.onComponentDidCatch?.(error, errorInfo);
            }
        );
        noticeError(error, errorInfo);
        addPageAction('quad-errorPage', {
            errorName: error.name,
            errorMsg: error.message,
            errorStack: error.stack,
            componentStack: errorInfo.componentStack,
        });
    }

    render() {
        if (this.state.hasError) {
            return null; // TODO: fallback ui implementation
        }
        return this.props.children;
    }
}
