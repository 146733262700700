import type { DSS, MCP } from '@vp/types-ddif';
import cloneDeep from 'lodash.clonedeep';

/**
 * Updates document panels to match the surface ids and names
 */
export function updateCimdocForPanelName(
    cimDoc: DSS.DesignDocument,
    views: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[]
) {
    const updatedCimDoc = cloneDeep(cimDoc);

    updatedCimDoc.document.panels = cimDoc.document.panels.map((panel, index) => {
        const view = views[index];
        return { ...panel, ...(view && { name: view.name }) };
    });

    return updatedCimDoc;
}
