import { createContext, useContext } from 'react';
import { ProductConfigurationStatus } from '../../config/constant';
import { UpdateProductConfig } from '../../features/Flexibility/common/types';
import { CalciferResponse } from '../../types/calcifer';
import { PriceDetails } from '../../types/price';
import { StudioSecondaryConfigResponse } from '../../types/secondaryConfigTypes';

export type ProductData = Omit<CalciferResponse, 'designVariations' | 'galleryConfig'>;

export type ProductConfigurationContextType = {
    productData?: ProductData;
    productConfigurationStatus?: ProductConfigurationStatus;
    workId: string;
    setWorkId: (workId: string) => void;
    updateCustomerSelectedOptions: (partialSelectedOptions: Record<string, string>) => void;
    updateProductConfiguration: (updateParams: UpdateProductConfig) => Promise<void>;
    secondaryConfig: StudioSecondaryConfigResponse | undefined;
    productPrice: PriceDetails | undefined;
    baseProductSelections: Record<string, string> | null;
};

export const ProductConfigurationContext = createContext<ProductConfigurationContextType | null>(null);

export const useProductConfiguration = () => {
    const value = useContext(ProductConfigurationContext);
    if (!value) {
        throw new Error('useProductConfiguration must be used within a ProductConfigurationProvider');
    }
    return value;
};
