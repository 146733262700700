import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens } from '@vp/swan';
import { BottomBarItem } from './BottomBarItem';
import { DesktopExperience } from '../../components/Experiences/DesktopExperience';
import { RedirectToStudio, RedirectToStudioPosition } from '../RedirectToStudio/RedirectToStudio';
import { CanvasZoomControls } from '../Zoom';

const bottomBarZoomStyle = css`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
`;

const bottomStyle = css`
    width: 100%;
    background-color: ${tokens.SwanBaseColorWhiteOpacity10};
    position: absolute;
    bottom: 0;
`;

export const BottomBar = () => {
    return (
        <DesktopExperience>
            <FlexBox p="6" className={bottomStyle} alignItems="center" justifyContent="center">
                <FlexBox p="6" className={bottomBarZoomStyle}>
                    <BottomBarItem>
                        <CanvasZoomControls />
                    </BottomBarItem>
                </FlexBox>
                <RedirectToStudio position={RedirectToStudioPosition.BOTTOM} />
            </FlexBox>
        </DesktopExperience>
    );
};
