/**
 * Studio reference
 * {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/f554885e27bded8974ec3648a69377d421f7f5ef/apps/studio/src/shared/features/Product/SubstrateColor/useGetSubstrateColorWithStockInfo.ts}
 */

import { useMemo } from 'react';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { useHasSubstrateColors } from './useHasSubstrateColors';
import { getSubstrateColorsWithStockInfoFromCalciferV2 } from '../../../api/calcifer';
import { ProductAttributes } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useProductParams } from '../../../providers';
import {
    mapDesignAttributeToProductOption,
    mapProductOptionValueToDesignAttributeValue,
} from '../../Flexibility/common';
import { substrateColorStore } from '../SubstrateColorStore';
import { SubstrateColorsWithStockInfo } from '../types';

export function useGetSubstrateColors() {
    const { locale } = useProductParams();
    const { productData, secondaryConfig } = useProductConfiguration();
    const { hasSubstrateColors } = useHasSubstrateColors();
    const { setSubstrateColorStore } = substrateColorStore;

    const substrateColorsProductDeps = useMemo(() => {
        return productData
            ? {
                  selectedOptions: productData.selectedOptions,
                  productKey: productData.productKey,
                  productVersion: productData.productVersion,
                  mpvId: productData.mpvId,
              }
            : undefined;
    }, [productData?.selectedOptions, productData?.productKey, productData?.productVersion, productData?.mpvId]);

    const designAttributeMappings = secondaryConfig?.designAttributes || [];

    useAsyncEffect(
        (helper) => {
            async function initializeSubstrateStore() {
                if (substrateColorsProductDeps && hasSubstrateColors) {
                    const substrateColorOptionName = mapDesignAttributeToProductOption(
                        designAttributeMappings,
                        ProductAttributes.SubstrateColor
                    );
                    const substrateColorsWithStockInfo =
                        await getSubstrateColorsWithStockInfoFromCalciferV2<SubstrateColorsWithStockInfo>(
                            substrateColorsProductDeps.mpvId,
                            locale,
                            substrateColorsProductDeps.productKey,
                            substrateColorsProductDeps.productVersion,
                            substrateColorsProductDeps.selectedOptions,
                            substrateColorOptionName,
                            helper.abortSignal
                        );

                    const mutatedColors: SubstrateColorsWithStockInfo = {};
                    if (substrateColorsWithStockInfo) {
                        Object.entries(substrateColorsWithStockInfo)?.forEach(([key, value]) => {
                            const convertedPrimaryColor = mapProductOptionValueToDesignAttributeValue(
                                designAttributeMappings,
                                substrateColorOptionName,
                                value.primaryColor
                            );
                            let convertedSecondaryColor = '';
                            if (value.secondaryColor) {
                                convertedSecondaryColor = mapProductOptionValueToDesignAttributeValue(
                                    designAttributeMappings,
                                    substrateColorOptionName,
                                    value.secondaryColor
                                );
                            }
                            mutatedColors[key] = {
                                ...value,
                                primaryColor: convertedPrimaryColor,
                                secondaryColor: convertedSecondaryColor,
                            };
                        });
                    }
                    setSubstrateColorStore({ substrateColorOptionName, substrateColorsWithStockInfo: mutatedColors });
                }
            }
            initializeSubstrateStore();
        },
        [hasSubstrateColors, substrateColorsProductDeps, locale]
    );
}
