import { useDesignEngine } from '@design-stack-vista/core-features';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { useTrackExperimentClick } from './useTrackExperimentClick';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../features/TrackingEvents/constant';
import { GA_EVENTS } from '../features/TrackingEvents/GAEvents';
import {
    addPageAction,
    AssetMetaData,
    extractAssetMetaData,
    getMediaAndFileTypesForMultipleUpload,
    getMimeType,
} from '../utils';

export const useUploadTracking = () => {
    const {
        layoutStore: { visiblePanels },
    } = useDesignEngine();
    const { fireEvent } = useCustomEventPropagation();
    const { trackExperimentClick } = useTrackExperimentClick();

    const activePanel = visiblePanels[0];
    const panelId = activePanel.id;
    const panelName = activePanel.panelProperties.name;

    const trackUploadStart = (metadata: AssetMetaData) => {
        trackExperimentClick({
            label: QUAD_TRACKING_EVENTS.UPLOAD_ASSETS_STARTED,
            pageSection: PageSection.AssetContainer,
            eventDetail: 'QUAD;QUAD;Upload Modal;Upload Button;Upload Start',
            navigationDetail: 'Upload Modal:Upload Button',
            pageStage: PageStage.AssetUpload,
            experimentDetail: {
                productSurfaceId: panelId,
                fileType: metadata.fileType,
                size: metadata.size,
                uniqueAssetId: metadata.uniqueAssetId,
                side: panelName,
            },
        });
    };

    const trackUploadCompleted = (metadata: AssetMetaData) => {
        if (metadata.fileType) {
            const assetContentType = metadata.fileType.split('/');

            const eventDetail = GA_EVENTS.UPLOAD_MODAL_PANEL_SUCCESS.replace('[MEDIATYPE]', assetContentType[0])
                .replace('[CANVAS FACE]', panelName)
                .replace('[FILETYPE]', assetContentType[1]);

            fireEvent({
                name: ACTION_NAME.DESIGN_TOOL_USED,
                extraData: {
                    experimentLabel: QUAD_TRACKING_EVENTS.UPLOAD_ASSETS_COMPLETED,
                    eventDetail,
                    pageSection: PageSection.AssetContainer,
                    pageStage: PageStage.AssetUpload,
                    experimentDetail: {
                        productSurfaceId: panelId,
                        ...metadata,
                        side: panelName,
                    },
                },
            });
        }
    };

    // log upload completion data to new relic
    const logUploadComplete = (metadata: AssetMetaData) => {
        addPageAction('upload-completed', {
            label: 'General',
            pageSection: PageSection.AssetContainer,
            productSurfaceId: panelId,
            ...metadata,
            side: panelName,
        });
    };

    const trackAssetUpload = async (uploads: Promise<void | VistaAsset>[]) => {
        const assets = await Promise.all(uploads);

        assets.forEach((asset) => {
            if (asset) {
                const metadata = extractAssetMetaData(asset);

                trackUploadStart(metadata);
                trackUploadCompleted(metadata);
                logUploadComplete(metadata);
            }
        });
    };

    const trackMultipleUpload = async (uploads: Promise<void | VistaAsset>[]) => {
        const assets = await Promise.all(uploads);
        const { media, files } = getMediaAndFileTypesForMultipleUpload(assets);

        trackExperimentClick({
            label: QUAD_TRACKING_EVENTS.UPLOAD_MUlTIPLE_ASSET,
            eventDetail: `QUAD;QUAD;Upload Modal;Upload Button;Multi file Upload;${files}`,
            navigationDetail: 'Upload Modal:Upload Button',
            pageSection: PageSection.AssetContainer,
            pageStage: PageStage.AssetUpload,
            category: undefined,
            experimentDetail: {
                mediaType: media,
                fileTypes: files,
            },
        });
    };

    const trackRecentlyUploadedAsset = (asset: VistaAsset) => {
        const contentType = getMimeType(asset);

        if (contentType) {
            const [mediaType, fileType] = contentType.split('/');

            const trackerName = QUAD_TRACKING_EVENTS.CLICK_UPLOAD_BUTTON_DRAWER.replace('${PANEL_NAME}', panelName);

            const eventDetail = GA_EVENTS.UPLOAD_MODAL_PANEL_RECENT.replace('[MEDIATYPE]', mediaType)
                .replace('[CANVAS FACE]', panelName)
                .replace('[FILETYPE]', fileType);

            fireEvent({
                name: ACTION_NAME.DESIGN_TOOL_USED,
                extraData: {
                    experimentLabel: trackerName,
                    eventDetail,
                    pageSection: PageSection.AssetContainer,
                    pageStage: PageStage.Discover,
                    experimentDetail: {
                        productSurfaceId: panelId,
                        side: panelName,
                    },
                },
            });
        }
    };

    const trackUploadButtonClick = () => {
        const eventName = QUAD_TRACKING_EVENTS.CLICK_MODAL_UPLOAD_BUTTON.replace('${PANEL_NAME}', panelName);

        fireEvent({
            name: ACTION_NAME.DESIGN_TOOL_USED,
            extraData: {
                experimentLabel: eventName,
                pageSection: PageSection.AssetContainer,
                pageStage: PageStage.Discover,
                eventDetail: GA_EVENTS.CLICK_MODAL_PANEL.replace('[CANVAS FACE]', panelName),
                experimentDetail: {
                    productSurfaceId: panelId,
                    side: panelName,
                },
            },
        });
    };

    return {
        trackMultipleUpload,
        trackAssetUpload,
        trackRecentlyUploadedAsset,
        trackUploadButtonClick,
    };
};
