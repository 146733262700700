import React from 'react';
import { SvgProps } from '../../features/quad/types';

export function MailingIcon(props: SvgProps) {
    return (
        <svg viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M6 16.7385V21.6616C6 22.2139 6.44771 22.6616 7 22.6616H8.55385C9.10613 22.6616 9.55385 22.2139 9.55385 21.6616V16.7385"
                stroke="currentColor"
            />
            <path
                d="M21 17H2C1.44772 17 1 16.5523 1 16V10C1 7.79088 2.79086 6.00002 5 6.00002H17C19.2091 6.00002 21 7.79088 21 10V17Z"
                stroke="currentColor"
            />
            <circle cx="7.23076" cy="10.3078" r="1.69231" stroke="currentColor" />
            <path d="M14.875 21L13 17H21.125L23 21H14.875Z" stroke="currentColor" />
            <path
                d="M3 2.26923C3 1.56825 3.56825 1 4.26923 1H6.84615C7.05857 1 7.23077 1.1722 7.23077 1.38462V3.15385C7.23077 3.36626 7.05857 3.53846 6.84615 3.53846H4.26923C3.56825 3.53846 3 2.97021 3 2.26923V2.26923Z"
                stroke="currentColor"
            />
            <path d="M7.23084 1.63452L7.23083 7.98068" stroke="currentColor" />
            <path d="M21 17H13V10C13 7.79086 14.7909 6 17 6C19.2091 6 21 7.79086 21 10V17Z" stroke="currentColor" />
        </svg>
    );
}

MailingIcon.displayName = 'MailingIcon';
