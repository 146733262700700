import { isSherbertAssetUrl } from '@design-stack-ct/assets-sdk';
import { addImage, PanelState, removeItems } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { PanelSides } from '../../../config/constant';
import { useUpsellPrice } from '../../../providers';
import {
    buildImageAttributes,
    buildLocalImage,
    generateImageUrls,
    getMimeType,
    getPanelItemIds,
    getUnitLessImageDimensions,
    getUnitLessSherbertImageDimensions,
    getUnzoomedPanelDimensions,
    noticeError,
} from '../../../utils';
import { ColorMode } from '../../../utils/upsell';
import { ImageInstantUploadExtension } from '../../InstantUpload';
import { getPanelByName } from '../../UploadPanel/components/UploadBackDesignModal/helper';
import {
    addOrUpdateMetadataDocumentSources,
    removeMetadataDocumentSources,
    removeTemplateAndPlaceholderMetaData,
} from '../commands/addPanelMetadata';
import { updatePanelColorMode } from '../commands/updatePanelColorMode';

interface AddImage {
    (panel: PanelState, asset: VistaAsset, pageNum?: number): Promise<void>;
}

interface RemoveItems {
    (panel: PanelState): void;
}

interface InstantUpload {
    (panel: PanelState, asset: Promise<void | VistaAsset>, rawFile: File): Promise<void>;
}

interface UseAssets {
    onAddImage: AddImage;
    onInstantUpload: InstantUpload;
    onRemoveItems: RemoveItems;
}

export const useUploadAsset = (): UseAssets => {
    const {
        cimDocStore: { panels },
        executeCommand,
        designExtensionSystem,
    } = useDesignEngine();
    const { isUpsellPanelAdded } = useUpsellPrice();
    const backPanel = getPanelByName(panels, PanelSides.Back);

    const onAddImage: AddImage = async (panel, asset, pageNum = 1) => {
        try {
            const panelId = panel.id;
            const panelUnitLessDimensions = getUnzoomedPanelDimensions(panel);

            // Get image urls and image unitless dimensions
            const imageUrls = await generateImageUrls(asset, pageNum);
            const imageUnitLessDimensions = isSherbertAssetUrl(imageUrls.preview)
                ? await getUnitLessSherbertImageDimensions(asset, pageNum)
                : await getUnitLessImageDimensions(imageUrls.preview);

            // build image attributes to be added
            const imageAttributes = buildImageAttributes(
                panelUnitLessDimensions,
                imageUnitLessDimensions,
                imageUrls,
                pageNum
            );

            const updateColorMode = panel.asJson.colorMode === ColorMode.Blank;
            const mimeType = getMimeType(asset);

            // add image to designEngine and update colorMode and metadata if required
            executeCommand((cimDoc) => {
                if (updateColorMode) {
                    updatePanelColorMode(cimDoc, { panelId, colorMode: ColorMode.Color });
                    addOrUpdateMetadataDocumentSources(cimDoc, { panelId, sourceType: 'FULLBLEED' });
                }
                if (panel.items.length) {
                    const itemIds = getPanelItemIds(panel);
                    removeItems(cimDoc, { ids: itemIds });
                }
                removeTemplateAndPlaceholderMetaData(cimDoc);
                addImage(cimDoc, {
                    panelId,
                    image: imageAttributes,
                    mimeType,
                });
            }, {});

            return;
        } catch (error) {
            noticeError(error, {
                method: 'useUploadAsset - onAddImage',
            });
            return;
        }
    };

    const onInstantUpload: InstantUpload = async (panel, asset, rawFile) => {
        try {
            const panelId = panel.id;
            const panelUnitLessDimensions = getUnzoomedPanelDimensions(panel);

            // build image attributes from raw file for Instant Upload
            const { imageUrls, imageUnitLessDimensions } = await buildLocalImage(rawFile);

            // build image attributes to be added
            const imageAttributes = buildImageAttributes(panelUnitLessDimensions, imageUnitLessDimensions, imageUrls);

            const updateColorMode = panel.asJson.colorMode === ColorMode.Blank;
            const mimeType = rawFile.type;

            // add image to designEngine and update colorMode and metadata if required
            const { commandOutput, historyEvent } = executeCommand((cimDoc) => {
                if (updateColorMode) {
                    updatePanelColorMode(cimDoc, { panelId, colorMode: ColorMode.Color });
                    addOrUpdateMetadataDocumentSources(cimDoc, { panelId, sourceType: 'FULLBLEED' });
                }
                if (panel.items.length) {
                    const itemIds = getPanelItemIds(panel);
                    removeItems(cimDoc, { ids: itemIds });
                }
                removeTemplateAndPlaceholderMetaData(cimDoc);
                return addImage(cimDoc, {
                    panelId,
                    image: imageAttributes,
                    mimeType,
                });
            }, {});

            const itemId = commandOutput.id;
            const eventId = historyEvent.id;

            const instantUploadExtension = designExtensionSystem.getExtension(
                `${panelId}-${itemId}`,
                ImageInstantUploadExtension
            );

            instantUploadExtension?.setUpload(asset, { eventId, temporaryUrl: imageAttributes.previewUrl });
        } catch (error) {
            noticeError(error, {
                method: 'useUploadAsset - onInstantUpload',
            });
            return;
        }
    };

    const onRemoveItems: RemoveItems = (panel) => {
        try {
            const panelId = panel.id;
            const updateMetaDataAndColorMode = isUpsellPanelAdded(panelId);

            // update metadata and colorMode if required removed and remove image
            executeCommand((cimDoc) => {
                if (updateMetaDataAndColorMode) {
                    updatePanelColorMode(cimDoc, { panelId, colorMode: ColorMode.Blank });
                    removeMetadataDocumentSources(cimDoc, { panelId });
                }
                if (panel.items.length) {
                    const itemIds = getPanelItemIds(panel);
                    removeItems(cimDoc, { ids: itemIds });
                }
                if (backPanel?.id === panelId) {
                    removeTemplateAndPlaceholderMetaData(cimDoc);
                }
            }, {});

            return;
        } catch (error) {
            noticeError(error, {
                method: 'useUploadAsset - onRemoveItem',
            });
            return;
        }
    };

    return {
        onAddImage,
        onInstantUpload,
        onRemoveItems,
    };
};
