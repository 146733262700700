import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { SizeItem } from './SizeItem';
import { useProductOptionsTracking } from '../../../providers';
import { FlexibilityOptions } from '../../Flexibility/common';
import { useAutoRecognitionTracking } from '../hooks';
import { changeSizeStore } from '../store';

export const Sizes = observer(() => {
    const productOptionsTracking = useProductOptionsTracking();
    const { trackSizeSelection } = useAutoRecognitionTracking();
    const { sizeAttribute, sizesList, activeSize, setActiveSize, activeOrientation } = changeSizeStore;

    const onClick = useCallback(
        (size: string) => {
            setActiveSize(size);
            trackSizeSelection({
                selectedOrientation: activeOrientation,
                selectedSize: size,
                userSelected: true,
            });

            productOptionsTracking.trackAttributeSelected(sizeAttribute, size);
        },
        [trackSizeSelection, activeOrientation, sizeAttribute]
    );

    return (
        <FlexibilityOptions>
            <>
                {sizesList.map((size) => (
                    <SizeItem size={size} activeSize={activeSize} key={size} onClick={onClick} />
                ))}
            </>
        </FlexibilityOptions>
    );
});
