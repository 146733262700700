import { action, makeObservable, observable } from 'mobx';
import { ChoiceGroupPriceDetails } from '../../../types/price';
import { ProductChoiceGroups } from '../../Flexibility/common';

interface DecorationAreaData {
    decorationAreaAttribute: string;
    decorationAreaChoiceGroups: ProductChoiceGroups;
}

class DecorationAreaStore {
    @observable activeDecorationArea: string | null = null;
    @observable decorationAreaAttribute: string | null = null;
    @observable isDecorationAreaFlexible = false;
    @observable isDecorationAreaModalOpen = false;
    @observable.struct decorationAreaPrices: ChoiceGroupPriceDetails = {};
    @observable.struct decorationAreaChoiceGroups: ProductChoiceGroups = {};
    @observable.struct decorationAreaList: string[] = [];

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setDecorationAreaData({ decorationAreaAttribute, decorationAreaChoiceGroups }: DecorationAreaData) {
        const decorationAreaChoiceGroupsKeys = Object.keys(decorationAreaChoiceGroups);
        this.decorationAreaAttribute = decorationAreaAttribute;
        this.decorationAreaChoiceGroups = decorationAreaChoiceGroups;
        this.isDecorationAreaFlexible = decorationAreaChoiceGroupsKeys.length >= 2;
        this.decorationAreaList = decorationAreaChoiceGroupsKeys;
    }

    @action.bound
    setDecorationAreaPrices(decorationAreaPrices: ChoiceGroupPriceDetails) {
        this.decorationAreaPrices = decorationAreaPrices;
    }

    @action.bound
    resetDecorationAreaStore() {
        this.activeDecorationArea = null;
        this.isDecorationAreaFlexible = false;
        this.decorationAreaChoiceGroups = {};
        this.decorationAreaPrices = {};
    }

    @action.bound
    setOpenDecorationAreaModal(state: boolean) {
        this.isDecorationAreaModalOpen = state;
    }

    @action.bound
    setActiveDecorationArea(decorationArea: string) {
        this.activeDecorationArea = decorationArea;
    }
}

export const decorationAreaStore = new DecorationAreaStore();
