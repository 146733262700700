import { CimDoc } from '@design-stack-vista/cdif-types';
import { MCP } from '@vp/types-ddif';
import qs from 'qs';
import { REQUESTOR } from '../config/constant';
import { fetchWrapper } from '../utils';

// document link - https://developer.cimpress.io/software-catalog/component/api/18051173-5e0c-5ec7-9089-39074136b808/openapi?env=production
export const DESIGN_SPEC_SERVICE_URL = 'https://design-specifications.design.vpsvc.com';
export const MAX_DIMENSION_TOLERANCE = 1000000;

export interface DesignViews {
    designViews: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[];
}

export function generateDesignViewsURL(
    productKey: string,
    productVersion: number,
    optionSelections: Record<string, string>,
    locale: string
): string {
    const queryString = qs.stringify({
        requester: REQUESTOR,
        optionSelections,
        culture: locale,
    });

    return `${DESIGN_SPEC_SERVICE_URL}/v2/designViews/products/${productKey}/${productVersion}?${queryString}`;
}

export const getDesignViews = async (
    locale: string,
    productKey: string,
    productVersion: number,
    productOption: Record<string, string>
): Promise<DesignViews | undefined> => {
    const url = generateDesignViewsURL(productKey, productVersion, productOption, locale);

    return await fetchWrapper(url, {
        method: 'GET',
        requestInitiator: 'getDesignViews',
    });
};

export const updateDocumentDimensions = async ({
    productKey,
    productVersion,
    selectedOptions,
    designDocument,
    locale,
    dimensionTolerancePercentage = MAX_DIMENSION_TOLERANCE,
    ignoreSavedPanels = false,
}: {
    productKey: string;
    productVersion: string | number;
    selectedOptions: Record<string, string>;
    designDocument: CimDoc;
    locale: string;
    dimensionTolerancePercentage?: number;
    ignoreSavedPanels?: boolean;
}) => {
    const queryString = qs.stringify({
        optionSelections: selectedOptions,
        dimensionTolerancePercentage,
        culture: locale,
        requestor: REQUESTOR,
        ignoreSavedPanels,
    });
    const body = {
        designDocument,
    };

    const url = `${DESIGN_SPEC_SERVICE_URL}/v2/designDocuments/products/${productKey}/${productVersion}?${queryString}`;

    return await fetchWrapper(url, {
        method: 'PUT',
        body,
        requestInitiator: 'updateDocumentDimensions',
    });
};
