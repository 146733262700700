import React from 'react';
import { DiscountPrice, FlexBox, ListPrice, PricingComparison, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { text } from '../../../utils/localization';
import { decorationAreaStore } from '../store';

interface DecorationAreaPricingProps {
    decorationArea: string;
}

export const DecorationAreaPricing = observer(({ decorationArea }: DecorationAreaPricingProps) => {
    const { productData } = useProductConfiguration();
    const { decorationAreaPrices } = decorationAreaStore;
    const decorationAreaPrice = decorationAreaPrices[decorationArea];

    if (!productData || !decorationAreaPrice) {
        return null;
    }

    const { isPriceDiscounted, totalListPrice, totalDiscountPrice } = decorationAreaPrice;

    return (
        <FlexBox flexDirection="row">
            <Typography>{text('quantity', { quantity: productData.quantity })}</Typography>
            {isPriceDiscounted ? (
                <PricingComparison>
                    <ListPrice mx="2">{totalListPrice}</ListPrice>
                    <DiscountPrice>{totalDiscountPrice}</DiscountPrice>
                </PricingComparison>
            ) : (
                <ListPrice mx="2">{totalListPrice}</ListPrice>
            )}
        </FlexBox>
    );
});
