import React from 'react';
import { css } from '@emotion/css';
import { Button, tokens, Typography } from '@vp/swan';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';
import { useSaveWork } from '../../../../providers/SaveWorkProvider';

interface SaveButtonProps {
    buttonLabel: string;
    handleClick?: () => void;
}

const getSaveButtonStyle = (isSmall: boolean) => css`
    &.swan-button {
        &:focus {
            outline: 1px solid ${tokens.SwanBaseColorBlack};
            outline-offset: 1px;
        }
        &.swan-button-mini {
            margin: 0 20px;
            padding: ${tokens.SwanSemSpace1};
            border-radius: 0;
            border: none;
            ${isSmall && `margin: 0 ;`}
        }
        &.swan-button[aria-disabled='true'] {
            background: none;
            border: none;
        }
    }
`;

const getSaveButtonIconStyle = (buttonVisibilityCondition: boolean) => css`
    margin: 0 ${tokens.SwanSemSpace2} 0 0;
    width: 9px;
    height: 9px;
    border-radius: 50px;
    background-color: ${tokens.SwanSemColorIconSuccess};
    ${buttonVisibilityCondition &&
    ` background-color: ${tokens.SwanSemColorIconWarning};
    `}
`;

const getSaveButtonTextStyle = (buttonVisibilityCondition: boolean) => css`
    font-weight: normal;
    text-decoration-line: none;
    ${buttonVisibilityCondition &&
    ` text-decoration-line: underline;
    `}
`;

export function SaveChangesButton({ buttonLabel, handleClick }: SaveButtonProps) {
    const { isWorkSaved } = useSaveWork();
    const { isSmall } = useScreenLayout();
    const buttonVisibilityCondition = !isWorkSaved;

    return (
        <Button
            size={'mini'}
            component={handleClick ? 'button' : 'span'}
            aria-disabled={!handleClick}
            onClick={handleClick}
            className={getSaveButtonStyle(isSmall)}
        >
            <div className={getSaveButtonIconStyle(buttonVisibilityCondition)}></div>
            <Typography className={getSaveButtonTextStyle(buttonVisibilityCondition)}>{buttonLabel}</Typography>
        </Button>
    );
}
SaveChangesButton.displayName = 'SaveChangesButton';
