import { CimDoc } from '@design-stack-vista/cdif-types/v2';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import {
    DesignLayerItemVisibilityExtension,
    IDAItemLayoutExtension,
    ItemAspectRatioLockExtension,
    ItemMoveExtension,
    ItemPreviewExtension,
    PanelLayoutExtension,
    PanelOrnamentsExtension,
    PanelPreviewInstructionsExtension,
    RenderingBoundItemExtension,
    SnappingExtension,
} from '@design-stack-vista/core-features';
import {
    BaseExtensionClassInterface,
    DesignExtensionSystem,
    InteractiveDesignEngine,
    ItemLocksExtension,
    ItemMetadataExtension,
    ItemPermissionsExtension,
    ItemProcessingExtension,
    ItemSelectionExtension,
    ItemTemplateExtension,
    Margins,
} from '@design-stack-vista/interactive-design-engine-core';
import { MultiPanelPreviewExtension } from '../extensions/multiPanelPreviewExtension';
import { ImageInstantUploadExtension } from '../features/InstantUpload';
import { PanelChromesExtension } from '../features/quad/Extensions';
import { BetweenBoundValidationExtension, LowResolutionExtension } from '../features/quad/Validations';

interface IQUADEngine {
    cimdoc: CimDoc;
    authHeader: string;
    screenClass: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deps: Record<string, any>;
    initialPanel: string;
}

/**
 * List all all the extensions needed to create QUAD design engine
 */
const extensions = [
    DesignLayerItemVisibilityExtension,
    IDAItemLayoutExtension,
    ItemMetadataExtension,
    ItemPreviewExtension,
    ItemSelectionExtension,
    ItemTemplateExtension,
    PanelLayoutExtension,
    ItemPermissionsExtension,
    ItemLocksExtension,
    PanelPreviewInstructionsExtension,
    RenderingBoundItemExtension,
    ItemAspectRatioLockExtension,
    ItemMoveExtension,
    SnappingExtension,
    PanelOrnamentsExtension,
    BetweenBoundValidationExtension,
    LowResolutionExtension,
    PanelChromesExtension,
    ImageInstantUploadExtension,
    ItemProcessingExtension,
    MultiPanelPreviewExtension,
];

/**
 * Design Engine panel margins acc. to screen size
 */
const panelMarginBreakpoints: Record<string, Margins> = {
    xs: { bottom: 158, left: 16, right: 16, top: 64 },
    sm: { bottom: 134, left: 64, right: 64, top: 134 },
    md: { bottom: 134, left: 64, right: 64, top: 134 },
    lg: { bottom: 134, left: 128, right: 128, top: 134 },
    xl: { bottom: 134, left: 128, right: 128, top: 134 },
};

/**
 *
 * @param designExtensionSystem
 * @param extensions
 */
function addExtensionsToDesignEngine(
    designExtensionSystem: DesignExtensionSystem,
    extensions: BaseExtensionClassInterface[]
) {
    extensions.forEach((extension) => {
        designExtensionSystem.addExtension(extension);
    });
}

export function cleanUpDesignEngineExtensions(designEngine: InteractiveDesignEngine) {
    extensions.forEach((extension) => {
        designEngine.designExtensionSystem.removeExtension(extension);
    });
}

/**
 *
 * @param designExtensionSystem Object of DesignExtensionSystem of InteractiveDesignEngine
 * @param dependencies - Dependencies that are need by the extensions
 */
function injectDependenciesToDesignEngine(
    designExtensionSystem: DesignExtensionSystem,
    dependencies: { [key: string]: unknown }
) {
    Object.keys(dependencies).map((dependencyKey) => {
        designExtensionSystem.injector.provide(dependencyKey, dependencies[dependencyKey]);
    });
}

function getPanelMargin({ panels, screenClass }: { panels: Array<PanelState>; screenClass: string }) {
    if (panels.length < 2) {
        // panel switcher is taking 50px and there is an extra margin of 16px at the bottom of the panel switcher,
        // releasing 50px + 16px = 66px from 158px when panel switcher is not present for single sided product
        panelMarginBreakpoints['xs'].bottom = 92;
    }
    return [panelMarginBreakpoints[screenClass]];
}

export const createQUADDesignEngine = ({ authHeader, cimdoc, screenClass, deps, initialPanel }: IQUADEngine) => {
    const headers = {
        Authorization: authHeader,
    };
    const designEngine = new InteractiveDesignEngine({
        authProvider: () => Promise.resolve(headers),
        cimDoc: cimdoc,
    });

    /**
     * Dependencies that are needed by the extensions
     */
    const dependencies = { ...deps };

    // Injecting dependencies before adding extensions
    injectDependenciesToDesignEngine(designEngine.designExtensionSystem, dependencies);

    addExtensionsToDesignEngine(designEngine.designExtensionSystem, extensions);

    designEngine.layoutStore.setPanelLayoutDefinition({
        type: 'calculated',
        mode: 'vertical',
        panelMargins: getPanelMargin({ panels: designEngine.cimDocStore.panels, screenClass }),
    });

    // Setting default Visible Panel to the inital Panel.
    const initialPanelId = designEngine.cimDocStore.panels[Number(initialPanel) - 1].id;
    designEngine.layoutStore.setVisiblePanelIds([initialPanelId]);
    designEngine.idaStore.setActiveDesignPanelId(initialPanelId);

    return designEngine;
};
