import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LandingPage } from './components/LandingPage';

const APP_BASE_PATH = process.env.REACT_APP_ROUTER_BASENAME || '';
const cb = (
    window as Window &
        typeof globalThis & { configureNewrelic?: (accountId: string, appId: string, appKey: string) => void }
).configureNewrelic;
process.env.REACT_APP_NR_APP_ID &&
    process.env.REACT_APP_NR_ACCOUNT_ID &&
    process.env.REACT_APP_NR_LICENSE_KEY &&
    cb &&
    cb(process.env.REACT_APP_NR_ACCOUNT_ID, process.env.REACT_APP_NR_APP_ID, process.env.REACT_APP_NR_LICENSE_KEY);

export const Router = () => {
    return (
        <BrowserRouter basename={APP_BASE_PATH}>
            <Switch>
                <Route path="/">
                    <LandingPage />
                </Route>
            </Switch>
        </BrowserRouter>
    );
};
