import { parseAssetUrl } from '@design-stack-ct/assets-sdk';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { getImageURLFromPanel } from '../../../utils';
import { ImageAsset } from '../types';

const isSupportWebPreview = (variants: string[]) => {
    return variants.some((variant) => variant === 'webPreview');
};

export const getAssetFromPanelImg = (panel: PanelState): ImageAsset | null => {
    const imageURL = getImageURLFromPanel(panel);
    if (imageURL) {
        const { id: assetId, variants } = parseAssetUrl(imageURL);
        if (assetId && variants && isSupportWebPreview(variants)) {
            return { assetId: `${assetId}/webPreview`, imageURL };
        }
        return {
            assetId,
            imageURL,
        };
    }

    return null;
};
