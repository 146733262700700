import { useActivePanel } from './useActivePanel';
import { useTrackExperimentClick } from './useTrackExperimentClick';
import { useUploadPanels } from './useUploadPanel';
import { useBackDesignTemplate } from '../features/BackDesignTemplate';
import { PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../features/TrackingEvents/constant';

export function useDeletePanelItems() {
    const { onRemoveItemFromPanel } = useUploadPanels();
    const { resetTemplatePreviewsAndColors } = useBackDesignTemplate();
    const { trackExperimentClick } = useTrackExperimentClick();
    const panel = useActivePanel();

    const deletePanelItems = () => {
        if (panel && panel.items.length > 0) {
            onRemoveItemFromPanel();
            resetTemplatePreviewsAndColors();
            trackExperimentClick({
                label: QUAD_TRACKING_EVENTS.CLICK_KEYBOARD_DELETE,
                eventDetail: 'QUAD;QUAD;Keyboard delete button',
                navigationDetail: 'Keyboard delete button',
                pageSection: PageSection.KeyboardDeleteButton,
                pageStage: PageStage.Design,
                experimentDetail: {
                    panelId: panel?.id,
                    panelName: panel?.panelProperties.name,
                },
            });
        }
    };

    return {
        isDeleteable: !!panel && panel.items.length > 0,
        deletePanelItems,
    };
}
