import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens, Typography } from '@vp/swan';
import { AdjustContrastColorPicker } from './AdjustContrastColorPicker';
import { AdjustContrastInvertColor } from './AdjustContrastInvertColor';
import { DesktopExperience, MobileExperience } from '../../../components/Experiences';
import { text } from '../../../utils/localization';

const desktopAdjustContrastAdjustColor = css`
    justify-content: center;
    width: 100%;
    gap: ${tokens.SwanSemSpace4};
    margin-bottom: ${tokens.SwanSemSpace5};
    .swan-button {
        &.swan-button-mini {
            padding: 0 ${tokens.SwanSemSpace3};
            &.swan-button-with-icon-left {
                svg {
                    margin-right: ${tokens.SwanSemSpace2};
                }
            }
        }
    }
`;

const mobileAdjustContrastAdjustColor = css`
    justify-content: space-between;
    font-size: ${tokens.SwanSemFontSizeSmall};
    padding: 0 ${tokens.SwanSemSpace4};
    margin-bottom: calc(${tokens.SwanSemSpace2} * -1);
`;

export const AdjustContrastColor = (): JSX.Element => {
    return (
        <>
            <DesktopExperience>
                <FlexBox className={desktopAdjustContrastAdjustColor}>
                    <AdjustContrastColorPicker />
                    <AdjustContrastInvertColor />
                </FlexBox>
            </DesktopExperience>
            <MobileExperience>
                <>
                    <FlexBox className={mobileAdjustContrastAdjustColor} mb="5">
                        <Typography>{text('chooseDifferentColor')}</Typography>
                        <AdjustContrastInvertColor />
                    </FlexBox>
                    <AdjustContrastColorPicker />
                </>
            </MobileExperience>
        </>
    );
};
