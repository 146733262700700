import React from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { AddEditDesign } from './AddEditDesign';
import { BACK } from '../../config/constant';
import { usePanelTitle } from '../../hooks/usePanelTitle';
import { useRedirectURL } from '../../hooks/useRedirectURL';
import { text } from '../../utils/localization';
import { PageSection, QUAD_TRACKING_EVENTS } from '../TrackingEvents/constant';

export const DesignTheBackRedirect = () => {
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const { getPanelTitle } = usePanelTitle();
    const { redirectToStudio } = useRedirectURL();

    const panelTitle = getPanelTitle(BACK)?.toLowerCase();
    const backPanelIndex = () => panels.findIndex(({ panelProperties: { name } }) => name === BACK);
    const panelNumber = backPanelIndex() + 1;

    const handleRedirectLinkClick = () => {
        redirectToStudio({
            pageSection: PageSection.DesignEditToolbar,
            ctaText: QUAD_TRACKING_EVENTS.CLICK_DESIGN_BACK,
            panelNumber,
        });
    };

    return (
        <AddEditDesign
            designUploadStatusLabel={text('panelQueryText', { panelTitle })}
            redirectLinkLabel={text('panelRedirectText', { panelTitle })}
            onClick={handleRedirectLinkClick}
            testId="design-back-redirect"
            py="4"
        />
    );
};
