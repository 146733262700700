import { useEffect, useState } from 'react';
import { Measurement } from '@design-stack-ct/utility-core';
import { useActivePanel } from './useActivePanel';

export const useIsVerticallySkewed = () => {
    const activePanel = useActivePanel();
    const [isVerticallySkewed, setISVerticallySkewed] = useState(false);
    useEffect(() => {
        if (activePanel) {
            const panelWidth = new Measurement(activePanel.panelProperties.width).mm;
            const panelHeight = new Measurement(activePanel.panelProperties.height).mm;
            const panelAspectRatio = panelWidth / panelHeight;
            if (panelAspectRatio < 0.5) {
                setISVerticallySkewed(true);
            } else {
                setISVerticallySkewed(false);
            }
        }
    }, [activePanel]);

    return {
        isVerticallySkewed,
    };
};
