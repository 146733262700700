import React, { useCallback } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import {
    FluidImage,
    SelectedValue,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    tokens,
    Typography,
} from '@vp/swan';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { PanelSides } from '../../../config/constant';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { useUploadPanels } from '../../../hooks/useUploadPanel';
import { text } from '../../../utils/localization';
import { getPanelByName } from '../../UploadPanel/components/UploadBackDesignModal/helper';
import { useBackDesignEvents } from '../hooks';
import { backDesignTemplateStore } from '../store/BackDesignTemplateStore';
import { selectLabelStyles } from '../style';
import { DesignTemplate } from '../types';

const getTemplateImageStyle = (isSmall: boolean) => css`
    max-height: ${isSmall ? '60vw' : '270px'};
    box-shadow: ${tokens.SwanSemElevationStandard};
`;

const designTemplateSelectLabelStyle = css`
    ${selectLabelStyles};
    &.swan-selection-set.swan-selection-set-skin-tiles-horizontal {
        .swan-selection-set-label {
            min-height: 200px;
        }
        img {
            width: auto;
            height: auto;
            max-width: 100%;
        }
    }
`;

export const BackDesignTemplateList = observer((): JSX.Element | null => {
    const { isSmall } = useScreenLayout();
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const { assetId, designTemplates, setSelectedDesignTemplate, openMakeEditModal } = backDesignTemplateStore;
    const { onAddDesignTemplateToPanel } = useUploadPanels();
    const { fireAddTemplateEvent } = useBackDesignEvents();
    const backPanel = getPanelByName(panels, PanelSides.Back);
    const backPanelId = backPanel?.id;

    const hideDesignTemplateList = !assetId || !designTemplates.length || !backPanel;

    const applyDesignTemplate = useCallback(
        async (designTemplate: DesignTemplate) => {
            if (backPanelId) {
                const { designCimDoc, layoutId, completionPercent } = designTemplate;
                await onAddDesignTemplateToPanel(backPanelId, toJS(designCimDoc));
                setSelectedDesignTemplate(designTemplate);
                openMakeEditModal();
                fireAddTemplateEvent({ layoutId, completionPercent });
            }
        },
        [backPanelId, onAddDesignTemplateToPanel]
    );

    const handleSelectedValueChange = useCallback(
        async (designIndex: SelectedValue) => {
            const designTemplate = designIndex && designTemplates[Number(designIndex)];
            if (designTemplate) {
                await applyDesignTemplate(designTemplate);
            }
        },
        [designTemplates, applyDesignTemplate]
    );

    if (hideDesignTemplateList) {
        return null;
    }

    return (
        <>
            <Typography fontWeight="bold" fontSize="large" mt="8" mb="4">
                {text('recommendations')}
            </Typography>
            <SelectionSet
                className={designTemplateSelectLabelStyle}
                onSelectedValueChange={handleSelectedValueChange}
                skin="tiles-horizontal"
                variant="single-select"
            >
                {designTemplates.map((designTemplate, index) => (
                    <SelectionSetInput key={designTemplate.preview} value={`${index}`}>
                        <SelectionSetLabel>
                            <FluidImage
                                className={getTemplateImageStyle(isSmall)}
                                src={designTemplate.preview}
                                alt={text('chooseTemplateAltText')}
                            />
                        </SelectionSetLabel>
                    </SelectionSetInput>
                ))}
            </SelectionSet>
        </>
    );
});
