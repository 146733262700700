import { useContext } from 'react';
import { NotificationContext, NotificationManager } from './NotificationProvider';

export const useNotificationFramework = (): NotificationManager => {
    const value = useContext(NotificationContext);
    if (!value) {
        throw new Error('useNotification must be wrapped inside a NotificationProvider');
    }

    return value;
};
