import React, { useEffect, useRef, useState } from 'react';
import { PanArea, SelectArea, useDesignEngine, useFocus } from '@design-stack-vista/core-features';
import { useLayoutContainers } from '@design-stack-vista/ida-framework';
import { css } from '@emotion/css';
import { FlexBox } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { PanelPreview } from './PanelPreview';
import { useKeyboardItemDeleter } from '../features/Keyboard';
import { PreviewLoader } from '../features/Loading';
import { useFocusOnWindowResize } from '../hooks/useFocusOnWindowResize';
import { useScreenLayout } from '../hooks/useScreenLayout';
import { useZoomFramework } from '../hooks/useZoomFramework';

const panAreaStyle = css`
    position: relative;
    width: 100%;
    height: 100%;
    touch-action: none;
`;

export const VisiblePanels = observer(() => {
    const ref = useRef<HTMLDivElement>(null);
    const { isSmall } = useScreenLayout();
    const designEngine = useDesignEngine();
    const { focusDesignArea } = useFocus();
    const { panContainer } = useLayoutContainers();
    const [showDesignArea, setShowShowDesignArea] = useState(false);

    const {
        layoutStore: { visiblePanels },
        idaStore: { activeDesignPanelId },
    } = designEngine;

    useFocusOnWindowResize();
    useKeyboardItemDeleter();
    useZoomFramework({ domTarget: ref });

    useEffect(() => {
        if (activeDesignPanelId && panContainer) {
            focusDesignArea(activeDesignPanelId);
            setShowShowDesignArea(true);
        }
    }, [panContainer, activeDesignPanelId, designEngine]);

    return (
        <FlexBox className={panAreaStyle} ref={ref}>
            <SelectArea>
                <PreviewLoader />
                <PanArea allowUserPan={isSmall}>
                    {showDesignArea && visiblePanels.map((panel) => <PanelPreview key={panel.id} panel={panel} />)}
                </PanArea>
            </SelectArea>
        </FlexBox>
    );
});
