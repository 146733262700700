//TODO: to use react-query for optimized fetch calls using useQuery
import { queryProductConfiguration } from '../../api/calcifer';
import { CalciferFetchOptions } from '../../types/calcifer';

export const sortOptions = (unorderedObject: Record<string, string>) =>
    Object.keys(unorderedObject)
        .sort()
        .reduce((obj, key) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            obj[key] = unorderedObject[key];
            return obj;
        }, {});

export const useQueryProductConfiguration = () => {
    const getProductConfiguration = (config: CalciferFetchOptions, onError: () => void, signal?: AbortSignal) => {
        return queryProductConfiguration(config, onError, signal);
    };

    return {
        getProductConfiguration,
    };
};
