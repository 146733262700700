import React from 'react';
import { css } from '@emotion/css';
import { AlertBox, FlexBox, tokens, Typography } from '@vp/swan';
import { MailingIcon } from '../../components/SvgIcons';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { text } from '../../utils/localization';

const getMailingPreviewStyle = (isSmall: boolean) => css`
    position: absolute;
    bottom: 0px;
    ${!isSmall && 'max-width: 550px'};
    ${isSmall && `margin: ${tokens.SwanSemSpace4} ${tokens.SwanSemSpace4} 0;`}
    .swan-alert-box {
        ${isSmall && `box-shadow: none;`}
        .swan-display-flex {
            span {
                text-align: left;
            }
        }
    }
`;

export const MailingPreview = () => {
    const { isSmall } = useScreenLayout();
    return (
        <div className={getMailingPreviewStyle(isSmall)} data-testId="mailing-preview-desc">
            <AlertBox px="5" py="4" skin="warning" data-testid="mailing-preview-message" pl="6">
                <FlexBox flexDirection="row" alignItems="center" gap="4">
                    <span className="mailing-icon">
                        <MailingIcon height="24" width="24" />
                    </span>
                    <Typography as="span">{text('mailingPreviewDescription')}</Typography>
                </FlexBox>
            </AlertBox>
        </div>
    );
};
