import React, { useCallback, useMemo, useState } from 'react';
import { Coordinates } from '@design-stack-vista/interactive-design-engine-core';
import { css } from '@emotion/css';
import { tokens } from '@vp/swan';
import { WarningIcon } from '../../../components/SvgIcons';
import { text } from '../../../utils/localization';

const legendMargin = 15;

const LegendWidth = {
    maxWidth: 340,
    minWidth: 335,
};

const getLegendStyle = (horizontalEmptySpace: number, legendWidth: number) => css`
    width: ${legendWidth}px;
    position: relative;
    background-color: ${tokens.SwanSemColorBgStandard};
    left: ${horizontalEmptySpace / 2 < legendWidth ? `${-legendWidth + horizontalEmptySpace / 2}px` : '0'};
    top: -5px;
    color: black;
    padding: ${tokens.SwanSemSpace4};
    font-size: 12px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 12%);
`;

interface WarningProp {
    type: string;
    iconPlacementWithZoom: Coordinates;
    panAreaRect: DOMRect;
    onInteraction: () => void;
}

export const DesignAreaWarningIcon = ({ type, iconPlacementWithZoom, panAreaRect, onInteraction }: WarningProp) => {
    const [showLegend, setShowLegend] = useState(false);
    const legendWidth = useMemo(() => {
        return panAreaRect.width > LegendWidth.maxWidth ? LegendWidth.minWidth : panAreaRect.width - legendMargin;
    }, [panAreaRect.width]);

    const handleMouseOver = useCallback(() => {
        setShowLegend(true);
        onInteraction();
    }, [onInteraction]);

    const handleMoveLeave = () => setShowLegend(false);

    return (
        <>
            <WarningIcon
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMoveLeave}
                pointerEvents="visible"
                width={24}
                height={24}
            />
            {showLegend && (
                <div className={getLegendStyle(panAreaRect.width - iconPlacementWithZoom.x, legendWidth)}>
                    {text(`${type}Warning`)}
                </div>
            )}
        </>
    );
};
