import { paint } from '@rendering/neon';
import type { LayoutResult, Selector } from '@rendering/plasma';
import { layout } from '@rendering/plasma';
import isEqual from 'lodash/isEqual';
import { computed, observe } from 'mobx';
import type { OperatorFunction } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    interval,
    map,
    mergeMap,
    Observable,
    retry,
    skip,
    switchMap,
    tap,
    throttle,
} from 'rxjs';
import type { RenderingInputs, RenderingResults } from './types';

declare global {
    // eslint-disable-next-line vars-on-top, no-var, no-underscore-dangle
    var __FUSION_DEBUG__: boolean;
    // eslint-disable-next-line vars-on-top, no-var, no-underscore-dangle
    var __FUSION_DEBUG_SELECTOR_TYPE__: string | undefined;
}

// eslint-disable-next-line no-underscore-dangle
globalThis.__FUSION_DEBUG__ = false;
// eslint-disable-next-line no-underscore-dangle
globalThis.__FUSION_DEBUG_SELECTOR_TYPE__ = undefined;

type DebugFlag = '__FUSION_DEBUG__' | '__FUSION_DEBUG_SELECTOR_TYPE__';
function getDebugValue(flag: DebugFlag) {
    return globalThis[flag] || localStorage.getItem(flag);
}

export interface RenderWithFusionOptions {
    prerender?: (inputs: RenderingInputs) => void;
    postrender?: (results: RenderingResults) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError?: (error: any) => void;
}

function isFusionDebugEnabled(selector?: Selector) {
    const isDebugEnabled = !!getDebugValue('__FUSION_DEBUG__');
    const debugSelectorType = getDebugValue('__FUSION_DEBUG_SELECTOR_TYPE__');
    return isDebugEnabled && (!debugSelectorType || !selector || debugSelectorType === selector.type);
}

export function isRenderingOnServer(layoutResult: LayoutResult): boolean {
    return layoutResult.elements.some((element) => element.status.mode === 'server');
}

/**
 * Operator function used to perform rendering as part of an RXJS pipe
 */
export function renderWithFusion(
    options?: RenderWithFusionOptions
): OperatorFunction<RenderingInputs, RenderingResults> {
    let throttling = false;
    let selector: Selector | undefined;

    return (source) => {
        // Initial processing of the raw RenderingInputs stream to ensure we don't spam the rendering server or make duplicate requests
        // eslint-disable-next-line no-param-reassign
        source = source.pipe(
            // Selectively enable throttling of rendering requests
            throttle(() => interval(throttling ? 200 : 0), { leading: true, trailing: true }),
            // Enable fusion debug logs based on runtime flag
            map((inputs) => {
                selector = inputs.layout.selector;
                if (isFusionDebugEnabled(inputs.layout.selector)) {
                    // eslint-disable-next-line no-param-reassign
                    inputs.layout.debugOptions = { ...inputs.layout.debugOptions, log: true };
                    // eslint-disable-next-line no-param-reassign
                    inputs.paint.debugOptions = { ...inputs.layout.debugOptions, log: true };
                }

                return inputs;
            }),
            // Only re-render when the inputs have changed
            distinctUntilChanged(isEqual)
        );

        return source.pipe(
            // Execute prerender callback before layout and paint
            tap((inputs) => options?.prerender?.(inputs)),
            // Request layout from fusion and only emit the latest result
            switchMap(async (inputs: RenderingInputs) => ({
                inputs,
                results: {
                    // Casting needed due to incompatible document types. See issue #81.
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    layout: await layout({ ...(inputs.layout as any) }),
                },
            })),
            // Determine if we should throttle future requests based on the previous layout result
            tap(({ results }) => {
                throttling = isRenderingOnServer(results.layout);
            }),
            // Request paint from fusion and emit the final results
            mergeMap(async ({ inputs, results }) => ({
                ...results,
                paint: paint({
                    layoutResult: results.layout,
                    ...inputs.paint,
                }),
            })),
            // Execute any post render callback
            tap((results) => options?.postrender?.(results)),
            // Execute any onError callback and rethrow the error to allow it to be retried
            catchError((error) => {
                options?.onError?.(error);

                if (isFusionDebugEnabled(selector)) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }

                throw error;
            }),
            // Retry whenever new RenderingInputs are recieved (skipping the last value that failed)
            retry({ delay: () => source.pipe(skip(1)) })
        );
    };
}

/**
 * Select data from a MobX store and convert that to an RxJS Observable stream.
 *
 * This is a reimplementation of `toStream` from `mobx-utils` which aims to fix issues caused when `Symbol.observable`
 * changes during runtime (e.g., by a third-party script or browser extension).
 *
 * @see https://github.com/mobxjs/mobx-utils/issues/300
 * @see https://github.com/mobxjs/mobx-utils/issues/131
 */
export function toRxObservable<T>(expression: () => T, fireImmediately = false): Observable<T> {
    const computedValue = computed(expression);

    return new Observable<T>((observer) => {
        observe(computedValue, ({ newValue }) => observer.next(newValue as T), fireImmediately);
    });
}
