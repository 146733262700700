import React, { useRef, useState } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { css } from '@emotion/css';
import { Checkbox, Divider, FlexBox } from '@vp/swan';
import { ContinueButtonQuad } from './ContinueButtonQuad';
import { DesignReviewDescription } from './DesignReviewDescription';
import { ACTION_NAME } from '../../features/TrackingEvents/constant';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { text } from '../../utils/localization';
import { DesktopExperience } from '../Experiences';

const getDesignReviewText = (isSmall: boolean) => css`
    grid-area: reviewText;
    height: 100%;
    overflow-y: auto;
    ${isSmall &&
    `
        height: auto;
        overflow-y: visible;
        width: 100%;
    `}
`;

const messages = {
    approvalMessage: {
        id: 'reviewStep.approvalMessage',
    },
    confirmDesignAriaLabel: {
        id: 'reviewStep.confirmDesignAriaLabel',
    },
};

interface Props {
    handleContinueEditingClick: () => void;
    placeholderTextPresent: boolean;
    hasPremiumFinishesCurrentCanvas: boolean;
}

export function DesignReview({ handleContinueEditingClick, placeholderTextPresent }: Props) {
    const [customerReviewed, setCustomerReviewed] = useState(false);
    const { fireEvent } = useCustomEventPropagation();
    const isCustomerReviewed = useRef(false);
    const { isSmall } = useScreenLayout();

    const onSelectionChange = () => {
        if (!isCustomerReviewed.current) {
            fireEvent({
                name: ACTION_NAME.DESIGN_TOOL_USED,
                extraData: {
                    eventDetail: `engage:toggle`,
                },
            });
        }
        isCustomerReviewed.current = true;
        setCustomerReviewed(!customerReviewed);
    };

    return (
        <FlexBox
            className={getDesignReviewText(isSmall)}
            flexDirection="column"
            justifyContent="space-between"
            px={{ xs: '5', sm: '8' }}
            pb={{ xs: '5', sm: '6' }}
            pt={{ sm: '9' }}
        >
            <DesktopExperience>
                <DesignReviewDescription
                    handleContinueEditingClick={handleContinueEditingClick}
                    placeholderTextPresent={placeholderTextPresent}
                />
            </DesktopExperience>
            <FlexBox flexDirection="column">
                <Divider mb={{ xs: '5', sm: '6' }} />
                <FlexBox mb="5" alignItems="center">
                    <Checkbox
                        id="customer-review"
                        aria-label={text(messages.confirmDesignAriaLabel.id)}
                        checked={customerReviewed}
                        onChange={onSelectionChange}
                        aria-required="true"
                        mr="4"
                    />
                    <label data-testid="customer-approve-chk" htmlFor="customer-review">
                        {text(messages.approvalMessage.id)}
                    </label>
                </FlexBox>
                <ContinueButtonQuad hasBeenReviewed={customerReviewed} />
            </FlexBox>
        </FlexBox>
    );
}
DesignReview.displayName = 'DesignReview';
