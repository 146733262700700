/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PointerEvent } from 'react';
import type { ReactNode } from 'react';
import { PanelLayoutExtension, useDesignEngine, useSelectedItems } from '@design-stack-vista/core-features';
import { Coordinates, DesignLayer, useLayoutContainers } from '@design-stack-vista/ida-framework';
import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

const touchLayerStyle = (panRect: DOMRect, offset: Coordinates, margins: Coordinates) => css`
    width: ${panRect.width}px;
    height: ${panRect.height}px;
    top: ${-1 * (offset.y + margins.y)}px;
    left: ${-1 * (offset.x + margins.x)}px;
    pointer-events: auto;
`;

interface TouchDeselectLayerProps {
    children?: ReactNode | ReactNode[];
}

export const TouchDeselectLayer = observer(({ children }: TouchDeselectLayerProps) => {
    const { selectedItemIds, setSelectedIds } = useSelectedItems();
    const {
        layoutStore: { offset, visiblePanelIds },
        designExtensionSystem,
    } = useDesignEngine();
    const { panContainer } = useLayoutContainers();
    const panelLayoutDef = designExtensionSystem.getExtension(visiblePanelIds[0], PanelLayoutExtension);
    if (selectedItemIds.length === 0 || !panContainer || !panelLayoutDef?.position) {
        return null;
    }
    const blockPropgation = (e: PointerEvent) => {
        e.preventDefault();
    };
    const handleClick = () => {
        setSelectedIds([]);
    };
    return (
        <DesignLayer name="touch-select">
            <div
                className={touchLayerStyle(panContainer.getBoundingClientRect(), offset, panelLayoutDef.position)}
                onPointerDown={blockPropgation}
                onClick={handleClick}
            >
                {children}
            </div>
        </DesignLayer>
    );
});
