import QueryStringUtils from './queryStringUtils';

export const ownerId = QueryStringUtils.getValueByKey('owner') ?? '';

export function isCareAgent() {
    if (!windowExists()) {
        return false;
    }

    const careAgent = QueryStringUtils.getValueByKey('careAgent') ?? '';
    return careAgent === 'true';
}

// Purpose: If care agents are creating new documents and not assigning it to any owner but rather them self,
// we want to make sure that we don't add those documents to cart by default
export function isCareAgentEditingCareAgentDocument() {
    return isCareAgent() && !ownerId;
}

export function isProd() {
    return !isLocalhost() && !isStaging() && !isBranch();
}

export function windowExists() {
    return typeof window !== 'undefined' && !!window;
}

export function isLocalhost() {
    return windowExists() && /localhost|127\.0\.0\.1|::1/.test(window.location.hostname);
}

export function isStaging() {
    return windowExists() && (/dev\./.test(window.location.hostname) || /staging\./.test(window.location.hostname));
}

export function isBranch() {
    return windowExists() && /\/review\//.test(window.location.hostname);
}

// get existing parameters for care agents to retain while redirecting to studio
export function getCareAgentQueryParams() {
    let queryParams = '';
    const careAgent = QueryStringUtils.getValueByKey('careAgent') ?? '';
    if (careAgent.length) {
        queryParams = queryParams + `&careAgent=${careAgent}`;
    }
    if (ownerId) {
        queryParams = queryParams + `&owner=${ownerId}`;
    }
    return queryParams;
}
