import React from 'react';
import { css } from '@emotion/css';
import { PopoverContent, tokens } from '@vp/swan';
import { ChangeFinishDialog } from './ChangeFinishDialog';
import { ExplorePremiumFinishDialog } from './ExplorePremiumFinishDialog';
import { text } from '../../../utils/localization';
import DelayRender from '../../quad/components/DelayRender';

interface Props {
    finishIconDisabled: boolean;
    textSelection: string;
    onFinishIconClick: () => void;
    onPopoverDeleteButtonClick: () => void;
    hideExplorePopover: () => void;
    shouldShowExploreFinish: boolean;
}

// This is the delay time that we needed before render explore finish popover
const EXPLORE_POPOVER_DELAY = 2000;

const disabledPopoverStyle = css`
    &.swan-popover {
        color: ${tokens.SwanSemColorTextSubtle};
        font-size: ${tokens.SwanSemFontSizeXsmall};
        width: 200px;
    }
`;

const popoverDialogStyle = css`
    &.swan-popover {
        width: 310px;
    }
`;

export const FinishPopoverContent = ({
    finishIconDisabled,
    textSelection,
    onFinishIconClick,
    onPopoverDeleteButtonClick,
    shouldShowExploreFinish,
    hideExplorePopover,
}: Props) => {
    if (finishIconDisabled) {
        return (
            <PopoverContent className={disabledPopoverStyle} p="5">
                {text('finishIconDisabledDialogMessage')}
            </PopoverContent>
        );
    }

    return shouldShowExploreFinish ? (
        <DelayRender waitBeforeShow={EXPLORE_POPOVER_DELAY}>
            <ExplorePremiumFinishDialog
                onExploreButtonClick={onFinishIconClick}
                hideExplorePopover={hideExplorePopover}
                popoverDialogStyle={popoverDialogStyle}
            />
        </DelayRender>
    ) : (
        <ChangeFinishDialog
            textSelection={textSelection}
            onFinishIconClick={onFinishIconClick}
            onPopoverDeleteButtonClick={onPopoverDeleteButtonClick}
            popoverDialogStyle={popoverDialogStyle}
        />
    );
};
