import React, { PointerEventHandler } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { Button, className, FlexBox, Icon, tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { UpsellPricingText } from './UpsellPricingText';
import { ZOOM_THRESHOLD_TO_SHOW_ICON } from '../../config/constant';
import { useActivePanel } from '../../hooks/useActivePanel';
import { useIsVerticallySkewed } from '../../hooks/useIsVerticallySkewed';
import { usePanelTitle } from '../../hooks/usePanelTitle';
import { useSceneConfiguration } from '../../hooks/useSceneConfiguration';

/*
    reference - https://vistaprint.atlassian.net/wiki/spaces/~6274f15f106b60006f59fac1/pages/3178661021/Fix+for+Upload+CTA+on+mobile
    This function return the font-size according to the zoom factor and if product is skewed
    The return values co-relates to px
*/
const getFontSize = (isVerticallySkewed: boolean, currentZoomFactor: number) => {
    if (isVerticallySkewed) {
        if (currentZoomFactor > 0.75) {
            return 14;
        } else if (currentZoomFactor > 0.5) {
            return 10;
        } else if (currentZoomFactor > 0.25) {
            return 8;
        } else {
            return 4;
        }
    } else {
        if (currentZoomFactor > 0.5) {
            return 18;
        } else {
            return 8;
        }
    }
};

const getBaseButtonStyle = (fontSize: number) => css`
    height: 100%;
    &.upload-button {
        margin: 0 auto;
        position: relative;
        top: 0vh;
    }

    .safety-area {
        height: 100%;
        .swan-button-skin-unstyled {
            height: 100%;
            white-space: normal;
            font-size: ${fontSize};
            text-align: center;
            .swan-icon {
                order: 0;
                margin-right: ${tokens.SwanSemSpace2};
            }
            span {
                word-wrap: break-word;
            }
        }
    }
`;

const primaryButtonStyle = css`
    background-color: ${tokens.SwanSemColorBgStandard};
    opacity: 1;
`;

const secondaryButtonStyle = css`
    background-color: ${tokens.SwanBaseColorGrey900};
    opacity: 0.3;
    .safety-area {
        .swan-button-skin-unstyled {
            span {
                color: ${tokens.SwanBaseColorWhite};
            }
        }
    }
`;

type UploadButtonProps = {
    onPointerDown?: (event: PointerEvent) => void;
    openUploadModal: () => void;
};

export const UploadButton = observer(({ onPointerDown, openUploadModal }: UploadButtonProps) => {
    const {
        layoutStore: { initialZoom, zoom },
    } = useDesignEngine();
    const panel = useActivePanel();
    const { getUploadButtonText } = usePanelTitle();
    const { sceneConfig } = useSceneConfiguration();
    const { isVerticallySkewed } = useIsVerticallySkewed();

    const panelName = panel?.panelProperties?.name;
    const buttonText = getUploadButtonText(panelName);
    const { underlayUrl } = sceneConfig?.[panel?.id || ''] || {};

    const currentZoomFactor = zoom / initialZoom;
    const fontSize = getFontSize(isVerticallySkewed, currentZoomFactor);
    const shouldShowUploadIcon = !isVerticallySkewed && currentZoomFactor > ZOOM_THRESHOLD_TO_SHOW_ICON;

    return (
        <div
            className={className(
                getBaseButtonStyle(fontSize),
                underlayUrl ? secondaryButtonStyle : primaryButtonStyle,
                'upload-button'
            )}
        >
            <FlexBox alignItems="center" className="safety-area">
                <Button
                    onClick={openUploadModal}
                    onPointerDown={onPointerDown as unknown as PointerEventHandler<HTMLButtonElement>}
                    fontWeight="bold"
                    textAlign="center"
                    width="full-width"
                    skin="unstyled"
                    data-testid="mobile-upload-button"
                >
                    {shouldShowUploadIcon && (
                        <Icon
                            className="upload-icon"
                            size="20p"
                            iconType="upload"
                            skin={underlayUrl ? 'white' : 'standard'}
                        />
                    )}
                    <span>{buttonText}</span>
                    {panelName && <UpsellPricingText panelName={panelName} />}
                </Button>
            </FlexBox>
        </div>
    );
});
