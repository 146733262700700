import { action, makeObservable, observable } from 'mobx';
import { ShapeKey } from './constants';
import { ChoiceGroupPriceDetails } from '../../types/price';
import { ProductChoiceGroups } from '../Flexibility/common';

class ShapeStore {
    @observable userSelectedSize = '';
    @observable shapeList: ShapeKey[] = [];
    @observable selectedShape = '';
    @observable showShapeModal = false;
    @observable shapeOptions: ProductChoiceGroups = {};
    @observable shapeOptionsPrices: ChoiceGroupPriceDetails = {};

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setUserSelectedSize(size: string) {
        this.userSelectedSize = size;
    }

    @action.bound
    setSelectedShape(shape: string) {
        this.selectedShape = shape;
    }

    @action.bound
    openShapeModal() {
        this.showShapeModal = true;
    }

    @action.bound
    closeShapeModal() {
        this.showShapeModal = false;
    }

    @action.bound
    setShapeList(list: ShapeKey[]) {
        this.shapeList = list;
    }

    @action.bound
    setShapeOptions(options: ProductChoiceGroups) {
        this.shapeOptions = options;
    }

    @action.bound
    setShapeOptionsPrices(optionsPrices: ChoiceGroupPriceDetails) {
        this.shapeOptionsPrices = optionsPrices;
    }
}

export const shapeStore = new ShapeStore();
