import { useEffect } from 'react';
import { usePostCard } from './usePostCard';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useQuadExperiment } from '../../../hooks/useQuadExperiment';
import { useRenderingState } from '../../../hooks/useRenderingState';
import { useAbTestContext } from '../../../providers/ABTestProvider';
import { getSessionStorageKey, LOCAL_STORAGE_KEY, localStorageUtility, SESSION_STORAGE_KEY } from '../../../utils';
import { getExperimentProperties } from '../../../utils/getExperimentProperties';
import { POSTCARD_AB_TEST_SECOND_VARIATION } from '../postcardConstants';
import { postCardStore } from '../PostCardStore';

export const usePostCardMailingModal = () => {
    const ABTest = useAbTestContext();
    const experiment = useQuadExperiment();
    const { workId } = useProductConfiguration();
    const { renderingStatus } = useRenderingState();
    const {
        disableMailingModalForUser,
        isFirstTimeSwitchFromMailablePostcard,
        setIsFirstTimeSwitchFromMailablePostcard,
        setShowPostCardMailingModal,
    } = postCardStore;
    const { isMailablePostCard, isMailingPostCard } = usePostCard();

    const imageRenderingCompleted = renderingStatus === 'COMPLETE';

    useEffect(() => {
        if (!ABTest) {
            return;
        }

        const { variationKey } = getExperimentProperties(experiment, ABTest);
        const isPostcardVariation2 = variationKey === POSTCARD_AB_TEST_SECOND_VARIATION;

        const isMailingServiceAvailable =
            imageRenderingCompleted && isMailablePostCard && !isMailingPostCard && isPostcardVariation2;

        if (!isMailingServiceAvailable) {
            return;
        }

        if (isFirstTimeSwitchFromMailablePostcard) {
            setIsFirstTimeSwitchFromMailablePostcard(false);
            return;
        }
        const { getQuadFlags } = localStorageUtility;
        const localMailedModalFlag = Boolean(getQuadFlags(LOCAL_STORAGE_KEY.NOT_SHOW_MAILING_MODAL_CHECKED));
        const sessionMailedModalFlag = Boolean(getSessionStorageKey(SESSION_STORAGE_KEY.MAILING_SERVICE_MODAL_SHOWED));
        const shouldShowMailingServiceModal =
            !localMailedModalFlag && !sessionMailedModalFlag && !workId && !disableMailingModalForUser;

        setShowPostCardMailingModal(shouldShowMailingServiceModal);
    }, [imageRenderingCompleted, experiment, ABTest, workId, disableMailingModalForUser]);
};
