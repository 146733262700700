import React from 'react';
import { FlexBox } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { FinishButton } from './FinishButton';
import { DesignPreviewModalButton } from '../DesignPreview/DesignPreviewModalButton';

export const ReviewComponent = observer(() => {
    return (
        <FlexBox flexDirection="column" gap="4" justifyContent="space-between" mt="6">
            <DesignPreviewModalButton />
            <FinishButton />
        </FlexBox>
    );
});
