import React, { Fragment, useCallback } from 'react';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine, useZoom } from '@design-stack-vista/core-features';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { css } from '@emotion/css';
import { FlexBox, SelectionSet, tokens, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { UPLOAD_BACK_DESIGN_MODAL_TYPES, useUploadBackDesignModal } from './components/UploadBackDesignModal';
import { UploadDesignButton } from './UploadDesignButton';
import { UploadPreview } from './UploadPreview';
import { DesktopExperience } from '../../components/Experiences';
import { BACK, PanelSides } from '../../config/constant';
import { useDesignablePanels } from '../../hooks/useDesignablePanels';
import { usePanelTitle } from '../../hooks/usePanelTitle';
import { usePremiumFinishModal, usePremiumFinishStore } from '../../providers';
import { useUploadModal } from '../../providers/UploadModalProvider';
import { text } from '../../utils/localization';
import { ProductProperties } from '../PanelProperties/ProductProperties';
import { PostCardSection } from '../PostCard';
import { getReviewInstruction } from '../PremiumFinish';
import { DesignTheBackRedirect } from '../RedirectToStudio/DesignTheBackRedirect';
import { ShapeOptionSet, useShapeUtils } from '../Shapes';
import { SubstrateColorPanel } from '../SubstrateColor';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../TrackingEvents/constant';
import { GA_EVENTS } from '../TrackingEvents/GAEvents';

const previewPanelContainerStyle = css`
    flex: 1;
    overflow: hidden;
`;

const previewPanelStyle = css`
    overflow: auto;
`;

const uploadPreviewStyles = css`
    &.swan-selection-set.swan-selection-set-skin-tiles-horizontal {
        .swan-selection-set-label {
            border-radius: ${tokens.SwanSemBorderRadiusStandard};
            min-height: unset;
            height: 80px;
            margin-bottom: 0;
            .swan-selection-set-tile-image-container {
                min-width: unset;
            }
        }
    }
`;

export const UploadPanelPreviews = observer(() => {
    const {
        cimDocStore: { panels, asJson: cimdoc },
        idaStore: { setActiveDesignPanelId, activeDesignPanelId },
        layoutStore: { setVisiblePanelIds, initialZoom },
    } = useDesignEngine();
    const { zoomTo } = useZoom();
    const { isShapeFeatureEnable } = useShapeUtils();
    const { getPanelTitle } = usePanelTitle();
    const { setShowUploadModal } = useUploadModal();
    const { fireEvent } = useCustomEventPropagation();
    const { isPremiumFinishProduct } = usePremiumFinishStore();
    const { openPremiumFinishConfirmationModal } = usePremiumFinishModal();
    const designablePanels = useDesignablePanels();

    const { showUploadBackDesignModal } = useUploadBackDesignModal();

    const showPanel = useCallback(
        (panelId: string) => {
            if (activeDesignPanelId !== panelId) {
                zoomTo(initialZoom);
            }
            setVisiblePanelIds([panelId]);
            setActiveDesignPanelId(panelId);
        },
        [initialZoom, activeDesignPanelId]
    );

    const showPremiumFinishConfirmationModal = useCallback(
        (panelId: string) => {
            const isFrontPanel = panels[0].id === panelId;
            if (!isFrontPanel && isPremiumFinishProduct) {
                const instruction = getReviewInstruction(cimdoc);
                if (!instruction?.finishOption) {
                    return true;
                }
            }
            return false;
        },
        [cimdoc, isPremiumFinishProduct, panels]
    );

    const handleModal = useCallback(
        (panel: PanelState) => {
            if (panel.panelProperties.name.toLowerCase() === PanelSides.Back) {
                showUploadBackDesignModal(UPLOAD_BACK_DESIGN_MODAL_TYPES.UPLOAD_ASSET);
            } else {
                setShowUploadModal(true);
            }
        },
        [showUploadBackDesignModal, setShowUploadModal, fireEvent]
    );

    const handleUploadButtonClick = useCallback(
        (panel: PanelState) => {
            const trackerName = QUAD_TRACKING_EVENTS.CLICK_UPLOAD_BUTTON.replace(
                '${PANEL_NAME}',
                panel.panelProperties.name
            );
            const eventDetail = GA_EVENTS.CLICK_RIGHT_PANEL_UPLOAD.replace('[CANVAS FACE]', panel.panelProperties.name);

            fireEvent({
                name: ACTION_NAME.DESIGN_TOOL_USED,
                extraData: {
                    experimentLabel: trackerName,
                    eventDetail,
                    pageStage: PageStage.Discover,
                    pageSection: PageSection.QuickUploadAndDesign,
                    experimentDetail: {
                        productSurfaceId: panel.id,
                        side: panel.panelProperties.name,
                    },
                },
            });

            if (showPremiumFinishConfirmationModal(panel.id)) {
                openPremiumFinishConfirmationModal();
                return;
            }
            handleModal(panel);
            showPanel(panel.id);
        },
        [showPremiumFinishConfirmationModal, openPremiumFinishConfirmationModal, handleModal, showPanel]
    );

    const handleUploadPreviewClick = useCallback(
        (panel: PanelState) => {
            if (activeDesignPanelId === panel.id) {
                handleModal(panel);
            } else {
                showPanel(panel.id);
            }
        },
        [activeDesignPanelId, handleModal, showPanel]
    );

    return (
        <FlexBox flexDirection="column" className={previewPanelContainerStyle}>
            <FlexBox flexDirection="column" gap="6" className={previewPanelStyle}>
                <Typography fontSize="x2large" fontWeight="bold">
                    {text('uploadDesignHeader')}
                </Typography>
                <ProductProperties />
                <SubstrateColorPanel />
                {isShapeFeatureEnable && <ShapeOptionSet />}
                <SelectionSet
                    className={uploadPreviewStyles}
                    skin="tiles-horizontal"
                    variant="single-select"
                    selectedValue={activeDesignPanelId}
                    defaultSelectedValue={activeDesignPanelId}
                >
                    {designablePanels.map((panel, index) => {
                        const {
                            id,
                            items,
                            panelProperties: { name },
                        } = panel;
                        return (
                            <Fragment key={id}>
                                {designablePanels.length > 1 && (
                                    <Typography fontSize="large" fontWeight="bold" pb="4" mt={index > 0 ? '7' : '0'}>
                                        {getPanelTitle(name)}
                                    </Typography>
                                )}
                                {items.length ? (
                                    <UploadPreview panel={panel} onClick={() => handleUploadPreviewClick(panel)} />
                                ) : (
                                    <UploadDesignButton
                                        panel={panel}
                                        panelName={name}
                                        handleUploadButtonClick={handleUploadButtonClick}
                                    />
                                )}
                                {name === BACK && <DesignTheBackRedirect />}
                            </Fragment>
                        );
                    })}

                    <DesktopExperience>
                        <PostCardSection />
                    </DesktopExperience>
                </SelectionSet>
            </FlexBox>
        </FlexBox>
    );
});
