import { CimDoc } from '@design-stack-vista/cdif-types';
import { PF_INSTRUCTIONS_TYPE } from '../constants';
import { FinishReviewInstructionType } from '../type';

export function getReviewInstruction(cimdoc: CimDoc) {
    if (cimdoc.metadata?.reviewInstructions) {
        const premiumReviewInstruction: FinishReviewInstructionType[] = cimdoc.metadata.reviewInstructions.filter(
            (review: { instructionType: string }) => review.instructionType === PF_INSTRUCTIONS_TYPE
        );
        if (premiumReviewInstruction.length > 0) {
            return {
                instructionNotes: premiumReviewInstruction[0].optionalNotes,
                finishOption: premiumReviewInstruction[0].finishOption,
            };
        }
    }
    return undefined;
}
