import React from 'react';
import { useSingleInitialize } from '@design-stack-vista/utility-react';
import { SiteMain } from '@vp/swan';
import { ValidationProvider } from './features/quad/Validations/ValidationProvider';
import { ValidationStore } from './features/quad/Validations/ValidationStore';
import { ProductConfigurationProvider, ProductParamsProvider } from './providers';
import { Router } from './Router';

function App() {
    const validationStore = useSingleInitialize(() => new ValidationStore());

    return (
        <SiteMain>
            <ValidationProvider validationStore={validationStore}>
                <ProductParamsProvider>
                    <ProductConfigurationProvider>
                        <Router />
                    </ProductConfigurationProvider>
                </ProductParamsProvider>
            </ValidationProvider>
        </SiteMain>
    );
}

export default App;
