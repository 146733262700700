import React from 'react';

/**
 *
 * @returns empty component as VCS currently does not want to track any event.
 */
export const Analytics = () => <></>;

export const sendPageTracking = () => {
    // trigger no page tracking info
};

export const sendEventTracking = () => {
    // trigger no event tracking info
};
