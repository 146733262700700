/**
 * Reference - {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/master/src/shared/utils/Cart/catchClient.ts}
 */
import { REQUESTOR } from '../config/constant';
import { fetchWrapper } from '../utils';

const CATCH_URL = 'https://catch.digital.vpsvc.com';

/**
 * Attempts to add the work entity to cart under the given locale,
 * or updates the work in cart if it's already been added
 * @param {string} locale - the locale of the page
 * @return The output of adding the work to cart
 */
export const callCatch = async (
    authorizationHeader: string,
    locale: string,
    workId: string,
    shopperId: string,
    anonymousId: string | undefined
) => {
    const url = `${CATCH_URL}/api/v1/connectedProduct/${workId}`;

    const response = await fetchWrapper(url, {
        method: 'POST',
        requestInitiator: 'callCatch',
        headers: {
            From: REQUESTOR,
            Accept: 'application/json',
            Authorization: authorizationHeader,
        },
        body: {
            shopperId,
            anonymousId,
            locale,
            tenant: locale,
        },
    });

    return response;
};
