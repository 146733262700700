import { Payload } from '@design-stack-vista/custom-event-propagation';
import { VistaAssetStore } from '@design-stack-vista/vista-assets-sdk';
import { makeAutoObservable, observable } from 'mobx';

export class DependencyInjectionStore {
    @observable _assetStore: VistaAssetStore | undefined;
    @observable _fireEvent: ((data: Payload) => void | undefined) | undefined;

    /**
     * feature flag to enable to disable validations in QUAD
     */
    @observable _isValidationEnabled = false;

    constructor() {
        makeAutoObservable(this);
    }

    addAssetStore(store: VistaAssetStore) {
        this._assetStore = store;
    }

    addFireEvent(fireEvent: (data: Payload) => void) {
        this._fireEvent = fireEvent;
    }

    /**
     *
     * @param status
     * @description setter function to enable/disable `_isValidationEnabled` property
     */
    setValidationEnabled(status: boolean) {
        this._isValidationEnabled = status;
    }
}
