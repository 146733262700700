import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const RectangleIcon = (props: SvgProps) => {
    return (
        <svg width="82" height="82" viewBox="0 0 82 82" fill="none" {...props}>
            <rect x="29.85" y="31.85" width="23.3" height="17.3" rx="3.15" stroke="black" strokeWidth="1.7" />
        </svg>
    );
};
