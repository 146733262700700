import React, { useEffect, useState } from 'react';
import { PanelSides } from '../../config/constant';
import { usePreview } from '../../providers/PreviewProvider';
import { usePostCard } from '../PostCard';
import { MailingPreview } from '../TwoDPreview/MailingPreview';

export const PreviewDescription = () => {
    const { selectedPreviewKey } = usePreview();
    const { isMailingPostCard } = usePostCard();

    const [showMailingPreview, setShowMailingPreview] = useState(false);

    useEffect(() => {
        if (selectedPreviewKey) {
            const isActivePreviewBack = selectedPreviewKey === PanelSides.Back;
            setShowMailingPreview(isActivePreviewBack && isMailingPostCard);
        }
    }, [isMailingPostCard, selectedPreviewKey]);

    return showMailingPreview ? <MailingPreview /> : <React.Fragment />;
};
