import { UnitlessDimensions } from '@design-stack-ct/utility-core';
import { CompatibleOptionsEntry, getProductSupportedAttributes } from '../../../api/catalog';
import { ProductAttributes } from '../../../config/constant';
import { noticeError } from '../../../utils';
import { HEIGHT_WIDTH_REGEX, ShapeKey } from '../constants';

interface ClosestSizeProps {
    userSelectedStrSize: string;
    productKey: string;
    productVersion: number;
    selectedOptions: Record<string, string>;
    newShape: ShapeKey;
    authHeader: string;
}

const convertToNum = (str: string) => Number(str);

const getSizeOption = (option: CompatibleOptionsEntry) => option.name === ProductAttributes.Size;

const getLowerSizeFilter = (currentSize: UnitlessDimensions, nextSize: UnitlessDimensions) =>
    nextSize.height <= currentSize.height && nextSize.width <= currentSize.width;

const getUpperSizeFilter = (currentSize: UnitlessDimensions, nextSize: UnitlessDimensions) =>
    nextSize.height >= currentSize.height && nextSize.width >= currentSize.width;

const sortSize = (size1: UnitlessDimensions, size2: UnitlessDimensions) => {
    const { height: height1, width: width1 } = size1;
    const { height: height2, width: width2 } = size2;
    if (height1 !== height2) {
        return height1 - height2;
    }
    return width1 - width2;
};

const parseSizeData = (strSize: string) => {
    const sizeData = strSize.match(HEIGHT_WIDTH_REGEX);
    if (!sizeData) return null;

    const [height, width] = sizeData.map(convertToNum);
    if (!height || !width) return null;
    return { height, width };
};

const getCloseSize = (currentSize: UnitlessDimensions, nextSizes: UnitlessDimensions[]) => {
    if (!nextSizes.length) return null;

    // sort based on width, if width's are equal sort based on height
    nextSizes.sort(sortSize);

    // Take the below size or exact match of current size
    // e.g. curr size { h: 1, w: 2 } below size will be either { h: 1, w: 2 } or { h: 1, w: 1 }
    const lowerSizes = nextSizes.filter((size) => getLowerSizeFilter(currentSize, size));
    if (lowerSizes.length) {
        const { height, width } = lowerSizes[lowerSizes.length - 1];
        return { height, width };
    }

    // if there are no below size then take the above size
    // e.g. curr size { h: 1, w: 2 } above size will be either { h: 1, w: 3 } or { h: 2, w: 1 }
    const upperSizes = nextSizes.filter((size) => getUpperSizeFilter(currentSize, size));
    if (upperSizes.length) {
        const { height, width } = upperSizes[0];
        return { height, width };
    }

    return nextSizes[0];
};

export const getClosestShapeSize = async ({
    userSelectedStrSize,
    productKey,
    productVersion,
    selectedOptions,
    newShape,
    authHeader,
}: ClosestSizeProps) => {
    try {
        const userSelectedOptions: Record<string, string> = { ...selectedOptions, Shape: newShape };
        delete userSelectedOptions[ProductAttributes.Size];
        delete userSelectedOptions[ProductAttributes.Orientation];
        const compatibleOption = await getProductSupportedAttributes(
            productKey,
            userSelectedOptions,
            productVersion,
            authHeader
        );

        // parse all sizes from string to number format e.g. {currWidth, currHeight}
        const userSelectedSize = parseSizeData(userSelectedStrSize);
        if (!userSelectedSize) {
            throw new Error('Base shape size data not in correct format');
        }

        const nextSizeObj = compatibleOption.find(getSizeOption);
        if (!nextSizeObj) {
            throw new Error('Size product attributes not exists');
        }

        // only take those sizes which are formatted correctly
        const nextSizes: UnitlessDimensions[] = [];
        nextSizeObj.values.forEach((value) => {
            const formattedSize = parseSizeData(value);
            if (formattedSize) {
                nextSizes.push(formattedSize);
            }
        });

        if (!nextSizes.length && nextSizeObj.values.length) {
            throw new Error('No sizes available of correct format for the selected shape');
        }

        const closeSize = getCloseSize(userSelectedSize, nextSizes);
        return closeSize;
    } catch (error) {
        noticeError(error, {
            method: 'getClosestSize',
        });
        return null;
    }
};
