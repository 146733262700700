import { getAllItemsFromPanel } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useQuickTools } from './useQuickTools';
import { ICON_COLOR } from '../../../config/ColorConstant';
import { PanelSides } from '../../../config/constant';
import { useActivePanel } from '../../../hooks/useActivePanel';
import { useAutoMapTrackEvent } from '../../../hooks/useAutoMapTrackEvent';
import { useUploadPanels } from '../../../hooks/useUploadPanel';
import { useUploadModal } from '../../../providers';
import { hasSingleImageItem } from '../../../utils';
import { useBackDesignEvents, useBackDesignTemplate } from '../../BackDesignTemplate';
import { useQuickToolFireTrackingEvent } from '../../QuickTools/useQuickToolFireTrackingEvent';
import { QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { GA_EVENTS } from '../../TrackingEvents/GAEvents';

// this hook only for those states and functions which are directly get used in quick tools as a props
export const useQuickToolProps = () => {
    const panel = useActivePanel();
    const {
        cimDocStore: { asJson },
    } = useDesignEngine();
    const { fireTrackingEvent } = useQuickToolFireTrackingEvent();
    const { triggerReverseAutoMapEvent } = useAutoMapTrackEvent();
    const { fitToBleed, fitToSafety, rotateItems } = useQuickTools();
    const { onRemoveItemFromPanel } = useUploadPanels();
    const { setShowDeleteConfirmationModal } = useUploadModal();
    const { resetTemplatePreviewsAndColors } = useBackDesignTemplate();
    const { fireDeleteBacksideEvent } = useBackDesignEvents();

    const disabled = panel && !hasSingleImageItem(panel);
    const iconColor = disabled ? ICON_COLOR.DISABLED : ICON_COLOR.DEFAULT;

    const handleFitToBleed = () => {
        if (panel) {
            fireTrackingEvent({
                label: QUAD_TRACKING_EVENTS.FIT_TO_BLEED,
                eventDetail: GA_EVENTS.CLICK_HEAD_PANEL_FIT_TO_BLEED.replace(
                    '[CANVAS FACE]',
                    panel.panelProperties.name
                ),
                panel,
            });
            fitToBleed();
        }
    };

    const handleFitToSafe = () => {
        if (panel) {
            fireTrackingEvent({
                label: QUAD_TRACKING_EVENTS.FIT_TO_SAFE,
                eventDetail: GA_EVENTS.CLICK_HEAD_PANEL_FIT_TO_SAFETY.replace(
                    '[CANVAS FACE]',
                    panel.panelProperties.name
                ),
                panel,
            });

            fitToSafety();
        }
    };

    const handleRotate = () => {
        if (panel) {
            fireTrackingEvent({
                label: QUAD_TRACKING_EVENTS.ROTATION,
                eventDetail: GA_EVENTS.CLICK_HEAD_PANEL_ROTATE.replace('[CANVAS FACE]', panel.panelProperties.name),
                panel,
            });

            rotateItems();
        }
    };

    const handleDelete = () => {
        if (panel) {
            triggerReverseAutoMapEvent({ panel });
            fireTrackingEvent({
                label: QUAD_TRACKING_EVENTS.CLICK_DELETE_DESIGN,
                eventDetail: GA_EVENTS.CLICK_HEAD_PANEL_DELETE.replace('[CANVAS FACE]', panel.panelProperties.name),
                panel,
            });

            const frontPanel = asJson.document.panels.find((panel) => panel.name.toLowerCase() === PanelSides.Front);
            const frontPanelItems = frontPanel ? getAllItemsFromPanel(frontPanel) : [];

            if (panel.panelProperties.name.toLowerCase() === PanelSides.Back) {
                if (!frontPanelItems.length) {
                    onRemoveItemFromPanel();
                    fireDeleteBacksideEvent();
                } else {
                    setShowDeleteConfirmationModal(true);
                }
            } else {
                onRemoveItemFromPanel();
                resetTemplatePreviewsAndColors();
            }
        }
    };

    return { disabled, iconColor, handleFitToBleed, handleFitToSafe, handleRotate, handleDelete };
};
