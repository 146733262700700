import React from 'react';
import { FlexBox } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { AutoRecognitionSet } from './AutoRecognitionSet';
import { Orientations } from './Orientations';
import { Sizes } from './Sizes';
import { changeSizeStore } from '../store';

export const ChangeSizePanelContent = observer(() => {
    const { activeOrientation, autoRecognitionData } = changeSizeStore;
    const showAutoRecognitionOptions =
        autoRecognitionData && autoRecognitionData.detectedOrientation === activeOrientation;

    return (
        <FlexBox flexDirection="column">
            <Orientations />
            {showAutoRecognitionOptions ? <AutoRecognitionSet /> : <Sizes />}
        </FlexBox>
    );
});
