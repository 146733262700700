import React from 'react';
import { PreviewClip, PreviewImage, PreviewPanArea } from '@design-stack-vista/preview-components';
import { css } from '@emotion/css';
import { className, FlexBox } from '@vp/swan';
import { DesignPreviewLoader } from '../DesignPreview/DesignPreviewLoader';
import { PreviewDescription } from '../DesignPreview/PreviewDescription';

const previewClipStyle = css`
    max-width: 100%;
    max-height: 100%;
    cursor: grab;
`;

const previewWrapperStyle = css`
    max-height: 100%;
    max-width: 100%;
`;

export const TwoDPreview = () => {
    return (
        <>
            <FlexBox
                alignItems="center"
                justifyContent="center"
                className={className(previewWrapperStyle, 'two-d-preview')}
                data-testid="scene-preview"
            >
                <PreviewClip className={previewClipStyle}>
                    <PreviewPanArea>
                        <PreviewImage loadingElement={<DesignPreviewLoader testId="2d-preview-loader" />} />
                    </PreviewPanArea>
                </PreviewClip>
            </FlexBox>
            <PreviewDescription />
        </>
    );
};
