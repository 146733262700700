import { Headers } from '@design-stack-ct/utility-core';
import { REQUESTOR, Tenants } from '../config/constant';
import { WorkEntity, WorkEntityResponse } from '../types/workEntityTypes';
import { fetchWrapper } from '../utils';
import { isCareAgent, isCareAgentEditingCareAgentDocument, ownerId } from '../utils/envUtils';

const WES_BASE_PATH = 'https://workentityservice.design.vpsvc.com/api/v1';
const WES_BASE_PATH_V2 = 'https://workentityservice.design.vpsvc.com/api/v2';

export const createWorkEntity = async (workEntity: WorkEntity, authorizationHeader: string) => {
    let url = `${WES_BASE_PATH}/works?from=${REQUESTOR}`;
    if (isCareAgent() && !isCareAgentEditingCareAgentDocument()) {
        if (!ownerId) {
            throw new Error('Please define owner query param');
        }
        url += `?ownerId=${ownerId}`;
    }
    const response = await fetchWrapper(url, {
        method: 'POST',
        requestInitiator: 'createWorkEntity',
        headers: {
            From: REQUESTOR,
            Accept: 'application/json',
            Authorization: authorizationHeader,
        },
        body: workEntity,
    });

    return response;
};

export type FetchWorkEntity = (
    authHeaders: string,
    requestor: string,
    workId: string,
    signal?: AbortSignal
) => Promise<WorkEntityResponse>;

export const fetchWorkEntity: FetchWorkEntity = async (authorizationHeader, requestor, workId, signal) => {
    // We want to allow anyone to view deleted documents, so fetching with includeHidden=true
    const response = await fetchWrapper(
        `${WES_BASE_PATH}/works/${encodeURIComponent(workId)}?includeHidden=true&timeStamp=${new Date().toISOString()}`,
        {
            method: 'GET',
            requestInitiator: 'fetchWorkEntity',
            headers: {
                From: requestor,
                Accept: 'application/json',
                Authorization: authorizationHeader,
            },
            signal,
        }
    );

    return response;
};

export const updateWorkEntity = async (workEntity: WorkEntity, authorizationHeader: string) => {
    let url = `${WES_BASE_PATH}/works/${workEntity.workId}/update`;
    if (isCareAgent() && !isCareAgentEditingCareAgentDocument()) {
        if (!ownerId) {
            throw new Error('Please define owner query param');
        }
        url += `?ownerId=${ownerId}`;
    }

    const response = await fetchWrapper(url, {
        method: 'POST',
        requestInitiator: 'updateWorkEntity',
        headers: {
            From: REQUESTOR,
            Accept: 'application/json',
            Authorization: authorizationHeader,
        },
        body: workEntity,
    });

    return response;
};

export type FetchWorkEntityFilterPRD = (
    productKey: string,
    authHeaders: Headers,
    signal?: AbortSignal
) => Promise<WorkEntityResponse[]>;

export const fetchWorkEntityFilterPRD: FetchWorkEntityFilterPRD = async (productKey, authHeaders, signal) => {
    const pageSize = 50;
    const response = await fetchWrapper(
        `${WES_BASE_PATH_V2}/works:search?tenants=${Tenants.VistaprintProd}&pageSize=${pageSize}&from=${REQUESTOR}&productKey=${productKey}`,
        {
            method: 'GET',
            requestInitiator: 'fetchWorkEntityFilterPRD',
            headers: {
                From: REQUESTOR,
                Accept: 'application/json',
                ...authHeaders,
            },
            signal,
        }
    );

    return response;
};

// fetchWorkEntityFilterPRD
