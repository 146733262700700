import React from 'react';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { DesignLayer } from '@design-stack-vista/ida-framework';
import { observer } from 'mobx-react-lite';
import { DesignAreaNotification } from './DesignAreaNotification';
import { Validation } from '../Validations/type';
import { useValidation } from '../Validations/ValidationProvider';

interface DesignNotificationLayerProp {
    panel: PanelState;
}

const filterValidationsFromCurrentPanel = (validation: Validation, panelId: string) => validation.panelId === panelId;

export const DesignNotificationLayer = observer(({ panel }: DesignNotificationLayerProp) => {
    const {
        validationStore: { validations },
    } = useValidation();

    const {
        layoutStore: { zoom },
    } = useDesignEngine();

    const filteredValidations = validations.filter((validation) =>
        filterValidationsFromCurrentPanel(validation, panel.id)
    );

    return (
        <DesignLayer name="validation">
            {filteredValidations.map(({ iconPlacement, itemId, validationType }) => {
                const iconPlacementWithZoom = {
                    x: iconPlacement.x * zoom,
                    y: iconPlacement.y * zoom,
                };
                return (
                    <DesignAreaNotification
                        key={`${itemId}-${validationType}}`}
                        itemId={itemId}
                        panel={panel}
                        iconPlacementWithZoom={iconPlacementWithZoom}
                        validationType={validationType}
                    />
                );
            })}
        </DesignLayer>
    );
});
