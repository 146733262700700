import React from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { FlexBox, SelectionSet, SkeletonText, SkeletonTextLine } from '@vp/swan';
import { SelectionOption } from '../../../components/SelectionOption';
import { PanelSides } from '../../../config/constant';
import { getPanelByName } from '../../UploadPanel/components/UploadBackDesignModal/helper';
import { MAX_NUM_COLORS } from '../constant';
import { selectLabelStyles } from '../style';

const colorSkeletonSelectStyle = css`
    ${selectLabelStyles};
    &.swan-selection-set.swan-selection-set-skin-tiles-horizontal {
        .swan-selection-set-label {
            min-height: auto;
        }
    }
`;

export const ColorPreviewSkeleton = () => {
    const skeletonItems = Array.from(Array(MAX_NUM_COLORS).keys());
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const backPanel = getPanelByName(panels, PanelSides.Back);

    if (!backPanel) return null;

    return (
        <SelectionSet className={colorSkeletonSelectStyle} skin="tiles-horizontal" variant="single-select">
            <FlexBox flexDirection="row" justifyContent="space-between" gap="4">
                {skeletonItems.map((item) => (
                    <SelectionOption panel={backPanel} key={`color-skeleton-${item}`}>
                        <SkeletonText style={{ height: '100%' }}>
                            <SkeletonTextLine style={{ height: '100%' }}></SkeletonTextLine>
                        </SkeletonText>
                    </SelectionOption>
                ))}
            </FlexBox>
        </SelectionSet>
    );
};
