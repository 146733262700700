import { useProductConfiguration } from '../../../hooks/calcifer';
import { useProductParams } from '../../../providers';
import { supportedLocales, supportedMpvIds } from '../constant';

export const useBackDesignFlags = () => {
    const { productData } = useProductConfiguration();
    const { locale } = useProductParams();
    let isBackDesignEnabled = false;

    if (productData) {
        const { mpvId } = productData;
        const isSupportedMpvId = supportedMpvIds.includes(mpvId);
        const isSupportedLocale = supportedLocales.includes(locale);

        if (isSupportedMpvId && isSupportedLocale) {
            isBackDesignEnabled = true;
        }
    }

    return { isBackDesignEnabled };
};
