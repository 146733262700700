import { css } from '@emotion/css';
import { tokens } from '@vp/swan';

export const selectLabelStyles = css`
    &.swan-selection-set.swan-selection-set-skin-tiles-horizontal {
        .swan-selection-set-label {
            padding: ${tokens.SwanSemSpace6};
            background: ${tokens.SwanSemColorBgStrong};
            justify-content: center;
            align-items: normal;
            height: 100%;
            width: 100%;
            min-width: auto;

            .swan-display-flex {
                width: 100%;
            }
        }
    }
`;

export const templateThumbStyle = css`
    display: flex;
    flex-wrap: wrap;
    gap: ${tokens.SwanSemSpace5};
`;
