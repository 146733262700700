import React from 'react';
import { css } from '@emotion/css';
import { tokens } from '@vp/swan';
import { useUpsellPrice } from '../../providers';

interface Props {
    panelName: string;
}

const priceStyle = css`
    font-weight: 400;
    opacity: 0.7;
    margin-left: ${tokens.SwanSemSpace1};
`;

export const UpsellPricingText = ({ panelName }: Props) => {
    const { getFormattedDiscountPrice } = useUpsellPrice();
    const discountPrice = getFormattedDiscountPrice(panelName);

    return <>{discountPrice && <span className={priceStyle}>{`(+${discountPrice})`}</span>}</>;
};
