/**
 * Studio Reference
 * {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/f554885e27bded8974ec3648a69377d421f7f5ef/apps/studio/src/shared/features/Flexibility/common/hooks/useFlexibilityMerchandisingData.ts}
 */
import { useCallback } from 'react';
import { useProductConfiguration } from './calcifer';
import {
    mapDesignAttributeToProductOption,
    mapDesignAttributeValueToProductOptionValue,
    mapProductOptionValueToDesignAttributeValue,
} from '../features/Flexibility/common';
import { ProductAttributesKeyAndValues, ProductAttributeValue } from '../types/secondaryConfigTypes';

// This hook helps us to get the translated product option values
// E.G. for Product orientation these hook returns
// Horizontal in en-us & Horizontale in fr-ca
export const useTranslatedConfig = () => {
    const { productData, secondaryConfig } = useProductConfiguration();

    const productAttributeAndValues = secondaryConfig?.productAttributeAndValues;
    const designAttributeMappings = secondaryConfig?.designAttributes || [];
    const selectedOptions = productData?.selectedOptions || {};

    // Returns the current product option value
    const getCurrentDesignAttributeValue = useCallback(
        (designAttributeName: string) => {
            const productOptionName = mapDesignAttributeToProductOption(designAttributeMappings, designAttributeName);
            const currentProductOptionValue =
                selectedOptions[productOptionName] || selectedOptions[designAttributeName];

            return mapProductOptionValueToDesignAttributeValue(
                designAttributeMappings,
                productOptionName,
                currentProductOptionValue
            );
        },
        [designAttributeMappings, selectedOptions]
    );

    // Given the product option and corresponding value returns the translated value
    const getTranslatedOptionName = useCallback(
        (designAttributeName: string, designAttributeValue: string) => {
            const productOptionName = mapDesignAttributeToProductOption(designAttributeMappings, designAttributeName);
            const productOptionValue = mapDesignAttributeValueToProductOptionValue(
                designAttributeMappings,
                designAttributeName,
                designAttributeValue
            );

            const kvPair =
                Array.isArray(productAttributeAndValues) &&
                productAttributeAndValues.length !== 0 &&
                productAttributeAndValues.find(
                    (keyAndValue: ProductAttributesKeyAndValues) => keyAndValue.key.key === productOptionName
                );

            const attributesAndValues = kvPair
                ? kvPair.value.find((value: ProductAttributeValue) => value.key === productOptionValue)
                : undefined;

            return attributesAndValues ? attributesAndValues.merchandisingName : designAttributeValue;
        },
        [getCurrentDesignAttributeValue, designAttributeMappings, productAttributeAndValues]
    );

    const getCurrentTranslatedOptionName = useCallback(
        (designAttributeName: string) => {
            const currentDesignAttributeValue = getCurrentDesignAttributeValue(designAttributeName);
            const translatedOptionName = getTranslatedOptionName(designAttributeName, currentDesignAttributeValue);
            return translatedOptionName;
        },
        [getCurrentDesignAttributeValue, getTranslatedOptionName]
    );

    return {
        getTranslatedOptionName,
        getCurrentTranslatedOptionName,
    };
};
