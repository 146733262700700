import React from 'react';
import { css } from '@emotion/css';
import { Button, FlexBox, FlexBoxProps, Li, tokens, Typography, Ul } from '@vp/swan';
import { PlaceholderInfoType, TemplatePlaceholderList } from '../../features/BackDesignTemplate';
import { text } from '../../utils/localization';
import { DesktopExperience } from '../Experiences';
import { WarningErrorIcon } from '../SvgIcons/WarningErrorIcon';

const warningErrorIcon = css`
    width: 23px;
    height: 23px;
    padding-top: ${tokens.SwanSemSpace1};
`;

const messages = {
    reviewStepModalHeader: {
        id: 'reviewStep.reviewStepModalHeader',
    },
    confirmationQuestion: {
        id: 'reviewStep.confirmationQuestion',
    },
    checkListItem_text: {
        id: 'reviewStep.checkListText',
    },
    checkListItem_margin: {
        id: 'reviewStep.checkListMargin',
    },
    checkListItem_info: {
        id: 'reviewStep.checkListItem_info',
    },
    checkListItem_images: {
        id: 'reviewStep.checkListItem_images',
    },
    placeholderWarningText: {
        id: 'reviewStep.placeholderWarningText',
    },
    placeholderWarningDescriptionFirst: {
        id: 'reviewStep.placeholderWarningDescriptionFirst',
    },
    editDesignButton: {
        id: 'reviewStep.editDesignButton',
    },
};

interface Props extends FlexBoxProps {
    handleContinueEditingClick: () => void;
    placeholderTextPresent: boolean;
    containerClass?: string;
}

export function DesignReviewDescription({
    handleContinueEditingClick,
    placeholderTextPresent,
    containerClass,
    ...rest
}: Props) {
    return (
        <FlexBox py="6" flexDirection="column" gap="6" className={containerClass} {...rest}>
            <DesktopExperience>
                <FlexBox flexDirection="column">
                    <Typography fontSize="x2large" fontWeight="bold" mb="6" data-testid="design-review-header">
                        {text(messages.reviewStepModalHeader.id)}
                    </Typography>
                    <Typography fontSize="large" fontWeight="bold" mb="4" data-testid="design-review-subheader">
                        {text(messages.confirmationQuestion.id)}
                    </Typography>
                    <Ul skin="checkmark" data-testid="design-review-checklist">
                        <Li>{text(messages.checkListItem_text.id)}</Li>
                        <Li>{text(messages.checkListItem_margin.id)}</Li>
                        <Li>{text(messages.checkListItem_info.id)}</Li>
                        <Li>{text(messages.checkListItem_images.id)}</Li>
                    </Ul>
                </FlexBox>
            </DesktopExperience>
            {placeholderTextPresent && (
                <FlexBox>
                    <WarningErrorIcon className={warningErrorIcon} aria-hidden="true" />
                    <div className="placeholder-warning-message-icon-text">
                        <Typography fontWeight="bold" textAlign="left" textColor="error" pl="2">
                            {text(messages.placeholderWarningText.id)}
                        </Typography>
                        <Typography fontSize="xsmall" textAlign="left" textColor="error" pl="2" pt="3" mb="5">
                            {text(messages.placeholderWarningDescriptionFirst.id)}
                        </Typography>
                        <TemplatePlaceholderList
                            showPanelName={true}
                            placeholderInfoType={PlaceholderInfoType.Warning}
                            fontSize="xsmall"
                            isLink={true}
                        />
                    </div>
                </FlexBox>
            )}
            <Button
                skin="secondary"
                width="full-width"
                size="mini"
                onClick={handleContinueEditingClick}
                data-testid="continueEditingButton"
            >
                {text(messages.editDesignButton.id)}
            </Button>
        </FlexBox>
    );
}
DesignReviewDescription.displayName = 'DesignReviewDescription';
