import React, { forwardRef, Ref } from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens, Typography } from '@vp/swan';
import { useLastSavedText } from '../../../../hooks/useLastSavedText';
import { useSaveWork } from '../../../../providers/SaveWorkProvider';
import { CheckMarkIcon } from '../../../SvgIcons';

interface Props {
    popperStyles: React.CSSProperties;
    popperAttributes?: Record<string, string>;
    isVisible: boolean;
}

const getSaveTooltipStyle = (isVisible: boolean) => css`
    background-color: ${tokens.SwanSemColorBgStandard};
    padding: ${tokens.SwanSemSpace4};
    border: 1px solid ${tokens.SwanSemColorBorderStandard};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 249px;
    margin-left: 20px;
    visibility: hidden;
    ${isVisible === true && `visibility: visible;`}
`;

const tooltipTextStyle = css`
    opacity: 0.7;
`;

const checkMarkIconStyle = css`
    margin-right: ${tokens.SwanSemSpace2};
    height: 20px;
    width: 16px;
`;

export const SaveChangesTooltip = forwardRef(
    ({ popperStyles, popperAttributes, isVisible }: Props, ref: Ref<HTMLDivElement>) => {
        const { lastWorkEntity } = useSaveWork();
        const lastSavedText = useLastSavedText(lastWorkEntity?.modified);

        return (
            <>
                {lastSavedText && (
                    <FlexBox
                        role="tooltip"
                        ref={ref}
                        style={popperStyles}
                        {...popperAttributes}
                        className={getSaveTooltipStyle(isVisible)}
                    >
                        <CheckMarkIcon className={checkMarkIconStyle} />
                        <Typography fontSize="small" fontWeight="bold" className={tooltipTextStyle}>
                            {lastSavedText}
                        </Typography>
                    </FlexBox>
                )}
            </>
        );
    }
);

SaveChangesTooltip.displayName = 'SaveChangesTooltip';
