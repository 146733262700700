import React from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import {
    Button,
    FlexBox,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
    tokens,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { PanelSides } from '../../config/constant';
import { useBackDesignEvents } from '../../features/BackDesignTemplate';
import {
    UPLOAD_BACK_DESIGN_MODAL_TYPES,
    useUploadBackDesignModal,
} from '../../features/UploadPanel/components/UploadBackDesignModal';
import { getPanelByName } from '../../features/UploadPanel/components/UploadBackDesignModal/helper';
import { useActivePanel } from '../../hooks/useActivePanel';
import { usePanelTitle } from '../../hooks/usePanelTitle';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { useUploadPanels } from '../../hooks/useUploadPanel';
import { useUploadModal, useUpsellPrice } from '../../providers';
import { text } from '../../utils/localization';

const modalContentStyle = css`
    &.swan-modal-dialog.swan-modal-dialog-visible {
        max-width: 492px;
    }

    &.swan-modal-dialog.swan-modal-dialog-panel.swan-modal-dialog-panel-bottom {
        border-radius: ${tokens.SwanSemBorderRadiusContainer} ${tokens.SwanSemBorderRadiusContainer} 0 0;
    }
`;

export const DeleteConfirmationModal = observer(() => {
    const designEngine = useDesignEngine();
    const {
        cimDocStore: { panels },
    } = designEngine;
    const { isSmall } = useScreenLayout();
    const panel = useActivePanel();
    const { getFormattedDiscountPrice } = useUpsellPrice();
    const { onRemoveItemFromPanel } = useUploadPanels();
    const { showDeleteConfirmationModal, setShowDeleteConfirmationModal } = useUploadModal();
    const { getPanelTitle } = usePanelTitle();
    const { showUploadBackDesignModal } = useUploadBackDesignModal();
    const { fireDeleteBacksideEvent } = useBackDesignEvents();

    if (!panel) {
        return null;
    }

    const backPanel = getPanelByName(panels, PanelSides.Back);
    const panelTitle = getPanelTitle(backPanel?.panelProperties.name)?.toLowerCase();
    const discountPrice = getFormattedDiscountPrice(panel.panelProperties.name);
    const priceDifference = discountPrice ? `-${discountPrice}` : '';

    const handleClose = () => {
        setShowDeleteConfirmationModal(false);
    };

    const handleDelete = () => {
        onRemoveItemFromPanel();
        handleClose();
        fireDeleteBacksideEvent();
    };

    const handleChange = () => {
        showUploadBackDesignModal(UPLOAD_BACK_DESIGN_MODAL_TYPES.UPLOAD_ASSET);
        setShowDeleteConfirmationModal(false);
    };

    return (
        <ModalDialog
            isOpen={showDeleteConfirmationModal}
            onRequestDismiss={handleClose}
            variant={isSmall ? 'panel-bottom' : 'standard'}
            onlyRenderWhenOpen
        >
            <ModalDialogContent aria-labelledby={text('deleteConfirmationAriaLabel')} className={modalContentStyle}>
                <ModalDialogNav mb="0">
                    <ModalDialogCloseButton accessibleText={text('closeAccessibilityLabel')} />
                </ModalDialogNav>
                <ModalDialogHeader mb="5">
                    <ModalDialogTitle>{text('deleteConfirmationModalHeader', { panelTitle })}</ModalDialogTitle>
                </ModalDialogHeader>
                <ModalDialogBody>{text('deleteConfirmationModalInfo')}</ModalDialogBody>
                <ModalDialogFooter mt="5">
                    <FlexBox flexDirection="column" gap="4">
                        <Button size={isSmall ? 'mini' : 'standard'} textColor="error" onClick={handleDelete}>
                            {text('deleteButtonLabel', {
                                panelTitle,
                                priceDifference,
                            })}
                        </Button>
                        <Button size={isSmall ? 'mini' : 'standard'} onClick={handleChange}>
                            {text('changeButtonLabel', { panelTitle })}
                        </Button>
                    </FlexBox>
                </ModalDialogFooter>
            </ModalDialogContent>
        </ModalDialog>
    );
});
