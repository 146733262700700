import React, { useMemo } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { tokens, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { SparkleIcon } from '../../components/SvgIcons';
import { ICON_COLOR } from '../../config/ColorConstant';
import { usePremiumFinishStore } from '../../providers';
import { capitalizeFirstLetter } from '../../utils';
import { text } from '../../utils/localization';
import { DialogMessageKey, FinishOption } from '../PremiumFinish';
import { usePremiumFinishData } from '../PremiumFinish/Modal/usePremiumFinishData';
import { Tooltip } from '../quad/components/Tooltip';

const tooltipStyle = css`
    position: absolute;
    inset: 4px 4px auto auto;
    z-index: 1;
`;

const tooltipTriggererStyle = css`
    background: ${tokens.SwanSemColorBgStandard};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    height: 20px;
    width: 20px;

    svg {
        pointer-events: all;
    }
`;

const tooltipContainerStyle = css`
    width: 226px;
    padding: ${tokens.SwanSemSpace4};
    margin: 0 0 -4px 100px;
    border: 1px solid ${tokens.SwanSemColorBorderStandard};
    border-radius: 0;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
    background: ${tokens.SwanSemColorBgStandard};

    /**
     *  This is to hide the arrow that would be seen when hovered, over the finish Icon.
     */
    .css-16jb7a8 {
        visibility: hidden;
    }
`;

interface Props {
    panelId: string;
}

export const PreviewFinishIcon = observer(({ panelId }: Props) => {
    const { defaultPremiumFinish, isPremiumFinishProduct } = usePremiumFinishStore();
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const { defaultFinishOptions } = usePremiumFinishData();

    const tooltipMessage = useMemo(() => {
        const finish = capitalizeFirstLetter(defaultPremiumFinish.toLowerCase());
        return defaultFinishOptions
            ? `${finish} ${text(DialogMessageKey[defaultFinishOptions as FinishOption])}.`
            : `${finish}.`;
    }, [defaultPremiumFinish, defaultFinishOptions]);

    if (panels[0].id !== panelId || !isPremiumFinishProduct) {
        return null;
    }

    return (
        <div className={tooltipStyle}>
            <Tooltip
                tooltipContainerClassName={tooltipContainerStyle}
                content={
                    <>
                        <Typography fontSize="xsmall" fontWeight="bold" textColor="standard" mb="2">
                            {text('premiumFinish')}
                        </Typography>
                        <Typography fontSize="xsmall" fontWeight="bold" textColor="subtle">
                            {tooltipMessage}
                        </Typography>
                    </>
                }
                placement="top"
            >
                <div className={tooltipTriggererStyle}>
                    <SparkleIcon fill={ICON_COLOR.SPARKLE} width={16} height={16} />
                </div>
            </Tooltip>
        </div>
    );
});
