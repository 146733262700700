import { CimDocMetadata, Panel } from '@design-stack-vista/cdif-types';
import { FRONT } from '../config/constant';

type DesignDialogsSeen = {
    [panelName: string]: boolean;
};
// This function is used to add metadata to the CimDoc that tracks which panels have been seen by the user for studio
export const addDesignDialogMetadata = (
    metadata: CimDocMetadata | undefined,
    panels: Panel[]
): CimDocMetadata | undefined => {
    if (!metadata || metadata.designDialogsSeen) {
        return metadata;
    }
    const designDialogsSeen: DesignDialogsSeen = {};
    const documentSources = metadata.documentSources.panels;
    panels.forEach((panel) => {
        const panelId = panel.id;
        const panelSource = documentSources.find((source: { id: string }) => source.id === panelId);
        // Only mark the panel as seen if it is not the Front panel
        if (panelSource && panel.name !== FRONT) {
            designDialogsSeen[panel.name] = true;
        }
    });

    return {
        ...metadata,
        designDialogsSeen,
    };
};
