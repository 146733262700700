import React, { createContext, useContext } from 'react';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { observer } from 'mobx-react-lite';
import { PremiumFinishModalProvider } from './PremiumFinishModalProvider';
import { useProductParams } from './ProductParamsProvider';
import { premiumFinishStore } from './Store/PremiumFinishStore';
import { ChoiceGroupsPricing } from '../features/Flexibility/common';
import { usePremiumFinish } from '../features/PremiumFinish/usePremiumFinish';
import { ProductData } from '../hooks/calcifer/useProductConfiguration';

interface PremiumFinishContextInterface {
    isPremiumFinishProduct: boolean;
    supportsPremiumFinish: boolean;
    isPremiumCard: boolean;
    defaultPremiumFinish: string;
    pricing: ChoiceGroupsPricing;
    supportedPremiumVariants: string[];
}

const PremiumFinishContext = createContext<PremiumFinishContextInterface | null>(null);

export const usePremiumFinishStore = () => {
    const value = useContext(PremiumFinishContext);
    if (!value) {
        throw new Error('usePremiumFinish must be used within a PremiumFinishProvider');
    }
    return value;
};

interface PremiumFinishProviderProps {
    productData: ProductData;
    children?: React.ReactNode | React.ReactNode[];
}

export const PremiumFinishProvider = observer(({ productData, children }: PremiumFinishProviderProps) => {
    const { locale } = useProductParams();
    const { setSupportedVariantProperties, setPremiumPricing } = premiumFinishStore;
    const { auth: vpAuth, isIdentityInitialized } = useIdentityContext();
    const authHeader = isIdentityInitialized ? vpAuth.getAuthorizationHeader() : null;
    const { shouldInitializePremiumFinishStore } = usePremiumFinish(productData);

    useAsyncEffect(
        (helpers) => {
            async function initializePremiumFinishStore() {
                await setSupportedVariantProperties(productData, authHeader, helpers.abortSignal);
                await setPremiumPricing(productData, locale, helpers.abortSignal);
            }
            if (shouldInitializePremiumFinishStore && authHeader) {
                initializePremiumFinishStore();
            }
        },
        [productData, locale, shouldInitializePremiumFinishStore, authHeader]
    );

    return (
        <PremiumFinishContext.Provider value={premiumFinishStore}>
            <PremiumFinishModalProvider>{children}</PremiumFinishModalProvider>
        </PremiumFinishContext.Provider>
    );
});
