import React from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { className, FlexBox } from '@vp/swan';
import { QuickToolShape } from './QuickToolShape';
import { ChangeSizeButton } from '../ChangeSize';
import { DecorationAreaButton } from '../DecorationArea';
import { DesignPreviewModalButton } from '../DesignPreview/DesignPreviewModalButton';

const fullWidthFlexbox = css`
    width: 100%;
`;

export const DefaultQuickToolItems = () => {
    const {
        layoutStore: { visibleItems },
    } = useDesignEngine();

    const haveItems = visibleItems.length > 0;

    return (
        <FlexBox
            gap={haveItems ? '3' : null}
            justifyContent={!haveItems ? 'space-around' : null}
            className={className({ [fullWidthFlexbox]: !haveItems })}
        >
            <DesignPreviewModalButton />
            <ChangeSizeButton />
            <QuickToolShape />
            <DecorationAreaButton />
        </FlexBox>
    );
};
