import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { SparkleIcon } from '../../components/SvgIcons';
import { ICON_COLOR } from '../../config/ColorConstant';
import { usePremiumFinishStore } from '../../providers';
import { text } from '../../utils/localization';
import { QuickToolItem } from '../QuickTools/QuickToolItem';

interface Props {
    onClick?: () => void;
    finishIconDisabled: boolean;
}

export const FinishButton = observer(({ onClick, finishIconDisabled }: Props) => {
    const { isPremiumFinishProduct } = usePremiumFinishStore();

    const iconColor = useMemo(() => {
        if (finishIconDisabled) {
            return ICON_COLOR.DISABLED;
        }
        if (isPremiumFinishProduct) {
            return ICON_COLOR.SPARKLE;
        }
        return ICON_COLOR.DEFAULT;
    }, [isPremiumFinishProduct, finishIconDisabled]);

    return (
        <QuickToolItem
            dataTestId="premium-finish-button"
            Icon={<SparkleIcon width="15" height="14" fill={iconColor} />}
            buttonLabel={text('finishButtonLabel')}
            disabled={finishIconDisabled}
            onClick={onClick}
        />
    );
});
