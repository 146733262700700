import React from 'react';
import { css } from '@emotion/css';
import { className, tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import UploadBackDesignModalBody from './components/UploadBackDesignModalBody';
import UploadBackDesignModalFooter from './components/UploadBackDesignModalFooter';
import UploadBackDesignModalHeader from './components/UploadBackDesignModalHeader';
import { useUploadBackDesignModal } from './useUploadBackDesignModal';
import { PanelRightModal } from '../../../../components/Modal/components/PanelRightModal';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';
import { useBackConfirmationContext } from '../../../../providers';

const modalPaddingStyle = css`
    .swan-modal-dialog {
        padding-left: ${tokens.SwanSemSpace6};
        padding-right: ${tokens.SwanSemSpace6};
        .swan-modal-dialog-nav {
            margin-right: calc(-1 * ${tokens.SwanSemSpace6});
        }
    }
    .swan-modal-dialog.swan-modal-dialog-panel.swan-modal-dialog-panel {
        max-width: 432px;
    }
`;

const UploadBackDesignModal = observer(() => {
    const { isSmall } = useScreenLayout();
    const { closeUploadBackDesignModal, onUploadButtonClick, footerAction } = useUploadBackDesignModal();

    const { openBackUploadModal, modalConfig } = useBackConfirmationContext();

    const { subHeadingText, footerButtonText, footerButtonSkin } = modalConfig;
    return (
        <PanelRightModal
            isOpen={openBackUploadModal}
            onClose={closeUploadBackDesignModal}
            data-testid="upload-back-design-modal"
            hasBorderRadius={true}
            className={className({ [modalPaddingStyle]: !isSmall })}
        >
            <UploadBackDesignModalHeader />
            <UploadBackDesignModalBody
                subHeadingText={subHeadingText}
                closeModal={closeUploadBackDesignModal}
                handleUploadButtonClick={onUploadButtonClick}
            />
            <UploadBackDesignModalFooter
                footerAction={footerAction}
                footerButtonSkin={footerButtonSkin}
                footerButtonText={footerButtonText}
                dataTestId="upload-back-design-modal-footer-action"
            />
        </PanelRightModal>
    );
});

export default UploadBackDesignModal;
