/**
 * Reference
 * {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/master/apps/studio/src/studioSix/features/UploadsAndAssets/useIsSelectionLocalImage.ts}
 */

import { useDesignEngine, useSelectedItems } from '@design-stack-vista/core-features';
import { ImageInstantUploadExtension } from '../ImageInstantUploadExtension';

export function useIsSelectionLocalImage() {
    const { designExtensionSystem } = useDesignEngine();
    const { selectedItems } = useSelectedItems();

    const isSelectionLocalImage = selectedItems.some(
        (item) => designExtensionSystem.getExtension(item.iid, ImageInstantUploadExtension)?.isUploading
    );

    return {
        isSelectionLocalImage,
    };
}
