import React from 'react';
import { css } from '@emotion/css';
import {
    Button,
    ModalDialog,
    ModalDialogBody,
    ModalDialogButtons,
    ModalDialogFooter,
    ModalDialogHeader,
    Typography,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { StandardModal } from '../../components/Modal/components/StandardModal';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { usePremiumFinishModal, usePremiumFinishStore } from '../../providers';
import { text } from '../../utils/localization';

export type DesignPreviewModalProps = React.ComponentProps<typeof ModalDialog>;

const confirmationDialogStyle = css`
    .swan-modal-dialog {
        width: 525px;
    }
`;

export const PremiumFinishConfirmationModal = observer(
    ({ isOpen = false, onRequestDismiss }: DesignPreviewModalProps): JSX.Element => {
        const { defaultPremiumFinish } = usePremiumFinishStore();
        const { openModal } = usePremiumFinishModal();
        const { isSmall } = useScreenLayout();

        const onButtonClick = () => {
            onRequestDismiss();
            openModal();
        };

        return (
            <StandardModal
                isOpen={isOpen}
                onClose={onRequestDismiss}
                ariaLabel={text('premiumConfirmationModalAria')}
                className={confirmationDialogStyle}
            >
                <ModalDialogHeader ml="0" mt="4" mb="6">
                    <Typography fontSize="x2large" fontWeight="bold">
                        {text('premiumConfirmationModalHeader', {
                            defaultPremiumFinish,
                        })}
                    </Typography>
                </ModalDialogHeader>
                <ModalDialogBody>
                    <Typography fontSize="standard">
                        {text('premiumConfirmationModalBody', {
                            defaultPremiumFinish,
                        })}
                    </Typography>
                </ModalDialogBody>
                <ModalDialogFooter mt="6">
                    <ModalDialogButtons>
                        <Button
                            skin="primary"
                            size={isSmall ? 'mini' : 'standard'}
                            width={isSmall ? 'full-width' : 'standard'}
                            my="0"
                            onClick={onButtonClick}
                        >
                            {text('premiumConfirmationModalBtn')}
                        </Button>
                    </ModalDialogButtons>
                </ModalDialogFooter>
            </StandardModal>
        );
    }
);
