import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { ReviewComponent } from './ReviewComponent';
import { UploadPanelPreviews } from './UploadPanelsPreviews';

export const uploadPanelStyle = css`
    min-width: 350px;
    width: 440px;
    overflow: auto;
    border-left: 1px solid ${tokens.SwanSemColorBorderStandard};
`;

export const UploadPanel = observer(() => {
    return (
        <FlexBox flexDirection="column" className={uploadPanelStyle} data-testid="upload-panel" p="6">
            <UploadPanelPreviews />
            <ReviewComponent />
        </FlexBox>
    );
});
