import React, { useCallback, useRef } from 'react';
import { useClickOutside } from '@design-stack-vista/utility-react';
import { css } from '@emotion/css';
import { Button, FlexBox, Icon, PopoverCloser, PopoverContent, tokens, Typography } from '@vp/swan';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';
import { updateExplorePopoverSeenStatus } from '../utils/exploreFinishPopover';

const popoverContentStyleDesktop = css`
    &.swan-popover .explore-finish-dialog-body {
        color: ${tokens.SwanSemColorTextSubtle};
        max-width: 237px;
        margin: ${tokens.SwanSemSpace1} 0 20px;
    }
    .swan-popover-close {
        font-size: 10px;
        cursor: pointer;
    }
`;

const popoverContentMobileStyle = css`
    &.swan-popover {
        border-radius: ${tokens.SwanSemSpace4};
    }
    &.swan-popover .explore-finish-dialog-body {
        color: ${tokens.SwanSemColorTextSubtle};
        margin: ${tokens.SwanSemSpace3} 0 ${tokens.SwanSemSpace5};
    }
    .swan-popover-close {
        font-size: 10px;
    }
    .explore-finish-cta {
        max-width: 152px;
    }
`;

interface Props {
    onExploreButtonClick: () => void;
    hideExplorePopover: () => void;
    popoverDialogStyle?: string;
}

export const ExplorePremiumFinishDialog = ({ onExploreButtonClick, hideExplorePopover, popoverDialogStyle }: Props) => {
    const popoverContentRef = useRef<HTMLDivElement>(null);

    // We need to close explore popover on clicking outside and also update session on clicking outside
    useClickOutside({ elementRef: popoverContentRef }, hideExplorePopover);

    const { isSmall } = useScreenLayout();
    const popoverContentStyle = isSmall ? popoverContentMobileStyle : popoverContentStyleDesktop;

    const handleExploreButtonClick = useCallback(() => {
        updateExplorePopoverSeenStatus();
        onExploreButtonClick();
    }, [onExploreButtonClick]);

    return (
        <PopoverContent
            data-testid="explore-premium-finish-popover"
            dialog
            className={`${popoverDialogStyle} ${popoverContentStyle}`}
            placement={isSmall ? 'top' : 'bottom'}
            ref={popoverContentRef}
        >
            <FlexBox flexDirection="column">
                <FlexBox justifyContent="space-between">
                    <Typography fontWeight="bold">{text('addFinishesTitle')}</Typography>
                    <PopoverCloser>
                        <Icon onClick={hideExplorePopover} iconType="closeSmall" />
                    </PopoverCloser>
                </FlexBox>
                <Typography className="explore-finish-dialog-body" fontSize="small">
                    {text('finishExploreMessage')}
                </Typography>
                <PopoverCloser>
                    <Button
                        width="full-width"
                        size="mini"
                        skin="primary"
                        data-testid="explore-premium-finish-cta"
                        className="explore-finish-cta"
                        onClick={handleExploreButtonClick}
                    >
                        {text('exploreFinishes')}
                    </Button>
                </PopoverCloser>
            </FlexBox>
        </PopoverContent>
    );
};
