import { CimDoc } from '@design-stack-vista/cdif-types';
import { fetchWrapper } from '../utils';

// Swagger link - https://design-engine.vpsvc.com/swagger/static/index.html
const SMART_DESIGN_ENGINE_SERVICE_URL = 'https://design-engine.vpsvc.com/v0/generateBackDesignLayouts';

export enum DesignVariant {
    ALL = 'All',
    FULLY_COMPLETED_DESIGNS = 'FullyCompletedDesigns',
}
interface GenerateDesignLayoutsProps {
    designVariant: DesignVariant;
    culture: string;
    product: {
        productId: string;
        productVersion: number;
        productOptions: Record<string, string>;
    };
    sourceDocument: CimDoc;
    authHeader: string;
    signal?: AbortSignal;
}

interface DebugInfo {
    purpose: string;
    input: string;
    output: string;
}

export interface DesignLayout {
    layoutId: string;
    cimDoc: {
        preview: CimDoc;
        editable: CimDoc;
    };
    completionPercent: number;
}

export interface DesignLayouts {
    designLayouts: DesignLayout[];
    debugInfo: DebugInfo[];
}

export const generateDesignLayouts = async ({
    designVariant,
    culture,
    product,
    sourceDocument,
    authHeader,
    signal,
}: GenerateDesignLayoutsProps): Promise<DesignLayouts | undefined> => {
    const body = {
        designVariant,
        culture,
        product,
        sourceDocument,
    };

    const response = await fetchWrapper(SMART_DESIGN_ENGINE_SERVICE_URL, {
        method: 'POST',
        requestInitiator: 'generateDesignLayouts',
        body,
        signal,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authHeader,
        },
    });

    return response;
};
