import { QUAD_SESSION_STORAGE_ACCESS_KEY, SESSION_STORAGE_KEY } from './constant';

export type KeyValueType = string | number | boolean | Record<string, string>;

export const setSessionStorageKey = (keys: SESSION_STORAGE_KEY, keyValue: KeyValueType) => {
    const val = sessionStorage.getItem(QUAD_SESSION_STORAGE_ACCESS_KEY);
    const value = JSON.parse(val === null ? '{}' : val);
    value[keys] = keyValue;

    sessionStorage.setItem(QUAD_SESSION_STORAGE_ACCESS_KEY, JSON.stringify(value));
};

export const getSessionStorageKey = (key: SESSION_STORAGE_KEY): KeyValueType | undefined => {
    const values = sessionStorage.getItem(QUAD_SESSION_STORAGE_ACCESS_KEY);
    if (values !== null) {
        const result = JSON.parse(values);
        return result[key];
    }
    return undefined;
};
