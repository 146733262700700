import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useProductConfiguration } from './calcifer';
import { useHandleCustomExperiences } from './useHandleCustomExperiences';
import { useNotificationFramework } from '../components/Notification';
import { PRICING_NOTIFICATION_DISMISS_TIMEOUT } from '../config/constant';
import { useUpsellPrice } from '../providers';
import { text } from '../utils/localization';

export const useRemoveItemEvents = () => {
    const { handleDeleteImageExperiences, handleDeleteTemplateExperiences } = useHandleCustomExperiences();
    const { getFormattedDiscountPrice, surfaceUpsells } = useUpsellPrice();
    const { updateCustomerSelectedOptions } = useProductConfiguration();
    const { notifyCustomer, removeAllNotifications } = useNotificationFramework();

    const handleProductUpdateOnUpsell = (panel: PanelState) => {
        if (surfaceUpsells) {
            const surfaceUpsell = surfaceUpsells[panel.panelProperties.name];

            if (surfaceUpsell) {
                const newOptions = { [surfaceUpsell.optionName]: surfaceUpsell.blankOption };
                updateCustomerSelectedOptions(newOptions);
            }
        }
    };

    const handleUpsellNotification = (panel: PanelState) => {
        const discountPrice = getFormattedDiscountPrice(panel.panelProperties.name);

        if (discountPrice) {
            notifyCustomer(
                {
                    notificationType: 'positive',
                    messageToShowCustomer: text('backDesignRemoved', {
                        formattedPrice: `-${discountPrice}`,
                    }),
                },
                true,
                PRICING_NOTIFICATION_DISMISS_TIMEOUT
            );
        }
    };

    const preRemoveItemEvents = () => {
        removeAllNotifications();
    };

    const postRemoveItemEvents = (panel: PanelState, isImageItem: boolean) => {
        handleProductUpdateOnUpsell(panel);
        handleUpsellNotification(panel);
        if (isImageItem) {
            handleDeleteImageExperiences(panel);
        } else {
            handleDeleteTemplateExperiences();
        }
    };

    return {
        preRemoveItemEvents,
        postRemoveItemEvents,
    };
};
