import { useCallback } from 'react';
import { useFlexibilityCimDocs } from './useFlexibilityCimDocs';
import { updateWindowQueryParams } from '..';
import { REQUESTOR } from '../../../../config/constant';
import { useProductConfiguration } from '../../../../hooks/calcifer';
import { sortOptions, useQueryProductConfiguration } from '../../../../hooks/calcifer/useQueryProductConfiguration';
import { useProductOptionsTracking, useProductParams } from '../../../../providers';
import { useSaveWork } from '../../../../providers/SaveWorkProvider';
import { getCountryFromLocale, noticeError } from '../../../../utils';
import { LoadProductData } from '../types';
import { checkIsNewProduct, getChangedAttributePairs, getProductOptions } from '../utils';

export const useLoadProduct = () => {
    const { setIsWorkSaved } = useSaveWork();
    const productOptionsTracking = useProductOptionsTracking();
    const { locale, selectedQuantity: qty } = useProductParams();
    const { getProductConfiguration } = useQueryProductConfiguration();
    const { getTransformedFlexibilityCimDocs } = useFlexibilityCimDocs();
    const { productData, workId, updateProductConfiguration } = useProductConfiguration();

    const loadProduct = useCallback(
        async ({ newProduct, newCimDoc }: LoadProductData) => {
            try {
                if (!productData) {
                    return false;
                }

                if (!checkIsNewProduct(productData, newProduct)) {
                    return false;
                }

                const { productKey, productVersion, productAttributes } = newProduct;

                const changedAttributePairs = getChangedAttributePairs(productData.selectedOptions, productAttributes);
                const newSelectedOptions = getProductOptions(productData, productAttributes);

                const targetCimdoc = newCimDoc ?? (await getTransformedFlexibilityCimDocs([productAttributes]))[0];

                const productConfig = await getProductConfiguration(
                    {
                        locale,
                        productKey,
                        market: getCountryFromLocale(locale),
                        selectedOptions: JSON.stringify(sortOptions(newSelectedOptions)),
                        productVersion,
                        requestor: REQUESTOR,
                        qty,
                    },
                    () => {
                        return;
                    }
                );

                if (!productConfig) {
                    return;
                }

                await updateProductConfiguration({ newCimDoc: targetCimdoc, productConfig });

                for (const [optionName, optionValue] of changedAttributePairs) {
                    productOptionsTracking.trackAttributeSelected(optionName, optionValue);
                }

                if (workId) {
                    setIsWorkSaved(false);
                } else {
                    updateWindowQueryParams(productKey, productVersion, newSelectedOptions);
                }

                return true;
            } catch (error) {
                noticeError(error, {
                    method: 'loadProduct - useLoadProduct',
                });

                return false;
            }
        },
        [
            locale,
            qty,
            workId,
            productData,
            getProductConfiguration,
            updateProductConfiguration,
            getTransformedFlexibilityCimDocs,
        ]
    );

    return {
        loadProduct,
    };
};
