import { noticeError } from './newRelicUtils';
import { CustomURLParams } from '../config/constant';

interface CleanUpURLParamsRequiredInStudioProps {
    state: Record<string, string> | null;
    unused?: string;
    url?: string;
}

export const cleanUpURLParamsRequiredInStudio = ({
    state,
    unused = '',
    url = '',
}: CleanUpURLParamsRequiredInStudioProps) => {
    try {
        const buildUrl = new URL(url);
        let isReplaceURL = false;
        Object.values(CustomURLParams).forEach((customURLParam) => {
            if (url.indexOf(customURLParam)) {
                isReplaceURL = true;
                buildUrl.searchParams.delete(customURLParam);
            }
        });
        if (isReplaceURL) {
            window.history.replaceState(state, unused, buildUrl.href);
        }
    } catch (error) {
        noticeError(error, {
            method: 'cleanUpURLParamsRequiredInStudio',
        });
    }
};
