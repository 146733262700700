import pricingContext from '@vp/pricing-context-module';
import { getCountryFromLocale } from './getCountryFromLocale';
import { Tenants } from '../config/constant';

export function formatPrice(price: number, locale: string, currency: string) {
    try {
        const formatter = new Intl.NumberFormat(locale, {
            currency,
            minimumFractionDigits: 2,
            style: 'currency',
        });
        return formatter.format(price);
    } catch (error) {
        console.error('Error: ', currency, error);
        return `${price}`;
    }
}

export function formatPriceDifference(price: number, locale: string, currency: string) {
    const priceChange = formatPrice(price, locale, currency);
    return priceChange.includes('-') ? priceChange : `+${priceChange}`;
}

// Checks if there exists a price difference and returns false if there is no price difference
// E.G for formattedPrice: $0.00 returns false
export function checkForPriceDifference(formattedPrice: string) {
    const result = Number(formattedPrice.replace(/[^\d.-]/g, ''));
    return result ? true : false;
}

const isDevelopmentMode = false;

export function getPricingContextModule() {
    // currently the pricing context module only exports when run server side
    return pricingContext.init ? pricingContext : document.pcxtV3;
}

export async function initPricingContextModule(locale: string) {
    // lets us be able to override locale if we want
    return getPricingContextModule().init(Tenants.Vistaprint, getCountryFromLocale(locale), isDevelopmentMode);
}

export function isVatInclusive() {
    return getPricingContextModule().isVatInclusive();
}
