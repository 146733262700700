import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { BookendHeaderDataType } from '../../features/quad/types';

interface HeaderProps {
    headerData: BookendHeaderDataType;
    isTeamPromoteModalVisible: boolean;
    setIsTeamPromoteModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderInfoContext = createContext<HeaderProps | null>(null);

export const useHeaderInfo = () => {
    const header = useContext(HeaderInfoContext);

    if (!header) {
        throw new Error('useHeaderInfo must be wrapped inside the HeaderProvider');
    }

    return header;
};

interface HeaderInfoProviderProps {
    children: ReactNode | ReactNode[];
    headerData: BookendHeaderDataType;
}

export const HeaderInfoProvider = ({ children, headerData }: HeaderInfoProviderProps) => {
    const [isTeamPromoteModalVisible, setIsTeamPromoteModalVisible] = useState(false);
    const value = useMemo(() => {
        return { headerData, isTeamPromoteModalVisible, setIsTeamPromoteModalVisible };
    }, [headerData, isTeamPromoteModalVisible]);

    return <HeaderInfoContext.Provider value={value}>{children}</HeaderInfoContext.Provider>;
};
