import React from 'react';
import { css } from '@emotion/css';
import {
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogHeader,
    tokens,
    Typography,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { ShapeOptionSet } from './ShapeOptionSet';
import { text } from '../../../utils/localization';
import { shapeStore } from '../ShapeStore';

// This may not be required after upgrading to swan 3.VL
const shapePanelStyle = css`
    &.swan-modal-dialog.swan-modal-dialog-panel.swan-modal-dialog-panel-bottom {
        border-radius: ${tokens.SwanSemBorderRadiusContainer} ${tokens.SwanSemBorderRadiusContainer} 0 0;
    }
`;

export const ShapeOptionSetModal = observer(() => {
    const { showShapeModal, closeShapeModal } = shapeStore;

    return (
        <ModalDialog
            variant="panel-bottom"
            isOpen={showShapeModal}
            onRequestDismiss={closeShapeModal}
            onlyRenderWhenOpen
        >
            <ModalDialogContent aria-label={text('shape')} className={shapePanelStyle}>
                <ModalDialogHeader marginBottom="4">
                    <Typography fontSize="large">{text('shape')}</Typography>
                    <ModalDialogCloseButton
                        accessibleText={text('closeAccessibilityLabel')}
                        data-testid="modal-close-btn"
                    />
                </ModalDialogHeader>
                <ModalDialogBody>
                    <ShapeOptionSet />
                </ModalDialogBody>
            </ModalDialogContent>
        </ModalDialog>
    );
});
