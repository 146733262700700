import { noticeError } from './newRelicUtils';
import { formatPrice, isVatInclusive } from './pricingUtilities';
import { getDifferentialPricing } from '../api/pricing';
import { PricingType } from '../config/constant';
import { ProductData } from '../hooks/calcifer/useProductConfiguration';
import { PriceDetails } from '../types/price';

export const getProductPricingData = async (
    productData: ProductData,
    locale: string,
    abortSignal?: AbortSignal
): Promise<PriceDetails | undefined> => {
    if (productData) {
        try {
            const choices = {
                included: {
                    ...productData.selectedOptions,
                },
            };

            const result = await getDifferentialPricing(
                productData.productKey,
                productData.selectedOptions,
                productData.quantity,
                choices,
                productData.productVersion,
                abortSignal
            );

            const { included: includedProductPricing } = result.choiceGroups;
            const priceToUse = isVatInclusive() ? PricingType.Taxed : PricingType.Untaxed;
            const totalListPrice = includedProductPricing.totalListPrice[priceToUse];
            const totalDiscountPrice = includedProductPricing.totalDiscountPrice[priceToUse];

            return {
                totalListPrice: formatPrice(totalListPrice, locale, result.currency),
                totalDiscountPrice: formatPrice(totalDiscountPrice, locale, result.currency),
                isPriceDiscounted: totalListPrice !== totalDiscountPrice,
                currency: result.currency,
            };
        } catch (error) {
            noticeError(error, {
                method: 'getProductPricingData',
            });
        }
    }
};
