import { action, makeObservable, observable } from 'mobx';
class AdjustContrastStore {
    @observable showAdjustContrastModal = false;
    @observable showImageProcessingLoader = false;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setShowAdjustContrastModal(state: boolean) {
        this.showAdjustContrastModal = state;
    }

    @action.bound
    setShowImageProcessingLoader(state: boolean) {
        this.showImageProcessingLoader = state;
    }
}

export const adjustContrastStore = new AdjustContrastStore();
