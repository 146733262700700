import { useCallback, useEffect, useState } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import DOMPurify from 'dompurify';
import { useContinueTrackingEvents } from './useContinueTrackingEvents';
import { useIsSlowInstantUpload } from './useIsSlowInstantUpload';
import { updateCart } from '../../../api/cart';
import { PanelSides } from '../../../config/constant';
import { useAutoRecognitionTracking } from '../../../features/ChangeSize';
import { getReviewInstruction } from '../../../features/PremiumFinish';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../../features/TrackingEvents/constant';
import { GA_EVENTS } from '../../../features/TrackingEvents/GAEvents';
import { getPanelByName } from '../../../features/UploadPanel/components/UploadBackDesignModal/helper';
import { useTrackExperimentClick } from '../../../hooks';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useBookendHeaderData } from '../../../hooks/useBookendHeaderData';
import { useSiteFlowNextStepUrl } from '../../../hooks/useSiteFlowNextStepUrl';
import { useValidationTracking } from '../../../hooks/useValidationTracking';
import { useWorkEntityService } from '../../../hooks/useWorkEntityService';
import {
    useBackConfirmationContext,
    useFullScreenLoader,
    usePremiumFinishModal,
    usePremiumFinishStore,
    useProductParams,
} from '../../../providers';
import { getHost } from '../../../utils/getHost';
import { text } from '../../../utils/localization';

export const useContinueButtonQuad = (hasBeenReviewed: boolean) => {
    const {
        cimDocStore: { panels, asJson },
    } = useDesignEngine();
    const headerData = useBookendHeaderData();
    const { fireEvent } = useCustomEventPropagation();
    const { saveCurrentProgress } = useWorkEntityService();
    const { isBackVisited } = useBackConfirmationContext();
    const { data: nextStepUrl } = useSiteFlowNextStepUrl();
    const { identity, auth: vpAuth } = useIdentityContext();
    const { setFullScreenLoaderData } = useFullScreenLoader();
    const { isPremiumFinishProduct } = usePremiumFinishStore();
    const { trackExperimentClick } = useTrackExperimentClick();
    const { updateValidationTracking } = useValidationTracking();
    const { locale, isItemInCart, cartQuantity } = useProductParams();
    const { productData, secondaryConfig } = useProductConfiguration();
    const { openPremiumFinishConfirmationModal } = usePremiumFinishModal();
    const { trackAutoRecognitionFinalSelection } = useAutoRecognitionTracking();
    const { checkForSlowUpload, showSlowImageUploadMessage, dismissSlowUploadMessage } = useIsSlowInstantUpload();
    const { fireProjectSavedEvent, fireContinueButtonEvent } = useContinueTrackingEvents();

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const reviewInstruction = getReviewInstruction(asJson);

    // TODO: have to use this logic for handling products with different name or for multiple panels
    const backPanel = getPanelByName(panels, PanelSides.Back);
    const backPanelImage = backPanel?.items.find((item) => item.isImageItem())?.id;

    const handleUpdateCartClick = async () => {
        setFullScreenLoaderData({
            showLoader: true,
            loaderMessage: text('designReadyLabel'),
        });

        const workEntity = await saveCurrentProgress();

        if (workEntity) {
            trackExperimentClick({
                label: QUAD_TRACKING_EVENTS.UPDATE_CART_BUTTON_CLICK,
                pageSection: PageSection.UpdateCartButton,
                eventDetail: 'quick-upload-and-design;cart-page;Update Cart-CTA',
                navigationDetail: 'quick-upload-and-design/cart',
            });

            updateValidationTracking({
                exitLabel: 'Update Cart',
                pageSection: PageSection.UpdateCartButton,
                pageStage: PageStage.Confirmation,
            });

            const cartUrl = headerData?.base.cartLink.url;
            // if item is already in cart, they have already gone through pdc etc
            // so take them straight to cart
            if (isItemInCart && cartQuantity > 0 && cartUrl) {
                const authorizationHeader = vpAuth.getAuthorizationHeader();

                const studioSelectedProductOptions = JSON.parse(
                    workEntity.design.metadata.studioSelectedProductOptions
                );
                const isConnectedCard = studioSelectedProductOptions.Connection === 'NFC';
                const isQuantityPageEnabled = secondaryConfig?.isQuantityPageEnabled ?? false;

                await updateCart({
                    authorizationHeader,
                    locale,
                    work: workEntity,
                    shopperId: identity.shopperId,
                    anonymousId: identity.anonymousUserId,
                    isQuantityPageEnabled,
                    isConnectedCard,
                    selectedProductOptions: productData
                        ? {
                              ...productData.selectedOptions,
                              ...productData.customerSelectedOptions,
                          }
                        : {},
                    mpvId: productData?.mpvId || null,
                });

                trackAutoRecognitionFinalSelection();
                fireProjectSavedEvent(workEntity);

                window.location.href = DOMPurify.sanitize(cartUrl);
            }
        }
    };

    const approveDesign = async () => {
        setFullScreenLoaderData({
            showLoader: true,
            loaderMessage: text('designReadyLabel'),
        });

        const workEntity = await saveCurrentProgress();

        if (nextStepUrl) {
            updateValidationTracking({
                exitLabel: 'Finish',
                pageSection: PageSection.FinishButton,
                pageStage: PageStage.Confirmation,
            });

            trackAutoRecognitionFinalSelection();
            fireProjectSavedEvent(workEntity);

            const pdc_url = `${getHost()}${nextStepUrl.replace('{workId}', workEntity?.workId as string)}`;
            window.location.href = pdc_url;
        }
    };

    const handleApproval = () => {
        if (isPremiumFinishProduct && (!reviewInstruction || !reviewInstruction.finishOption)) {
            openPremiumFinishConfirmationModal();
            return;
        }

        if (!isBackVisited && backPanel && !backPanelImage) {
            fireEvent({
                name: ACTION_NAME.DESIGN_TOOL_USED,
                extraData: {
                    experimentLabel: QUAD_TRACKING_EVENTS.OPEN_NUDGE_TO_BACK,
                    eventDetail: GA_EVENTS.VIEW_NUDGE_PANEL.replace('[CTA1 TEXT]', 'Continue').replace(
                        '[CTA2 TEXT]',
                        QUAD_TRACKING_EVENTS.CLICK_DESIGN_BACK
                    ),
                    pageStage: PageStage.Confirmation,
                    pageSection: PageSection.NudgeToBackModal,
                },
            });
        } else {
            approveDesign();
            fireContinueButtonEvent();
        }
    };

    const handleContinueClick = useCallback(() => {
        if (hasBeenReviewed || sessionStorage.getItem('was_inside_next_dialog')) {
            if (checkForSlowUpload()) {
                return;
            }

            isItemInCart && cartQuantity > 0 ? handleUpdateCartClick() : handleApproval();
        } else {
            setShowErrorMessage(true);
        }
    }, [handleUpdateCartClick, handleApproval, setShowErrorMessage, checkForSlowUpload]);

    useEffect(() => {
        if (showErrorMessage) {
            setShowErrorMessage(!hasBeenReviewed);
        }
    }, [hasBeenReviewed, showErrorMessage]);

    return {
        handleContinueClick,
        showErrorMessage,
        showSlowImageUploadMessage,
        dismissSlowUploadMessage,
    };
};
