import React, { useState } from 'react';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { observer } from 'mobx-react-lite';
import { DecorationAreaOptions } from './DecorationAreaOptions';
import { text } from '../../../utils/localization';
import { FlexibilityDocuments, FlexibilityModal, useFlexibilityDesignDocuments } from '../../Flexibility/common';
import { useDecorationAreaModal } from '../hooks';
import { decorationAreaStore } from '../store';

export const DecorationAreaModal = observer(() => {
    const { getFlexibilityDocuments } = useFlexibilityDesignDocuments();
    const [decorationAreaFlexibilityDocuments, setDecorationAreaFlexibilityDocuments] = useState<
        FlexibilityDocuments | undefined
    >(undefined);
    const { isDecorationAreaModalOpen, decorationAreaChoiceGroups } = decorationAreaStore;
    const { flexibilityDocument, onChangeDecorationAreaClick, closeDecorationAreaModal } = useDecorationAreaModal(
        decorationAreaFlexibilityDocuments
    );

    useAsyncEffect(
        (helper) => {
            const initDecorationAreaFlexibilityDocuments = async () => {
                if (!isDecorationAreaModalOpen) {
                    return;
                }

                const flexibilityDocuments = await getFlexibilityDocuments(
                    decorationAreaChoiceGroups,
                    helper.abortSignal
                );
                setDecorationAreaFlexibilityDocuments(flexibilityDocuments);
            };

            initDecorationAreaFlexibilityDocuments();

            return () => {
                setDecorationAreaFlexibilityDocuments(undefined);
            };
        },
        [isDecorationAreaModalOpen, decorationAreaChoiceGroups, getFlexibilityDocuments]
    );

    return (
        <FlexibilityModal
            ariaLabel={text('changeArea')}
            isFlexibilityModalOpen={isDecorationAreaModalOpen}
            onModalClose={closeDecorationAreaModal}
            flexibilityDocument={flexibilityDocument}
            showPreviewSwitcher={false}
            headerText={text('changeDecorationArea')}
            subHeaderText={text('adjustDesign')}
            panelContent={<DecorationAreaOptions />}
            changeButtonText={text('change')}
            onChangeButtonClick={onChangeDecorationAreaClick}
        />
    );
});
