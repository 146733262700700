import { useEffect, useRef, useState } from 'react';
import { ImageItem } from '@design-stack-vista/cdif-types';
import { ItemState } from '@design-stack-vista/cimdoc-state-manager';
import { IDAItemLayoutExtension, useDesignEngine } from '@design-stack-vista/core-features';
import { useLayoutContainers } from '@design-stack-vista/ida-framework';
import { useUploadManager } from '@design-stack-vista/upload-components';
import { AssetStatusType } from '@design-stack-vista/vista-assets-sdk';
import { PanelSides } from '../../../config/constant';
import { useActivePanel } from '../../../hooks/useActivePanel';
import { useUploadModal } from '../../../providers';
import { addPageAction } from '../../../utils/newRelicUtils';
import { backDesignTemplateStore } from '../../BackDesignTemplate';
import { changeSizeStore } from '../../ChangeSize';
import { LowResolutionExtension } from '../../quad/Validations';
import { PageSection } from '../../TrackingEvents/constant';

export enum LoaderType {
    UPLOAD = 'UPLOAD',
    SHARPEN = 'SHARPEN',
    DEFAULT = 'DEFAULT',
}

/**
 * This function is responsible to decide which type of preview loader should be shown
 * in different stages uploading and processing an asset.
 * UPLOAD - While an asset is getting upload.
 * SHARPEN - While an asset is getting sharpened.
 * DEFAULT - While an asset is getting processed before adding to canvas.
 * @returns Object containing LoaderType.
 */
export function useLoaderContent(): { loaderType: LoaderType; uploadProgress?: number } | null {
    const activePanel = useActivePanel();
    const { assetStore } = useUploadManager();
    const { panContainer } = useLayoutContainers();
    const { designExtensionSystem } = useDesignEngine();
    const { showAutoRecognitionLoader } = changeSizeStore;
    const { showUploadLoader, showUploadModal } = useUploadModal();
    const { showCanvasTemplateLoader } = backDesignTemplateStore;

    const shouldLog = useRef(true);
    const [renderStartTime, setRenderStartTime] = useState(0);

    const assets = assetStore?.assets ?? [];
    const uploadingAssets = assets.filter((asset) => asset.status.type === AssetStatusType.Preparing);
    const imageItem = activePanel?.items?.find((item) => item.isImageItem());
    const layout = designExtensionSystem.getExtension(imageItem?.iid ?? '', IDAItemLayoutExtension);
    const lowResolution = designExtensionSystem.getExtension(imageItem?.iid ?? '', LowResolutionExtension);
    const isBackPanel = activePanel?.panelProperties.name.toLowerCase() === PanelSides.Back;

    useEffect(() => {
        if (imageItem && panContainer && layout) {
            // when image was clicked and PreviewLoader was started showing
            setRenderStartTime(performance.now());
        }
    }, [imageItem, panContainer]);

    if (showAutoRecognitionLoader || (isBackPanel && showCanvasTemplateLoader)) {
        return {
            loaderType: LoaderType.DEFAULT,
        };
    }

    // check if there is an asset being uploading.
    // show upload progress loader in case latest upload is still being upload.
    if (showUploadLoader && !showUploadModal && uploadingAssets.length > 0) {
        const asset = uploadingAssets[0];
        const currentUploadStatus = asset.status.type;
        const uploadProgress = currentUploadStatus === AssetStatusType.Preparing && asset.status.uploadProgress;

        return {
            loaderType: LoaderType.UPLOAD,
            uploadProgress: Number(uploadProgress),
        };
    }

    if (!imageItem || !panContainer) {
        return null;
    }

    if (layout && !layout?.previewBox) {
        shouldLog.current = true;
    }

    if (layout?.previewBox && !lowResolution?.loaderStatus) {
        if (shouldLog.current) {
            const assetFile =
                activePanel &&
                assets.find(
                    (asset) =>
                        asset.status.type === AssetStatusType.Prepared &&
                        asset.getUrl() === (activePanel.items[0] as ItemState<ImageItem>).model.originalSourceUrl
                );
            // Rendering on canvas time will measure, time of image clicked and rendering on canvas
            addPageAction('rendering-on-canvas-time', {
                label: 'General',
                pageSection: PageSection.AssetContainer,
                productSurfaceId: activePanel?.id,
                fileType: assetFile?.data?.info.image?.format,
                size: assetFile?.data?.info.storage?.fileSizeBytes,
                fileName: assetFile?.data?.info.storage?.fileName,
                uniqueAssetId: assetFile?.data?.id,
                renderingOnCanvasTime: performance.now() - renderStartTime,
                side: activePanel?.panelProperties.name,
            });

            shouldLog.current = false;
        }
        return null;
    }

    const loaderType = lowResolution?.loaderStatus ? LoaderType.SHARPEN : LoaderType.DEFAULT;

    return {
        loaderType: loaderType,
    };
}
