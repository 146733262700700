import { useEffect, useState } from 'react';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { ownerId } from '../utils/envUtils';
import { noticeError } from '../utils/newRelicUtils';

export const useFolderName = () => {
    const [customerId, setCustomerId] = useState<string | undefined>();
    const {
        identity: { isCareAgent, shopperId, cimpressADFSUserId, anonymousUserId },
        isIdentityInitialized,
    } = useIdentityContext();

    useEffect(() => {
        // This loads before we redirect to force non-logged-in agents to login
        // That spams sherbert with bad folder retrieval/creation calls
        // So don't provide the folder name (which is only used for querying uploads for other users) unless we're sure this is an agent
        if (isCareAgent && ownerId) {
            setCustomerId(ownerId);
        } else if (isIdentityInitialized) {
            setCustomerId(shopperId || anonymousUserId || cimpressADFSUserId);
        } else {
            noticeError(new Error('User identity is not initialized to create folder name'), {
                method: 'useFolderName',
            });
        }
    }, [isCareAgent, isIdentityInitialized]);

    return customerId;
};
