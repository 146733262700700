import { useCallback, useMemo } from 'react';
import { useDesignEngine, useFocus } from '@design-stack-vista/core-features';
import { useLayoutContainers } from '@design-stack-vista/ida-framework';
import debounce from 'lodash.debounce';
import { useResizeObserver } from '../utils/useResizeObserver';

export function useFocusOnWindowResize() {
    const {
        idaStore: { activeDesignPanelId },
    } = useDesignEngine();
    const { focusDesignArea } = useFocus();
    const { panContainer } = useLayoutContainers();

    const focus = useCallback(() => {
        panContainer &&
            activeDesignPanelId &&
            focusDesignArea(activeDesignPanelId, {
                changeZoom: false,
            });
    }, [activeDesignPanelId, focusDesignArea, panContainer]);

    const resizeHandler = useMemo(() => debounce(focus, 50), [focus]);

    const setVHValue = () => {
        // On real mobile devices css vH is different from JS window.innerHeight, so to make it align we are adding custom vH variable in CSS using this Piece of code https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useResizeObserver(panContainer, () => {
        setVHValue();
        resizeHandler();
    });
}
