import React from 'react';
import { css } from '@emotion/css';
import { ModalDialogBody, ModalDialogButtons, ModalDialogFooter, ModalDialogHeader, Typography } from '@vp/swan';
import { TeamsPromoteModalGetStarted } from './TeamsPromoteModalGetStarted';
import { TeamsPromoteModalSteps } from './TeamsPromoteModalSteps';
import { TeamsPromoteModalStepsDesc } from './TeamsPromoteModalStepsDesc';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';
import { text } from '../../../../utils/localization';
import { DesktopExperience } from '../../../Experiences';
import { StandardModal } from '../../../Modal/components/StandardModal';
import { useHeaderInfo } from '../../HeaderInfoProvider';

const getTeamsPromoteModalStyle = css`
    .swan-modal-dialog.swan-modal-dialog-visible {
        max-width: 850px;
    }
`;

const teamsPromoteModalStyle = css`
    &.swan-modal-dialog-header {
        display: flex;
        justify-content: center;
        text-align: center;
        .swan-text-x1 {
            text-align: center;
        }
    }
`;

const teamsPromoteModalFooterStyle = css`
    &.swan-modal-dialog-footer {
        display: flex;
        justify-content: center;
    }
`;

export const TeamsPromoteModal = () => {
    const { isSmall } = useScreenLayout();
    const { isTeamPromoteModalVisible, setIsTeamPromoteModalVisible } = useHeaderInfo();

    const onRequestDismiss = () => {
        setIsTeamPromoteModalVisible(false);
    };

    return (
        <StandardModal
            isOpen={isTeamPromoteModalVisible}
            onClose={onRequestDismiss}
            ariaLabelledby={text('teamsPromote')}
            className={!isSmall ? getTeamsPromoteModalStyle : ''}
        >
            <ModalDialogHeader mb={{ xs: '6', sm: '7' }} className={teamsPromoteModalStyle}>
                <Typography fontSize="large" fontWeight="bold">
                    {text('teamsPromoteHeading')}
                </Typography>
            </ModalDialogHeader>
            <ModalDialogBody m="5" textAlign="center">
                <DesktopExperience>
                    <TeamsPromoteModalSteps />
                </DesktopExperience>
                <TeamsPromoteModalStepsDesc />
            </ModalDialogBody>
            <ModalDialogFooter pinned className={teamsPromoteModalFooterStyle}>
                <ModalDialogButtons>
                    <TeamsPromoteModalGetStarted />
                </ModalDialogButtons>
            </ModalDialogFooter>
        </StandardModal>
    );
};
