import React from 'react';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { DesignLayer } from '@design-stack-vista/ida-framework';
import { EmptyState } from './EmptyState';

export interface EmptyStateLayerProps {
    panel: PanelState;
}

export const EmptyStateLayer = ({ panel }: EmptyStateLayerProps) => {
    if (panel.items.length) {
        return null;
    }

    return (
        <DesignLayer>
            <EmptyState />
        </DesignLayer>
    );
};
