import { useCallback } from 'react';
import { useAutoRecognitionTracking } from './useAutoRecognitionTracking';
import { useNotificationFramework } from '../../../components/Notification';
import { PRICING_NOTIFICATION_DISMISS_TIMEOUT, ProductAttributes } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useTranslatedConfig } from '../../../hooks/useTranslatedConfig';
import { useFullScreenLoader, useProductParams } from '../../../providers';
import { FlexibilityDocument, useLoadProduct } from '../../Flexibility/common';
import { shapeStore, useShapePrice } from '../../Shapes';
import { changeSizeStore } from '../store';
import { getChangeSizeNotification } from '../utils';

export const useLoadNewSize = () => {
    const { loadProduct } = useLoadProduct();
    const { selectedQuantity } = useProductParams();
    const { notifyCustomer } = useNotificationFramework();
    const { updateShapeOptionsAndPrices } = useShapePrice();
    const { trackChangeSize } = useAutoRecognitionTracking();
    const { getTranslatedOptionName } = useTranslatedConfig();
    const { setFullScreenLoaderData } = useFullScreenLoader();
    const { productData, secondaryConfig } = useProductConfiguration();
    const {
        sizeAttribute,
        changeSizePrices,
        changeSizeChoiceGroups,
        setOpenChangeSizeModal,
        isChangeSizeModalAutoOpened,
        setIsChangeSizeModalAutoOpened,
        setShowAutoRecognitionLoader,
    } = changeSizeStore;
    const { setUserSelectedSize } = shapeStore;

    const loadNewSize = useCallback(
        async (flexibilityDocument: FlexibilityDocument | undefined, choiceKey: string | undefined) => {
            if (!productData || !choiceKey || !changeSizeChoiceGroups[choiceKey] || !changeSizePrices[choiceKey]) {
                return;
            }

            const { productKey, productVersion } = productData;
            const productAttributes = changeSizeChoiceGroups[choiceKey];
            const messageToShowCustomer = getChangeSizeNotification(
                productData,
                productAttributes,
                changeSizePrices[choiceKey],
                sizeAttribute,
                selectedQuantity,
                getTranslatedOptionName
            );

            /**
             * In shapes feature to calculate closest size, we have to give more priority on user selected size which could be different from productData size.
             * Updating the price of the available compatible shapes based on user selected size.
             */
            setUserSelectedSize(productAttributes[sizeAttribute]);
            updateShapeOptionsAndPrices({
                productData,
                secondaryConfig,
                userSelectedSize: productAttributes[sizeAttribute],
            });

            setOpenChangeSizeModal(false);
            setIsChangeSizeModalAutoOpened(false);

            if (!isChangeSizeModalAutoOpened) {
                setFullScreenLoaderData({ showLoader: true });
            }

            const loadedNewSize = await loadProduct({
                newCimDoc: flexibilityDocument?.cimDoc,
                newProduct: {
                    productKey,
                    productVersion,
                    productAttributes,
                },
            });

            setFullScreenLoaderData({ showLoader: false });
            setShowAutoRecognitionLoader(false);

            if (!loadedNewSize) {
                return;
            }

            notifyCustomer(
                {
                    notificationType: 'positive',
                    messageToShowCustomer,
                },
                true,
                PRICING_NOTIFICATION_DISMISS_TIMEOUT
            );

            trackChangeSize({
                selectedSize: productAttributes[sizeAttribute],
                selectedOrientation: productAttributes[ProductAttributes.Orientation],
            });
        },
        [
            productData,
            changeSizePrices,
            changeSizeChoiceGroups,
            sizeAttribute,
            selectedQuantity,
            secondaryConfig,
            isChangeSizeModalAutoOpened,
            getTranslatedOptionName,
            loadProduct,
            trackChangeSize,
        ]
    );

    return {
        loadNewSize,
    };
};
