import React, { useEffect } from 'react';
import { useUploadManager } from '@design-stack-vista/upload-components';
import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { MobileExperience } from './Experiences';
import { Header } from './Header';
import { FullScreenLoader } from './Loader';
import { ModalsWrapper } from './ModalsWrapper';
import { UploadDesignArea } from './UploadDesignArea';
import { useInitializeBackDesignTemplateStore } from '../features/BackDesignTemplate';
import { useInitializeChangeSizeStore } from '../features/ChangeSize';
import { LazilyLoadedDebugToolbar } from '../features/Debug';
import { useInitializeDecorationAreaStore } from '../features/DecorationArea';
import { DropZoneWrapper } from '../features/quad/components/DropZoneWrapper';
import { DependencyInjectionStore } from '../features/quad/Validations/DependencyInjectionStore';
import { RedirectToStudio } from '../features/RedirectToStudio/RedirectToStudio';
import { useInitializeShapeStore } from '../features/Shapes';
import { useGetSubstrateColors } from '../features/SubstrateColor';
import { useTrackExperimentClick, useValidationExperiment } from '../hooks';

const landingPageLayout = css`
    width: 100%;
    height: 100vh;
    position: relative;
`;

interface LandingPageLayoutProps {
    dependencyInjectionStore: DependencyInjectionStore;
}

export const LandingPageLayout = observer(({ dependencyInjectionStore }: LandingPageLayoutProps) => {
    const { assetStore } = useUploadManager();
    const { isValidationEnabled } = useValidationExperiment();
    const { trackExperimentClick } = useTrackExperimentClick();

    useEffect(() => {
        if (assetStore) {
            dependencyInjectionStore.addAssetStore(assetStore);
        }
        if (trackExperimentClick) {
            dependencyInjectionStore.addFireEvent(trackExperimentClick);
        }
        dependencyInjectionStore.setValidationEnabled(isValidationEnabled);
    }, [assetStore, isValidationEnabled, trackExperimentClick]);

    useGetSubstrateColors();
    useInitializeShapeStore();
    useInitializeChangeSizeStore();
    useInitializeDecorationAreaStore();
    useInitializeBackDesignTemplateStore();

    return (
        <div className={landingPageLayout}>
            <DropZoneWrapper>
                <LazilyLoadedDebugToolbar />
                <Header />
                <MobileExperience>
                    <RedirectToStudio />
                </MobileExperience>
                <UploadDesignArea />
            </DropZoneWrapper>
            <ModalsWrapper />
            <FullScreenLoader />
        </div>
    );
});
