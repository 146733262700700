import React, { createContext, ReactElement } from 'react';
import { makeAutoObservable } from 'mobx';
import { NOTIFICATION_DISMISS_TIMEOUT } from '../../config/constant';

export type OnFatalError = (error: Error, errorInfo: React.ErrorInfo) => void;

export type AlertType = 'standard' | 'warning' | 'error' | 'positive' | 'legal-warning';
export enum NotificationSections {
    CANVAS = 'canvas',
    DESIGN_REVIEW = 'designReview',
}

export interface Notification {
    notificationSections?: NotificationSections;
    notificationType: AlertType;
    messageToShowCustomer: string;
    notificationTitle?: string;
    underlyingError?: Error;
}
export interface NotificationHistoryEvent extends Notification {
    notificationId: number;
}

export class NotificationManager {
    notificationHistory: NotificationHistoryEvent[] = [];
    nextNotificationId = 0;
    onFatalError?: OnFatalError;

    notifyCustomer(notification: Notification, autoDismiss = false, dismissTimeout = NOTIFICATION_DISMISS_TIMEOUT) {
        this.notificationHistory.push({
            ...notification,
            notificationSections: notification.notificationSections ?? NotificationSections.CANVAS,
            notificationId: this.nextNotificationId,
        });

        const currentNotificationId = this.nextNotificationId;

        if (autoDismiss) {
            setTimeout(() => {
                if (this.notificationHistory.length > 0) {
                    this.removeNotification(currentNotificationId);
                }
            }, dismissTimeout);
        }

        this.nextNotificationId = this.nextNotificationId + 1;
    }

    removeNotification = (notificationId: number) => {
        this.notificationHistory = this.notificationHistory.filter((notification) => {
            return notification.notificationId !== notificationId;
        });
    };

    notifyFatalError(error: Error, errorInfo: React.ErrorInfo) {
        this.onFatalError?.(error, errorInfo);
    }

    removeAllNotifications = () => {
        this.notificationHistory = [];
    };

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }
}

export const NotificationContext = createContext<NotificationManager | undefined>(undefined);

export interface NotificationProviderProps {
    children?: React.ReactNode;
    notificationManager: NotificationManager;
}

export const NotificationProvider = ({ children, notificationManager }: NotificationProviderProps): ReactElement => {
    return <NotificationContext.Provider value={notificationManager}>{children}</NotificationContext.Provider>;
};
