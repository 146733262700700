import React from 'react';
import { Button, Spinner } from '@vp/swan';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';

interface Props {
    onClick: () => void;
    buttonText: string;
    disabled?: boolean;
    isLoading?: boolean;
}

export const FooterButton = ({ onClick, buttonText, disabled, isLoading }: Props) => {
    const { isSmall } = useScreenLayout();

    return (
        <Button
            skin="primary"
            size={isSmall ? 'mini' : 'standard'}
            width="full-width"
            disabled={disabled}
            onClick={onClick}
        >
            {isLoading && <Spinner size="tiny" accessibleText={text('loadingLabel')} />}
            {buttonText}
        </Button>
    );
};
