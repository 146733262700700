import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const WarningIcon = (props: SvgProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#fff"
            fillRule="evenodd"
            {...props}
        >
            <g fillRule="nonzero">
                <path
                    d="M0 4c0-2.216 1.784-4 4-4h16c2.216 0 4 1.784 4 4v16c0 2.216-1.784 4-4 4H4c-2.216 0-4-1.784-4-4z"
                    fillOpacity=".95"
                />
                <path
                    d="M11.685485101531 6.857503594208l-6.1403 10.2338c-.2399.3999.0481.9087.5145.9087h12.2806c.4664 0 .7544-.5088.5145-.9087l-6.1403-10.2338c-.233-.3884-.796-.3884-1.029 0z"
                    fill="#b64b05"
                />
            </g>
            <path d="M12.199603652954 14.40010433197c-.233 0-.4244-.1842-.4332-.4171l-.144-3.7833c-.0124-.3274.2496-.5996.5772-.5996s.5897.2722.5772.5996l-.1439 3.7833c-.0089.2329-.2002.4171-.4333.4171zm0 1.8c.3314 0 .6-.2686.6-.6s-.2686-.6-.6-.6-.6.2686-.6.6.2686.6.6.6z" />
        </svg>
    );
};
