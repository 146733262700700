import React from 'react';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { css } from '@emotion/css';
import { Icon, SelectionSetInput, SelectionSetLabel, Typography } from '@vp/swan';
import { UpsellPricingText } from './UpsellPricingText';
import { usePanelTitle } from '../../hooks/usePanelTitle';

const uploadLabelStyles = css`
    &.swan-selection-set-label {
        justify-content: center;
    }
`;

interface UploadDesignButtonProp {
    panel: PanelState;
    panelName: string;
    handleUploadButtonClick: (panel: PanelState) => void;
}

export const UploadDesignButton = ({ panel, panelName, handleUploadButtonClick }: UploadDesignButtonProp) => {
    const { getUploadButtonText } = usePanelTitle();

    const handleClick = () => {
        handleUploadButtonClick(panel);
    };

    const uploadButtonText = getUploadButtonText(panelName);

    return (
        <SelectionSetInput value={panel.id}>
            <SelectionSetLabel
                data-testid={`upload-${panelName.toLowerCase()}-btn`}
                fontWeight="bold"
                className={uploadLabelStyles}
                onClick={handleClick}
                fontSize="standard"
                mb="0"
            >
                <Icon iconType="upload" size="24p" skin="standard" mr="3" />
                <Typography fontSize="standard" mr="3">
                    {uploadButtonText}
                </Typography>
                {panelName && <UpsellPricingText panelName={panelName} />}
            </SelectionSetLabel>
        </SelectionSetInput>
    );
};
