import { buildInstructionsUrl, buildTransientDocumentUri, InstructionsType } from '@design-stack-ct/documents-sdk';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useProductConfiguration } from './calcifer';
import { getPurcsPreviewUrl } from '../api/purcs';
import { PREVIEW_SIZE, STUDIO_PREVIEW_PURPOSE } from '../config/constant';
import { useProductParams } from '../providers';
import { usePreview } from '../providers/PreviewProvider';
import { fetchWrapper } from '../utils';

interface Preview {
    _links: { image: { href: string; title: string } };
    name: string;
}

export const useDesignPreviewData = () => {
    const designEngine = useDesignEngine();
    const { productData } = useProductConfiguration();
    const { locale } = useProductParams();
    const { cimDocStore } = designEngine;
    const { ...docWithoutMetadata } = cimDocStore.asJson;
    const previewDocumentUrl = buildTransientDocumentUri(docWithoutMetadata);
    const previewInstructionsUrl = buildInstructionsUrl(previewDocumentUrl, InstructionsType.preview);
    const { setDesignPreviews } = usePreview();

    const getDesignPreview = async (signal?: AbortSignal) => {
        if (productData) {
            const { productKey, productVersion, selectedOptions } = productData;
            const url = getPurcsPreviewUrl({
                previewInstructionsUrl,
                width: PREVIEW_SIZE.width,
                locale,
                purpose: STUDIO_PREVIEW_PURPOSE,
                productKey,
                productVersion,
                optionSelections: selectedOptions,
            });

            const response = await fetchWrapper(url, {
                method: 'GET',
                requestInitiator: 'getDesignPreview',
                signal,
            });
            if (response) {
                const previewImageUrls = response.views.map((preview: Preview) => ({
                    url: preview._links.image.href,
                    title: preview._links.image.title,
                    name: preview.name,
                }));
                setDesignPreviews(previewImageUrls);
                return previewImageUrls;
            }
        }
    };

    return {
        getDesignPreview,
    };
};
