import React, { useEffect, useRef } from 'react';
import type { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css, cx } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { MultiPanelPreviewExtension } from '../../extensions/multiPanelPreviewExtension';

const PanelPreviewCanvasStyle = css`
    max-height: 100%;
    max-width: 100%;
`;

export interface SimplePanelPreviewProps {
    /** The `PanelState` to be previewed */
    panel: PanelState;
    /** Optional class name to be added to the preview canvas */
    className?: string;
    /**
     * The pixel size to be used by fusion for the preview
     * Higher values will result in a larger preview (before styles are applied)
     */
    pixelSize?: number;
}

/**
 * The PanelPreview is a connected observer component linked to the current design engine which renders a preview for a given {@link PanelState}.
 *
 * @remarks
 * This component depends on the {@link MultiPanelPreviewExtension} to render the preview.
 */
export const SimplePanelPreview = observer(({ panel, className, pixelSize }: SimplePanelPreviewProps) => {
    const canvas = useRef<HTMLCanvasElement>(null);
    const { designExtensionSystem } = useDesignEngine();

    const panelPreview = designExtensionSystem.getExtension(panel.iid, MultiPanelPreviewExtension);
    useEffect(() => {
        if (canvas.current && panelPreview) {
            panelPreview.setCanvas(canvas.current);
            if (pixelSize) {
                panelPreview.setPixelSize(pixelSize);
            }
        }
    }, [canvas.current, panelPreview, pixelSize]);

    if (!panelPreview) {
        return null;
    }

    return <canvas ref={canvas} className={cx(PanelPreviewCanvasStyle, 'quad-panel-preview__canvas', className)} />;
});
