import React, { useCallback } from 'react';
import { css } from '@emotion/css';
import {
    Button,
    className,
    Divider,
    FlexBox,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogNav,
    ModalDialogTitle,
    tokens,
    Typography,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { TemplatePlaceholderList } from './TemplatePlaceholderList';
import { DesktopExperience, MobileExperience } from '../../../components/Experiences';
import { useActivePanel } from '../../../hooks/useActivePanel';
import { useRedirectURL } from '../../../hooks/useRedirectURL';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';
import { PageSection } from '../../TrackingEvents/constant';
import { useTemplateData } from '../hooks';
import { backDesignTemplateStore } from '../store/BackDesignTemplateStore';

const getModalDialogStyle = (isSmall: boolean) => css`
    .swan-modal-dialog {
        max-width: 1200px;
        height: auto;

        .swan-modal-dialog-nav {
            position: absolute;
            background: transparent;
            right: 0;
            top: 0;
        }
    }
    .swan-modal-dialog.swan-modal-dialog-panel.swan-modal-dialog-panel-bottom {
        ${isSmall &&
        `
            height: 70vh;
            // This may not be required after upgrading to swan 3.VL
            border-radius: ${tokens.SwanSemBorderRadiusContainer} ${tokens.SwanSemBorderRadiusContainer} 0 0;
        `}
    }
    .swan-modal-dialog-body {
        height: 100%;
    }
`;

const getModalBodyStyle = (isSmall: boolean) => css`
    max-width: 960px;
    max-height: ${isSmall ? 'none' : '480px'};
    height: 100%;

    > div {
        width: ${isSmall ? 'auto' : '50%'};
    }
`;

const imageContainerStyle = css`
    height: 45vh;
`;

const imageStyle = css`
    max-width: 100%;
    max-height: 100%;
`;

const contentStyle = css`
    flex: 1 0 auto;
`;

const mobileButtonContainerStyle = css`
    position: sticky;
    bottom: ${tokens.SwanSemSpaceNone};
`;

export const TemplateMakeEditModal = observer(() => {
    const { isSmall } = useScreenLayout();
    const { selectedDesignTemplate, showMakeEditModal, closeMakeEditModal } = backDesignTemplateStore;
    const { redirectToStudio } = useRedirectURL();
    const activePanel = useActivePanel();
    const { templateMetaData, redirectText, panelNumber } = useTemplateData();

    const makeEditHandler = useCallback(() => {
        closeMakeEditModal();
        redirectToStudio({ pageSection: PageSection.QuickUploadAndDesign, ctaText: redirectText, panelNumber });
    }, [panelNumber, redirectText, redirectToStudio]);

    if (!selectedDesignTemplate || !activePanel || !templateMetaData.length) {
        return null;
    }

    return (
        <ModalDialog
            isOpen={showMakeEditModal}
            onRequestDismiss={closeMakeEditModal}
            onlyRenderWhenOpen
            variant={isSmall ? 'panel-bottom' : 'standard'}
            className={getModalDialogStyle(isSmall)}
        >
            <ModalDialogContent fullBleed aria-labelledby={text('templateMakeEditModalAriaLabel')}>
                <ModalDialogNav>
                    <ModalDialogCloseButton
                        style={{ zIndex: tokens.SwanSemZIndexOverlay }}
                        accessibleText={text('closeAccessibilityLabel')}
                    />
                </ModalDialogNav>
                <ModalDialogBody>
                    <FlexBox flexDirection={isSmall ? 'column' : 'row'} className={getModalBodyStyle(isSmall)}>
                        <FlexBox
                            alignItems="center"
                            justifyContent="center"
                            py="6"
                            px="8"
                            backgroundColor="strong"
                            className={className({ [imageContainerStyle]: isSmall })}
                        >
                            <img
                                className={imageStyle}
                                src={selectedDesignTemplate.preview}
                                alt={text('templateModalPreviewAltText')}
                            />
                        </FlexBox>
                        <FlexBox
                            flexDirection="column"
                            pt={{ xs: '5', sm: '10' }}
                            pb="6"
                            px={{ xs: '5', sm: '6' }}
                            className={contentStyle}
                        >
                            <ModalDialogTitle pb={{ xs: '3', sm: '4' }}>{text('templateModalTitle')}</ModalDialogTitle>
                            <Typography pb="6">{text('templateModalPlaceholderInfo')}</Typography>
                            <TemplatePlaceholderList onClickPlaceholder={makeEditHandler} fontSize="standard" />
                            <DesktopExperience>
                                <Button skin="primary" mt="6" mb="10" onClick={makeEditHandler}>
                                    {text('templateModalEditButtonLabel')}
                                </Button>
                            </DesktopExperience>
                        </FlexBox>
                        <MobileExperience>
                            <FlexBox
                                gap="5"
                                p={{ xs: '5', sm: '3' }}
                                flexDirection="column"
                                backgroundColor="standard"
                                className={mobileButtonContainerStyle}
                            >
                                <Divider />
                                <Button skin="primary" width="full-width" onClick={makeEditHandler}>
                                    {text('templateModalEditButtonLabel')}
                                </Button>
                            </FlexBox>
                        </MobileExperience>
                    </FlexBox>
                </ModalDialogBody>
            </ModalDialogContent>
        </ModalDialog>
    );
});
