import React from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { FlexBox, tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { QuickToolItems } from './QuickToolItems';
import { DesktopExperience } from '../../components/Experiences';
import { HorizontalScroller } from '../Scroller/HorizontalScroller';

const quickToolStyle = css`
    position: absolute;
    width: 100%;
    border-bottom: 1px solid ${tokens.SwanSemColorBorderStandard};
    border-right: 1px solid ${tokens.SwanBaseColorGrey100};
`;

export const QuickToolsDesktop = observer(() => {
    const {
        layoutStore: { visibleItems },
    } = useDesignEngine();

    if (!visibleItems.length) {
        return null;
    }

    return (
        <DesktopExperience>
            <FlexBox
                alignItems="center"
                py="3"
                justifyContent="center"
                className={quickToolStyle}
                backgroundColor="standard"
            >
                <HorizontalScroller>
                    <QuickToolItems />
                </HorizontalScroller>
            </FlexBox>
        </DesktopExperience>
    );
});
