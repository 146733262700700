import { action, makeObservable, observable, runInAction } from 'mobx';
import { CompatibleOptionsEntry, getProductSupportedAttributes } from '../../api/catalog';
import { getDifferentialPricing } from '../../api/pricing';
import { ChoiceGroupsPricing, formatPricingData } from '../../features/Flexibility/common';
import { FINISH_TYPE } from '../../features/PremiumFinish';
import { SUPPORTED_PREMIUM_FINISHES, SUPPORTED_PREMIUM_PRD } from '../../features/PremiumFinish/config';
import { ProductData } from '../../hooks/calcifer/useProductConfiguration';
import { noticeError } from '../../utils/newRelicUtils';

const FINISH_OPTION = 'Finish';

class PremiumFinishStore {
    @observable isPremiumFinishProduct = false;
    @observable supportsPremiumFinish = false;
    @observable isPremiumCard = false;
    /**
     * The default finish of the product
     */
    @observable defaultPremiumFinish: string = FINISH_TYPE.none;
    @observable.struct pricing: ChoiceGroupsPricing = {};
    /**
     * @description Supported premium finish variants. In the case of Standard Business Card it supports
     * Embossed Gloss, Foil Accent and None.
     *
     * @example sbc = ['Embossed Gloss', 'Foil Accent', 'None']
     *
     */
    @observable supportedPremiumVariants: string[] = [];

    constructor() {
        makeObservable(this);
    }

    @action.bound
    private moveNoneToEndOfList(filteredPremiumOptions: CompatibleOptionsEntry[]) {
        if (filteredPremiumOptions.length) {
            const options = filteredPremiumOptions[0].values;
            const noneFinishIndex = options.findIndex((value) => value === FINISH_TYPE.none);
            if (noneFinishIndex !== -1) {
                const deletedElement = options.splice(noneFinishIndex, 1);
                options.push(...deletedElement);
            }
            this.supportedPremiumVariants = options;
        }
    }

    @action.bound
    private filterSupportedFinishes() {
        const containsNone = this.supportedPremiumVariants.includes(FINISH_TYPE.none);
        this.supportedPremiumVariants = this.supportedPremiumVariants.filter((finish) =>
            SUPPORTED_PREMIUM_FINISHES.includes(finish)
        );
        if (containsNone) {
            this.supportedPremiumVariants.push(FINISH_TYPE.none);
        }
    }

    @action.bound
    async setSupportedVariantProperties(productData: ProductData, authHeader: string, signal?: AbortSignal) {
        /**
         * There are multiple ways finish variants of the product are supported
         * 1. Through PRD
         * 2. Supported select options
         */
        const compatibleOptionsResponse = await getProductSupportedAttributes(
            productData.productKey,
            productData?.customerSelectedOptions,
            productData.productVersion,
            authHeader,
            signal
        );

        if (compatibleOptionsResponse.length) {
            const filteredPremiumOptions = compatibleOptionsResponse.filter((prod) => prod.name === FINISH_OPTION);
            this.moveNoneToEndOfList(filteredPremiumOptions);
            this.filterSupportedFinishes();

            /**
             * Checking is the PRD supported for premium-finishes
             */
            const productVariant = SUPPORTED_PREMIUM_PRD[productData.productKey];
            if (productVariant) {
                runInAction(() => {
                    this.supportsPremiumFinish = true;
                    this.isPremiumFinishProduct = true;
                    this.defaultPremiumFinish = productVariant;
                    this.isPremiumCard = true;
                });
                return;
            }
            const premiumOption = productData.customerSelectedOptions[FINISH_OPTION];

            /**
             * Checking is the selected finish option supported
             */
            if (SUPPORTED_PREMIUM_FINISHES.includes(premiumOption)) {
                runInAction(() => {
                    this.supportsPremiumFinish = true;
                    this.isPremiumFinishProduct = true;
                    this.defaultPremiumFinish = premiumOption;
                });
                return;
            }

            /**
             * Filtering the finish-compatible options to filter out only the supported options
             *
             * Setting Default to `none`
             */
            if (filteredPremiumOptions.length > 0 && filteredPremiumOptions[0].values.length > 0) {
                const includesSupportedPremiumFinishes = filteredPremiumOptions[0].values.some(
                    (supportedPremiumFinish) => SUPPORTED_PREMIUM_FINISHES.includes(supportedPremiumFinish)
                );
                if (includesSupportedPremiumFinishes) {
                    runInAction(() => {
                        this.supportsPremiumFinish = true;
                        this.isPremiumFinishProduct = false;
                        this.defaultPremiumFinish = FINISH_TYPE.none;
                    });
                }
            }
        }
    }

    @action.bound
    async setPremiumPricing(productData: ProductData, locale: string, signal?: AbortSignal) {
        if (this.supportsPremiumFinish) {
            const supportedChoices = this.supportedPremiumVariants.reduce((previous, supportedVariant) => {
                return { [supportedVariant]: { Finish: supportedVariant }, ...previous };
            }, {});
            const choices = {
                included: {
                    Finish: this.defaultPremiumFinish,
                },
                ...supportedChoices,
            };
            try {
                const finishPricing = await getDifferentialPricing(
                    productData.productKey,
                    { Thickness: 'Standard', ...productData.selectedOptions },
                    productData.quantity,
                    choices,
                    productData.productVersion,
                    signal
                );

                const { choiceGroupsPricing: flexChoicePrice } = formatPricingData(finishPricing, locale);
                runInAction(() => {
                    this.pricing = flexChoicePrice;
                });
            } catch (error) {
                noticeError(error, {
                    method: 'setPremiumPricing',
                });
            }
        }
    }
}

export const premiumFinishStore = new PremiumFinishStore();
