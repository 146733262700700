import React from 'react';
import { FlexBox, SkeletonText, SkeletonTextLine } from '@vp/swan';
import { uploadPanelStyle } from '../../features/UploadPanel/UploadPanel';
import { DesktopExperience } from '../Experiences';

const skeletonStyle = {
    borderRadius: { borderRadius: 8 },
    rightPane: {
        heading: { width: 275 },
        productInfo: { width: 182, marginTop: 32 },
        uploadBtn: { width: 392 },
        designBack: { width: 298 },
    },
};

export const RightPanelSkeleton = () => {
    return (
        <DesktopExperience>
            <FlexBox flexDirection="column" className={uploadPanelStyle} px="6" py="7">
                <SkeletonText style={skeletonStyle.rightPane.heading}>
                    <SkeletonTextLine style={{ borderRadius: 12, height: 30 }}></SkeletonTextLine>
                </SkeletonText>
                <SkeletonText style={skeletonStyle.rightPane.productInfo}>
                    <SkeletonTextLine style={{ borderRadius: 10 }}></SkeletonTextLine>
                    <SkeletonTextLine style={{ borderRadius: 10 }}></SkeletonTextLine>
                </SkeletonText>
                <SkeletonText style={skeletonStyle.rightPane.uploadBtn}>
                    <SkeletonTextLine style={{ height: 86, marginTop: 46 }}></SkeletonTextLine>
                    <SkeletonTextLine style={{ height: 86, marginTop: 46 }}></SkeletonTextLine>
                    {/** by default last skeleton line have shorter than other line so make it equal hide last one */}
                    <SkeletonTextLine style={{ display: 'none' }}></SkeletonTextLine>
                </SkeletonText>
                <SkeletonText style={skeletonStyle.rightPane.designBack}>
                    <SkeletonTextLine
                        style={{ ...skeletonStyle.borderRadius, height: 14, marginTop: 16 }}
                    ></SkeletonTextLine>
                </SkeletonText>
            </FlexBox>
        </DesktopExperience>
    );
};
