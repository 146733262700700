/**
 * Reference - {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/master/src/shared/utils/Cart/watchClient.ts}
 */
import { REQUESTOR } from '../config/constant';
import { fetchWrapper } from '../utils';

const WATCH_URL = 'https://watch.design.vpsvc.com';

/**
 * Attempts to add the work entity to cart under the given locale,
 * or updates the work in cart if it's already been added
 * @param {string} locale - the locale of the page
 * @return The output of adding the work to cart
 */
export const callWatch = async (
    authorizationHeader: string,
    locale: string | undefined,
    workId: string
): Promise<void> => {
    let url = `${WATCH_URL}/api/v1/vistaprint-production/upsert/${workId}?from=${REQUESTOR}`;
    if (locale) {
        url += `&locale=${locale}`;
    }
    const response = await fetchWrapper(url, {
        method: 'POST',
        requestInitiator: 'callWatch',
        headers: {
            From: REQUESTOR,
            Accept: 'application/json',
            Authorization: authorizationHeader,
        },
    });

    return response;
};
