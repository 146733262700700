import React, { createContext, useContext } from 'react';
import { ValidationStore } from './ValidationStore';

interface ValidationProps {
    children?: React.ReactNode | React.ReactNode[];
    validationStore: ValidationStore;
}

type ValidationContextProp = {
    validationStore: ValidationStore;
};

export const validationContext = createContext<ValidationContextProp | null>(null);

export const ValidationProvider = ({ children, validationStore }: ValidationProps) => {
    return (
        <validationContext.Provider value={{ validationStore: validationStore }}>{children}</validationContext.Provider>
    );
};

export const useValidation = () => {
    const value = useContext(validationContext);
    if (!value) {
        throw new Error('useValidation must be used within a ValidationProvider');
    }
    return value;
};
