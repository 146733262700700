import { Item } from '@design-stack-vista/cdif-types';
import { ItemState, PanelState, SubpanelState } from '@design-stack-vista/cimdoc-state-manager';

export const getImageURLFromPanel = (panel: PanelState) => {
    const { items } = panel;
    return items.length > 0 && items[0].isImageItem() ? items[0].model.previewUrl : null;
};

export function getPanelName(panels: PanelState[], activePanelId = '') {
    return panels.filter((panel) => panel.id === activePanelId).map((panel) => panel.panelProperties.name)[0];
}

export const getAllItemsInCimdocPanels = (panels: PanelState[]): Array<ItemState<Item> | SubpanelState> => {
    const selectedItem = panels.reduce<(ItemState<Item> | SubpanelState)[]>((items, panel) => {
        return [...items, ...panel.items];
    }, []);

    return selectedItem;
};

export const getPanelNameWithDesign = (panels: PanelState[]) => {
    return panels.filter((panel) => panel.items.length > 0).map(({ panelProperties }) => panelProperties.name);
};

export const hasSingleImageItem = (panel: PanelState): boolean =>
    panel.items.length === 1 && panel.items[0].isImageItem();

export const hasSingleShapeItem = (panel: PanelState): boolean =>
    panel.items.length === 1 && panel.items[0].isShapeItem();

export const hasSingleItem = (panel: PanelState): boolean =>
    panel.items.length === 1 && (panel.items[0].isImageItem() || panel.items[0].isShapeItem());

export const getPanelItemIds = (panel: PanelState): string[] => panel.items.map((item) => item.id);
