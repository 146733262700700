import { getImageColorInfo } from '../../../api/sherbert';
import { Color, ColorInfo, ImageAsset } from '../types';

export const getColorsFromUploadedDesign = async ({
    imgAsset,
    authorizationHeader,
    signal,
}: {
    imgAsset: ImageAsset;
    authorizationHeader: string;
    signal?: AbortSignal;
}): Promise<string[] | null> => {
    const colorInfo = await getImageColorInfo(imgAsset, authorizationHeader, signal);

    if (colorInfo && !colorInfo.isError) {
        const RGBColors = colorInfo.colors.map(
            (color: Color) => color.colorInfo.find((color: ColorInfo) => color.colorSpace === 'RGB')?.color
        );
        return RGBColors;
    }

    return null;
};
