/**
 * Reference - { @link https://gitlab.com/vistaprint-org/design-technology/ds/ui/dst-react-ui-library/-/blob/master/src/components/Analytics.tsx}
 */
import React, { useEffect } from 'react';
import TrackingJS from '@vp/react-tracking';
import omit from 'lodash.omit';
import { ACTION_NAME, ANALYTICS_TENANT, GA, GA_PRODUCT, QUAD_TRACKING_EVENTS } from './constant';
import { tenantUtil } from './tenantUtil';
import { AnalyticsPageData, SegmentEventData } from './type';
import { QuadConsumer, REQUESTOR } from '../../config/constant';
import { currentLocale } from '../../utils/localeUtils';
import {
    Analytics as VcsAnalytics,
    sendEventTracking as vcsEventTracking,
    sendPageTracking as vcsPageTracking,
} from '../VCS/TrackingEvents/Analytics';
import { isVCSConsumer } from '../VCS/utils/isVCSConsumer';

export const isGAProduct = (productKey: string) => {
    return GA_PRODUCT.includes(productKey);
};

export const Analytics = ({ culture }: { culture?: string }) => {
    let analyticsComponent;
    switch (REQUESTOR) {
        case QuadConsumer.VCS:
            analyticsComponent = <VcsAnalytics />;
            break;
        default:
            analyticsComponent = (
                <TrackingJS
                    tenant={ANALYTICS_TENANT}
                    culture={culture || currentLocale}
                    environment={tenantUtil.isProdTenant() ? 'prod' : 'dev'}
                />
            );
            break;
    }
    return <>{analyticsComponent}</>;
};

/**
 * Valid Design Services page tracking events is documented here:
 * https://vistaprint.atlassian.net/wiki/spaces/DST/pages/1330544777/Design+Services+Page+Tracking
 */
export function usePageTracking(analyticsPageData: AnalyticsPageData) {
    useEffect(() => sendPageTracking(analyticsPageData), []);
}

/**
 * Valid Design Services page tracking events is documented here:
 * https://vistaprint.atlassian.net/wiki/spaces/DST/pages/1330544777/Design+Services+Page+Tracking
 */
export function sendPageTracking(analyticsPageData: AnalyticsPageData) {
    if (isVCSConsumer()) {
        vcsPageTracking();
        return;
    }
    if (window?.tracking?.page) {
        window.tracking.page({
            ...analyticsPageData,
            genTrack: '',
            languageLocale: analyticsPageData.culture || currentLocale,
        });
    } else {
        setTimeout(() => sendPageTracking(analyticsPageData), 1000);
    }
}

/**
 * Valid Design Services click tracking events are documented here:
 * https://vistaprint.atlassian.net/wiki/spaces/DST/pages/1332740898/Design+Services+Event+Click+Tracking
 */
export function sendEventTracking({
    analyticsEventData,
    analyticsPageData,
}: {
    analyticsEventData: SegmentEventData;
    analyticsPageData?: AnalyticsPageData;
}) {
    if (isVCSConsumer()) {
        vcsEventTracking();
        return;
    }

    const { action, ...trackingData } = analyticsEventData;
    const isGAEvent = isGAProduct(trackingData.core_product_id) && action !== ACTION_NAME.EXPERIMENT_CLICKED;

    if (window?.tracking?.track) {
        if (!isGAEvent) {
            const expLabel = trackingData.experimentLabel;
            const experimentData = omit(trackingData, ['experimentLabel']);
            window.tracking.track(ACTION_NAME.EXPERIMENT_CLICKED, {
                category: 'Design Services',
                pageSection: 'Design Services',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ...(experimentData as any),
                label: expLabel ?? experimentData.label,
            });
        } else {
            const gaData = omit(trackingData, [
                'experimentDetail',
                'experimentId',
                'experimentName',
                'variationId',
                'variationName',
                'experimentLabel',
            ]);
            const category = action === ACTION_NAME.NAVIGATION_CLICKED ? GA.NAVIGATION_CATEGORY : GA.PAGE_SECTION;

            const label = gaData.eventDetail?.includes(';Text Link;') // navigation event
                ? GA.LABELS.CONTENT_TEXT_LINK
                : action === ACTION_NAME.PRODUCT_VIEWED
                ? QUAD_TRACKING_EVENTS.PRODUCT_VIEWED_EVENT
                : GA.LABELS.DEFAULT;

            window.tracking.track(action, {
                label,
                category,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ...(gaData as any),
                ...analyticsPageData,
            });
        }
    } else {
        setTimeout(() => sendEventTracking({ analyticsEventData, analyticsPageData }), 1000);
    }
}
