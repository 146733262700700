import React from 'react';
import type { ReactNode } from 'react';
import { css } from '@emotion/css';
import { className, FlexBox, tokens } from '@vp/swan';
import { generateTestIdFromText } from '../../../../utils/generateTestIdFromText';

const getOptionButtonStyle = (selected: boolean) => css`
    position: relative;
    width: 100%;
    border: ${selected ? '2px' : '1px'} solid
        ${selected ? tokens.SwanSemColorBorderStrongActive : tokens.SwanSemColorBorderStandard};
    border-radius: ${tokens.SwanSemBorderRadiusContainer};
    svg {
        width: 20px;
        height: 20px;
    }
`;

interface Props {
    selected: boolean;
    children: ReactNode | ReactNode[];
    showActiveIcon?: boolean;
    onClick: () => void;
    customClass?: string;
    testId?: string;
}

export const OptionButton = ({ selected, children, onClick, customClass, testId }: Props) => {
    return (
        <FlexBox
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            as="button"
            className={className(getOptionButtonStyle(selected), customClass)}
            onClick={onClick}
            bgc="standard"
            py={{ xs: '4', sm: '4' }}
            px={{ xs: '5', sm: '5' }}
            data-testid={generateTestIdFromText(testId)}
        >
            {children}
        </FlexBox>
    );
};
