import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const AdjustContrastIcon = (props: SvgProps): JSX.Element => {
    return (
        <svg viewBox="0 0 16 18" fill="none" {...props}>
            <path
                d="M8 16.5C12.1421 16.5 15.5 13.1421 15.5 9C15.5 4.85786 12.1421 1.5 8 1.5C3.85786 1.5 0.5 4.85786 0.5 9C0.5 13.1421 3.85786 16.5 8 16.5Z"
                fill="white"
                stroke="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 15C11.3137 15 14 12.3137 14 9C14 5.68629 11.3137 3 8 3V15Z"
                fill="black"
            />
        </svg>
    );
};
