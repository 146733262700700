import { useCallback, useMemo } from 'react';
import { setRotationDegrees } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useAutoRecognitionTracking } from './useAutoRecognitionTracking';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useProductParams } from '../../../providers';
import {
    getAllItemsInCimdocPanels,
    getImageDimensionInInches,
    getImageURLFromPanel,
    noticeError,
} from '../../../utils';
import { useDebugFlag } from '../../Debug/useDebugFlag';
import { changeSizeStore } from '../store';
import {
    checkIsAutoRecognitionPRD,
    getAspectRatioSortedProductOptions,
    getCompatibleOrientationData,
    getRotationAngle,
} from '../utils';

export const useAutoRecognition = () => {
    const {
        cimDocStore: { panels },
        layoutStore: { visiblePanels },
        executeCommand,
    } = useDesignEngine();
    const {
        sizeAttribute,
        setupAutoRecognition,
        isAutoRecognitionApplied,
        cleanUpAutoRecognition,
        resetAutoRecognition,
        setShowAutoRecognitionLoader,
        orientationSizesMap,
        compatibleOrientations,
    } = changeSizeStore;
    const { isFlagEnabled } = useDebugFlag();
    const { auth: vpAuth } = useIdentityContext();
    const { locale } = useProductParams();
    const { productData } = useProductConfiguration();
    const { trackAutoRecognitionTriggered, trackSizeSelection, trackOpenChangeSizeModal } =
        useAutoRecognitionTracking();

    const authorizationHeader = vpAuth.getAuthorizationHeader();
    const isAutoRecognitionEnabled = isFlagEnabled('quad_auto_recognition');
    const isAutoRecognitionPRD = useMemo(
        () => (productData?.productKey ? checkIsAutoRecognitionPRD(productData.productKey) : false),
        [productData?.productKey]
    );

    const applyAutoRecognition = useCallback(async () => {
        try {
            const imageURL = getImageURLFromPanel(visiblePanels[0]);
            if (!productData || !imageURL || !authorizationHeader) {
                return;
            }

            setShowAutoRecognitionLoader(true);

            const {
                customerSelectedOptions,
                selectedOptions,
                studioConfiguration: { productMinDpi },
            } = productData;

            const [{ orientation, direction, angle }, imageDimensions] = await Promise.all([
                getCompatibleOrientationData(
                    imageURL,
                    sizeAttribute,
                    compatibleOrientations,
                    orientationSizesMap,
                    selectedOptions,
                    authorizationHeader
                ),
                getImageDimensionInInches(imageURL, authorizationHeader, productMinDpi),
            ]);

            const { bestFits, others } = await getAspectRatioSortedProductOptions(
                orientation,
                imageDimensions,
                orientationSizesMap,
                sizeAttribute,
                locale,
                productData
            );

            const autoRecognitionData = {
                bestFits,
                others,
                preSelected: customerSelectedOptions[sizeAttribute] ?? bestFits[0],
                detectedOrientation: orientation,
            };

            if (angle && direction) {
                executeCommand(setRotationDegrees, {
                    id: visiblePanels[0].items[0].id,
                    degrees: getRotationAngle(direction, angle),
                });
            }

            setupAutoRecognition(autoRecognitionData);

            trackAutoRecognitionTriggered({ autoRecognitionData });
            trackOpenChangeSizeModal({
                autoOpened: true,
                selectedOrientation: autoRecognitionData.detectedOrientation,
                selectedSize: autoRecognitionData.preSelected,
            });
            trackSizeSelection({
                selectedSize: autoRecognitionData.preSelected,
                selectedOrientation: autoRecognitionData.detectedOrientation,
                userSelected: false,
            });
        } catch (error) {
            cleanUpAutoRecognition();
            noticeError(error, {
                method: 'applyAutoRecognition',
            });
        }
    }, [
        visiblePanels,
        sizeAttribute,
        productData,
        orientationSizesMap,
        compatibleOrientations,
        authorizationHeader,
        locale,
    ]);

    const handleApplyAutoRecognition = async () => {
        if (
            isAutoRecognitionPRD &&
            isAutoRecognitionEnabled &&
            !isAutoRecognitionApplied &&
            getAllItemsInCimdocPanels(panels).length === 1
        ) {
            await applyAutoRecognition();
        }
    };

    const handleResetAutoRecognition = () => {
        if (isAutoRecognitionPRD && getAllItemsInCimdocPanels(panels).length === 0 && isAutoRecognitionEnabled) {
            resetAutoRecognition();
        }
    };

    return { handleApplyAutoRecognition, handleResetAutoRecognition };
};
