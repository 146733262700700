import React from 'react';
import { css } from '@emotion/css';
import { Typography } from '@vp/swan';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';

const adjustContrastSliderDescription = css`
    width: 100%;
`;

export const AdjustContrastSliderDescription = () => {
    const { isSmall } = useScreenLayout();

    return (
        <Typography
            px={{ xs: '5', sm: '9' }}
            mb={{ xs: '4', sm: '5' }}
            fontSize={isSmall ? 'small' : 'standard'}
            textAlign="center"
            className={adjustContrastSliderDescription}
        >
            {text('adjustContrastMoveTheSlider')}
        </Typography>
    );
};
