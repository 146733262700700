import type { DesignState } from '@design-stack-vista/cimdoc-state-manager';
import type { BaseDesignRequirements, LayoutStore } from '@design-stack-vista/interactive-design-engine-core';
import { BaseExtension, LAYOUT_STORE_TOKEN } from '@design-stack-vista/interactive-design-engine-core';
import { computed, makeObservable } from 'mobx';
import { GuideWithBoundingArea, MaskWithBoundingArea } from '../../../utils/normalizeDesignViews';

/**
 * This component fetches the masks and guides from the design requirements.
 */
export class PanelChromesExtension extends BaseExtension {
    static supports(state: DesignState) {
        return state.isPanelState();
    }

    static override inject = ['designRequirements', LAYOUT_STORE_TOKEN];

    constructor(
        designState: DesignState,
        private designRequirements: BaseDesignRequirements,
        private layoutStore: LayoutStore
    ) {
        super(designState);
        makeObservable(this);
    }

    @computed
    get strokeWidth() {
        // The original component was using unzoomedWidth / zoomedWidth for this value, which cancels out to just 1/zoom.
        return 1 / this.layoutStore.zoom;
    }

    @computed
    get masks(): MaskWithBoundingArea[] {
        const panelRequirements = this.designRequirements.panels.find((p) => p.id === this.designState.id);
        return panelRequirements?.masks ?? [];
    }

    @computed
    get guides(): GuideWithBoundingArea[] {
        const panelRequirements = this.designRequirements.panels.find((p) => p.id === this.designState.id);
        return panelRequirements?.guides ?? [];
    }
}
