import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/css';
import {
    SelectedValue,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    StandardTile,
    StandardTileContents,
    StandardTileDescription,
    StandardTileName,
    StandardTilePrice,
    tokens,
    tokensRaw,
    Typography,
} from '@vp/swan';
import { DesktopExperience } from '../../../components/Experiences';
import { ActiveOptionIcon } from '../../../components/SvgIcons';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useProductParams } from '../../../providers';
import { formatPrice } from '../../../utils';
import { text } from '../../../utils/localization';
import { PremiumFinishSelectionSetType } from '../type';

const selectionSetStyles = css`
    &.swan-selection-set.swan-selection-set-skin-tiles-horizontal .swan-selection-set-label {
        margin-bottom: 12px;
        min-height: 105px;
        @media only screen and (max-width: ${tokensRaw.SwanBaseBreakpointSmEnd}) {
            min-height: 90px;
        }

        .swan-standard-tile {
            .swan-standard-tile-image {
                flex-basis: 85px;
                min-width: 85px;
                .swan-fluid-image {
                    height: 105px;
                }
            }
            .button-content-pricing-included {
                text-decoration: line-through;
                color: ${tokens.SwanSemColorTextSubtle};
            }
            .button-content-pricing-increment {
                color: ${tokens.SwanSemColorTextPromo};
            }
        }

        .swan-standard-tile-contents {
            justify-content: center;
            padding: ${tokens.SwanSemSpace2} ${tokens.SwanSemSpace4};
            margin: 0;
        }

        .swan-standard-tile-name {
            font-size: ${tokens.SwanSemFontSizeSmall};
        }

        .swan-standard-tile-horizontal {
            margin-bottom: ${tokens.SwanSemSpaceNone};
            min-height: inherit;
        }
    }
`;

const activeIconStyles = css`
    position: absolute;
    right: 16px;
    top: 16px;
    height: 20px;
    width: 20px;
`;

const addImage = (url: string) => css`
    width: 85px;
    background-image: url(${url});
    background-position-x: 50%;
    background-position-y: 50%;
    background-color: ${tokens.SwanSemColorBgStrong};
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
`;

interface Props {
    handleSelectionChange: (data: string) => void;
    selections: PremiumFinishSelectionSetType[];
    defaultSelection: SelectedValue;
}

export const FinishSelectionSet = ({ handleSelectionChange, selections, defaultSelection }: Props) => {
    const [selectionValue, setSelectionValue] = useState(defaultSelection);
    useEffect(() => {
        setSelectionValue(defaultSelection);
    }, [defaultSelection]);

    const handleSelection = useCallback(
        (value: SelectedValue) => {
            if (value) {
                setSelectionValue(value);
                handleSelectionChange(value);
            }
        },
        [handleSelectionChange]
    );
    const { locale } = useProductParams();
    const { productPrice } = useProductConfiguration();
    const currency = productPrice?.currency || '';
    const includedPrice = 0;
    const includedFormattedPrice = formatPrice(includedPrice, locale, currency);

    return (
        <SelectionSet
            variant="single-select"
            skin="tiles-horizontal"
            onSelectedValueChange={handleSelection}
            selectedValue={selectionValue}
            className={selectionSetStyles}
            defaultSelectedValue={defaultSelection}
        >
            {selections.map(({ description, thumbnailUrl, pricing, title, id }) => {
                const included = pricing?.increment === 'Included';
                const priceToDisplay = included ? includedFormattedPrice : pricing?.increment;
                const priceClassName =
                    priceToDisplay?.includes('-') || included ? '' : 'button-content-pricing-increment';
                return (
                    <SelectionSetInput data-testid={title} key={id} value={id}>
                        <SelectionSetLabel>
                            <StandardTile layout="horizontal">
                                <span className={addImage(thumbnailUrl)}></span>
                                <StandardTileContents as="span">
                                    <StandardTileName as="span">{text(title)}</StandardTileName>
                                    <StandardTileDescription as="span" fontSize="xsmall">
                                        {text(description)}
                                    </StandardTileDescription>
                                    {pricing && (
                                        <StandardTilePrice as="span" fontSize="xsmall">
                                            <Typography className={priceClassName} ml="2">
                                                {priceToDisplay}
                                            </Typography>
                                        </StandardTilePrice>
                                    )}
                                </StandardTileContents>
                            </StandardTile>
                            {selectionValue === id && (
                                <DesktopExperience>
                                    <ActiveOptionIcon className={activeIconStyles} />
                                </DesktopExperience>
                            )}
                        </SelectionSetLabel>
                    </SelectionSetInput>
                );
            })}
        </SelectionSet>
    );
};
