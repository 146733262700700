import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { css } from '@emotion/css';
import { TextArea, tokensRaw, Typography } from '@vp/swan';
import { FinishSelectionSet } from './FinishSelectionSet';
import { text } from '../../../utils/localization';
import { FinishSelectionType, PremiumFinishSelectionSetType } from '../type';

const textAreaStyle = css`
    &.swan-textarea {
        width: 100%;
        min-height: unset;
        background: none;
        padding: 0;
        border: none;
        overflow: auto;
        resize: none;
        border-bottom: 1px solid ${tokensRaw.SwanSemColorBorderStandard};
    }
`;

interface Props {
    handleSelectionChange: (data: FinishSelectionType) => void;
    selections: PremiumFinishSelectionSetType[];
    showInstructionNotes: boolean;
    instruction: string;
    defaultSelection: string;
}

export const FinishAreaSelectionSet = ({
    handleSelectionChange,
    selections,
    showInstructionNotes,
    instruction,
    defaultSelection,
}: Props) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    /**
     * Want to expand the textarea as the user enters there text.
     */
    useLayoutEffect(() => {
        if (instruction && textAreaRef.current) {
            textAreaRef.current.style.height = 'inherit';
            textAreaRef.current.style.minHeight = '40px';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight + 4}px`;
            textAreaRef.current.scrollIntoView();
        }
    }, [instruction]);

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = event.target;
            if (textAreaRef.current) {
                textAreaRef.current.scrollIntoView();
            }
            handleSelectionChange({ selectedValue: defaultSelection, optionalInstruction: value });
        },
        [handleSelectionChange, defaultSelection]
    );

    const handleSelectionSetChange = useCallback(
        (value: string) => {
            handleSelectionChange({ selectedValue: value, optionalInstruction: instruction });
        },
        [handleSelectionChange, instruction]
    );

    return (
        <>
            <FinishSelectionSet
                selections={selections}
                handleSelectionChange={handleSelectionSetChange}
                defaultSelection={defaultSelection}
            />
            {showInstructionNotes && defaultSelection && (
                <>
                    <Typography fontWeight="bold" fontSize="small" mb="3" mt="5">
                        {text('finishInstructionOptionalNote')}
                    </Typography>
                    <TextArea
                        className={textAreaStyle}
                        ref={textAreaRef}
                        fullWidth={true}
                        fontSize="standard"
                        rows={1}
                        minLength={3}
                        pb="4"
                        placeholder={text('finishInstructionNotesPlaceholder')}
                        onInput={onChange}
                        value={instruction}
                    />
                </>
            )}
        </>
    );
};
