"use strict";
export const mupdfView = {};
let lastPromiseId = 0;
const messagePromises = new Map();
let worker;
fetch("https://mupdf.docext.cimpress.io/1.0.4/mupdf-view-worker.js").then((script) => {
  script.text().then((script_as_text) => {
    worker = makeWorker(script_as_text);
    if (!worker) return;
    mupdfView.ready = new Promise((resolve, reject) => {
      worker.onmessage = function (event) {
        let type = event.data[0];
        if (type === "READY") {
          let methodNames = event.data[1];
          for (let method of methodNames) mupdfView[method] = wrap(method);
          worker.onmessage = onWorkerMessage;
          resolve();
        } else if (type === "ERROR") {
          let error = event.data[1];
          reject(new Error(error));
        } else {
          reject(new Error(`Unexpected first message: ${event.data}`));
        }
      };
    });
  });
});

function makeWorker(script) {
  var URL = window.URL || window.webkitURL;
  var Blob = window.Blob;
  var Worker = window.Worker;

  if (!URL || !Blob || !Worker || !script) {
    return null;
  }

  var blob = new Blob([script]);
  var worker = new Worker(URL.createObjectURL(blob));
  return worker;
}

function onWorkerMessage(event) {
  let [type, id, result] = event.data;
  if (type === "RESULT") messagePromises.get(id).resolve(result);
  else if (type === "READY") messagePromises.get(id).reject(new Error("Unexpected READY message"));
  else if (type === "ERROR") {
    let error = new Error(result.message);
    error.name = result.name;
    error.stack = result.stack;
    messagePromises.get(id).reject(error);
  } else messagePromises.get(id).reject(new Error(`Unexpected result type '${type}'`));

  messagePromises.delete(id);
}

function wrap(func) {
  return function (...args) {
    return new Promise(function (resolve, reject) {
      let id = lastPromiseId++;
      messagePromises.set(id, { resolve, reject });
      if (args[0] instanceof ArrayBuffer) worker.postMessage([func, id, args], [args[0]]);
      else worker.postMessage([func, id, args]);
    });
  };
}

mupdfView.terminate = function () {
  worker.terminate();
};
