import { useCallback } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useNotificationFramework } from '../../../components/Notification';
import { PRICING_NOTIFICATION_DISMISS_TIMEOUT, ProductAttributes } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useTranslatedConfig } from '../../../hooks/useTranslatedConfig';
import { useProductParams } from '../../../providers';
import { noticeError } from '../../../utils';
import { getProductOptions, getResizedDocument, transformDocument, useLoadProduct } from '../../Flexibility/common';
import { changeSizeStore } from '../store';
import { AutoRecognitionDataType } from '../types';
import { getAutoRecognitionNotification } from '../utils';

export const useAutoLoadProduct = () => {
    const {
        sizeAttribute,
        changeSizePrices,
        autoRecognitionData: autoRecognitionDataState,
        isChangeSizeModalAutoOpened,
        setShowAutoRecognitionLoader,
    } = changeSizeStore;
    const designEngine = useDesignEngine();
    const { loadProduct } = useLoadProduct();
    const { auth: vpAuth } = useIdentityContext();
    const { productData } = useProductConfiguration();
    const { notifyCustomer } = useNotificationFramework();
    const { locale, selectedQuantity } = useProductParams();
    const { getTranslatedOptionName } = useTranslatedConfig();

    const authorizationHeader = vpAuth.getAuthorizationHeader();

    const loadAutoRecognitionProduct = useCallback(
        async (autoRecognitionDataProp?: AutoRecognitionDataType) => {
            try {
                const autoRecognitionData = autoRecognitionDataProp ?? autoRecognitionDataState;

                if (!autoRecognitionData || !productData || !authorizationHeader) {
                    return;
                }

                const { productKey, productVersion } = productData;
                const productAttributes = {
                    [sizeAttribute]: autoRecognitionData.preSelected,
                    [ProductAttributes.Orientation]: autoRecognitionData.detectedOrientation,
                };
                const targetOptions = getProductOptions(productData, productAttributes);

                const messageToShowCustomer = getAutoRecognitionNotification(
                    isChangeSizeModalAutoOpened,
                    sizeAttribute,
                    autoRecognitionData,
                    productData,
                    changeSizePrices,
                    getTranslatedOptionName
                );

                const designDocument = await designEngine.getCimDoc();
                const newCimDoc = await getResizedDocument({
                    designDocument,
                    selectedOptions: targetOptions,
                    authorizationHeader,
                    productKey: productData.productKey,
                    productVersion: productData.productVersion,
                    locale,
                });
                transformDocument(newCimDoc);

                const loadedNewProduct = await loadProduct({
                    newProduct: {
                        productKey,
                        productVersion,
                        productAttributes,
                    },
                    newCimDoc,
                });

                if (messageToShowCustomer && loadedNewProduct) {
                    notifyCustomer(
                        {
                            notificationType: 'positive',
                            messageToShowCustomer,
                        },
                        true,
                        PRICING_NOTIFICATION_DISMISS_TIMEOUT
                    );
                }

                setShowAutoRecognitionLoader(false);
            } catch (error) {
                noticeError(error, {
                    method: 'loadAutoRecognitionProduct - useAutoLoadProduct',
                });
            }
        },
        [
            autoRecognitionDataState,
            sizeAttribute,
            productData,
            locale,
            selectedQuantity,
            authorizationHeader,
            isChangeSizeModalAutoOpened,
            changeSizePrices,
            designEngine,
            getTranslatedOptionName,
            loadProduct,
        ]
    );

    return {
        loadAutoRecognitionProduct,
    };
};
