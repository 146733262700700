import { action, makeObservable, observable } from 'mobx';
import { SubstrateColorStoreType, SubstrateColorsWithStockInfo } from './types';
import { ProductAttributes } from '../../config/constant';

class SubstrateColorStore {
    @observable.struct substrateColorsWithStockInfo: SubstrateColorsWithStockInfo = {};
    @observable substrateColorOptionName: string = ProductAttributes.SubstrateColor;
    @observable isColorSwatchModalOpen = false;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setSubstrateColorStore(state: SubstrateColorStoreType) {
        this.substrateColorsWithStockInfo = state.substrateColorsWithStockInfo;
        this.substrateColorOptionName = state.substrateColorOptionName;
    }

    @action.bound
    setIsColorSwatchModalOpen(status: boolean) {
        this.isColorSwatchModalOpen = status;
    }
}

export const substrateColorStore = new SubstrateColorStore();
