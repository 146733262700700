import { useState } from 'react';
import { fetchSTARSRouting } from '@design-stack-vista/stars-client';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { REQUESTOR } from '../config/constant';
import { useProductParams } from '../providers';
import { handleError } from '../utils';

export const useStudioBaseURL = () => {
    const { productKey, productVersion, locale } = useProductParams();
    const [studioBaseUrl, setStudioBaseUrl] = useState<string>();

    useAsyncEffect(
        (handler) => {
            (async () => {
                try {
                    const starsResponse = await fetchSTARSRouting({
                        placement: 'studio',
                        productKey,
                        productVersion,
                        locale,
                        requestor: REQUESTOR,
                        signal: handler.abortSignal,
                    });

                    let baseUrl = '/studio';
                    if (starsResponse.url?.href) {
                        const pathnameRegex = /([^?]+)/;
                        const matchedPattern = starsResponse.url?.href.match(pathnameRegex);
                        baseUrl = matchedPattern ? matchedPattern[0] : baseUrl;
                    }

                    setStudioBaseUrl(baseUrl);
                } catch (e) {
                    handleError(e, 'useStudioURL');
                }
            })();
        },
        [productKey, productVersion, locale]
    );

    return studioBaseUrl;
};
