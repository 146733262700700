import { useEffect } from 'react';
import type { RefObject } from 'react';
import { clamp } from '@design-stack-ct/utility-core';
import { useDesignEngine, useFocus, useZoom } from '@design-stack-vista/core-features';
import { useLayoutContainers } from '@design-stack-vista/ida-framework';
import { useGesture } from '@use-gesture/react';
import { useProductConfiguration } from './calcifer';
import { useActivePanel } from './useActivePanel';
import { usePanelNudger } from './usePanelNudger';
import { useSceneConfiguration } from './useSceneConfiguration';
import { useScreenLayout } from './useScreenLayout';
import { Zoom } from '../config/constant';
import { useLayoutZoom } from '../providers';
import { allowDownPan, allowLeftPan, allowRightPan, allowUpPan } from '../utils';
import { getHeightZoomRatio } from '../utils/getHeightZoomRatio';

interface Props {
    domTarget: RefObject<HTMLElement>;
}

export function useZoomFramework({ domTarget }: Props) {
    const { isSmall } = useScreenLayout();
    const { sceneConfig } = useSceneConfiguration();
    const { panContainer } = useLayoutContainers();
    const { productData } = useProductConfiguration();
    const designAreaRef = document.getElementsByClassName('quad-design-area')[0];
    const panel = useActivePanel();
    const {
        layoutStore: { initialZoom, zoom, offset, setOffset, setInitialZoom },
        idaStore: { activeDesignPanelId },
    } = useDesignEngine();
    const { zoomTo } = useZoom();
    const { focusDesignArea } = useFocus();
    const { setCanvasZoomFactor, setCanvasZoomScale } = useLayoutZoom();

    // Prevent interference with Safari accessibility zoom
    // https://use-gesture.netlify.app/docs/hooks/#about-the-pinch-gesture
    useEffect(() => {
        function cancelGesture(event: Event) {
            event.preventDefault();
        }

        document.addEventListener('gesturestart', cancelGesture);
        document.addEventListener('gesturechange', cancelGesture);

        return () => {
            document.removeEventListener('gesturestart', cancelGesture);
            document.removeEventListener('gesturechange', cancelGesture);
        };
    }, []);

    // Add panel nudge feature on use of arrow keys as we don't use browser scroll
    usePanelNudger(designAreaRef?.getBoundingClientRect(), isSmall);

    const currentZoomFactor = zoom / initialZoom;

    // adjust viewport zoom level based on product dimensions
    useEffect(() => {
        if (panel && activeDesignPanelId && panContainer && sceneConfig?.[panel.id]?.underlayUrl) {
            const sceneInfo = productData?.scenesConfiguration.underlay.find(
                (underlay) => underlay.name.toLowerCase() === panel.panelProperties.name.toLowerCase()
            )?.sceneInfo;
            if (sceneInfo) {
                const { documentSlot, height } = sceneInfo;
                if (documentSlot) {
                    const initialZoomFactor = productData?.studioConfiguration?.initialZoomFactor;
                    const heightZoomRatio = initialZoomFactor
                        ? Number(initialZoomFactor)
                        : getHeightZoomRatio({
                              underlaySceneHeight: height,
                              decorationAreaHeight: documentSlot.height,
                          });
                    setInitialZoom(heightZoomRatio * initialZoom);
                    zoomTo(heightZoomRatio * initialZoom);
                    focusDesignArea(activeDesignPanelId, { changeZoom: false });
                }
            }
        }
    }, [panel, sceneConfig, productData?.scenesConfiguration, activeDesignPanelId]);

    // use gestures for pinch to zoom and panning on design area
    useGesture(
        {
            onPinch: (state) => {
                const {
                    event,
                    dragging,
                    last,
                    movement: [scale],
                } = state;

                if (dragging) {
                    return;
                }
                event.preventDefault();
                event.stopPropagation();

                const newZoom = clamp(currentZoomFactor * scale, Zoom.MIN_VALUE, Zoom.MAX_VALUE);
                const clampedScale = newZoom / currentZoomFactor;

                if (last) {
                    setCanvasZoomScale(1);
                    setCanvasZoomFactor(newZoom);
                    zoomTo(newZoom * initialZoom);
                    activeDesignPanelId && focusDesignArea(activeDesignPanelId, { changeZoom: false });
                } else {
                    setCanvasZoomScale(clampedScale);
                    setCanvasZoomFactor(newZoom);
                }
            },
            onWheel: (state) => {
                const { pinching, dragging, event, axis, delta, direction, ctrlKey } = state;
                if (dragging || pinching || ctrlKey || !panContainer || !designAreaRef) {
                    return;
                }
                event.preventDefault();
                event.stopPropagation();
                const panAreaRect = panContainer.getBoundingClientRect();
                const designAreaRect = designAreaRef.getBoundingClientRect();
                if (
                    (axis === 'x' && direction[0] === 1 && allowRightPan(panAreaRect, designAreaRect)) ||
                    (axis === 'x' && direction[0] === -1 && allowLeftPan(panAreaRect, designAreaRect))
                ) {
                    setOffset({ x: offset.x - delta[0], y: offset.y });
                } else if (
                    (axis === 'y' && direction[1] === 1 && allowUpPan(panAreaRect, designAreaRect)) ||
                    (axis === 'y' && direction[1] === -1 && allowDownPan(panAreaRect, designAreaRect))
                ) {
                    setOffset({ x: offset.x, y: offset.y - delta[1] });
                }
            },
        },
        {
            // shared config
            target: domTarget,
            eventOptions: { passive: false },
            // gesture specific config
            pinch: {
                enabled: true,
                pointer: { touch: true },
            },
            wheel: {
                enabled: currentZoomFactor > 1 && !isSmall,
            },
        }
    );

    // Set layout zoom factor and zoom used when initialZoom Changes
    // initialZoom changes when window is resized
    useEffect(() => {
        setCanvasZoomFactor(1);
    }, [initialZoom]);
}
