import React from 'react';
import { css } from '@emotion/css';
import { SelectedValue, tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { ColorSwatchPicker } from '../../../components/ColorSwatchPicker';
import { useProductParams } from '../../../providers';
import { text } from '../../../utils/localization';
import { DESKTOP_SUBSTRATE_COLOR_SWATCHES } from '../constants';
import { SubstrateColorsWithStockInfo } from '../types';

const substrateColorPickerStyle = css`
    .swan-color-swatches {
        margin-bottom: 0;
        gap: ${tokens.SwanSemSpace5} ${tokens.SwanSemSpace3};
    }
    .swan-selection-set .swan-color-swatches.swan-color-swatches-super .swan-selection-set-label {
        margin: 0;
    }
    .swan-selection-set .swan-color-swatches.swan-color-swatches-super .swan-selection-set-input {
        position: absolute;
    }
`;

interface SubstrateColorPickerProps {
    renderSubstrateColorsInfo: SubstrateColorsWithStockInfo;
    selectedColor: string;
    onColorSwatchChange: (newSelectedSubstrateColor: SelectedValue) => Promise<void>;
}

export const SubstrateColorPicker = observer((props: SubstrateColorPickerProps) => {
    const { locale } = useProductParams();
    const { renderSubstrateColorsInfo, selectedColor, onColorSwatchChange } = props;
    return (
        <ColorSwatchPicker
            colorSwatches={renderSubstrateColorsInfo}
            colorSwatchesProps={{
                size: 'super',
            }}
            selectedColor={selectedColor}
            onColorSwatchChange={onColorSwatchChange}
            collapse={false}
            maxColorSwatches={DESKTOP_SUBSTRATE_COLOR_SWATCHES}
            locale={locale}
            defaultColorLabel={text('defaultColorLabel')}
            className={substrateColorPickerStyle}
        />
    );
});
