import React, { useCallback } from 'react';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { Card, tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { SelectionOption } from '../../../../../../../../components/SelectionOption';
import { usePanelTitle } from '../../../../../../../../hooks/usePanelTitle';
import { text } from '../../../../../../../../utils/localization';
import { SimplePanelPreview } from '../../../../../../SimplePanelPreview';
import { isPanelEmpty } from '../../../../helper';

interface FrontPanelPreviewProps {
    panel: PanelState;
    onDuplicateFrontDesignClick: () => void;
}

const emptyCardStyles = css`
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    height: 100%;
    &.swan-card {
        border-radius: ${tokens.SwanBaseBorderRadius0};
    }
`;

const emptyCardContainerStyles = css`
    cursor: not-allowed;
    pointer-events: none;
`;

const FrontPanelPreview = observer(({ panel, onDuplicateFrontDesignClick }: FrontPanelPreviewProps) => {
    const designEngine = useDesignEngine();
    const isFrontPanelEmpty = isPanelEmpty(panel);
    const handleSelection = useCallback(() => {
        if (isFrontPanelEmpty) {
            return;
        }
        onDuplicateFrontDesignClick();
    }, [isFrontPanelEmpty]);
    const selectOptionStyles = isFrontPanelEmpty ? emptyCardContainerStyles : '';
    const { getPanelTitle } = usePanelTitle();
    const panelTitle = getPanelTitle(panel?.panelProperties.name)?.toLowerCase();
    return (
        <SelectionOption
            panel={panel}
            onSelection={handleSelection}
            description={text('duplicateFrontDesign', { panelTitle })}
            customStyles={selectOptionStyles}
            testId="front-panel-preview"
        >
            {isFrontPanelEmpty ? (
                <Card fullBleed className={emptyCardStyles} />
            ) : (
                <SimplePanelPreview panel={panel} pixelSize={designEngine.layoutStore.zoom} />
            )}
        </SelectionOption>
    );
});

export default FrontPanelPreview;
