import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { ACTION_NAME, PageSection, PageStage } from '../TrackingEvents/constant';

type TrackingEventDetails = {
    label: string;
    eventDetail: string;
    panel: PanelState;
    isGAEvent?: boolean;
    navigationDetail?: string;
};

export const useQuickToolFireTrackingEvent = () => {
    const { fireEvent } = useCustomEventPropagation();

    const fireTrackingEvent = ({ label, eventDetail, panel, isGAEvent = true }: TrackingEventDetails) =>
        fireEvent({
            name: isGAEvent ? ACTION_NAME.DESIGN_TOOL_USED : ACTION_NAME.EXPERIMENT_CLICKED,
            extraData: {
                experimentLabel: label,
                eventDetail,
                pageSection: PageSection.DesignEditToolbar,
                pageStage: PageStage.Design,
                experimentDetail: {
                    panelId: panel?.id,
                    panelName: panel?.panelProperties.name,
                },
            },
        });

    return { fireTrackingEvent };
};
