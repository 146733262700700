import React from 'react';
import { useLayoutContainers } from '@design-stack-vista/ida-framework';
import { css } from '@emotion/css';
import { FlexBox, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { LoaderType, useLoaderContent } from './useLoaderContent';
import { Loader } from '../../../components/Loader';
import { text } from '../../../utils/localization';
import { useIsSelectionLocalImage } from '../../InstantUpload';

const uploadProgressStyle = css`
    line-height: 150%;
`;

const UploadProgressContent = ({ uploadProgress }: { uploadProgress: number }) => {
    return (
        <FlexBox flexDirection="column" alignItems="center" justifyContent="center">
            <Typography fontWeight="bold" className={uploadProgressStyle}>
                {text('uploadProgressLabel')} - {uploadProgress}%
            </Typography>
            <Typography fontSize="small">{text('uploadProgressDescription')}</Typography>
        </FlexBox>
    );
};

export const PreviewLoader = observer(() => {
    const { panContainer } = useLayoutContainers();
    const { loaderType, uploadProgress } = useLoaderContent() || {};
    const { isSelectionLocalImage } = useIsSelectionLocalImage();

    if (!loaderType || !panContainer || isSelectionLocalImage) {
        return null;
    }

    let loaderContent;
    if (loaderType === LoaderType.UPLOAD) {
        loaderContent = <UploadProgressContent uploadProgress={uploadProgress as number} />;
    } else {
        const loaderLabel = loaderType === LoaderType.SHARPEN ? 'sharpeningLoadingLabel' : 'loadingLabel';
        loaderContent = text(loaderLabel);
    }

    return (
        <Loader testId="preview-loader" accessibilityLabel={text('loadingLabel')} showText={false}>
            {loaderContent}
        </Loader>
    );
});
