import React, { createContext, useContext, useMemo, useState } from 'react';

type FullScreenLoaderData = {
    showLoader: boolean;
    loaderMessage?: string;
    testId?: string;
};

type FullScreenLoaderContextType = {
    fullScreenLoaderData: FullScreenLoaderData | undefined;
    setFullScreenLoaderData: (value: FullScreenLoaderData | undefined) => void;
};

interface FullScreenLoaderProps {
    children: React.ReactNode;
}

const FullScreenLoaderContext = createContext<FullScreenLoaderContextType | null>(null);

export const useFullScreenLoader = () => {
    const value = useContext(FullScreenLoaderContext);
    if (!value) {
        throw new Error('useFullScreenLoader must be used within a FullScreenLoaderProvider');
    }
    return value;
};

export const FullScreenLoaderProvider = ({ children }: FullScreenLoaderProps) => {
    const [fullScreenLoaderData, setFullScreenLoaderData] = useState<FullScreenLoaderData>();

    const value = useMemo(() => {
        return {
            fullScreenLoaderData,
            setFullScreenLoaderData,
        };
    }, [fullScreenLoaderData]);

    return <FullScreenLoaderContext.Provider value={value}>{children}</FullScreenLoaderContext.Provider>;
};
