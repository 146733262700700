import type { CimDoc, DclMetadataItem } from '@design-stack-vista/cdif-types';
import { replaceImage, ReplaceImageParameters } from '@design-stack-vista/cimdoc-state-manager';

export interface SingleColorImageDclMetadata extends DclMetadataItem {
    threshold: number;
    inverted: boolean;
}

interface SingleColorImageMetadata {
    threshold: number;
    inverted: boolean;
}

const addMetaData = (
    cimDoc: CimDoc,
    { id, imageMetaData }: { id: string; imageMetaData: SingleColorImageMetadata }
) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const dclMetadataItem = cimDoc.metadata!.dclMetadata!.find(
        (metadataItem) => metadataItem.id === id
    ) as SingleColorImageDclMetadata;
    const { threshold, inverted } = imageMetaData;
    if (dclMetadataItem) {
        dclMetadataItem.threshold = threshold;
        dclMetadataItem.inverted = inverted;
    }
};

export const replaceSingleColorImage = (
    cimDoc: CimDoc,
    { id, imageAttributes }: ReplaceImageParameters,
    imageMetaData: SingleColorImageMetadata
) => {
    replaceImage(cimDoc, {
        id,
        imageAttributes,
    });
    addMetaData(cimDoc, { id, imageMetaData });
};

export const updateSingleColorImageColor = (
    cimDoc: CimDoc,
    { panelId, id, color }: { panelId: string; id: string; color: string }
) => {
    const panelIndex = cimDoc.document.panels.findIndex((p) => p.id === panelId);
    const panel = cimDoc.document.panels[panelIndex];

    if (!panel) {
        throw new Error(`addImage: Could not find find panel with id ${panelId}`);
    }

    const image = panel.images?.find((item) => item.id === id);
    if (image && image.overlays && image.overlays.length) {
        const [overlay] = image.overlays;
        overlay.color = color;
    }
};
