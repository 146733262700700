import { action, computed, makeObservable, observable } from 'mobx';
import { Validation, ValidationInfo, ValidationTypes } from './type';

type ValidationInteractionType = {
    type: ValidationTypes;
    interacted: boolean;
};

type ValidationIdentifiedType = {
    [itemId: string]: ValidationInteractionType;
};

export class ValidationStore {
    @observable private _validations: Validation[] = [];
    @observable.struct validationIdentified: ValidationIdentifiedType = {};

    constructor() {
        makeObservable(this);
    }

    @action.bound
    add(panelId: string, itemId: string, validationType: ValidationTypes, validationInfo: ValidationInfo) {
        const newValidationObject: Validation = {
            panelId,
            itemId,
            validationType,
            ...validationInfo,
        };

        const index = this._validations.findIndex(
            (validation) =>
                validation.panelId === panelId &&
                validation.itemId === itemId &&
                validationType === validation.validationType
        );

        index > -1 && this._validations.splice(index, 1);

        this._validations.push(newValidationObject);
    }

    @action.bound
    remove(panelId: string, itemId: string, validationType: ValidationTypes) {
        this._validations = this._validations.filter(
            (validation) =>
                !(
                    validation.panelId === panelId &&
                    validation.itemId === itemId &&
                    validationType === validation.validationType
                )
        );
    }

    @computed
    get validations(): ReadonlyArray<Validation> {
        return this._validations;
    }

    @action.bound
    interactedWithValidation(itemId: string) {
        this.validationIdentified[itemId] = {
            interacted: true,
            type: ValidationTypes.BetweenBound,
        };
    }
}
