import React from 'react';
import { QuickToolAdjustContrast } from './QuickToolAdjustContrast';
import { QuickToolDelete } from './QuickToolDelete';
import { QuickToolFitToBleed } from './QuickToolFitToBleed';
import { QuickToolFitToSafe } from './QuickToolFitToSafe';
import { QuickToolRotate } from './QuickToolRotate';
import { PremiumFinishButton } from '../PremiumFinish';

export const QuickToolItems = () => {
    return (
        <>
            <PremiumFinishButton />
            <QuickToolAdjustContrast />
            <QuickToolDelete />
            <QuickToolRotate />
            <QuickToolFitToBleed />
            <QuickToolFitToSafe />
        </>
    );
};
