import React, { ReactNode } from 'react';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { MaskType } from '@design-stack-vista/ida-framework';
import { css } from '@emotion/css';
import { className as classNames, tokens, tokensRaw } from '@vp/swan';
import { DESIGN_MASK_CONFIGURATION } from '../../../../config/constant';
import { hexToRGB } from '../../../../utils/hexToRGB';
import { PanelChromesExtension, useGetExtension } from '../../Extensions';
import { PanelMasksDesignLayer } from '../PanelMaskDesignLayer';

const getHighLightedMaskStyle = (highlightedMask: MaskType | undefined, dashLength: number) => css`
    svg[class*='idaf-stroke-mask'] path {
        fill: transparent;
        transition: fill 1s;
    }

    svg[class*='idaf-fill-mask'] path {
        stroke: transparent;
        transition: stroke 1s;
    }

    svg[class*='idaf-fill-mask--BLEED'] path {
        stroke: ${DESIGN_MASK_CONFIGURATION.BLEED.backgroundColor};
        fill: ${tokens.SwanBaseColorGrey100};
    }

    .idaf-curve-mask--BLEED {
        fill: none !important;
        stroke: ${highlightedMask === 'BLEED'
            ? tokens.SwanBaseColorWhite
            : DESIGN_MASK_CONFIGURATION.BLEED.backgroundColor} !important;
        stroke-dasharray: ${highlightedMask === 'BLEED' ? `${dashLength} ${dashLength}` : 'none'};
    }

    ${highlightedMask !== 'BLEED' &&
    `&.${highlightedMask} {
        .idaf-stroke-mask--${highlightedMask} path:first-of-type {
            fill: ${hexToRGB(tokensRaw.SwanBaseColorGreen700, 0.3)};
        }
        .idaf-fill-mask--${highlightedMask} path:first-of-type {
            stroke: ${hexToRGB(tokensRaw.SwanBaseColorGreen700, 0.3)};
        }
    }`}
`;

export interface PresentationalDesignMarginsProps {
    panel: PanelState;
    mapMask?: (panel: PanelState) => ReactNode | ReactNode[];
}

export type HighlightablePanelMasksDesignLayerProps = PresentationalDesignMarginsProps & {
    highlightedMask?: MaskType;
};

export function HighlightablePanelMasksDesignLayer({
    panel,
    highlightedMask,
}: HighlightablePanelMasksDesignLayerProps) {
    const { strokeWidth } = useGetExtension(panel, PanelChromesExtension);
    const dashLengthFactor = 5;

    return (
        <div
            className={classNames(
                highlightedMask,
                getHighLightedMaskStyle(highlightedMask, dashLengthFactor * strokeWidth)
            )}
        >
            <PanelMasksDesignLayer panel={panel} />
        </div>
    );
}
