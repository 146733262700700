import React, { useMemo } from 'react';
import { Mask, SharedComponentProps } from '@design-stack-vista/ida-framework';
import { css, cx } from '@emotion/css';
import { createBorderInstructions, createMaskPath } from './svgPathUtil';

const maskPathStyle = css`
    width: 100%;
    height: 100%;
`;

export interface FillMaskProps extends SharedComponentProps {
    /**
     * Width to use for the underlying SVG's viewbox
     */
    width: number;
    /**
     * Height to use for the underlying SVG's viewbox
     */
    height: number;
    /**
     * Collection of points used to draw the SVG
     */
    mask: Mask;
    /**
     * @default '#C4CDD6'
     */
    fillColor?: string;
    /**
     * Whether to draw a border around the SVG.
     * @default true
     */
    useBorder?: boolean;
}

export function FillMask({
    width,
    height,
    mask,
    ariaLabel,
    style,
    className,
    fillColor = '#C4CDD6',
    useBorder = true,
}: FillMaskProps) {
    const { type, paths } = mask;
    const pathType = type.toLowerCase();

    const path = useMemo(() => {
        /** Fill masks require us to create a border for the mask such the the closed regions drawn by the paths we receive
         *  operate correctly under the even odd rule.
         */
        if (useBorder) {
            const borderPath = createBorderInstructions({ width, height });
            return createMaskPath([borderPath, ...paths]);
        }
        return createMaskPath([...paths]);
    }, [paths, width, height, useBorder]);

    /**
     * The way accessability works for svgs relys primarily on the title for svgs according to the w3c specification
     * https://www.w3.org/TR/SVG11/struct.html#DescriptionAndTitleElements
     * Chromium and Firefox have a bug related to how screenreaders work that require the usage of aria-labelledby
     * to appropriately provide the aria text to the screenreader.
     */

    return (
        <svg
            className={cx(`idaf-path-mask idaf-fill-mask--${type}`, maskPathStyle, className)}
            viewBox={`0 0 ${width} ${height}`}
            style={style}
            aria-labelledby={`idaf-${pathType}-fillmask`}
        >
            <title id={`idaf-${pathType}-fillmask`}>{ariaLabel}</title>
            <path
                className={css`
                    fill: ${fillColor};
                `}
                d={path}
                fillRule="evenodd"
            />
            <path className={`idaf-curve-mask--${type}`} d={createMaskPath([...paths])} />
        </svg>
    );
}
