import { getRenderingPixelValue } from '@design-stack-ct/utility-core';
import type { CimDoc } from '@design-stack-vista/cdif-types';
import type { CimDocStore, DesignState, PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { BaseExtension } from '@design-stack-vista/interactive-design-engine-core';
import type { ExperimentalOptions, TextOptions } from '@rendering/plasma';
import { action, makeObservable, observable } from 'mobx';
import { filter, Subject, takeUntil } from 'rxjs';
import {
    EXPERIMENTAL_FUSION_OPTIONS_TOKEN,
    FUSION_REFERRER_TOKEN,
    FUSION_TEXT_OPTIONS_TOKEN,
    REFERRER_FALLBACK,
} from './constant';
import { renderWithFusion, toRxObservable } from './helper';
import { RenderingInputs } from './types';

// Leaving at 0 causes a line of the canvas background to be visible around the preview on chrome
const FUSION_PANEL_PREVIEW_PADDING = 1;

/**
 * @experimental
 * Extension that uses Client Side Rendering libraries, also collectively known as "Fusion", to generate panel previews.
 *
 * @dependencies [{@link CimDocStore}, {@link LayoutStore}]
 */
export class MultiPanelPreviewExtension extends BaseExtension {
    protected declare designState: PanelState;

    @observable.ref public canvas: HTMLCanvasElement | undefined;

    /**
     * Scale multiplier for determining the pixel resolution of the canvas. Should be set higher if previews any larger than a thumbnail will be needed.
     */
    @observable private pixelSize = 1;

    static override supports(state: DesignState): boolean {
        return state.isPanelState();
    }

    static override inject = [
        'cimDocStore',
        { isOptional: true, token: EXPERIMENTAL_FUSION_OPTIONS_TOKEN },
        { isOptional: true, token: FUSION_TEXT_OPTIONS_TOKEN },
        { isOptional: true, token: FUSION_REFERRER_TOKEN },
    ];

    private unsubscribed$ = new Subject<void>();

    constructor(
        designState: DesignState,
        private cimDocStore: CimDocStore,
        private experimentalFusionOptions?: ExperimentalOptions,
        private fusionTextOptions?: Pick<TextOptions, 'rtextEnabled'>,
        private fusionReferrer?: string
    ) {
        super(designState);
        makeObservable(this);
    }

    private getrenderingInputs(canvas: HTMLCanvasElement) {
        return this.renderingInputs(canvas);
    }

    private renderingInputs(canvas: HTMLCanvasElement): RenderingInputs | undefined {
        if (!canvas) {
            return undefined;
        }

        const { version, fontRepositoryUrl } = this.cimDocStore.cimDocProperties;
        const panel = this.designState.asJson;
        const cimDoc: CimDoc = { version, fontRepositoryUrl, document: { panels: [panel] } };

        const pixelSize = getRenderingPixelValue(this.pixelSize);

        return {
            layout: {
                document: cimDoc,
                selector: {
                    type: 'panel',
                    id: this.designState.id,
                },
                pixelSize,
                experimentalOptions: this.experimentalFusionOptions,
                textOptions: {
                    rtextEnabled: this.fusionTextOptions?.rtextEnabled ?? false,
                },
                referrer: this.fusionReferrer ?? REFERRER_FALLBACK,
            },
            paint: {
                canvasContext: canvas.getContext('2d') as CanvasRenderingContext2D,
                pixelSize,
                paddingPx: FUSION_PANEL_PREVIEW_PADDING,
            },
        };
    }

    /**
     * Updates the pixelSize to be used by fusion for the panel preview.
     */
    @action.bound
    public setPixelSize(pixelSize: number) {
        this.pixelSize = pixelSize;
    }

    @action.bound
    public setCanvas(canvas: HTMLCanvasElement) {
        toRxObservable(() => this.getrenderingInputs(canvas), true)
            .pipe(
                filter((inputs): inputs is RenderingInputs => inputs !== undefined),
                renderWithFusion(),
                takeUntil(this.unsubscribed$) // Automatically cancel & unsubscribe when this item is disposed of
            )
            .subscribe();
    }

    dispose(): void {
        super.dispose();
        this.unsubscribed$.next();
    }
}
