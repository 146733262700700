import { CircleIcon } from '../../components/SvgIcons/CircleIcon';
import { OvalIcon } from '../../components/SvgIcons/OvalIcon';
import { RectangleIcon } from '../../components/SvgIcons/RectangleIcon';
import { SquareIcon } from '../../components/SvgIcons/SquareIcon';

export enum ShapeKey {
    Circle = 'Circle',
    RoundedSquare = 'Rounded Square',
    RoundedRectangle = 'Rounded Rectangle',
    Oval = 'Oval',
    Custom = 'Custom',
}

export const SHAPE_TO_ICON_MAP = {
    [ShapeKey.Circle]: CircleIcon,
    [ShapeKey.RoundedSquare]: SquareIcon,
    [ShapeKey.RoundedRectangle]: RectangleIcon,
    [ShapeKey.Oval]: OvalIcon,
    [ShapeKey.Custom]: CircleIcon,
};

export const SHAPE_NAME: { [key: string]: string } = {
    Square: 'roundedSquare',
    'Rounded Square': 'roundedSquare',
    Rectangle: 'roundedRectangle',
    'Rounded Rectangle': 'roundedRectangle',
};

export const HEIGHT_WIDTH_REGEX = new RegExp(/\d+\.*\d*/g);

export const ROLL_LABELS_MPV_ID = 'rollLabels';
export const SMALL_SCREEN_SVG_WIDTH = '74px';
export const MEDIUM_LARGE_SCREEN_SVG_WIDTH = '82px';
