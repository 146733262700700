import { noticeError } from '../../../utils';
import QueryStringHelper from '../../../utils/queryStringUtils';
import { DEFAULT_LOCALE, QUAD_HOSTNAMES } from '../constant';

/**
 * if locale in query params
 * @returns locale from query params
 *
 * OR
 *
 * For VCS, pathname for load VCS QUAD on a ProShop will be something like `.../en-US/quick-upload-and-design/...`
 * @returns locale from pathname
 * @returns `DEFAULT_LOCALE` for localhost/dev/stage VCS QUAD
 */
export const getLocale = () => {
    const qsLocale = QueryStringHelper.getValueByKey('locale');
    if (qsLocale && isLocaleValid(qsLocale)) {
        return qsLocale;
    }
    try {
        const hostname = globalThis.location.hostname;
        if (hostname === QUAD_HOSTNAMES.local || hostname === QUAD_HOSTNAMES.dev || hostname === QUAD_HOSTNAMES.stage) {
            return DEFAULT_LOCALE;
        }

        const path = globalThis.location.pathname;
        const locale = path.split('/')[1];
        return isLocaleValid(locale) ? locale : DEFAULT_LOCALE;
    } catch (error) {
        noticeError(error, {
            method: 'vcs - getLocale',
        });
        return DEFAULT_LOCALE;
    }
};

const isLocaleValid = (locale: string) => {
    return /^[a-z]{2}-[A-Z]{2}$/.test(locale);
};
