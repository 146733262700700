import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens, Typography } from '@vp/swan';
import { PostCardMailingInfoToolTip } from './PostCardMailingInfoToolTip';
import { PostCardPricing } from './PostCardPricing';
import { text } from '../../../utils/localization';

const postCardDetailsStyle = css`
    margin-right: ${tokens.SwanSemSpace2};
`;

export const PostCardDetails = (): JSX.Element => {
    return (
        <>
            <div className={postCardDetailsStyle}>
                <FlexBox alignItems="center" fontSize="small" mb="3">
                    <Typography as="h5" fontSize="small" mr="3" mb="0" data-testid="postcard-switching-block-heading">
                        {text('postcardMailingServices')}
                    </Typography>
                    <PostCardMailingInfoToolTip />
                </FlexBox>
                <PostCardPricing />
            </div>
        </>
    );
};
