import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import { CompatibleOptionsEntry } from '../../../../api/catalog';
import { ProductData } from '../../../../hooks/calcifer/useProductConfiguration';
import { NewProduct, ProductChoice, ProductChoiceGroups } from '../types';

export const getProductOptions = (productData: ProductData, productAttributesList: ProductChoice): ProductChoice => {
    const { selectedOptions, customerSelectedOptions } = productData;

    return {
        ...selectedOptions,
        ...customerSelectedOptions,
        ...productAttributesList,
    };
};

export const getProductOptionsList = (
    productData: ProductData,
    productAttributesList: ProductChoice[]
): ProductChoice[] => {
    return productAttributesList.map((productAttributes) => getProductOptions(productData, productAttributes));
};

export const getOptionsWithoutAttribute = (
    selectedOptions: Record<string, string>,
    attribute: string
): ProductChoice => {
    return omit(selectedOptions, attribute);
};

export const getCompatibleChoiceGroups = (
    compatibleOptions: CompatibleOptionsEntry[],
    productAttribute: string
): ProductChoiceGroups => {
    const compatibleAttributeValues = compatibleOptions.find((option) => option.name === productAttribute)?.values;

    return (
        compatibleAttributeValues?.reduce((acc, value) => {
            return {
                ...acc,
                [value]: {
                    [productAttribute]: value,
                },
            };
        }, {}) ?? {}
    );
};

export const getAttributeValuesFromChoiceGroups = (
    attribute: string,
    productChoiceGroups: ProductChoiceGroups
): string[] => {
    return Object.values(productChoiceGroups).map((value) => value[attribute]);
};

const checkIsProductAttributesDifferent = (productData: ProductData, newProductData: NewProduct) => {
    const baseSelectedOptions = {
        ...productData.selectedOptions,
        ...productData.customerSelectedOptions,
    };

    const { productAttributes } = newProductData;

    const baseSelectedAttributes = pick(baseSelectedOptions, Object.keys(productAttributes));

    return !isEqual(baseSelectedAttributes, productAttributes);
};

export const checkIsNewProduct = (productData: ProductData, newProductData: NewProduct) => {
    const isProductKeyDifferent = productData.productKey !== newProductData.productKey;
    const isProductVersionDifferent = productData.productVersion !== newProductData.productVersion;
    const isProductAttributesDifferent = checkIsProductAttributesDifferent(productData, newProductData);

    return isProductKeyDifferent || isProductVersionDifferent || isProductAttributesDifferent;
};

export const getChangedAttributePairs = (
    selectedOptions: Record<string, string>,
    productAttributes: Record<string, string>
) => {
    return Object.keys(productAttributes).reduce(
        (acc: Array<[optionName: string, optionValue: string]>, optionName) => {
            if (selectedOptions[optionName] !== productAttributes[optionName]) {
                acc.push([optionName, productAttributes[optionName]]);
            }
            return acc;
        },
        []
    );
};
