import React from 'react';
import { DeleteConfirmationModal } from './DeleteConfirmation';
import { DesignReviewPanel } from './DesignReview';
import { UploadModal } from './Upload';
import { AdjustContrastModal, AdjustContrastProvider } from '../features/AdjustContrast';
import { TemplateMakeEditModal } from '../features/BackDesignTemplate';
import { ChangeSizeModal } from '../features/ChangeSize';
import { DecorationAreaModal } from '../features/DecorationArea';
import DesignPreviewModal from '../features/DesignPreview/DesignPreviewModal';
import { PostCardModals } from '../features/PostCard';
import { ShapeOptionSetModal } from '../features/Shapes';
import { SubstrateColorModal } from '../features/SubstrateColor';
import { EditFromCartModal } from '../features/UpdateCart/EditFromCartModal';
import UploadBackDesignModal from '../features/UploadPanel/components/UploadBackDesignModal';

export const ModalsWrapper = (): JSX.Element => {
    return (
        <>
            <UploadModal />
            <UploadBackDesignModal />
            <PostCardModals />
            <AdjustContrastProvider>
                <AdjustContrastModal />
            </AdjustContrastProvider>
            <SubstrateColorModal />
            <ShapeOptionSetModal />
            <ChangeSizeModal />
            <DecorationAreaModal />
            <DesignReviewPanel />
            <EditFromCartModal />
            <DesignPreviewModal />
            <DeleteConfirmationModal />
            <TemplateMakeEditModal />
        </>
    );
};
