import React, { useCallback, useEffect, useState } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { observer } from 'mobx-react-lite';
import { FinishAreaSelectionSet } from './FinishAreaSelectionSet';
import { usePremiumFinishData } from './usePremiumFinishData';
import { useNotificationFramework } from '../../../components/Notification';
import { PRICING_NOTIFICATION_DISMISS_TIMEOUT } from '../../../config/constant';
import { usePremiumFinishModal, usePremiumFinishStore } from '../../../providers';
import { capitalizeFirstLetter } from '../../../utils';
import { text } from '../../../utils/localization';
import { useWaitForInstantUpload } from '../../InstantUpload';
import { addPremiumFinishReviewInstruction } from '../../quad/commands/addPremiumFinishReviewInstruction';
import { removePremiumFinishReviewInstruction } from '../../quad/commands/removePremiumFinishReviewInstruction';
import { TransitionalPreviewModal } from '../../TransitionalPreviewModal';
import { FINISH_TYPE, FinishOption, LEVEL } from '../constants';
import { FINISH_PREVIEW_SCENEType, FinishSelectionType } from '../type';
import { useUpdateFinishVariant } from '../useUpdateFinishVariant';

export const PremiumFinishModal = observer(() => {
    const { isOpen, closeModal } = usePremiumFinishModal();
    const {
        transitionalPreviewModalData,
        currentFinishSelection,
        defaultFinishOptions,
        getScenePreview,
        level,
        optionalNotes,
        updateCurrentFinishSelection,
        updateLevel,
    } = usePremiumFinishData();
    const { isPremiumCard, supportedPremiumVariants, pricing, defaultPremiumFinish } = usePremiumFinishStore();
    const {
        executeCommand,
        cimDocStore: { panels },
    } = useDesignEngine();
    const { onFinishVariantUpdate } = useUpdateFinishVariant();
    const { notifyCustomer } = useNotificationFramework();

    const [isFooterDisabled, setIsFooterDisabled] = useState(false);
    const [scenePreview, setScenePreview] = useState<FINISH_PREVIEW_SCENEType>(
        transitionalPreviewModalData?.previewScene
    );
    const [selectionValue, setSelectionValue] = useState<FinishSelectionType>();
    const [finishOption, setFinishOption] = useState<string>(defaultFinishOptions);
    const [optionalInstructionalNotes, setOptionalInstructionalNotes] = useState(optionalNotes);
    const [defaultSelection, setDefaultSelection] = useState('');
    const [showInstructionNotes, setShowInstructionNotes] = useState(false);
    const { isInstantUploadProcessing } = useWaitForInstantUpload();

    const onLevel2Selection = useCallback((data: FinishSelectionType) => {
        setShowInstructionNotes(data.selectedValue !== '');
        setOptionalInstructionalNotes(data.optionalInstruction);
        setFinishOption(data.selectedValue);
        setIsFooterDisabled(false);
    }, []);

    useEffect(() => {
        setSelectionValue({
            selectedValue: defaultPremiumFinish,
            optionalInstruction: optionalNotes,
        });
        setOptionalInstructionalNotes(optionalNotes);
    }, [optionalNotes, defaultPremiumFinish]);

    useEffect(() => {
        let premiumFinishSelectedOption = '';
        let instructionNotes = '';
        if (currentFinishSelection === defaultPremiumFinish) {
            premiumFinishSelectedOption = defaultFinishOptions;
            instructionNotes = optionalNotes;
        }

        setFinishOption(premiumFinishSelectedOption);
        setOptionalInstructionalNotes(instructionNotes);
    }, [currentFinishSelection, defaultFinishOptions, defaultPremiumFinish, optionalNotes]);

    useEffect(() => {
        setScenePreview(getScenePreview(currentFinishSelection));
        const currentDefaultSelection = level === LEVEL.level1 ? currentFinishSelection : finishOption;

        if (level === LEVEL.level2) {
            setShowInstructionNotes(finishOption !== '');
        } else {
            setShowInstructionNotes(false);
        }
        setDefaultSelection(currentDefaultSelection);
    }, [currentFinishSelection, finishOption, level, getScenePreview]);

    const removeFinish = async () => {
        const updatedCimdoc = executeCommand(removePremiumFinishReviewInstruction, {}).cimDoc;
        if (supportedPremiumVariants.length > 1) {
            await onFinishVariantUpdate(FINISH_TYPE.none, updatedCimdoc);
        }
        const price = pricing[FINISH_TYPE.none].increment;
        if (price) {
            notifyCustomer(
                {
                    messageToShowCustomer: text('finishDeleteNotification', {
                        defaultPremiumFinish: capitalizeFirstLetter(defaultPremiumFinish),
                        price,
                    }),
                    notificationType: 'positive',
                },
                true,
                PRICING_NOTIFICATION_DISMISS_TIMEOUT
            );
        }
    };

    const onSelectingNoFinish = useCallback(() => {
        removeFinish();
        closeModal();
    }, [removeFinish, closeModal]);

    const onContinueWithValidFinish = useCallback(() => {
        if (selectionValue?.selectedValue) {
            const premiumFinishSelectedOption =
                selectionValue.selectedValue === defaultPremiumFinish ? defaultFinishOptions : '';
            setIsFooterDisabled(true);
            setFinishOption(premiumFinishSelectedOption);
            updateCurrentFinishSelection(selectionValue.selectedValue);
            updateLevel(LEVEL.level2);
        }
    }, [
        defaultFinishOptions,
        defaultPremiumFinish,
        selectionValue?.selectedValue,
        updateCurrentFinishSelection,
        updateLevel,
    ]);

    const onContinue = useCallback(() => {
        if (selectionValue?.selectedValue === FINISH_TYPE.none) {
            onSelectingNoFinish();
        } else {
            onContinueWithValidFinish();
        }
    }, [selectionValue?.selectedValue, onContinueWithValidFinish, onSelectingNoFinish]);

    const priceNotification = useCallback(() => {
        const price = pricing[currentFinishSelection].increment;
        if (price) {
            notifyCustomer(
                {
                    messageToShowCustomer: text('finishAddNotification', {
                        variant: capitalizeFirstLetter(currentFinishSelection),
                        price,
                    }),
                    notificationType: 'positive',
                },
                true,
                PRICING_NOTIFICATION_DISMISS_TIMEOUT
            );
        }
    }, [currentFinishSelection, notifyCustomer, pricing]);

    const resetModalToInitialLevel = useCallback(() => {
        const currentLevel = isPremiumCard ? LEVEL.level2 : LEVEL.level1;
        updateLevel(currentLevel);
    }, [isPremiumCard]);

    const onSaveSelection = useCallback(async () => {
        const firstPanelId = panels[0].id;
        const updatedCimdoc = executeCommand(addPremiumFinishReviewInstruction, {
            finishOption: finishOption as FinishOption,
            panelId: firstPanelId,
            optionalNotes: optionalInstructionalNotes,
        }).cimDoc;
        if (defaultPremiumFinish !== currentFinishSelection) {
            await onFinishVariantUpdate(currentFinishSelection, updatedCimdoc);
            priceNotification();
        }
        setSelectionValue({ selectedValue: currentFinishSelection, optionalInstruction: optionalInstructionalNotes });
        closeModal();
        resetModalToInitialLevel();
    }, [
        closeModal,
        currentFinishSelection,
        defaultPremiumFinish,
        executeCommand,
        finishOption,
        onFinishVariantUpdate,
        optionalInstructionalNotes,
        panels,
        priceNotification,
    ]);

    const handleFooterButtonClick = useCallback(async () => {
        if (level === LEVEL.level1) {
            onContinue();
        } else {
            await onSaveSelection();
        }
    }, [level, onContinue, onSaveSelection]);

    const handleScenePreview = useCallback(
        (finishKey: string) => {
            setScenePreview(getScenePreview(finishKey));
        },
        [getScenePreview]
    );

    const handleSelection = useCallback(
        (data: FinishSelectionType) => {
            setSelectionValue(data);
            if (level === LEVEL.level1) {
                handleScenePreview(data.selectedValue);
                setIsFooterDisabled(false);
            } else {
                onLevel2Selection(data);
            }
        },
        [handleScenePreview, onLevel2Selection, level]
    );

    const resetToInitial = useCallback(() => {
        resetModalToInitialLevel();
        setSelectionValue({ selectedValue: defaultPremiumFinish, optionalInstruction: optionalNotes });
        setIsFooterDisabled(false);
        setFinishOption(defaultFinishOptions);
        updateCurrentFinishSelection(defaultPremiumFinish);
        setOptionalInstructionalNotes(optionalNotes);
    }, [
        defaultPremiumFinish,
        isPremiumCard,
        optionalNotes,
        updateCurrentFinishSelection,
        updateLevel,
        defaultFinishOptions,
    ]);

    const modalDialogCloseRequest = useCallback(() => {
        resetToInitial();
        closeModal();
    }, [closeModal, resetToInitial]);

    const onNavigationClick = useCallback(() => {
        const finishNotes = currentFinishSelection === defaultPremiumFinish ? optionalNotes : '';
        updateLevel(LEVEL.level1);
        setDefaultSelection(currentFinishSelection);
        handleScenePreview(currentFinishSelection);
        setShowInstructionNotes(false);
        setIsFooterDisabled(false);
        setSelectionValue({
            selectedValue: currentFinishSelection,
            optionalInstruction: finishNotes,
        });
        setOptionalInstructionalNotes(finishNotes);
    }, [currentFinishSelection, defaultPremiumFinish, handleScenePreview, optionalNotes, updateLevel]);

    const { footerKey, headerKey, subHeaderKey, selectionSet } = transitionalPreviewModalData;

    return (
        <TransitionalPreviewModal
            dataTestId="premium-finish-modal"
            isOpen={isOpen}
            onRequestDismiss={modalDialogCloseRequest}
            footer={{
                disabled: isFooterDisabled || isInstantUploadProcessing,
                labelKey: footerKey,
                onClick: handleFooterButtonClick,
                isLoading: isInstantUploadProcessing,
            }}
            headerKey={headerKey}
            subHeaderKey={subHeaderKey}
            navigation={{
                labelKey: 'backToFinishNavBtn',
                onClick: onNavigationClick,
                show: level === LEVEL.level2 && supportedPremiumVariants.length > 1,
            }}
            preview={scenePreview}
        >
            <FinishAreaSelectionSet
                defaultSelection={defaultSelection}
                handleSelectionChange={handleSelection}
                showInstructionNotes={showInstructionNotes}
                instruction={optionalInstructionalNotes}
                selections={selectionSet}
            />
        </TransitionalPreviewModal>
    );
});

PremiumFinishModal.displayName = 'PremiumFinishModal';
