import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DesignEngineProvider, RichTextProvider } from '@design-stack-vista/core-features';
import { LayoutContainersProvider } from '@design-stack-vista/ida-framework';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { InteractiveDesignEngine } from '@design-stack-vista/interactive-design-engine-core';
import { hydratePlaceholdersIntoCimdoc } from '@design-stack-vista/studio-document-metadata-management';
import { allSupportedFileTypeMappings, UploadConfigurationProvider } from '@design-stack-vista/upload-components';
import { useInitializeEffect, useSingleInitialize } from '@design-stack-vista/utility-react';
import { useScreenClass } from '@vp/swan';
import { LandingPageLayout } from './LandingPageLayout';
import { LandingPageSkeleton } from './LandingPageSkeleton/LandingPageSkeleton';
import { NotificationProvider } from './Notification';
import { NotificationManager } from './Notification/NotificationProvider';
import { MAX_FILE_SIZE_IN_KB, ProductConfigurationStatus } from '../config/constant';
import { defaultFontConfiguration } from '../config/defaultFontConfiguration';
import { PostCardProvider } from '../features/PostCard/PostCardProvider';
import { addOrUpdateMetadataDocumentSources } from '../features/quad/commands/addPanelMetadata';
import { DEPENDENCY_STORE, NOTIFICATION_FRAMEWORK, VALIDATION_STORE } from '../features/quad/Validations/constant';
import { DependencyInjectionStore } from '../features/quad/Validations/DependencyInjectionStore';
import { useValidation } from '../features/quad/Validations/ValidationProvider';
import { TrackingProvider } from '../features/TrackingEvents/TrackingProvider';
import { useProductConfiguration } from '../hooks/calcifer';
import { cleanUpDesignEngineExtensions, createQUADDesignEngine } from '../hooks/createQUADEngine';
import { useInitializeTracking } from '../hooks/useInitializeTracking';
import { useInitPricingContextModule } from '../hooks/useInitPricingContextModule';
import { FullScreenLoaderProvider, PremiumFinishProvider, UpsellPriceProvider, useProductParams } from '../providers';
import { MaskLegendProvider } from '../providers/MaskLegendProvider';
import { PreviewProvider } from '../providers/PreviewProvider';
import { SaveWorkProvider } from '../providers/SaveWorkProvider';
import { UploadProvider } from '../providers/UploadProvider';
import { cleanUpURLParamsRequiredInStudio } from '../utils/cleanUpURLParamsRequiredInStudio';
import { text } from '../utils/localization';
import { normalizeDesignViews } from '../utils/normalizeDesignViews';
import { ColorMode, PanelWithColorMode } from '../utils/upsell';

export const LandingPage = () => {
    useInitPricingContextModule();
    const screenClass = useScreenClass();
    const { validationStore } = useValidation();
    const { startingCanvas } = useProductParams();
    const { trackingEvent } = useInitializeTracking();
    const { auth: vpAuth, isIdentityInitialized } = useIdentityContext();
    const { productData, productConfigurationStatus } = useProductConfiguration();
    const notificationManager = useSingleInitialize(() => new NotificationManager());
    const dependencyInjectionStore = useSingleInitialize(() => new DependencyInjectionStore());

    const [designEngine, setDesignEngine] = useState<InteractiveDesignEngine | null>(null);
    const [loadingError, setLoadingError] = useState<string | undefined>();
    const timeoutHandler = useRef<ReturnType<typeof setTimeout>>();

    const authHeader: string | undefined = isIdentityInitialized ? vpAuth.getAuthorizationHeader() : undefined;

    // designEngine creation should be dependent only on the following fields from productData
    // designDocument, designViews, and productMinDpi
    const designEngineProductsDeps = useMemo(() => {
        return productData
            ? {
                  designDocument: productData.designDocument,
                  designViews: productData.designViews.designViews,
                  productMinDpi: productData.studioConfiguration.productMinDpi,
              }
            : undefined;
    }, [
        productData?.designDocument,
        productData?.designViews.designViews,
        productData?.studioConfiguration.productMinDpi,
    ]);

    useInitializeEffect(
        () => {
            function initDesignEngine() {
                if (designEngineProductsDeps && authHeader) {
                    const { designDocument, designViews, productMinDpi } = designEngineProductsDeps;

                    const panels = designDocument.document.panels as PanelWithColorMode[];
                    panels.forEach((panel: PanelWithColorMode) => {
                        if (panel.colorMode === ColorMode.Color) {
                            addOrUpdateMetadataDocumentSources(designDocument, {
                                panelId: panel.id,
                                sourceType: 'FULLBLEED',
                            });
                        }
                    });
                    hydratePlaceholdersIntoCimdoc(designDocument);

                    const newDesignEngine = createQUADDesignEngine({
                        cimdoc: designDocument,
                        authHeader,
                        screenClass,
                        deps: {
                            productMinDpi,
                            [VALIDATION_STORE]: validationStore,
                            // TODO: designViews is different than designRequirements need to modify to fit the need
                            designRequirements: normalizeDesignViews(designViews),
                            [NOTIFICATION_FRAMEWORK]: notificationManager,
                            [DEPENDENCY_STORE]: dependencyInjectionStore,
                        },
                        initialPanel: startingCanvas,
                    });

                    setDesignEngine(newDesignEngine);
                    cleanUpURLParamsRequiredInStudio({ state: {}, url: window.location.href });

                    // returning the newDesignEngine so that useInitializeEffect can get the old instance
                    return newDesignEngine;
                }
            }
            return initDesignEngine();
        },
        [designEngineProductsDeps, authHeader],
        (oldDesignEngine) => {
            if (oldDesignEngine) {
                cleanUpDesignEngineExtensions(oldDesignEngine);
                oldDesignEngine.dispose();
            }
        }
    );

    useEffect(() => {
        return () => {
            setDesignEngine(null);
        };
    }, []);

    useEffect(() => {
        if (productData) {
            timeoutHandler.current && clearTimeout(timeoutHandler.current);
        } else if (productConfigurationStatus === ProductConfigurationStatus.Error) {
            // wait for params get initialized
            timeoutHandler.current = setTimeout(() => {
                // set default error message id product data not available
                setLoadingError(text('defaultErrorMessage'));
                timeoutHandler.current && clearTimeout(timeoutHandler.current);
            }, 400);
        }
    }, [productConfigurationStatus, productData]);

    return !designEngine || !productData || !trackingEvent ? (
        <NotificationProvider notificationManager={notificationManager}>
            <LandingPageSkeleton initErrorMessage={loadingError} />
        </NotificationProvider>
    ) : (
        <FullScreenLoaderProvider>
            <TrackingProvider trackingData={trackingEvent}>
                <NotificationProvider notificationManager={notificationManager}>
                    <DesignEngineProvider designEngine={designEngine}>
                        <SaveWorkProvider>
                            <UpsellPriceProvider>
                                <LayoutContainersProvider>
                                    <RichTextProvider
                                        designEngine={designEngine}
                                        fontConfiguration={{
                                            ...defaultFontConfiguration,
                                            fontRepositoryUrl:
                                                designEngine.cimDocStore.cimDocProperties.fontRepositoryUrl,
                                        }}
                                        interactiveContext={designEngine.idaStore}
                                    >
                                        <UploadConfigurationProvider
                                            supportedFileTypes={allSupportedFileTypeMappings}
                                            maxFileSizeInKilobytes={MAX_FILE_SIZE_IN_KB}
                                        >
                                            <PostCardProvider>
                                                <PreviewProvider productData={productData}>
                                                    <PremiumFinishProvider productData={productData}>
                                                        <UploadProvider>
                                                            <MaskLegendProvider>
                                                                <LandingPageLayout
                                                                    dependencyInjectionStore={dependencyInjectionStore}
                                                                />
                                                            </MaskLegendProvider>
                                                        </UploadProvider>
                                                    </PremiumFinishProvider>
                                                </PreviewProvider>
                                            </PostCardProvider>
                                        </UploadConfigurationProvider>
                                    </RichTextProvider>
                                </LayoutContainersProvider>
                            </UpsellPriceProvider>
                        </SaveWorkProvider>
                    </DesignEngineProvider>
                </NotificationProvider>
            </TrackingProvider>
        </FullScreenLoaderProvider>
    );
};
