import React, { lazy, Suspense } from 'react';
import { DesktopExperience } from '../../components/Experiences';
import { isDebugMode } from '../../utils';

const DebugToolbar = lazy(() => import('./DebugToolbar').then((module) => ({ default: module.DebugToolbar })));

export const LazilyLoadedDebugToolbar = () => {
    const isQuadDebugEnabled = isDebugMode();

    return isQuadDebugEnabled ? (
        <DesktopExperience>
            <Suspense fallback={<></>}>
                <DebugToolbar />
            </Suspense>
        </DesktopExperience>
    ) : null;
};
