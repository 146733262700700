import React, { useRef, useState } from 'react';
import { Hex } from '@design-stack-ct/utility-core';
import { css } from '@emotion/css';
import { ColorSwatch, ColorSwatches, SelectionSet, SelectionSetInput, SelectionSetLabel, tokens } from '@vp/swan';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { ColorPaletteEntry } from '../types/colorPalette';

export type PaletteColor = Record<string, string>;

interface ColorPaletteProps {
    paletteColors: ColorPaletteEntry[];
    selectedColor: ColorPaletteEntry;
    onColorChange: (colorName: string) => void;
}

const getColorSwatchStyle = (isSmall: boolean) => css`
    ${isSmall &&
    `
        &.swan-selection-set{
            .swan-color-swatches {
                padding: 0 ${tokens.SwanSemSpace4};
                flex-wrap: nowrap;
                margin-bottom: 0;
                overflow-x: auto;
                &::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
            .swan-color-swatches.swan-color-swatches-super .swan-selection-set-label {
                margin: ${tokens.SwanSemSpace5} ${tokens.SwanSemSpace3} ${tokens.SwanSemSpace5} 0;
            }
        }
    `}
`;

export const ColorPalette = ({ paletteColors, selectedColor, onColorChange }: ColorPaletteProps) => {
    const { isSmall } = useScreenLayout();
    const [scrollValue, setScrollValue] = useState(0);
    const scrollRef = useRef<HTMLDivElement | null>(null);

    const onChangeColor = (newColor: string | null) => {
        if (scrollRef.current && isSmall) {
            // There is a bug where on change of selected color, the ColorSwatches component scrolls back to the starting position(first color)
            // Need to reset it to the last scrolled position
            scrollRef.current.scrollTo(scrollValue, 0);
        }
        onColorChange(newColor as Hex);
    };

    const updateScrollValue = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const scrollLeft = event.currentTarget.scrollLeft;
        setScrollValue(scrollLeft);
    };

    return (
        <SelectionSet
            selectedValue={selectedColor.cssBackground}
            onSelectedValueChange={onChangeColor}
            className={getColorSwatchStyle(isSmall)}
        >
            <ColorSwatches size={isSmall ? 'super' : 'standard'} ref={scrollRef} onScrollCapture={updateScrollValue}>
                {paletteColors.map((color) => (
                    <SelectionSetInput key={color.cdifValue} value={color.cssBackground}>
                        <SelectionSetLabel>
                            <ColorSwatch as="span" primaryColor={color.cssBackground} accessibleText={color.name} />
                        </SelectionSetLabel>
                    </SelectionSetInput>
                ))}
            </ColorSwatches>
        </SelectionSet>
    );
};
