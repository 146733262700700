import React from 'react';
import { observer } from 'mobx-react-lite';
import { HeaderButton } from '../../../components/Header/components/HeaderButton';
import { BucketColorIcon } from '../../../components/SvgIcons';
import { text } from '../../../utils/localization';
import { substrateColorStore } from '../SubstrateColorStore';

interface MaterialColorButtonProps {
    closeHeaderOptions: () => void;
}

export const MaterialColorButton = observer(({ closeHeaderOptions }: MaterialColorButtonProps) => {
    const { substrateColorsWithStockInfo, setIsColorSwatchModalOpen } = substrateColorStore;

    if (Object.entries(substrateColorsWithStockInfo).length === 0) {
        return null;
    }

    const openColorSwatchModal = () => {
        closeHeaderOptions();
        setIsColorSwatchModalOpen(true);
    };
    return <HeaderButton label={text('materialColor')} icon={<BucketColorIcon />} onClick={openColorSwatchModal} />;
});
