import React, { useState } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { Icon } from '@vp/swan';
import { SpecAndTemplateModalDialog } from './SpecAndTemplateModalDialog';
import { ACTION_NAME, PageSection } from '../../../../features/TrackingEvents/constant';
import { GA_EVENTS } from '../../../../features/TrackingEvents/GAEvents';
import { useProductConfiguration } from '../../../../hooks/calcifer';
import { useActivePanel } from '../../../../hooks/useActivePanel';
import { text } from '../../../../utils/localization';
import { HeaderButton } from '../HeaderButton';

export const SpecsAndTemplateButton = () => {
    const [showModalDialog, setShowModalDialog] = useState(false);
    const { fireEvent } = useCustomEventPropagation();
    const panel = useActivePanel();
    const { productData } = useProductConfiguration();

    if (!productData) {
        return null;
    }

    const handleButtonClick = () => {
        setShowModalDialog(true);
        fireEvent({
            name: ACTION_NAME.FLY_OUT_VIEWED,
            extraData: {
                label: GA_EVENTS.QUAD_STUDIO_SPECS_AND_TEMPLATE_FLY_OUT,
                category: PageSection.FlyOut,
                eventDetail: panel?.panelProperties.name,
            },
        });
    };

    return (
        <>
            <HeaderButton
                label={text('specAndTemplate')}
                icon={<Icon iconType="templates" size="24p" />}
                onClick={handleButtonClick}
            />
            <SpecAndTemplateModalDialog openDialog={showModalDialog} onClose={(status) => setShowModalDialog(status)} />
        </>
    );
};
