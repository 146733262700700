export const LogoImageUrl = 'https://cms.cloudinary.vpsvc.com/image/upload/v1683280352/VCS/global_images/Logo.png';

export const QUAD_HOSTNAMES = {
    local: 'localhost',
    dev: 'quad.dev.nextgen.vcs.cimpress.io',
    stage: 'quad.stage.nextgen.vcs.cimpress.io',
};

export const vcsStudioBaseUrl = '/studio/';

export const DEFAULT_LOCALE = 'en-US';
