import { useDesignEngine } from '@design-stack-vista/core-features';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { PanelSides } from '../../../config/constant';
import { useBackDesignFlags } from '../../../features/BackDesignTemplate/hooks';
import {
    ACTION_NAME,
    GA,
    LABELS,
    PageSection,
    PageStage,
    QUAD_TRACKING_EVENTS,
} from '../../../features/TrackingEvents/constant';
import { GA_EVENTS } from '../../../features/TrackingEvents/GAEvents';
import { getPanelByName } from '../../../features/UploadPanel/components/UploadBackDesignModal/helper';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useBackConfirmationContext } from '../../../providers';
import { WorkEntityResponse } from '../../../types/workEntityTypes';
import { hasSingleImageItem, hasSingleShapeItem } from '../../../utils';

export const useContinueTrackingEvents = () => {
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const { productData } = useProductConfiguration();
    const { fireEvent } = useCustomEventPropagation();
    const { isBackDesignEnabled } = useBackDesignFlags();
    const { isBackVisited } = useBackConfirmationContext();

    const backPanel = getPanelByName(panels, PanelSides.Back);
    const backPanelImage = backPanel?.items.find((item) => item.isImageItem())?.id;
    const isSingleImage = backPanel && hasSingleImageItem(backPanel);
    const isSingleShape = backPanel && hasSingleShapeItem(backPanel);
    const isMultipleItems = backPanel && backPanel.items.length > 1;

    const fireContinueButtonEvent = () => {
        let eventLabel = `${LABELS.CONTENT_TEXT_LINK}:`;

        if (isSingleShape && isBackDesignEnabled) {
            eventLabel += QUAD_TRACKING_EVENTS.SMART_BACKSIDE;
        } else if (isSingleImage) {
            eventLabel += QUAD_TRACKING_EVENTS.BACKSIDE;
        } else if (isMultipleItems) {
            eventLabel += QUAD_TRACKING_EVENTS.TEMPLATE;
        } else {
            eventLabel += QUAD_TRACKING_EVENTS.NO_BACKSIDE;
        }

        const eventDetail = GA_EVENTS.FINISH_PDC_REDIRECTION.replace('[pagePath]', 'quick-upload-and-design')
            .replace('[destinationPath]', 'pdc-page')
            .replace('[CTA TEXT]', 'Continue');

        const name =
            !isBackVisited && backPanel && !backPanelImage
                ? ACTION_NAME.DESIGN_TOOL_USED
                : ACTION_NAME.NAVIGATION_CLICKED;

        fireEvent({
            name,
            extraData: {
                label: eventLabel,
                pageStage: PageStage.Confirmation,
                pageSection: PageSection.QUADStudioReview,
                eventDetail,
                navigationDetail: 'Continue',
            },
        });
    };

    const fireProjectSavedEvent = (workEntity: WorkEntityResponse | undefined) => {
        fireEvent({
            name: ACTION_NAME.STUDIO_PROJECT_SAVED,
            extraData: {
                label: QUAD_TRACKING_EVENTS.STUDIO_PROJECT_SAVED,
                eventDetail: QUAD_TRACKING_EVENTS.STUDIO_PROJECT_SAVED,
                category: 'QUAD',
                pageSection: PageSection.FinishButton,
                pageStage: PageStage.Confirmation,
                pageName: `${GA.PAGE_NAME.replace('[Product ID]', productData?.mpvId || '')}`,
                product_id: productData?.mpvId,
                workId: workEntity?.workId,
                workRevisionId: workEntity?.workRevisionId,
                documentUrl: workEntity?.design.designUrl,
                product_options: JSON.stringify({
                    ...productData?.selectedOptions,
                    ...productData?.customerSelectedOptions,
                }),
                savedFrom: 'Continue Button',
                documentSource: 'FULLBLEED',
            },
        });
    };

    return { fireContinueButtonEvent, fireProjectSavedEvent };
};
