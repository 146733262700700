export const formatBoxData = (data) => {
  return {
    Left: data.x1,
    Right: data.x2,
    Top: data.y2,
    Bottom: data.y1,
    LowerLeft: {
      X: data.x1,
      Y: data.y1,
    },
    LowerRight: {
      X: data.x2,
      Y: data.y1,
    },
    UpperLeft: {
      X: data.x1,
      Y: data.y2,
    },
    UpperRight: {
      X: data.x2,
      Y: data.y2,
    },
    Area: data.x2 * data.y2,
  };
};

export const box_to_points = async (bbox) => {
  return {
    Left: bbox[0],
    Right: bbox[2],
    Top: bbox[3],
    Bottom: bbox[1],
    LowerLeft: { X: bbox[0], Y: bbox[1] },
    LowerRight: { X: bbox[2], Y: bbox[1] },
    UpperLeft: { X: bbox[0], Y: bbox[3] },
    UpperRight: { X: bbox[2], Y: bbox[3] },
    Area: Math.abs(bbox[2] - bbox[0]) * Math.abs(bbox[3] - bbox[1]),
  };
};

export const processSpotColors = (spotColorList) => {
  spotColorList = spotColorList.length == 0 ? [] : JSON.parse(spotColorList) ?? [];
  let output = getUniqueObjectsByField(spotColorList, "ColorantName");
  return output;
};

export const processInks = async (spotColorList) => {
  let cmyk = [
    {
      ColorantName: "Cyan",
      IsProcessColor: true,
    },
    {
      ColorantName: "Magenta",
      IsProcessColor: true,
    },
    {
      ColorantName: "Yellow",
      IsProcessColor: true,
    },
    {
      ColorantName: "Black",
      IsProcessColor: true,
    },
  ];
  for (let key of spotColorList) {
    cmyk.push(key);
  }
  return cmyk;
};

export const processpageBox = async (box, userUnit, rotationAngle) => {
  let result = {};
  result.Boxes = {};
  result.Boxes.ArtBox = await box_to_points(box?.ArtBox);
  result.Boxes.BleedBox = await box_to_points(box?.BleedBox);
  result.Boxes.CropBox = await box_to_points(box?.CropBox);
  result.Boxes.MediaBox = await box_to_points(box?.MediaBox);
  result.Boxes.TrimBox = await box_to_points(box?.TrimBox);
  result.SizeInfo = {};
  result.SizeInfo.UserUnit = userUnit;
  result.SizeInfo.Rotation = "Rotate" + rotationAngle;
  result.SizeInfo.WidthUserUnit = Math.abs(result.Boxes.MediaBox.Right - result.Boxes.MediaBox.Left);
  result.SizeInfo.HeightUserUnit = Math.abs(result.Boxes.MediaBox.Top - result.Boxes.MediaBox.Bottom);
  result.SizeInfo.WidthPt = result.SizeInfo.WidthUserUnit;
  result.SizeInfo.HeightPt = result.SizeInfo.HeightUserUnit;
  result.SizeInfo.WidthIn = (result.SizeInfo.UserUnit * result.SizeInfo.WidthUserUnit) / 72;
  result.SizeInfo.HeightIn = (result.SizeInfo.UserUnit * result.SizeInfo.HeightUserUnit) / 72;
  result.SizeInfo.AreaSqIn = result.SizeInfo.HeightIn * result.SizeInfo.WidthIn;

  return result;
};

export const fetchArrayBufferFromURL = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const arrayBuffer = await response.arrayBuffer();
    return arrayBuffer;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export const getArrayBufferfromFileorUrl = async (input) => {
  let buffer;
  try {
    if (input instanceof ArrayBuffer) {
      buffer = input;
    } else if (input instanceof File) {
      buffer = await input.arrayBuffer();
    } else if (isValidUrl(input)) {
      buffer = await fetchArrayBufferFromURL(input);
    } else {
      throw new Error(`Argument '${input}' can't be other than File, ArrayBuffer OR URL`);
    }
  } catch (error) {
    console.log("Error:", error);
  }
  return buffer;
};

export const isFileSvgOrNot = async (file) => {
  if (file.type.includes("svg")) return true;
  else return false;
};

function getUniqueObjectsByField(arr, field) {
  const uniqueValues = new Set();
  const uniqueObjects = [];
  for (const obj of arr) {
    if (!uniqueValues.has(obj[field])) {
      uniqueValues.add(obj[field]);
      uniqueObjects.push(obj);
    }
  }
  return uniqueObjects;
}
