import { CimDoc } from '@design-stack-vista/cdif-types';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { InteractiveDesignEngine } from '@design-stack-vista/interactive-design-engine-core';
import { setDesignTemplateToPanel } from '../commands/setDesignTemplateToPanel';

export const applyDesignTemplateToPanel = ({
    designEngine,
    designCimDoc,
    panel,
}: {
    designEngine: InteractiveDesignEngine;
    designCimDoc: CimDoc;
    panel: PanelState;
}) => {
    designEngine.executeCommand(setDesignTemplateToPanel, { panel, designCimDoc });
};
