/**
 * Reference - {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/master/src/studioFive/components/DifferentialPricingComponent.tsx}
 */
import React from 'react';
import { FlexBox, Typography } from '@vp/swan';
import { PlusOrMinus, VatMessage } from '@vp/vp-tokenized-fragment';
import { isVatInclusive } from '../utils';
import { text } from '../utils/localization';

interface Pricing {
    listPrice?: string | number;
    discountPrice: number;
}

interface DifferentialPricingComponentProps {
    pricing?: Pricing;
    hideQuantity?: boolean;
    hideVatMessage?: boolean;
    negatePricingValues?: boolean;
    locale: string;
    currency: string;
    quantity: number;
}

export function DifferentialPricingComponent({
    pricing,
    hideQuantity,
    hideVatMessage,
    negatePricingValues,
    currency,
    locale,
    quantity,
}: DifferentialPricingComponentProps) {
    if (!pricing || typeof pricing.listPrice === 'undefined') {
        return null;
    }

    if (pricing.listPrice === 'Included' || pricing.listPrice === 0) {
        return <span>{text('included')}</span>;
    }

    let { listPrice, discountPrice } = pricing;
    if (negatePricingValues) {
        listPrice = -1 * (listPrice as number);
        discountPrice *= -1;
    }

    const vatInc = isVatInclusive();

    return (
        <FlexBox data-testid="quad-differential-pricing" gap="2" flexDirection="row" fontSize="xsmall">
            <PlusOrMinus
                placeHolderText="..."
                pricingInfo={{
                    currency,
                    minimumFractionDigits: 2,
                    culture: locale,
                    priceDifference: listPrice as number,
                    priceDifferenceDiscounted: discountPrice,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    positiveDifference: true,
                }}
            />
            {!hideQuantity && quantity && <Typography>{text('callOutQuantity', { quantity })}</Typography>}
            {!hideVatMessage && (
                <VatMessage
                    placeHolderText="..."
                    pricingInfo={{
                        culture: locale,
                        vatInc,
                    }}
                />
            )}
        </FlexBox>
    );
}
