import { buildInstructionsUrl, buildTransientDocumentUri, InstructionsType } from '@design-stack-ct/documents-sdk';
import { CimDoc } from '@design-stack-vista/cdif-types';

export const getInstructionsUrl = (cimDoc: CimDoc) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { metadata, ...docWithoutMetadata } = cimDoc;
    const previewDocumentUrl = buildTransientDocumentUri(docWithoutMetadata);

    return buildInstructionsUrl(previewDocumentUrl, InstructionsType.preview);
};
