import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens } from '@vp/swan';

interface Props {
    children: React.ReactNode | React.ReactNode[];
}

const bottomBarItemStyle = css`
    font-size: ${tokens.SwanSemFontSizeSmall};
    border-left: solid 1px black;
    padding: 0 ${tokens.SwanSemSpace4};
    &:first-child {
        border-left: none;
        padding-left: 0;
    }
`;

export const BottomBarItem = ({ children }: Props) => {
    return <FlexBox className={bottomBarItemStyle}>{children}</FlexBox>;
};
