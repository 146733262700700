import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../features/TrackingEvents/constant';
import { GA_EVENTS } from '../features/TrackingEvents/GAEvents';
import { useUploadModal } from '../providers';

type AutoMappedEvent = {
    numberOfPagesAutoMapped: number;
    totalNumberOfPDFPages: number;
};

type AutoMapReversedEvent = {
    panel: PanelState;
};

export const useAutoMapTrackEvent = () => {
    const {
        cimDocStore: { panels },
        idaStore: { activeDesignPanelId },
    } = useDesignEngine();

    const { autoMapped, setAutoMapped } = useUploadModal();
    const { fireEvent } = useCustomEventPropagation();

    const triggerAutoMappedEvent = ({ numberOfPagesAutoMapped, totalNumberOfPDFPages }: AutoMappedEvent) => {
        setAutoMapped(true);
        fireEvent({
            name: ACTION_NAME.DESIGN_TOOL_USED,
            extraData: {
                experimentLabel: QUAD_TRACKING_EVENTS.AUTOMAPPING,
                eventDetail: GA_EVENTS.AUTO_MAPPING_STATUS,
                pageSection: PageSection.DesignEditToolbar,
                pageStage: PageStage.AssetUpload,
                experimentDetail: {
                    productSurfaceId: activeDesignPanelId,
                    total_pages_automapped: numberOfPagesAutoMapped,
                    total_pdf_pages: totalNumberOfPDFPages,
                    total_no_panels: panels.length,
                },
            },
        });
    };

    const triggerReverseAutoMapEvent = ({ panel }: AutoMapReversedEvent) => {
        const panelIndex = panels.indexOf(panel);
        if (autoMapped && panelIndex !== 0) {
            setAutoMapped(false);
            fireEvent({
                name: ACTION_NAME.DESIGN_TOOL_USED,
                extraData: {
                    label: QUAD_TRACKING_EVENTS.REVERSE_AUTOMAPPING,
                    eventDetail: GA_EVENTS.REVERSE_AUTO_MAPPING_STATUS,
                    pageSection: PageSection.DesignEditToolbar,
                    pageStage: PageStage.Design,
                    category: undefined,
                    experimentDetail: {
                        side: panel.panelProperties.name,
                        productSurfaceId: panel.id,
                    },
                },
            });
        }
    };

    return {
        triggerAutoMappedEvent,
        triggerReverseAutoMapEvent,
    };
};
