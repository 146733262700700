import { useCallback } from 'react';
import { useProductConfiguration } from './calcifer/useProductConfiguration';
import { getDifferentialPricing } from '../api/pricing';
import { PricingType } from '../config/constant';
import { PricingApiResponse, ProductChoiceGroups } from '../features/Flexibility/common';
import { useProductParams } from '../providers';
import { formatPrice, formatPriceDifference, isVatInclusive, noticeError } from '../utils';

export const usePricingData = () => {
    const { locale } = useProductParams();
    const { productData } = useProductConfiguration();

    const getAllCompatibleOptionsPriceData = useCallback(
        async (
            compatibleOptionChoiceGroups: ProductChoiceGroups,
            selectedOptions?: Record<string, string>,
            abortSignal?: AbortSignal
        ) => {
            try {
                if (productData) {
                    const {
                        productKey,
                        productVersion,
                        selectedOptions: productSelectedOptions,
                        quantity,
                    } = productData;

                    const pricingData = await getDifferentialPricing(
                        productKey,
                        selectedOptions ?? productSelectedOptions,
                        quantity,
                        compatibleOptionChoiceGroups,
                        productVersion,
                        abortSignal
                    );

                    return formatPriceData(pricingData);
                }
            } catch (error) {
                noticeError(error, {
                    method: 'usePricingData - getAllCompatibleOptionsPriceData',
                });
            }
        },
        [productData]
    );

    const formatPriceData = (priceResponse: PricingApiResponse) => {
        const priceToUse = isVatInclusive() ? PricingType.Taxed : PricingType.Untaxed;

        const {
            choiceGroups,
            currency,
            baseSelectionsDiscountedPrice: { [priceToUse]: includedPrice },
        } = priceResponse;

        return Object.keys(choiceGroups).reduce((acc, choice) => {
            const {
                totalListPrice: { [priceToUse]: choiceTotalListPrice },
                totalDiscountPrice: { [priceToUse]: choiceTotalDiscountPrice },
                differentialDiscountPrice: { [priceToUse]: choiceDifferentialDiscountPrice },
            } = choiceGroups[choice];

            return {
                ...acc,
                [choice]: {
                    includedPrice: formatPrice(includedPrice, locale, currency),
                    totalListPrice: formatPrice(choiceTotalListPrice, locale, currency),
                    totalDiscountPrice: formatPrice(choiceTotalDiscountPrice, locale, currency),
                    differentialDiscountPrice: formatPriceDifference(choiceDifferentialDiscountPrice, locale, currency),
                    isPriceDiscounted: choiceTotalListPrice !== choiceTotalDiscountPrice,
                    currency,
                },
            };
        }, {});
    };

    return {
        getAllCompatibleOptionsPriceData,
    };
};
