import React, { useCallback, useEffect, useState } from 'react';
import { ImageItem } from '@design-stack-vista/cdif-types';
import { ItemState, PanelState } from '@design-stack-vista/cimdoc-state-manager';
import {
    ConnectedSceneLayer,
    DEFAULT_ORNAMENT_DRAW_INSTRUCTIONS,
    DesignArea,
    ItemHandlesDesignLayer,
    ItemPreviewDesignLayer,
    ItemPreviewExtension,
    OrnamentsDesignLayer,
    SelectAndMoveDesignLayer,
    SnappingUIDesignLayer,
    useDesignEngine,
    useSelectedItems,
} from '@design-stack-vista/core-features';
import { MaskType } from '@design-stack-vista/ida-framework';
import { css } from '@emotion/css';
import { tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { MobileExperience } from './Experiences';
import { DesktopExperience } from './Experiences/DesktopExperience';
import { hideImageProcessingLoader } from '../features/AdjustContrast/utils/clientsideThresholdImageUtil';
import { PanelGuidesDesignLayer } from '../features/DesignLayers';
import { EmptyStateLayer } from '../features/EmptyState/EmptyStateLayer';
import { HighlightablePanelMasksDesignLayer } from '../features/quad/components/CustomMasks/HighlightablePanelMasksDesignLayer';
import { LegendLayer } from '../features/quad/components/CustomMasks/LegendLayer';
import { DesignNotificationLayer } from '../features/quad/components/DesignNotificationLayer';
import { MobileScrollLayer } from '../features/quad/components/MobileScrollLayer';
import { SimpleSceneLayer } from '../features/quad/components/SimpleScene';
import { TouchDeselectLayer } from '../features/quad/components/TouchDeselectLayer';
import { useProductConfiguration } from '../hooks/calcifer';
import { useActivePanel } from '../hooks/useActivePanel';
import { useSceneConfiguration } from '../hooks/useSceneConfiguration';
import { useFullScreenLoader, useLayoutZoom, useUploadModal } from '../providers';
import { hasSingleImageItem } from '../utils';

interface PanelPreviewProp {
    panel: PanelState;
}

// TODO: currently moving with hard coded hex value here, but these can be replaced if we can use a variable
const selectAndMoveStyle = css`
    .dec-item-select {
        outline: 2px solid #00b5e2;
        &:hover {
            opacity: 0;
        }
    }
`;

const snappningStyle = css`
    .dec-snapline {
        border-top: 1px solid ${tokens.SwanBaseColorGreen400};
        border-left: 1px solid ${tokens.SwanBaseColorGreen400};
    }
    .dec-snapline--mask {
        border: none;
    }
    .dec-snapbox {
        border-color: ${tokens.SwanBaseColorGreen400};
    }
`;

const itemHandleStyle = css`
    .idaf-resize-handle {
        display: flex;
        justify-content: center;
        align-items: center;
        &::after {
            content: '';
            display: block;
            padding: ${tokens.SwanSemSpace4};
            border-radius: 50%;
        }
    }
`;

export const PanelPreview = observer(({ panel }: PanelPreviewProp) => {
    const [highlightedMask, setHighlightedMask] = useState<MaskType | undefined>();
    const { canvasZoomScale } = useLayoutZoom();
    const { setSelectedIds } = useSelectedItems();
    const { setShowUploadModal } = useUploadModal();
    const {
        cimDocStore: { panels },
        designExtensionSystem,
    } = useDesignEngine();
    const { productData } = useProductConfiguration();
    const { sceneConfig } = useSceneConfiguration();
    const { setFullScreenLoaderData } = useFullScreenLoader();

    const activePanel = useActivePanel();
    const activeImage = activePanel?.items?.find((item) => item.isImageItem()) as ItemState<ImageItem> | undefined;
    const isSingleImageItem = hasSingleImageItem(panel);

    const { renderingStatus } =
        (activeImage && designExtensionSystem.getExtension(activeImage.iid, ItemPreviewExtension)) || {};

    // update of renderingStatus let us know if canvas preview is ready to view,
    // hence stop image processing loader if running
    useEffect(() => {
        const hideLoader = hideImageProcessingLoader(Boolean(activeImage), renderingStatus);
        if (hideLoader) {
            setFullScreenLoaderData({ showLoader: false });
        }
    }, [renderingStatus]);

    const handleTap = useCallback(() => {
        if (panel.items.length > 0) {
            const { id } = panel.items[0];
            setSelectedIds([id]);
        } else {
            setShowUploadModal(true);
        }
    }, [panel]);

    const panelIndex = panels.findIndex((p) => p.id === panel.id);
    const ornamentGroups = productData?.designViews.designViews[panelIndex].ornamentSet?.ornamentGroups;
    const ornamentType = ornamentGroups && ornamentGroups.length > 0 ? ornamentGroups[0].ornamentType : undefined;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const radiusInMm = DEFAULT_ORNAMENT_DRAW_INSTRUCTIONS[ornamentType];
    const itemPreviewBlendMode = productData?.studioConfiguration?.itemPreviewMixedBlendMultiply
        ? 'multiply'
        : undefined;

    const { underlayUrl, overlayUrl } = sceneConfig?.[panel.id] || {};

    return (
        <DesignArea
            panel={panel}
            key={panel.id}
            style={{ transform: `scale(${canvasZoomScale})` }}
            className="quad-design-area"
        >
            <ConnectedSceneLayer
                panelId={panel.id}
                name={'underlay'}
                backgroundColor={'F8F8F8'}
                sceneUrl={underlayUrl}
                allowUnsafePositioning={true}
                transparent={true}
            />
            <SimpleSceneLayer background={underlayUrl ? 'transparent' : undefined} />
            <EmptyStateLayer panel={panel} />
            <MobileExperience>
                <TouchDeselectLayer />
            </MobileExperience>
            <ItemPreviewDesignLayer panel={panel} blendMode={itemPreviewBlendMode} />
            <HighlightablePanelMasksDesignLayer panel={panel} highlightedMask={highlightedMask} />
            <PanelGuidesDesignLayer panel={panel} />
            <ConnectedSceneLayer
                panelId={panel.id}
                name={'overlay'}
                sceneUrl={overlayUrl}
                allowUnsafePositioning={true}
                transparent={true}
            />
            {isSingleImageItem && (
                <>
                    <SelectAndMoveDesignLayer panel={panel} className={selectAndMoveStyle} />
                    <ItemHandlesDesignLayer
                        panel={panel}
                        enableCornerRotation={false}
                        enableRotationHandle={false}
                        className={itemHandleStyle}
                    />
                </>
            )}
            <MobileExperience>
                <MobileScrollLayer handleTap={handleTap} />
            </MobileExperience>
            <DesktopExperience>
                <LegendLayer panel={panel} onLabelHover={setHighlightedMask} />
            </DesktopExperience>
            <SnappingUIDesignLayer panel={panel} className={snappningStyle} />
            <DesignNotificationLayer panel={panel} />
            {ornamentType && (
                <OrnamentsDesignLayer
                    panel={panel}
                    drawInstructions={{
                        ornamentType: radiusInMm,
                    }}
                />
            )}
        </DesignArea>
    );
});
