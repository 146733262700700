import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens } from '@vp/swan';
import { FlexibilitySwitcher } from './FlexibilityPreviewSwitcher';
import { Loader } from '../../../../components/Loader';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';
import { text } from '../../../../utils/localization';
import { useFlexibilityPreviewPanel } from '../hooks';
import { FlexibilityDocument } from '../types';

const previewContainerStyle = css`
    width: 100%;
    height: 100%;
`;

const getPreviewStyle = (isSmall: boolean) => css`
    min-height: ${isSmall ? 'calc(65vh - 80px)' : '100vh'};
    width: ${isSmall ? '100%' : 'calc(100vw - 440px)'};
    position: relative;

    @media screen and (min-width: 1030px) {
        width: 590px;
    }
`;

const getPreviewImageStyle = (isSmall: boolean) => css`
    width: 100%;
    height: ${isSmall ? '50vh' : '100%'};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: drop-shadow(0px 0px 3px ${tokens.SwanSemColorBgStrong});
`;

interface FlexibilityPreviewProps {
    flexibilityDocument: FlexibilityDocument | undefined;
    showPreviewSwitcher: boolean;
}

export const FlexibilityPreview = ({ flexibilityDocument, showPreviewSwitcher }: FlexibilityPreviewProps) => {
    const { isSmall } = useScreenLayout();
    const { previewUrls, cimDoc, loadingImage, selectedIndex, labels, onSelectedValueChange } =
        useFlexibilityPreviewPanel(flexibilityDocument);

    return (
        <FlexBox
            flexDirection="column"
            className={getPreviewStyle(isSmall)}
            alignItems="center"
            justifyContent="center"
            p={{ xs: '0', sm: '10' }}
            bgc="strong"
            data-testid="flexibility-preview"
        >
            {!previewUrls || !cimDoc || loadingImage ? (
                <Loader testId="flexibility-preview-loader" accessibilityLabel={text('previewLoader')} />
            ) : (
                <FlexBox
                    flexDirection="column"
                    alignItems="center"
                    p={{ xs: '4', sm: '0' }}
                    className={previewContainerStyle}
                >
                    <div
                        className={getPreviewImageStyle(isSmall)}
                        style={{
                            backgroundImage: previewUrls.length > 0 ? `url(${previewUrls[selectedIndex]})` : '',
                        }}
                        role="img"
                        aria-label={text('previewButton')}
                    ></div>
                    <FlexibilitySwitcher
                        labels={labels}
                        selectedIndex={selectedIndex}
                        onSelectedValueChange={onSelectedValueChange}
                        showPreviewSwitcher={showPreviewSwitcher}
                    />
                </FlexBox>
            )}
        </FlexBox>
    );
};
