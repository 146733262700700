/**
 * Studio Reference
 * {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/f554885e27bded8974ec3648a69377d421f7f5ef/apps/studio/src/shared/features/Product/SubstrateColor/useHasSubstrateColors.ts}
 */

import { useMemo } from 'react';
import { CompatibleOptionsEntry } from '../../../api/catalog';
import { ProductAttributes } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { mapDesignAttributeToProductOption } from '../../Flexibility/common';

export const useHasSubstrateColors = () => {
    const { secondaryConfig } = useProductConfiguration();
    const designAttributeMappings = secondaryConfig?.designAttributes || [];
    const compatibleOptions = secondaryConfig?.compatibleOptions;

    const hasSubstrateColors = useMemo(() => {
        // decide whether to show substrate color button based on compatible options
        // Will show when 'Substrate Color' option exists and has more than 1 choice
        const substrateColorDesignAttribute = mapDesignAttributeToProductOption(
            designAttributeMappings,
            ProductAttributes.SubstrateColor
        );
        const colorOption = compatibleOptions?.find(
            (option: CompatibleOptionsEntry) => option.name === substrateColorDesignAttribute
        );

        return colorOption?.values?.length && colorOption?.values?.length > 1;
    }, [designAttributeMappings, compatibleOptions]);

    return {
        hasSubstrateColors,
    };
};
