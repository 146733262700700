import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const BucketColorIcon = (props: SvgProps) => (
    <svg viewBox="0 0 16 16" fill="none" {...props}>
        <path
            fill="#000"
            d="M15 14.167c0 .736-.672 1.333-1.5 1.333s-1.5-.597-1.5-1.333c0-.737 1.5-2.667 1.5-2.667s1.5 1.93 1.5 2.667Z"
        />
        <path
            stroke="#000"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="m6.002 2 1.5 1.5 4.793 4.657a1 1 0 0 1 .01 1.425l-4.232 4.232a1 1 0 0 1-1.414 0L2.617 9.773a1 1 0 0 1 0-1.415l4.71-4.71"
        />
        <path fill="#000" d="M13 8.5H2L7.5 14 13 8.5Z" />
    </svg>
);
