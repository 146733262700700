/**
 * Reference - {@link https://gitlab.com/vistaprint-org/design-technology/studio/old-studio-studio-5/-/blob/master/src/easel/designer-suite/ToolbarComponents/SingleColorImages/clientsideThresholdImageUtil.ts?ref_type=heads}
 */
import { findRgbDifference, Hex, hex2rgb, RGB, rgb2hex } from '@design-stack-ct/utility-core';
import { CimDocMetadata, ImageItem } from '@design-stack-vista/cdif-types';
import { ItemState } from '@design-stack-vista/cimdoc-state-manager';
import { RenderingStatus } from '@design-stack-vista/core-features/dist/extensions/preview/types';
import potrace from 'potrace';
import { DocumentSlotInfo, SceneConfig } from '../../../types/sceneTypes';
import { ColorPaletteEntry } from '../../quad/types/colorPalette';
import { SingleColorImageDclMetadata } from '../commands';

interface DesignAreaDetails {
    x: number;
    y: number;
    height?: number;
    width?: number;
}
const MAX_IMAGE_SIZE = 1000;
const MAX_CHANNEL_VALUE = 255;
const MIDDLE_CHANNEL_VALUE = MAX_CHANNEL_VALUE / 2;
const CIMPRESS_RENDERING_URL = 'https://rendering.documents.cimpress.io';

const DEFAULT_RGB = { r: 0, g: 0, b: 0 } as RGB;
export const DEFAULT_THRESHOLD = 75;

export function hasTooManyPixels(width: number, height: number) {
    return height > MAX_IMAGE_SIZE || width > MAX_IMAGE_SIZE;
}

export function scaleImageToMaxResolution(width: number, height: number) {
    const ratio = Math.min(MAX_IMAGE_SIZE / width, MAX_IMAGE_SIZE / height);
    return { width: width * ratio, height: height * ratio };
}

// Client-side threshold logic taken from example client side image processing repo:
// https://gitlab.com/Cimpress-Technology/FileReview/sherbert/sdk/sandbox/client-side-image-processing/-/blob/feature/single_color/src/App.js

// This is the L channel of HSL transform. It consider Red and Blue Channel as well along with Green
function lightnessForPixel(r: number, g: number, b: number) {
    // Lightness in the scale of 0 to 255
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const l = (max + min) / 2;
    return l;
}

export function drawImageOnCanvas(image: HTMLImageElement, canvas: HTMLCanvasElement) {
    const context = canvas.getContext('2d');
    // eslint-disable-next-line no-param-reassign
    canvas.width = image.width;
    // eslint-disable-next-line no-param-reassign
    canvas.height = image.height;

    if (hasTooManyPixels(image.width, image.height)) {
        const { width, height } = scaleImageToMaxResolution(image.width, image.height);
        // eslint-disable-next-line no-param-reassign
        canvas.height = height;
        // eslint-disable-next-line no-param-reassign
        canvas.width = width;
        context?.drawImage(image, 0, 0, canvas.width, canvas.height);
    } else {
        context?.drawImage(image, 0, 0);
    }
    const { width, height } = canvas;
    const imgData = context?.getImageData(0, 0, width, height);

    return imgData;
}

export function createSingleColor(imgData: ImageData, color: RGB | undefined, threshold: number, inverted: boolean) {
    const pixels = imgData.data;

    if (!color) {
        // eslint-disable-next-line no-param-reassign
        color = DEFAULT_RGB;
    }
    /*
     * thresholdPercentage would be between 1 to 255 and we need to convert it to mid point to Max
     * i.e. 127 to 255. Having a shorter range would give more control for threshold.
     */
    const thresholdValue = MIDDLE_CHANNEL_VALUE * (threshold / 100 + 1);

    for (let i = 0; i < pixels.length; i += 4) {
        const lightness = lightnessForPixel(pixels[i], pixels[i + 1], pixels[i + 2]);
        const alpha = pixels[i + 3];
        // considering both color lightness and opacity for threshold
        const alphaLightness = (lightness + alpha) / 2;
        if (alpha > 0) {
            // non transparent pixels
            if (!inverted) {
                pixels[i + 3] = alphaLightness <= thresholdValue ? MAX_CHANNEL_VALUE : 0;
            } else {
                pixels[i + 3] = alphaLightness > thresholdValue ? MAX_CHANNEL_VALUE : 0;
            }
        }

        pixels[i] = color.r;
        pixels[i + 1] = color.g;
        pixels[i + 2] = color.b;
    }

    return imgData;
}

export function drawSingleColor(
    canvas: HTMLCanvasElement,
    color: RGB | undefined,
    threshold: number,
    inverted: boolean,
    image: HTMLImageElement,
    imageData?: ImageData
) {
    const context = canvas.getContext('2d');
    const imgData = imageData || drawImageOnCanvas(image, canvas);

    if (imgData) {
        const newImgData = createSingleColor(imgData, color, threshold, inverted);
        context?.putImageData(newImgData, 0, 0);
    }
}

export const getColor = (pixels?: ImageData) => {
    if (!pixels) {
        return [0, 0, 0];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const colors = {} as any;
    /* pixel data 4 bits: red,green,blue then alpha */
    // Computing the color count
    for (let i = 0; i < pixels.data.length; i += 4) {
        if (pixels.data[i + 3] !== 0) {
            const curColor = `${pixels.data[i]}|${pixels.data[i + 1]}|${pixels.data[i + 2]}`;
            colors[curColor] = colors[curColor] ? colors[curColor] + 1 : 1;
        }
    }

    // get the dominant color
    const totalK = Object.keys(colors).length;
    const paletteRgb = [];
    if (totalK < 1) {
        // no data found
        return [0, 0, 0];
    }
    if (totalK === 1) {
        // only one color in input
        paletteRgb[0] = Object.keys(colors)[0].split('|').map(Number);
    } else {
        /* sort array */
        const sortedC = [];
        for (const key in colors) {
            if (key) sortedC.push(key);
        }

        sortedC.sort((a, b) => {
            return colors[b] - colors[a];
        });

        for (let i = 0; i < Math.min(sortedC.length, 5); i++) {
            const rgb = sortedC[i].split('|').map(Number);
            paletteRgb[i] = rgb;
        }
    }

    return paletteRgb[0];
};

export function createVector(
    canvas: HTMLCanvasElement,
    thresholdPercentage: number,
    svgPathCB: (svgPath: string) => void
) {
    const ctx = canvas.getContext('2d');
    // converting percentage to pixel value
    const thresholdValue = (thresholdPercentage * 127) / 100 + 128;
    if (ctx) {
        let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        imgData = createSingleColor(imgData, DEFAULT_RGB, thresholdValue, false);
        ctx.putImageData(imgData, 0, 0);
    }

    const imgAsDataURL = canvas.toDataURL('image/png');
    const params = {
        color: rgb2hex(DEFAULT_RGB),
        // passing the max value of 255 to potrace leads to the entire image being filled in for images with transparent background
        threshold: Math.min(thresholdValue, 254),
    };

    function getVector(err: Error | null, svg: string) {
        if (err) throw err;
        svgPathCB(svg);
    }
    potrace.trace(imgAsDataURL, params, getVector);
}

export const getPreviewBackgroundStyle = (
    underlayScene: SceneConfig,
    previewImageWidth: number,
    previewImageHeight: number
) => {
    const sceneWidth = underlayScene.sceneInfo?.width;
    const sceneHeight = underlayScene.sceneInfo?.height;

    let designAreaDetails: DesignAreaDetails = { x: 0, y: 0 };
    if (underlayScene.sceneInfo?.documentSlot) {
        const { x, y, width, height } = underlayScene.sceneInfo?.documentSlot as DocumentSlotInfo;
        designAreaDetails = {
            x: x,
            y: y,
            height,
            width,
        };
    }

    const width = sceneWidth || previewImageWidth;
    const height = sceneHeight || previewImageHeight;

    let widthRatio = 1;
    let heightRatio = 1;

    if (designAreaDetails.width && designAreaDetails.height) {
        widthRatio =
            Math.max(designAreaDetails.width, previewImageWidth) / Math.min(designAreaDetails.width, previewImageWidth);
        heightRatio =
            Math.max(designAreaDetails.height, previewImageHeight) /
            Math.min(designAreaDetails.height, previewImageHeight);
    }
    const ratio = Math.max(widthRatio, heightRatio);
    const sceneUrl = underlayScene?.url;

    const underlayUrl = `${CIMPRESS_RENDERING_URL}/preview?scene=${encodeURIComponent(
        sceneUrl
    )}&width=${width}&category=studio`;

    const backgroundSize = `${width * ratio}px ${height * ratio}px`;
    const backgroundPosition = `-${designAreaDetails.x * ratio}px -${designAreaDetails.y * ratio}px`;
    const backgroundImage = `url(${underlayUrl})`;

    return { backgroundSize, backgroundPosition, backgroundImage };
};

export function searchForSimilarColor(targetColor: RGB, colors: Hex[]) {
    let bestMatch: { color: Hex; colorDifference: number } | undefined;

    colors.forEach((hex) => {
        const inputRGB = hex2rgb(hex);
        const colorDifference = findRgbDifference(targetColor, inputRGB);

        if (!bestMatch || bestMatch.colorDifference > colorDifference) {
            bestMatch = { colorDifference, color: hex };
        }
    });

    return bestMatch?.color;
}

export const searchForSimilarPaletteColor = (
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    availablePaletteColors: ColorPaletteEntry[]
) => {
    let similarPaletteColor = null;
    const imgData = drawImageOnCanvas(image, canvas);
    const imgColor = getColor(imgData);
    const imgRgb = { r: imgColor[0], g: imgColor[1], b: imgColor[2] };
    const availableHexColors = availablePaletteColors.map((color) => color.cssBackground);
    const similarHex = searchForSimilarColor(imgRgb, availableHexColors);
    if (similarHex) {
        similarPaletteColor = availablePaletteColors.find((color) => color.cssBackground === similarHex);
    }
    return { imageData: imgData, similarPaletteColor };
};

export const needsVectorization = (
    metadata: CimDocMetadata | undefined,
    imageItem: ItemState<ImageItem> | undefined,
    threshold: number,
    isInverted: boolean
) => {
    const { dclMetadata } = metadata || {};
    if (imageItem && dclMetadata) {
        const imageMetadata = dclMetadata.find((data) => data.id === imageItem.id);
        const { threshold: originalThreshold, inverted } = imageMetadata as SingleColorImageDclMetadata;
        return originalThreshold !== threshold || inverted !== isInverted;
    }
    return false;
};

// hide image processing loader when image preview is ready
export const hideImageProcessingLoader = (isActiveImage: boolean, renderingStatus: RenderingStatus | undefined) => {
    return isActiveImage && renderingStatus === 'COMPLETE';
};
