import { AssetStatusType, VistaAsset } from '@design-stack-vista/vista-assets-sdk';

const checkIfAssetNotPrepared = (asset: VistaAsset): boolean => {
    return asset.status.type !== AssetStatusType.Prepared;
};

const checkIfAssetImageInfoAbsent = (asset: VistaAsset): boolean => {
    return !asset.data?.info?.image;
};

const checkIfAssetHasError = (asset: VistaAsset): boolean => {
    return !!(asset.data?.info.image && 'isError' in asset.data.info.image && asset.data.info.image.isError);
};

const checkIfAssetNotPresigned = (asset: VistaAsset): boolean => {
    return !asset.isPresigned;
};

const checkIfAssetHasNoFormat = (asset: VistaAsset): boolean => {
    return !asset.data?.info.image?.format;
};

export const checkIsAssetProcessed = (asset: VistaAsset): boolean => {
    return !(
        checkIfAssetNotPrepared(asset) ||
        checkIfAssetImageInfoAbsent(asset) ||
        checkIfAssetHasNoFormat(asset) ||
        checkIfAssetNotPresigned(asset) ||
        checkIfAssetHasError(asset)
    );
};

type ImageMetadata = Awaited<ReturnType<VistaAsset['getPageImageInfo']>>;

export function checkIsImageWithDimensionMetadata(
    metadata: ImageMetadata
): metadata is Extract<ImageMetadata, 'width' | 'height'> {
    return 'width' in metadata && 'height' in metadata;
}

type VistaAssetWithMultiPageInfo = VistaAsset & { data: { info: { image: { numPages: number } } } };

export function checkIsAssetMultiPage(asset: VistaAsset): asset is VistaAssetWithMultiPageInfo {
    if (asset.data && asset.data.info.image) {
        return 'numPages' in asset.data.info.image;
    }
    return false;
}

type VistaAssetWithImageDimensionInfo = VistaAsset & { data: { info: { image: { width: number; height: number } } } };

export function checkIsAssetWithImageDimensionInfo(asset: VistaAsset): asset is VistaAssetWithImageDimensionInfo {
    return !!asset.data?.info.image && 'width' in asset.data.info.image && 'height' in asset.data.info.image;
}
