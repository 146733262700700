import { ImageItem } from '@design-stack-vista/cdif-types';
import { ItemState } from '@design-stack-vista/cimdoc-state-manager';
import { ItemPreviewExtension, useDesignEngine } from '@design-stack-vista/core-features';
import { useActivePanel } from './useActivePanel';

export const useRenderingState = () => {
    const activePanel = useActivePanel();
    const activeImage = activePanel?.items?.find((item) => item.isImageItem()) as ItemState<ImageItem> | undefined;

    const { designExtensionSystem } = useDesignEngine();
    const { renderingStatus } =
        (activeImage && designExtensionSystem.getExtension(activeImage.iid, ItemPreviewExtension)) || {};
    return {
        renderingStatus,
    };
};
