import { isSherbertAssetUrl } from '@design-stack-ct/assets-sdk';
import { cm2mm, getImageAsPromise, Measurement } from '@design-stack-ct/utility-core';
import { Dimensions } from '@design-stack-ct/validations';
import { ImageItem } from '@design-stack-vista/cdif-types';
import { DesignState, ItemState, replaceImage } from '@design-stack-vista/cimdoc-state-manager';
import { Coordinates } from '@design-stack-vista/ida-framework';
import { BaseDesignRequirements, ExecuteCommand } from '@design-stack-vista/interactive-design-engine-core';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction, runInAction } from 'mobx';
import { BaseValidationExtension } from './BaseValidationExtension';
import { DEPENDENCY_STORE, NOTIFICATION_FRAMEWORK, VALIDATION_STORE } from './constant';
import { DependencyInjectionStore } from './DependencyInjectionStore';
import { ValidationMessage, ValidationTypes } from './type';
import { ValidationStore } from './ValidationStore';
import { NotificationManager } from '../../../components/Notification/NotificationProvider';
import { Mask, ProcessType, SingleColorTypes } from '../../../config/constant';
import { text } from '../../../utils/localization';
import { MaskWithBoundingArea } from '../../../utils/normalizeDesignViews';
import { PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { changeAppliedProcess } from '../commands/changeAppliedProcess';
import { isLowResolutionImage } from '../validators/isLowResolutionImage';

type eventDetails = {
    label: string;
    eventDetail: string;
    navigationDetail: string;
    pageSection: PageSection;
};

const SHARPEN_PROCESS_NAME = 'sharpen';

export class LowResolutionExtension extends BaseValidationExtension {
    static supports(state: DesignState): boolean {
        return state.isImageItem();
    }

    /**
     * Reference for supported file types for Crispify
     * Reference - {@link https://gitlab.com/Cimpress-Technology/artwork-design/blue-team/cimpress-designer/-/blob/master/app/services/ipa/ipaAdapter.js#L108}
     */
    private SUPPORTED_FILE_TYPES = ['jpg', 'jpeg', 'gif', 'png'];
    private disposeReaction: IReactionDisposer;
    @observable private _loaderStatus = false;

    static override inject = [
        'productMinDpi',
        DEPENDENCY_STORE,
        'executeCommand',
        VALIDATION_STORE,
        NOTIFICATION_FRAMEWORK,
        'designRequirements',
    ];

    constructor(
        designState: DesignState,
        private productMinDpi: number,
        private dependencyStore: DependencyInjectionStore,
        private executeCommand: ExecuteCommand,
        private validationStore: ValidationStore,
        private notificationFramework: NotificationManager,
        private designRequirements: BaseDesignRequirements
    ) {
        super(designState);
        makeObservable(this);

        /**
         * Whenever the image item's source `previewUrl` (NOT the item preview) changes, rerun the calculation to fetch low-resolution validation.
         * This will typically happen via replacement, and thus should only rerun rarely per-item.
         */
        this.disposeReaction = reaction(
            () => this.imageChange,
            () => {
                this.validate();
            }
        );

        this.validate();
    }

    /**
     * Validates is the image is of low Resolution with the productMinDpi
     */
    @action.bound
    async validate() {
        if (!this.isSharpeningSupported || !(this.designState as ItemState<ImageItem>).model.printUrl) {
            return;
        }
        const fileDimension = await this.getImageDimension(
            (this.designState as ItemState<ImageItem>).model.printUrl || ''
        );
        const imageModel = this.designState.isImageItem() && this.designState.model;

        if (imageModel && imageModel.originalSourceUrl && fileDimension) {
            const isLowRes = await isLowResolutionImage(
                {
                    height: imageModel.position.height,
                    width: imageModel.position.width,
                },
                { height: fileDimension.height, width: fileDimension.width },
                this.productMinDpi
            );
            // temp solution: old uploaded assets uploaded in v1 not supported sharpening
            if (isLowRes) {
                this.addValidation(imageModel);
                if (isSherbertAssetUrl(imageModel.originalSourceUrl) && !this.isImageSharpened()) {
                    this.triggerFireEvent({
                        label: QUAD_TRACKING_EVENTS.LOW_RESOLUTION_IMAGE_WARNING,
                        eventDetail: 'QUAD;QUAD;Image Validation;Low resolution image warning',
                        navigationDetail: 'Upload Image:Image Validation:Low resolution image warning',
                        pageSection: PageSection.LowResolutionImageWarning,
                    });
                    await this.sharpen();
                }
            } else {
                this.removeValidation();
            }
            return isLowRes;
        }
        return false;
    }

    @action.bound
    async sharpen() {
        if (this.designState.isImageItem() && this.getAsset) {
            runInAction(() => {
                this._loaderStatus = true;
            });
            const imageModel = this.designState.model;
            if (imageModel.originalSourceUrl && this.getAsset) {
                const sharpenedUrl = this.getAsset.sharpened.getUrl();

                /**
                 * Rendering only supports images of 1000px and anything over that is not supported.
                 * To prevent adding images over the threshold, we after it gets sharpened we are adding this to prevent it.
                 */
                let previewUrl = sharpenedUrl;
                const fileDimension = await this.getImageDimension(imageModel.originalSourceUrl);
                if (
                    fileDimension &&
                    (fileDimension.height >= 1000 || fileDimension.width >= 1000) &&
                    this.designState.model.previewUrl
                ) {
                    previewUrl = this.designState.model.previewUrl;
                }

                if (sharpenedUrl && sharpenedUrl !== imageModel.printUrl) {
                    this.triggerFireEvent({
                        label: QUAD_TRACKING_EVENTS.SHARPENED_IMAGE,
                        eventDetail: 'QUAD;QUAD;Image Validation;Sharpened image',
                        navigationDetail: 'Upload Image:Image Validation:sharpened image',
                        pageSection: PageSection.LowResolutionImageWarning,
                    });
                    this.executeCommand(replaceImage, {
                        id: imageModel.id,
                        imageAttributes: {
                            previewUrl: previewUrl,
                            printUrl: sharpenedUrl,
                            originalSourceUrl: imageModel.originalSourceUrl,
                            pageNumber: imageModel.pageNumber,
                        },
                    });
                    this.executeCommand(changeAppliedProcess, {
                        appliedProcesses: [SHARPEN_PROCESS_NAME],
                        id: this.designState.id,
                    });

                    await getImageAsPromise(sharpenedUrl); // waiting for the image to download before showing the message.

                    this.notificationFramework.notifyCustomer(
                        {
                            notificationType: 'standard',
                            messageToShowCustomer: text('autoSharpened'),
                        },
                        true
                    );
                }
            }
            runInAction(() => {
                this._loaderStatus = false;
            });
        }
    }

    @computed
    get loaderStatus(): boolean {
        return this._loaderStatus;
    }

    @computed
    get isSharpeningSupported(): boolean {
        const decorationTechnology =
            this.designRequirements.panels.length &&
            (this.designRequirements.panels[0].decorationTechnology as ProcessType);
        const isSingleColorDecorationTech = decorationTechnology && SingleColorTypes.includes(decorationTechnology);

        const checkIsSharpeningSupported =
            this.designState.isImageItem() && this.getAsset && !isSingleColorDecorationTech;

        if (checkIsSharpeningSupported) {
            const fileFormat = this.getAsset?.data?.info.image?.format;
            return !!fileFormat && this.SUPPORTED_FILE_TYPES.includes(fileFormat.toLocaleLowerCase());
        }
        return false;
    }

    private getImageDimension(url: string): Promise<Dimensions | undefined> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () =>
                resolve({
                    width: img.width,
                    height: img.height,
                });
            img.onerror = (error) => reject(error);
            img.src = url;
        });
    }

    private addValidation(imageModel: ImageItem) {
        this.removeValidation();
        this.validationStore.add(
            (this.designState as ItemState).parent.id,
            imageModel.id,
            ValidationTypes.LowResolution,
            {
                iconPlacement: this.safeBoundingTopRight,
                level: 'WARNING',
                message: ValidationMessage.LowResolution,
            }
        );
    }

    private removeValidation() {
        if (this.designState.isImageItem()) {
            this.validationStore.remove(
                this.designState.parent.id,
                this.designState.model.id,
                ValidationTypes.LowResolution
            );
        }
    }

    @computed
    get imageChange() {
        if (this.designState.isImageItem()) {
            return {
                height: this.designState.model.position.height,
                width: this.designState.model.position.width,
                printUrl: this.designState.model.printUrl,
                asset: this.getAsset,
            };
        }
    }

    @computed
    get safeBoundingTopRight(): Coordinates {
        let topRight = {
            x: 0,
            y: 0,
        };
        const designRequirementPanel = this.designRequirements.panels.filter(
            (designRequirement) => designRequirement.id === (this.designState as ItemState).parent.id
        );
        if (designRequirementPanel && designRequirementPanel.length && designRequirementPanel[0].masks) {
            const safeMasks = designRequirementPanel[0].masks.filter((mask) => mask.type === Mask.Safe);

            if (safeMasks.length > 1) {
                const safeMask: MaskWithBoundingArea = safeMasks[0];

                const boundingAreaPosition = safeMask.boundingArea?.position;

                if (boundingAreaPosition) {
                    const { x, width, y } = boundingAreaPosition;
                    if (x && width && y) {
                        topRight = {
                            x: new Measurement(cm2mm(x + width), 'mm').value,
                            y: new Measurement(cm2mm(y), 'mm').value,
                        };
                    }
                }
            }
        }
        return topRight;
    }

    @computed
    private get getAsset() {
        if (this.dependencyStore && this.dependencyStore._assetStore) {
            return this.dependencyStore._assetStore.assets?.find((asset) => {
                return (
                    asset?.data?.id &&
                    asset?.getUrl() === (this.designState as ItemState<ImageItem>).model.originalSourceUrl
                );
            });
        }
    }

    private triggerFireEvent({ eventDetail, label, navigationDetail, pageSection }: eventDetails) {
        if (this.dependencyStore._fireEvent) {
            this.dependencyStore._fireEvent({
                label,
                eventDetail,
                navigationDetail,
                pageSection,
                pageStage: PageStage.Design,
                category: undefined,
                experimentDetail: {
                    panelId: (this.designState as ItemState).parent.id,
                    panelName: (this.designState as ItemState).parent.asJson.name,
                },
            });
        }
    }

    private isImageSharpened() {
        if (this.getAsset && this.designState.isImageItem()) {
            const { printUrl } = this.designState.model;
            const sharpenedUrl = this.getAsset.sharpened.getUrl();
            return printUrl === sharpenedUrl;
        }
        return false;
    }

    dispose() {
        this.removeValidation();
        this.disposeReaction();
        super.dispose();
    }
}
