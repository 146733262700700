import React from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { Callout, FlexBox, ModalDialogHeader, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DifferentialPricingComponent } from '../../../../../../components/DifferentialPricingComponent';
import { PanelSides } from '../../../../../../config/constant';
import { useProductConfiguration } from '../../../../../../hooks/calcifer';
import { usePanelTitle } from '../../../../../../hooks/usePanelTitle';
import { useProductParams, useUpsellPrice } from '../../../../../../providers';
import { text } from '../../../../../../utils/localization';
import { getPanelByName, getUpSellPriceInfo } from '../../helper';

const UploadBackDesignModalHeader = observer(() => {
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const { locale } = useProductParams();
    const { getPricingByPanelName } = useUpsellPrice();
    const { productData } = useProductConfiguration();
    const { getPanelTitle } = usePanelTitle();
    const backPanel = getPanelByName(panels, PanelSides.Back);
    const panelTitle = getPanelTitle(backPanel?.panelProperties.name)?.toLocaleLowerCase();
    const { upsellPricing, currency, quantity } = getUpSellPriceInfo(productData, backPanel, getPricingByPanelName);
    const hidePricing = !upsellPricing || typeof upsellPricing.listPrice === 'undefined';
    return (
        <ModalDialogHeader mt="6" mb="5">
            <FlexBox gap="3" alignItems="center" flexWrap="wrap">
                <Typography data-testid="add-back-modal-header">{text('addPanelDesign', { panelTitle })}</Typography>
                {!hidePricing && (
                    <Callout skin="success" className="upsell-side-price">
                        <DifferentialPricingComponent
                            pricing={upsellPricing}
                            locale={locale}
                            currency={currency}
                            quantity={quantity}
                        />
                    </Callout>
                )}
            </FlexBox>
        </ModalDialogHeader>
    );
});

export default UploadBackDesignModalHeader;
