import { useCallback } from 'react';
import { useNotificationFramework } from '../../../components/Notification';
import { PRICING_NOTIFICATION_DISMISS_TIMEOUT, ProductAttributes } from '../../../config/constant';
import { useProductParams } from '../../../providers';
import { checkForPriceDifference } from '../../../utils';
import { text } from '../../../utils/localization';

export const useNotifyShapeChange = () => {
    const { selectedQuantity: quantity } = useProductParams();
    const { notifyCustomer, removeAllNotifications } = useNotificationFramework();

    const notifyShapeChange = useCallback(
        (productName: string, differentialDiscountPrice: string, price: string, newOptions: Record<string, string>) => {
            removeAllNotifications();

            const optionWithPriceDifference = checkForPriceDifference(differentialDiscountPrice);

            notifyCustomer(
                {
                    notificationType: 'positive',
                    messageToShowCustomer: text(
                        optionWithPriceDifference ? 'updateProductWithPrice' : 'updateProductWithoutPrice',
                        {
                            productName: productName,
                            price,
                            attributeValue: newOptions[ProductAttributes.Size],
                            quantity,
                        }
                    ),
                },
                true,
                PRICING_NOTIFICATION_DISMISS_TIMEOUT
            );
        },
        [quantity, removeAllNotifications, notifyCustomer]
    );

    return {
        notifyShapeChange,
    };
};
