import React from 'react';
import { css } from '@emotion/css';
import { Carousel, CarouselSlide, FlexBox, tokens } from '@vp/swan';
import { TeamsPromoteModalStepsDescItem } from './TeamsPromoteModalStepsDescItem';
import { text } from '../../../../utils/localization';
import { DesktopExperience, MobileExperience } from '../../../Experiences';

const TeamsPromoteModalStepsDescStyle = css`
    margin-top: ${tokens.SwanSemSpace6};
    text-align: center;
    gap: ${tokens.SwanSemSpace8};
`;

const teamsPromoteModalCarouselStyle = css`
    max-width: 80vw;
`;

export const TeamsPromoteModalStepsDesc = (): JSX.Element => {
    return (
        <>
            <DesktopExperience>
                <FlexBox className={TeamsPromoteModalStepsDescStyle}>
                    <TeamsPromoteModalStepsDescItem step={1} desc={text('teamsPromoteStepsDescPositionPlaceholder')} />
                    <TeamsPromoteModalStepsDescItem step={2} desc={text('teamsPromoteStepsDescTeamDetails')} />
                    <TeamsPromoteModalStepsDescItem step={3} desc={text('teamsPromoteStepsDescReview')} />
                </FlexBox>
            </DesktopExperience>
            <MobileExperience>
                <Carousel
                    arrows={false}
                    accessibleTextForPrevious={text('goToPreviousSlide')}
                    accessibleTextForNext={text('goToNextSlide')}
                    accessibleTextForDots={[
                        text('teamsPromoteStepsDescPositionPlaceholder'),
                        text('teamsPromoteStepsDescTeamDetails'),
                        text('teamsPromoteStepsDescReview'),
                    ]}
                    progressIndicator="dots"
                    slidesToShow={1}
                    className={teamsPromoteModalCarouselStyle}
                    infinite={false}
                >
                    <CarouselSlide pb="5">
                        <TeamsPromoteModalStepsDescItem
                            step={1}
                            desc={text('teamsPromoteStepsDescPositionPlaceholder')}
                        />
                    </CarouselSlide>
                    <CarouselSlide pb="5">
                        <TeamsPromoteModalStepsDescItem step={2} desc={text('teamsPromoteStepsDescTeamDetails')} />
                    </CarouselSlide>
                    <CarouselSlide pb="5">
                        <TeamsPromoteModalStepsDescItem step={3} desc={text('teamsPromoteStepsDescReview')} />
                    </CarouselSlide>
                </Carousel>
            </MobileExperience>
        </>
    );
};
