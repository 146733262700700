export enum REDIRECT_MESSAGES {
    REDIRECT = 'redirect',
    QUERY = 'query',
}

const redirectMessages: Record<string, Record<string, string>> = {
    Front: {
        [REDIRECT_MESSAGES.QUERY]: 'frontEmptyQuery',
        [REDIRECT_MESSAGES.REDIRECT]: 'frontEmptyRedirect',
    },
    Back: {
        [REDIRECT_MESSAGES.QUERY]: 'panelQueryText',
        [REDIRECT_MESSAGES.REDIRECT]: 'panelRedirectText',
    },
    uploaded: {
        [REDIRECT_MESSAGES.QUERY]: 'uploadedQuery',
        [REDIRECT_MESSAGES.REDIRECT]: 'uploadedRedirect',
    },
    default: {
        [REDIRECT_MESSAGES.QUERY]: 'frontEmptyQuery',
        [REDIRECT_MESSAGES.REDIRECT]: 'frontEmptyRedirect',
    },
};

export const getMessage = (isEmptyDesign: boolean, value: REDIRECT_MESSAGES, sideVisible?: string) => {
    if (!isEmptyDesign) {
        return redirectMessages.uploaded[value];
    }

    if (sideVisible && redirectMessages[sideVisible]) {
        return redirectMessages[sideVisible][value];
    }

    return redirectMessages.default[value];
};
