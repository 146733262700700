/**
 * Reference
 * {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/master/apps/studio/src/studioSix/features/UploadsAndAssets/ImageInstantUploadExtension.ts}
 */

import {
    type DesignState,
    getCimDocNodeById,
    isImageItem,
    removeItems,
} from '@design-stack-vista/cimdoc-state-manager';
import {
    BaseExtension,
    HISTORY_STORE_TOKEN,
    HistoryEvent,
    HistoryStore,
} from '@design-stack-vista/interactive-design-engine-core';
import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { applyPatches } from 'immer';
import { action, makeObservable, observable, when } from 'mobx';
import { NotificationManager } from '../../components/Notification/NotificationProvider';
import {
    checkIsAssetProcessed,
    generateImageUrls,
    logInstantUploadError,
    noticeError,
    SESSION_STORAGE_KEY,
    setSessionStorageKey,
} from '../../utils';
import { text } from '../../utils/localization';
import { NOTIFICATION_FRAMEWORK } from '../quad/Validations/constant';

/**
 * Data needed for resolving an item after the related upload has finished (or failed)
 */
export interface InstantUploadData {
    /** The id of the {@link HistoryEvent} returned from whichever executed command created the item holding a temporary URL */
    eventId: HistoryEvent['id'];
    /** The URL being used as a placeholder while the upload is being processed by sherbert. */
    temporaryUrl: string;
    /** Optional item id to signal that the upload was started as part of an image replacement */
    replacedImageId?: string;
}

// Most of the uploads do not take more than 40000ms
// Less than 1% of the users upload a design that takes more than 40000ms to upload to sherbert
const SHERBERT_UPLOAD_WAIT = 40000;

/**
 * This extension exists to enable the automatic handling of an "instant upload":
 * an imageItem that has had a temporary URL assigned to it such that it can be displayed immediately while the asset is still uploading.
 */
export class ImageInstantUploadExtension extends BaseExtension {
    /**
     * This property exposes whether there are any ongoing uploads that might still affect this item in the future
     */
    // Note: this is intentionally tracked separately from `ItemProcessingExtension.isProcessing` to avoid displaying spinners on an item
    @observable isUploading = false;
    @observable isSlowAssetUpload = false;

    // Used to prevent race conditions when an instant upload image is replaced before a previous upload completes
    private controller: AbortController | undefined;

    static supports(state: DesignState): boolean {
        return state.isItemState() && state.isImageItem();
    }

    static override inject = [HISTORY_STORE_TOKEN, NOTIFICATION_FRAMEWORK];

    constructor(
        designState: DesignState,
        private historyStore: HistoryStore,
        private notificationFramework: NotificationManager
    ) {
        super(designState);
        makeObservable(this);
    }

    /**
     * Starts tracking the status of a new upload. Once the upload promise resolves, related items and any history steps
     * referencing them will be automatically updated with the new asset's information, or deleted if the asset failed to upload
     *
     * @param assetPromise A promise for the asset that should be used to update any items that have the `temporaryURL` defined in the second argument
     * @param data Data related to the upload that is needed for resolving history, see: {@link InstantUploadData}
     */
    @action.bound
    setUpload(assetPromise: Promise<VistaAsset | void>, data: InstantUploadData) {
        if (this.controller) {
            this.controller.abort();
        }
        this.controller = new AbortController();
        const { signal } = this.controller as AbortController;

        this.isUploading = true;

        const slowAssetUploadTimeout = setTimeout(() => this.slowAssetUpload(), SHERBERT_UPLOAD_WAIT);

        assetPromise
            .then((asset) => {
                clearTimeout(slowAssetUploadTimeout);

                if (asset) {
                    when(
                        () => checkIsAssetProcessed(asset),
                        async () => {
                            if (!signal.aborted) {
                                try {
                                    await this.swap(asset, data);
                                } catch (error) {
                                    this.rollback(data);
                                    logInstantUploadError(error, asset);
                                }
                            }
                        },
                        {
                            timeout: 3000,
                            onError: (error) => {
                                this.rollback(data);
                                logInstantUploadError(error, asset);
                            },
                        }
                    );
                } else if (!signal.aborted) {
                    this.rollback(data);
                }
            })
            .catch(() => {
                if (!signal.aborted) {
                    this.rollback(data);
                }
            });
    }

    private async swap(asset: VistaAsset, { temporaryUrl, eventId }: InstantUploadData) {
        // Get print & preview urls for fetched asset so we can swap them into relevant items in the document history
        const { print: printUrl, preview: previewUrl, original: originalSourceUrl } = await generateImageUrls(asset);

        // We need to rewrite history to use the new asset URL from the step that the item was first created, otherwise
        // undos & redos could potentially restore the temporaryUrl instead of the finished asset's URL.

        // `historyStore.rewriteFrom` enables this by accepting a callback used to iterate through history, which is passed a
        // CimDoc representing a snapshot from that point in history, which we can freely modify to update the temporary URL
        // See https://interactive-design-engine-core.ddt.cimpress.io/HistoryStore/ for more information

        // They are written inline here, but eventually we would like to export commands from cimdoc-state-manager purpose-built for helping with these cleanup cases
        // TODO: (DIYCP-24) Replace this callback with a relevant command once one is available
        this.historyStore.rewriteFrom(eventId, (cimDoc, { itemIds }) => {
            itemIds.forEach((id) => {
                const node = getCimDocNodeById(cimDoc, id);
                const item = node?.element;
                // we change the temporaryURL on *all* items so that any duplicates of the initial item will also get updated
                if (item && isImageItem(item) && item.previewUrl === temporaryUrl) {
                    Object.assign(item, { originalSourceUrl, previewUrl, printUrl });
                }
            });
        });

        this.isUploading = false;
        this.isSlowAssetUpload = false;
    }

    private rollback({ temporaryUrl, eventId, replacedImageId }: InstantUploadData) {
        // If the upload fails we need to rewrite history to remove/revert any item that contains the potentially unsafe temporaryUrl
        // TODO: (DIYCP-24) Replace this callback with a relevant command once one is available
        this.historyStore.rewriteFrom(eventId, (cimDoc, { itemIds, event }) => {
            // If this was an image replacement event, we want to simply roll the image back to its initial state before the replacement
            if (replacedImageId && eventId === event.id) {
                // eslint-disable-next-line no-param-reassign
                cimDoc = applyPatches(cimDoc, event.reverse);
                return;
            }

            const idsToRemove = itemIds.filter((id) => {
                const item = getCimDocNodeById(cimDoc, id)?.element;
                return item && id !== replacedImageId && isImageItem(item) && item.previewUrl === temporaryUrl;
            });

            if (idsToRemove.length) {
                removeItems(cimDoc, { ids: idsToRemove });
            }
        });

        this.notificationFramework.notifyCustomer({
            notificationType: 'error',
            messageToShowCustomer: text('instantUploadFailed'),
        });

        this.isUploading = false;
    }

    private slowAssetUpload() {
        this.isSlowAssetUpload = true;

        noticeError('Slow asset upload to sherbert', {
            method: 'slowAssetUpload - ImageInstantUploadExtension',
        });

        // Disable instant upload for this session
        setSessionStorageKey(SESSION_STORAGE_KEY.DISABLE_INSTANT_UPLOAD, true);

        // Notify user to refresh page
        this.notificationFramework.removeAllNotifications();
        this.notificationFramework.notifyCustomer({
            notificationType: 'error',
            messageToShowCustomer: text('slowInstantUpload'),
        });
    }
}
