import type { ReactNode } from 'react';
import React from 'react';
import { DesignLayer } from '@design-stack-vista/ida-framework';
import { css, cx } from '@emotion/css';
import { tokens } from '@vp/swan';

export interface SimpleSceneLayerProps {
    /** The child element(s) */
    children?: ReactNode | ReactNode[];
    /**
     * Optional background css value
     * @defaultValue `white`
     */
    background?: string;
    /**
     * Optional box-shadow css value
     * @defaultValue `0px 0px 4px 0px rgba(0,0,0,0.3)`
     */
    boxShadow?: string;
    /**
     * Optional CSS class name to add custom style
     */
    className?: string;
}

export function SimpleSceneLayer({
    children,
    background = tokens.SwanSemColorBgStandard,
    boxShadow = '0px 5px 10px rgba(0,0,0,0.1)',
    className,
}: SimpleSceneLayerProps) {
    const sceneLayerStyle = css`
        width: 100%;
        height: 100%;
        background: ${background};
        box-shadow: ${boxShadow};
    `;
    return (
        <DesignLayer name="simple-scene">
            <div className={cx('ida-simple-scene-layer', sceneLayerStyle, className)}>{children}</div>
        </DesignLayer>
    );
}
