import React, { createContext, useContext, useMemo, useState } from 'react';
import { UPLOAD_BACK_DESIGN_MODAL_TYPES } from '../features/UploadPanel/components/UploadBackDesignModal';
import { TextConfig } from '../features/UploadPanel/components/UploadBackDesignModal/constants';
import { getUploadBackDesignModalConfig } from '../features/UploadPanel/components/UploadBackDesignModal/helper';

type BackConfirmationContextType = {
    isBackVisited: boolean;
    openBackUploadModal: boolean;
    backDesignModalType: UPLOAD_BACK_DESIGN_MODAL_TYPES;
    modalConfig: TextConfig;
    setIsBackVisited: (value: boolean) => void;
    setOpenBackUploadModal: (value: boolean) => void;
    setBackDesignModalType: (value: UPLOAD_BACK_DESIGN_MODAL_TYPES) => void;
    setModalConfig: (value: TextConfig) => void;
};

const BackConfirmationContext = createContext<BackConfirmationContextType | null>(null);

export const useBackConfirmationContext = () => {
    const value = useContext(BackConfirmationContext);
    if (!value) {
        throw new Error('useBackConfirmationContext must be used within a BackConfirmationProvider');
    }
    return value;
};

export interface BackConfirmationProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

export const BackConfirmationProvider = (props: BackConfirmationProviderProps) => {
    const [isBackVisited, setIsBackVisited] = useState<boolean>(false);
    const [openBackUploadModal, setOpenBackUploadModal] = useState<boolean>(false);
    const [backDesignModalType, setBackDesignModalType] = useState<UPLOAD_BACK_DESIGN_MODAL_TYPES>(
        UPLOAD_BACK_DESIGN_MODAL_TYPES.UPLOAD_ASSET
    );
    const initialModalConfig = getUploadBackDesignModalConfig();
    const [modalConfig, setModalConfig] = useState<TextConfig>(initialModalConfig);
    const value = useMemo(() => {
        return {
            isBackVisited,
            openBackUploadModal,
            backDesignModalType,
            modalConfig,
            setIsBackVisited,
            setOpenBackUploadModal,
            setBackDesignModalType,
            setModalConfig,
        };
    }, [isBackVisited, openBackUploadModal, backDesignModalType]);
    return <BackConfirmationContext.Provider value={value}>{props.children}</BackConfirmationContext.Provider>;
};
