import React, { useEffect } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import {
    ACTION_NAME,
    PageSection,
    PageStage,
    QUAD_TRACKING_EVENTS,
} from '../../../../features/TrackingEvents/constant';
import { text } from '../../../../utils/localization';
import { TeamsIcon } from '../../../SvgIcons';
import { useHeaderInfo } from '../../HeaderInfoProvider';
import { useIsTeamsEnabled } from '../../hooks/useIsTeamsEnabled';
import { HeaderButton } from '../HeaderButton';

export const TeamsButton = (): JSX.Element | null => {
    const { setIsTeamPromoteModalVisible } = useHeaderInfo();
    const { fireEvent } = useCustomEventPropagation();
    const isTeamsEnabled = useIsTeamsEnabled();

    const openTeamPromoteModal = () => {
        setIsTeamPromoteModalVisible(true);

        fireEvent({
            name: ACTION_NAME.EXPERIMENT_CLICKED,
            extraData: {
                label: QUAD_TRACKING_EVENTS.CLICK_TEAMS_BUTTON,
                eventDetail: 'QUAD;QUAD;Teams',
                navigationDetail: 'Teams:Teams Button',
                pageSection: PageSection.Teams,
                pageStage: PageStage.Design,
                category: undefined,
            },
        });
    };

    // preload teams guide modal images
    // ToDo: We need to use cloudinary for static image
    useEffect(() => {
        const imagesPreload = [
            `${process.env.PUBLIC_URL}/images/teams-promote-1.png`,
            `${process.env.PUBLIC_URL}/images/teams-promote-2.png`,
            `${process.env.PUBLIC_URL}/images/teams-promote-3.png`,
        ];
        imagesPreload.forEach((image: string) => {
            const newImage = new Image();
            newImage.src = image;
        });
    }, []);

    if (!isTeamsEnabled) {
        return null;
    }

    return <HeaderButton label={text('teams')} icon={<TeamsIcon />} onClick={openTeamPromoteModal} />;
};
