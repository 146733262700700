import React, { useCallback, useState } from 'react';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { css } from '@emotion/css';
import { Icon } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { usePopper } from 'react-popper';
import { SaveChangesButton } from './SaveChangesButton';
import { SaveChangesTooltip } from './SaveChangesTooltip';
import { updateCart } from '../../../../api/cart';
import { fetchWorkEntity } from '../../../../api/workEntity';
import { NOTIFICATION_DISMISS_TIMEOUT, REQUESTOR } from '../../../../config/constant';
import { PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../../../features/TrackingEvents/constant';
import { useProductConfiguration } from '../../../../hooks/calcifer';
import { useActivePanel } from '../../../../hooks/useActivePanel';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';
import { useTrackExperimentClick } from '../../../../hooks/useTrackExperimentClick';
import { useWorkEntityService } from '../../../../hooks/useWorkEntityService';
import { useFullScreenLoader, useProductParams } from '../../../../providers';
import { useSaveWork } from '../../../../providers/SaveWorkProvider';
import { text } from '../../../../utils/localization';
import { DesktopExperience, MobileExperience } from '../../../Experiences';
import { useNotificationFramework } from '../../../Notification';
import { HeaderButton } from '../HeaderButton';

const iconAndTextStyle = css`
    display: flex;
    flex-direction: row;
`;

export const SaveChangesButtonWrapper = observer(() => {
    const { isWorkSaved, setLastWorkEntity, setIsWorkSaved } = useSaveWork();
    const panel = useActivePanel();
    const { isSmall } = useScreenLayout();
    const { locale, isItemInCart } = useProductParams();
    const { saveCurrentProgress } = useWorkEntityService();
    const { identity, auth: vpAuth } = useIdentityContext();
    const { setFullScreenLoaderData } = useFullScreenLoader();
    const { trackExperimentClick } = useTrackExperimentClick();
    const { productData, workId, secondaryConfig } = useProductConfiguration();
    const { notifyCustomer, removeAllNotifications } = useNotificationFramework();

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
    });

    const authorizationHeader = vpAuth.getAuthorizationHeader();

    useAsyncEffect(
        (helpers) => {
            const workEntityData = async () => {
                if (workId) {
                    setIsWorkSaved(true);
                    const workEntity = await fetchWorkEntity(
                        authorizationHeader,
                        REQUESTOR,
                        workId,
                        helpers.abortSignal
                    );

                    if (workEntity) {
                        setLastWorkEntity(workEntity);
                    }
                }
            };

            workEntityData();
        },
        [workId, authorizationHeader]
    );

    const handleClick = useCallback(async () => {
        setFullScreenLoaderData({
            showLoader: true,
            loaderMessage: text('designSaveLabel'),
        });
        setIsWorkSaved(true);

        const workEntity = await saveCurrentProgress();

        removeAllNotifications();
        notifyCustomer(
            {
                notificationType: 'positive',
                messageToShowCustomer: text('saveToastMessage'),
            },
            true,
            NOTIFICATION_DISMISS_TIMEOUT
        );

        trackExperimentClick({
            label: QUAD_TRACKING_EVENTS.CLICK_SAVE_CHANGES,
            eventDetail: 'QUAD;QUAD;Save changes',
            navigationDetail: 'Quad header:Save changes:1',
            pageSection: PageSection.SaveChanges,
            pageStage: PageStage.Design,
            experimentDetail: {
                panelId: panel?.id,
                panelName: panel?.panelProperties.name,
            },
        });

        if (isItemInCart && workEntity) {
            const isConnectedCard = productData?.customerSelectedOptions.Connection === 'NFC';
            const isQuantityPageEnabled = secondaryConfig?.isQuantityPageEnabled ?? false;

            await updateCart({
                authorizationHeader,
                locale,
                work: workEntity,
                shopperId: identity.shopperId,
                anonymousId: identity.anonymousUserId,
                isQuantityPageEnabled,
                isConnectedCard,
                selectedProductOptions: productData
                    ? { ...productData.selectedOptions, ...productData.customerSelectedOptions }
                    : {},
                mpvId: productData?.mpvId || null,
            });
        }

        setFullScreenLoaderData({ showLoader: false });
    }, [isItemInCart, productData, identity, secondaryConfig, panel, authorizationHeader, isSmall]);

    return (
        <>
            <MobileExperience>
                <HeaderButton
                    label={text('saveChanges')}
                    icon={<Icon iconType="save" size="24p" />}
                    onClick={handleClick}
                />
            </MobileExperience>
            <DesktopExperience>
                <>
                    <div
                        className={iconAndTextStyle}
                        ref={setReferenceElement}
                        onMouseEnter={() => setTooltipVisible(true)}
                        onMouseLeave={() => setTooltipVisible(false)}
                        onFocus={() => setTooltipVisible(true)}
                        onBlur={() => setTooltipVisible(false)}
                        aria-describedby={text('saveChangesTooltip')}
                        role="button"
                        tabIndex={0}
                        aria-expanded={tooltipVisible}
                        aria-controls={text('saveChangesTooltip')}
                    >
                        {!isWorkSaved ? (
                            <SaveChangesButton
                                handleClick={handleClick}
                                buttonLabel={!isSmall ? text('saveChanges') : text('save')}
                                aria-describedby={text('saveChangesBtn')}
                            />
                        ) : (
                            <SaveChangesButton
                                buttonLabel={!isSmall ? text('allChangesSaved') : text('saved')}
                                aria-describedby={text('saveChangesBtn')}
                            />
                        )}
                    </div>
                    <SaveChangesTooltip
                        popperAttributes={attributes.popper}
                        popperStyles={styles.popper}
                        ref={setPopperElement}
                        isVisible={tooltipVisible}
                    />
                </>
            </DesktopExperience>
        </>
    );
});
