import type { Item } from '@design-stack-vista/cdif-types';
import type { ItemState, PanelState, SubpanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import type {
    BaseExtensionClassInterface,
    InteractiveDesignEngine,
} from '@design-stack-vista/interactive-design-engine-core';

export type ItemStateType = ItemState<Item> | SubpanelState;

/**
 * A hook retrieving an instance of an extension for a given design state item's
 * internal ID; throws an error if extension can not be found
 */
export function useGetExtension<T extends BaseExtensionClassInterface>(iid: string, extensionClass: T): InstanceType<T>;
/**
 * A hook retrieving an instance of an extension for a given item state;
 * throws an error if extension can not be found
 */
export function useGetExtension<T extends BaseExtensionClassInterface>(
    designState: ItemStateType,
    extensionClass: T
): InstanceType<T>;
/**
 * A hook retrieving an instance of an extension for a given (sub)panel state;
 * throws an error if extension can not be found
 */
export function useGetExtension<T extends BaseExtensionClassInterface>(
    designState: PanelState | SubpanelState,
    extensionClass: T
): InstanceType<T>;

export function useGetExtension<T extends BaseExtensionClassInterface>(
    designStateOrIID: ItemStateType | PanelState | string,
    extensionClass: T
) {
    return getExtensionInternal(useDesignEngine(), designStateOrIID, extensionClass);
}

export function getExtensionInternal<T extends BaseExtensionClassInterface>(
    designEngine: InteractiveDesignEngine,
    designStateOrIID: ItemStateType | PanelState | string,
    extensionClass: T
): InstanceType<T> {
    const { designExtensionSystem } = designEngine;
    const extension = designExtensionSystem.getExtension(
        typeof designStateOrIID === 'string' ? designStateOrIID : designStateOrIID.iid,
        extensionClass
    );

    if (!extension) {
        throw new Error(`Required extension ${extensionClass.name} was not found`);
    }
    return extension;
}
