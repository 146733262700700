import { useDesignEngine } from '@design-stack-vista/core-features';
import { SelectedValue } from '@vp/swan';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useFullScreenLoader } from '../../../providers';
import { text } from '../../../utils/localization';
import { useLoadProduct } from '../../Flexibility/common';
import { useWaitForInstantUpload } from '../../InstantUpload';
import { WAIT_FOR_DISABLING_SUBSTRATE_LOADER } from '../constants';
import { substrateColorStore } from '../SubstrateColorStore';

export const useLoadNewSubstrateColor = () => {
    const designEngine = useDesignEngine();
    const { loadProduct } = useLoadProduct();
    const { productData } = useProductConfiguration();
    const { setFullScreenLoaderData } = useFullScreenLoader();
    const { waitForInstantUpload } = useWaitForInstantUpload();
    const { substrateColorOptionName } = substrateColorStore;

    const loadNewSubstrateColor = async (newSelectedSubstrateColor: SelectedValue) => {
        if (productData && newSelectedSubstrateColor) {
            setFullScreenLoaderData({ showLoader: true, loaderMessage: text('updatingColor') });

            await waitForInstantUpload();
            const targetDocument = await designEngine.getCimDoc();
            const customerSelectedProductOptions = {
                ...productData.selectedOptions,
                [substrateColorOptionName]: newSelectedSubstrateColor.toString(),
            };

            await loadProduct({
                newProduct: {
                    productKey: productData.productKey,
                    productVersion: productData.productVersion,
                    productAttributes: customerSelectedProductOptions,
                },
                newCimDoc: targetDocument,
            });

            // using timeout as in some cases the loader disappears quickly
            // which appears as if the application is flickering
            setTimeout(() => {
                setFullScreenLoaderData({ showLoader: false });
            }, WAIT_FOR_DISABLING_SUBSTRATE_LOADER);
        }
    };

    return {
        loadNewSubstrateColor,
    };
};
