import React, { useCallback } from 'react';
import { UploadsButton } from '@design-stack-vista/upload-components';
import { RecentImages } from './RecentImages';
import { UploadModalInfo } from './UploadModalInfo';
import { UploadModalNotification } from './UploadModalNotification';
import { usePanelTitle } from '../../hooks/usePanelTitle';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { useUploadTracking } from '../../hooks/useUploadTracking';

interface UploadModalBodyComponentsProps {
    activePanelName: string;
    activePanelId: string;
}

export const UploadModalBodyComponents = ({ activePanelName, activePanelId }: UploadModalBodyComponentsProps) => {
    const { isSmall } = useScreenLayout();
    const { getUploadButtonText } = usePanelTitle();
    const { trackUploadButtonClick } = useUploadTracking();

    const handleUploadButtonClick = useCallback(() => {
        trackUploadButtonClick();
    }, [trackUploadButtonClick]);

    return (
        <>
            <UploadModalInfo />
            <UploadModalNotification />
            <UploadsButton
                skin="primary"
                multiple={true}
                size={isSmall ? 'mini' : 'standard'}
                width="full-width"
                data-testid="upload-button"
                onClick={handleUploadButtonClick}
            >
                {getUploadButtonText(activePanelName)}
            </UploadsButton>
            <RecentImages panelId={activePanelId} />
        </>
    );
};
