import i18next, { Resource, t, TOptions, use } from 'i18next';
import { initReactI18next } from 'react-i18next';

const fallbackLanguages = {
    'en-ca': ['en-au', 'en-gb', 'en-us'],
    'en-nz': ['en-au', 'en-gb', 'en-us'],
    'en-au': ['en-gb', 'en-us'],
    'en-ie': ['en-gb', 'en-us'],
    'en-in': ['en-gb', 'en-us'],
    'en-sg': ['en-gb', 'en-us'],
    'en-gb': ['en-us'],
    'fr-be': ['fr-fr', 'en-us'],
    'fr-ca': ['fr-fr', 'en-us'],
    'fr-ch': ['fr-fr', 'en-us'],
    'es-us': ['es-es', 'en-us'],
    'nl-be': ['nl-nl', 'en-us'],
    'de-at': ['de-de', 'en-us'],
    'de-ch': ['de-de', 'en-us'],
    'it-ch': ['it-it', 'en-us'],
    default: ['en-us'],
};

/**
 * Initializes the global i18next instance to use the correct locale and resources for localization.
 * @param resources resource objects to be initialized for i18next localization
 * @param locale Current locale of the client. e.g. 'en-ie'
 * @param isDevelopmentMode Determines if i18next will log localization debugging info to console.
 * @returns The initialized i18next instance.
 */
function initialize(resources: Resource, locale: string, isDevelopmentMode: boolean) {
    return use(initReactI18next).init({
        resources: resources,
        debug: isDevelopmentMode,
        lng: locale,
        fallbackLng: fallbackLanguages,
        returnObjects: true,
        keySeparator: '.',
        lowerCaseLng: true,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    });
}

/**
 * Returns the localized string value given a corresponding key. Returns the key if no such entry exists.
 * @param key The kebab-case string key that denotes which localized text to return.
 * @param options Optional object of options to pass to i18next.t function (https://www.i18next.com/translation-function/essentials).
 * @returns A localized string value from the corresponding locale and key.
 */
export function text(key: string, options?: TOptions): string {
    return t(key, options);
}

export const localizationUtil = {
    fallbackLanguages,
    initialize,
    i18next,
};
