import { useMemo } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useProductConfiguration } from './calcifer';
import { findScene } from '../utils';

export interface PanelSceneConfiguration {
    underlayUrl?: string;
    overlayUrl?: string;
    thumbnailUrl?: string;
}
export interface SceneConfiguration {
    [panelId: string]: PanelSceneConfiguration;
}

export const useSceneConfiguration = () => {
    const { productData } = useProductConfiguration();
    const {
        cimDocStore: { panels },
    } = useDesignEngine();

    const scenesConfiguration = productData?.scenesConfiguration;
    const designViews = productData?.designViews.designViews;

    const sceneConfig = useMemo(() => {
        if (!scenesConfiguration) {
            return undefined;
        }
        return panels.reduce((accumulator, panel, index) => {
            accumulator[panel.id] = {
                underlayUrl: findScene(
                    panel,
                    index,
                    panels,
                    scenesConfiguration.underlay,
                    designViews && designViews[index]
                ),
                overlayUrl: findScene(
                    panel,
                    index,
                    panels,
                    scenesConfiguration.overlay,
                    designViews && designViews[index]
                ),
            };
            return accumulator;
        }, {} as SceneConfiguration);
    }, [scenesConfiguration, designViews, panels]);

    return {
        sceneConfig,
    };
};
