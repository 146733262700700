import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { getNumPages } from './uploadUtils';

const areOtherPanelsEmpty = (panels: PanelState[]) => {
    const value = panels.every((panel, index) => {
        /**
         * Skipping the first panel, as we don't want to evaluate
         * is there any items in the first panel.
         */
        if (index === 0) {
            return true;
        }
        return panel.items.length > 0;
    });

    return value;
};

/**
 * Auto mapping will be available when meet all below conditions
 * It will be only two panels of the product
 * Only will be triggered from the first panel
 * The second panel should be blank
 * If a single file is uploaded and that file should be a multi-page pdf
 */
export const canBeAutoMapped = (asset: VistaAsset, panels: PanelState[], activePanelId: string | undefined) => {
    const isPdf = asset.data?.info.image?.format === 'pdf';
    const itemsPresentInOtherPanels = areOtherPanelsEmpty(panels);
    const noOfPages = getNumPages(asset);

    return panels.length > 1 && activePanelId === panels[0].id && !itemsPresentInOtherPanels && isPdf && noOfPages > 1;
};
