import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const InvertColorIcon = (props: SvgProps) => {
    return (
        <svg viewBox="0 0 11 11" fill="none" {...props}>
            <rect width="11" height="11" rx="2" fill="black"></rect>
            <path d="M3.16667 1.5H8.79289L1.5 8.79289V2.75L3.16667 1.5Z" fill="#F8F8F8" stroke="#F8F8F8"></path>
        </svg>
    );
};
