import { CimDoc } from '@design-stack-vista/cdif-types';
import { FinishOption, PF_INSTRUCTIONS_TYPE } from '../../PremiumFinish';

type PremiumFinishReviewInstructionArgs = {
    panelId: string;
    finishOption: FinishOption;
    optionalNotes?: string;
};

/**
 * Add Premium finish review instruction to the cimdoc
 */
export const addPremiumFinishReviewInstruction = (
    cimdoc: CimDoc,
    { panelId, finishOption, optionalNotes }: PremiumFinishReviewInstructionArgs
) => {
    if (!cimdoc.metadata) {
        cimdoc.metadata = {};
    }
    if (!cimdoc.metadata.reviewInstructions) {
        cimdoc.metadata.reviewInstructions = [];
    }
    const premiumInstructionReviewIndex = cimdoc.metadata.reviewInstructions.findIndex(
        (review: { instructionType: string }) => review.instructionType === PF_INSTRUCTIONS_TYPE
    );
    const finishReviewInstruction = {
        panelId,
        instructionType: PF_INSTRUCTIONS_TYPE,
        finishOption,
        optionalNotes,
    };
    if (premiumInstructionReviewIndex === -1) {
        cimdoc.metadata.reviewInstructions.push(finishReviewInstruction);
    } else {
        cimdoc.metadata.reviewInstructions[premiumInstructionReviewIndex] = finishReviewInstruction;
    }
};
