import React from 'react';
import { css } from '@emotion/css';
import { MiniLogo } from '@vp/diy-editor-ui';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';
import { useHeaderInfo } from '../HeaderInfoProvider';

export const miniLogoStyle = css`
    img {
        height: 32px;
    }
`;

export const mobileMiniLogoStyle = css`
    svg {
        height: 32px;
    }
`;

export const VistaLogo = (): JSX.Element => {
    const { headerData } = useHeaderInfo();
    const { isSmall } = useScreenLayout();

    return (
        <MiniLogo
            mt={2}
            className={isSmall ? mobileMiniLogoStyle : miniLogoStyle}
            logo={isSmall ? headerData.base.inlineSecondaryLogo : headerData.base.logo}
            href={headerData.base.homeUrl}
            accessibleText={text('miniLogo')}
        />
    );
};
