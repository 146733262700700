import React from 'react';
import { Button, Icon } from '@vp/swan';
import { DesktopExperience, MobileExperience } from '../../components/Experiences';
import { usePreview } from '../../providers/PreviewProvider';
import { text } from '../../utils/localization';
import { useDesignPreview } from '../quad/recipes/useDesignPreview';
import { QuickToolItem } from '../QuickTools/QuickToolItem';

export const DesignPreviewModalButton = (): JSX.Element => {
    const { designPreviews } = usePreview();
    const { handleButtonClick } = useDesignPreview();

    return (
        <>
            {/*
            This code is to prefetch all preview Url images and keep it in browser cache
            as this div is not having width, it'll not contribute to any visual effect
        */}
            {designPreviews?.map((preview) => (
                <div
                    key={preview.title}
                    style={{
                        backgroundImage: `url(${preview.url})`,
                        display: 'none',
                    }}
                />
            ))}
            <DesktopExperience>
                <Button
                    iconPosition="left"
                    onClick={handleButtonClick}
                    skin="secondary"
                    width="full-width"
                    data-testid="design-preview-btn"
                >
                    <Icon iconType="passwordShow" />
                    {text('previewButton')}
                </Button>
            </DesktopExperience>
            <MobileExperience>
                <QuickToolItem
                    Icon={<Icon iconType="passwordShow" size="20p" />}
                    buttonLabel={text('previewButton')}
                    onClick={handleButtonClick}
                    dataTestId="design-preview-btn"
                />
            </MobileExperience>
        </>
    );
};
