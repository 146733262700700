import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const CircleIcon = (props: SvgProps) => {
    return (
        <svg viewBox="0 0 82 82" fill="none" {...props}>
            <rect x="30.35" y="29.85" width="22.3" height="22.3" rx="11.15" stroke="black" strokeWidth="1.7" />
        </svg>
    );
};
