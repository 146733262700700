import React, { useCallback } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { FlexBox, SelectionSet, tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { SelectionOption } from '../../../components/SelectionOption';
import { PanelSides } from '../../../config/constant';
import { useUploadPanels } from '../../../hooks/useUploadPanel';
import { text } from '../../../utils/localization';
import { getPanelByName } from '../../UploadPanel/components/UploadBackDesignModal/helper';
import { useBackDesignEvents } from '../hooks';
import { backDesignTemplateStore } from '../store/BackDesignTemplateStore';
import { selectLabelStyles } from '../style';

const colorListSelectStyle = css`
    ${selectLabelStyles};
    &.swan-selection-set.swan-selection-set-skin-tiles-horizontal {
        .swan-selection-set-label {
            padding: ${tokens.SwanSemSpace6} ${tokens.SwanSemSpace5};
            min-height: auto;
        }
        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
        }
    }
`;

export const BackColorList = observer((): JSX.Element | null => {
    const designEngine = useDesignEngine();
    const {
        cimDocStore: { panels },
    } = designEngine;
    const { onAddShapeToPanel } = useUploadPanels();
    const { fireAddColorEvent } = useBackDesignEvents();
    const { assetId, colorPreviews } = backDesignTemplateStore;
    const backPanel = getPanelByName(panels, PanelSides.Back);

    const hideColorList = !assetId || !colorPreviews.length || !backPanel;

    const applyColor = useCallback(
        async (color?: string) => {
            if (backPanel && color) {
                await onAddShapeToPanel(backPanel.id, color);
                fireAddColorEvent();
            }
        },
        [backPanel, onAddShapeToPanel]
    );

    if (hideColorList) {
        return null;
    }

    return (
        <SelectionSet className={colorListSelectStyle} skin="tiles-horizontal" variant="single-select">
            <FlexBox flexDirection="row" justifyContent="space-between" gap="4">
                {colorPreviews.map(({ color, preview }) => (
                    <SelectionOption key={color} optionValue={color} panel={backPanel} onSelection={applyColor}>
                        <img src={preview} alt={text('chooseTemplateAltText')} />
                    </SelectionOption>
                ))}
            </FlexBox>
        </SelectionSet>
    );
});
