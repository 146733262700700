import { useContext } from 'react';
import { PostCardContext } from '../PostCardProvider';

export const usePostCard = () => {
    const postCard = useContext(PostCardContext);

    if (!postCard) {
        throw new Error('usePostCard should be wrapped inside PostCardProvider');
    }

    return postCard;
};
