import React, { useEffect, useRef } from 'react';
import { css } from '@emotion/css';
import { FlexBox } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DesktopExperience } from './Experiences';
import { DesignAreaCanvas } from '../features/DesignArea';
import { useProductPageViewed, useTrackNewPage } from '../features/TrackingEvents/hooks';
import { UploadPanel } from '../features/UploadPanel/UploadPanel';
import { useExitPrompt } from '../hooks/useExitPrompt';
import { useScreenLayout } from '../hooks/useScreenLayout';
import { useMaskLegend } from '../providers/MaskLegendProvider';

export const getDesignAreaPageStyle = (isSmall: boolean) => css`
    // exclude 80px page header height for desktop and 60px for mobile
    ${isSmall && 'height: calc( calc(var(--vh, 1vh) * 100) - 60px);'} // fallback for browsers not supporting dvh
    height: ${isSmall ? 'calc(100dvh - 60px)' : 'calc(100% - 80px)'};
`;

export const UploadDesignArea = observer(() => {
    useExitPrompt();
    const { isSmall } = useScreenLayout();
    const { closeAllLegend } = useMaskLegend();
    const { trackProductView } = useProductPageViewed();
    const firePageLoadEvents = useRef(true);
    const { trackPage } = useTrackNewPage();

    useEffect(() => {
        if (firePageLoadEvents.current) {
            trackProductView();
            trackPage();
            firePageLoadEvents.current = false;
        }
    }, [firePageLoadEvents.current, trackProductView, trackPage]);

    return (
        <FlexBox className={getDesignAreaPageStyle(isSmall)} onClick={closeAllLegend}>
            <DesignAreaCanvas />
            <DesktopExperience>
                <UploadPanel />
            </DesktopExperience>
        </FlexBox>
    );
});
