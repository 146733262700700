/**
 * Reference - {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/master/src/studioFive/clients/pricingServiceClient.js
}
 */
import { REQUESTOR, Tenants } from '../config/constant';
import { PricingApiResponse } from '../features/Flexibility/common';
import { EstimatedPricingApiResponse } from '../types/price';
import { fetchWrapper, getPricingContextModule } from '../utils';

export async function getDifferentialPricing(
    productKey: string,
    studioSelectedProductOptions: Record<string, string>,
    quantity: number,
    choiceGroups: Record<string, Record<string, string>>,
    productVersion: number,
    signal?: AbortSignal
): Promise<PricingApiResponse> {
    const body = {
        productKey,
        quantity,
        merchantId: Tenants.Vistaprint,
        selections: studioSelectedProductOptions,
        choiceGroups,
        pricingContext: getPricingContextModule().getEncodedContextString(),
        productVersion,
    };

    const response = await fetchWrapper<PricingApiResponse>(
        `https://website-pricing-service.prices.cimpress.io/v4/prices/differential?requestor=${REQUESTOR}`,
        {
            method: 'POST',
            body,
            requestInitiator: 'getDifferentialPricing',
            signal,
        }
    ); // Error and Retry are not handled

    if (
        !response ||
        !response.choiceGroups ||
        // response.choiceGroups.length === 0 || // (Note: this is a wrong check since choiceGroups is object)
        // this may not be an error - perhaps the option was just invalid and we shouldn't offer it?
        Object.values(response.choiceGroups).some((choice) => choice.priceCalculationFailed)
    ) {
        throw new Error(
            `Could not get differential pricing.  Body: ${JSON.stringify(body)}  response: ${JSON.stringify(response)}`
        );
    }

    return response;
}

export async function getEstimatedPricing(
    productKey: string,
    studioSelectedProductOptions: Record<string, string>,
    quantity: number,
    productVersion: number,
    signal?: AbortSignal
): Promise<EstimatedPricingApiResponse> {
    const selectionsQueryStringParams = formatSelectionsQueryParameters(studioSelectedProductOptions);

    const queryStringParams = {
        requestor: REQUESTOR,
        Quantities: quantity.toString(),
        ProductKey: productKey,
        ProductVersion: productVersion.toString(),
        MerchantId: Tenants.Vistaprint,
        PricingContext: getPricingContextModule().getEncodedContextString(),
        ...selectionsQueryStringParams,
    };

    const response = await fetchWrapper<EstimatedPricingApiResponse>(
        `https://website-pricing-service.prices.cimpress.io/v4/prices/startingAt/estimated?requestor=${REQUESTOR}`,
        {
            method: 'GET',
            query: queryStringParams,
            requestInitiator: 'getEstimatedPricing',
            signal,
        }
    );

    if (!response) {
        throw new Error(
            `Could not get estimated pricing.  Body: ${JSON.stringify(queryStringParams)}  response: ${JSON.stringify(
                response
            )}`
        );
    }

    return response;
}

function formatSelectionsQueryParameters(selections: Record<string, string>): Record<string, string> {
    const entries = Object.entries(selections);
    const newEntries = entries.map(([key, value]) => [`Selections[${key}]`, value]);

    return Object.fromEntries(newEntries);
}
