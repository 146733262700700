import { LOCAL_STORAGE_CATEGORIES } from './constant';
import { isEmptyObject } from '../common';

const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

class LocalStorageUtility {
    refetch: boolean;

    constructor() {
        this.refetch = false;
    }

    private localStorageGetter = () => {
        let localStorageData = { ...window.localStorage };
        return (category: string) => {
            if (this.refetch) {
                this.refetch = false;
                localStorageData = { ...window.localStorage };
            }
            return localStorageData[category];
        };
    };

    private getCategorisedLocalStorageData = this.localStorageGetter();

    private getCategorisedDataFromLocalStorage = (category: string, key?: string) => {
        if (canUseDOM) {
            const localStorageDataForCategory = this.getCategorisedLocalStorageData(category);
            if (localStorageDataForCategory) {
                const parsedLocalStorageDataForCategory = JSON.parse(localStorageDataForCategory);
                if (key) {
                    return parsedLocalStorageDataForCategory[key] ?? null;
                }
                return parsedLocalStorageDataForCategory;
            }
        } else {
            console.error('LocalStorage must be used at client');
        }
        return key ? null : {};
    };

    private setCategorisedDataToLocalStorage = (category: string, categoryData: { [key: string]: unknown }) => {
        localStorage.setItem(category, JSON.stringify(categoryData));
    };

    private addItem = (category: string, key: string, value: unknown) => {
        const categoryData = this.getCategorisedDataFromLocalStorage(category);
        if (isEmptyObject(categoryData)) {
            this.setCategorisedDataToLocalStorage(category, { [key]: value });
        } else {
            const isDifferentValue = categoryData[key] !== value;
            if (isDifferentValue) {
                this.setCategorisedDataToLocalStorage(category, { ...categoryData, [key]: value });
            }
        }
        this.refetch = true;
    };

    private removeItem = (category: string, key: string) => {
        const categoryData = this.getCategorisedDataFromLocalStorage(category);
        const updatedCategoryData = { ...categoryData };
        if (!isEmptyObject(updatedCategoryData) && updatedCategoryData[key]) {
            delete updatedCategoryData[key];
            this.setCategorisedDataToLocalStorage(category, updatedCategoryData);
        }
        this.refetch = true;
    };

    // Getters for flag/config/Experiments wil accept key as an option and return specific value if key is provided otherwise it'll return whole local storage object of specific category that can be used at consumer end
    getQuadFlags = (key?: string) => this.getCategorisedDataFromLocalStorage(LOCAL_STORAGE_CATEGORIES.QUAD_FLAGS, key);
    getQuadExperiments = (key?: string) =>
        this.getCategorisedDataFromLocalStorage(LOCAL_STORAGE_CATEGORIES.QUAD_EXPERIMENTS, key);
    getQuadConfig = (key?: string) =>
        this.getCategorisedDataFromLocalStorage(LOCAL_STORAGE_CATEGORIES.QUAD_CONFIG, key);

    addQuadFlags = (key: string, value: string) => this.addItem(LOCAL_STORAGE_CATEGORIES.QUAD_FLAGS, key, value);
    removeQuadFlag = (key: string) => this.removeItem(LOCAL_STORAGE_CATEGORIES.QUAD_FLAGS, key);
    addQuadExperiment = (key: string, value: string) =>
        this.addItem(LOCAL_STORAGE_CATEGORIES.QUAD_EXPERIMENTS, key, value);
    removeQuadExperiment = (key: string) => this.removeItem(LOCAL_STORAGE_CATEGORIES.QUAD_EXPERIMENTS, key);
    addQuadConfig = (key: string, value: string) => this.addItem(LOCAL_STORAGE_CATEGORIES.QUAD_CONFIG, key, value);
    removeQuadConfig = (key: string) => this.removeItem(LOCAL_STORAGE_CATEGORIES.QUAD_CONFIG, key);
}

export const localStorageUtility = new LocalStorageUtility();
