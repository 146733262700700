import { useCallback } from 'react';
import { CimDoc } from '@design-stack-vista/cdif-types';
import { REQUESTOR } from '../../config/constant';
import { useProductConfiguration } from '../../hooks/calcifer';
import { sortOptions, useQueryProductConfiguration } from '../../hooks/calcifer/useQueryProductConfiguration';
import { useProductParams } from '../../providers';
import { getCountryFromLocale } from '../../utils';
import { addQueryParams, getQueryParams } from '../Flexibility/common';

export const useUpdateFinishVariant = () => {
    const { productData, updateProductConfiguration } = useProductConfiguration();
    const { getProductConfiguration } = useQueryProductConfiguration();
    const productParams = useProductParams();

    const onError = () => {
        // TODO:handle error scenario here
    };
    const onFinishVariantUpdate = useCallback(
        async (variant: string, latestCimdoc: CimDoc) => {
            const groupChoiceSelectionOption = { ...productData?.customerSelectedOptions, Finish: variant };

            if (productData) {
                const cimdoc = structuredClone(latestCimdoc) as CimDoc;

                const productConfig = await getProductConfiguration(
                    {
                        locale: productParams.locale,
                        productKey: productParams.productKey,
                        market: getCountryFromLocale(productParams.locale),
                        selectedOptions: JSON.stringify(sortOptions(groupChoiceSelectionOption)),
                        productVersion: productParams.productVersion,
                        requestor: REQUESTOR,
                        qty: productParams.selectedQuantity,
                        documentUrl: productParams.documentUrl,
                    },
                    onError
                );

                if (productConfig) {
                    await updateProductConfiguration({ newCimDoc: cimdoc, productConfig });
                    const queryParam = getQueryParams();
                    if (!queryParam.workId) {
                        const replaceQuery = addQueryParams(window.location.href, {
                            key: productParams.productKey,
                            productVersion: productParams.productVersion,
                            selectedOptions: JSON.stringify(groupChoiceSelectionOption),
                            workId: undefined,
                        });

                        window.history.replaceState('update-url', '', replaceQuery);
                    }
                }
            }
        },
        [
            getProductConfiguration,
            productData,
            productParams.documentUrl,
            productParams.locale,
            productParams.productKey,
            productParams.productVersion,
            productParams.selectedQuantity,
        ]
    );

    return {
        onFinishVariantUpdate,
    };
};
