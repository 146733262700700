import { useDesignEngine } from '@design-stack-vista/core-features';
import { useObserver } from 'mobx-react-lite';
import { noticeError } from '../../../utils';
import { isEngineProcessing, ProcessingTimeoutError, waitForEngineProcessingCompletion } from '../utils';

interface WaitForInstantUploadProps {
    abortSignal?: AbortSignal;
    setLoaderStatus?: (status: boolean) => void;
}

export const useWaitForInstantUpload = () => {
    const designEngine = useDesignEngine();
    const isInstantUploadProcessing = useObserver(() => isEngineProcessing(designEngine));

    const waitForInstantUpload = async ({ abortSignal, setLoaderStatus }: WaitForInstantUploadProps = {}) => {
        if (isEngineProcessing(designEngine)) {
            try {
                setLoaderStatus?.(true);
                // Waits for any items that are processing to complete before getting the document
                await waitForEngineProcessingCompletion(designEngine, abortSignal);
            } catch (error) {
                if (error instanceof ProcessingTimeoutError) {
                    // Handle the error, but continue getting the document to prevent breaking behavior.
                    // This may result in some changes (e.g. Instant Uploads and Image Processing) being lost.
                    noticeError(error, { method: 'useWaitForInstantUpload - waitForInstantUpload' });
                }
            } finally {
                setLoaderStatus?.(false);
            }
        }
    };

    return { isInstantUploadProcessing, waitForInstantUpload };
};
