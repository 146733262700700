import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { AuthConfiguration, convertToAuthHeaders } from '@design-stack-ct/utility-core';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { DiscountPricingDetails, PricingApiStatus, usePostCardPricing } from './hooks/usePostCardPricing';
import { postCardStore } from './PostCardStore';
import { fetchWorkEntityFilterPRD } from '../../api/workEntity';
import { useProductConfiguration } from '../../hooks/calcifer';
import { useQuadProductConfig } from '../../hooks/useQuadProductConfig';
import { WorkEntityResponse } from '../../types/workEntityTypes';
interface PostCardProps {
    isMailablePostCard: boolean;
    isMailingPostCard: boolean;
    switchProductTo: string | undefined;
    mailableProductPricingDetails: DiscountPricingDetails | null;
    pricingApiStatus: PricingApiStatus;
    postcardPricingDifference: string;
}

export const PostCardContext = createContext<PostCardProps | null>(null);

interface PostCardProviderProps {
    children: ReactNode | ReactNode[];
}

export const PostCardProvider = ({ children }: PostCardProviderProps): JSX.Element => {
    const { customProductConfig } = useQuadProductConfig();
    const { productData } = useProductConfiguration();
    const { getPostcardPricingDetails } = usePostCardPricing();

    const [postcardPRDKey, setPostcardPRDKey] = useState({ mailable: '', nonMailable: '' });
    const [mailableProductPricingDetails, setMailableProductPricingDetails] = useState<DiscountPricingDetails | null>(
        null
    );
    const [pricingApiStatus, setPricingApiStatus] = useState<PricingApiStatus>(PricingApiStatus.Pending);
    const [postcardPricingDifference, setPostcardPricingDifference] = useState<string>('');
    const { auth: vpAuth } = useIdentityContext();
    const { setDisableMailingModalForUser } = postCardStore;

    async function checkMailingModalDisable() {
        const auth: AuthConfiguration = { authType: 'bearer', value: vpAuth.getToken() };
        const authHeaders = convertToAuthHeaders(auth);

        let allFilterWorkEntity: WorkEntityResponse[] = [];
        await Promise.all(
            Object.keys(customProductConfig).map(async (productKey) => {
                const filterWorkEntityWithPRD = await fetchWorkEntityFilterPRD(productKey, authHeaders);
                allFilterWorkEntity = [...allFilterWorkEntity, ...filterWorkEntityWithPRD];
            })
        );

        const disableMailingService = allFilterWorkEntity.some(
            (workEntity) => workEntity.resources.disableMailingServiceModal === 'true'
        );
        setDisableMailingModalForUser(disableMailingService);
    }

    const value = useMemo(() => {
        let isMailingPostCard = false;
        let switchProductTo;
        let isMailablePostCard = false;
        if (
            productData?.productKey &&
            customProductConfig &&
            productData?.studioConfiguration &&
            customProductConfig[productData.productKey]
        ) {
            const {
                mailingPostCard,
                mailable,
                switchProductTo: switchProductToConfig,
            } = customProductConfig[productData.productKey];

            let mailableProductKey;
            let nonMailableProductKey;

            const { isCompatibleForMailingService } = productData.studioConfiguration;
            if (mailingPostCard || (mailable && isCompatibleForMailingService)) {
                isMailablePostCard = true;
                switchProductTo = switchProductToConfig as string | undefined;
            } else {
                isMailablePostCard = false;
            }

            if (mailingPostCard) {
                mailableProductKey = productData.productKey;
                nonMailableProductKey = switchProductToConfig as string;
            } else {
                mailableProductKey = switchProductToConfig as string;
                nonMailableProductKey = productData.productKey;
            }

            isMailingPostCard = mailingPostCard as boolean;

            setPostcardPRDKey({
                mailable: mailableProductKey,
                nonMailable: nonMailableProductKey,
            });
        }

        checkMailingModalDisable();

        return {
            isMailablePostCard,
            isMailingPostCard,
            switchProductTo,
        };
    }, [productData?.productKey, productData?.studioConfiguration, customProductConfig]);

    useEffect(() => {
        if (postcardPRDKey.mailable && postcardPRDKey.nonMailable) {
            getPostcardPricingDetails(postcardPRDKey).then((result) => {
                if (result) {
                    setPostcardPricingDifference(result.postcardPricingDifference);
                    setMailableProductPricingDetails(result.mailableProductPricingDetails);
                    setPricingApiStatus(result.pricingApiStatus);
                } else {
                    setPricingApiStatus(PricingApiStatus.Failed);
                }
            });
        }
    }, [postcardPRDKey, productData?.customerSelectedOptions]);

    const contextValue = useMemo(() => {
        return {
            mailableProductPricingDetails,
            postcardPricingDifference: postcardPricingDifference,
            pricingApiStatus,
            isMailablePostCard: value.isMailablePostCard,
            isMailingPostCard: value.isMailingPostCard,
            switchProductTo: value.switchProductTo,
        };
    }, [value, postcardPricingDifference, pricingApiStatus, mailableProductPricingDetails]);

    return <PostCardContext.Provider value={contextValue}>{children}</PostCardContext.Provider>;
};
