import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { text } from '../../../utils/localization';
import { changeSizeStore } from '../store';
import { getChangeSizeChoiceKey } from '../utils';

const includedPriceColor = css`
    color: ${tokens.SwanSemColorTextSubtle};
`;

const incrementPriceColor = css`
    color: ${tokens.SwanSemColorTextPromo};
`;

interface SizePriceProps {
    size: string;
}

export const SizePrice = observer(({ size }: SizePriceProps) => {
    const { isOrientationFlexible, activeOrientation, changeSizePrices } = changeSizeStore;

    const optionKey = getChangeSizeChoiceKey(isOrientationFlexible, activeOrientation, size);
    const optionPrice = changeSizePrices[optionKey];

    if (!optionPrice) {
        return null;
    }

    return optionPrice.includedPrice === optionPrice.totalDiscountPrice ? (
        <Typography fontSize="xsmall">{text('included')}</Typography>
    ) : (
        <FlexBox flexDirection="row" gap="2">
            <Typography fontSize="xsmall" className={includedPriceColor}>
                {optionPrice.includedPrice}
            </Typography>
            <Typography fontSize="xsmall" className={incrementPriceColor}>
                {optionPrice.differentialDiscountPrice}
            </Typography>
        </FlexBox>
    );
});
