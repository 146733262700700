import React, { useState } from 'react';
import { createDocument } from '@design-stack-ct/documents-sdk';
import { getPdfProof } from '@design-stack-ct/prepress-client';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { Button, FlexBox, Icon, Typography } from '@vp/swan';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import { PdfProofDownloadModal } from './PdfProofDownloadModal';
import { text } from '../../utils/localization';
import { useWaitForInstantUpload } from '../InstantUpload';

export const DownloadPdfProof = observer(({ styling }: { styling: string }) => {
    const [showPdfDetailsModal, setShowPdfDetailsModal] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const designEngine = useDesignEngine();
    const { isInstantUploadProcessing } = useWaitForInstantUpload();
    const { auth: vpAuth } = useIdentityContext();
    const authorizationHeader = vpAuth.getAuthorizationHeader();

    const onClose = () => {
        setShowPdfDetailsModal(false);
    };

    async function generatePdf() {
        if (!designEngine) {
            return undefined;
        }
        setIsGenerating(true);
        const { asJson } = designEngine.cimDocStore;
        const newCimDoc = { ...asJson, tenant: undefined };

        const data = await createDocument({
            cimDoc: newCimDoc,
            headers: {
                Authorization: authorizationHeader,
            },
            deleteAfterDays: 1,
        });
        const {
            _links: {
                drawingInstructions: { href: prepressInstructionsUrl },
            },
        } = data;
        const { PdfUrl } = await getPdfProof({
            prepressInstructionsUrl,
            headers: { Authorization: authorizationHeader },
        });

        saveAs(PdfUrl, 'pdf-proof');
        setIsGenerating(false);
    }

    return (
        <>
            <FlexBox alignItems="center" justifyContent="center" className={styling}>
                <Button
                    onClick={() => setShowPdfDetailsModal(true)}
                    disabled={isGenerating || isInstantUploadProcessing}
                    iconPosition="left"
                    fontSize="small"
                    data-testid="download-pdf-proof"
                >
                    <Icon iconType="download" skin="standard" size="20p" />
                    <Typography fontSize="small" fontWeight="bold">
                        {isGenerating ? text('downloadInProgress') : text('downloadPDF')}
                    </Typography>
                </Button>
            </FlexBox>
            <PdfProofDownloadModal
                isOpen={showPdfDetailsModal}
                onRequestDismiss={onClose}
                generatePdf={generatePdf}
                isGenerating={isGenerating}
                data-testid="pdf-download-modal"
            />
        </>
    );
});
