import { useEffect, useState } from 'react';
import { PlaceholderImage, TemplateTextItem } from '@design-stack-vista/cdif-types';
import { isPlaceholderTemplateItem } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { PanelSides } from '../../../config/constant';
import { usePanelTitle } from '../../../hooks/usePanelTitle';
import { getMessage, REDIRECT_MESSAGES } from '../../../utils';
import { text } from '../../../utils/localization';
import { getPanelByName } from '../../UploadPanel/components/UploadBackDesignModal/helper';

export const useTemplateData = () => {
    const { cimDocStore } = useDesignEngine();
    const { panels } = cimDocStore;
    const [templateMetaData, setTemplateMetaData] = useState<(TemplateTextItem | PlaceholderImage)[]>([]);
    const { getPanelTitle } = usePanelTitle();
    const backPanel = getPanelByName(panels, PanelSides.Back);
    const panelName = backPanel?.panelProperties.name;
    const panelTitle = getPanelTitle(panelName)?.toLowerCase();
    const isEmptyDesign = !backPanel?.items.length;
    const redirectText = text(getMessage(isEmptyDesign, REDIRECT_MESSAGES.REDIRECT, panelName), { panelTitle });
    const panelNumber = panels.findIndex((panel) => panel.id === backPanel?.id) + 1;

    const getTemplateMetaData = () => {
        const textMetaData: TemplateTextItem[] = [];
        backPanel?.items.forEach((item) => {
            if (item.isTextAreaItem()) {
                const { content } = item.model;
                if (content.length === 1 && (content[0].content === '\n' || content[0].content === '')) {
                    const metadata = (cimDocStore.asJson.metadata?.template ?? []).find(
                        (templateItem) => templateItem.id === item.id
                    ) as TemplateTextItem;

                    textMetaData.push(metadata);
                }
            }
        });

        const placeholderImageMetaData = (cimDocStore.asJson.metadata?.template ?? [])
            .filter(isPlaceholderTemplateItem)
            .filter((templateItem) => templateItem.intent === 'QR Code');

        return [...textMetaData, ...placeholderImageMetaData];
    };

    useEffect(() => {
        const templateData = getTemplateMetaData();
        setTemplateMetaData(templateData);
    }, [cimDocStore.asJson, backPanel?.items]);

    return { templateMetaData, redirectText, panelNumber, getTemplateMetaData };
};
