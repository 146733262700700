import { useCallback } from 'react';
import { Item } from '@design-stack-vista/cdif-types';
import { ItemState } from '@design-stack-vista/cimdoc-state-manager';
import { IDAItemLayoutExtension, useDesignEngine } from '@design-stack-vista/core-features';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { useActivePanel } from '../../../hooks/useActivePanel';
import { useDesignPreviewData } from '../../../hooks/useDesignPreviewData';
import { usePreview } from '../../../providers/PreviewProvider';
import { ACTION_NAME } from '../../TrackingEvents/constant';
import { GA_EVENTS } from '../../TrackingEvents/GAEvents';

export const useDesignPreview = () => {
    const { fireEvent } = useCustomEventPropagation();
    const { setPreviewLoader, is3DView } = usePreview();
    const { getDesignPreview } = useDesignPreviewData();
    const { designExtensionSystem } = useDesignEngine();
    const activePanel = useActivePanel();
    const imageItem = activePanel?.items?.find((item) => item.isImageItem()) as ItemState<Item>;
    const layout = designExtensionSystem.getExtension(imageItem?.iid, IDAItemLayoutExtension);
    const previewType = is3DView ? '3D' : '2D';

    const { showPreviewModal, setShowPreviewModal } = usePreview();
    const onClose = useCallback(() => {
        setShowPreviewModal(false);
    }, [setShowPreviewModal]);

    useAsyncEffect(
        (helpers) => {
            setPreviewLoader(true);
            getDesignPreview(helpers.abortSignal).then(() => setPreviewLoader(false));
        },
        [layout?.position, layout?.rotation, layout?.previewBox]
    );

    const handleButtonClick = useCallback(() => {
        setShowPreviewModal(true);
        fireEvent({
            name: ACTION_NAME.DESIGN_TOOL_USED,
            extraData: {
                eventDetail: `${GA_EVENTS.CLICK_RIGHT_PANEL_PREVIEW}:${previewType}:${activePanel?.panelProperties.name}`,
            },
        });
    }, [previewType, activePanel?.panelProperties.name, setShowPreviewModal]);

    return { showPreviewModal, handleButtonClick, onClose };
};
