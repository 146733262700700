// Hooks
import { Payload, useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';

/**
 * TODO: Fix: experimentName && variationKey condition, when experimentName config is not present
 */

// import { useProductConfiguration } from '../calcifer';
// import { ProductConfigurationContext } from '../calcifer/useProductConfiguration';

// Context
// import { useAbTestContext } from '../providers/ABTestProvider';

// Constants
import { ACTION_NAME } from '../features/TrackingEvents/constant';

// Helpers
// import { experimentConfig } from '../trackingEvents/experimentConfig';

export const useTrackExperimentClick = () => {
    const { fireEvent } = useCustomEventPropagation();
    // const { productData }: ProductConfigurationContext = useProductConfiguration();

    // const ABTest = useAbTestContext();
    // const productKey = productData?.productKey || '';
    // const experimentName = experimentConfig(productKey).experimentName;
    // const variationKey = ABTest?.getVariation(experimentName);

    const trackExperimentClick = (trackingData: Payload) => {
        // if (experimentName && variationKey) {
        fireEvent({
            name: ACTION_NAME.EXPERIMENT_CLICKED,
            extraData: trackingData,
        });
        // }
    };

    return { trackExperimentClick };
};
