import React from 'react';
import { QuadHeader } from './QuadHeader';
import { TeamsPromoteModal } from './Teams/TeamsPromoteModal';
import { useBookendHeaderData } from '../../../hooks/useBookendHeaderData';
import { HeaderSkeleton } from '../../LandingPageSkeleton/HeaderSkeleton';
import { HeaderInfoProvider } from '../HeaderInfoProvider';

export const Header = (): JSX.Element => {
    const bookEndHeaderData = useBookendHeaderData();

    // UI Seems to jump on initial load as bookEndHeaderData is undefined
    // Hence returning HeaderSkeleton so show loading state
    if (!bookEndHeaderData) {
        return <HeaderSkeleton />;
    }

    return (
        <HeaderInfoProvider headerData={bookEndHeaderData}>
            <QuadHeader />
            <TeamsPromoteModal />
        </HeaderInfoProvider>
    );
};
