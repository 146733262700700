import React from 'react';
import { PostCardMailable } from './PostCardMailable';
import { PostCardMailingModal } from './PostCardMailingModal';
import { PostCardSuccessModal } from './PostCardSuccessModal';

export const PostCardModals = (): JSX.Element => {
    return (
        <PostCardMailable>
            <PostCardSuccessModal />
            <PostCardMailingModal />
        </PostCardMailable>
    );
};
