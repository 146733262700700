import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { CustomEventPropagationProvider, ExtendedCustomEventData } from '@design-stack-vista/custom-event-propagation';
import { sendEventTracking } from './Analytics';
import { QUAD_INSTANCE, QUAD_UNIQUE_SESSION_ID, TRACKING_TENANT, USER_SESSION_ID } from './constant';
import { AnalyticsEventData, AnalyticsPageData, SegmentEventData } from './type';
import { getAnalyticsPageData } from './utils';
import { APPLICATION_NAME } from '../../config/constant';
import { ProductOptionsTrackingProvider } from '../../providers/ProductOptionsTrackingProvider';
import { TrackingProvider as VcsTrackingProvider } from '../VCS/TrackingEvents/TrackingProvider';
import { isVCSConsumer } from '../VCS/utils/isVCSConsumer';

export interface TrackingEventAnalytics {
    productKey: string;
    productVersion: number;
    productName: string;
    pageName: string;
    productMpVId: string;
    language: string;
    locale: string;
    timeSinceLoad: number;
    workId: string | null;
    experimentId: string;
    experimentName: string;
    variationId: string;
    variationName: string;
}

interface TrackEvenAnalyticsWithSessionData extends TrackingEventAnalytics {
    qmReplayLink?: string;
    quadUniqueSession: string;
    quadUserSession: string;
}

interface TrackingContextProps {
    trackDataSession: TrackEvenAnalyticsWithSessionData;
    analyticsPageData: AnalyticsPageData | undefined;
    setTrackingPage: React.Dispatch<React.SetStateAction<string | null>>;
}

export const TrackingContext = createContext<TrackingContextProps | null>(null);

interface TrackingProviderProps {
    children: React.ReactNode | React.ReactNode[];
    trackingData: TrackingEventAnalytics;
}

/**
 * Reference to get generalTrackingData
 * https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/master/src/studioFive/tracking/generalTrackingData.js
 */
export const TrackingProvider = ({ trackingData, children }: TrackingProviderProps) => {
    const [trackingPage, setTrackingPage] = useState<string | null>(null);
    const userSessionId = (window.tracking && window.tracking.visit && window.tracking.visit()) || USER_SESSION_ID;

    const trackDataSession: TrackEvenAnalyticsWithSessionData = useMemo(() => {
        return {
            quadUserSession: userSessionId,
            quadUniqueSession: QUAD_UNIQUE_SESSION_ID,
            ...trackingData,
        };
    }, [trackingData]);

    const {
        pageName,
        experimentId,
        experimentName,
        variationId,
        variationName,
        productMpVId,
        productName,
        productKey,
        productVersion,
        timeSinceLoad,
        workId,
    } = trackingData;

    const analyticsPageData: AnalyticsPageData = useMemo(() => {
        return getAnalyticsPageData(productMpVId, trackingPage);
    }, [productMpVId, trackingPage]);

    const trackingEventHandler = useCallback(
        (data: ExtendedCustomEventData) => {
            const analyticsEventData: SegmentEventData = {
                ...(data.extraData as unknown as AnalyticsEventData),
                action: data.name,
                pageName,
                trackingTenant: TRACKING_TENANT,
                experimentId,
                experimentName,
                variationId,
                variationName,
                product_id: productMpVId,
                name: productName,
                core_product_id: productKey,
                core_product_version: productVersion,
                experimentDetail: JSON.stringify({
                    timeSinceLoad,
                    workId: workId?.toString(),
                    quadInstance: QUAD_INSTANCE,
                    ...(data.extraData as unknown as AnalyticsEventData).experimentDetail,
                }),
            };
            sendEventTracking({ analyticsEventData, analyticsPageData });
        },
        [
            pageName,
            experimentId,
            experimentName,
            variationId,
            variationName,
            productMpVId,
            productName,
            productKey,
            productVersion,
            timeSinceLoad,
            analyticsPageData,
            workId,
        ]
    );

    const contextData = useMemo(() => {
        return { trackDataSession, analyticsPageData, setTrackingPage };
    }, [trackDataSession, analyticsPageData]);

    if (isVCSConsumer()) {
        return <VcsTrackingProvider>{children}</VcsTrackingProvider>;
    }

    return (
        <TrackingContext.Provider value={contextData}>
            <ProductOptionsTrackingProvider>
                <CustomEventPropagationProvider
                    enableGlobalSharing={true}
                    callback={trackingEventHandler}
                    name={APPLICATION_NAME}
                >
                    {children}
                </CustomEventPropagationProvider>
            </ProductOptionsTrackingProvider>
        </TrackingContext.Provider>
    );
};

export const useTrackingAnalytics = () => {
    const trackingContext = useContext(TrackingContext);
    return trackingContext;
};
