import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, Typography } from '@vp/swan';
import { DecorationAreaPricing } from './DecorationAreaPricing';
import { ProductAttributes } from '../../../config/constant';
import { useTranslatedConfig } from '../../../hooks/useTranslatedConfig';
import { OptionButton } from '../../Flexibility/common';

const decorationAreaItemStyle = css`
    width: max-content;
`;

interface DecorationAreaItemProps {
    decorationArea: string;
    activeDecorationArea: string | null;
    onClick: () => void;
}

export const DecorationAreaItem = ({ decorationArea, activeDecorationArea, onClick }: DecorationAreaItemProps) => {
    const { getTranslatedOptionName } = useTranslatedConfig();

    const selected = decorationArea === activeDecorationArea;
    const decorationAreaLabel = getTranslatedOptionName(ProductAttributes.DecorationArea, decorationArea);

    return (
        <OptionButton selected={selected} onClick={onClick} showActiveIcon={false}>
            <FlexBox flexDirection="column" alignItems="flex-start" className={decorationAreaItemStyle}>
                <Typography fontWeight="bold" fontSize="small" mb="2">
                    {decorationAreaLabel}
                </Typography>
                <DecorationAreaPricing decorationArea={decorationArea} />
            </FlexBox>
        </OptionButton>
    );
};
