import React from 'react';
import { css } from '@emotion/css';
import { className, tokens, Typography } from '@vp/swan';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';

const bestFitTagStyle = css`
    width: 55px;
    height: 22px;
    border-radius: ${tokens.SwanSemBorderRadiusSubtle};
`;

const desktopTagStyle = css`
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 ${tokens.SwanSemBorderRadiusSubtle};
`;

export const BestFitTag = () => {
    const { isSmall } = useScreenLayout();

    return (
        <Typography
            textColor="standard"
            bgc="holiday-green-1"
            className={className(bestFitTagStyle, { [desktopTagStyle]: !isSmall })}
            fontSize="xsmall"
            fontWeight="normal"
            darkMode
            textAlign="center"
            py="1"
            px="2"
        >
            {text('bestFit')}
        </Typography>
    );
};
