import React from 'react';
import { css } from '@emotion/css';
import {
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
    tokens,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { SubstrateColorPanel } from './SubstrateColorPanel';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';
import { substrateColorStore } from '../SubstrateColorStore';

const getSubstrateColorModalStyle = (isSmall: boolean) => css`
    .swan-modal-dialog {
        padding: ${isSmall ? tokens.SwanSemSpace4 : tokens.SwanSemSpace6};
        padding-top: 0;
        max-width: 440px;
        .swan-modal-dialog-nav {
            ${isSmall
                ? `margin: 0 calc(${tokens.SwanSemSpace4} * -1) 0 0;`
                : `margin: 0 calc(${tokens.SwanSemSpace8} * -1) 0 0;`}
        }
        &.swan-modal-dialog-panel .swan-modal-dialog-header {
            margin-bottom: ${isSmall ? tokens.SwanSemSpace5 : tokens.SwanSemSpace7};
        }
    }

    // This may not be required after upgrading to swan 3.VL
    ${isSmall &&
    `
        .swan-modal-dialog.swan-modal-dialog-panel.swan-modal-dialog-panel-bottom {
            border-radius: ${tokens.SwanSemBorderRadiusContainer} ${tokens.SwanSemBorderRadiusContainer} 0 0;
        }
        `}
`;

export const SubstrateColorModal = observer(() => {
    const { isSmall } = useScreenLayout();
    const { isColorSwatchModalOpen, setIsColorSwatchModalOpen } = substrateColorStore;

    const onRequestDismiss = () => {
        setIsColorSwatchModalOpen(false);
    };
    return (
        <ModalDialog
            variant={isSmall ? 'panel-bottom' : 'panel-right'}
            bodyWidth="capped"
            isOpen={isColorSwatchModalOpen}
            onRequestDismiss={onRequestDismiss}
            className={getSubstrateColorModalStyle(isSmall)}
            onlyRenderWhenOpen
        >
            <ModalDialogContent aria-label={text('chooseMaterialColor')}>
                <ModalDialogNav>
                    <ModalDialogCloseButton accessibleText={text('closeAccessibilityLabel')} />
                </ModalDialogNav>
                <ModalDialogHeader>
                    <ModalDialogTitle>{isSmall ? text('materialColor') : text('chooseMaterialColor')}</ModalDialogTitle>
                </ModalDialogHeader>
                <ModalDialogBody>
                    <SubstrateColorPanel renderedOnModalOpen={isColorSwatchModalOpen} />
                </ModalDialogBody>
            </ModalDialogContent>
        </ModalDialog>
    );
});
