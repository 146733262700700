import { CimDoc } from '@design-stack-vista/cdif-types';
import { PanelState, removeItems } from '@design-stack-vista/cimdoc-state-manager';
import { hydratePlaceholdersIntoCimdoc } from '@design-stack-vista/studio-document-metadata-management';
import { getPanelItemIds } from '../../../utils';
import { ColorMode } from '../../../utils/upsell';
import {
    addOrUpdateMetadataDocumentSources,
    addTemplateOrPlaceholderMetaData,
    removeTemplateAndPlaceholderMetaData,
} from '../../quad/commands/addPanelMetadata';
import { updatePanelColorMode } from '../../quad/commands/updatePanelColorMode';

const replacePanelItem = <T>(targetArray?: T[], sourceArray?: T[]) => {
    if (sourceArray && targetArray) {
        targetArray.push(...sourceArray);
    }
};

const addDesignTemplateToPanel = (
    cimDoc: CimDoc,
    { panelId, designCimDoc }: { panelId: string; designCimDoc: CimDoc }
) => {
    const panel = cimDoc.document.panels.find((panel) => panel.id === panelId);
    if (!panel) throw new Error('Cannot find target panel');

    const designPanel = designCimDoc.document.panels[0];

    replacePanelItem(panel.shapes, designPanel.shapes);
    replacePanelItem(panel.images, designPanel.images);
    replacePanelItem(panel.textAreas, designPanel.textAreas);
    replacePanelItem(panel.itemReferences, designPanel.itemReferences);
    replacePanelItem(panel.subpanels, designPanel.subpanels);

    const { metadata } = designCimDoc;
    if (metadata) {
        if (metadata.placeholders) {
            // Reverse the canvases index map to panels index
            metadata.placeholders.canvases = metadata.placeholders.canvases.reverse();
        }

        addTemplateOrPlaceholderMetaData(cimDoc, {
            templateMetaData: metadata.template,
            placeholdersMetaData: metadata.placeholders,
        });
    }
};

export const setDesignTemplateToPanel = (
    cimDoc: CimDoc,
    {
        panel,
        designCimDoc,
    }: {
        panel: PanelState;
        designCimDoc: CimDoc;
    }
) => {
    updatePanelColorMode(cimDoc, { panelId: panel.id, colorMode: ColorMode.Color });
    addOrUpdateMetadataDocumentSources(cimDoc, { panelId: panel.id, sourceType: 'FULLBLEED' });
    if (panel.items.length) {
        const itemIds = getPanelItemIds(panel);
        removeItems(cimDoc, { ids: itemIds });
    }
    removeTemplateAndPlaceholderMetaData(cimDoc);
    addDesignTemplateToPanel(cimDoc, { panelId: panel.id, designCimDoc });
    hydratePlaceholdersIntoCimdoc(cimDoc);
};
