import { MaskType, Path } from '@design-stack-vista/ida-framework';

export interface PreviewItemType {
    url: string;
    title: string;
}

// Defined this type with boundingArea dimensions to resolve type issue in useQuickTools.
export interface Masks {
    type: MaskType;
    paths: Path[];
    boundingArea: {
        type: string;
        position: {
            x: number;
            y: number;
            width: number;
            height: number;
        };
    };
}

// Defined svgProps for svg icons.

export type SvgProps = React.SVGProps<SVGSVGElement>;

// Defined this type for bookend slim header logo and text.

export type BookendHeaderDataType = {
    base: {
        inlineNavburger: {
            image: {
                contents: string;
            };
        };
        inlineSecondaryLogo: {
            image: {
                contents: string;
            };
        };
        logo: {
            image?: {
                contents?: string;
                url?: string;
            };
            alt?: string;
            lazy?: boolean;
        };
        host: string;
        className?: string;
        helpLink: {
            text: string;
            url: string;
            image: {
                contents: string;
            };
            analyticsId?: string;
        };
        homeUrl: string;
        cartLink: {
            analyticsId: string;
            image: {
                contents: string;
            };
            text: string;
            url: string;
        };
        myProjectsLink: {
            text: string;
            url: string;
            image: {
                contents: string;
            };
            analyticsId: string;
        };
    };
};

export enum ModalDialogVariant {
    MENU = 'menu',
    PANEL_BOTTOM = 'panel-bottom',
    STANDARD = 'standard',
    PANEL_RIGHT = 'panel-right',
    PANEL_LEFT = 'panel-left',
    PANEL_TOP = 'panel-top',
}
