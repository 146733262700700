import { calculateCoverPosition, cm2mm, Measurement, mod } from '@design-stack-ct/utility-core';
import { CimDoc } from '@design-stack-vista/cdif-types';
import { resizeItem, setRotationDegrees } from '@design-stack-vista/cimdoc-state-manager';
import { IDAItemLayoutExtension, useDesignEngine } from '@design-stack-vista/core-features';
import { Mask } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useActivePanel } from '../../../hooks/useActivePanel';
import { getItemPositionFitOnRotation } from '../../../utils/getItemPositionFitOnRotation';
import { MaskWithBoundingArea } from '../../../utils/normalizeDesignViews';
import { useGetExtension } from '../Extensions';
import { PanelChromesExtension } from '../Extensions/PanelChromesExtension';

export const useQuickTools = () => {
    const designEngine = useDesignEngine();
    const { productData } = useProductConfiguration();
    const activePanel = useActivePanel();
    const {
        idaStore,
        designExtensionSystem,
        layoutStore: { visiblePanels },
    } = designEngine;
    const [currentPanel] = visiblePanels;
    const { masks } = useGetExtension(currentPanel, PanelChromesExtension);

    const fitToSafety = () => {
        const [item] = currentPanel.items;
        const imageItem = designExtensionSystem.getExtension(item.iid, IDAItemLayoutExtension);

        if (productData && imageItem) {
            const newRotation = imageItem.rotation;
            const safetyArea = masks.find((mask: MaskWithBoundingArea) => mask.type === Mask.Safe);
            const safetyAreaDimensions = safetyArea?.boundingArea?.position;

            const targetBoundingBox = safetyAreaDimensions && {
                x: 0,
                y: 0,
                width: cm2mm(safetyAreaDimensions?.width || 0),
                height: cm2mm(safetyAreaDimensions?.height || 0),
            };

            const aspectRatio = imageItem.dimensions.width / imageItem.dimensions.height;
            const fitToSafetyDimensions =
                targetBoundingBox && getItemPositionFitOnRotation(targetBoundingBox, aspectRatio, newRotation);

            if (item && fitToSafetyDimensions && safetyAreaDimensions) {
                designEngine.executeCommand((cimDoc: CimDoc) => {
                    resizeItem(cimDoc, {
                        id: item.id,
                        newHeight: fitToSafetyDimensions.height,
                        newWidth: fitToSafetyDimensions.width,
                        newX: fitToSafetyDimensions.x + cm2mm(safetyAreaDimensions?.x || 0),
                        newY: fitToSafetyDimensions.y + cm2mm(safetyAreaDimensions?.y || 0),
                    });
                }, {});
            }
        }
    };

    const fitToBleed = () => {
        const [item] = currentPanel.items;
        const imageItem = designExtensionSystem.getExtension(item.iid, IDAItemLayoutExtension);
        const panel = designEngine.cimDocStore.panels.find((p) => activePanel && p.id === activePanel.id);

        if (!panel) {
            throw Error('Cannot find target panel that the upload should be added to');
        }
        if (imageItem) {
            const { rotation } = imageItem;

            const panelDimension = {
                width: new Measurement(panel.panelProperties.width).mm,
                height: new Measurement(panel.panelProperties.height).mm,
            };

            const coverPosition =
                rotation === 90 || rotation === 270
                    ? calculateCoverPosition(
                          {
                              width: imageItem.dimensions.height,
                              height: imageItem.dimensions.width,
                          },
                          panelDimension
                      )
                    : calculateCoverPosition(
                          {
                              width: imageItem.dimensions.width,
                              height: imageItem.dimensions.height,
                          },
                          panelDimension
                      );

            const targetBoundingBox = {
                x: coverPosition.x,
                y: coverPosition.y,
                width: coverPosition.width,
                height: coverPosition.height,
            };

            const aspectRatio = imageItem.dimensions.width / imageItem.dimensions.height;

            const fitToBleedDimensions = getItemPositionFitOnRotation(targetBoundingBox, aspectRatio, rotation);

            if (item && fitToBleedDimensions) {
                designEngine.executeCommand((cimDoc: CimDoc) => {
                    const roundedDimensions = {
                        newHeight: fitToBleedDimensions.height,
                        newWidth: fitToBleedDimensions.width,
                        newX: fitToBleedDimensions.x,
                        newY: fitToBleedDimensions.y,
                    };
                    resizeItem(cimDoc, {
                        id: item.id,
                        ...roundedDimensions,
                    });
                }, {});
            }
        }
    };

    const rotateItems = () => {
        const [item] = currentPanel.items;
        const imageItem = designExtensionSystem.getExtension(item.iid, IDAItemLayoutExtension);

        if (item && imageItem) {
            const { rotation } = imageItem;

            designEngine.executeCommand(setRotationDegrees, {
                id: item.id,
                degrees: mod(rotation + 90, 360),
            });
            idaStore.setTemporaryRotationDelta(0);
        }
    };

    return { fitToSafety, fitToBleed, rotateItems };
};
