export const PREMIUM_FINISH_ASSETS: { [key: string]: string } = {
    embossedGlossPreviewVideo:
        'https://cms.cloudinary.vpsvc.com/video/upload/c_scale,dpr_auto,f_auto,q_auto,t_productPageHeroGalleryTransformationCroppedToTheLeft,w_auto,e_volume:mute/MXP-14924-Embossed-Gloss-BC-Video-Animation-001.mp4',
    foilAccentPreviewVideo:
        'https://cms.cloudinary.vpsvc.com/video/upload/c_scale,dpr_auto,f_auto,q_auto,t_productPageHeroGalleryTransformationCroppedToTheLeft,w_auto,e_volume:mute/MXP-28-Business-Card-PDP-animated-Heroes-001.mp4',
    noPremiumPreviewImage: `${process.env.PUBLIC_URL}/no-finish.png`,
    embossedGlossThumbnail:
        'https://cms.cloudinary.vpsvc.com/images/c_scale,dpr_auto,f_auto,h_200,w_200/legacy_dam/en-us/S001553360/MXP14255-T5_embossed_gloss?cb=f3ef5ac146fc805fcae1acf18fd48136b4e55a75',
    foilAccentThumbnail:
        'https://cms.cloudinary.vpsvc.com/images/c_scale,dpr_auto,f_auto,h_200,w_200/legacy_dam/en-us/S001553318/MXP14255-T5_foil-metallic?cb=bf756080e01c7d345f0c46e76bf5f3e7d84aef8e',
    noPremiumThumbnail:
        'https://cms.cloudinary.vpsvc.com/images/c_scale,dpr_auto,f_auto,h_200,w_200/legacy_dam/en-us/S001283055/none-finish-002?cb=e4ae49782b36a9b18eb57e7dfeb708780a707dd4',
    foilAccentTextOnlyThumbnail:
        'https://www.vistaprint.com/studio-assets/fullBleedPremiumFinishModal/foilAccent-text-only-001.png',
    foilAccentImageOnlyThumbnail:
        'https://www.vistaprint.com/studio-assets/fullBleedPremiumFinishModal/foil-accent-image-only-001.png',
    foilAccentTextAndImageThumbnail:
        'https://www.vistaprint.com/studio-assets/fullBleedPremiumFinishModal/foil-accent-image-text-001.png',
    embossedGlossTextOnlyThumbnail:
        'https://www.vistaprint.com/studio-assets/fullBleedPremiumFinishModal/spot-gloss-text-only-001.png',
    embossedGlossImageOnlyThumbnail:
        'https://www.vistaprint.com/studio-assets/fullBleedPremiumFinishModal/spot-gloss-image-only-001.png',
    embossedGlossTextAndImageThumbnail:
        'https://www.vistaprint.com/studio-assets/fullBleedPremiumFinishModal/spot-gloss-image-text-001.png',
};
