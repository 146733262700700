import React from 'react';
import { VortexCanvas } from '@rendering/vortex-react';
import { ThreeDPreviewLoader } from './ThreeDPreviewLoader';
import { text } from '../../utils/localization';

export const ThreeDPreview = (): JSX.Element => {
    return (
        <>
            <ThreeDPreviewLoader />
            <VortexCanvas aria-labelledby="3d-preview" data-testid="vortex-preview">
                <p id="3d-preview">{text('3dDesignPreview')}</p>
            </VortexCanvas>
        </>
    );
};
