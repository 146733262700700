import React from 'react';
import { css } from '@emotion/css';
import { Button, Divider, FlexBox, tokens } from '@vp/swan';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';

const getChangeProductButtonStyle = (isSmall: boolean) => css`
    width: 100%;
    border-top: ${tokens.SwanSemElevationStrong};
    ${isSmall &&
    `
        position: sticky;
        bottom: 0;
        .swan-button {
            height: 40px;
        }
    `}
`;

interface FlexibilityChangeButtonProps {
    changeButtonText: string;
    onChangeButtonClick: () => Promise<void>;
}

export const FlexibilityChangeButton = ({ changeButtonText, onChangeButtonClick }: FlexibilityChangeButtonProps) => {
    const { isSmall } = useScreenLayout();

    return (
        <FlexBox
            pb={{ xs: '5', sm: '6' }}
            px={{ xs: '5', sm: '6' }}
            data-testid="flexibility-change-button"
            backgroundColor="standard"
            className={getChangeProductButtonStyle(isSmall)}
            flexDirection="column"
            gap={isSmall ? '5' : '6'}
        >
            <Divider />
            <Button
                skin="primary"
                width="full-width"
                size={isSmall ? 'mini' : 'standard'}
                onClick={onChangeButtonClick}
                data-testid="flexibility-change-btn"
            >
                {changeButtonText}
            </Button>
        </FlexBox>
    );
};
