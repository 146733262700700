export const enum UPLOAD_BACK_DESIGN_MODAL_TYPES {
    UPLOAD_ASSET = 'UPLOAD_ASSET',
    REVIEW_CONFIRMATION = 'REVIEW_CONFIRMATION',
}

export interface TextConfig {
    footerButtonSkin: 'primary' | 'secondary';
    subHeadingText: string;
    footerButtonText: string;
}

export const UPLOAD_ASSET_MODAL_TEXT_CONFIG: TextConfig = {
    subHeadingText: 'addBackDesignSubHeading',
    footerButtonText: 'cancelButton',
    footerButtonSkin: 'secondary',
};

export const REVIEW_CONFIRMATION_MODAL_TEXT_CONFIG: TextConfig = {
    subHeadingText: 'addBackDesignSubHeading2',
    footerButtonText: 'leaveBlankBtn',
    footerButtonSkin: 'primary',
};
