import React from 'react';
import { css } from '@emotion/css';
import { CanvasSelectorButton } from './CanvasSelectorButton';
import { MobileExperience } from '../../components/Experiences/MobileExperience';
import { PREVIEW_BUTTON_HEIGHT } from '../../config/constant';

const canvasSelectorMobileStyle = css`
    position: absolute;
    bottom: calc(${PREVIEW_BUTTON_HEIGHT} + 24px);
`;

export const CanvasSelectorMobile = () => {
    return (
        <MobileExperience>
            <div className={canvasSelectorMobileStyle}>
                <CanvasSelectorButton />
            </div>
        </MobileExperience>
    );
};
