import React from 'react';
import { Button, FlexBox, PopoverCloser, Typography } from '@vp/swan';
import { text } from '../../../utils/localization';

interface Props {
    onFinishIconClick: () => void;
    onPopoverDeleteButtonClick: () => void;
}

export const ChangeFinishButtons = ({ onFinishIconClick, onPopoverDeleteButtonClick }: Props) => {
    return (
        <PopoverCloser>
            <FlexBox justifyContent="space-between">
                <Button
                    data-testid="change-button"
                    width="full-width"
                    mr="3"
                    skin="primary"
                    size="mini"
                    onClick={onFinishIconClick}
                >
                    <Typography fontSize="xsmall" fontWeight="bold">
                        {text('change')}
                    </Typography>
                </Button>
                <Button
                    data-testid="finish-delete-button"
                    width="full-width"
                    skin="secondary"
                    size="mini"
                    onClick={onPopoverDeleteButtonClick}
                >
                    <Typography fontSize="xsmall" fontWeight="bold">
                        {text('deleteButton')}
                    </Typography>
                </Button>
            </FlexBox>
        </PopoverCloser>
    );
};
