import React, { useMemo } from 'react';
import { Callout, FlexBox, ModalDialogHeader, Typography } from '@vp/swan';
import { usePanelTitle } from '../../hooks/usePanelTitle';
import { useProductParams, useUpsellPrice } from '../../providers';
import { DifferentialPricingComponent } from '../DifferentialPricingComponent';

interface UploadModalHeaderProps {
    panelId: string;
    panelName: string;
}

export const UploadModalHeader = ({ panelId, panelName }: UploadModalHeaderProps) => {
    const { getUploadModalHeaderText } = usePanelTitle();
    const { selectedQuantity: quantity, locale } = useProductParams();
    const { getPricingByPanelName, isUpsellPanelAdded } = useUpsellPrice();

    const isUpsellAdded = useMemo(() => isUpsellPanelAdded(panelId), [panelId]);
    const upsellPricing = useMemo(() => getPricingByPanelName(panelName), [panelName]);
    const showAdditionalPrice = !isUpsellAdded && upsellPricing && quantity;

    const uploadModalHeaderText = useMemo(() => getUploadModalHeaderText(panelName), [panelName]);

    return (
        <ModalDialogHeader mb="5">
            <FlexBox gap="3" flexWrap="wrap">
                <Typography
                    pl={{ xs: '5', sm: '0' }}
                    fontSize="x2large"
                    fontWeight="bold"
                    data-testid="upload-modal-header"
                >
                    {uploadModalHeaderText}
                </Typography>
                {showAdditionalPrice && (
                    <Callout skin="success">
                        <DifferentialPricingComponent
                            pricing={upsellPricing.listPrice !== 0 ? upsellPricing : undefined}
                            locale={locale}
                            currency={upsellPricing.currency}
                            quantity={quantity}
                        />
                    </Callout>
                )}
            </FlexBox>
        </ModalDialogHeader>
    );
};
