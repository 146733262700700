/*
 *Based on product dimension , adjust viewport zoom level
 *ref link: https://vistaprint.atlassian.net/wiki/spaces/PL/pages/3156616136/Studio+Zoom+configuration
 */

export const getHeightZoomRatio = ({
    underlaySceneHeight,
    decorationAreaHeight,
}: {
    underlaySceneHeight: number;
    decorationAreaHeight: number;
}): number => {
    const heighRatio = decorationAreaHeight / underlaySceneHeight;
    let ratio = 1;
    if (heighRatio > 0 && heighRatio <= 0.25) {
        ratio = 0.25;
    } else if (heighRatio > 0.25 && heighRatio <= 0.4) {
        ratio = 0.4;
    } else if (heighRatio > 0.4 && heighRatio <= 0.5) {
        ratio = 0.5;
    } else if (heighRatio > 0.5 && heighRatio <= 0.6) {
        ratio = 0.6;
    } else if (heighRatio > 0.6 && heighRatio <= 0.75) {
        ratio = 0.75;
    }

    return ratio;
};
