import { useMemo } from 'react';
import { CompatibleOptionsEntry } from '../../../api/catalog';
import { ProductAttributes } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { ROLL_LABELS_MPV_ID } from '../constants';

export const useShapeUtils = () => {
    const { productData, secondaryConfig } = useProductConfiguration();
    const isRollLabels = productData?.mpvId === ROLL_LABELS_MPV_ID;
    const isShapeFeatureEnable = isRollLabels;
    const compatibleOptions = secondaryConfig?.compatibleOptions;

    const hasShapes = useMemo(() => {
        const shapeOption = compatibleOptions?.find(
            (option: CompatibleOptionsEntry) => option.name === ProductAttributes.Shape
        );

        return shapeOption?.values?.length && shapeOption?.values?.length > 1;
    }, [compatibleOptions]);

    return { hasShapes, isShapeFeatureEnable };
};
