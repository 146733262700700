declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newrelic: any;
    }
}

// https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/add-page-action/
export function addPageAction(name: string, attributes: unknown): void {
    if (window && window.newrelic && window.newrelic.addPageAction) {
        window.newrelic.addPageAction(name, attributes);
    }
}

// https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api
export function noticeError(error: unknown, attributes: unknown): void {
    if (window && window.newrelic && window.newrelic.noticeError) {
        window.newrelic.noticeError(error, attributes);
    }
}
