import type { ReactNode } from 'react';
import React from 'react';
import type { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { DesignLayer, StrokeMask } from '@design-stack-vista/ida-framework';
import { css } from '@emotion/css';
import { tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { FillMask } from './FillMask/FillMask';
import { DESIGN_MASK_CONFIGURATION, Mask } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { getUnzoomedPanelDimensions } from '../../../utils';
import { PanelChromesExtension, useGetExtension } from '../Extensions';

export interface PresentationalDesignMarginsProps {
    panel: PanelState;
    mapMask?: (panel: PanelState, color?: string) => ReactNode | ReactNode[];
    color?: string;
    className?: string;
}

const dashLength = 10;

const preview_panel_stroke_width = 2;

const safe_stroke_color = tokens.SwanBaseColorGreen700;
const safe_alternative_color = tokens.SwanBaseColorWhite;
const trim_alternative_color = tokens.SwanBaseColorWhite;
const getFillMaskOutlineStyle = (strokeWidth: number, color?: string) => css`
    path {
        stroke: ${color ? tokens.SwanBaseColorGrey100 : DESIGN_MASK_CONFIGURATION.BLEED.backgroundColor};
        stroke-width: ${color ? preview_panel_stroke_width : strokeWidth};
    }

    .idaf-curve-mask--BLEED {
        fill: none;
    }
`;

function MapMaskOverlays(panel: PanelState, color?: string) {
    const { productData } = useProductConfiguration();

    const { masks, strokeWidth } = useGetExtension(panel, PanelChromesExtension);
    if (productData && masks && strokeWidth) {
        const { width, height } = getUnzoomedPanelDimensions(panel);
        const firstBleedMaskIndex = masks.findIndex((mask) => mask.type === 'BLEED');

        return masks.map((mask, index) => {
            switch (mask.type) {
                case Mask.Bleed:
                    /*
                The first bleed path should have the evenodd rule applied, but whenever there are more bleed paths,
                then they should not have a border around them.
            */
                    return (
                        DESIGN_MASK_CONFIGURATION.BLEED.enabled && (
                            <FillMask
                                key={mask.type}
                                width={width}
                                height={height}
                                mask={mask}
                                useBorder={index === firstBleedMaskIndex}
                                className={getFillMaskOutlineStyle(strokeWidth, color)}
                                style={{ overflow: 'visible' }}
                                fillColor={tokens.SwanBaseColorGrey100}
                            />
                        )
                    );
                case Mask.Trim:
                    return (
                        DESIGN_MASK_CONFIGURATION.TRIM.enabled && (
                            <StrokeMask
                                key={mask.type}
                                width={width}
                                height={height}
                                mask={mask}
                                strokeWidth={strokeWidth}
                                dashLength={dashLength}
                                alternateStrokeColor={color ?? trim_alternative_color}
                                strokeColor={color ?? safe_stroke_color}
                            />
                        )
                    );
                case Mask.Safe:
                    return (
                        DESIGN_MASK_CONFIGURATION.SAFE.enabled && (
                            <StrokeMask
                                key={mask.type}
                                width={width}
                                height={height}
                                mask={mask}
                                strokeWidth={strokeWidth}
                                strokeColor={color ?? safe_stroke_color}
                                alternateStrokeColor={color ?? safe_alternative_color}
                                dashLength={dashLength}
                            />
                        )
                    );
                default:
                    return (
                        <StrokeMask
                            key={mask.type}
                            width={width}
                            height={height}
                            mask={mask}
                            strokeWidth={strokeWidth}
                        />
                    );
            }
        });
    }
}

export const PanelMasksDesignLayer = observer(
    ({ panel, mapMask = MapMaskOverlays, className, color }: PresentationalDesignMarginsProps) => (
        <DesignLayer name="panel-masks" className={className}>
            {mapMask(panel, color)}
        </DesignLayer>
    )
);
