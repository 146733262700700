import React, { useMemo } from 'react';
import { FlexBox, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DesktopExperience, MobileExperience } from '../../components/Experiences';
import { SparkleIcon } from '../../components/SvgIcons';
import { ICON_COLOR } from '../../config/ColorConstant';
import { ProductAttributes } from '../../config/constant';
import { useProductConfiguration } from '../../hooks/calcifer';
import { useQuadProductConfig } from '../../hooks/useQuadProductConfig';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { useTranslatedConfig } from '../../hooks/useTranslatedConfig';
import { usePremiumFinishStore } from '../../providers';
import { ChangeSizeButton } from '../ChangeSize';
import { DecorationAreaButton } from '../DecorationArea';

export const ProductProperties = observer(() => {
    const { isSmall } = useScreenLayout();
    const { productData } = useProductConfiguration();
    const { isPremiumFinishProduct } = usePremiumFinishStore();
    const { getCurrentTranslatedOptionName } = useTranslatedConfig();
    const { customProductConfig } = useQuadProductConfig();

    const productKey = productData?.productKey ?? '';

    const productAttributes = useMemo(() => {
        /**
         * For some products size may not be shown because of two reasons:
         * 1. Product may have size in calcifer response but PDP page does not offer size selection
         * 2. Calcifer has product size and not canvas size(which is what needs to be displayed)
         * E.G. T-Shirts have size XL, 2XL..., in calcifer response but these are not available on PDP and neither they represent canvas size
         */
        const dimensionAttribute = customProductConfig[productKey]?.disableSizeAttribute
            ? ''
            : getCurrentTranslatedOptionName(ProductAttributes.Size);
        const configurableAttribute =
            getCurrentTranslatedOptionName(ProductAttributes.Orientation) ||
            getCurrentTranslatedOptionName(ProductAttributes.DecorationArea) ||
            getCurrentTranslatedOptionName(ProductAttributes.DesignableArea);

        return configurableAttribute && dimensionAttribute
            ? `${configurableAttribute} | ${dimensionAttribute}`
            : configurableAttribute || dimensionAttribute || '';
    }, [getCurrentTranslatedOptionName, productKey, customProductConfig]);

    return productData ? (
        <FlexBox alignItems="center" justifyContent="space-between" flexWrap="nowrap" gap="3">
            <FlexBox data-testid="product-properties" flexDirection="column" gap={isSmall ? '3' : '0'}>
                <FlexBox gap="2">
                    <Typography noWrap fontWeight="bold" fontSize={isSmall ? 'large' : 'standard'}>
                        {productData.productName}
                    </Typography>
                    {isPremiumFinishProduct && (
                        <MobileExperience>
                            <SparkleIcon fill={ICON_COLOR.SPARKLE} width={16} height={16} />
                        </MobileExperience>
                    )}
                </FlexBox>
                <Typography noWrap fontSize={isSmall ? 'standard' : 'small'}>
                    {productAttributes}
                </Typography>
            </FlexBox>
            <DesktopExperience>
                <>
                    <ChangeSizeButton />
                    <DecorationAreaButton />
                </>
            </DesktopExperience>
        </FlexBox>
    ) : null;
});
