/**
 * Reference -
 *  How update cart functionality work - {@link https://vistaprint.atlassian.net/wiki/spaces/DTT/pages/2609219142/Updating+Cart+from+Studio}
 * {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/master/src/shared/utils/Cart/cartUtilities.ts}
 */

import qs from 'qs';
import { callCatch } from '../api/catch';
import { callWatch } from '../api/watch';
import { REQUESTOR, Tenants } from '../config/constant';
import { CartItems, CartVersion, GetItemsFromCart, LineItem, UpdateCartParams } from '../types/cart';
import { WorkEntityResponse } from '../types/workEntityTypes';
import { fetchWrapper, getCountryFromLocale } from '../utils';

const VISTA_CART_URL = 'https://vistacart.orders.vpsvc.com';
const LAT_CART_URL = 'https://cart.large-assortment.vpsvc.com';

/**
 * Reference - {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/master/src/shared/utils/Cart/latCartClient.ts}
 */

/**
 * Attempts to add the work entity to cart under the given locale,
 * or updates the work in cart if it's already been added
 * @param {string} locale - the locale of the page
 * @return The output of adding the work to cart
 */
export const callLatCart = async (authorizationHeader: string, locale: string, workId: string) => {
    let url = `${LAT_CART_URL}/v1/upsert/${workId}?tenant=${Tenants.VistaprintProd}&requestor=${REQUESTOR}`;
    if (locale) {
        url += `&locale=${locale}`;
    }
    const response = await fetchWrapper(url, {
        method: 'POST',
        requestInitiator: 'callLatCart',
        headers: {
            Accept: 'application/json',
            Authorization: authorizationHeader,
        },
    });

    return response;
};

export const callUpsertCart = async (
    authorizationHeader: string,
    locale: string,
    ownerId: string,
    work: WorkEntityResponse,
    selectedProductOptions: Record<string, string>,
    mpvId: string | null
) => {
    const existingCartItems = await getItemsFromCart(authorizationHeader, ownerId, locale);
    const existingItemInCart = getItemInCart(existingCartItems, work.workId);

    // call new cartV2 api only for cart items version: 2 and requires correlationId
    if (
        !existingItemInCart ||
        (existingItemInCart.version === CartVersion.CART_V2 && existingItemInCart.correlationId)
    ) {
        await upsertCartV2(
            authorizationHeader,
            locale,
            ownerId,
            work,
            existingItemInCart?.correlationId || work.workId,
            selectedProductOptions,
            mpvId,
            existingItemInCart
        );
    } else {
        // TODO: remove watch client call when v1 items no longer in use
        // call legacy api WATCH
        await callWatch(authorizationHeader, locale, work.workId);
    }
};

export const updateCart = async ({
    authorizationHeader,
    locale,
    work,
    shopperId,
    anonymousId,
    isQuantityPageEnabled,
    isConnectedCard,
    selectedProductOptions,
    mpvId,
}: UpdateCartParams) => {
    if (isQuantityPageEnabled) {
        await callLatCart(authorizationHeader, locale, work.workId);
    } else if (isConnectedCard) {
        await callCatch(authorizationHeader, locale, work.workId, shopperId, anonymousId);
    } else {
        const ownerId = shopperId || anonymousId;
        await callUpsertCart(authorizationHeader, locale, ownerId, work, selectedProductOptions, mpvId);
    }
};

/**
 * Attempts to add the work entity to cart under the given locale,
 * or updates the work in cart if it's already been added
 * @param {string} locale - the locale of the page
 * @param {string} correlationId - the correlationId of the item
 * @return The output of adding the work to cart
 */

export const upsertCartV2 = async (
    authorizationHeader: string,
    locale: string,
    ownerId: string,
    work: WorkEntityResponse,
    correlationId: string,
    selectedProductOptions: Record<string, string>,
    mpvId: string | null,
    existingItemInCart?: LineItem
) => {
    const url = `${VISTA_CART_URL}/tenants/${getTenant(
        locale
    )}/carts/${ownerId}/v2/items/correlationId/${correlationId}/?requestor=${REQUESTOR}`;

    const selectedAttributes = Object.entries(selectedProductOptions).map(([key, value]) => {
        return { key, value };
    });

    const {
        merchandising: { quantity },
        product: { key: productKey, version: productVersion },
        workId,
        workRevisionId,
    } = work;

    const editDocumentUrl = work.design.editUrl.replace(/\${workId}/, workId);

    const body = {
        quantity,
        product: {
            productKey,
            productVersion,
            selectedAttributes,
            editOptionsUrl: !existingItemInCart
                ? `/pdc/${locale}?workId=${workId}`
                : existingItemInCart?.merchandisingData.editOptionsUrl,
        },
        design: {
            documentReferenceUrl: buildDocRefUrl(work),
            editDocumentUrl,
            livePreviewUrl: undefined, // set to undefined to clear out existing preview url and let cart generate its own
        },
        customOrderData: {
            analytics: {
                // Required for a minimum edit in cart, which references this field rather than the designData
                workId,
                workRevisionId,
                product_id: mpvId, // mpvId required for x sell
                productKey,
            },
            fullfillment: {
                workId, // required for envelopes
            },
        },
    };

    const content = await fetchWrapper(url, {
        method: 'PUT',
        requestInitiator: 'upsertCartV2',
        body,
        headers: {
            Accept: 'application/json',
            Authorization: authorizationHeader,
        },
    });

    return content;
};

export function getTenant(locale: string) {
    return `VP-${getCountryFromLocale(locale)}-PROD`;
}

export const getItemsFromCart: GetItemsFromCart = async (authorizationHeader, ownerId, locale) => {
    const cartUrl = `${VISTA_CART_URL}/tenants/${getTenant(locale)}/carts/${ownerId}`;

    const response = await fetchWrapper(cartUrl, {
        method: 'GET',
        requestInitiator: 'getItemsFromCart',
        headers: {
            From: REQUESTOR,
            Accept: 'application/json',
            Authorization: authorizationHeader,
        },
    });

    return response;
};

export const getItemInCart = (cartItems: CartItems | undefined, workId: string) => {
    const itemFromFulfillment = cartItems?.lineItems?.find(
        (item: LineItem) => item.customOrderData?.fulfillment?.workId === workId
    );
    const itemFromAnalytics = cartItems?.lineItems?.find(
        (item: LineItem) => item.customOrderData?.analytics?.workId === workId
    );
    const itemFromEditDocumentUrl = cartItems?.lineItems?.find((item: LineItem) => {
        // example editDocumentUrl: "/studio/?workId=<WORKID>&editSource=cart"
        const splitUrl = item.designData?.editDocumentUrl?.split('?');

        if (splitUrl && splitUrl.length > 1) {
            const parsed = qs.parse(splitUrl[1]);
            if (parsed.workId === workId) {
                return true;
            }
        }
        return undefined;
    });

    const hasRelatedAccessoryInCart = cartItems?.lineItems.find(
        (lineItem: LineItem) => lineItem.customOrderData.modData?.AccessoryRelationship?.ParentWorkEntityId === workId
    );

    if (hasRelatedAccessoryInCart) {
        return undefined;
    }
    // some properties from VistaCart may be empty so check for workId in any of these
    return itemFromFulfillment || itemFromAnalytics || itemFromEditDocumentUrl;
};

export const getAllCartItems = async (authorizationHeader: string, identity: string, localeLang: string) => {
    return await getItemsFromCart(authorizationHeader, identity, localeLang);
};

export function buildDocRefUrl(work: WorkEntityResponse) {
    if (!work.design.docRefUrl) {
        // derived from how WATCH originally built a documentReferenceUrl
        // https://gitlab.com/vistaprint-org/design-technology/work-add-to-cart-handler/-/blob/master/src/Domain/Cart/CartLineItemBuilder.cs#L92
        const initialUrl = work.design.designUrl.replace(
            'uds.documents.cimpress.io/v0',
            'uds.documents.cimpress.io/v3'
        );
        const url = new URL(initialUrl);
        url.pathname = url.pathname.replace('/+', '/');
        url.pathname = url.pathname.endsWith('/') ? url.pathname : `${url.pathname}/docref`;
        return url.toString();
    }
    return work.design.docRefUrl;
}
