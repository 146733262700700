import React, { useRef } from 'react';
import type { ReactNode } from 'react';
import { useDesignEngine, useSelectedItems } from '@design-stack-vista/core-features';
import { DesignLayer, useLayoutContainers } from '@design-stack-vista/ida-framework';
import { useDrag } from '@use-gesture/react';
import { observer } from 'mobx-react-lite';
import { allowDownPan, allowLeftPan, allowRightPan, allowUpPan } from '../../../utils';

interface MobileScrollLayerProps {
    children?: ReactNode | ReactNode[];
    handleTap: () => void;
}

export const MobileScrollLayer = observer(({ children, handleTap }: MobileScrollLayerProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const { selectedItemIds } = useSelectedItems();
    const { panContainer } = useLayoutContainers();
    const {
        layoutStore: { zoom, initialZoom, offset, setOffset },
    } = useDesignEngine();

    const designAreaRef = document.getElementsByClassName('quad-design-area')[0];
    const currentZoomFactor = zoom / initialZoom;

    useDrag(
        (state) => {
            const { pinching, event, axis, cancel, tap, delta, direction } = state;
            if (tap) {
                handleTap();
                cancel();
                return;
            }
            if (pinching || !panContainer || !designAreaRef) {
                cancel();
                return;
            }
            event.preventDefault();
            const panAreaRect = panContainer.getBoundingClientRect();
            const designAreaRect = designAreaRef.getBoundingClientRect();
            if (
                (axis === 'x' && direction[0] === -1 && allowRightPan(panAreaRect, designAreaRect)) ||
                (axis === 'x' && direction[0] === 1 && allowLeftPan(panAreaRect, designAreaRect))
            ) {
                setOffset({ x: offset.x + delta[0], y: offset.y });
            } else if (
                (axis === 'y' && direction[1] === -1 && allowUpPan(panAreaRect, designAreaRect)) ||
                (axis === 'y' && direction[1] === 1 && allowDownPan(panAreaRect, designAreaRect))
            ) {
                setOffset({ x: offset.x, y: offset.y + delta[1] });
            }
        },
        {
            enabled: true,
            target: ref,
            eventOptions: {
                passive: false,
            },
            filterTaps: true,
            pointer: { touch: true },
            preventDefault: true,
        }
    );

    if (currentZoomFactor <= 1 || selectedItemIds.length > 0) {
        return null;
    }

    return (
        <DesignLayer name="mobile-scroll">
            <div style={{ width: '100%', height: '100%', pointerEvents: 'all', touchAction: 'none' }} ref={ref}>
                {children}
            </div>
        </DesignLayer>
    );
});
