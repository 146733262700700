import React, { RefObject } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { Button } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { useAdjustContrast, useAdjustContrastCanvas } from '../hooks';

export const ApplyContrastColor = observer(({ imageCanvasRef }: { imageCanvasRef: RefObject<HTMLCanvasElement> }) => {
    const { isSmall } = useScreenLayout();
    const { isImageLoaded } = useAdjustContrast();
    const { fireEvent } = useCustomEventPropagation();
    const { onApplyContrastToCanvas } = useAdjustContrastCanvas();

    const handleApply = () => {
        fireEvent({
            name: ACTION_NAME.EXPERIMENT_CLICKED,
            extraData: {
                label: QUAD_TRACKING_EVENTS.APPLY_ADJUST_CONTRAST,
                eventDetail: 'QUAD;QUAD;Apply adjust contrast',
                navigationDetail: 'AdjustContrast:Apply',
                pageSection: PageSection.AdjustContrast,
                pageStage: PageStage.Design,
                category: undefined,
            },
        });
        onApplyContrastToCanvas(imageCanvasRef?.current);
    };

    return (
        <Button
            skin="primary"
            onClick={handleApply}
            size="mini"
            disabled={!isImageLoaded}
            width={isSmall ? 'full-width' : 'standard'}
        >
            {text('applyButton')}
        </Button>
    );
});
