import React from 'react';
import { AssetStatusType } from '@design-stack-ct/assets-sdk';
import { ImageItem } from '@design-stack-vista/cdif-types';
import { ItemState, PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useUploadManager } from '@design-stack-vista/upload-components';
import { css } from '@emotion/css';
import {
    FlexBox,
    SelectionSetInput,
    SelectionSetLabel,
    SelectionSetTileContents,
    SelectionSetTileDescription,
    SelectionSetTileImageContainer,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { PreviewFinishIcon } from './PreviewFinishIcon';
import { SimplePanelPreviewLayer } from './SimplePanelPreviewLayer';
import { getFileName } from './utils';
import { WarningIcon } from '../../components/SvgIcons';
import { hasSingleImageItem, hasSingleShapeItem } from '../../utils';
import { text } from '../../utils/localization';
import { useValidation } from '../quad/Validations/ValidationProvider';

const previewContainerStyle = css`
    position: relative;
    height: 86px;
    width: 112px;
    .idaf-design-layer {
        height: 54px;
        width: 80px;
    }
`;

const warningIconStyle = css`
    position: absolute;
    top: 8px;
    right: 4px;
    z-index: 1;
`;

interface UploadPreviewProps {
    panel: PanelState;
    onClick: () => void;
}

export const UploadPreview = observer(({ panel, onClick }: UploadPreviewProps) => {
    const { assetStore } = useUploadManager();
    const assets = assetStore?.assets || [];
    const { validationStore } = useValidation();
    const validations = validationStore.validations.filter((validation) => validation.panelId === panel.id);

    const isShapeItem = hasSingleShapeItem(panel);
    const isImageItem = hasSingleImageItem(panel);
    let fileName = '';

    if (isShapeItem) {
        fileName = text('solid');
    } else if (isImageItem) {
        const assetFile = assets.find(
            (asset) =>
                asset.status.type === AssetStatusType.Prepared &&
                asset.getUrl() === (panel.items[0] as ItemState<ImageItem>).model.originalSourceUrl
        );

        fileName = getFileName(assetFile);
    } else {
        fileName = text('template');
    }

    return (
        <SelectionSetInput value={panel.id}>
            <SelectionSetLabel data-testid={`upload-${panel.panelProperties.name}-preview`} onClick={onClick}>
                <SelectionSetTileImageContainer>
                    <FlexBox
                        alignItems="center"
                        justifyContent="center"
                        backgroundColor="strong"
                        className={previewContainerStyle}
                    >
                        <SimplePanelPreviewLayer panel={panel} />
                        {validations.length > 0 ? (
                            <WarningIcon className={warningIconStyle} width={24} height={24} />
                        ) : (
                            <PreviewFinishIcon panelId={panel.id} />
                        )}
                    </FlexBox>
                </SelectionSetTileImageContainer>
                <SelectionSetTileContents>
                    <SelectionSetTileDescription data-testid={`upload-${panel.panelProperties.name}-file-name`}>
                        {fileName}
                    </SelectionSetTileDescription>
                </SelectionSetTileContents>
            </SelectionSetLabel>
        </SelectionSetInput>
    );
});
