import React, { HTMLProps, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { className as classNames, FlexBox, Icon, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { HorizontalScrollButton } from './HorizontalScrollButton';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { text } from '../../utils/localization';

const scrollbarStyle = css`
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;

    /*This will hide the scrollbar, keeping the scroll functionality*/
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    .horizontal-scroll-indicator {
        position: absolute;
        height: 100%;
        top: 0;
        display: none;
        opacity: 0;
        transition: opacity 0.4s;
        align-items: center;
    }

    .horizontal-scroll-show {
        display: flex;
        opacity: 1;
    }

    .left-horizontal-scroll-indicator {
        left: 0px;
        button {
            left: 0px;
        }
    }

    .right-horizontal-scroll-indicator {
        right: 0px;
        button {
            right: 0;
        }
    }
`;

export const HorizontalScroller = observer(({ children, className }: HTMLProps<HTMLDivElement>) => {
    const {
        layoutStore: { visibleItems },
    } = useDesignEngine();

    const { isSmall, isLarge } = useScreenLayout();

    const containerRef = useRef<HTMLDivElement | null>(null);
    const contentsRef = useRef<HTMLDivElement | null>(null);

    const [showLeft, setShowLeft] = useState<boolean>(false);
    const [showRight, setShowRight] = useState<boolean>(false);
    const [contentsWidth, setContentsWidth] = useState<number>(0);
    const [scrollDiff, setScrollDiff] = useState(0);

    const panelHasItems = visibleItems.length > 0;

    // this isn't inlined so that containerRef is mutable without casting it.
    const setContainerRef = (current: HTMLDivElement | null) => {
        if (current) {
            containerRef.current = current;
        }
    };

    const setContentRef = (current: HTMLDivElement | null) => {
        if (current) {
            contentsRef.current = current;
        }
    };

    useLayoutEffect(() => {
        setTimeout(() => {
            if (contentsRef.current && containerRef.current) {
                const contentsScrollWidth = contentsRef.current.scrollWidth;
                const containerWidth = containerRef.current.clientWidth;
                const containerScrollLeft = containerRef.current.scrollLeft;
                const isContentOverflowing = containerScrollLeft + containerWidth < contentsScrollWidth;

                setContentsWidth(contentsScrollWidth);
                setScrollDiff(contentsScrollWidth - containerWidth);
                setShowRight(panelHasItems && isContentOverflowing);
            }
        });
    }, [contentsRef.current?.scrollWidth, containerRef.current?.clientWidth, panelHasItems]);

    const onScroll = useCallback(
        (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
            setShowLeft(event.currentTarget.scrollLeft > 0);

            // -1 to account for weirdness at 1240-1244 screen width, off by 1px
            setShowRight(event.currentTarget.scrollLeft + event.currentTarget.clientWidth < contentsWidth - 1);
        },
        [contentsWidth, scrollDiff]
    );

    const handleScroll = useCallback(
        (moveToRight: boolean) => {
            if (containerRef.current && contentsRef.current) {
                containerRef.current.scrollBy({
                    top: 0,
                    left: (moveToRight ? 1 : -1) * scrollDiff,
                    behavior: 'smooth',
                });
            }
        },
        [containerRef.current, contentsRef.current, scrollDiff]
    );

    const onClickLeft = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.preventDefault();
        handleScroll(false);
    };

    const onClickRight = () => {
        handleScroll(true);
    };

    return (
        <div data-testid="quick-tool-scroller" ref={setContainerRef} className={scrollbarStyle} onScroll={onScroll}>
            <FlexBox
                alignItems="center"
                justifyContent={isLarge || !panelHasItems ? 'center' : 'flex-start'}
                ref={setContentRef}
                className={className}
                gap={isSmall ? '3' : '4'}
                px={{ xs: '0', sm: '6' }}
            >
                {children}
            </FlexBox>

            <div
                className={classNames('horizontal-scroll-indicator left-horizontal-scroll-indicator', {
                    'horizontal-scroll-show': showLeft,
                })}
            >
                <HorizontalScrollButton data-testid="horizontal-scroll-indicator-left" onClick={onClickLeft}>
                    <Icon iconType="chevronLeft" size="16p" />
                    <Typography visuallyHidden>{text('scrollLeft')}</Typography>
                </HorizontalScrollButton>
            </div>
            <div
                className={classNames('horizontal-scroll-indicator right-horizontal-scroll-indicator', {
                    'horizontal-scroll-show': showRight,
                })}
            >
                <HorizontalScrollButton data-testid="horizontal-scroll-indicator-right" onClick={onClickRight}>
                    <Icon iconType="chevronRight" size="16p" />
                    {/* This element is hidden but the white color shuts up a11y scanners about contrast ratios */}
                    <Typography textColor="standard" visuallyHidden>
                        {text('scrollRight')}
                    </Typography>
                </HorizontalScrollButton>
            </div>
        </div>
    );
});
