import React from 'react';
import { FlexBox } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DecorationAreaItem } from './DecorationAreaItem';
import { FlexibilityOptions } from '../../Flexibility/common';
import { decorationAreaStore } from '../store';

export const DecorationAreaOptions = observer(() => {
    const { activeDecorationArea, decorationAreaList, setActiveDecorationArea } = decorationAreaStore;

    const onClick = (decorationArea: string) => {
        setActiveDecorationArea(decorationArea);
    };
    return (
        <FlexBox flexDirection="column">
            <FlexibilityOptions>
                {decorationAreaList.map((decorationArea) => (
                    <DecorationAreaItem
                        key={decorationArea}
                        decorationArea={decorationArea}
                        activeDecorationArea={activeDecorationArea}
                        onClick={() => onClick(decorationArea)}
                    />
                ))}
            </FlexibilityOptions>
        </FlexBox>
    );
});
