import { DesignState } from '@design-stack-vista/cimdoc-state-manager';
import { BaseExtension, OptionalInject } from '@design-stack-vista/interactive-design-engine-core';

export abstract class BaseValidationExtension extends BaseExtension {
    static inject?: (string | OptionalInject)[];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static supports(state: DesignState) {
        return true;
    }

    constructor(designState: DesignState) {
        super(designState);
        this.designState = designState;
    }

    protected validate() {
        throw Error('Validate needs to be implemented');
    }
}
