import React, { useEffect } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { InfiniteScrollContainer } from '@design-stack-vista/upload-components';
import { css } from '@emotion/css';
import { ModalDialogBody } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { UploadModalBodyComponents } from './UploadModalBodyComponents';
import { UploadModalHeader } from './UploadModalHeader';
import { FRONT } from '../../config/constant';
import { ACTION_NAME, QUAD_TRACKING_EVENTS } from '../../features/TrackingEvents/constant';
import { GA_EVENTS } from '../../features/TrackingEvents/GAEvents';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { useUploadModal } from '../../providers';
import { DesktopExperience, MobileExperience } from '../Experiences';
import { StandardModal } from '../Modal/components/StandardModal';

const getUploadModalBodyStyle = (isSmall: boolean) => css`
    // 112px is the height of the components above the scrollable area
    // 60px for quad header height

    ${isSmall && 'height: calc(100vh - 112px - 60px)'}; // fallback for browsers not supporting dvh
    height: ${isSmall ? 'calc(100dvh - 112px - 60px)' : '464px'};

    #upload-infinite-scroll-component {
        height: ${isSmall ? '100%' : '210px'};

        ${isSmall &&
        `
            margin-bottom: -32px;
            margin-right: 5px;
            padding: 0 8px 16px 16px;
        `}
    }
    img {
        border-radius: 8px;
    }
`;

const getUploadModalStyle = (isSmall: boolean) => css`
    ${!isSmall &&
    `
        .swan-modal-dialog.swan-modal-dialog-visible {
                overflow: hidden;
            }    
    `}
`;

export const UploadModal = observer(() => {
    const {
        layoutStore: { visiblePanels },
        historyStore: {
            changes: { length: historyStoreLength },
        },
    } = useDesignEngine();
    const { isSmall } = useScreenLayout();
    const { fireEvent } = useCustomEventPropagation();
    const { showUploadModal, setShowUploadModal } = useUploadModal();

    const {
        id: activePanelId,
        items: { length: activePanelItemsLength },
        panelProperties: { name: activePanelName },
    } = visiblePanels[0];

    useEffect(() => {
        if (!activePanelItemsLength && activePanelName === FRONT && !historyStoreLength) {
            setShowUploadModal(true);
            fireEvent({
                name: ACTION_NAME.DESIGN_TOOL_USED,
                extraData: {
                    experimentLabel: QUAD_TRACKING_EVENTS.AUTO_OPEN_UPLOAD_MODAL,
                    eventDetail: GA_EVENTS.VIEW_MODAL_PANEL.replace('[CANVAS FACE]', activePanelName),
                },
            });
        }
    }, [activePanelItemsLength, activePanelName, historyStoreLength]);

    return (
        <StandardModal
            isOpen={showUploadModal}
            data-testid="upload-modal"
            onClose={setShowUploadModal}
            fullBleed={isSmall}
            className={getUploadModalStyle(isSmall)}
        >
            <UploadModalHeader panelName={activePanelName} panelId={activePanelId} />
            <ModalDialogBody className={getUploadModalBodyStyle(isSmall)}>
                <MobileExperience>
                    <InfiniteScrollContainer infiniteScrollId="upload-infinite-scroll-component">
                        <UploadModalBodyComponents activePanelId={activePanelId} activePanelName={activePanelName} />
                    </InfiniteScrollContainer>
                </MobileExperience>
                <DesktopExperience>
                    <UploadModalBodyComponents activePanelId={activePanelId} activePanelName={activePanelName} />
                </DesktopExperience>
            </ModalDialogBody>
        </StandardModal>
    );
});
