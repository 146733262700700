import { useEffect, useMemo, useState } from 'react';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useProductConfiguration } from './calcifer';
import { REQUESTOR } from '../config/constant';
import { isGAProduct } from '../features/TrackingEvents/Analytics';
import { TrackingEventAnalytics } from '../features/TrackingEvents/TrackingProvider';
import { VariationConfType } from '../features/TrackingEvents/type';
import { getAnalyticsPageData } from '../features/TrackingEvents/utils';
import { useProductParams } from '../providers';
import { useAbTestContext } from '../providers/ABTestProvider';
import { getQUADExperiments } from '../utils/getQUADExperiment';

function restructureVariations(updatedVariations: string[], variationKey: string | null) {
    return updatedVariations
        .filter((variation) => variation === variationKey)
        .reduce((accumulator: VariationConfType, current: string) => {
            if (current) {
                accumulator = { variationId: current, variationName: current };
            }
            return accumulator;
        }, {} as VariationConfType);
}

export const useInitializeTracking = () => {
    const ABTest = useAbTestContext();
    const { locale } = useProductParams();
    const { productData, workId } = useProductConfiguration();
    const [trackingEvent, setTrackingEvent] = useState<TrackingEventAnalytics>();
    const { auth: vpAuth, isIdentityInitialized } = useIdentityContext();
    const authorizationHeader = isIdentityInitialized ? vpAuth.getAuthorizationHeader() : undefined;

    const trackingProductDeps = useMemo(() => {
        return productData
            ? {
                  productKey: productData.productKey,
                  productName: productData.productName,
                  productVersion: productData.productVersion,
                  mpvId: productData.mpvId,
              }
            : undefined;
    }, [productData?.productKey, productData?.productName, productData?.productVersion, productData?.mpvId]);

    useEffect(() => {
        if (trackingProductDeps && locale && ABTest && authorizationHeader) {
            const analyticsPageData = getAnalyticsPageData(trackingProductDeps.mpvId);
            const [localLanguage, localCountry] = locale.split('-');

            const experiment = getQUADExperiments(locale, trackingProductDeps.mpvId);

            let experimentDetails = {
                experimentId: '',
                experimentName: '',
                variationId: '',
                variationName: '',
            };

            if (experiment) {
                const { experimentId, experimentKey, variations } = experiment;
                const variationKey = ABTest.getVariation(experimentKey);
                const variationDetails = restructureVariations(variations, variationKey);

                experimentDetails = { experimentId, experimentName: experimentKey, ...variationDetails };

                if (isGAProduct(trackingProductDeps.productKey)) {
                    if (experimentKey && variationKey) {
                        ABTest.trackImpression(experimentKey, analyticsPageData);
                    }
                }
            }

            setTrackingEvent({
                language: localLanguage,
                locale: localCountry,
                pageName: REQUESTOR,
                productKey: trackingProductDeps.productKey,
                productMpVId: trackingProductDeps.mpvId,
                productName: trackingProductDeps.productName,
                productVersion: trackingProductDeps.productVersion,
                timeSinceLoad: performance.now(),
                workId: workId,
                ...experimentDetails,
            });
        }
    }, [trackingProductDeps, locale, ABTest, workId, authorizationHeader]);

    return { trackingEvent };
};
