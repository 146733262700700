import { useEffect, useState } from 'react';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';

export const useActivePanel = () => {
    const [activePanel, setActivepanel] = useState<PanelState>();
    const {
        cimDocStore: { panels },
        idaStore: { activeDesignPanelId },
    } = useDesignEngine();

    useEffect(() => {
        const panel = panels.find((panel) => panel.id === activeDesignPanelId);
        setActivepanel(panel);
    }, [activeDesignPanelId, panels]);

    return activePanel;
};
