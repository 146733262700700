import { useDesignEngine } from '@design-stack-vista/core-features';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useQueryClient } from '@tanstack/react-query';
import { useBackDesignFlags } from './useBackDesignFlags';
import { DesignVariant, generateDesignLayouts } from '../../../api/generateDesignLayouts';
import { PanelSides } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useActivePanel } from '../../../hooks/useActivePanel';
import { useProductParams } from '../../../providers';
import { getImageURLFromPanel } from '../../../utils';
import { getPanelByName } from '../../UploadPanel/components/UploadBackDesignModal/helper';
import { MAX_NUM_COLORS, QUERY_KEY, TemplateThumbDimension } from '../constant';
import { backDesignTemplateStore } from '../store/BackDesignTemplateStore';
import { ImageAsset } from '../types';
import {
    getAssetFromPanelImg,
    getColorsFromUploadedDesign,
    getPreviewUrl,
    getSingleColorTemplatePreviews,
    isCloseToWhiteColor,
} from '../utils';

export const useBackDesignTemplate = () => {
    const { cimDocStore } = useDesignEngine();
    const { panels } = cimDocStore;
    const activePanel = useActivePanel();
    const { locale } = useProductParams();
    const { productData } = useProductConfiguration();
    const {
        setAssetId,
        setColorPreviews,
        setDesignTemplates,
        setIsColorLoading,
        setIsDesignTemplateLoading,
        setSelectedDesignTemplate,
    } = backDesignTemplateStore;
    const { isBackDesignEnabled } = useBackDesignFlags();
    const { auth: vpAuth } = useIdentityContext();
    const authorizationHeader = vpAuth.getAuthorizationHeader();
    const backPanel = getPanelByName(panels, PanelSides.Back);
    const queryClient = useQueryClient();

    const generateAndSetDesignTemplates = async () => {
        if (!productData || !backPanel) {
            setIsDesignTemplateLoading(false);
            return;
        }
        const { productKey, productVersion, selectedOptions, customerSelectedOptions } = productData;
        queryClient.cancelQueries([QUERY_KEY.DESIGN_TEMPLATES]);
        try {
            const designs = await queryClient.fetchQuery({
                queryKey: [
                    QUERY_KEY.DESIGN_TEMPLATES,
                    {
                        imageUrl: getImageURLFromPanel(panels[0]),
                        productOptions: { ...selectedOptions, ...customerSelectedOptions },
                    },
                ],
                queryFn: async ({ signal }) =>
                    await generateDesignLayouts({
                        designVariant: DesignVariant.ALL,
                        culture: locale,
                        product: {
                            productId: productKey,
                            productVersion,
                            productOptions: { ...selectedOptions, ...customerSelectedOptions },
                        },
                        sourceDocument: cimDocStore.asJson,
                        authHeader: authorizationHeader,
                        signal,
                    }),
                staleTime: Infinity,
            });

            if (designs) {
                const designTemplates = designs.designLayouts.map((design) => {
                    const {
                        cimDoc: { editable },
                        layoutId,
                        completionPercent,
                    } = design;
                    const preview = getPreviewUrl({
                        panel: backPanel,
                        cimDoc: design.cimDoc.preview,
                        width: TemplateThumbDimension.templateWidth,
                    });
                    return { preview, designCimDoc: editable, layoutId, completionPercent };
                });

                if (designTemplates.length) {
                    setDesignTemplates(designTemplates);
                }
            }
        } catch (err) {
            setDesignTemplates([]);
        } finally {
            if (!queryClient.isFetching([QUERY_KEY.DESIGN_TEMPLATES])) {
                setIsDesignTemplateLoading(false);
            }
        }
    };

    const generateAndSetColors = async (imgAsset: ImageAsset) => {
        if (!productData || !backPanel) {
            setIsColorLoading(false);
            return;
        }
        try {
            const { selectedOptions, customerSelectedOptions } = productData;
            queryClient.cancelQueries([QUERY_KEY.IMAGE_COLORS]);
            const colors = await queryClient.fetchQuery({
                queryKey: [
                    QUERY_KEY.IMAGE_COLORS,
                    {
                        imageUrl: imgAsset.imageURL,
                        productOptions: { ...selectedOptions, ...customerSelectedOptions },
                    },
                ],
                queryFn: async ({ signal }) =>
                    await getColorsFromUploadedDesign({ imgAsset, authorizationHeader, signal }),
                staleTime: Infinity,
            });
            if (!colors || !colors.length) return;
            const filteredColor = colors
                .filter((color, index) => colors.indexOf(color) === index)
                .filter((color) => !isCloseToWhiteColor(color))
                .slice(0, MAX_NUM_COLORS);

            // backPanel is panel where solid color will be applied
            const colorPreviews = getSingleColorTemplatePreviews({
                colors: filteredColor,
                panel: backPanel,
                cimDoc: cimDocStore.asJson,
            });

            if (colorPreviews?.length) {
                setColorPreviews(colorPreviews);
            }
        } catch (err) {
            setColorPreviews([]);
        } finally {
            setIsColorLoading(false);
        }
    };

    const setTemplatePreviewsAndColors = async () => {
        if (!productData || !cimDocStore.asJson || !isBackDesignEnabled) return;

        // fetch upload img url from panels[0]
        const imgAsset = getAssetFromPanelImg(panels[0]);
        if (!imgAsset) return;
        const { assetId } = imgAsset;
        setAssetId(assetId);

        setIsColorLoading(true);
        setIsDesignTemplateLoading(true);

        await generateAndSetColors(imgAsset);
        await generateAndSetDesignTemplates();
    };

    const resetTemplatePreviewsAndColors = () => {
        const isFirstPanel = activePanel?.panelProperties.id === panels[0].panelProperties.id;
        if (isFirstPanel) {
            setAssetId('');
            setColorPreviews([]);
            setDesignTemplates([]);
            setSelectedDesignTemplate(null);
        }
    };

    return { setTemplatePreviewsAndColors, resetTemplatePreviewsAndColors };
};
