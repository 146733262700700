import { useState } from 'react';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { useActivePanel } from './useActivePanel';
import { getBookendHeaderData } from '../api/bookend';
import { BookendHeaderDataType } from '../features/quad/types';
import { useProductParams } from '../providers';
import { addPageAction } from '../utils/newRelicUtils';

export const useBookendHeaderData = () => {
    const [headerData, setHeaderData] = useState<BookendHeaderDataType>();
    const { locale, productKey } = useProductParams();
    const panel = useActivePanel();

    useAsyncEffect(() => {
        async function bookendHeaderData() {
            if (locale) {
                const bookendsHeader = await getBookendHeaderData(locale);
                if (bookendsHeader) {
                    setHeaderData(bookendsHeader);
                    addPageAction('quad-new-header', {
                        label: 'General',
                        locale: locale,
                        productKey: productKey,
                        panelId: panel?.id,
                        panelName: panel?.panelProperties.name,
                    });
                }
            }
        }
        bookendHeaderData();
    }, [locale]);

    return headerData;
};
