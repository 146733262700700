import React, { ReactNode, useState } from 'react';
import { UploadDropZone } from '@design-stack-vista/upload-components';
import { css } from '@emotion/css';
import { className as classNames, FlexBox, tokens, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DropIcon } from '../../../components/SvgIcons';
import { text } from '../../../utils/localization';

const dropZoneStyle = css`
    position: absolute;
    height: 100%;
    width: 100%;
    > div {
        position: absolute;
        height: 100%;
        width: 100%;
    }
`;

const overlayStyle = css`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: ${tokens.SwanSemZIndexFloating};
    background: ${tokens.SwanBaseColorBlackOpacity70};
    pointer-events: none;
`;

const getDefaultOverlayContent = () => {
    return (
        <>
            <DropIcon height={'32px'} width={'32px'} />
            <Typography darkMode>{text('dropOverlayLabel')}</Typography>
        </>
    );
};

export const DropZoneWrapper = observer(
    ({
        children,
        overlayContent = getDefaultOverlayContent(),
    }: {
        children: ReactNode;
        overlayContent?: ReactNode | undefined;
    }) => {
        const [showBackDrop, setBackDrop] = useState(false);

        const showOverlay = () => {
            setBackDrop(true);
        };

        const hideOverlay = () => {
            setBackDrop(false);
        };

        return (
            <div className={classNames(dropZoneStyle, 'dropzone-container')}>
                <FlexBox
                    alignItems="center"
                    justifyContent="center"
                    visuallyHidden={!showBackDrop}
                    className={overlayStyle}
                >
                    {overlayContent}
                </FlexBox>
                <UploadDropZone
                    noClick={true}
                    noKeyboard={true}
                    onDragEnter={showOverlay}
                    onDropAccepted={hideOverlay}
                    onDropRejected={hideOverlay}
                    onDragLeave={hideOverlay}
                >
                    {children}
                </UploadDropZone>
            </div>
        );
    }
);
