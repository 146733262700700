import React, { useCallback, useState } from 'react';
import { VortexActionsProps } from '@rendering/vortex-react';
import { Buttonbar, ButtonbarButton, ButtonbarButtonLabel, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { usePanelTitle } from '../../hooks/usePanelTitle';

export const ThreeDPreviewActionButton = observer(({ actions }: VortexActionsProps): JSX.Element => {
    const { getPanelTitle } = usePanelTitle();
    const [activeSide, setActiveSide] = useState(actions[0].name);

    const handleSelection = useCallback(
        (newSelectedValue: string | undefined | null) => {
            if (newSelectedValue) {
                actions
                    .find((action) => action.name === newSelectedValue)
                    ?.onAnimate({ scaleToFitGeometry: true, centerGeometry: true, animationDuration: 2 });
                setActiveSide(newSelectedValue);
            }
        },
        [actions]
    );

    return (
        <Buttonbar variant="single-select" selectedValue={activeSide} onSelectedValueChange={handleSelection}>
            {actions.map((action) => {
                return (
                    <ButtonbarButton key={action.name} value={action.name}>
                        <ButtonbarButtonLabel>
                            <Typography fontSize="small">{getPanelTitle(action.name)}</Typography>
                        </ButtonbarButtonLabel>
                    </ButtonbarButton>
                );
            })}
        </Buttonbar>
    );
});
