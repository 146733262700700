import React from 'react';
import { css } from '@emotion/css';
import { tokens, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { text } from '../../../utils/localization';
import { stockInfoStore } from '../StockInfoStore';

const inStockStyle = css`
    color: ${tokens.SwanSemColorTextPromo};
    margin-right: ${tokens.SwanSemSpace3};
`;

const outOfStockStyle = css`
    color: ${tokens.SwanSemColorTextError};
    margin-right: ${tokens.SwanSemSpace3};
`;

export const StockInfo = observer(() => {
    const { stockInfo, inStockDetail, outOfStockDetail } = stockInfoStore;

    if (!stockInfo) {
        return null;
    }

    return (
        <div>
            {inStockDetail && (
                <Typography mb="1" fontSize="small">
                    <span className={inStockStyle}>{text('inStock')}</span>
                    {inStockDetail}
                </Typography>
            )}
            {outOfStockDetail && (
                <Typography mb="1" fontSize="small">
                    <span className={outOfStockStyle}>{text('outOfStock')}</span>
                    {outOfStockDetail}
                </Typography>
            )}
            <Typography mb="6" fontSize="small" textColor="subtle">
                {text('chooseSizesLater')}
            </Typography>
        </div>
    );
});
