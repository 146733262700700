import { useContext } from 'react';
import { AdjustContrastContext } from '../store/AdjustContrastProvider';

/**
 * This hook is used to manage the state of adjust contrast feature.
 * @returns AdjustContrastContext
 */
export const useAdjustContrast = () => {
    const header = useContext(AdjustContrastContext);

    if (!header) {
        throw new Error('useAdjustContrast must be wrapped inside the AdjustContrastProvider');
    }

    return header;
};
