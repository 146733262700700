import React, { useMemo } from 'react';
import { useDesignEngine, useZoom } from '@design-stack-vista/core-features';
import { Buttonbar, ButtonbarButton, ButtonbarButtonLabel, FlexBox } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { BACK, FRONT } from '../../config/constant';
import { useDesignablePanels } from '../../hooks/useDesignablePanels';
import { usePanelTitle } from '../../hooks/usePanelTitle';
import { useTrackExperimentClick } from '../../hooks/useTrackExperimentClick';
import { usePremiumFinishModal, usePremiumFinishStore, useUploadModal } from '../../providers';
import { getPanelName } from '../../utils';
import { text } from '../../utils/localization';
import { getReviewInstruction } from '../PremiumFinish';
import { PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../TrackingEvents/constant';
import {
    UPLOAD_BACK_DESIGN_MODAL_TYPES,
    useUploadBackDesignModal,
} from '../UploadPanel/components/UploadBackDesignModal';

export const CanvasSelectorButton = observer(() => {
    const {
        cimDocStore: { panels, asJson },
        idaStore: { setActiveDesignPanelId, activeDesignPanelId },
        layoutStore: { setVisiblePanelIds, initialZoom },
    } = useDesignEngine();
    const { zoomTo } = useZoom();
    const { getPanelTitle } = usePanelTitle();
    const { setShowUploadModal } = useUploadModal();
    const { isPremiumFinishProduct } = usePremiumFinishStore();
    const { trackExperimentClick } = useTrackExperimentClick();
    const { openPremiumFinishConfirmationModal } = usePremiumFinishModal();
    const { showUploadBackDesignModal } = useUploadBackDesignModal();
    const designablePanels = useDesignablePanels();
    const reviewInstruction = useMemo(() => getReviewInstruction(asJson), [asJson]);

    if (designablePanels.length === 1) {
        return null;
    }

    const onSelectedValueChange = (newSelectedValue: string | undefined | null) => {
        if (newSelectedValue) {
            const panelName = getPanelName(panels, newSelectedValue);
            const activePanel = panels.find((panel) => panel.id === newSelectedValue);
            if (panelName) {
                if (
                    panelName === BACK &&
                    isPremiumFinishProduct &&
                    (!reviewInstruction || !reviewInstruction.finishOption)
                ) {
                    openPremiumFinishConfirmationModal();
                    return;
                }
                const eventName = QUAD_TRACKING_EVENTS.TOGGLE_VISIBLE_PANELS.replace('${PANEL_NAME}', panelName);
                trackExperimentClick({
                    label: eventName,
                    pageSection: PageSection.QuickUploadAndDesign,
                    pageStage: PageStage.Discover,
                    experimentDetail: {
                        side: getPanelName(panels, activeDesignPanelId),
                    },
                });
            }
            zoomTo(initialZoom);
            setActiveDesignPanelId(newSelectedValue);
            setVisiblePanelIds([newSelectedValue]);
            if (panelName === BACK) {
                if (!activePanel?.items.length) {
                    showUploadBackDesignModal(UPLOAD_BACK_DESIGN_MODAL_TYPES.UPLOAD_ASSET);
                }
            }

            if (!activePanel?.items.length && panelName === FRONT) {
                setShowUploadModal(true);
            }
        }
    };

    return (
        <>
            <FlexBox justifyContent="center" alignItems="center" mt="5">
                <Buttonbar
                    variant="single-select"
                    selectedValue={activeDesignPanelId}
                    onSelectedValueChange={onSelectedValueChange}
                    data-testid="canvas-selector-mobile"
                >
                    {designablePanels.map(({ panelProperties: { id, name }, items }, index) => {
                        const panelTitle = getPanelTitle(name);
                        const testId = name.toLowerCase();

                        return (
                            <ButtonbarButton key={id} value={id}>
                                <ButtonbarButtonLabel data-testid={`canvas-selector-button-${testId}`}>
                                    {!items.length && index === designablePanels.length - 1
                                        ? text('addTitle', { title: panelTitle })
                                        : panelTitle}
                                </ButtonbarButtonLabel>
                            </ButtonbarButton>
                        );
                    })}
                </Buttonbar>
            </FlexBox>
        </>
    );
});
