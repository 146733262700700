import React from 'react';
import { css } from '@emotion/css';
import { Button, className, FlexBox, tokens, Typography } from '@vp/swan';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { generateTestIdFromText } from '../../../utils/generateTestIdFromText';

const getSvgStyle = (isSmall: boolean) => css`
    svg {
        width: 24px;
        height: 24px;
        ${isSmall && `margin-right: ${tokens.SwanSemSpace3};`};
    }
`;

interface HeaderButtonProps {
    onClick: () => void;
    label: string;
    icon?: React.ReactNode | React.ReactNode[];
    disabled?: boolean;
}

export const HeaderButton = ({ onClick, label, icon, disabled }: HeaderButtonProps): JSX.Element => {
    const { isSmall } = useScreenLayout();

    return (
        <Button
            px={{ xs: '0', sm: '5' }}
            py={{ xs: '0', sm: '4' }}
            skin={isSmall ? 'unstyled' : 'tertiary'}
            disabled={disabled}
            onClick={onClick}
            iconPosition="left"
            data-testid={generateTestIdFromText(label)}
            className={className(getSvgStyle(isSmall))}
        >
            {isSmall ? (
                <FlexBox gap="3" alignItems="center">
                    {icon}
                    <Typography fontSize="standard" noWrap fontWeight="bold">
                        {label}
                    </Typography>
                </FlexBox>
            ) : (
                <>
                    {icon}
                    {label}
                </>
            )}
        </Button>
    );
};
