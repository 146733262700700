export enum FINISH_TYPE {
    embossedGloss = 'Embossed Gloss',
    foilAccent = 'Foil Accent',
    none = 'None',
}

export enum FinishOption {
    TextOnly = 'Text Only',
    ImageOnly = 'Image Only',
    TextAndImage = 'Text and Image',
}

export enum FINISH_PREVIEW_FORMAT {
    IMAGE = 'image',
    VIDEO = 'video',
}

export enum LEVEL {
    /**
     * Level 1 lists the various types of finishes that are possible for the product.
     * Example: Embossed Gloss, Foil Accent & Metallic
     */
    level1 = 'finishInformation',
    /**
     * Level-2 specifies the area of the product where the finish will be applied.
     * Example: Text only, Image Only, Text and Image
     */
    level2 = 'finishApplyOption',
}

export const PF_INSTRUCTIONS_TYPE = 'Premium Finish';

export const DialogMessageKey = {
    [FinishOption.TextOnly]: 'textOnlyPopoverDialogMessage',
    [FinishOption.ImageOnly]: 'imageOnlyPopoverDialogMessage',
    [FinishOption.TextAndImage]: 'textAndImagePopoverDialogMessage',
};
