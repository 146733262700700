import React from 'react';
import { FlexBox, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { OrientationItem } from './OrientationItem';
import { ProductAttributes } from '../../../config/constant';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { useTranslatedConfig } from '../../../hooks/useTranslatedConfig';
import { useProductOptionsTracking } from '../../../providers';
import { text } from '../../../utils/localization';
import { useAutoRecognitionTracking } from '../hooks';
import { changeSizeStore } from '../store';

export const Orientations = observer(() => {
    const {
        activeSize,
        activeOrientation,
        compatibleOrientations,
        orientationSizesMap,
        isOrientationFlexible,
        setActiveOrientation,
        setActiveSize,
    } = changeSizeStore;
    const { isSmall } = useScreenLayout();
    const { getTranslatedOptionName } = useTranslatedConfig();
    const productOptionsTracking = useProductOptionsTracking();
    const { trackSizeSelection } = useAutoRecognitionTracking();

    const onClick = (orientation: string) => {
        const isActiveSizeCompatible = orientationSizesMap[orientation].includes(activeSize);

        if (isActiveSizeCompatible) {
            setActiveOrientation(orientation);
        } else {
            setActiveSize(orientationSizesMap[orientation][0]);
            setActiveOrientation(orientation);
        }

        trackSizeSelection({
            selectedSize: isActiveSizeCompatible ? activeSize : orientationSizesMap[orientation][0],
            selectedOrientation: orientation,
            userSelected: true,
        });

        productOptionsTracking.trackAttributeSelected(ProductAttributes.Orientation, orientation);
    };

    if (!isOrientationFlexible) {
        return null;
    }

    return (
        <>
            <Typography mb="4" fontWeight={isSmall ? 'normal' : 'bold'} fontSize={isSmall ? 'xsmall' : 'large'}>
                {text('orientationLabel')}
            </Typography>
            <FlexBox flexDirection="row" gap="4">
                {compatibleOrientations.map((orientation) => (
                    <OrientationItem
                        key={orientation}
                        orientation={orientation}
                        activeOrientation={activeOrientation}
                        onClick={() => onClick(orientation)}
                    />
                ))}
            </FlexBox>
            <Typography
                fontWeight={isSmall ? 'normal' : 'bold'}
                mt={isSmall ? '5' : '6'}
                mb="4"
                fontSize={isSmall ? 'xsmall' : 'large'}
            >
                {text('orientationSizes', {
                    orientation: getTranslatedOptionName(ProductAttributes.Orientation, activeOrientation),
                })}
            </Typography>
        </>
    );
});
