import { useCallback } from 'react';
import { useFlexibilityCimDocs } from './useFlexibilityCimDocs';
import { useFlexibilityPreviews } from './useFlexibilityPreviews';
import { noticeError } from '../../../../utils';
import { FlexibilityDocuments, ProductChoiceGroups } from '../types';

export const useFlexibilityDesignDocuments = () => {
    const { getFlexibilityPreviews } = useFlexibilityPreviews();
    const { getTransformedFlexibilityCimDocs } = useFlexibilityCimDocs();

    const getFlexibilityDocuments = useCallback(
        async (
            productChoiceGroups: ProductChoiceGroups,
            abortSignal?: AbortSignal
        ): Promise<FlexibilityDocuments | undefined> => {
            try {
                const productChoiceKeys = Object.keys(productChoiceGroups);
                const productAttributes = Object.values(productChoiceGroups);

                const flexibilityCimDocs = await getTransformedFlexibilityCimDocs(productAttributes, abortSignal);
                const flexibilityPreviews = await getFlexibilityPreviews(
                    flexibilityCimDocs,
                    productAttributes,
                    abortSignal
                );

                return productChoiceKeys.reduce((acc, key, index) => {
                    return {
                        ...acc,
                        [key]: {
                            cimDoc: flexibilityCimDocs[index],
                            previewUrls: flexibilityPreviews[index],
                        },
                    };
                }, {});
            } catch (error) {
                noticeError(error, {
                    method: 'getFlexibilityDocuments - useFlexibility',
                });
            }
        },
        [getFlexibilityPreviews, getTransformedFlexibilityCimDocs]
    );

    return {
        getFlexibilityDocuments,
    };
};
