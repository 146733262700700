import React from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { observer } from 'mobx-react-lite';
import { QuickToolItem } from './QuickToolItem';
import { SafetyIcon } from '../../components/SvgIcons';
import { Mask } from '../../config/constant';
import { useProductConfiguration } from '../../hooks/calcifer/useProductConfiguration';
import { useActivePanel } from '../../hooks/useActivePanel';
import { text } from '../../utils/localization';
import { PanelChromesExtension } from '../quad/Extensions';
import { useQuickToolProps } from '../quad/recipes/useQuickToolProps';

export const QuickToolFitToSafe = observer((): JSX.Element | null => {
    const { productData } = useProductConfiguration();
    const panel = useActivePanel();
    const { designExtensionSystem } = useDesignEngine();
    const { disabled, iconColor, handleFitToSafe } = useQuickToolProps();

    if (!productData || !panel) {
        return null;
    }

    const panelChromes = designExtensionSystem.getExtension(panel.iid, PanelChromesExtension);
    const isFitToSafe = panelChromes?.masks.some((mask) => mask.type === Mask.Safe);

    return (
        <>
            {isFitToSafe && (
                <QuickToolItem
                    Icon={<SafetyIcon fill={iconColor} />}
                    buttonLabel={text('fitToSafe')}
                    onClick={handleFitToSafe}
                    disabled={disabled}
                    dataTestId="fit-to-safe"
                />
            )}
        </>
    );
});
