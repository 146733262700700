import { useCallback, useEffect, useMemo } from 'react';
import { useShapePrice } from './useShapePrice';
import { useShapeUtils } from './useShapeUtils';
import { ProductAttributes } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { ShapeKey } from '../constants';
import { shapeStore } from '../ShapeStore';

export const useInitializeShapeStore = () => {
    const { productData, secondaryConfig } = useProductConfiguration();
    const { userSelectedSize, shapeOptions, setShapeList, setSelectedShape, setUserSelectedSize } = shapeStore;
    const { hasShapes } = useShapeUtils();
    const { updateShapeOptionsAndPrices } = useShapePrice();

    const productShape = productData?.selectedOptions[ProductAttributes.Shape];
    const productSize = productData?.selectedOptions[ProductAttributes.Size];

    const shapeList: ShapeKey[] = useMemo(() => {
        const shapeOptionKey = Object.keys(shapeOptions)
            .filter((option) => option !== ShapeKey.Custom)
            .sort() as ShapeKey[];

        return shapeOptionKey;
    }, [shapeOptions]);

    const initializeShapeStore = useCallback(() => {
        if (hasShapes && productShape) {
            setShapeList(shapeList);
            setSelectedShape(productShape);
            if (productSize && !userSelectedSize) {
                setUserSelectedSize(productSize);
                updateShapeOptionsAndPrices({
                    productData,
                    secondaryConfig,
                    userSelectedSize: productSize,
                });
            }
        }
    }, [shapeList, productData, userSelectedSize, hasShapes, secondaryConfig]);

    useEffect(() => initializeShapeStore(), [initializeShapeStore]);
};
