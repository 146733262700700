import React from 'react';
import { Button, ModalDialogButtons, ModalDialogFooter, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { text } from '../../../../../../utils/localization';

type Props = {
    footerButtonText: string;
    footerButtonSkin: 'primary' | 'secondary';
    footerAction: () => Promise<void>;
    dataTestId?: string;
};

const UploadBackDesignModalFooter = observer((props: Props) => {
    const { footerButtonText, footerButtonSkin, footerAction, dataTestId } = props;

    return (
        <ModalDialogFooter pinned mt="0" pb={{ xs: 0, sm: '6' }} pt={{ xs: '5', sm: '6' }}>
            <ModalDialogButtons m="0" mt={{ xs: '2', sm: '0' }}>
                <Button
                    width="full-width"
                    size="standard"
                    m={0}
                    skin={footerButtonSkin}
                    onClick={footerAction}
                    data-testid={dataTestId}
                >
                    <Typography fontSize="small">{text(footerButtonText)}</Typography>
                </Button>
            </ModalDialogButtons>
        </ModalDialogFooter>
    );
});

export default UploadBackDesignModalFooter;
