import { CimDoc } from '@design-stack-vista/cdif-types';
import { PF_INSTRUCTIONS_TYPE } from '../../PremiumFinish';

/**
 * Remove Premium finish review instruction from the cimdoc
 */
export const removePremiumFinishReviewInstruction = (cimdoc: CimDoc) => {
    if (!cimdoc.metadata) {
        return;
    }
    if (!cimdoc.metadata.reviewInstructions) {
        return;
    }
    const premiumInstructionReviewIndex = cimdoc.metadata.reviewInstructions.findIndex(
        (review: { instructionType: string }) => review.instructionType === PF_INSTRUCTIONS_TYPE
    );
    if (premiumInstructionReviewIndex === -1) {
        return;
    } else {
        cimdoc.metadata.reviewInstructions.splice(premiumInstructionReviewIndex);
    }
};
