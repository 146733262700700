import React from 'react';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { Preview } from '@design-stack-vista/preview-components';
import { REQUESTOR, STUDIO_PREVIEW_PURPOSE } from '../../config/constant';
import { useProductConfiguration } from '../../hooks/calcifer';
import { useGetDesignDocument } from '../../hooks/useGetDesignDocument';
import { useProductParams } from '../../providers';
import { usePreview } from '../../providers/PreviewProvider';

interface TwoDPreviewInstanceProps {
    children?: React.ReactNode;
}

export const TwoDPreviewInstance = ({ children }: TwoDPreviewInstanceProps): JSX.Element | null => {
    const { locale } = useProductParams();
    const { auth: vpAuth } = useIdentityContext();
    const { productData } = useProductConfiguration();
    const accessToken = vpAuth.getToken();
    const { activePreview } = usePreview();
    const { getDocumentWithoutPlaceholders } = useGetDesignDocument();
    const designDocument = getDocumentWithoutPlaceholders();

    if (!productData || !activePreview) {
        return null;
    }

    const { productKey, selectedOptions } = productData;

    return (
        <Preview
            minZoom={1}
            maxZoom={3}
            accessToken={accessToken}
            locale={locale}
            productKey={productKey}
            options={selectedOptions}
            requestor={REQUESTOR}
            purpose={STUDIO_PREVIEW_PURPOSE}
            cimDoc={designDocument}
            selectedLabel={activePreview}
        >
            {children}
        </Preview>
    );
};
