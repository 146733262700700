export const TemplateThumbDimension = {
    imageWidth: 136,
    imageHeight: 80,
    templateWidth: 800,
};
export const MAX_NUM_COLORS = 2;
export const NUM_OF_LOADER = 4;
export enum QUERY_KEY {
    DESIGN_TEMPLATES = 'designTemplates',
    IMAGE_COLORS = 'imageColors',
}
export const supportedMpvIds = [
    'standardBusinessCards',
    'roundedCornerBusinessCards',
    'matteBusinessCards',
    'softTouchBusinessCards',
    'glossyBusinessCards',
    'uncoatedBusinessCards',
    'premiumPlusBusinessCards',
];
export const supportedLocales = ['en-us', 'es-us', 'en-ca', 'fr-ca'];
