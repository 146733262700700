import React from 'react';
import { observer } from 'mobx-react-lite';
import { BackColorList } from './BackColorList';
import { ColorPreviewSkeleton } from './ColorPreviewSkeleton';
import { backDesignTemplateStore } from '../store/BackDesignTemplateStore';

export const BackColor = observer(() => {
    const { isColorLoading } = backDesignTemplateStore;
    return isColorLoading ? <ColorPreviewSkeleton /> : <BackColorList />;
});
