import React, { useCallback } from 'react';
import { AlertBox, AlertBoxDismissButton, Typography } from '@vp/swan';
import { useUploadModal } from '../../providers';
import { text } from '../../utils/localization';

export const UploadModalNotification = () => {
    const { validationMessage, setValidationMessage } = useUploadModal();

    const onRequestDismiss = useCallback(() => setValidationMessage(null), []);

    if (!validationMessage) {
        return null;
    }

    return (
        <AlertBox skin="error" onRequestDismiss={onRequestDismiss} mb={{ xs: '5', sm: '6' }}>
            <Typography textAlign="left">{validationMessage}</Typography>
            <AlertBoxDismissButton accessibleText={text('dismissAccessibilityLabel')} />
        </AlertBox>
    );
};
