import React from 'react';
import { AlertBox, AlertBoxDismissButton, Button, ButtonProps, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { useContinueButtonQuad } from './hooks/useContinueButtonQuad';
import { text } from '../../utils/localization';

// TODO: look at studio's implementation of localization and see if we can leverage anything from there.
const messages = {
    addToCartApprovalAlert: {
        id: 'addToCartButton.addToCartApprovalAlert',
    },
    continueButton: {
        id: 'addToCartButton.continueButton',
    },
};

type ContinueButtonProps = Omit<ButtonProps, 'data-testid' | 'onClick' | 'id' | 'skin' | 'ref'> & {
    hasBeenReviewed: boolean;
};

export const ContinueButtonQuad = observer(({ hasBeenReviewed, ...rest }: ContinueButtonProps) => {
    const { handleContinueClick, showErrorMessage, showSlowImageUploadMessage, dismissSlowUploadMessage } =
        useContinueButtonQuad(hasBeenReviewed);

    return (
        <>
            {showErrorMessage && (
                <AlertBox data-testid="approvalAlert" skin="error" mb="5">
                    {text(messages.addToCartApprovalAlert.id)}
                </AlertBox>
            )}
            {showSlowImageUploadMessage && (
                <AlertBox skin="error" onRequestDismiss={dismissSlowUploadMessage} mb="5">
                    <Typography>{text('slowUploadCheckbox')}</Typography>
                    <AlertBoxDismissButton visuallyHiddenLabel={text('dismissAccessibilityLabel')} />
                </AlertBox>
            )}
            <Button
                data-testid="continueButton"
                onClick={handleContinueClick}
                id="approveAndContinueButton"
                skin="primary"
                width="full-width"
                {...rest}
            >
                {text(messages.continueButton.id)}
            </Button>
        </>
    );
});
