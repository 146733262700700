import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { usePricingData } from '../../../hooks/usePricingData';
import { useProductParams } from '../../../providers';
import { noticeError } from '../../../utils';
import { isVCSConsumer } from '../../VCS/utils/isVCSConsumer';
import { changeSizeStore } from '../store/ChangeSizeStore';
import { checkIsChangeSizeCompatible, getChangeSizeData } from '../utils';

export const useInitializeChangeSizeStore = () => {
    const { locale } = useProductParams();
    const { getAllCompatibleOptionsPriceData } = usePricingData();
    const { productData, secondaryConfig } = useProductConfiguration();
    const { changeSizeChoiceGroups, setChangeSizeData, setChangeSizePrices, resetChangeSizeStore } = changeSizeStore;
    const { auth: vpAuth, isIdentityInitialized } = useIdentityContext();

    const authHeader = vpAuth.getAuthorizationHeader();

    useAsyncEffect(
        (helpers) => {
            const initializeSizeProvider = async () => {
                try {
                    if (!productData || !secondaryConfig || !isIdentityInitialized || isVCSConsumer()) {
                        return;
                    }

                    const isChangeSizeCompatible = checkIsChangeSizeCompatible(productData);
                    if (!isChangeSizeCompatible) {
                        resetChangeSizeStore();
                        return;
                    }

                    const changeSizeData = await getChangeSizeData(
                        productData,
                        secondaryConfig,
                        authHeader,
                        helpers.abortSignal
                    );
                    setChangeSizeData(changeSizeData);
                } catch (error) {
                    resetChangeSizeStore();
                    noticeError(error, {
                        method: 'initializeSizeProvider - useInitialiseChangeSizeStore',
                    });
                }
            };

            initializeSizeProvider();
        },
        [
            productData?.productKey,
            productData?.productVersion,
            productData?.selectedOptions,
            productData?.studioConfiguration,
            productData?.designViews,
            secondaryConfig,
            isIdentityInitialized,
        ]
    );

    useAsyncEffect(
        (helpers) => {
            const initChangeSizePrices = async () => {
                try {
                    if (Object.keys(changeSizeChoiceGroups).length > 1 && productData) {
                        const baseOptions = {
                            ...productData.selectedOptions,
                            ...productData.customerSelectedOptions,
                        };
                        const pricingInfo = await getAllCompatibleOptionsPriceData(
                            changeSizeChoiceGroups,
                            baseOptions,
                            helpers.abortSignal
                        );
                        setChangeSizePrices(pricingInfo ?? {});
                    }
                } catch (error) {
                    noticeError(error, {
                        method: 'initChangeSizePrices - useInitialiseChangeSizeStore',
                    });
                }
            };

            initChangeSizePrices();
        },
        [
            changeSizeChoiceGroups,
            locale,
            productData?.selectedOptions,
            productData?.customerSelectedOptions,
            getAllCompatibleOptionsPriceData,
        ]
    );
};
