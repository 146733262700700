import { getDefaultProduct } from '../api/calcifer';
import { CompatibleOptionsEntry, getProductSupportedAttributes } from '../api/catalog';

type Props = {
    productKey: string;
    locale: string;
    quantity: number;
    customerSelectedProductOptions: Record<string, string>;
    authHeader: string;
};

/*
 This function will return default product options when we're switching from PRD to another PRD,
 Some options are available to one PRD, same options might not available to other PRD, may be we need 
 to have some additional options, in those scenarios we'll apply the default option 
*/
export async function getDefaultProductOptions({
    productKey,
    locale,
    quantity,
    customerSelectedProductOptions,
    authHeader,
}: Props) {
    const defaultProduct = await getDefaultProduct({ productKey, quantity, locale });
    const { productVersion, selectedOptions } = defaultProduct;

    const compatibleOptions = await getProductSupportedAttributes(
        productKey,
        selectedOptions,
        productVersion,
        authHeader
    );

    const compatibleCustomerSelectedProductOptions: Record<string, string> = {};

    Object.entries(customerSelectedProductOptions).forEach(([key, value]) => {
        const compatibleItem = compatibleOptions.find(({ name }: CompatibleOptionsEntry) => name === key);
        if (compatibleItem) {
            if (compatibleItem.values.includes(value)) {
                compatibleCustomerSelectedProductOptions[key] = value;
            } else {
                compatibleCustomerSelectedProductOptions[key] = selectedOptions[key];
            }
        }
    });

    const productOptions = {
        productKey,
        locale,
        productVersion,
        quantity,
        compatibleCustomerSelectedProductOptions: { ...selectedOptions, ...compatibleCustomerSelectedProductOptions },
    };
    return productOptions;
}
