import React, { createContext, useContext } from 'react';
import { useQueryProductParams } from '../hooks/useQueryProductParams';

export type ProductParamsContext = {
    paramInitialized: boolean;
    locale: string;
    productKey: string;
    productVersion: number;
    customerSelectedOptions: Record<string, string>;
    selectedOptions?: Record<string, string>;
    selectedQuantity: number;
    documentUrl: string;
    workId: string;
    startingCanvas: string;
    setCustomerSelectedOptions: React.Dispatch<React.SetStateAction<object>>;
    isItemInCart: boolean;
    cartQuantity: number;
    returnPath: string;
};

export const productParamsContext = createContext<ProductParamsContext | null>(null);

export const useProductParams = () => {
    const value = useContext(productParamsContext);
    if (!value) {
        throw new Error('useProductParams must be used within a ProductParamsProvider');
    }
    return value;
};

export interface ProductParamsProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

export const ProductParamsProvider = (props: ProductParamsProviderProps) => {
    const productPrams = useQueryProductParams();
    return <productParamsContext.Provider value={productPrams}>{props.children}</productParamsContext.Provider>;
};
