import { useCallback } from 'react';
import { Item } from '@design-stack-vista/cdif-types';
import { ItemState, SubpanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useTrackExperimentClick } from './useTrackExperimentClick';
import { useValidationExperiment } from './useValidationExperiment';
import { BetweenBoundValidationExtension } from '../features/quad/Validations';
import { Validation, ValidationTypes } from '../features/quad/Validations/type';
import { useValidation } from '../features/quad/Validations/ValidationProvider';
import { PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../features/TrackingEvents/constant';
import { getAllItemsInCimdocPanels } from '../utils';

type ValidationTrackingType = {
    /**
     * name of the CTA, the position from where we are existing QUAD from
     */
    exitLabel: string;
    /**
     * Section of the page
     *
     * For Finish / Update card : PageStage.Confirmation
     *
     * To Studio redirection, there are multiple exit points
     */
    pageStage: PageStage;
    pageSection: PageSection;
};

type ValidationTrackingData = {
    panelSide: string;
    errorOccurred: boolean;
    interacted: boolean;
    errorFixed: boolean;
};

export const useValidationTracking = () => {
    const {
        validationStore: { validationIdentified, validations },
    } = useValidation();
    const { trackExperimentClick } = useTrackExperimentClick();
    const { isValidationEnabled } = useValidationExperiment();
    const { designExtensionSystem, cimDocStore } = useDesignEngine();

    const updateValidationTracking = useCallback(
        ({ exitLabel, pageStage, pageSection }: ValidationTrackingType) => {
            if (isValidationEnabled) {
                const validationTrackingData: ValidationTrackingData[] = [];

                const filterImageItems = (item: ItemState<Item> | SubpanelState) =>
                    item.isItemState() && item.isImageItem() && !item.isSubpanelState();

                const imageItems = getAllItemsInCimdocPanels(cimDocStore.panels).filter(filterImageItems);

                imageItems.forEach((item) => {
                    const extensionResult = designExtensionSystem.getExtension(
                        item.iid,
                        BetweenBoundValidationExtension
                    );
                    let errorOccurred = false;
                    if (extensionResult) {
                        const { errorOccurred: extensionErrorOccurred } = extensionResult;
                        errorOccurred = extensionErrorOccurred;
                    }

                    const filterItemsWithBetweenBoundValidations = (validation: Validation) =>
                        validation.itemId === item.id && validation.validationType === ValidationTypes.BetweenBound;
                    const filteredValidationResults = validations.filter(filterItemsWithBetweenBoundValidations);

                    let interacted = false;
                    if (validationIdentified[item.id]) {
                        interacted =
                            validationIdentified[item.id]?.interacted &&
                            validationIdentified[item.id]?.type === ValidationTypes.BetweenBound;
                    }
                    let errorFixed = true;
                    if (filteredValidationResults.length > 0) {
                        errorFixed = false;
                    }

                    validationTrackingData.push({
                        panelSide: item.parent.asJson.name ?? '',
                        errorOccurred: errorOccurred,
                        interacted,
                        errorFixed,
                    });
                });

                const isStudio = exitLabel.includes('Other', 0);
                let trackingLabel = QUAD_TRACKING_EVENTS.BETWEEN_BOUND_ON_FINISH;
                if (isStudio) {
                    trackingLabel = QUAD_TRACKING_EVENTS.BETWEEN_BOUND_ON_STUDIO;
                }

                trackExperimentClick({
                    label: trackingLabel,
                    pageStage: pageStage,
                    pageSection: pageSection,
                    experimentDetail: {
                        whereWeAreExiting: exitLabel,
                        additionProperties: validationTrackingData,
                    },
                });
            }
        },
        [
            designExtensionSystem,
            isValidationEnabled,
            trackExperimentClick,
            validationIdentified,
            validations,
            cimDocStore.panels,
        ]
    );

    return {
        updateValidationTracking,
    };
};
