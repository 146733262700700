import { CimDoc } from '@design-stack-vista/cdif-types/v2';

export const updatePanelColorMode = (
    cimDoc: CimDoc,
    { panelId, colorMode }: { panelId: string; colorMode: string }
) => {
    const panel = cimDoc.document.panels.find((panel) => panel.id === panelId);
    if (!panel) throw Error('Cannot find target panel');

    cimDoc.document.panels = cimDoc.document.panels.map((panel) => {
        if (panel.id === panelId) return { ...panel, colorMode };
        return panel;
    });
};
