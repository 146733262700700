import React from 'react';
import { css } from '@emotion/css';
import { Button, className as classNames, FlexBox, FlexBoxProps, Icon, Typography } from '@vp/swan';
import { RedirectToStudioPosition } from './RedirectToStudio';
import { useScreenLayout } from '../../hooks/useScreenLayout';

const redirectContainerStyle = css`
    position: absolute;
    width: 100%;
    top: 60px;
`;

interface AddEditDesignProps extends FlexBoxProps {
    designUploadStatusLabel: string;
    redirectLinkLabel: string;
    testId: string;
    position?: RedirectToStudioPosition;
    onClick: () => void;
    className?: string;
}

export const AddEditDesign = ({
    designUploadStatusLabel,
    redirectLinkLabel,
    testId,
    position = RedirectToStudioPosition.DEFAULT,
    onClick,
    className,
    ...rest
}: AddEditDesignProps) => {
    const { isSmall } = useScreenLayout();

    return (
        <FlexBox
            gap="2"
            flexWrap="wrap"
            alignItems="center"
            justifyContent={isSmall ? 'center' : 'flex-start'}
            px={{ xs: '5', sm: '0' }}
            py={{ xs: '3', sm: '0' }}
            backgroundColor={{ xs: 'success', sm: 'transparent' }}
            className={classNames({ [redirectContainerStyle]: isSmall }, className)}
            {...rest}
        >
            {position === RedirectToStudioPosition.BOTTOM ? (
                <Button p="4" mb={{ sm: '9', md: '0' }} iconPosition="left" onClick={onClick} data-testid={testId}>
                    <Icon iconType="edit" size="20p" />
                    <Typography>{redirectLinkLabel}</Typography>
                </Button>
            ) : (
                <>
                    <Typography fontSize="standard" data-testid="without-design-text">
                        {designUploadStatusLabel}
                    </Typography>
                    <Button skin="unstyled" iconPosition="right" onClick={onClick} data-testid={testId}>
                        <FlexBox gap="1" alignItems="center">
                            <Typography fontSize="standard" fontWeight="bold">
                                {redirectLinkLabel}
                            </Typography>
                            <Icon iconType="arrowRight" size="20p" />
                        </FlexBox>
                    </Button>
                </>
            )}
        </FlexBox>
    );
};
