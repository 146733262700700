import React from 'react';
import { css } from '@emotion/css';
import { MiniLogo, StudioHeader } from '@vp/diy-editor-ui';
import { text } from '../../../../utils/localization';
import { LogoImageUrl } from '../../constant';
import { getLocale } from '../../utils/getLocale';

const miniLogoStyle = css`
    img {
        height: 48px;
    }
`;
const mobileMiniLogoStyle = css`
    img {
        height: 32px;
    }
`;

const logo = {
    image: {
        url: LogoImageUrl,
    },
};

const homeUrl = `/${getLocale()}`;

interface VCSHeaderProps {
    mobileRightChildren: React.ReactNode;
    className: string;
}

export const Header = ({ mobileRightChildren, className }: VCSHeaderProps) => {
    return (
        <StudioHeader
            p={{ xs: '5', sm: '7' }}
            leadingNode={
                <MiniLogo
                    mt="2"
                    className={miniLogoStyle}
                    logo={logo}
                    href={homeUrl}
                    accessibleText={text('miniLogo')}
                />
            }
            trailingNode={<></>}
            logoSmallScreen={
                <MiniLogo
                    mt="2"
                    className={mobileMiniLogoStyle}
                    logo={logo}
                    href={homeUrl}
                    accessibleText={text('miniLogo')}
                />
            }
            trailingNodeSmallScreen={mobileRightChildren}
            className={className}
        />
    );
};
