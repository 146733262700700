import { Panel } from '@design-stack-vista/cdif-types';

export interface UpsellPricing {
    listPrice: number;
    discountPrice: number;
    currency: string;
}

export interface DifferentialPricing {
    differentialListPrice: number;
    differentialDiscountPrice: number;
}

export interface SurfaceUpsell {
    panelId: string;
    panelName: string;
    colorOption: string;
    optionName: string;
    blankOption: string;
    currency: string;
    pricing: Record<string, DifferentialPricing>;
}

export enum ColorMode {
    Blank = 'blank',
    Color = 'color',
}
export interface PanelWithColorMode extends Panel {
    colorMode: string;
}

export interface SurfaceUpsellMetadata {
    upsellOffered: boolean;
    panelName: string;
}
