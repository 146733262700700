import { cm2mm, getAxisAlignedBoundingBox, Measurement, UnitlessTransforms } from '@design-stack-ct/utility-core';
import { Rectangle, ValidationResult } from '@design-stack-ct/validations';
import { BetweenBoundsPayload, validateItemBetweenBounds } from '@design-stack-ct/validations/dist/core/BetweenBounds';
import { ImageItem, Panel } from '@design-stack-vista/cdif-types';
import { DesignState, ItemState } from '@design-stack-vista/cimdoc-state-manager';
import { IDAItemLayoutExtension } from '@design-stack-vista/core-features';
import { Coordinates } from '@design-stack-vista/ida-framework';
import {
    BaseDesignRequirements,
    DESIGN_EXTENSION_SYSTEM_TOKEN,
    DesignExtensionSystem,
    LAYOUT_STORE_TOKEN,
    LayoutStore,
} from '@design-stack-vista/interactive-design-engine-core';
import { action, computed, IReactionDisposer, makeObservable, reaction } from 'mobx';
import { BaseValidationExtension } from './BaseValidationExtension';
import { DEPENDENCY_STORE, VALIDATION_STORE } from './constant';
import { DependencyInjectionStore } from './DependencyInjectionStore';
import { ValidationMessage, ValidationTypes } from './type';
import { ValidationStore } from './ValidationStore';
import { Mask } from '../../../config/constant';
import { PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { Masks } from '../types';

export class BetweenBoundValidationExtension extends BaseValidationExtension {
    static supports(state: DesignState): boolean {
        return state.isImageItem();
    }

    static override inject = [
        LAYOUT_STORE_TOKEN,
        DESIGN_EXTENSION_SYSTEM_TOKEN,
        VALIDATION_STORE,
        'designRequirements',
        DEPENDENCY_STORE,
    ];

    private disposeReaction: IReactionDisposer | null;
    errorOccurred = false;

    constructor(
        designState: DesignState,
        protected layoutStore: LayoutStore,
        protected extensionSystem: DesignExtensionSystem,
        private validationStore: ValidationStore,
        private designRequirements: BaseDesignRequirements,
        private dependencyStore: DependencyInjectionStore
    ) {
        super(designState);
        makeObservable(this);
        this.disposeReaction = null;
        this.errorOccurred = false;

        this.disposeReaction = reaction(
            () => this.itemPosition,
            () => {
                this.validate();
            }
        );
        this.validate();
    }

    @action.bound
    async validate() {
        if (this.designState.isImageItem() && this.safeBoundingBox && this.bleedBoundingBox) {
            const imageState = this.designState as ItemState<ImageItem>;
            const itemLayout = this.extensionSystem.getExtension(this.designState.iid, IDAItemLayoutExtension);
            if (itemLayout && this.itemPreviewBox) {
                const itemPos: Rectangle = {
                    x: new Measurement(imageState.asJson.position.x).value * this.layoutStore.zoom,
                    y: new Measurement(imageState.asJson.position.y).value * this.layoutStore.zoom,
                    width: new Measurement(imageState.asJson.position.width).value * this.layoutStore.zoom,
                    height: new Measurement(imageState.asJson.position.height).value * this.layoutStore.zoom,
                };

                const result = await validateItemBetweenBounds({
                    innerSurfaceRect: this.safeBoundingBox,
                    outerSurfaceRect: this.bleedBoundingBox,
                    itemRect: itemPos,
                    itemRotation: itemLayout.rotation,
                    previewRect: this.itemPreviewBox,
                    previewUrl: imageState.model.previewUrl ?? '',
                });
                const betweenBoundValidationCoordinates = this.getWarningCoordinates(
                    result,
                    itemPos,
                    itemLayout.rotation
                );

                this.addNotification(betweenBoundValidationCoordinates);
            }
        }
    }

    private addNotification(betweenBoundValidationCoordinates: Coordinates | null) {
        this.removeValidation();
        if (this.designState.isImageItem() && betweenBoundValidationCoordinates) {
            /**
             * Fire tracking event only once for the item.
             * All the subsequent validation warning/errors will be ignored i.e, not fired.
             *
             * We don't want to capture every time the events occurs we want to capture only once for the item.
             */
            if (this.dependencyStore._fireEvent && !this.errorOccurred) {
                this.dependencyStore._fireEvent({
                    label: QUAD_TRACKING_EVENTS.BETWEEN_BOUND_VALIDATION,
                    eventDetail: 'QUAD;QUAD;Design Area;Between Bound',
                    navigationDetail: 'Design Area:Between bound',
                    pageSection: PageSection.Validations,
                    pageStage: PageStage.Design,
                    experimentDetail: {
                        whiteBorderDetected: true,
                        panelName: this.designState.parent.asJson.name,
                        panelId: this.designState.parent.id,
                    },
                });
            }
            this.errorOccurred = true;
            this.validationStore.add(this.designState.parent.id, this.designState.id, ValidationTypes.BetweenBound, {
                iconPlacement: betweenBoundValidationCoordinates,
                level: 'WARNING',
                message: ValidationMessage.betweenBound,
            });
        }
    }

    private findSmallestCoordinate(positionCoordinates: Coordinates[]) {
        let coordinate: Coordinates = positionCoordinates[0];
        positionCoordinates.forEach((positionCoordinate) => {
            if (positionCoordinate.x <= coordinate.x && positionCoordinate.y <= coordinate.y) {
                coordinate = positionCoordinate;
            }
        });
        return coordinate;
    }

    private adjustCoordinatesToBoundingBox(coordinates: Coordinates): Coordinates {
        const { zoom } = this.layoutStore;
        let unZoomedPositionX = coordinates.x / zoom;
        let unZoomedPositionY = coordinates.y / zoom;

        if (this.safeBoundingBox) {
            const unZoomedSafeAreaWidth = this.safeBoundingBox.width / zoom;
            const unZoomedSafeAreaHeight = this.safeBoundingBox.height / zoom;
            if (unZoomedPositionX < 0) {
                unZoomedPositionX = 0;
            }
            if (unZoomedPositionX > unZoomedSafeAreaWidth) {
                unZoomedPositionX = unZoomedSafeAreaWidth;
            }
            if (unZoomedPositionY < 0) {
                unZoomedPositionY = 0;
            }
            if (unZoomedPositionY > unZoomedSafeAreaHeight) {
                unZoomedPositionY = unZoomedSafeAreaHeight;
            }
        }
        return {
            x: unZoomedPositionX,
            y: unZoomedPositionY,
        };
    }

    private getOffsetX(pointX: number, rotation: number, itemPos: Rectangle) {
        if (rotation === 0 || rotation === 180 || rotation === 360) {
            return pointX + itemPos.width / 2;
        }
        return pointX + itemPos.height / 2;
    }

    private getOffsetY(pointY: number, rotation: number, itemPos: Rectangle) {
        if (rotation === 0 || rotation === 180 || rotation === 360) {
            return pointY + itemPos.height / 2;
        }
        return pointY + itemPos.width / 2;
    }

    private getWarningCoordinates(
        result: ValidationResult<BetweenBoundsPayload>,
        itemPos: Rectangle,
        itemRotationAngle: number
    ): Coordinates | null {
        if (!result.isValid && result.payload) {
            const { top, bottom, left, right } = result.payload.sides;
            let positionX = 0;
            let positionY = 0;
            if (bottom) {
                const coordinates = this.findSmallestCoordinate(bottom);
                positionX = this.getOffsetX(coordinates.x, itemRotationAngle, itemPos);
                positionY = coordinates.y;
            }
            if (top) {
                const coordinates = this.findSmallestCoordinate(top);
                positionX = this.getOffsetX(coordinates.x, itemRotationAngle, itemPos);
                positionY = coordinates.y;
            }
            if (left) {
                const coordinates = this.findSmallestCoordinate(left);
                positionX = coordinates.x;
                positionY = this.getOffsetY(coordinates.y, itemRotationAngle, itemPos);
            }
            if (right) {
                const coordinates = this.findSmallestCoordinate(right);
                positionX = coordinates.x;
                positionY = this.getOffsetY(coordinates.y, itemRotationAngle, itemPos);
            }

            return this.adjustCoordinatesToBoundingBox({ x: positionX, y: positionY });
        }
        return null;
    }

    @computed
    private get itemPosition() {
        if (this.designState.isImageItem() && this.dependencyStore._isValidationEnabled) {
            const { zoom } = this.layoutStore;
            const result: UnitlessTransforms = {
                height: new Measurement(this.designState.model.position.height).value * zoom,
                width: new Measurement(this.designState.model.position.width).value * zoom,
                x: new Measurement(this.designState.model.position.x).value * zoom,
                y: new Measurement(this.designState.model.position.y).value * zoom,
                rotation: Number(this.designState.model.rotationAngle),
            };
            const itemPosition = getAxisAlignedBoundingBox(result);
            return { itemPosition, itemPreviewBox: this.itemPreviewBox };
        }
        return null;
    }

    @computed
    get safeBoundingBox(): Rectangle | null {
        if (this.designState.isImageItem() && this.designRequirements) {
            const designViews = this.designRequirements;
            const { zoom } = this.layoutStore;
            const panelId = this.designState.parent.id;
            const masks = designViews.panels.filter((designView) => designView.id === panelId)[0]?.masks;
            if (masks) {
                const safeMasks = masks.filter((mask) => mask.type === Mask.Safe) as unknown as Masks[];
                if (safeMasks.length > 0) {
                    const safeMask = safeMasks[0];
                    return {
                        x: new Measurement(cm2mm(safeMask.boundingArea.position.x), 'mm').value * zoom,
                        y: new Measurement(cm2mm(safeMask.boundingArea.position.y), 'mm').value * zoom,
                        width: new Measurement(cm2mm(safeMask.boundingArea.position.width), 'mm').value * zoom,
                        height: new Measurement(cm2mm(safeMask.boundingArea.position.height), 'mm').value * zoom,
                    };
                }
            }
        }
        return null;
    }

    @computed
    private get bleedBoundingBox(): Rectangle | null {
        if (this.designState.isImageItem() && this.designRequirements) {
            const designViews = this.designRequirements;
            const { zoom } = this.layoutStore;
            const panelId = this.designState.parent.id;
            const masks = designViews.panels.filter((designView) => designView.id === panelId)[0]?.masks;
            if (masks) {
                const bleedMasks = masks.filter((mask) => mask.type === Mask.Bleed) as unknown as Masks[];
                if (bleedMasks.length > 0) {
                    const { height, width } = this.designState.parent.asJson as Panel;
                    return {
                        x: new Measurement(0, 'mm').value * zoom,
                        y: new Measurement(0, 'mm').value * zoom,
                        width: new Measurement(width).value * zoom,
                        height: new Measurement(height).value * zoom,
                    };
                }
            }
        }
        return null;
    }

    private removeValidation() {
        if (this.designState.isImageItem()) {
            this.validationStore.remove(this.designState.parent.id, this.designState.id, ValidationTypes.BetweenBound);
        }
    }

    @computed
    private get itemPreviewBox() {
        if (this.designState.isImageItem()) {
            const itemLayout = this.extensionSystem.getExtension(this.designState.iid, IDAItemLayoutExtension);
            if (itemLayout) {
                return itemLayout?.previewBox;
            }
        }
        return null;
    }

    dispose() {
        this.removeValidation();
        this.disposeReaction?.();
        super.dispose();
    }
}
