import React from 'react';
import { SvgProps } from '../../features/quad/types';

interface SparkleIconProps extends SvgProps {
    fill?: string;
}

export const SparkleIcon = ({ fill = 'black', ...props }: SparkleIconProps) => {
    return (
        <svg data-testid="sparkleIcon" viewBox="0 0 15 14" fill="none" {...props} pointerEvents="all">
            <path
                d="M11 7L12.0967 4.59667L14.5 3.5L12.0967 2.40333L11 0L9.90333 2.40333L7.5 3.5L9.90333 4.59667L11 7ZM6.625 7.875L5.16667 4.66667L3.70833 7.875L0.5 9.33333L3.70833 10.7917L5.16667 14L6.625 10.7917L9.83333 9.33333L6.625 7.875Z"
                fill={fill}
            />
        </svg>
    );
};
