/* eslint-disable @typescript-eslint/no-explicit-any */
import qs from 'qs';
import { windowExists } from '../../../../utils/envUtils';

export function tryParse<Type>(item: string | null | undefined, fallback: Type): Type {
    if (!item) {
        return fallback;
    }
    try {
        return JSON.parse(item);
    } catch {
        return fallback;
    }
}

export function getQueryParams(search?: string | undefined): { [key: string]: any } {
    if ((search && search.length === 0) || (!search && (!windowExists() || window.location.search.length === 0))) {
        return {};
    }
    const rawParams = search ? qs.parse(search.substring(1)) : qs.parse(window.location.search.substring(1));
    return Object.assign({}, rawParams, {
        selectedOptions: tryParse<Record<string, string | number> | unknown>(rawParams.selectedOptions as string, {}),
        qty: tryParse<number>(rawParams.qty as string, 1),
        owner: rawParams.owner || rawParams.ownerId || undefined,
    });
}

export function addQueryParams(url: string | URL, newParameters: Record<string, string | number | null | undefined>) {
    const realUrl = new URL(url);
    const params = qs.parse(realUrl.search, { ignoreQueryPrefix: true });
    Object.keys(newParameters).forEach((key) => {
        const newValue = newParameters[key];
        if (newValue === null || newValue === undefined) {
            delete params[key];
        } else {
            params[key] = `${newValue}`;
        }
    });
    const newParams = qs.stringify(params);
    return `${realUrl.protocol}//${realUrl.hostname}${realUrl.port ? `:${realUrl.port}` : ''}${
        realUrl.pathname
    }?${newParams}`;
}

export const updateWindowQueryParams = (
    productKey: string,
    productVersion: number,
    selectedOptions: Record<string, string>
) => {
    window.history.replaceState(
        'update-url',
        '',
        addQueryParams(window.location.href, {
            key: productKey,
            productVersion,
            selectedOptions: JSON.stringify(selectedOptions),
        })
    );
};
