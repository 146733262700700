import { useEffect, useState } from 'react';
import { useQuadExperiment } from './useQuadExperiment';
import { useProductParams } from '../providers';
import { useAbTestContext } from '../providers/ABTestProvider';
import { getExperimentProperties } from '../utils/getExperimentProperties';

export enum ExperimentName {
    WhiteBorderValidation = 'white_border_validation_quad',
}

export function useValidationExperiment() {
    const ABTest = useAbTestContext();
    const { productKey } = useProductParams();
    const [isValidationEnabled, setIsValidationEnabled] = useState(false);
    const experiment = useQuadExperiment();

    useEffect(() => {
        if (ABTest) {
            const { experimentKey, isControlVariation } = getExperimentProperties(experiment, ABTest);
            const shouldEnableValidation =
                experimentKey === ExperimentName.WhiteBorderValidation && !isControlVariation;
            setIsValidationEnabled(shouldEnableValidation);
        }
    }, [ABTest, productKey, experiment]);
    return {
        isValidationEnabled,
    };
}
