import React from 'react';
import { FlexBox } from '@vp/swan';
import { HelpLink } from './HelpLink';
import { QuadHeaderMoreOption } from './QuadHeaderMoreOption';

export const HeaderMobileLeftChildren = (): JSX.Element => {
    return (
        <FlexBox gap="3" alignItems="center">
            <QuadHeaderMoreOption />
            <HelpLink />
        </FlexBox>
    );
};
