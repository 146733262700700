import { ImageItem } from '@design-stack-vista/cdif-types';
import { addImage, ItemState, PanelState, removeItems } from '@design-stack-vista/cimdoc-state-manager';
import { ExecuteCommand } from '@design-stack-vista/interactive-design-engine-core';
import { toJS } from 'mobx';
import {
    REVIEW_CONFIRMATION_MODAL_TEXT_CONFIG,
    TextConfig,
    UPLOAD_ASSET_MODAL_TEXT_CONFIG,
    UPLOAD_BACK_DESIGN_MODAL_TYPES,
} from './constants';
import { PanelSides } from '../../../../config/constant';
import { ProductData } from '../../../../hooks/calcifer/useProductConfiguration';
import { getPanelItemIds, noticeError } from '../../../../utils';
import { ColorMode, UpsellPricing } from '../../../../utils/upsell';
import {
    addOrUpdateMetadataDocumentSources,
    removeTemplateAndPlaceholderMetaData,
} from '../../../quad/commands/addPanelMetadata';
import { updatePanelColorMode } from '../../../quad/commands/updatePanelColorMode';

export const getUploadBackDesignModalConfig = (modalType?: UPLOAD_BACK_DESIGN_MODAL_TYPES): TextConfig => {
    if (modalType === UPLOAD_BACK_DESIGN_MODAL_TYPES.UPLOAD_ASSET) {
        return { ...UPLOAD_ASSET_MODAL_TEXT_CONFIG };
    }
    return {
        ...REVIEW_CONFIRMATION_MODAL_TEXT_CONFIG,
    };
};

export const duplicateFrontDesign = async (
    frontPanel: PanelState | undefined,
    backPanel: PanelState | undefined,
    closeModal: () => void,
    executeCommand: ExecuteCommand,
    postAddItemEvents: (panel: PanelState, isImageItem: boolean, showNotification?: boolean) => Promise<void>
) => {
    try {
        if (frontPanel && backPanel) {
            const frontPanelImage = frontPanel.items.find((item) => item.isImageItem()) as
                | ItemState<ImageItem>
                | undefined;
            const backPanelItemIds = getPanelItemIds(backPanel);
            const backPanelId = backPanel.id;
            if (frontPanelImage) {
                const updateColorMode = backPanel.asJson.colorMode === ColorMode.Blank;
                executeCommand((cimDoc) => {
                    if (updateColorMode) {
                        updatePanelColorMode(cimDoc, { panelId: backPanelId, colorMode: ColorMode.Color });
                        addOrUpdateMetadataDocumentSources(cimDoc, { panelId: backPanelId, sourceType: 'FULLBLEED' });
                    }
                    if (backPanelItemIds.length) {
                        removeItems(cimDoc, { ids: backPanelItemIds });
                    }
                    removeTemplateAndPlaceholderMetaData(cimDoc);
                    addImage(cimDoc, {
                        panelId: backPanelId,
                        image: toJS(frontPanelImage.model),
                    });
                }, {});
            }
            closeModal();
            await postAddItemEvents(backPanel, true, !backPanelItemIds.length);
        }
    } catch (error) {
        noticeError(error, {
            method: 'useUploadPanels - duplicateFrontDesign',
        });
        return;
    }
};

export const onClickUpload = (closeModal: () => void, handleUploadButtonClick: () => void) => {
    closeModal();
    handleUploadButtonClick();
};

export const getPanelByName = (panels: PanelState[], panelName: PanelSides) =>
    panels.find((panel) => panel.panelProperties.name.toLowerCase() === panelName);

export const getUpSellPriceInfo = (
    productData: ProductData | undefined,
    backPanel: PanelState | undefined,
    getPricingByPanelName: (panelName: string) => UpsellPricing | undefined
) => {
    let upsellPricing;
    if (backPanel) {
        upsellPricing = getPricingByPanelName(backPanel.panelProperties.name);
    }
    const currency = upsellPricing?.currency || '';
    const quantity = productData?.quantity || 0;
    return { upsellPricing, currency, quantity };
};

export const isPanelEmpty = (panel: PanelState | undefined) => !panel?.items.length;
