import React from 'react';
import { css } from '@emotion/css';
import { Button, FlexBox, Icon, PipeSeparator, Typography } from '@vp/swan';
import classnames from 'classnames';

interface PropTypes extends React.ComponentProps<typeof Button> {
    active: boolean;
}

const buttonContainerStyle = css`
    order: 2;
    white-space: no-wrap;
`;

const buttonStyle = css`
    white-space: nowrap;
`;

export const ShowMoreButton = (props: PropTypes): JSX.Element => {
    const { active, children, className, ...rest } = props;

    return (
        <FlexBox flexWrap="nowrap" className={classnames('show-more-button-container', buttonContainerStyle)}>
            <PipeSeparator />
            <Button
                className={classnames('show-more-button', 'simple', buttonStyle, className, {
                    active,
                })}
                skin="unstyled"
                {...rest}
            >
                {children && (
                    <Typography as="span" className="show-more-text" fontSize="small">
                        {children}
                    </Typography>
                )}
                <Icon iconType={active ? 'caretUp' : 'caretDown'} />
            </Button>
        </FlexBox>
    );
};
