import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useSelectedItems } from '@design-stack-vista/core-features';
import { useProductConfiguration } from './calcifer';
import { useHandleCustomExperiences } from './useHandleCustomExperiences';
import { useNotificationFramework } from '../components/Notification';
import { PRICING_NOTIFICATION_DISMISS_TIMEOUT } from '../config/constant';
import { useTemplateData } from '../features/BackDesignTemplate';
import { useBackConfirmationContext, useUploadModal, useUpsellPrice } from '../providers';
import { getImageItemFromPanel } from '../utils';
import { text } from '../utils/localization';

export const useAddItemEvents = () => {
    const { setSelectedIds } = useSelectedItems();
    const { setShowUploadModal } = useUploadModal();
    const { notifyCustomer, removeAllNotifications } = useNotificationFramework();
    const { handleAddImageExperiences } = useHandleCustomExperiences();
    const { updateCustomerSelectedOptions } = useProductConfiguration();
    const { getFormattedDiscountPrice, isUpsellPanelAdded, surfaceUpsells } = useUpsellPrice();
    const { setOpenBackUploadModal } = useBackConfirmationContext();
    const { getTemplateMetaData } = useTemplateData();

    const handleImageSelection = (panel: PanelState) => {
        const imageItem = getImageItemFromPanel(panel);

        if (imageItem) {
            setSelectedIds([imageItem.id]);
        } else {
            throw new Error('No image found on panel to select');
        }
    };

    const handleProductUpdateOnUpsell = (panel: PanelState) => {
        if (surfaceUpsells) {
            const surfaceUpsell = surfaceUpsells[panel.panelProperties.name];

            if (surfaceUpsell) {
                const newOptions = { [surfaceUpsell.optionName]: surfaceUpsell.colorOption };
                updateCustomerSelectedOptions(newOptions);
            }
        }
    };

    const handleUpsellNotification = (panel: PanelState) => {
        const isUpsell = isUpsellPanelAdded(panel.id);
        const discountPrice = getFormattedDiscountPrice(panel.panelProperties.name);
        const templateMetaData = getTemplateMetaData();

        if (isUpsell && discountPrice) {
            notifyCustomer(
                {
                    notificationType: 'positive',
                    messageToShowCustomer: text('backDesignAdded', {
                        formattedPrice: `+${discountPrice}`,
                    }),
                },
                templateMetaData.length ? false : true,
                PRICING_NOTIFICATION_DISMISS_TIMEOUT
            );
        }
    };

    const preAddImageEvents = () => {
        setShowUploadModal(false);
        removeAllNotifications();
    };

    const postAddImageEvents = async (panel: PanelState): Promise<void> => {
        handleImageSelection(panel);
        await handleAddImageExperiences(panel);
    };

    const postAddItemEvents = async (
        panel: PanelState,
        isImageItem: boolean,
        showPriceUpdateNotification = true
    ): Promise<void> => {
        setOpenBackUploadModal(false);

        if (showPriceUpdateNotification) {
            handleProductUpdateOnUpsell(panel);
            handleUpsellNotification(panel);
        }

        if (isImageItem) {
            await postAddImageEvents(panel);
        }
    };

    return {
        preAddImageEvents,
        postAddItemEvents,
    };
};
