import { action, makeObservable, observable } from 'mobx';
import { ColorPreview, DesignTemplate } from '../types';

class BackDesignTemplateStore {
    @observable assetId = '';
    @observable colorPreviews: ColorPreview[] = [];
    @observable designTemplates: DesignTemplate[] = [];
    @observable isColorLoading = false;
    @observable isDesignTemplateLoading = false;
    @observable selectedDesignTemplate: DesignTemplate | null = null;
    @observable showMakeEditModal = false;
    @observable showCanvasTemplateLoader = false;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setAssetId(assetId: string) {
        this.assetId = assetId;
    }

    @action.bound
    setColorPreviews(colorPreviews: ColorPreview[]) {
        this.colorPreviews = colorPreviews;
    }

    @action.bound
    setDesignTemplates(designTemplates: DesignTemplate[]) {
        this.designTemplates = designTemplates;
    }

    @action.bound
    setIsColorLoading(status: boolean) {
        this.isColorLoading = status;
    }

    @action.bound
    setIsDesignTemplateLoading(status: boolean) {
        this.isDesignTemplateLoading = status;
    }

    @action.bound
    setSelectedDesignTemplate(designTemplate: DesignTemplate | null) {
        this.selectedDesignTemplate = designTemplate;
    }

    @action.bound
    openMakeEditModal() {
        this.showMakeEditModal = true;
    }

    @action.bound
    closeMakeEditModal() {
        this.showMakeEditModal = false;
    }

    @action.bound
    setShowCanvasTemplateLoader(status: boolean) {
        this.showCanvasTemplateLoader = status;
    }

    @action.bound
    handleTemplateExperience() {
        this.selectedDesignTemplate = null;
    }
}

export const backDesignTemplateStore = new BackDesignTemplateStore();
