import React from 'react';
import { css } from '@emotion/css';
import {
    Button,
    ModalDialog,
    ModalDialogBody,
    ModalDialogButtons,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogHeader,
    ModalDialogNav,
    tokens,
    Typography,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { text } from '../../utils/localization';

interface MobileFinishDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
    onChange: () => void;
    descriptionMessage: string;
}

const mobileFinishDialogStyle = css`
    // This may not be required after upgrading to swan 3.VL
    .swan-modal-dialog.swan-modal-dialog-panel.swan-modal-dialog-panel-bottom {
        border-radius: ${tokens.SwanSemBorderRadiusContainer} ${tokens.SwanSemBorderRadiusContainer} 0 0;
    }
    .swan-modal-dialog-buttons .swan-button {
        margin: 0 ${tokens.SwanSemSpace1};
    }
`;

export const MobileFinishDialog = observer(
    ({ descriptionMessage, isOpen, onChange, onClose, onDelete }: MobileFinishDialogProps) => {
        const { isSmall } = useScreenLayout();

        return (
            <ModalDialog
                isOpen={isOpen}
                variant="panel-bottom"
                onRequestDismiss={onClose}
                data-testid="mobile-premium-finish-popover-modal"
                className={mobileFinishDialogStyle}
                onlyRenderWhenOpen
            >
                <ModalDialogContent>
                    <ModalDialogNav>
                        <ModalDialogCloseButton accessibleText={text('closeAccessibilityLabel')} />
                    </ModalDialogNav>
                    <ModalDialogHeader mb="5">
                        <Typography fontSize="x2large" fontWeight="bold">
                            {text('premiumFinish')}
                        </Typography>
                    </ModalDialogHeader>
                    <ModalDialogBody mb="7">
                        <Typography>{descriptionMessage}</Typography>
                    </ModalDialogBody>
                    <ModalDialogFooter>
                        <ModalDialogButtons>
                            <Button
                                data-testid="change-button"
                                width="full-width"
                                size={isSmall ? 'mini' : 'standard'}
                                mb="4"
                                skin="primary"
                                onClick={onChange}
                            >
                                <Typography fontSize="small">{text('change')}</Typography>
                            </Button>
                            <Button
                                data-testid="finish-delete-button"
                                width="full-width"
                                size={isSmall ? 'mini' : 'standard'}
                                skin="secondary"
                                onClick={onDelete}
                                mb="3"
                            >
                                <Typography fontSize="small">{text('deleteButton')}</Typography>
                            </Button>
                        </ModalDialogButtons>
                    </ModalDialogFooter>
                </ModalDialogContent>
            </ModalDialog>
        );
    }
);
