import React from 'react';
import { InfiniteScrollContainer, useUploadManager } from '@design-stack-vista/upload-components';
import { Divider, Typography } from '@vp/swan';
import { RecentImagesDrawer } from './RecentImagesDrawer';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { text } from '../../utils/localization';
import { DesktopExperience, MobileExperience } from '../Experiences';

interface RecentImagesInterface {
    panelId: string;
}

export const RecentImages = ({ panelId }: RecentImagesInterface) => {
    const { isSmall } = useScreenLayout();
    const { hasAssets } = useUploadManager();

    if (!hasAssets) {
        return null;
    }

    return (
        <>
            <Divider my={{ xs: '5', sm: '6' }} />
            <Typography fontSize="xsmall" fontWeight={isSmall ? 'bold' : 'normal'} textColor="subtle" mb="5">
                {text('recentlyUploaded')}
            </Typography>
            <MobileExperience>
                <RecentImagesDrawer panelId={panelId} />
            </MobileExperience>
            <DesktopExperience>
                <InfiniteScrollContainer infiniteScrollId="upload-infinite-scroll-component">
                    <RecentImagesDrawer panelId={panelId} />
                </InfiniteScrollContainer>
            </DesktopExperience>
        </>
    );
};
