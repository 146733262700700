import { UnitlessDimensions } from '@design-stack-ct/utility-core';
import { getDesignViews } from '../../../api/designViews';
import { ProductAttributes } from '../../../config/constant';
import { ProductData } from '../../../hooks/calcifer/useProductConfiguration';
import { getProductOptionsList } from '../../Flexibility/common';
import { ImageOrientations } from '../constants';
import { OrientationsSizesMap, ProductSizes } from '../types';

const BEST_FITS_SLICE = 3;

/**
 *
 * @description if the detected orientation is horizontal and the image height is greater than its width swap height and width,
 * if the detected orientation is vertical and the image width is greater than its height swap height and width,
 * else don't swap
 */
const swapImageDimensions = (detectedOrientation: string, imageDimension: UnitlessDimensions) => {
    if (detectedOrientation === ImageOrientations.Vertical && imageDimension.width > imageDimension.height) {
        return true;
    }
    if (detectedOrientation === ImageOrientations.Horizontal && imageDimension.height > imageDimension.width) {
        return true;
    }
    return false;
};

/**
 *
 * @param imageDimension represents width and height of the image
 * @param detectedOrientation represents processed and compatible orientation of the image
 * @param productSizes represents the width and height of the various products (designView)
 * @description compares and sorts the productSizes based on the aspect ratio of the image
 *
 */
const compareAndSortByAspectRatio = (
    imageDimension: UnitlessDimensions,
    productSizes: ProductSizes,
    detectedOrientation: string
) => {
    const imageAspectRatio = swapImageDimensions(detectedOrientation, imageDimension)
        ? imageDimension.height / imageDimension.width
        : imageDimension.width / imageDimension.height;

    /**
     * Generate aspect-ratio of the documents and group them
     * based on the document aspect-ratio
     *
     */
    const documentAspectRatio: Record<number, string[]> = {};

    Object.keys(productSizes).forEach((docDim) => {
        const { height, width } = productSizes[docDim];
        const aspectRatio = width / height;
        const aspVal = documentAspectRatio[aspectRatio] ?? [];
        documentAspectRatio[aspectRatio] = [...aspVal, docDim];
    });

    /**
     * Sorting aspect-ratios
     */

    const sortedAspectRatios = Object.keys(documentAspectRatio).sort((a, b) => {
        return Math.abs(imageAspectRatio - Number(a)) - Math.abs(imageAspectRatio - Number(b));
    });

    const sortedProductSizes: string[] = [];
    sortedAspectRatios.forEach((aspectRatio) => {
        const aspectRatioOptions = documentAspectRatio[Number(aspectRatio)];
        aspectRatioOptions.forEach((aspectRatioOption) => sortedProductSizes.push(aspectRatioOption));
    });

    return sortedProductSizes;
};

export const getAspectRatioSortedProductOptions = async (
    orientation: string,
    imageDimension: UnitlessDimensions,
    orientationSizesMap: OrientationsSizesMap,
    sizeAttribute: string,
    locale: string,
    productData: ProductData
) => {
    const sizes = orientationSizesMap[orientation] ?? [];

    if (sizes.length === 0) {
        throw new Error('Could not find product sizes for the detected orientation');
    }

    const { productKey, productVersion } = productData;
    const productAttributes = sizes.map((size) => {
        return {
            [sizeAttribute]: size,
            [ProductAttributes.Orientation]: orientation,
        };
    });
    const productOptionsList = getProductOptionsList(productData, productAttributes);

    const designViewPromises = productOptionsList.map((productOptions) =>
        getDesignViews(locale, productKey, productVersion, productOptions)
    );
    const designViewResponses = await Promise.all(designViewPromises);

    const productSizes = sizes.reduce((acc, size, index) => {
        return {
            ...acc,
            [size]: {
                height: designViewResponses[index]?.designViews[0].heightCm,
                width: designViewResponses[index]?.designViews[0].widthCm,
            },
        };
    }, {});

    const sortedProductSizes = compareAndSortByAspectRatio(imageDimension, productSizes, orientation);

    return {
        bestFits: sortedProductSizes.slice(0, BEST_FITS_SLICE),
        others: sortedProductSizes.slice(BEST_FITS_SLICE),
    };
};
