/**
 * Studio Reference -
 * {@link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/15c4cbfbae0ecdc14624af2c67833dd42c1259a6/src/shared/utils/Scene/easelSceneClient.ts}
 */

import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { DSS } from '@vp/types-ddif';
import { SceneConfig } from '../types/sceneTypes';

export function findScene(
    panel: PanelState,
    index: number,
    documentPanels: PanelState[],
    possibleScenes: SceneConfig[],
    surface?: DSS.DesignView
) {
    // find the scene tagged for this panel
    const specificScene = possibleScenes.find(
        (scene) => scene.name && scene.name.toLowerCase() === panel?.panelProperties.name.toLowerCase()
    );
    if (specificScene) {
        return specificScene.url;
    }

    const originalLocationName = surface?.docAdditionalData.originalLocation;
    if (originalLocationName) {
        const sceneForLocationName = possibleScenes.find(
            (scene) => scene.name && scene.name.toLowerCase() === originalLocationName.toLowerCase()
        );
        if (sceneForLocationName) {
            return sceneForLocationName.url;
        }
    }

    // find a generic scene tagged for no specific panels - this can happen for products like kraft business cards
    // where the underlay is not a photographic scene but a texture
    const genericScene = possibleScenes.find((scene) => !scene.name);
    if (genericScene) {
        return genericScene.url;
    }

    // sometimes scenes are not named correctly.  just fallback to choosing a scene in the correct order
    if (possibleScenes[index] && possibleScenes.length === documentPanels.length) {
        return possibleScenes[index].url;
    }

    return undefined;
}
