import { useCallback } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { ACTION_NAME, DEFAULT_PRODUCT_QUANTITY, QUAD_TRACKING_EVENTS } from '../constant';

export const useProductPageViewed = () => {
    const { productData, productPrice } = useProductConfiguration();
    const { fireEvent } = useCustomEventPropagation();
    const baseDiscountedPrice = productPrice?.totalDiscountPrice;
    const baseListPrice = productPrice?.totalListPrice;

    const trackProductView = useCallback(() => {
        if (productData?.quantity && baseListPrice && baseDiscountedPrice) {
            fireEvent({
                name: ACTION_NAME.PRODUCT_VIEWED,
                extraData: {
                    label: QUAD_TRACKING_EVENTS.PRODUCT_VIEWED_EVENT,
                    quantity: DEFAULT_PRODUCT_QUANTITY,
                    sales_quantity: productData?.quantity,
                    list_price: baseListPrice,
                    price: baseDiscountedPrice,
                },
            });
        }
    }, [productData?.quantity, baseListPrice, baseDiscountedPrice]);

    return { trackProductView };
};
