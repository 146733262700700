import { cm2mm } from '@design-stack-ct/utility-core';
import { Guide, Mask, Path, Point } from '@design-stack-vista/ida-framework';
import { BaseDesignRequirements } from '@design-stack-vista/interactive-design-engine-core';
import { MCP } from '@vp/types-ddif';

export interface MaskWithBoundingArea extends Mask {
    boundingArea?: {
        type?: string;
        position?: {
            x?: number;
            y?: number;
            width?: number;
            height?: number;
        };
    };
}

export interface GuideWithBoundingArea extends Guide {
    boundingArea?: {
        type?: string;
        position?: {
            x?: number;
            y?: number;
            width?: number;
            height?: number;
        };
    };
}

export function normalizeDesignViews(
    designView: MCP.SurfaceSpecificationSvcModelsV3CalculatedSurface[]
): BaseDesignRequirements {
    const baseDeso: BaseDesignRequirements = {
        panels: designView.map((d) => {
            return {
                id: d.id,
                name: d.name,
                width: d.widthCm,
                height: d.heightCm,
                decorationTechnology: d.processType,
                masks: d.docAdditionalData ? createMask(d.docAdditionalData.masks) : [],
                guides: d.docAdditionalData ? crateGuide(d.docAdditionalData.guides) : [],
            };
        }),
    };

    return baseDeso;
}

function createMask(masks: MCP.SurfaceSpecificationModelsV3CalculatedPathGroup[] | undefined): MaskWithBoundingArea[] {
    if (masks) {
        return masks.map((mask) => {
            return {
                paths: createPath(mask.paths),
                type: mask.pathType,
                boundingArea: mask.boundingArea,
            };
        });
    }
    return [];
}

function crateGuide(
    guides: MCP.SurfaceSpecificationModelsV3CalculatedPathGroup[] | undefined
): GuideWithBoundingArea[] {
    if (guides) {
        return guides.map((guide) => {
            return {
                type: guide.pathType,
                paths: createPath(guide.paths),
                boundingArea: guide.boundingArea,
            };
        });
    }
    return [];
}

function createPath(mcpPaths: MCP.SurfaceSpecificationModelsV3CalculatedPath[]): Path[] {
    return mcpPaths.map((mcpPath) => {
        return {
            points: createPathPoints(mcpPath.pathPoints),
            anchor: { x: cm2mm(mcpPath.anchorX), y: cm2mm(mcpPath.anchorY) },
        };
    });
}

function createPathPoints(points: MCP.SurfaceSpecificationModelsV3CalculatedPathPoint[]): Point[] {
    return points.map((c) => {
        const hasControlPoints =
            c.firstControlPointX != null &&
            c.firstControlPointY != null &&
            c.secondControlPointX != null &&
            c.secondControlPointY != null;

        if (hasControlPoints) {
            return {
                x: cm2mm(c.endPointX),
                y: cm2mm(c.endPointY),
                controlPoints: {
                    first: {
                        x: cm2mm(c.firstControlPointX as number),
                        y: cm2mm(c.firstControlPointY as number),
                    },
                    second: {
                        x: cm2mm(c.secondControlPointX as number),
                        y: cm2mm(c.secondControlPointY as number),
                    },
                },
            };
        }

        return {
            x: cm2mm(c.endPointX),
            y: cm2mm(c.endPointY),
            controlPoints: undefined,
        };
    });
}
