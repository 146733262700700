import type { FocusEvent, MouseEvent, ReactNode, TouchEvent } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createIdGenerator } from '@design-stack-ct/utility-core';
import { useClickOutside } from '@design-stack-vista/utility-react';
import { css } from '@emotion/css';
import { className, Span, tokens } from '@vp/swan';
import { createPortal } from 'react-dom';
import type { Modifier } from 'react-popper';
import { Manager, Popper, Reference } from 'react-popper';

export type TooltipPlacement = 'auto' | 'top' | 'bottom' | 'left' | 'right';
export type TooltipTrigger = 'hover' | 'click' | 'focus';

const stylesContainer = css`
    border-radius: ${tokens.SwanSemBorderRadiusContainer};
    padding: ${tokens.SwanSemSpace5};
    z-index: 1;
    font-size: ${tokens.SwanSemFontSizeXsmall};
    background: ${tokens.SwanSemColorBgStandard};
    color: ${tokens.SwanSemColorTextStandard};
    width: 288px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;

export interface TooltipProps {
    /** The element(s) that will trigger the tooltip */
    children: ReactNode | ReactNode[];
    /** optional aria-label for trigger e.g useful with images */
    triggerAriaLabel?: string;
    /** Content of the tooltip */
    content: ReactNode | string;
    /**
     * Allows opening and closing the tooltip programmatically
     * @defaultValue `undefined`
     */
    open?: boolean;
    /**
     * Position of the tooltip relative to the triggering element(s)
     * @defaultValue `'auto'`
     */
    placement?: TooltipPlacement;
    /**
     * What event the tooltip will react to. You can specify more than one event in an array
     * @defaultValue `'hover'`
     */
    trigger?: TooltipTrigger | TooltipTrigger[];
    /**
     * Enable pointer movement between tooltip trigger and tooltip by using delay on mouse leave
     */
    mouseLeaveDelay?: number;
    /**
     * Padding applied around tooltip to prevent overflow out of the viewport. Negative numbers are ignored
     * @defaultValue `0`
     */
    viewportPadding?: number;
    /**
     * Adds a className to the container element of the tooltip
     */
    tooltipContainerClassName?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialPopperModifiers: Modifier<any>[] = [
    {
        name: 'offset',
        options: {
            offset: [0, 9],
        },
    },
    {
        name: 'arrow',
        options: {
            padding: 3,
        },
    },
];

const generateId = createIdGenerator('quad-tooltip');

export function Tooltip({
    open: openProp = undefined,
    content,
    placement = 'auto',
    trigger = 'hover',
    children,
    triggerAriaLabel,
    mouseLeaveDelay = 0,
    viewportPadding = 0,
    tooltipContainerClassName,
}: TooltipProps) {
    const { current: isControlled } = useRef(openProp !== undefined);
    const [openState, setOpenState] = useState<boolean | null>(null);
    const isHovered = useRef<boolean>(false);
    const triggerRef = useRef<HTMLSpanElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const tooltipId = useMemo(() => generateId(), []);
    const popperModifiers =
        viewportPadding > 0
            ? [
                  ...initialPopperModifiers,
                  {
                      name: 'preventOverflow',
                      options: {
                          padding: viewportPadding,
                      },
                  },
              ]
            : initialPopperModifiers;

    const open = isControlled ? openProp : openState;

    const showTooltip = (event: MouseEvent<HTMLElement> | TouchEvent<HTMLElement> | FocusEvent<HTMLElement>) => {
        event.preventDefault();
        setOpenState(true);
    };

    const hideTooltip = () => {
        setOpenState(false);
    };

    const toggleTooltip = () => {
        setOpenState((prevState) => !prevState);
    };

    const isTriggeredBy = useCallback(
        (event: TooltipTrigger) => trigger === event || (Array.isArray(trigger) && trigger.includes(event)),
        [trigger]
    );

    const onMouseEnter = (event: MouseEvent<HTMLElement>) => {
        isHovered.current = true;
        showTooltip(event);
    };

    const onMouseLeave = () => {
        isHovered.current = false;
        if (mouseLeaveDelay) {
            setTimeout(() => {
                if (!isHovered.current) {
                    hideTooltip();
                }
            }, mouseLeaveDelay);
        } else {
            hideTooltip();
        }
    };

    const tabindex = {
        ...(isTriggeredBy('focus') && { tabIndex: 0 }),
    };

    const triggerHandlers = {
        ...(isTriggeredBy('click') && {
            onClick: toggleTooltip,
        }),
        ...(isTriggeredBy('hover') && {
            onMouseEnter,
            onMouseLeave,
            onTouchStart: showTooltip,
        }),
        ...(isTriggeredBy('focus') && {
            onFocus: showTooltip,
            onBlur: hideTooltip,
        }),
    };

    const tooltipHandlers = {
        ...(isTriggeredBy('hover') && {
            onMouseEnter,
            onMouseLeave,
        }),
    };

    useClickOutside(
        {
            elementRef: [triggerRef, tooltipRef],
            shouldAddEventListener: !isControlled && isTriggeredBy('click') && !!openState,
        },
        () => {
            hideTooltip();
        },
        [hideTooltip]
    );

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        const ESCAPE = 'escape';
        const loweredKey = event.key.toLowerCase();
        if (loweredKey === ESCAPE) {
            toggleTooltip();
        }
    }, []);

    useEffect(() => {
        open && isTriggeredBy('focus') && window.addEventListener('keydown', handleKeyDown);

        return () => {
            isTriggeredBy('focus') && window.removeEventListener('keydown', handleKeyDown);
        };
    }, [open, handleKeyDown, isTriggeredBy]);

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <>
                        <span
                            ref={ref}
                            className="quad-tooltip__trigger"
                            aria-describedby={tooltipId}
                            role="button"
                            aria-label={triggerAriaLabel}
                            data-testid="tooltip-trigger"
                            {...tabindex}
                            {...triggerHandlers}
                        >
                            <span ref={triggerRef}>{children}</span>
                        </span>
                        {/* in order to be readable by accessibility technology, because it doesnt work with createPortal */}
                        <Span id={tooltipId} visuallyHidden role="tooltip">
                            {content}
                        </Span>
                    </>
                )}
            </Reference>

            {open &&
                createPortal(
                    <Popper innerRef={tooltipRef} placement={placement} modifiers={popperModifiers}>
                        {({ ref, style }) => (
                            <div
                                ref={ref}
                                aria-hidden="true"
                                className={className(stylesContainer, tooltipContainerClassName)}
                                data-testid="tooltip-window"
                                style={style}
                                {...tooltipHandlers}
                            >
                                {content}
                            </div>
                        )}
                    </Popper>,
                    document.body
                )}
        </Manager>
    );
}
