import React from 'react';
import { css } from '@emotion/css';
import {
    FlexBox,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogNav,
    ModalDialogTitle,
    tokens,
    tokensRaw,
    Typography,
} from '@vp/swan';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { text } from '../../utils/localization';
import { FINISH_PREVIEW_FORMAT } from '../PremiumFinish';
import { FooterButton } from '../PremiumFinish/Modal/FooterButton';
import { FINISH_PREVIEW_SCENEType } from '../PremiumFinish/type';
import { BackNavigationalButton } from '../quad/components/BackNavigationalButton';

const BODY_WIDTH = '440px';

const getNavigationalModalStyle = (isMedium: boolean) => css`
    &.swan-modal-dialog,
    &.swam-modal-dialog-panel,
    &.swan-modal-dialog-pinned {
        padding: 0;
        max-width: 1160px;
        flex-direction: row;

        .navigational-modal-body {
            overflow: auto;
            width: ${BODY_WIDTH};
            position: relative;
            .swan-modal-dialog-nav {
                position: sticky;
                top: 0px;
                margin: 0;
                padding: ${tokens.SwanSemSpace6} 0 ${tokens.SwanSemSpace5} 0;
            }

            .swan-modal-dialog-body {
                margin-bottom: 0;
            }

            .swan-modal-dialog-footer {
                background-color: ${tokens.SwanSemColorBgStandard};
                position: sticky;
                bottom: 0px;
            }
        }
    }
    .preview {
        background-color: ${tokens.SwanSemColorBgStrong};
        z-index: 2;
        width: 720px;
        @media only screen and (max-width: ${tokensRaw.SwanBaseBreakpointSmEnd}) {
            width: 519px;
        }
        @media only screen and (max-width: ${tokensRaw.SwanBaseBreakpointMdEnd}) {
            width: 463px;
        }

        background: ${tokens.SwanSemColorBgStandard};

        video {
            width: 100%;
            object-fit: ${isMedium ? 'contain' : 'cover'};
            height: 100%;
            object-position: 30%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

const getTransitionStyle = (isFirstLevel: boolean) => {
    const animationName = isFirstLevel ? 'left-to-right' : 'right-to-left';
    const transitionalValue = isFirstLevel ? '64px' : '32px';

    return css`
        flex: 1;
        z-index: 0;
        -webkit-animation: linear 0.15s;
        -webkit-animation-name: ${animationName};
        -webkit-animation-duration: 0.15s;
        @-webkit-keyframes ${animationName} {
            0% {
                right: 100%;
            }
            100% {
                right: ${transitionalValue};
            }
        }
        animation: linear 0.15s;
        animation-name: ${animationName};
        animation-duration: 0.15s;
        @keyframes ${animationName} {
            0% {
                right: 100%;
            }
            100% {
                right: ${transitionalValue};
            }
        }
    `;
};

interface TransitionalPreviewModalProps {
    isOpen: boolean;
    onRequestDismiss: () => void;
    headerKey: string;
    subHeaderKey?: string;
    children: JSX.Element;
    footer: {
        labelKey: string;
        disabled: boolean;
        onClick: () => Promise<void>;
        isLoading?: boolean;
    };
    navigation: {
        labelKey: string;
        show: boolean;
        onClick: () => void;
    };
    preview: FINISH_PREVIEW_SCENEType;
    dataTestId?: string;
}

export const TransitionalPreviewModal = ({
    children,
    footer,
    headerKey,
    subHeaderKey,
    navigation,
    preview,
    isOpen,
    onRequestDismiss,
    dataTestId,
}: TransitionalPreviewModalProps) => {
    const { isSmall, isMedium } = useScreenLayout();

    return (
        <ModalDialog
            data-testid={dataTestId}
            variant="panel-right"
            isOpen={isOpen}
            onRequestDismiss={onRequestDismiss}
            onlyRenderWhenOpen
        >
            <ModalDialogContent
                className={getNavigationalModalStyle(isMedium)}
                aria-labelledby="modal dialog panel"
                display={'flex'}
            >
                {!isSmall && preview && (
                    <FlexBox alignItems="center" className="preview">
                        {preview?.type === FINISH_PREVIEW_FORMAT.IMAGE ? (
                            <img src={preview.sourceUrl} alt={'preview'} />
                        ) : (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            <video loop autoPlay={true} src={preview.sourceUrl} />
                        )}
                    </FlexBox>
                )}
                <FlexBox flexDirection="column" className="navigational-modal-body" px={{ xs: '5', sm: '7' }}>
                    <ModalDialogNav>
                        {navigation.show && (
                            <BackNavigationalButton
                                onBack={navigation.onClick}
                                buttonText={text(navigation.labelKey)}
                            />
                        )}
                        <ModalDialogCloseButton accessibleText={text('closeAccessibilityLabel')} />
                    </ModalDialogNav>
                    <FlexBox flexDirection="column" className={getTransitionStyle(!navigation.show)}>
                        <ModalDialogTitle fontWeight="bold" mb={subHeaderKey ? '5' : '7'}>
                            {text(headerKey)}
                        </ModalDialogTitle>
                        {subHeaderKey ? <Typography mb="7">{text(subHeaderKey)}</Typography> : null}
                        <ModalDialogBody>{children}</ModalDialogBody>
                    </FlexBox>
                    <ModalDialogFooter py="6">
                        <FooterButton
                            onClick={footer.onClick}
                            disabled={footer.disabled}
                            buttonText={text(footer.labelKey)}
                            isLoading={footer.isLoading}
                        />
                    </ModalDialogFooter>
                </FlexBox>
            </ModalDialogContent>
        </ModalDialog>
    );
};
