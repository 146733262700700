import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { ProcessType, SingleColorTypes } from '../../../config/constant';

export const useIsEligibleForAdjustColor = () => {
    const isEligibleForAdjustColor = (panel: PanelState) => {
        return SingleColorTypes.includes(panel.panelProperties.decorationTechnology as ProcessType);
    };

    return { isEligibleForAdjustColor };
};
