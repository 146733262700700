import { fetchSiteFlowData, SiteFlowParams, SiteFlowResponse } from '@design-stack-vista/siteflow-client';
import { QueryFunctionContext } from '@tanstack/react-query';
import { noticeError } from '../utils';

type SiteFlowNextStepQueryKey = [string, SiteFlowParams];

export const SITEFLOW_NEXT_STEP_QUERY_KEY = 'siteflowNextStepByMPVID';

export const queryGetNextStep = (
    context: QueryFunctionContext<SiteFlowNextStepQueryKey>
): Promise<SiteFlowResponse> => {
    const { queryKey } = context;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, { locale, requestor, productKey, selectedOptions, nextStep, siteContext, optimizelyEndUserId }] =
        queryKey;

    try {
        return fetchSiteFlowData({
            locale,
            requestor,
            productKey,
            selectedOptions,
            nextStep,
            siteContext,
            optimizelyEndUserId,
        } as SiteFlowParams);
    } catch (e) {
        noticeError(e, {
            method: 'queryGetNextStep',
        });
        throw e;
    }
};
