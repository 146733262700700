import React, { useCallback } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { observer } from 'mobx-react-lite';
import { UPLOAD_BACK_DESIGN_MODAL_TYPES, useUploadBackDesignModal } from './components/UploadBackDesignModal';
import { DesignApproveButton } from './DesignApproveButton';
import { BACK } from '../../config/constant';
import { isBlankAndHasNoUpsells } from '../../hooks/useDesignablePanels';
import {
    useBackConfirmationContext,
    useDesignReviewContext,
    usePremiumFinishModal,
    usePremiumFinishStore,
    useProductOptionsTracking,
    useProductParams,
    useUpsellPrice,
} from '../../providers';
import { text } from '../../utils/localization';
import { getReviewInstruction } from '../PremiumFinish';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../TrackingEvents/constant';
import { GA_EVENTS } from '../TrackingEvents/GAEvents';
import { useTrackNewPage } from '../TrackingEvents/hooks';
import { useContinueButtonVCS } from '../VCS/hooks/useContinueButtonVCS';
import { isVCSConsumer } from '../VCS/utils/isVCSConsumer';

export const FinishButton = observer(() => {
    const {
        cimDocStore: { panels, asJson },
    } = useDesignEngine();
    const { trackNewPage } = useTrackNewPage();
    const { surfaceUpsells } = useUpsellPrice();
    const { fireEvent } = useCustomEventPropagation();
    const { isBackVisited } = useBackConfirmationContext();
    const { isItemInCart, cartQuantity } = useProductParams();
    const { isPremiumFinishProduct } = usePremiumFinishStore();
    const productOptionsTracking = useProductOptionsTracking();
    const { setIsOpenDesignReviewModal } = useDesignReviewContext();
    const { showUploadBackDesignModal } = useUploadBackDesignModal();
    const { openPremiumFinishConfirmationModal } = usePremiumFinishModal();
    const { handleFinishForVCS } = useContinueButtonVCS();

    const reviewInstruction = getReviewInstruction(asJson);

    // TODO: have to use this logic for handling products with different name or for multiple panels
    const backPanel = panels.find((panel) => panel.panelProperties.name === BACK);
    const backPanelImage = backPanel?.items.find((item) => item.isImageItem())?.id;

    const handleReviewDesign = useCallback(async () => {
        if (isPremiumFinishProduct && (!reviewInstruction || !reviewInstruction.finishOption)) {
            openPremiumFinishConfirmationModal();
            return;
        }

        const openUploadBackDesignModal =
            !isBackVisited && backPanel && !backPanelImage && !isBlankAndHasNoUpsells(backPanel, surfaceUpsells);

        if (openUploadBackDesignModal) {
            fireEvent({
                name: ACTION_NAME.DESIGN_TOOL_USED,
                extraData: {
                    experimentLabel: QUAD_TRACKING_EVENTS.OPEN_NUDGE_TO_BACK,
                    eventDetail: GA_EVENTS.VIEW_NUDGE_PANEL.replace('[CTA1 TEXT]', 'Finish').replace(
                        '[CTA2 TEXT]',
                        QUAD_TRACKING_EVENTS.CLICK_DESIGN_BACK
                    ),
                    pageStage: PageStage.Confirmation,
                    pageSection: PageSection.NudgeToBackModal,
                },
            });
            showUploadBackDesignModal(UPLOAD_BACK_DESIGN_MODAL_TYPES.REVIEW_CONFIRMATION);
            return;
        }

        if (isVCSConsumer()) {
            await handleFinishForVCS();
            return;
        }

        const eventDetail = GA_EVENTS.FINISH_PDC_REDIRECTION.replace('[pagePath]', 'quick-upload-and-design')
            .replace('[destinationPath]', 'quick-upload-and-design:Review')
            .replace('[CTA TEXT]', 'Finish');

        fireEvent({
            name: ACTION_NAME.NAVIGATION_CLICKED,
            extraData: {
                eventDetail,
                navigationDetail: 'Moved to review page',
                pageStage: PageStage.Confirmation,
                pageSection: PageSection.PreviewAndProof,
            },
        });

        trackNewPage('Review');
        setIsOpenDesignReviewModal(true);
        productOptionsTracking.trackProductOptionsSelected();
    }, [
        trackNewPage,
        isPremiumFinishProduct,
        reviewInstruction,
        isBackVisited,
        backPanel,
        backPanelImage,
        surfaceUpsells,
        handleFinishForVCS,
    ]);

    const getFinishButtonTitle = () => (isItemInCart && cartQuantity > 0 ? text('updateCartBtn') : text('finishBtn'));

    return (
        <DesignApproveButton
            data-testid="upload-finish-btn"
            title={getFinishButtonTitle()}
            onClick={handleReviewDesign}
        />
    );
});
