import React, { useState } from 'react';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { observer } from 'mobx-react-lite';
import { ChangeSizePanelContent } from './ChangeSizePanelContent';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { text } from '../../../utils/localization';
import { FlexibilityDocuments, FlexibilityModal, useFlexibilityDesignDocuments } from '../../Flexibility/common';
import { useChangeSizeModal } from '../hooks';
import { changeSizeStore } from '../store';

export const ChangeSizeModal = observer(() => {
    const { productData } = useProductConfiguration();
    const { getFlexibilityDocuments } = useFlexibilityDesignDocuments();
    const [changeSizeFlexibilityDocuments, setChangeSizeFlexibilityDocuments] = useState<
        FlexibilityDocuments | undefined
    >(undefined);
    const { isChangeSizeModalOpen, changeSizeChoiceGroups } = changeSizeStore;
    const { onChangeSizeButtonClick, closeChangeSizeModal, flexibilityDocument } =
        useChangeSizeModal(changeSizeFlexibilityDocuments);

    useAsyncEffect(
        (helper) => {
            const initChangeSizeFlexibilityDocuments = async () => {
                if (!isChangeSizeModalOpen) {
                    return;
                }

                const flexibilityDocuments = await getFlexibilityDocuments(changeSizeChoiceGroups, helper.abortSignal);
                setChangeSizeFlexibilityDocuments(flexibilityDocuments);
            };

            initChangeSizeFlexibilityDocuments();

            return () => {
                setChangeSizeFlexibilityDocuments(undefined);
            };
        },
        [isChangeSizeModalOpen, changeSizeChoiceGroups, getFlexibilityDocuments]
    );

    return (
        <FlexibilityModal
            ariaLabel={text('changeSize')}
            isFlexibilityModalOpen={isChangeSizeModalOpen}
            onModalClose={closeChangeSizeModal}
            flexibilityDocument={flexibilityDocument}
            showPreviewSwitcher={true}
            headerText={text('changeProductSize', { productName: productData?.productName })}
            subHeaderText={text('adjustDesign')}
            panelContent={<ChangeSizePanelContent />}
            changeButtonText={text('changeSize')}
            onChangeButtonClick={onChangeSizeButtonClick}
            testId="change-size-modal"
        />
    );
});
