import qs from 'qs';

export default class QueryStringHelper {
    static objectToQuery(object: unknown): string {
        const queryString = qs.stringify(object, { encodeValuesOnly: true });
        return `?${queryString}`;
    }

    static getValueByKey(field: string): string | null {
        return new URLSearchParams(window.location.search).get(field);
    }
}

export function removeQueryParam(url: string | URL, key: string | undefined) {
    const realUrl = new URL(url);
    const params = qs.parse(realUrl.search, { ignoreQueryPrefix: true });
    if (key && params[key]) {
        delete params[key];
    }
    const newParams = qs.stringify(params);
    return `${realUrl.protocol}//${realUrl.hostname}${realUrl.port ? `:${realUrl.port}` : ''}${
        realUrl.pathname
    }?${newParams}`;
}

export function addQueryParam(url: string | URL, key: string, value: string) {
    const realUrl = new URL(url);
    const params = qs.parse(realUrl.search, { ignoreQueryPrefix: true });
    params[key] = value;
    const newParams = qs.stringify(params);
    return `${realUrl.protocol}//${realUrl.hostname}${realUrl.port ? `:${realUrl.port}` : ''}${
        realUrl.pathname
    }?${newParams}`;
}
