import React from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { AuthHeader, SpecsAndTemplates } from '@design-stack-vista/specs-and-templates';
import { css } from '@emotion/css';
import {
    className,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
    tokensRaw,
} from '@vp/swan';
import { getFileTypeForTracking } from './SpecsAndTemplateUtils';
import { ACTION_NAME, PageSection } from '../../../../features/TrackingEvents/constant';
import { GA_EVENTS } from '../../../../features/TrackingEvents/GAEvents';
import { useProductConfiguration } from '../../../../hooks/calcifer';
import { useActivePanel } from '../../../../hooks/useActivePanel';
import { currentLocale } from '../../../../utils/localeUtils';
import { text } from '../../../../utils/localization';

interface SpecAndTemplateModalDialogProp {
    openDialog: boolean;
    onClose: (status: boolean) => void;
}

const modalDialogStyle = css`
    .swan-modal-dialog {
        &.swan-modal-dialog-panel {
            padding: 0;
            width: 440px;
            min-width: 350px;
        }
        .swan-modal-dialog-body {
            margin: 0px;
        }
        @media only screen and (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
            .swan-modal-dialog-body {
                width: 100%;
            }
        }
        .specs-and-template-preview-image {
            margin: auto;
        }
        .swan-modal-dialog-nav {
            margin: 0;
        }
    }
`;

export const SpecAndTemplateModalDialog = ({ openDialog, onClose }: SpecAndTemplateModalDialogProp) => {
    const handleModalClose = () => {
        fireEvent({
            name: ACTION_NAME.FLY_OUT_CLOSED,
            extraData: {
                label: 'QUAD Studio Specs & Templates Fly-Out',
                category: PageSection.FlyOut,
            },
        });
        onClose(false);
    };
    const { fireEvent } = useCustomEventPropagation();
    const { auth: vpAuth } = useIdentityContext();
    const { productData } = useProductConfiguration();

    const panel = useActivePanel();

    if (!productData) {
        return null;
    }

    const authHeader: AuthHeader = { type: 'header', header: vpAuth.getAuthorizationHeader() };

    const localizationKeys = {
        template: text('templates'),
        templateDesc: text('templateDesc'),
        designSpec: text('designSpec'),
        designSpecDescLine: text('designSpecDescLine'),
        bleedLabel: text('bleedLabelSpecAndTemplates'),
        trimLabel: text('trimLabelSpecAndTemplates'),
        safeLabel: text('safeLabel'),
        height: text('height'),
        width: text('width'),
        lengthInInch: text('lengthInInch'),
        widthInInch: text('widthInInch'),
        lengthInMM: text('lengthInMM'),
        widthInMM: text('widthInMM'),
        safeArea: text('safeArea'),
    };

    const rotatePreview = productData.designViews.projectionId ? true : false;

    return (
        <ModalDialog
            className={className(modalDialogStyle, 'specs-and-template-modal-dialog')}
            variant="panel-right"
            isOpen={openDialog}
            onRequestDismiss={handleModalClose}
            onlyRenderWhenOpen
        >
            <ModalDialogContent aria-labelledby="modal dialog panel">
                <ModalDialogNav>
                    <ModalDialogCloseButton accessibleText={text('closeAccessibilityLabel')} />
                </ModalDialogNav>
                <ModalDialogHeader>
                    <ModalDialogTitle px="6">{productData.productName}</ModalDialogTitle>
                </ModalDialogHeader>
                <ModalDialogBody>
                    <SpecsAndTemplates
                        authTokenOrHeader={authHeader}
                        designViews={productData.designViews.designViews}
                        productKey={productData.productKey}
                        productVersion={productData.productVersion}
                        selectedOptions={productData.selectedOptions}
                        culture={currentLocale}
                        localizedValues={localizationKeys}
                        rotate90={rotatePreview}
                        onFileDownload={(fileName) => {
                            const fileType = getFileTypeForTracking(fileName);

                            fireEvent({
                                name: ACTION_NAME.FLY_OUT_CLICKED,
                                extraData: {
                                    label: GA_EVENTS.QUAD_STUDIO_SPECS_AND_TEMPLATE_FLY_OUT,
                                    category: PageSection.FlyOut,
                                    eventDetail: `download:${fileType}:${panel?.panelProperties.name}`,
                                },
                            });
                        }}
                        guidelinesButtonLabel={text('artworkGuidelinesButtonLabel')}
                    />
                </ModalDialogBody>
            </ModalDialogContent>
        </ModalDialog>
    );
};
