import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { observer } from 'mobx-react-lite';
import { usePremiumFinishStore } from './PremiumFinishProvider';
import { PremiumFinishConfirmationModal, PremiumFinishModal } from '../features/PremiumFinish';

type ModalUI = {
    /**
     * shows Premium Finish Flyout status
     */
    isOpen: boolean;
    /**
     * Callback to open premium finish flyout
     */
    openModal: () => void;
    /**
     * Callback to close premium finish flyout
     */
    closeModal: () => void;
    /**
     * IN - DEVELOPMENT
     * Callback to open premium finish flyout
     * on upload of an image.
     *
     * The flyout to be opened only once for the user session
     *
     * // TODO: Define what a session is here
     */
    openPremiumFinishModalOnUpload: (panel: PanelState) => void;
    /**
     * Callback to open premium finish confirmation modal
     */
    openPremiumFinishConfirmationModal: () => void;
};

const PremiumFinishModalContext = createContext<ModalUI | null>(null);

export const usePremiumFinishModal = () => {
    const value = useContext(PremiumFinishModalContext);
    if (!value) {
        throw new Error('usePremiumFinishModal must be used within a PremiumFinishModalProvider');
    }
    return value;
};

interface PremiumFinishModalProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

const PREMIUM_FINISH_FLYOUT_MODAL_TIMEOUT = 1000;

export const PremiumFinishModalProvider = observer(({ children }: PremiumFinishModalProviderProps) => {
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const { isPremiumFinishProduct } = usePremiumFinishStore();

    const [isOpen, setIsOpen] = useState(false);
    const [finishModalDiscoverdOnUpload, setFinishModalDiscoverdOnUpload] = useState(false);
    const [isFinishConfirmationOpen, setIsFinishConfirmationOpen] = useState(false);
    const timeOut = useRef(0);

    const firstPanelId = panels[0].id;

    const openModal = () => {
        setIsOpen(true);
    };

    /**
     * When the user loads a finish product,
     * we open the premium-finish flyout for them
     * on upload completion
     */
    const openPremiumFinishModalOnUpload = useCallback(
        (panel: PanelState) => {
            if (!finishModalDiscoverdOnUpload && isPremiumFinishProduct && firstPanelId === panel.id) {
                setFinishModalDiscoverdOnUpload(true);
                timeOut.current = window.setTimeout(() => {
                    openModal();
                    clearTimeout(timeOut.current);
                }, PREMIUM_FINISH_FLYOUT_MODAL_TIMEOUT);
            }
        },
        [finishModalDiscoverdOnUpload, isPremiumFinishProduct, firstPanelId]
    );

    const closeModal = () => {
        setIsOpen(false);
    };

    const openPremiumFinishConfirmationModal = () => {
        setIsFinishConfirmationOpen(true);
    };

    const closeFinishConfirmation = () => {
        setIsFinishConfirmationOpen(false);
    };

    return (
        <PremiumFinishModalContext.Provider
            value={{
                closeModal,
                isOpen,
                openModal,
                openPremiumFinishModalOnUpload,
                openPremiumFinishConfirmationModal,
            }}
        >
            <PremiumFinishModal />
            <PremiumFinishConfirmationModal
                isOpen={isFinishConfirmationOpen}
                onRequestDismiss={closeFinishConfirmation}
            />
            {children}
        </PremiumFinishModalContext.Provider>
    );
});

PremiumFinishModalProvider.displayName = 'PremiumFinishModalProvider';
