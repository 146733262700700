import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { observer } from 'mobx-react-lite';
import { usePreviousValue } from '../hooks/usePreviousValue';
import { WorkEntityResponse } from '../types/workEntityTypes';

interface SaveWorkProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

type SaveWorkContextType = {
    isWorkSaved: boolean;
    setIsWorkSaved: (value: boolean) => void;
    lastWorkEntity: WorkEntityResponse | undefined;
    setLastWorkEntity: (workEntity: WorkEntityResponse) => void;
};

export const SaveWorkContext = createContext<SaveWorkContextType | null>(null);

export const SaveWorkProvider = observer(({ children }: SaveWorkProviderProps) => {
    const [isWorkSaved, setIsWorkSaved] = useState(false);
    const [lastWorkEntity, setLastWorkEntity] = useState<WorkEntityResponse>();
    const { historyStore } = useDesignEngine();

    const historyChanges = historyStore['changes']?.length;
    const prevHistoryChanges = usePreviousValue(historyChanges);

    useEffect(() => {
        if (historyChanges > (prevHistoryChanges || 0)) {
            setIsWorkSaved(false);
        }
    }, [historyChanges]);

    const value = useMemo(() => {
        return {
            isWorkSaved,
            setIsWorkSaved,
            lastWorkEntity,
            setLastWorkEntity,
        };
    }, [isWorkSaved, lastWorkEntity]);

    return <SaveWorkContext.Provider value={value}>{children}</SaveWorkContext.Provider>;
});

export const useSaveWork = () => {
    const value = useContext(SaveWorkContext);
    if (!value) {
        throw new Error('useSaveWork must be used within a SaveWorkProvider');
    }
    return value;
};
