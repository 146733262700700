import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as reader from '@vp/ab-reader';
import { getQueryParams } from '../features/Flexibility/common';
import { AnalyticsPageData } from '../features/TrackingEvents/type';
import { localStorageUtility } from '../utils';
import { isLocalhost, isStaging } from '../utils/envUtils';
import { addPageAction, noticeError } from '../utils/newRelicUtils';

export interface Data {
    /**
     * Returns a boolean saying whether or not the variation is set
     * Will return true or false
     */
    isExperimentUsingVariation: (experimentKey: string, variationKey: string) => Promise<boolean>;
    /**
     * Given an experiment and variation key, will return whether or not the variation is a forced one
     */
    isForcedVariation: (experimentKey: string, variationKey: string) => boolean;

    getForcedVariation: (experimentKey: string) => string;

    isExperimentActive: (experimentKey: string) => Promise<boolean>;

    trackImpression: (experimentKey: string, pageAnalyticsData: AnalyticsPageData) => Promise<void>;

    getVariation: (experimentKey: string) => string | null;
}

export const AbTestContext = createContext<Data | undefined>(undefined);

export const useAbTestContext = () => {
    return useContext(AbTestContext);
};

const AB_READER_WAIT_TIME = 2000;

type Props = {
    children: ReactNode | ReactNode[];
};

export const AbTestProvider = (props: Props) => {
    const { children } = props;
    const [forcedVariations, setForcedVariations] = useState<Record<string, string>>({});
    const [readerAvailable, setReaderAvailable] = useState(false);
    const localHost = isLocalhost();
    const stagingHost = isStaging();

    // initialize AB test client and create list of all experiments and variations
    useEffect(() => {
        const init = async () => {
            try {
                reader.initialize();
                reader.whenAvailable(() => setReaderAvailable(true), AB_READER_WAIT_TIME);
                const queryParams = getQueryParams(window.location.href);
                const forcedVariationQueryParams = Object.keys(queryParams)
                    .filter((key) => key.startsWith('AB_'))
                    .reduce((queryParamsAcc, experimentQueryParamKey) => {
                        const queryParamValue = queryParams[experimentQueryParamKey];
                        const experimentKey = experimentQueryParamKey.substring(3);

                        // if there are multiple variations specified for the same key, use the last one
                        if (Array.isArray(queryParamValue)) {
                            return {
                                ...queryParamsAcc,
                                [experimentKey]: queryParamValue[queryParamValue.length - 1],
                            };
                        }
                        return { ...queryParamsAcc, [experimentKey]: queryParamValue };
                    }, {});
                const { getQuadExperiments, addQuadExperiment } = localStorageUtility;
                const quadExperiments = getQuadExperiments() ?? {};
                const forcedVariationLocalhost = Object.keys(quadExperiments)
                    .filter((key) => key.startsWith('AB_'))
                    .reduce((queryParamsAcc, experimentQueryParamKey) => {
                        const queryParamValue = quadExperiments[experimentQueryParamKey];
                        const experimentKey = experimentQueryParamKey.substring(3);

                        // if there are multiple variations specified for the same key, use the last one
                        if (Array.isArray(queryParamValue)) {
                            return {
                                ...queryParamsAcc,
                                [experimentKey]: queryParamValue[queryParamValue.length - 1],
                            };
                        }
                        return { ...queryParamsAcc, [experimentKey]: queryParamValue };
                    }, {});

                const forcedVariationsOverloads = Object.assign(
                    {},
                    forcedVariationLocalhost,
                    forcedVariationQueryParams
                );

                // Go through the overloads and make sure they are all in sync between query parm and local storage
                Object.entries(forcedVariationsOverloads).forEach(([key, value]) => {
                    addQuadExperiment(key, value as string);
                });

                setForcedVariations(forcedVariationsOverloads);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                /** AB testing error should not break the application */
                addPageAction('quad-ab-test-initialization-failed', {
                    errorMessage: error.message,
                });
                noticeError(error, {
                    method: 'AbTestProvider - useEffect',
                });
            }
        };
        init();
    }, []);

    const getForcedVariation = useCallback(
        (experimentKey: string) => forcedVariations[experimentKey],
        [forcedVariations]
    );

    const context = useMemo(() => {
        // If we're on local host, the cookie for loading the reader might not be on developers machines
        // As such the reader never calls the "whenAvailable" callback setting reader available to be true.
        // Thus, if we're on local host, it's ok to set all these functions up so that the debug menu works.
        if (readerAvailable || localHost || stagingHost) {
            return {
                getVariation: (experimentKey: string) => {
                    const forcedVariation = getForcedVariation(experimentKey);
                    const readerValue = reader.activate(experimentKey);
                    return forcedVariation || readerValue;
                },
                isExperimentUsingVariation: async (experimentKey: string, variationKey: string) => {
                    const forcedVariation = getForcedVariation(experimentKey);
                    const clientVariation = forcedVariation || reader.activate(experimentKey);
                    return clientVariation === variationKey;
                },
                trackImpression: async (experimentKey: string, pageAnalyticsData: AnalyticsPageData) => {
                    const forcedVariation = getForcedVariation(experimentKey);
                    if (forcedVariation) {
                        if (localHost || stagingHost) {
                            console.info('will track impression for:', { experimentKey, forcedVariation });
                        }
                        return;
                    }

                    try {
                        const variationKey = reader.activate(experimentKey);
                        if (variationKey) {
                            /**
                             * Since fireImpression 3rd param(SDK key) will be ignored by the method we can pass anything,
                             *  this was done by the library owner to avoid major upgrade
                             */
                            await reader.fireImpression(experimentKey, variationKey, pageAnalyticsData);
                        }
                    } catch (error) {
                        noticeError(error, {
                            method: 'AbTestProvider - context',
                        });
                    }
                },
                isForcedVariation: (experimentKey: string, variationKey: string) => {
                    const forcedVariation = getForcedVariation(experimentKey);
                    return forcedVariation === variationKey;
                },
                getForcedVariation,

                isExperimentActive: async (experimentKey: string) => {
                    const forcedVariation = getForcedVariation(experimentKey);
                    if (forcedVariation) {
                        return false;
                    }

                    try {
                        const variation = reader.activate(experimentKey);
                        if (!variation) {
                            return false;
                        }
                    } catch {
                        return false;
                    }

                    return true;
                },
            };
        }

        return undefined;
    }, [readerAvailable, localHost, getForcedVariation, stagingHost]);

    return <AbTestContext.Provider value={context}>{children}</AbTestContext.Provider>;
};
