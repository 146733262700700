import { useEffect, useRef, useState } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { useStudioBaseURL } from './useStudioURL';
import { useValidationTracking } from './useValidationTracking';
import { useWorkEntityService } from './useWorkEntityService';
import { CustomURLParams } from '../config/constant';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../features/TrackingEvents/constant';
import { GA_EVENTS } from '../features/TrackingEvents/GAEvents';
import { vcsStudioBaseUrl } from '../features/VCS/constant';
import { getHost as getVcsHost } from '../features/VCS/utils/getHost';
import { getLocale as getVcsLocale } from '../features/VCS/utils/getLocale';
import { isVCSConsumer } from '../features/VCS/utils/isVCSConsumer';
import { useFullScreenLoader, useProductParams } from '../providers';
import { useSaveWork } from '../providers/SaveWorkProvider';
import { getPanelName } from '../utils';
import { getCareAgentQueryParams } from '../utils/envUtils';
import { getHost } from '../utils/getHost';
import { text } from '../utils/localization';
import QueryStringHelper from '../utils/queryStringUtils';
interface RedirectProps {
    pageSection: string;
    panelNumber?: number;
    ctaText: string;
    activeSectionInStudio?: string;
}

export const useRedirectURL = () => {
    const {
        idaStore: { activeDesignPanelId },
        cimDocStore: { panels },
    } = useDesignEngine();
    const studioURL = useStudioBaseURL();
    const { isWorkSaved } = useSaveWork();
    const { fireEvent } = useCustomEventPropagation();
    const { saveCurrentProgress } = useWorkEntityService();
    const { setFullScreenLoaderData } = useFullScreenLoader();
    const { updateValidationTracking } = useValidationTracking();
    const { returnPath } = useProductParams();

    const [workId, setWorkId] = useState<string | undefined>();
    const startingCanvas = useRef<number | undefined>(undefined);
    const activeDialogPanel = useRef<string | undefined>(undefined);

    const panelName = getPanelName(panels, activeDesignPanelId);

    const redirectToStudio = async ({ pageSection, panelNumber, ctaText, activeSectionInStudio }: RedirectProps) => {
        const eventDetail = GA_EVENTS.STUDIO_REDIRECTION.replace('[pagePath]', 'quick-upload-and-design')
            .replace('[destinationPath]', 'studio')
            .replace('[CTA TEXT]', ctaText);

        updateValidationTracking({
            exitLabel: `Other:${ctaText}`,
            pageSection: pageSection as PageSection,
            pageStage: PageStage.Design,
        });

        fireEvent({
            name: ACTION_NAME.NAVIGATION_CLICKED,
            extraData: {
                label: 'Content:Text Link',
                pageSection,
                pageStage: PageStage.Design,
                eventDetail,
                navigationDetail: `${ctaText}-CTA:${ctaText === QUAD_TRACKING_EVENTS.CLICK_DESIGN_BACK ? 1 : 2}`,
                experimentDetail: {
                    side: panelName,
                },
            },
        });

        startingCanvas.current = panelNumber;
        if (activeSectionInStudio) {
            activeDialogPanel.current = activeSectionInStudio;
        }

        setFullScreenLoaderData({ showLoader: true, loaderMessage: text('designReadyLabel') });

        const workEntity = await saveCurrentProgress();
        setWorkId(workEntity?.workId);
    };

    useEffect(() => {
        if (isWorkSaved && workId) {
            const uurl = isVCSConsumer() ? getVcsHost() : getHost();
            let queryParams = `?workId=${workId}${getCareAgentQueryParams()}`;
            if (isVCSConsumer()) {
                queryParams += `&returnPath=${returnPath}`;
            }
            if (startingCanvas.current) {
                const startingCanvasQueryParam = QueryStringHelper.getValueByKey(CustomURLParams.StartingCanvas);
                queryParams += `&${CustomURLParams.StartingCanvas}=${startingCanvas.current}`;
                let currentUrl = window.location.href;
                if (!startingCanvasQueryParam) {
                    currentUrl += `&${CustomURLParams.StartingCanvas}=${startingCanvas.current}`;
                } else {
                    currentUrl = currentUrl.replace(
                        `/&${startingCanvas.current}=d/`,
                        `&${CustomURLParams.StartingCanvas}=${startingCanvas.current}`
                    );
                }
                if (activeDialogPanel.current) {
                    currentUrl += `&${CustomURLParams.ActiveDialogPanel}=${activeDialogPanel.current}`;
                    queryParams += `&${CustomURLParams.ActiveDialogPanel}=${activeDialogPanel.current}`;
                }
                window.history.replaceState({}, '', `${currentUrl}`);
            }
            if (isVCSConsumer()) {
                window.location.href = `${uurl}${getVcsLocale().toLowerCase()}${vcsStudioBaseUrl}${queryParams}`;
            } else {
                window.location.href = `${uurl}${studioURL}${queryParams}`;
            }
        }
    }, [isWorkSaved, workId, startingCanvas, activeDialogPanel]);

    return {
        redirectToStudio,
    };
};
