import { useMemo, useRef } from 'react';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { getDifferentialPricing } from '../../../api/pricing';
import { PricingType } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useProductParams } from '../../../providers';
import { formatPrice, isVatInclusive, noticeError } from '../../../utils';
import { getDefaultProductOptions } from '../../../utils/getDefaultProductOptions';
import { text } from '../../../utils/localization';

export type DiscountPricingDetails = {
    totalListPrice: string;
    totalDiscountPrice: string;
    isPriceDiscounted: boolean;
};

export enum PricingApiStatus {
    Failed = 'failed',
    Success = 'success',
    Pending = 'pending',
}

type PostcardType = {
    mailable: string;
    nonMailable: string;
};

export const usePostCardPricing = () => {
    const { productData } = useProductConfiguration();
    const { locale } = useProductParams();
    const { auth: vpAuth } = useIdentityContext();

    const authHeader = vpAuth.getAuthorizationHeader();
    const pricingApiStatus = useRef(PricingApiStatus.Pending);

    const priceToUse = useMemo(() => (isVatInclusive() ? PricingType.Taxed : PricingType.Untaxed), []);

    const getPricing = async (postcardPRDKey: PostcardType) => {
        const { signal } = new window.AbortController();

        const mailablePriceResult = await getPricingForPostcard(postcardPRDKey.mailable, authHeader, signal);
        const nonMailablePriceResult = await getPricingForPostcard(postcardPRDKey.nonMailable, authHeader, signal);

        if (!mailablePriceResult || !nonMailablePriceResult) {
            pricingApiStatus.current = PricingApiStatus.Failed;
            return null;
        }

        const { included: mailablePrice, currency } = mailablePriceResult;
        const { included: nonMailablePrice } = nonMailablePriceResult;

        return {
            mailablePrice,
            currency,
            nonMailablePrice,
        };
    };

    const getPostcardPricingDetails = async (postcardPRDKey: PostcardType) => {
        const pricingDetails = await getPricing(postcardPRDKey);

        if (!pricingDetails) {
            return null;
        }

        const { currency, mailablePrice, nonMailablePrice } = pricingDetails;

        const mailableTotalListPrice = mailablePrice.totalListPrice[priceToUse];
        const mailableTotalDiscountPrice = mailablePrice.totalDiscountPrice[priceToUse];

        const mailableProductPricingDetails = {
            totalListPrice: formatPrice(mailableTotalListPrice, locale, currency),
            totalDiscountPrice: formatPrice(mailableTotalDiscountPrice, locale, currency),
            isPriceDiscounted: mailableTotalListPrice !== mailableTotalDiscountPrice,
        };

        const nonMailableTotalListPrice = nonMailablePrice.totalDiscountPrice[priceToUse];

        const switchDiff = postcardPRDKey.mailable === productData?.productKey ? 1 : -1;

        const difference = (nonMailableTotalListPrice - mailableTotalDiscountPrice) * switchDiff;

        let pricing: string | null = null;

        if (difference === 0) {
            pricing = text('included');
        } else if (difference > 0) {
            pricing = `+${formatPrice(difference, locale, currency)}`;
        } else {
            pricing = `-${formatPrice(-1 * difference, locale, currency)}`;
        }

        return {
            mailableProductPricingDetails,
            pricingApiStatus: PricingApiStatus.Success,
            postcardPricingDifference: pricing,
        };
    };

    const getPricingForPostcard = async (productKey: string, authHeader: string, abortSignal: AbortSignal) => {
        if (productData) {
            try {
                const { compatibleCustomerSelectedProductOptions: defaultOptions, productVersion } =
                    await getDefaultProductOptions({
                        productKey: productKey,
                        locale: locale,
                        customerSelectedProductOptions: {},
                        quantity: productData.quantity,
                        authHeader,
                    });

                const choices = {
                    included: {
                        ...productData.selectedOptions,
                        ...productData.customerSelectedOptions,
                        ...defaultOptions,
                        Size: productData.customerSelectedOptions.Size,
                        Backside: productData.customerSelectedOptions.Backside,
                    },
                };

                const postcardPricingResult = await getDifferentialPricing(
                    productKey,
                    { ...productData.selectedOptions, ...defaultOptions },
                    productData.quantity,
                    choices,
                    productVersion,
                    abortSignal
                );

                return {
                    included: postcardPricingResult.choiceGroups.included,
                    currency: postcardPricingResult.currency,
                };
            } catch (error) {
                noticeError(error, {
                    method: 'getPostcardPricingDetails.getPricingForPostcard',
                });
                return null;
            }
        }
        return null;
    };

    return {
        getPostcardPricingDetails,
    };
};
