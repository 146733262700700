import React, { useEffect, useState } from 'react';

type Props = {
    children: React.ReactNode;
    waitBeforeShow?: number;
};

const DelayRender = ({ children, waitBeforeShow = 500 }: Props) => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsShown(true);
        }, waitBeforeShow);
        return () => clearTimeout(timer);
    }, [waitBeforeShow]);

    return isShown ? <>{children}</> : null;
};

export default DelayRender;
