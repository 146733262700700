import React, { useCallback } from 'react';
import { Measurement } from '@design-stack-ct/utility-core';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { css } from '@emotion/css';
import {
    className,
    FlexBox,
    SelectionSetInput,
    SelectionSetLabel,
    SelectionSetTileDescription,
    tokens,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { useScreenLayout } from '../../hooks/useScreenLayout';

const cardStyle = css`
    width: calc(50% - ${tokens.SwanSemSpace2});
`;

const getPreviewStyle = (isSmall: boolean, panelHeightRatio: number, panelWidthRatio: number) => css`
    height: ${isSmall ? `calc((60vw - 60px) * ${panelHeightRatio})` : `calc(200px * ${panelHeightRatio})`};
    width: ${isSmall ? `calc((60vw - 60px) * ${panelWidthRatio})` : `calc(200px * ${panelWidthRatio})`};
    max-width: 100%;
`;

interface SelectionOptionProps {
    panel: PanelState;
    optionValue?: string;
    onSelection?: (value?: string) => void;
    customStyles?: string;
    children?: React.ReactNode;
    description?: string | React.ReactNode;
    testId?: string;
}

export const SelectionOption = observer(
    ({ panel, optionValue, description, children, onSelection, customStyles, testId }: SelectionOptionProps) => {
        const { isSmall } = useScreenLayout();

        const handleClick = useCallback(() => {
            if (onSelection) {
                onSelection(optionValue);
            }
        }, [onSelection, panel]);

        const panelWidth = new Measurement(panel.panelProperties.width).mm;
        const panelHeight = new Measurement(panel.panelProperties.height).mm;
        const panelHeightRatio = panelHeight / (panelHeight + panelWidth);
        const panelWidthRatio = panelWidth / (panelHeight + panelWidth);

        return (
            <div className={className(cardStyle, customStyles)}>
                <SelectionSetInput value={panel.id}>
                    <SelectionSetLabel data-testid={testId} onClick={handleClick} m={0}>
                        <FlexBox flexDirection="column" gap="6" alignItems="center">
                            <div className={getPreviewStyle(isSmall, panelHeightRatio, panelWidthRatio)}>
                                {children}
                            </div>
                            {description && (
                                <SelectionSetTileDescription
                                    fontSize={isSmall ? 'xsmall' : 'small'}
                                    style={{ letterSpacing: `${tokens.SwanBaseFontLetterSpacing025}` }}
                                >
                                    {description}
                                </SelectionSetTileDescription>
                            )}
                        </FlexBox>
                    </SelectionSetLabel>
                </SelectionSetInput>
            </div>
        );
    }
);
