import React, { useCallback } from 'react';
import { css } from '@emotion/css';
import { FlexBox, SelectedValue, SelectionSet } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { ShapeOption } from './ShapeOption';
import { HorizontalSet } from '../../Flexibility/common/components/HorizontalSet';
import { useChangeShape, useShapePrice } from '../hooks';
import { shapeStore } from '../ShapeStore';

const containerStyle = css`
    justify-content: safe center;
`;
export const ShapeOptionSet = observer(() => {
    const { shapeList, selectedShape, setSelectedShape } = shapeStore;
    const { getShapePriceChange } = useShapePrice();
    const { loadProductWithNewShape } = useChangeShape();

    const handleShapeSelection = useCallback(
        (selectedShape: SelectedValue) => {
            if (selectedShape) {
                setSelectedShape(selectedShape);
                loadProductWithNewShape(selectedShape);
            }
        },
        [loadProductWithNewShape]
    );

    if (!shapeList.length) return null;

    return (
        <SelectionSet
            variant="single-select"
            selectedValue={selectedShape}
            onSelectedValueChange={handleShapeSelection}
            skin="standard"
            mb={{ xs: '0', sm: '7' }}
            mt={{ xs: '0', sm: '3' }}
        >
            <HorizontalSet>
                <FlexBox gap="4" className={containerStyle} data-testid="shape-set">
                    {shapeList.map((shape) => (
                        <ShapeOption key={shape} shape={shape} price={getShapePriceChange(shape)} />
                    ))}
                </FlexBox>
            </HorizontalSet>
        </SelectionSet>
    );
});
