import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { observer } from 'mobx-react-lite';
import { getModelConfig } from '../api/modelConfig';
import { usePostCard } from '../features/PostCard';
import { isVCSConsumer } from '../features/VCS/utils/isVCSConsumer';
import { ProductData } from '../hooks/calcifer/useProductConfiguration';

interface DesignPreview {
    url: string;
    title: string;
    name: string;
}

type PreviewContextType = {
    activePreview: string;
    setActivePreview: (v: string) => void;
    is3DView: boolean;
    setIs3DView: (v: boolean) => void;
    previewLoader: boolean;
    is3DViewSupported: boolean;
    setIs3DViewSupported: (v: boolean) => void;
    showPreviewModal: boolean;
    setShowPreviewModal: (v: boolean) => void;
    setPreviewLoader: (v: boolean) => void;
    designPreviews: DesignPreview[];
    setDesignPreviews: (v: DesignPreview[]) => void;
    /**
     * Key to identify the currently active preview
     *
     * default: null
     */
    selectedPreviewKey: string | null;
    /**
     * Callback to set the current active preview
     */
    setSelectedPreviewKey: (key: string) => void;
};

const PreviewContext = createContext<PreviewContextType | null>(null);

export const usePreview = () => {
    const value = useContext(PreviewContext);
    if (!value) {
        throw new Error('usePreview must be used within a PreviewProvider');
    }
    return value;
};

export interface PreviewProviderProps {
    children?: React.ReactNode | React.ReactNode[];
    productData: ProductData;
}

export const PreviewProvider = observer(({ children, productData }: PreviewProviderProps) => {
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [is3DView, setIs3DView] = useState(true);
    const [is3DViewSupported, setIs3DViewSupported] = useState(true);
    const [activePreview, setActivePreview] = useState('');
    const [previewLoader, setPreviewLoader] = useState(true);
    const [designPreviews, setDesignPreviews] = useState<DesignPreview[]>([]);
    const { isMailablePostCard, isMailingPostCard } = usePostCard();
    const [selectedPreviewKey, setSelectedPreviewKey] = useState<string | null>(null);

    /**
     * VCS business requires only 2D previews for now.
     */
    useEffect(() => {
        if (isVCSConsumer()) {
            setIs3DView(false);
            setIs3DViewSupported(false);
        }
    }, [is3DView, is3DViewSupported]);

    useEffect(() => {
        setIs3DView(is3DViewSupported ? true : is3DViewSupported);
    }, [is3DViewSupported]);

    useEffect(() => {
        if (isMailablePostCard) {
            setIs3DView(!isMailingPostCard);
            setIs3DViewSupported(!isMailingPostCard);
        }
    }, [isMailingPostCard, isMailablePostCard]);

    useEffect(() => {
        const previewName = designPreviews[0]?.title;
        setActivePreview(previewName);
        setSelectedPreviewKey(null);
    }, [designPreviews]);

    useAsyncEffect(
        (helpers) => {
            async function initialize3DViewSupport() {
                if (productData) {
                    const { productKey, selectedOptions, productVersion } = productData;
                    const productConfig = await getModelConfig(
                        {
                            sku: productKey,
                            attributes: selectedOptions,
                            version: productVersion,
                        },
                        helpers.abortSignal
                    );
                    setIs3DView(!!productConfig);
                    setIs3DViewSupported(!!productConfig);
                }
            }
            if (!isMailablePostCard) {
                initialize3DViewSupport();
            }
        },
        [productData]
    );

    const value = useMemo(() => {
        return {
            activePreview,
            setActivePreview,
            previewLoader,
            setPreviewLoader,
            designPreviews,
            setDesignPreviews,
            is3DView,
            setIs3DView,
            is3DViewSupported,
            setIs3DViewSupported,
            selectedPreviewKey,
            setSelectedPreviewKey,
            showPreviewModal,
            setShowPreviewModal,
        };
    }, [
        activePreview,
        previewLoader,
        designPreviews,
        is3DView,
        is3DViewSupported,
        selectedPreviewKey,
        showPreviewModal,
        setShowPreviewModal,
    ]);

    return <PreviewContext.Provider value={value}>{children}</PreviewContext.Provider>;
});
