import { useEffect } from 'react';
import { useProductParams } from '../providers';
import { initPricingContextModule, noticeError } from '../utils';

export const useInitPricingContextModule = () => {
    const { locale } = useProductParams();

    useEffect(() => {
        async function initializePricingModule() {
            try {
                await initPricingContextModule(locale);
            } catch (error) {
                noticeError(error, {
                    method: 'useInitPricingContextModule',
                });
            }
        }
        if (locale) {
            initializePricingModule();
        }
    }, [locale]);
};
