import React from 'react';
import { FlexBox, SkeletonText, SkeletonTextLine } from '@vp/swan';
import { RightPanelSkeleton } from './RightPanelSkeleton';
import { designAreaCanvasStyle, getNotificationPropStyle } from '../../features/DesignArea';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { NotificationToasts } from '../Notification';
import { getDesignAreaPageStyle } from '../UploadDesignArea';

const skeletonStyle = {
    borderRadius: { borderRadius: 8 },
    canvas: { width: '56.9%', aspectRatio: '720/424', overflow: 'hidden' },
    designArea: { height: '90vh' },
};

const CanvasSkeleton = () => {
    const { isSmall } = useScreenLayout();

    return (
        <FlexBox flexDirection="column" justifyContent="center" alignItems="center" className={designAreaCanvasStyle}>
            <NotificationToasts propStyle={getNotificationPropStyle(isSmall)} />
            <SkeletonText style={skeletonStyle.canvas}>
                <SkeletonTextLine style={{ height: 424 }}></SkeletonTextLine>
            </SkeletonText>
        </FlexBox>
    );
};

export const DesignAreaSkeleton = () => {
    const { isSmall } = useScreenLayout();

    return (
        <FlexBox className={getDesignAreaPageStyle(isSmall)} style={skeletonStyle.designArea}>
            <CanvasSkeleton />
            <RightPanelSkeleton />
        </FlexBox>
    );
};
