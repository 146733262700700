import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, Icon, Link, tokens, Typography } from '@vp/swan';

import { HeaderButton } from './HeaderButton';
import { PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../../features/TrackingEvents/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useActivePanel } from '../../../hooks/useActivePanel';
import { useBookendHeaderData } from '../../../hooks/useBookendHeaderData';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { useTrackExperimentClick } from '../../../hooks/useTrackExperimentClick';
import { useSaveWork } from '../../../providers/SaveWorkProvider';
import { text } from '../../../utils/localization';
import { DesktopExperience, MobileExperience } from '../../Experiences';

const myProjectTextStyle = css`
    &:hover {
        display: inline-table;
        background-color: ${tokens.SwanSemColorBgHover};
        border-radius: ${tokens.SwanBaseBorderRadius400};
    }
`;

export const MyProjectButton = () => {
    const headerData = useBookendHeaderData();
    const { isSmall } = useScreenLayout();
    const { productData } = useProductConfiguration();
    const { lastWorkEntity } = useSaveWork();
    const { trackExperimentClick } = useTrackExperimentClick();
    const panel = useActivePanel();
    const trackingNavigationDetail = !isSmall ? 'Quad header:My Projects:1' : 'Quad header:Menu button:My Projects';

    const handleButtonClick = () => {
        handleLinkClick();
        window.open(headerData?.base.myProjectsLink.url, '_blank');
    };

    const handleLinkClick = () => {
        trackExperimentClick({
            label: QUAD_TRACKING_EVENTS.CLICK_MY_PROJECTS,
            eventDetail: 'QUAD;QUAD;My Projects',
            navigationDetail: trackingNavigationDetail,
            pageSection: PageSection.MyProjects,
            pageStage: PageStage.Design,
            url: headerData?.base.myProjectsLink.url, // Added this url here because, opening my project in new tab.
            experimentDetail: {
                panelId: panel?.id,
                panelName: panel?.panelProperties.name,
            },
        });
    };
    return (
        <>
            <MobileExperience>
                <HeaderButton
                    label={text('myProjectBtnText')}
                    icon={<Icon iconType="myProjects_1" size="24p" />}
                    onClick={handleButtonClick}
                />
            </MobileExperience>
            <DesktopExperience>
                <FlexBox flexDirection="row" alignItems="center">
                    <Link
                        skin="unstyled"
                        href={headerData ? headerData.base.myProjectsLink.url : undefined}
                        onClick={handleLinkClick}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Typography
                            className={myProjectTextStyle}
                            fontSize="small"
                            fontWeight={isSmall ? 'bold' : 'normal'}
                            textColor="standard"
                            p={{ xs: '0', sm: '2' }}
                            mr={{ xs: '4', sm: '3' }}
                            ml={{ xs: '4', sm: '6' }}
                        >
                            {text('myProjectBtnText')}
                        </Typography>
                    </Link>
                    {(productData || lastWorkEntity?.workName) && <span>{'-'}</span>}
                    <Typography mr="6" ml="2" fontSize="small">
                        {lastWorkEntity?.workName ? lastWorkEntity.workName : productData?.productName}
                    </Typography>
                </FlexBox>
            </DesktopExperience>
        </>
    );
};
