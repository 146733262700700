import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, ToggleSwitch, ToggleSwitchOffLabel, ToggleSwitchOnLabel, tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { Loader, LoaderSize } from '../../../components/Loader';
import { useTrackExperimentClick } from '../../../hooks';
import { text } from '../../../utils/localization';
import { PageSection, QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { useMailingSwitch } from '../hooks/useMailingSwitch';
import { usePostCard } from '../hooks/usePostCard';
import { postCardStore } from '../PostCardStore';

const postCardMailingToggleWrapperStyle = css`
    position: relative;
`;

const postCardMailingToggleStyle = css`
    &.swan-toggle-switch {
        margin-right: 0;
        height: ${tokens.SwanSemSpace7};
        min-height: ${tokens.SwanSemSpace7};
    }
`;

const loaderStyle = css`
    justify-content: flex-start;
`;

export const PostCardMailingToggle = observer((): JSX.Element | null => {
    const { switchMSProduct } = useMailingSwitch();
    const { isMailingPostCard } = usePostCard();
    const { postcardSwitchingInProgress } = postCardStore;
    const { trackExperimentClick } = useTrackExperimentClick();

    const onRequestActivatedChange = async (activated: boolean) => {
        await switchMSProduct(activated);
        const eventDetail = activated
            ? 'QUAD;QUAD;Toggle to mailing postcard'
            : 'QUAD;QUAD;Toggle to non mailing postcard';
        trackExperimentClick({
            label: QUAD_TRACKING_EVENTS.CLICK_MAILING_POSTCARD_TOGGLE,
            pageSection: PageSection.MailingPostcard,
            eventDetail,
        });
    };

    return (
        <div className={postCardMailingToggleWrapperStyle}>
            {postcardSwitchingInProgress ? (
                <FlexBox>
                    <Loader showText={false} transparent={true} size={LoaderSize.MINI} styling={loaderStyle} />
                </FlexBox>
            ) : (
                <ToggleSwitch
                    className={postCardMailingToggleStyle}
                    activated={isMailingPostCard}
                    aria-label={text('mailingPostCardToggle')}
                    onRequestActivatedChange={onRequestActivatedChange}
                    data-testid="postcard-switching-block-toggle"
                >
                    <ToggleSwitchOnLabel></ToggleSwitchOnLabel>
                    <ToggleSwitchOffLabel></ToggleSwitchOffLabel>
                </ToggleSwitch>
            )}
        </div>
    );
});
