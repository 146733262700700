import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { MAX_FILE_NAME_LENGTH } from './constants';

export const getFileName = (asset: VistaAsset | undefined) => {
    if (!asset) return '';

    const fullAssetFileName = asset?.data?.info.storage?.fileName || '';
    let wrappedAssetFileName = fullAssetFileName;

    if (fullAssetFileName.length > MAX_FILE_NAME_LENGTH) {
        wrappedAssetFileName =
            fullAssetFileName.substring(0, MAX_FILE_NAME_LENGTH - 2) +
            '...' +
            fullAssetFileName.substring(fullAssetFileName.length - 8);
    }

    return wrappedAssetFileName;
};
