import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { ImageItem, ImageOverlay } from '@design-stack-vista/cdif-types';
import { ItemState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useActivePanel } from '../../../hooks/useActivePanel';
import { useFullScreenLoader, useProductParams } from '../../../providers';
import { ColorPaletteEntry } from '../../quad/types/colorPalette';
import { SingleColorImageDclMetadata } from '../commands';
import { DEFAULT_THRESHOLD, getSingleColorColorPalette } from '../utils';

interface AdjustContrast {
    selectedColor: ColorPaletteEntry;
    setSelectedColor: React.Dispatch<React.SetStateAction<ColorPaletteEntry>>;
    threshold: number;
    setThreshold: React.Dispatch<React.SetStateAction<number>>;
    isInverted: boolean;
    setIsInverted: React.Dispatch<React.SetStateAction<boolean>>;
    previewImage: HTMLImageElement | null;
    setPreviewImage: React.Dispatch<React.SetStateAction<HTMLImageElement | null>>;
    previewImageUrl: string;
    setPreviewImageUrl: React.Dispatch<React.SetStateAction<string>>;
    isImageLoaded: boolean;
    setIsImageLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    activeImage: ItemState<ImageItem> | undefined;
    getImageMetadata: (key: keyof SingleColorImageDclMetadata) => unknown;
    getImageOverlayData: (key: keyof ImageOverlay) => string | undefined;
    resetContrastControls: () => void;
    availablePaletteColors: ColorPaletteEntry[];
}

export const AdjustContrastContext = createContext<AdjustContrast | null>(null);
interface AdjustContrastProviderProps {
    children: ReactNode | ReactNode[];
}

export const AdjustContrastProvider = ({ children }: AdjustContrastProviderProps) => {
    const {
        cimDocStore: { metadata },
    } = useDesignEngine();
    const { locale } = useProductParams();
    const { productData } = useProductConfiguration();
    const { setFullScreenLoaderData } = useFullScreenLoader();

    const activePanel = useActivePanel();
    const activeImage = activePanel?.items?.find((item) => item.isImageItem()) as ItemState<ImageItem> | undefined;

    const getImageMetadata = (key: keyof SingleColorImageDclMetadata) => {
        const { dclMetadata } = metadata || {};
        if (activeImage && dclMetadata) {
            const imageMetadata = dclMetadata.find((data) => data.id === activeImage.id) as SingleColorImageDclMetadata;
            return imageMetadata?.[key];
        }
    };

    const getImageOverlayData = (key: keyof ImageOverlay) => {
        if (activeImage && activeImage.model.overlays && activeImage.model.overlays.length) {
            const [overlay] = activeImage.model.overlays;
            return overlay[key];
        }
    };

    const availablePaletteColors = useMemo(
        () =>
            getSingleColorColorPalette(
                locale,
                productData?.designViews.designViews[0].spotColorSet?.spotColorGroups[0].availableSpotColors
            ),
        [productData?.designViews, locale]
    );

    const getDefaultColor = () => {
        const cdifColor = getImageOverlayData('color');
        let colorStoredInCimdoc;
        if (cdifColor) {
            colorStoredInCimdoc = availablePaletteColors.find((color) => color.cdifValue === cdifColor);
        }
        // default to white color in color found in cimdoc
        return (
            colorStoredInCimdoc ||
            (availablePaletteColors.find((color) => color.cdifValue === 'pantone(White)') as ColorPaletteEntry)
        );
    };

    const [selectedColor, setSelectedColor] = useState<ColorPaletteEntry>(getDefaultColor());
    const [threshold, setThreshold] = useState<number>((getImageMetadata('threshold') as number) || DEFAULT_THRESHOLD);
    const [isInverted, setIsInverted] = useState<boolean>(Boolean(getImageMetadata('inverted')));
    const [previewImage, setPreviewImage] = useState<HTMLImageElement | null>(null);
    const [previewImageUrl, setPreviewImageUrl] = useState('');
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
        // reset state data on image change
        if (activeImage) {
            resetContrastControls();
            setPreviewImage(null);
            setPreviewImageUrl('');
            setFullScreenLoaderData({ showLoader: false });
        }
    }, [activeImage?.id]);

    const resetContrastControls = () => {
        setSelectedColor(getDefaultColor());
        setThreshold((getImageMetadata('threshold') as number) || DEFAULT_THRESHOLD);
        setIsInverted(Boolean(getImageMetadata('inverted')));
        setIsImageLoaded(false);
    };

    const value = useMemo(() => {
        return {
            activeImage,
            selectedColor,
            setSelectedColor,
            threshold,
            setThreshold,
            isInverted,
            setIsInverted,
            previewImage,
            setPreviewImage,
            previewImageUrl,
            setPreviewImageUrl,
            isImageLoaded,
            setIsImageLoaded,
            getImageMetadata,
            getImageOverlayData,
            resetContrastControls,
            availablePaletteColors,
        };
    }, [
        activeImage,
        selectedColor,
        isInverted,
        threshold,
        previewImage,
        previewImageUrl,
        isImageLoaded,
        resetContrastControls,
        availablePaletteColors,
    ]);

    return <AdjustContrastContext.Provider value={value}>{children}</AdjustContrastContext.Provider>;
};
