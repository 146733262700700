import React, { useCallback, useMemo, useState } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { Button, tokens } from '@vp/swan';
import { CustomURLParamValues } from '../../../../config/constant';
import { PageSection, QUAD_TRACKING_EVENTS } from '../../../../features/TrackingEvents/constant';
import { useRedirectURL } from '../../../../hooks/useRedirectURL';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';
import { text } from '../../../../utils/localization';
import { Loader, LoaderSize } from '../../../Loader';

const teamsButtonStyle = css`
    &.swan-button {
        gap: ${tokens.SwanSemSpace2};
        .loader {
            position: unset;
            width: auto;
            height: auto;
        }
    }
`;

export const TeamsPromoteModalGetStarted = (): JSX.Element => {
    const [redirectInProgress, setRedirectInProgress] = useState(false);
    const { redirectToStudio } = useRedirectURL();
    const {
        idaStore: { activeDesignPanelId },
        cimDocStore: { panels },
    } = useDesignEngine();
    const { isSmall } = useScreenLayout();

    const panelNumber = useMemo(() => {
        const panel = panels.find((panel) => panel.id === activeDesignPanelId);
        if (panel) {
            return panels.indexOf(panel) + 1;
        }
    }, [panels, activeDesignPanelId]);

    const redirectToStudioAndOpenTeam = useCallback(async () => {
        setRedirectInProgress(true);

        await redirectToStudio({
            pageSection: PageSection.Teams,
            ctaText: QUAD_TRACKING_EVENTS.CLICK_TEAMS_GET_STARTED,
            panelNumber: panelNumber,
            activeSectionInStudio: CustomURLParamValues.GroupAndTeams,
        });
        setRedirectInProgress(false);
    }, [panelNumber, redirectToStudio]);

    return (
        <Button
            m={{ xs: 0 }}
            skin="primary"
            size={isSmall ? 'mini' : 'standard'}
            width={isSmall ? 'full-width' : 'standard'}
            onClick={redirectToStudioAndOpenTeam}
            className={teamsButtonStyle}
        >
            <span>{text('teamsPromoteGetStartedButton')}</span>
            {redirectInProgress && (
                <Loader
                    showText={false}
                    transparent={true}
                    size={isSmall ? LoaderSize.TINY : LoaderSize.MINI}
                    styling="loader"
                />
            )}
        </Button>
    );
};
