import { useEffect, useState } from 'react';
import { verifyTeamsProduct } from '../../../api/teamsProductVerifier';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useProductParams } from '../../../providers';

export const useIsTeamsEnabled = () => {
    const [isTeamsProduct, setisTeamsProduct] = useState(false);
    const { productData } = useProductConfiguration();
    const { locale } = useProductParams();

    useEffect(() => {
        if (!productData || !locale) {
            return;
        }
        const { productKey, productVersion, customerSelectedOptions } = productData;

        const verifyIfTeamsProduct = async () => {
            try {
                const isTeamsProduct = await verifyTeamsProduct(
                    productKey,
                    productVersion,
                    locale,
                    customerSelectedOptions
                );
                setisTeamsProduct(isTeamsProduct);
            } catch {
                setisTeamsProduct(false);
            }
        };
        verifyIfTeamsProduct();
    }, [productData, locale]);

    return isTeamsProduct;
};
