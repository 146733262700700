import React, { useState } from 'react';
import { FlexBox, Icon, ModalDialogBody } from '@vp/swan';
import { DownloadPDFProof } from './DownloadPDFProof';
import { MyProjectButton } from './MyProjectButton';
import { SaveChangesButtonWrapper } from './SaveChanges/SaveChangesButtonWrapper';
import { SpecsAndTemplateButton } from './SpecsAndTemplate/SpecsAndTemplateButton';
import { TeamsButton } from './Teams/TeamsButton';
import { useDebugFlag } from '../../../features/Debug/useDebugFlag';
import { ProductProperties } from '../../../features/PanelProperties/ProductProperties';
import { PostCardSection } from '../../../features/PostCard';
import { MaterialColorButton } from '../../../features/SubstrateColor';
import { text } from '../../../utils/localization';
import { MobileExperience } from '../../Experiences';
import { PanelRightModal } from '../../Modal/components/PanelRightModal';

export const QuadHeaderMoreOption = () => {
    const [showOptionsModalDialog, setShowOptionsModalDialog] = useState(false);

    const handleButtonClick = () => {
        setShowOptionsModalDialog(true);
    };

    return (
        <FlexBox p="4">
            <Icon iconType="menu" size="20p" onClick={handleButtonClick} />
            <MoreOptionsDialog
                onClose={(status) => setShowOptionsModalDialog(status)}
                openDialog={showOptionsModalDialog}
            />
        </FlexBox>
    );
};

interface MoreOptionsProp {
    openDialog: boolean;
    onClose: (status: boolean) => void;
}

const MoreOptionsDialog = ({ openDialog, onClose }: MoreOptionsProp) => {
    const { isFlagEnabled } = useDebugFlag();
    const isSaveAndMyProjectEnabled = isFlagEnabled('quad_save_and_my_project');

    const handleModalClose = () => {
        onClose(false);
    };

    return (
        <MobileExperience>
            <PanelRightModal
                isOpen={openDialog}
                onClose={handleModalClose}
                ariaLabelledby={text('optionMenuAriaLabel')}
            >
                <ModalDialogBody>
                    <FlexBox flexDirection="column" gap="6" mb="5">
                        <ProductProperties />
                        {isSaveAndMyProjectEnabled && <SaveChangesButtonWrapper />}
                        {isSaveAndMyProjectEnabled && <MyProjectButton />}
                        <MaterialColorButton closeHeaderOptions={handleModalClose} />
                        <TeamsButton />
                        <SpecsAndTemplateButton />
                        <DownloadPDFProof />
                    </FlexBox>
                    <PostCardSection />
                </ModalDialogBody>
            </PanelRightModal>
        </MobileExperience>
    );
};
