import React from 'react';
import { IdentityProvider } from '@design-stack-vista/identity-provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { QUADErrorBoundary } from './components/QUADErrorBoundary';
import { SwanConfiguration } from './components/SwanConfiguration';
import { Analytics } from './features/TrackingEvents/Analytics';
import { BackConfirmationProvider, UploadModalProvider, ZoomProvider } from './providers';
import { AbTestProvider } from './providers/ABTestProvider';
import { DesignReviewProvider } from './providers/DesignReviewProvider';
import { isCareAgent, isProd, ownerId, windowExists } from './utils/envUtils';
import './i18n';
import { currentLocale } from './utils/localeUtils';

const queryClient = new QueryClient();

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <SwanConfiguration>
            <QUADErrorBoundary>
                <Analytics />
                <QueryClientProvider client={queryClient}>
                    <IdentityProvider
                        locale={currentLocale}
                        isProd={isProd()}
                        isCareAgent={isCareAgent()}
                        ownerId={ownerId}
                        redirectUriOrigin={
                            !isProd() && windowExists()
                                ? `${window.location.origin}${window.location.pathname}`
                                : `${window.location.origin}/quick-upload-and-design`
                        }
                    >
                        <AbTestProvider>
                            <DesignReviewProvider>
                                <BackConfirmationProvider>
                                    <UploadModalProvider>
                                        <ZoomProvider>
                                            <App />
                                        </ZoomProvider>
                                    </UploadModalProvider>
                                </BackConfirmationProvider>
                            </DesignReviewProvider>
                        </AbTestProvider>
                    </IdentityProvider>
                </QueryClientProvider>
            </QUADErrorBoundary>
        </SwanConfiguration>
    </React.StrictMode>
);
