import React from 'react';
import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { QuickToolItem } from './QuickToolItem';
import { useQuickToolFireTrackingEvent } from './useQuickToolFireTrackingEvent';
import { AdjustContrastIcon } from '../../components/SvgIcons';
import { useActivePanel } from '../../hooks/useActivePanel';
import { text } from '../../utils/localization';
import { adjustContrastStore, useIsEligibleForAdjustColor } from '../AdjustContrast';
import { QUAD_TRACKING_EVENTS } from '../TrackingEvents/constant';

const adjustContrastIconStyle = css`
    width: 16px;
    height: 16px;
`;

export const QuickToolAdjustContrast = observer((): JSX.Element | null => {
    const panel = useActivePanel();
    const { isEligibleForAdjustColor } = useIsEligibleForAdjustColor();
    const { setShowAdjustContrastModal } = adjustContrastStore;
    const { fireTrackingEvent } = useQuickToolFireTrackingEvent();

    if (!panel) {
        return null;
    }

    if (!isEligibleForAdjustColor(panel)) {
        return null;
    }

    const openAdjustContrastModal = () => {
        setShowAdjustContrastModal(true);
        fireTrackingEvent({
            label: QUAD_TRACKING_EVENTS.CLICK_ADJUST_CONTRAST,
            eventDetail: QUAD_TRACKING_EVENTS.CLICK_HEAD_PANEL_ADJUST_CONTRAST.replace(
                '[CANVAS FACE]',
                panel.panelProperties.name
            ),
            navigationDetail: 'Quick Tool:Adjust contrast Button',
            panel,
            isGAEvent: false,
        });
    };

    return (
        <>
            <QuickToolItem
                Icon={<AdjustContrastIcon className={adjustContrastIconStyle} />}
                buttonLabel={text('adjustContrastQuickToolLabel')}
                onClick={openAdjustContrastModal}
                dataTestId="adjust-contrast"
            />
        </>
    );
});
