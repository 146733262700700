import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const OvalIcon = (props: SvgProps) => {
    return (
        <svg viewBox="0 0 82 82" fill="none" {...props}>
            <path
                d="M55.65 40C55.65 42.3888 54.1976 44.6555 51.6351 46.3638C49.0785 48.0682 45.4977 49.15 41.5 49.15C37.5023 49.15 33.9215 48.0682 31.3649 46.3638C28.8024 44.6555 27.35 42.3888 27.35 40C27.35 37.6112 28.8024 35.3445 31.3649 33.6362C33.9215 31.9318 37.5023 30.85 41.5 30.85C45.4977 30.85 49.0785 31.9318 51.6351 33.6362C54.1976 35.3445 55.65 37.6112 55.65 40Z"
                stroke="black"
                strokeWidth="1.7"
            />
        </svg>
    );
};
