export const QUAD_HOSTNAMES = {
    local: 'localhost',
    dev: 'dev.quick-upload-and-design.design.vpsvc.com',
    stage: 'staging.vistaprint.com',
};

export const getHost = () => {
    const hostname = globalThis.location.hostname;

    /**
     * If the hostname is local testing machine or the testing url, redirecting to Vistaprint.com
     *
     * This takes the user to the PDC page
     *
     */
    if (hostname === QUAD_HOSTNAMES.local || hostname === QUAD_HOSTNAMES.dev || hostname === QUAD_HOSTNAMES.stage) {
        return 'https://www.vistaprint.com';
    }
    return globalThis.location.origin;
};
