import { convertRGBToXYZ, convertXYZToLAB, distance, hexToRGBA } from '@design-stack-vista/i18n-color-name';

/**
 * Threshold = 1-2: Extremely close to white; only very slight variations will be accepted.
 * Threshold = 3-5: Quite close to white; small variations in color will still be considered close to white.
 * Threshold = 6-10: Moderate closeness to white; more noticeable color differences will be tolerated.
 * Threshold > 10: Quite lenient; even colors with significant differences from white may be considered close to white.
 */
export const isCloseToWhiteColor = (color: string, threshold = 5) => {
    const { r, g, b } = hexToRGBA(color);
    const [x, y, z] = convertRGBToXYZ(r as number, g as number, b as number);
    const [l, a, b2] = convertXYZToLAB(x, y, z);

    const whiteLAB = [100, 0, 0];
    const dist = distance([l, a, b2], whiteLAB);

    return dist <= threshold;
};
