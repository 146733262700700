const allowedPanGap = 100;

export const allowUpPan = (panAreaRect: DOMRect | undefined, designAreaRect: DOMRect | undefined): boolean => {
    if (!panAreaRect || !designAreaRect) {
        return false;
    }
    const panelBottomY = designAreaRect.y + designAreaRect.height;
    const panBottomY = panAreaRect.y + panAreaRect.height - allowedPanGap;
    if (panelBottomY < panBottomY) {
        return false;
    }
    return true;
};

export const allowDownPan = (panAreaRect: DOMRect | undefined, designAreaRect: DOMRect | undefined): boolean => {
    if (!panAreaRect || !designAreaRect) {
        return false;
    }
    const panelTopY = designAreaRect.y;
    const panTopY = panAreaRect.y + allowedPanGap;
    if (panelTopY > panTopY) {
        return false;
    }
    return true;
};

export const allowLeftPan = (panAreaRect: DOMRect | undefined, designAreaRect: DOMRect | undefined): boolean => {
    if (!panAreaRect || !designAreaRect) {
        return false;
    }
    const panelLeftX = designAreaRect.x;
    const panLeftX = panAreaRect.x + allowedPanGap;
    if (panelLeftX > panLeftX) {
        return false;
    }
    return true;
};

export const allowRightPan = (panAreaRect: DOMRect | undefined, designAreaRect: DOMRect | undefined): boolean => {
    if (!panAreaRect || !designAreaRect) {
        return false;
    }
    const panelRightX = designAreaRect.x + designAreaRect.width;
    const panLeftX = panAreaRect.x + panAreaRect.width - allowedPanGap;
    if (panelRightX < panLeftX) {
        return false;
    }
    return true;
};
