import { action, makeObservable, observable } from 'mobx';

class PostCardStore {
    @observable isMailingServiceSuccessModalOpen = false;
    @observable showPostCardMailingModal = false;
    @observable postcardSwitchingInProgress = false;
    @observable disableMailingModalForUser = false;
    @observable isFirstTimeSwitchFromMailablePostcard = false;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setIsMailingServiceSuccessModalOpen(state: boolean) {
        this.isMailingServiceSuccessModalOpen = state;
    }

    @action.bound
    setPostcardSwitchingInProgress(state: boolean) {
        this.postcardSwitchingInProgress = state;
    }

    @action.bound
    setShowPostCardMailingModal(state: boolean) {
        this.showPostCardMailingModal = state;
    }

    @action.bound
    setDisableMailingModalForUser(state: boolean) {
        this.disableMailingModalForUser = state;
    }

    @action.bound
    setIsFirstTimeSwitchFromMailablePostcard(state: boolean) {
        this.isFirstTimeSwitchFromMailablePostcard = state;
    }

    @action.bound
    handlePostCardExperience() {
        if (this.isFirstTimeSwitchFromMailablePostcard) {
            this.setIsFirstTimeSwitchFromMailablePostcard(false);
        }
    }
}

export const postCardStore = new PostCardStore();
