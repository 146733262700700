import React, { ReactNode } from 'react';
import { usePostCard } from '../hooks/usePostCard';

interface Props {
    children?: ReactNode | ReactNode[];
}

export const PostCardMailable = ({ children }: Props): JSX.Element | null => {
    const { isMailablePostCard } = usePostCard();

    if (!isMailablePostCard) {
        return null;
    }

    return <>{children}</>;
};
