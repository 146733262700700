const getFileExtension = (fileName: string): string => {
    return (fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName).toLowerCase();
};

export const getFileTypeForTracking = (fileName: string): string => {
    let fileExtension = getFileExtension(fileName);
    if (fileExtension === 'zip') {
        fileExtension = 'svg';
    }
    return `file_${fileExtension}`;
};
