import { useDesignEngine } from '@design-stack-vista/core-features';
import { useProductConfiguration } from './calcifer';
import { text } from '../utils/localization';

export const usePanelTitle = () => {
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const { productData } = useProductConfiguration();

    const designViews = productData?.designViews.designViews;

    const getPanelTitle = (panelName: string | undefined) => {
        if (!panelName) {
            return;
        }

        const panelDesignView = designViews?.find((view) => view.name === panelName);
        return panelDesignView ? panelDesignView.location.name : panelName;
    };

    const getUploadButtonText = (panelName: string | undefined) => {
        if (!panelName) {
            return;
        }

        const panelTitle = getPanelTitle(panelName);
        return panels.length === 1
            ? text('uploadDesignText')
            : text('uploadText', { panelTitle: panelTitle?.toLowerCase() });
    };

    const getUploadModalHeaderText = (panelName: string | undefined) => {
        if (!panelName) {
            return;
        }

        const panelTitle = getPanelTitle(panelName);
        return panels.length === 1
            ? text('uploadDesignText')
            : text('uploadPanelHeader', { panelTitle: panelTitle?.toLowerCase() });
    };

    return {
        getPanelTitle,
        getUploadButtonText,
        getUploadModalHeaderText,
    };
};
