import React, { useCallback, useEffect, useState } from 'react';
import { Button, ModalDialogBody, ModalDialogFooter, ModalDialogHeader, Typography } from '@vp/swan';
import { StandardModal } from '../../components/Modal/components/StandardModal';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { useProductParams } from '../../providers';
import { text } from '../../utils/localization';

export const EditFromCartModal = () => {
    const [isEditCartModalOpen, setEditCartModalOpen] = useState<boolean>(false);
    const { isItemInCart } = useProductParams();
    const onRequestDismiss = useCallback(() => setEditCartModalOpen(false), []);
    const { isSmall } = useScreenLayout();

    useEffect(() => {
        if (isItemInCart) {
            setEditCartModalOpen(true);
        }
    }, [isItemInCart]);

    return (
        <StandardModal isOpen={isEditCartModalOpen} bodyWidth="capped" ariaLabelledby={text('editCartAriaLabel')}>
            <ModalDialogHeader mt={{ xs: '8', sm: '0' }} mb={{ xs: '6', sm: '5' }}>
                <Typography fontSize="x2large" fontWeight={isSmall ? 'bold' : 'bold'}>
                    {text('editFromCart')}
                </Typography>
            </ModalDialogHeader>
            <ModalDialogBody>
                <Typography>
                    {text('clickToUpdate')}
                    <Typography as="span" fontWeight="bold">
                        {' '}
                        {text('updateCart')}
                    </Typography>
                </Typography>
            </ModalDialogBody>
            <ModalDialogFooter>
                <Button
                    skin="primary"
                    size={isSmall ? 'mini' : 'standard'}
                    width={isSmall ? 'full-width' : 'standard'}
                    onClick={onRequestDismiss}
                >
                    {text('continueBtn')}
                </Button>
            </ModalDialogFooter>
        </StandardModal>
    );
};
