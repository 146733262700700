import { useEffect } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useTrackExperimentClick } from './useTrackExperimentClick';
import { PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../features/TrackingEvents/constant';
import { useDesignReviewContext } from '../providers';
import { useSaveWork } from '../providers/SaveWorkProvider';

export function useExitPrompt() {
    const {
        layoutStore: { visiblePanels },
    } = useDesignEngine();
    const { isWorkSaved } = useSaveWork();
    const { trackExperimentClick } = useTrackExperimentClick();
    const { isOpenDesignReviewModal } = useDesignReviewContext();

    const activePanel = visiblePanels[0];

    useEffect(() => {
        const beforeUnloadListener = (e: BeforeUnloadEvent) => {
            const showExitPrompt = !isWorkSaved && !isOpenDesignReviewModal;

            if (showExitPrompt) {
                trackExperimentClick({
                    label: QUAD_TRACKING_EVENTS.EXIT_PROMPT,
                    eventDetail: 'QUAD;QUAD;Quick Tool;Exit Prompt',
                    navigationDetail: 'Quick Tool:Exit Prompt',
                    pageSection: PageSection.BrowserExitPrompt,
                    pageStage: PageStage.Design,
                    experimentDetail: {
                        panelId: activePanel.id,
                        panelName: activePanel.panelProperties.name,
                    },
                });
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });
        return () => {
            window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
        };
    }, [activePanel, isWorkSaved, isOpenDesignReviewModal]);
}
