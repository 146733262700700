import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { getCatalogMerchandisingInfo } from '../../../api/catalogTransition';
import { getMerchandisingAvailability } from '../../../api/maise';
import { ProductAttributes } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useProductParams } from '../../../providers';
import { stockInfoStore } from '../StockInfoStore';
import { CatalogTransitionMerchandising, MerchandisingAvailability } from '../types';
import { filterStockInfoBySize } from '../utils';

export const useInitializeStockInfoStore = () => {
    const { locale } = useProductParams();
    const { auth: vpAuth } = useIdentityContext();
    const { secondaryConfig, productData } = useProductConfiguration();
    const { setStockInfoDetails, setMinimumOrderQuantity, minimumOrderQuantity } = stockInfoStore;

    const isQuantityPageEnabled = secondaryConfig?.isQuantityPageEnabled;

    const authorizationHeader = vpAuth.getAuthorizationHeader();

    useAsyncEffect(
        (helpers) => {
            async function initialiseMinimumOrderQuantity() {
                if (productData?.productKey && productData.productVersion && locale) {
                    const merchandisingInfo = await getCatalogMerchandisingInfo<CatalogTransitionMerchandising>(
                        productData.productKey,
                        productData.productVersion,
                        locale,
                        helpers.abortSignal
                    );
                    if (merchandisingInfo && merchandisingInfo.availableQuantities.range.length > 0) {
                        setMinimumOrderQuantity(merchandisingInfo.availableQuantities.range[0].minimum);
                    }
                }
            }
            initialiseMinimumOrderQuantity();
        },
        [productData?.productKey, productData?.productVersion, locale]
    );

    const setStockInfoByColor = async (selectedColor: string) => {
        if (productData && isQuantityPageEnabled && locale && authorizationHeader) {
            const { productKey, productVersion, customerSelectedOptions, selectedOptions } = productData;
            const attributeSelections = {
                ...selectedOptions,
                ...customerSelectedOptions,
                [ProductAttributes.SubstrateColor]: selectedColor,
            };
            const availability = await getMerchandisingAvailability<MerchandisingAvailability>({
                productKey,
                productVersion,
                locale,
                attributeSelections,
                facetAttributeName: ProductAttributes.Size,
                authorizationHeader,
            });
            if (availability) {
                const filteredStockInfo = filterStockInfoBySize(availability, minimumOrderQuantity);
                setStockInfoDetails(filteredStockInfo);
            }
        }
    };

    return {
        setStockInfoByColor,
    };
};
