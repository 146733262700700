import { getClosestShapeSize } from './getClosestShapeSize';
import { ProductAttributes } from '../../../config/constant';
import { ProductData } from '../../../hooks/calcifer/useProductConfiguration';
import { ProductAttributesKeyAndValues } from '../../../types/secondaryConfigTypes';
import { noticeError } from '../../../utils';
import { ProductChoiceGroups } from '../../Flexibility/common';
import { ShapeKey } from '../constants';

export const getShapeChoiceGroup = async (
    productData: ProductData,
    productAttributes: ProductAttributesKeyAndValues[],
    userSelectedSize: string,
    authHeader: string
): Promise<ProductChoiceGroups> => {
    const { productKey, productVersion, selectedOptions } = productData;
    const choiceGroups: ProductChoiceGroups = {};

    const productShapeOptions = productAttributes.find((attribute) => attribute.key.key === ProductAttributes.Shape);

    if (productShapeOptions) {
        await Promise.all(
            productShapeOptions.value.map((option) =>
                getClosestShapeSize({
                    userSelectedStrSize: userSelectedSize,
                    productKey,
                    productVersion,
                    selectedOptions,
                    newShape: option.key as ShapeKey,
                    authHeader,
                })
            )
        )
            .then((sizes) => {
                sizes.forEach((size, idx) => {
                    if (size) {
                        const { height, width } = size;
                        choiceGroups[productShapeOptions.value[idx].key] = {
                            [ProductAttributes.Shape]: productShapeOptions.value[idx].key,
                            [ProductAttributes.Size]: `${height}"x${width}"`,
                        };
                    }
                });
            })
            .catch((error) => {
                noticeError(error, {
                    method: 'getShapeChoiceGroup',
                });
            });
    }

    return choiceGroups;
};
