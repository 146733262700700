import { mupdfView } from "./mupdf-view";
import { pushToSnowflake } from "./utility/snowflake";
import {
  processInks,
  processSpotColors,
  processpageBox,
  getArrayBufferfromFileorUrl,
  isFileSvgOrNot,
} from "./utility/utility";
const BLOCK_TYPES = { IMAGE_TYPE: "image", TEXT_TYPE: "text" };
class MuPdf {
  isCorrupted = false;
  doc;
  constructor(mupdfWorker = mupdfView) {
    this.mupdfWorker = mupdfWorker;
  }

  async init(fileOrUrl, magic = "FileName.pdf") {
    const start = performance.now();
    let buffer;
    try {
      buffer = await getArrayBufferfromFileorUrl(fileOrUrl);
      this.doc = await this.mupdfWorker.openDocumentFromStream(buffer, magic);
      this.isCorrupted = false;
    } catch (error) {
      this.isCorrupted = true;
      console.log("Error:", error);
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "Initialization",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
  }

  numberOfPages = async () => {
    const start = performance.now();
    let pageCount;
    try {
      pageCount = await this.mupdfWorker.countPages(this.doc);
    } catch (err) {
      if (this.isCorrupted) throw new Error("PDF is corrupted");
      console.log("Error", err);
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "NumberOfPages",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return { NumberOfPages: pageCount };
  };

  isPDF = () => {
    return this.mupdfWorker.isPDF();
  };

  isPdfCorrupted = async () => {
    const start = performance.now();
    let result = this.isCorrupted;
    const end = performance.now();
    pushToSnowflake({
      eventType: "IsPdfCorrupted",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return { Corrupted: result };
  };

  isPasswordProtected = async () => {
    const start = performance.now();
    let result = await this.mupdfWorker.needsPassword(this.doc);
    const end = performance.now();
    pushToSnowflake({
      eventType: "CheckEncryption",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return { PasswordProtected: result };
  };

  pdfInfo = async () => {
    let keys = [
      "format",
      "encryption",
      "info:Author",
      "info:Title",
      "info:Subject",
      "info:Creator",
      "info:Producer",
      "info:CreationDate",
      "info:ModDate",
      "info:Keywords",
    ];
    let pdfInfo = {};
    const start = performance.now();
    pdfInfo.Format = await this.mupdfWorker.getMetaData(this.doc, "format");
    pdfInfo.Encryption = await this.mupdfWorker.getMetaData(this.doc, "encryption");
    pdfInfo.Author = await this.mupdfWorker.getMetaData(this.doc, "info:Author");
    pdfInfo.Title = await this.mupdfWorker.getMetaData(this.doc, "info:Title");
    pdfInfo.Subject = await this.mupdfWorker.getMetaData(this.doc, "info:Subject");
    pdfInfo.Creator = await this.mupdfWorker.getMetaData(this.doc, "info:Creator");
    pdfInfo.Producer = await this.mupdfWorker.getMetaData(this.doc, "info:Producer");
    pdfInfo.CreationDate = await this.mupdfWorker.getMetaData(this.doc, "info:CreationDate");
    pdfInfo.ModDate = await this.mupdfWorker.getMetaData(this.doc, "info:ModDate");
    pdfInfo.Keywords = await this.mupdfWorker.getMetaData(this.doc, "info:Keywords");
    const end = performance.now();
    pushToSnowflake({
      eventType: "PDFInfo",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return JSON.parse(JSON.stringify(pdfInfo));
  };

  isPdfEncrypted = async () => {
    const start = performance.now();
    let encryption = await this.mupdfWorker.getMetaData(this.doc, "encryption");
    let isPdfEncrypted = encryption == "None" ? false : true;
    const end = performance.now();
    pushToSnowflake({
      eventType: "IsPdfEncrypted",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return { isPdfEncrypted: isPdfEncrypted };
  };

  convertPageToPNG = async (pageNumber, dpi) => {
    const start = performance.now();
    let arrayBuffer = await this.mupdfWorker.drawPageAsPNG(this.doc, pageNumber, dpi);
    const blobObject = new Blob([arrayBuffer], { type: "image/png" });
    const end = performance.now();
    pushToSnowflake({
      eventType: "ConvertToImage",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return blobObject;
  };

  convertPageToJPG = async (pageNumber, dpi = 300) => {
    const start = performance.now();
    let arrayBuffer = await this.mupdfWorker.drawPageAsJPEG(this.doc, pageNumber, dpi);
    const blobObject = new Blob([arrayBuffer], { type: "image/jpeg" });
    const end = performance.now();
    pushToSnowflake({
      eventType: "ConvertToImage",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return blobObject;
  };

  getSpotColors = async (pageNumber) => {
    const start = performance.now();
    let result = await this.mupdfWorker.getSpotColorsDetails(this.doc, pageNumber);
    result = processSpotColors(result);
    const end = performance.now();
    pushToSnowflake({
      eventType: "ColorSeparation",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return result;
  };

  getPageBoxes = async (pageNumber) => {
    const start = performance.now();
    let box = await this.mupdfWorker.getPageBoxes(this.doc, pageNumber);
    let userUnit = await this.mupdfWorker.pageUserunit(this.doc, pageNumber);
    let rotationAngle = await this.mupdfWorker.PageRotation(this.doc, pageNumber);
    let result = await processpageBox(box, userUnit, rotationAngle);
    const end = performance.now();
    pushToSnowflake({
      eventType: "PageBoxes",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return result;
  };

  getPdfOutputIntent = async () => {
    const start = performance.now();
    let pdf_intent = await this.mupdfWorker.getOutputIntent(this.doc);
    const end = performance.now();
    pushToSnowflake({
      eventType: "OutputIntent",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return { OutputIntents: pdf_intent };
  };

  getPdfVersion = async () => {
    const start = performance.now();
    let pdf_version = await this.mupdfWorker.getPdfVersion(this.doc);
    const end = performance.now();
    pushToSnowflake({
      eventType: "PDFVersion",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return { PDFVersion: pdf_version };
  };

  getLayerDetails = async () => {
    const start = performance.now();
    let layers_count = await this.mupdfWorker.getPdfLayerCounts(this.doc);
    let layers = [];
    for (let i = 0; i < layers_count; i++) {
      let temp = {};
      temp.Name = await this.mupdfWorker.getLayerName(this.doc, i);
      temp.Intent = [];
      for (let j = 0; j < (await this.mupdfWorker.getPdfLayerIntentsCount(this.doc, i)); j++) {
        temp.Intent.push(await this.mupdfWorker.getlayerInentName(this.doc, i, j));
      }
      temp.Visible = (await this.mupdfWorker.isLayerEnabled(this.doc, i)) == 1 ? true : false;
      layers.push(temp);
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "LayerDetails",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return { Layers: layers };
  };

  fonts = async () => {
    const start = performance.now();
    let fonts = {};
    fonts.pages = {};
    let embeddedFonts = await this.mupdfWorker.getEmbeddedFonts(this.doc);
    let pageCounts = await this.mupdfWorker.countPages(this.doc);
    for (let pageNumber = 1; pageNumber <= pageCounts; pageNumber++) {
      let fontName = await this.mupdfWorker.getPageText(this.doc, pageNumber);
      Object.keys(fontName).map((t) => {
        if (embeddedFonts.hasOwnProperty(t)) {
          fontName[t].IsEmbedded = true;
          delete fontName[t].Min;
          delete fontName[t].Max;
        } else {
          fontName[t].IsEmbedded = false;
          delete fontName[t].Min;
          delete fontName[t].Max;
        }
      });
      fonts.pages[pageNumber - 1] = fontName;
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "Fonts",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return fonts;
  };

  splitPdf = async (group = 1) => {
    const start = performance.now();
    let pdf_pages = [];
    let pageCounts = await this.mupdfWorker.countPages(this.doc);
    if (group < 1 || group > pageCounts) {
      throw new Error(`Group size can't be less than 1 and greater than ${pageCounts}`);
    }
    for (let i = 1; i <= pageCounts; i += group) {
      pdf_pages.push(
        new Blob([await this.mupdfWorker.split_pdf_into_pages(this.doc, i, group, pageCounts)], {
          type: "application/pdf",
        }),
      );
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "SplitPdf",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return pdf_pages;
  };

  getPdfTextInfo = async () => {
    const start = performance.now();
    let TextInfo = {};
    TextInfo.pages = {};
    let pageCounts = await this.mupdfWorker.countPages(this.doc);
    for (let i = 1; i <= pageCounts; i++) {
      let pageText = await this.mupdfWorker.getPageText(this.doc, i);
      TextInfo.pages[i - 1] = pageText;
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "TextInfo",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return TextInfo;
  };

  getEmbeddedFontsCount = async () => {
    const start = performance.now();
    let fonts = {};
    fonts.pages = {};
    let embeddedFonts = await this.mupdfWorker.getEmbeddedFonts(this.doc);
    let pageCounts = await this.mupdfWorker.countPages(this.doc);
    for (let pageNumber = 1; pageNumber <= pageCounts; pageNumber++) {
      let embeddedFontsCount = 0;
      fonts.pages[pageNumber - 1] = {};
      let fontName = await this.mupdfWorker.getPageText(this.doc, pageNumber);
      Object.keys(fontName).map((t) => {
        if (embeddedFonts.hasOwnProperty(t)) {
          embeddedFontsCount++;
        }
      });
      fonts.pages[pageNumber - 1].EmbeddedFontsCount = embeddedFontsCount;
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "EmbeddedFontsCount",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return fonts;
  };

  pdfInspect = async () => {
    const start = performance.now();
    const regex = /[0-9]{1,}.[0-9]{1,}/g;
    let pdf_version = await this.mupdfWorker.getMetaData(this.doc, "format");
    const found = pdf_version.match(regex)[0].split(".");
    let result = {};
    result.Creator = await this.mupdfWorker.getMetaData(this.doc, "info:Creator");
    result.Producer = await this.mupdfWorker.getMetaData(this.doc, "info:Producer");
    result.Version = {
      PdfVersion: pdf_version,
      Major: found?.[0],
      Minor: found?.[1],
    };
    result.Security = {};
    result.Security.PasswordProtected = await this.mupdfWorker.needsPassword(this.doc);
    result.Pages = {};
    let embeddedFonts = await this.mupdfWorker.getEmbeddedFonts(this.doc);
    let missingFonts = {};
    for (let i = 0; i < (await this.mupdfWorker.countPages(this.doc)); i++) {
      let box = await this.mupdfWorker.getPageBoxes(this.doc, i + 1);
      let userUnit = await this.mupdfWorker.pageUserunit(this.doc, i + 1);
      let rotationAngle = await this.mupdfWorker.PageRotation(this.doc, i + 1);
      let pageboxes = await processpageBox(box, userUnit, rotationAngle);
      result.Pages[i] = {};
      result.Pages[i].PageNumber = i;
      result.Pages[i].Counter = {};
      let textInfo = await this.mupdfWorker.getPageText(this.doc, i + 1);
      let counterObject = await this.mupdfWorker.getCounter(this.doc, i + 1);
      result.Pages[i].Counter = counterObject;
      Object.keys(textInfo).map((t) => {
        if (embeddedFonts.hasOwnProperty(t)) {
        } else {
          missingFonts[t] = 1;
        }
      });
      result.Pages[i].SizeInfo = pageboxes.SizeInfo;
      result.Pages[i].Boxes = pageboxes.Boxes;
      let spotcolorList = await this.mupdfWorker.getSpotColorsDetails(this.doc, i + 1);
      spotcolorList = processSpotColors(spotcolorList);
      result.Pages[i].Inks = await processInks(spotcolorList);
      result.Pages[i].TransparencyGroupColorSpace = await this.mupdfWorker.transparencyGroupColorSpace(this.doc, i + 1);
    }
    result.Layers = (await this.getLayerDetails()).Layers;
    result.MissingFonts = Object.keys(missingFonts);
    const end = performance.now();
    pushToSnowflake({
      eventType: "PdfInspect",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return JSON.parse(JSON.stringify(result));
  };

  getUnEmbeddedFontsCount = async () => {
    const start = performance.now();
    let fonts = {};
    fonts.pages = {};
    let embeddedFonts = await this.mupdfWorker.getEmbeddedFonts(this.doc);
    let pageCounts = await this.mupdfWorker.countPages(this.doc);
    for (let pageNumber = 1; pageNumber <= pageCounts; pageNumber++) {
      let unEmbeddedFontsCount = 0;
      fonts.pages[pageNumber - 1] = {};
      let fontName = await this.mupdfWorker.getPageText(this.doc, pageNumber);
      Object.keys(fontName).map((t) => {
        if (!embeddedFonts.hasOwnProperty(t)) {
          unEmbeddedFontsCount++;
        }
      });
      fonts.pages[pageNumber - 1].UnEmbeddedFontsCount = unEmbeddedFontsCount;
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "UnEmbeddedFontsCount",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return fonts;
  };

  isPDF = () => {
    return this.mupdfWorker.isPDF();
  };

  getCounter = async (pageNumber = 1) => {
    const start = performance.now();
    let result;
    try {
      result = await this.mupdfWorker.getCounter(this.doc, pageNumber);
    } catch (err) {
      if (this.isCorrupted) throw new Error("PDF is corrupted");
      console.log("Error", err);
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "GetCounter",
      executionTime: end - start,
      host: window.location.host,
    });
    return { Counter: result };
  };

  getObjects = async (types) => {
    const start = performance.now();
    let result = {};
    try {
      if (!(types && types.length > 0)) {
        types = [BLOCK_TYPES.IMAGE_TYPE, BLOCK_TYPES.TEXT_TYPE];
      }
      let pageCount = await this.mupdfWorker.countPages(this.doc);
      for (let pageNumber = 1; pageNumber <= pageCount; pageNumber++) {
        let res = await this.mupdfWorker.getStructuredText(this.doc, pageNumber);
        result[pageNumber - 1] = res.blocks.filter((x) => types.includes(x.type));
      }
    } catch (err) {
      if (this.isCorrupted) throw new Error("PDF is corrupted");
      console.log("Error", err);
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "GetObjects",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return { pages: result };
  };

  mergePdf = async (buffers) => {
    const start = performance.now();
    let result = {};
    try {
      result = await this.mupdfWorker.mergePdf(this.doc, buffers);
    } catch (err) {
      if (this.isCorrupted) throw new Error("PDF is corrupted");
      console.log("Error", err);
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "MergePdf",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return result;
  };

  colorSeparation = async (dpi = 300) => {
    const start = performance.now();
    let preview_arr = [];
    let result = [];
    try {
      let pageCounts = await this.mupdfWorker.countPages(this.doc);
      for (let pageNumber = 1; pageNumber <= pageCounts; pageNumber++) {
        let preview_obj = {};
        let arrayBuffer = await this.mupdfWorker.drawPageAsPNG(this.doc, pageNumber, dpi);
        const blobObject = new Blob([arrayBuffer], { type: "image/png" });
        preview_obj.Url = URL.createObjectURL(blobObject);
        preview_arr.push(preview_obj);
        let response = {};
        response.PageNumber = pageNumber - 1;
        let colorSeparation_result = await this.mupdfWorker.getPageColorSeparations(this.doc, pageNumber, dpi);
        response.Separations = colorSeparation_result;
        result.push(response);
      }
    } catch (err) {
      if (this.isCorrupted) throw new Error("PDF is corrupted");
      console.log("Error", err);
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "colorSeparation",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return { RasterizedPages: result, Previews: preview_arr };
  };

  getTransparencyGroupColorSpace = async () => {
    const start = performance.now();
    let result = [];
    let pageCounts = await this.mupdfWorker.countPages(this.doc);
    try {
      let response = {};
      for (let i = 0; i < pageCounts; i++) {
        response.pageNumber = i;
        let colorSpace = await this.mupdfWorker.transparencyGroupColorSpace(this.doc, i + 1);
        response.TransparencyGroupColorSpace = colorSpace;
        result.push(response);
      }
    } catch (err) {
      if (this.isCorrupted) throw new Error("PDF is corrupted");
      console.log("Error", err);
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "TransparencyGroupColorSpace",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return result;
  };

  convertSvgToPdf = async (fileOrUrl) => {
    const start = performance.now();
    let arrayBuffer;
    let result;
    try {
      if (fileOrUrl instanceof File && !isFileSvgOrNot(fileOrUrl)) throw new Error("Input file must be SVG");
      arrayBuffer = await getArrayBufferfromFileorUrl(fileOrUrl);
      result = await this.mupdfWorker.createPdfFromSvgImageBuffer(arrayBuffer);
      if (result.size == 0) throw new Error(`size of the ouput pdf is 0 for input ${fileOrUrl}`);
    } catch (err) {
      console.log("Error", err);
      throw new Error(`Error while creting pdf for input ${fileOrUrl}`);
    }
    const end = performance.now();
    pushToSnowflake({
      eventType: "SvgToPdf",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return result;
  };

  extractPdfPage = async (pageNumber) => {
    const start = performance.now();
    let pageCounts = await this.mupdfWorker.countPages(this.doc);
    if (pageNumber < 1 || pageNumber > pageCounts) {
      throw new Error(`PageNumber can't be less than 1 and greater than ${pageCounts}`);
    }
    let pdfDoc = new Blob([await this.mupdfWorker.split_pdf_into_pages(this.doc, pageNumber, 1, pageCounts)], {
      type: "application/pdf",
    });

    const end = performance.now();
    pushToSnowflake({
      eventType: "ExtractPage",
      executionTime: parseFloat((end - start).toFixed(2)),
      host: window.location.host,
    });
    return pdfDoc;
  };
}

export { MuPdf, BLOCK_TYPES };
