import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { v4 as uuid } from 'uuid';
import { ColorMode, PanelWithColorMode } from './upsell';
import { getPlaceHolderUrl } from '../api/designService';

interface CanvasItem {
    x: number;
    y: number;
    width: string;
    height: string;
    rotationAngle: number;
    crop: {
        bottom: number;
        left: number;
        top: number;
        right: number;
    };
    zIndex: number;
    uploadUrlIndex: number;
    id: string;
}

type PlaceholderType = {
    version: string;
    uploads: Array<string>;
    canvases: Array<Array<CanvasItem>>;
};

type PlaceholderProps = {
    locale: string;
    panels: Array<PanelState>;
};

export const getPlaceholder = async ({ locale, panels }: PlaceholderProps): Promise<PlaceholderType> => {
    const canvases: Array<CanvasItem | undefined> = panels.map((panel, panelIndex) => {
        const {
            items,
            asJson,
            panelProperties: { height, width },
        } = panel;
        const { colorMode } = asJson as PanelWithColorMode;

        if (colorMode === ColorMode.Blank || items.length > 0) {
            return undefined;
        }

        const canvasData: CanvasItem = {
            x: 0,
            y: 0,
            width,
            height,
            rotationAngle: 0,
            crop: {
                bottom: 0,
                left: 0,
                top: 0,
                right: 0,
            },
            // in studio it's taking a random value, keep it same as studio
            zIndex: 1900,
            /**
             *  `uploadUrlIndex` is used by in getting the right upload url
             *  from the uploads[].
             *
             * @link https://gitlab.com/vistaprint-org/design-technology/design-stack/studio-document-metadata-management/-/blob/main/src/Placeholders/placeholderHydration.ts#L50
             */
            uploadUrlIndex: panelIndex,
            id: uuid(),
        };
        return canvasData;
    });

    const uploads = await Promise.all(
        canvases.map((canvas) => {
            if (canvas) {
                const { height, width } = canvas;
                return getPlaceHolderUrl({ culture: locale, height, width });
            }
            return '';
        })
    );

    const placeholderCanvases: Array<Array<CanvasItem>> = canvases.map((canvas) => (canvas ? [canvas] : []));

    return {
        version: '2',
        uploads,
        canvases: placeholderCanvases,
    };
};
