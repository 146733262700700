import React from 'react';
import { css } from '@emotion/css';
import { DiscountPrice, ListPrice, PricingComparison, SkeletonText, SkeletonTextLine, Typography } from '@vp/swan';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { text } from '../../../utils/localization';
import { usePostCard } from '../hooks';
import { PricingApiStatus } from '../hooks/usePostCardPricing';

const detailsStyle = css`
    line-height: 150%;
`;

export const PostCardPricing = (): JSX.Element => {
    const { productData } = useProductConfiguration();
    const { mailableProductPricingDetails, pricingApiStatus } = usePostCard();

    if (!productData || !pricingApiStatus || pricingApiStatus === PricingApiStatus.Pending) {
        return (
            <SkeletonText>
                <SkeletonTextLine></SkeletonTextLine>
            </SkeletonText>
        );
    }

    return (
        <Typography className={detailsStyle} as="div" fontSize="small" fontWeight="normal">
            {pricingApiStatus === PricingApiStatus.Success ? (
                <>
                    {text('postcardQuantity', { quantity: productData.quantity })}
                    {mailableProductPricingDetails?.isPriceDiscounted ? (
                        <PricingComparison>
                            <ListPrice mx="2">{mailableProductPricingDetails?.totalListPrice}</ListPrice>
                            <DiscountPrice>{mailableProductPricingDetails?.totalDiscountPrice}</DiscountPrice>
                        </PricingComparison>
                    ) : (
                        <ListPrice mx="2">{mailableProductPricingDetails?.totalListPrice}.</ListPrice>
                    )}
                    <Typography as="div" fontSize="small" textColor="subtle">
                        {text('includesPrintingPostageFees')}
                    </Typography>
                </>
            ) : (
                text('mailingPricingApiFailedMessage')
            )}
        </Typography>
    );
};
