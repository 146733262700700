import qs from 'qs';
import { Tenants } from '../config/constant';
import { fetchWrapper, getCountryFromLocale } from '../utils';

interface MerchandisingAvailabilityRequest {
    productKey: string;
    productVersion: number;
    locale: string;
    attributeSelections: Record<string, string>;
    facetAttributeName: string;
    authorizationHeader: string;
    signal?: AbortSignal;
}

const MAISE_BASE_URL = 'https://maise.products.cimpress.io';

export const getMerchandisingAvailability = async <T>({
    productKey,
    productVersion,
    locale,
    attributeSelections,
    facetAttributeName,
    authorizationHeader,
    signal,
}: MerchandisingAvailabilityRequest): Promise<T | undefined> => {
    const query = qs.stringify({
        context: {
            Merchant: Tenants.Vistaprint,
            Country: getCountryFromLocale(locale),
        },
        attributeSelections,
        facetAttributeName,
    });

    const availabilityUrl = `${MAISE_BASE_URL}/v3/product/${productKey}/versions/${productVersion}/availability`;

    const availability = await fetchWrapper<T>(availabilityUrl, {
        method: 'GET',
        requestInitiator: 'getMerchandisingAvailability',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authorizationHeader,
        },
        query,
        signal,
    });

    return availability;
};
