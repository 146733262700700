import { VORTEX_TENANT } from '../config/constant';
import { VortexProductConfig } from '../config/vortexProductConfig';
import { fetchWrapper } from '../utils';

const MODEL_CONFIG_URL = 'https://cdn.previews.cimpress.io/v2/modelConfig';
interface ProductConfigOptions {
    sku: string;
    attributes: { [key: string]: string | number };
    version?: number;
}
export type FetchModelConfig = (options: ProductConfigOptions, signal?: AbortSignal) => Promise<VortexProductConfig>;

export const getModelConfig: FetchModelConfig = async (options, signal) => {
    const attributes: string = JSON.stringify(options.attributes);

    let queryUrl =
        `${MODEL_CONFIG_URL}?sku=${options.sku}&skuAttributes=${encodeURIComponent(attributes)}` +
        `&tenantId=${VORTEX_TENANT.id}&tenantType=${VORTEX_TENANT.type}`;

    if (options.version) {
        queryUrl += `&version=${options.version}`;
    }

    const response = await fetchWrapper(queryUrl, {
        method: 'GET',
        requestInitiator: 'getModelConfig',
        signal,
    });
    return response;
};
