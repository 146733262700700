import React from 'react';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { css } from '@emotion/css';
import { FlexBox, Icon, tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { SelectionOption } from '../../../../../../../../components/SelectionOption';
import { text } from '../../../../../../../../utils/localization';

const uploadLabelStyles = css`
    width: 100%;
    height: 100%;
    border: 2px solid ${tokens.SwanSemColorBorderStandard};
    .swan-icon.swan-icon-skin-standard.swan-icon-type-upload {
        height: 21px;
        width: 21px;
    }
`;

const uploadButtonContainerStyle = css`
    .swan-selection-set-tile-description {
        padding: 0 ${tokens.SwanSemSpace4};
    }
`;

interface UploadButtonProps {
    panel: PanelState;
    handleUploadButtonClick: () => void;
    panelName: string;
}

const UploadButton = observer(({ panel, handleUploadButtonClick }: UploadButtonProps) => {
    return (
        <SelectionOption
            panel={panel}
            description={text('uploadDesignHeader')}
            onSelection={handleUploadButtonClick}
            testId="back-upload-button"
            customStyles={uploadButtonContainerStyle}
        >
            <FlexBox justifyContent="center" alignItems="center" className={uploadLabelStyles}>
                <Icon iconType="upload" skin="standard" data-testid="duplicate-flyout-upload-back-btn" />
            </FlexBox>
        </SelectionOption>
    );
});

export default UploadButton;
