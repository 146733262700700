import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const SafetyIcon = (props: SvgProps) => (
    <svg viewBox="0 0 16 16" {...props} fill="none">
        <path stroke={props.fill || '#000'} strokeWidth={1.2} d="M1.9 1.4h13.2v13.2H1.9z" />
        <path stroke={props.fill || '#000'} strokeWidth={1.2} d="M4.9 4.4h7.2v7.2H4.9z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 5H8.914L5.5 8.414V7l2-2Zm-2 5v1h6V5h-1l-5 5Zm.414 1L11.5 5.414V7l-4 4H5.914Zm3 0L11.5 8.414V11H8.914Z"
            fill={props.fill || '#000'}
        />
    </svg>
);
