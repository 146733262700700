import React from 'react';

import { css } from '@emotion/css';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { UploadDesign } from '../UploadPanel/UploadDesign';

const emptyStateStyle = css`
    width: 100%;
    height: 100%;
    pointer-events: auto;
`;

export const EmptyState = () => {
    const { isSmall } = useScreenLayout();

    const blockSelection = (event: PointerEvent) => event.stopPropagation();

    return <div className={emptyStateStyle}>{isSmall && <UploadDesign onPointerDown={blockSelection} />}</div>;
};
