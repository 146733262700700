import { InteractiveDesignEngine } from '@design-stack-vista/interactive-design-engine-core';
import { IReactionDisposer, reaction } from 'mobx';
import { ImageInstantUploadExtension } from './ImageInstantUploadExtension';

export class ProcessingTimeoutError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ProcessingTimeoutError';
    }
}

export function isEngineProcessing(designEngine: InteractiveDesignEngine) {
    const {
        cimDocStore: { panels },
        designExtensionSystem,
    } = designEngine;
    for (const panel of panels) {
        const imageItems = panel.items.filter((item) => item.isImageItem());
        for (const item of imageItems) {
            const imageInstantUploadExtension = designExtensionSystem.getExtension(
                item.iid,
                ImageInstantUploadExtension
            );
            if (imageInstantUploadExtension?.isUploading) {
                return true;
            }
        }
    }

    return false;
}

export function waitForEngineProcessingCompletion(
    designEngine: InteractiveDesignEngine,
    abortSignal?: AbortSignal
): Promise<void> {
    return new Promise((resolve) => {
        // eslint-disable-next-line prefer-const
        let processingReactionDisposer: IReactionDisposer;

        const cleanup = () => {
            if (processingReactionDisposer) {
                processingReactionDisposer();
            }
        };

        processingReactionDisposer = reaction(
            () => isEngineProcessing(designEngine),
            (processing) => {
                if (!processing) {
                    cleanup();
                    resolve();
                }
            },
            {
                signal: abortSignal,
            }
        );
    });
}
