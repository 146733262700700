import React from 'react';
import { css } from '@emotion/css';
import {
    Button,
    ModalDialogBody,
    ModalDialogButtons,
    ModalDialogFooter,
    ModalDialogHeader,
    ModalDialogTitle,
    tokens,
    Typography,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { StandardModal } from '../../../components/Modal/components/StandardModal';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';
import { postCardStore } from '../PostCardStore';

const getSuccessModalStyle = (isSmall: boolean) => css`
    .swan-modal-dialog.swan-modal-dialog-panel {
        ${isSmall &&
        `
        padding-top: ${tokens.SwanSemSpace8}
        `}
    }
`;

export const PostCardSuccessModal = observer(() => {
    const { isSmall } = useScreenLayout();
    const { isMailingServiceSuccessModalOpen, setIsMailingServiceSuccessModalOpen } = postCardStore;

    const onRequestDismiss = () => {
        setIsMailingServiceSuccessModalOpen(false);
    };

    return (
        <StandardModal
            isOpen={isMailingServiceSuccessModalOpen}
            ariaLabelledby={text('mailingServiceAddedAccessibilityLabel')}
            contentTestId="mailing-services-added-modal"
            className={getSuccessModalStyle(isSmall)}
        >
            <ModalDialogHeader mb={{ xs: '6', sm: '7' }} data-testid="mailing-services-added-modal-heading">
                <ModalDialogTitle>{text('addedForMailingService')}</ModalDialogTitle>
            </ModalDialogHeader>
            <ModalDialogBody>
                <Typography>{text('areaReservedForMailingAddress')}</Typography>
                <Typography>{text('mailingAddressAutomaticallyAdded')}</Typography>
            </ModalDialogBody>
            <ModalDialogFooter pinned>
                <ModalDialogButtons>
                    <Button
                        skin="primary"
                        size={isSmall ? 'mini' : 'standard'}
                        width={isSmall ? 'full-width' : 'standard'}
                        onClick={onRequestDismiss}
                        data-testid="mailing-services-added-modal-continue-button"
                    >
                        {text('continueBtn')}
                    </Button>
                </ModalDialogButtons>
            </ModalDialogFooter>
        </StandardModal>
    );
});
