/**
 * list of all GA tracking events will found her -
 * Reference - {@link https://vistaprint.atlassian.net/wiki/spaces/GA/pages/3219521605/QUAD+-+Tracking+Concept}
 */

export const GA_EVENTS = {
    // upload modal events
    VIEW_MODAL_PANEL: 'view:modalpanel:[CANVAS FACE]',
    CLICK_MODAL_PANEL: 'click:modalpanel:upload:[CANVAS FACE]',
    UPLOAD_MODAL_PANEL_SUCCESS: 'upload:modalpanel:success:[MEDIATYPE]:[CANVAS FACE]:[FILETYPE]',
    UPLOAD_MODAL_PANEL_RECENT: 'click:modalpanel:recent:[MEDIATYPE]:[CANVAS FACE]:[FILETYPE]',

    // nudge modal
    VIEW_NUDGE_PANEL: 'view:nudgepanel:[CTA1 TEXT];[CTA2 TEXT]',
    CLICK_NUDGE_PANEL: 'click:nudgepanel:[CTA TEXT]',
    LEAVE_IT_BLANK: 'click:[CTA TEXT]-CTA',

    // right panel button
    CLICK_RIGHT_PANEL_UPLOAD: 'click:rightpanel:upload:[CANVAS FACE]',
    CLICK_RIGHT_PANEL_PREVIEW: 'click:rightpanel:PreviewDialogOpen',

    // quick tools
    CLICK_HEAD_PANEL_FIT_TO_BLEED: 'click:headpanel:quickTool:fitToBleed:[CANVAS FACE]',
    CLICK_HEAD_PANEL_FIT_TO_SAFETY: 'click:headpanel:quickTool:fitToSafetyArea:[CANVAS FACE]',
    CLICK_HEAD_PANEL_ROTATE: 'click:headpanel:quickTool:rotate:[CANVAS FACE]',
    CLICK_HEAD_PANEL_DELETE: 'click:headpanel:quickTool:delete:[CANVAS FACE]',

    // preview pdf download
    CLICK_PREVIEW_PANEL_DOWNLOAD: 'click:previewpanel:download:[FILE TYPE]',

    // Automapping
    AUTO_MAPPING_STATUS: 'QUAD;Upload Modal;Upload Button;Automapping triggered',
    REVERSE_AUTO_MAPPING_STATUS: 'QUAD;QUAD;Design Tool;Delete Button;Automapping reversed',

    // navigation clicked events
    STUDIO_REDIRECTION: '[pagePath];[destinationPath];Text Link;[CTA TEXT]-CTA',
    FINISH_PDC_REDIRECTION: '[pagePath];[destinationPath];Text Link;[CTA TEXT]-CTA',
    CONTACT_US_HELP_LINK: '[pagePath];[destinationPath];Header;contactUs;',
    QUAD_CHANGE_SIZE_FLY_OUT: 'QUAD Change Size Fly-Out',
    QUAD_STUDIO_SPECS_AND_TEMPLATE_FLY_OUT: 'QUAD Studio Specs & Templates Fly-Out',
    QUAD_BACK_DESIGN_FLY_OUT: 'QUAD Back Design Fly-Out',
};
