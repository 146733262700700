import { useEffect, useMemo, useState } from 'react';
import { ButtonbarSelectedValue } from '@vp/swan';
import { usePanelTitle } from '../../../../hooks/usePanelTitle';
import { FlexibilityDocument } from '../types';

export const useFlexibilityPreviewPanel = (flexibilityDocument: FlexibilityDocument | undefined) => {
    const { getPanelTitle } = usePanelTitle();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [loadingImage, setLoadingImage] = useState(false);

    const { previewUrls, cimDoc } = flexibilityDocument ?? {};

    useEffect(() => {
        if (previewUrls) {
            setLoadingImage(true);
            const image = new Image();
            image.onload = () => {
                setLoadingImage(false);
            };
            image.src = previewUrls[0];
        }
        return () => setLoadingImage(false);
    }, [previewUrls]);

    const onSelectedValueChange = (newSelectedIndex: ButtonbarSelectedValue) => {
        if (newSelectedIndex) {
            setSelectedIndex(Number(newSelectedIndex));
        }
    };

    const labels = useMemo(
        () => (cimDoc ? cimDoc.document.panels.map((panel) => getPanelTitle(panel.name)) : []),
        [cimDoc]
    );

    return {
        selectedIndex,
        loadingImage,
        previewUrls,
        cimDoc,
        labels,
        onSelectedValueChange,
    };
};
