import React, { createContext, useContext, useMemo, useState } from 'react';

type DesignReviewContextType = {
    isOpenDesignReviewModal: boolean;
    setIsOpenDesignReviewModal: (value: boolean) => void;
};

const DesignReviewContext = createContext<DesignReviewContextType | null>(null);

export const useDesignReviewContext = () => {
    const value = useContext(DesignReviewContext);
    if (!value) {
        throw new Error('useDesignReviewContext must be used within a DesignReviewProvider');
    }
    return value;
};

export interface DesignReviewProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

export const DesignReviewProvider = (props: DesignReviewProviderProps) => {
    const [isOpenDesignReviewModal, setIsOpenDesignReviewModal] = useState<boolean>(false);

    const value = useMemo(() => {
        return {
            isOpenDesignReviewModal,
            setIsOpenDesignReviewModal,
        };
    }, [isOpenDesignReviewModal]);
    return <DesignReviewContext.Provider value={value}>{props.children}</DesignReviewContext.Provider>;
};
