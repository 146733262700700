import React from 'react';
import { css } from '@emotion/css';
import {
    FlexBox,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogNav,
    tokens,
} from '@vp/swan';
import { FlexibilityContent } from './FlexibilityContent';
import { FlexibilityPreview } from './FlexibilityPreview';
import { DesktopExperience, MobileExperience } from '../../../../components/Experiences';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';
import { FlexibilityDocument } from '../types';

const getFlexibilityModalStyle = (isSmall: boolean) => css`
    .swan-modal-dialog {
        max-width: ${isSmall ? '100%' : 'unset'};
        .swan-modal-dialog-panel {
            overflow: hidden;
        }
    }
    .swan-modal-dialog-nav {
        position: absolute;
        background: transparent;
        right: ${isSmall ? tokens.SwanSemSpace5 : tokens.SwanSemSpace8};
        top: ${isSmall ? tokens.SwanSemSpace5 : tokens.SwanSemSpace8};
    }
`;

const getFlexibilityModalBodyStyle = (isSmall: boolean) => css`
    ${isSmall &&
    `
        display: flex;
        flex-direction: column;
    `}
    ${!isSmall &&
    ` display: grid;
      grid-template-columns: auto 440px;
      overflow: hidden;`}
    height: 100%;
`;

interface FlexibilityModalProps {
    ariaLabel: string;
    isFlexibilityModalOpen: boolean;
    onModalClose: () => void;
    flexibilityDocument: FlexibilityDocument | undefined;
    showPreviewSwitcher: boolean;
    headerText: string;
    subHeaderText: string;
    panelContent: React.ReactNode;
    changeButtonText: string;
    onChangeButtonClick: () => Promise<void>;
    testId?: string;
}

export const FlexibilityModal = ({
    ariaLabel,
    isFlexibilityModalOpen,
    onModalClose,
    flexibilityDocument,
    showPreviewSwitcher,
    headerText,
    subHeaderText,
    panelContent,
    changeButtonText,
    onChangeButtonClick,
    testId,
}: FlexibilityModalProps) => {
    const { isSmall } = useScreenLayout();

    return (
        <ModalDialog
            variant="panel-right"
            bodyWidth="standard"
            isOpen={isFlexibilityModalOpen}
            onRequestDismiss={onModalClose}
            className={getFlexibilityModalStyle(isSmall)}
            onlyRenderWhenOpen={true}
            data-testid={testId}
        >
            <ModalDialogContent aria-labelledby={ariaLabel} p={0}>
                <ModalDialogNav>
                    <ModalDialogCloseButton
                        style={{ zIndex: tokens.SwanSemZIndexOverlay }}
                        accessibleText="Close"
                        data-testid="change-size-close-btn"
                    />
                </ModalDialogNav>
                <ModalDialogBody className={getFlexibilityModalBodyStyle(isSmall)} m={0}>
                    <FlexibilityPreview
                        flexibilityDocument={flexibilityDocument}
                        showPreviewSwitcher={showPreviewSwitcher}
                    />
                    <DesktopExperience>
                        <FlexBox flexDirection="column">
                            <FlexibilityContent
                                headerText={headerText}
                                subHeaderText={subHeaderText}
                                panelContent={panelContent}
                                changeButtonText={changeButtonText}
                                onChangeButtonClick={onChangeButtonClick}
                            />
                        </FlexBox>
                    </DesktopExperience>
                    <MobileExperience>
                        <FlexibilityContent
                            headerText={headerText}
                            subHeaderText={subHeaderText}
                            panelContent={panelContent}
                            changeButtonText={changeButtonText}
                            onChangeButtonClick={onChangeButtonClick}
                        />
                    </MobileExperience>
                </ModalDialogBody>
            </ModalDialogContent>
        </ModalDialog>
    );
};
