import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { useProductParams } from './ProductParamsProvider';
import { useProductConfiguration } from '../hooks/calcifer';
import { formatPrice } from '../utils';
import { ColorMode, getPricedSurfaceUpsell, PanelWithColorMode, SurfaceUpsell, UpsellPricing } from '../utils/upsell';

interface UpsellPriceProviderProps {
    children: ReactNode;
}

interface UpsellPriceContextInterface {
    getPricingByPanelName(panelName: string): UpsellPricing | undefined;
    getFormattedDiscountPrice: (panelName: string) => string | null;
    isUpsellPanelAdded(panelId: string): boolean;
    surfaceUpsells: Record<string, SurfaceUpsell> | undefined;
}

const UpsellPriceContext = createContext<UpsellPriceContextInterface | null>(null);

export const useUpsellPrice = () => {
    const value = useContext(UpsellPriceContext);
    if (!value) {
        throw new Error('useUpsellPrice must be used within a UpsellPriceProvider');
    }
    return value;
};

export const UpsellPriceProvider = ({ children }: UpsellPriceProviderProps) => {
    const [surfaceUpsells, setSurfaceUpsells] = useState<Record<string, SurfaceUpsell> | undefined>();
    const { cimDocStore } = useDesignEngine();
    const { locale } = useProductParams();

    const { productData, secondaryConfig } = useProductConfiguration();

    const upsellProductDeps = useMemo(() => {
        return productData
            ? {
                  productKey: productData?.productKey,
                  selectedOptions: productData?.selectedOptions,
                  productVersion: productData?.productVersion,
                  quantity: productData?.quantity,
              }
            : undefined;
    }, [productData?.productKey, productData?.selectedOptions, productData?.productVersion, productData?.quantity]);

    const getPricingByPanelName = useCallback(
        (panelName: string): UpsellPricing | undefined => {
            if (!surfaceUpsells) {
                return undefined;
            }
            const dialogSurfaceUpsell = surfaceUpsells[panelName];
            if (!dialogSurfaceUpsell) {
                return undefined;
            }
            const { pricing: allPricing, colorOption, currency } = dialogSurfaceUpsell;
            if (!allPricing || !colorOption) {
                return undefined;
            }
            const pricing = allPricing[colorOption];
            if (!pricing) {
                return undefined;
            }
            /**
             * `pricing.differentialDiscountPrice` could be of floating point value.
             * Parsing it to integer and checking if the price is 0.
             * If the price is 0 we are not showing the up-sell data.
             */
            const price = parseInt(pricing.differentialDiscountPrice.toString(), 10);
            if (price === 0) {
                return undefined;
            }
            return {
                listPrice: pricing?.differentialListPrice,
                discountPrice: pricing?.differentialDiscountPrice,
                currency,
            };
        },
        [surfaceUpsells]
    );

    const getFormattedDiscountPrice = (panelName: string) => {
        const upsellPricing = getPricingByPanelName(panelName);
        if (!upsellPricing) return null;
        return formatPrice(upsellPricing.discountPrice, locale, upsellPricing.currency);
    };

    useAsyncEffect(
        (helpers) => {
            async function initPricingAndFetchUpsellData() {
                if (upsellProductDeps && secondaryConfig) {
                    const document = cimDocStore.asJson;
                    const { surfaceUpsells } = secondaryConfig;

                    const pricedSurfaceUpsell = await getPricedSurfaceUpsell(
                        document,
                        surfaceUpsells,
                        upsellProductDeps,
                        helpers.abortSignal
                    );
                    setSurfaceUpsells(pricedSurfaceUpsell);
                }
            }

            if (upsellProductDeps && secondaryConfig) {
                initPricingAndFetchUpsellData();
            }
        },
        [upsellProductDeps, secondaryConfig]
    );

    useEffect(() => {
        return () => setSurfaceUpsells(undefined);
    }, []);

    const isUpsellPanelAdded = (panelId: string) => {
        const panel = cimDocStore.panels.find((panel) => panel.id === panelId);
        const panelName = panel?.panelProperties.name;
        const panelAsJson = panel?.asJson as PanelWithColorMode;
        return Boolean(
            panelName && surfaceUpsells && surfaceUpsells[panelName] && panelAsJson.colorMode === ColorMode.Color
        );
    };

    const storeValue = useMemo(() => {
        return {
            getPricingByPanelName,
            getFormattedDiscountPrice,
            surfaceUpsells,
            isUpsellPanelAdded,
        };
    }, [surfaceUpsells, getPricingByPanelName]);

    return <UpsellPriceContext.Provider value={storeValue}>{children}</UpsellPriceContext.Provider>;
};
