import React from 'react';
import { PipeSeparator } from '@vp/swan';
import { MyProjectButton } from './MyProjectButton';
import { SaveChangesButtonWrapper } from './SaveChanges/SaveChangesButtonWrapper';
import { VistaLogo } from './VistaLogo';
import { useDebugFlag } from '../../../features/Debug/useDebugFlag';

export const HeaderLeftChildren = (): JSX.Element => {
    const { isFlagEnabled } = useDebugFlag();
    const isSaveAndMyProjectEnabled = isFlagEnabled('quad_save_and_my_project');

    return (
        <>
            <VistaLogo />
            {isSaveAndMyProjectEnabled && (
                <>
                    <MyProjectButton />
                    <PipeSeparator p="0" />
                    <SaveChangesButtonWrapper />
                </>
            )}
        </>
    );
};
