import { useEffect } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useLayoutContainers } from '@design-stack-vista/ida-framework';
import { useEventCallback } from '@design-stack-vista/utility-react';
import { NUDGE_OFFSET } from '../config/constant';
import { useLayoutZoom } from '../providers';
import { allowDownPan, allowLeftPan, allowRightPan, allowUpPan } from '../utils';

export function usePanelNudger(designAreaRect: DOMRect | undefined, isSmallDevice: boolean) {
    const {
        layoutStore: { offset, setOffset },
    } = useDesignEngine();
    const { panContainer } = useLayoutContainers();
    const { canvasZoomFactor } = useLayoutZoom();
    const allowedKeys = ['arrowup', 'arrowdown', 'arrowright', 'arrowleft'];

    const keyListener = useEventCallback(
        (e: KeyboardEvent) => {
            const { key } = e;
            const loweredKey = key.toLowerCase();
            const panAreaRect = panContainer?.getBoundingClientRect();

            if (!allowedKeys.includes(loweredKey) || canvasZoomFactor <= 1 || !panAreaRect || isSmallDevice) {
                return;
            }

            e.preventDefault();
            if (loweredKey === 'arrowup') {
                if (allowDownPan(panAreaRect, designAreaRect)) {
                    setOffset({ x: offset.x, y: offset.y + NUDGE_OFFSET });
                }
            } else if (loweredKey === 'arrowdown') {
                if (allowUpPan(panAreaRect, designAreaRect)) {
                    setOffset({ x: offset.x, y: offset.y - NUDGE_OFFSET });
                }
            } else if (loweredKey === 'arrowleft') {
                if (allowLeftPan(panAreaRect, designAreaRect)) {
                    setOffset({ x: offset.x + NUDGE_OFFSET, y: offset.y });
                }
            } else if (loweredKey === 'arrowright') {
                if (allowRightPan(panAreaRect, designAreaRect)) {
                    setOffset({ x: offset.x - NUDGE_OFFSET, y: offset.y });
                }
            }
        },
        [allowedKeys, panContainer]
    );

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => keyListener(e);
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [keyListener]);
}
