import React from 'react';
import { css } from '@emotion/css';
import { tokens, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { ProductAttributes } from '../../../config/constant';
import { useTranslatedConfig } from '../../../hooks/useTranslatedConfig';
import { OptionButton } from '../../Flexibility/common';

interface OrientationItemProps {
    orientation: string;
    activeOrientation: string;
    onClick: () => void;
}

const customClass = css`
    border-radius: ${tokens.SwanSemBorderRadiusAction} !important;
`;

export const OrientationItem = observer(({ orientation, activeOrientation, onClick }: OrientationItemProps) => {
    const { getTranslatedOptionName } = useTranslatedConfig();
    const label = getTranslatedOptionName(ProductAttributes.Orientation, orientation);

    return (
        <OptionButton
            selected={orientation === activeOrientation}
            onClick={onClick}
            testId={label}
            showActiveIcon={false}
            customClass={customClass}
        >
            <Typography fontWeight="bold" fontSize="small">
                {label}
            </Typography>
        </OptionButton>
    );
});
