import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens } from '@vp/swan';
import { DownloadPdfProof } from './DownloadPdfProof';
import { PreviewSwitcher } from './PreviewSwitcher';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { usePreview } from '../../providers/PreviewProvider';

const previewSwitcherStyle = css`
    position: absolute;
`;

const getPdfProofStyle = (isSmall: boolean, is3DViewSupported: boolean) => css`
    margin: auto;
    padding-top: ${isSmall && is3DViewSupported ? tokens.SwanSemSpace9 : tokens.SwanBaseSpace0};
`;

export const DesignPreviewHeader = () => {
    const { isSmall } = useScreenLayout();
    const { is3DViewSupported } = usePreview();
    return (
        <FlexBox>
            <PreviewSwitcher testIdSuffix="preview" className={previewSwitcherStyle} pl="8" />
            <DownloadPdfProof styling={getPdfProofStyle(isSmall, is3DViewSupported)} />
        </FlexBox>
    );
};
