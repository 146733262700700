import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, SelectionSetInput, SelectionSetLabel, tokens, Typography } from '@vp/swan';
import { ProductAttributes } from '../../../config/constant';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { useTranslatedConfig } from '../../../hooks/useTranslatedConfig';
import { generateTestIdFromText } from '../../../utils/generateTestIdFromText';
import { text } from '../../../utils/localization';
import {
    MEDIUM_LARGE_SCREEN_SVG_WIDTH,
    SHAPE_NAME,
    SHAPE_TO_ICON_MAP,
    ShapeKey,
    SMALL_SCREEN_SVG_WIDTH,
} from '../constants';

const textStyle = css`
    width: min-content;
`;

const optionContainerStyle = css`
    flex-direction: column;
    align-items: center;
    .swan-selection-set-label {
        padding: ${tokens.SwanSemSpaceNone};
        margin-bottom: ${tokens.SwanSemSpace2};
        height: auto;
    }
`;

interface ShapeOptionProps {
    shape: ShapeKey;
    price?: string;
}

export const ShapeOption = ({ shape, price }: ShapeOptionProps) => {
    const { isSmall } = useScreenLayout();
    const { getTranslatedOptionName } = useTranslatedConfig();
    const widthAndHeight = isSmall ? SMALL_SCREEN_SVG_WIDTH : MEDIUM_LARGE_SCREEN_SVG_WIDTH;

    const ShapeIcon = SHAPE_TO_ICON_MAP[shape];
    // To handle inconsistent naming data, taking name from generic shape to name map
    const translatedName = getTranslatedOptionName(ProductAttributes.Shape, shape).trim();
    const name = SHAPE_NAME[translatedName] ? text(SHAPE_NAME[translatedName]) : translatedName;
    const dataTestId = generateTestIdFromText(`${shape}-shape`);

    return (
        <FlexBox className={optionContainerStyle} data-testid={dataTestId}>
            <SelectionSetInput key={translatedName} value={shape} aria-label={translatedName}>
                <SelectionSetLabel>
                    <ShapeIcon width={widthAndHeight} height={widthAndHeight} />
                </SelectionSetLabel>
            </SelectionSetInput>
            <Typography fontWeight="bold" fontSize="small" textAlign="center" className={textStyle}>
                {name}
            </Typography>
            {price && (
                <Typography textColor="subtle" fontSize="small" textAlign="center" className={textStyle}>
                    {price}
                </Typography>
            )}
        </FlexBox>
    );
};
