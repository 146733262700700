import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens } from '@vp/swan';
import { DesignPreviewButton } from './DesignPreviewButton';
import { DesktopExperience } from '../../../components/Experiences';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { PreviewZoomControls } from '../../Zoom';

const getContainerStyle = (isSmall: boolean) => css`
    width: 100%;
    ${isSmall &&
    `
        padding:0;
        border: none;
    `}
`;

const zoomControlStyle = css`
    position: absolute;
    left: ${tokens.SwanSemSpace6};
`;

export const DesignPreviewSelection = () => {
    const { isSmall } = useScreenLayout();

    return (
        <FlexBox justifyContent="center" alignItems="center" className={getContainerStyle(isSmall)}>
            <DesktopExperience>
                <div className={zoomControlStyle}>
                    <PreviewZoomControls />
                </div>
            </DesktopExperience>
            <DesignPreviewButton />
        </FlexBox>
    );
};
