import React from 'react';
import { buildInstructionsUrl, buildTransientDocumentUri, InstructionsType } from '@design-stack-ct/documents-sdk';
import { VortexContainer } from '@rendering/vortex-react';
import { observer } from 'mobx-react-lite';
import { REQUESTOR, VORTEX_TENANT } from '../../config/constant';
import { useProductConfiguration } from '../../hooks/calcifer/useProductConfiguration';
import { useGetDesignDocument } from '../../hooks/useGetDesignDocument';
import { usePreview } from '../../providers/PreviewProvider';
import { noticeError } from '../../utils/newRelicUtils';
import { DesignPreview } from '../DesignPreview/DesignPreview';

const USER = { referer: REQUESTOR, tenant: VORTEX_TENANT };
interface ThreeDPreviewInstanceProps {
    children?: React.ReactNode;
}

export const ThreeDPreviewInstance = observer(({ children }: ThreeDPreviewInstanceProps): JSX.Element | null => {
    const { productData } = useProductConfiguration();
    const { setIs3DView, setIs3DViewSupported } = usePreview();
    const { getDocumentWithoutPlaceholders } = useGetDesignDocument();

    if (!productData) {
        return null;
    }

    const { productKey, selectedOptions, productVersion } = productData;
    const { ...docWithoutMetadata } = getDocumentWithoutPlaceholders();
    /*
         This will create Instruction Url for whole cimdoc, not for each panel.
         The scene Url will render corresponding panel depending on whether its front or back.
     */
    const previewDocumentUrl = buildTransientDocumentUri(docWithoutMetadata);
    const previewInstructionsUrl = buildInstructionsUrl(previewDocumentUrl, InstructionsType.preview);

    return (
        <VortexContainer
            user={USER}
            productConfig={{
                sku: productKey,
                attributes: selectedOptions,
                version: productVersion,
                tenant: VORTEX_TENANT,
            }}
            renderingInstructionsUrl={previewInstructionsUrl}
            onError={(error) => {
                setIs3DView(false);
                setIs3DViewSupported(false);
                noticeError(error, {
                    method: 'VortexContainer onError',
                });
            }}
            fallback={<DesignPreview />}
            preserveCamera={true}
            cameraOptions={{
                minZoom: 0.5,
                maxZoom: 1,
                hover: true,
            }}
            initializationOptions={{
                swivel: { enabled: true },
                scaleToInitialState: true,
                cameraOrientation: {
                    azimuthAngle: 0,
                    polarAngle: Math.PI / 2,
                },
            }}
        >
            {children}
        </VortexContainer>
    );
});
