import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens } from '@vp/swan';
import { PostCardDetails, PostCardMailable, PostCardMailingToggle } from '.';
import { useScreenLayout } from '../../../hooks/useScreenLayout';

const getPostCardSectionStyle = (isSmall: boolean) => css`
    border-radius: ${tokens.SwanSemBorderRadiusSubtle};
    padding: ${tokens.SwanSemSpace4} ${tokens.SwanSemSpace5};
    gap: ${tokens.SwanSemSpace2};
    background-color: ${tokens.SwanSemColorBgPromo};
    margin-top: ${isSmall ? tokens.SwanSemSpace8 : tokens.SwanSemSpace5};
    margin-bottom: ${isSmall ? tokens.SwanSemSpace6 : tokens.SwanSemSpace5};
`;

export const PostCardSection = (): JSX.Element => {
    const { isSmall } = useScreenLayout();
    return (
        <PostCardMailable>
            <FlexBox
                data-testid="postcard-switching-block"
                className={getPostCardSectionStyle(isSmall)}
                justifyContent="space-between"
            >
                <PostCardDetails />
                <PostCardMailingToggle />
            </FlexBox>
        </PostCardMailable>
    );
};
