import experiments from '../config/experiments.json';
import { QuadExperiment } from '../types/quadExperiment';

export const experimentFilter = (locale: string, mpvId: string) => (experiment: QuadExperiment) => {
    const { supportedLocales, supportedMpvIds } = experiment;

    // mandatory fields for experiment filters
    return supportedLocales.includes(locale) && supportedMpvIds.includes(mpvId);
};

export const getQUADExperiments = (locale: string, mpvId: string) => {
    const lowerCaseLocale = locale.toLowerCase();
    const [experiment] = (experiments as QuadExperiment[]).filter(experimentFilter(lowerCaseLocale, mpvId));

    return experiment;
};
