import React, { useCallback, useMemo } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { className } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { AddEditDesign } from './AddEditDesign';
import { usePanelTitle } from '../../hooks/usePanelTitle';
import { useRedirectURL } from '../../hooks/useRedirectURL';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { getAllItemsInCimdocPanels, getMessage, REDIRECT_MESSAGES } from '../../utils';
import { text } from '../../utils/localization';
import { PageSection } from '../TrackingEvents/constant';

export enum RedirectToStudioPosition {
    'BOTTOM' = 'bottom',
    'DEFAULT' = 'default',
}

type Props = {
    position?: RedirectToStudioPosition;
};

const redirectStudioStyle = css`
    z-index: 1;
`;

export const RedirectToStudio = observer(({ position = RedirectToStudioPosition.DEFAULT }: Props) => {
    const {
        layoutStore: { visiblePanels },
        cimDocStore: { panels },
    } = useDesignEngine();
    const { getPanelTitle } = usePanelTitle();
    const { isSmall } = useScreenLayout();
    const { redirectToStudio } = useRedirectURL();

    const activePanel = isSmall ? visiblePanels[0] : panels[0];
    const activePanelName = activePanel?.panelProperties.name;

    const panelTitle = getPanelTitle(activePanelName)?.toLowerCase();
    const isEmptyDesign = getAllItemsInCimdocPanels([activePanel]).length === 0;
    const queryText = text(getMessage(isEmptyDesign, REDIRECT_MESSAGES.QUERY, activePanelName), { panelTitle });
    const redirectText = text(getMessage(isEmptyDesign, REDIRECT_MESSAGES.REDIRECT, activePanelName), { panelTitle });

    const panelNumber = useMemo(() => {
        const panel = panels.find((panel) => panel.id === activePanel.id);
        if (panel) {
            return panels.indexOf(panel) + 1;
        }
    }, [panels, activePanel]);

    const onClickHandler = useCallback(() => {
        redirectToStudio({
            pageSection: PageSection.QuickUploadAndDesign,
            ctaText: `${redirectText}`,
            panelNumber,
        });
    }, [panelNumber, redirectText, redirectToStudio]);

    if (!activePanelName) {
        return null;
    }

    return (
        <AddEditDesign
            designUploadStatusLabel={queryText}
            redirectLinkLabel={redirectText}
            onClick={onClickHandler}
            position={position}
            testId="redirect-to-studio"
            className={className({ [redirectStudioStyle]: isSmall })}
        />
    );
});
