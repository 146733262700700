import React from 'react';
import { css } from '@emotion/css';
import { tokens, Typography } from '@vp/swan';
import { FlexibilityChangeButton } from './FlexibilityChangeButton';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';

const getFlexibilityContentContainerStyles = (isSmall: boolean) => css`
    width: 100%;
    overflow: ${isSmall ? 'unset' : 'hidden'};
    padding-top: ${isSmall ? tokens.SwanSemSpace6 : tokens.SwanSemSpace9};
    flex: 1 0 auto;
    overflow-y: ${isSmall ? 'unset' : 'auto'};
    ${!isSmall && `height: 0`}

}
`;

const getFlexibilityContentStyle = (isSmall: boolean) => css`
    width: 100%;
    height: 100%;
    padding: ${isSmall ? tokens.SwanSemSpace5 : tokens.SwanSemSpace6};
    padding-bottom: ${tokens.SwanSemSpace5};
    overflow: ${isSmall ? 'unset' : 'visible auto'};
`;

interface FlexibilityPanelContentProps {
    headerText: string;
    subHeaderText: string;
    panelContent: React.ReactNode;
    changeButtonText: string;
    onChangeButtonClick: () => Promise<void>;
}

export const FlexibilityContent = ({
    headerText,
    subHeaderText,
    panelContent,
    changeButtonText,
    onChangeButtonClick,
}: FlexibilityPanelContentProps) => {
    const { isSmall } = useScreenLayout();

    return (
        <>
            <div data-testid="flexibility-content" className={getFlexibilityContentContainerStyles(isSmall)}>
                <div className={getFlexibilityContentStyle(isSmall)}>
                    <Typography
                        fontSize={isSmall ? 'large' : 'x2large'}
                        mb={{ xs: '3', sm: '3' }}
                        fontWeight="bold"
                        data-testid="flexibility-header-text"
                    >
                        {headerText}
                    </Typography>
                    <Typography
                        fontSize={isSmall ? 'xsmall' : 'standard'}
                        mb={{ xs: '5', sm: '7' }}
                        data-testid="flexibility-subheader-text"
                    >
                        {subHeaderText}
                    </Typography>
                    {panelContent}
                </div>
            </div>
            <FlexibilityChangeButton changeButtonText={changeButtonText} onChangeButtonClick={onChangeButtonClick} />
        </>
    );
};
