import { CimDoc } from '@design-stack-vista/cdif-types';
import { v4 as uuid } from 'uuid';
import { REQUESTOR } from '../config/constant';
import { fetchWrapper } from '../utils';

const CUSTOMIZATION_TRANSFER_URL = 'https://artworkgeneration.cimpress.io/';

export async function getResizedDocumentByDesignViews(
    documentUrl: string,
    designViewsUrl: string,
    signal?: AbortSignal
): Promise<CimDoc> {
    const response = await fetchWrapper(`${CUSTOMIZATION_TRANSFER_URL}api/v1/Vista/Resize`, {
        method: 'POST',
        requestInitiator: 'getResizedDocumentByDesignViews',
        body: {
            requester: REQUESTOR,
            documentUrl,
            designViewsUrl,
        },
        headers: {
            'x-caller-id': REQUESTOR,
            'x-correlation-id': uuid(),
        },
        signal,
    });

    return response;
}
