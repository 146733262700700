import React from 'react';
import { usePreviewSelectors } from '@design-stack-vista/preview-components';
import { css } from '@emotion/css';
import { tokens } from '@vp/swan';
import { DesktopExperience } from '../../components/Experiences/DesktopExperience';
import { DesignPreviewSelection } from '../quad/components/DesignPreviewSelection';
import { PreviewZoomControls } from '../Zoom';

const zoomControlStyle = css`
    padding-left: ${tokens.SwanSemSpace6};
`;

export const TwoDPreviewController = (): JSX.Element => {
    const { previewPropGetters } = usePreviewSelectors();
    return (
        <>
            <DesktopExperience>
                <>
                    {previewPropGetters.length === 1 && (
                        <div className={zoomControlStyle}>
                            <PreviewZoomControls />
                        </div>
                    )}
                </>
            </DesktopExperience>
            {previewPropGetters.length > 1 && <DesignPreviewSelection />}
        </>
    );
};
