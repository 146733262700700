import React from 'react';
import { css } from '@emotion/css';
import { Button } from '@vp/swan';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode | React.ReactNode[];
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const horizontalScrollButtonStyle = css`
    &.swan-button-skin-unstyled {
        height: inherit;
        opacity: 0.9;
        position: absolute;
        display: flex;
        align-items: center;
    }
`;

export function HorizontalScrollButton({ children, onClick, ...restProps }: Props) {
    return (
        <Button
            px="4"
            skin="unstyled"
            className={horizontalScrollButtonStyle}
            onClick={onClick}
            backgroundColor="standard"
            {...restProps}
        >
            {children}
        </Button>
    );
}
