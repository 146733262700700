import * as React from 'react';
import {
    getRootClassNames,
    ScreenClassProvider,
    SiteContent,
    SWAN_BASE_URL_MAP,
    SWAN_STYLE_KEY_MAP,
    SwanFontNameEnum,
    SwanHead,
    SwanProvider,
    useBrowserClasses,
} from '@vp/swan';
import { Helmet, HelmetProvider } from 'react-helmet-async';

/**
 * List of all the styles that are used in the app.
 * We have to explicitly need to load the styling of all the components
 */
const eagerlyLoadedStyles = [
    SWAN_STYLE_KEY_MAP.alertBox,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.selectionSet,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.progressiveImage,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.spinner,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.zoomControls,
];

/**
 * List of all the font-families used in the APP
 */
const swanFontFamilyNames = [SwanFontNameEnum.Graphik, SwanFontNameEnum.GraphikMedium, SwanFontNameEnum.GraphikRegular];

interface SwanConfigurationProps {
    children?: React.ReactNode;
}

export const SwanConfiguration = ({ children }: SwanConfigurationProps) => {
    const browser = useBrowserClasses();
    const rootClassName = getRootClassNames();
    const swanBaseUrl = SWAN_BASE_URL_MAP.default.cdn;
    return (
        <SwanProvider swanBaseUrl={swanBaseUrl}>
            <HelmetProvider>
                <Helmet htmlAttributes={{ class: browser }} bodyAttributes={{ class: rootClassName }} />
                <SwanHead
                    renderStyleContentAsChildren
                    renderWith={Helmet}
                    styleSheetKeys={eagerlyLoadedStyles}
                    fontNames={swanFontFamilyNames}
                />
                {/**
                 * SiteContent is not related to Typographic changes,
                 * but it's the proper way of using SWAN
                 * as SiteContent and it's main content need to be enclosed within
                 * `SiteContent` and `SiteMain` Components
                 *
                 * Reference : https://vista.design/swan/developer-setup/configuring-react-projects/#Getting-Started-With-CRA-or-Parcel
                 *  refer app.tsx & index.tsx file
                 */}
                <SiteContent>
                    <ScreenClassProvider>{children}</ScreenClassProvider>
                </SiteContent>
            </HelmetProvider>
        </SwanProvider>
    );
};
