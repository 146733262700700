import { useCallback } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { useNotificationFramework } from '../../../components/Notification';
import { PRICING_NOTIFICATION_DISMISS_TIMEOUT, ProductAttributes } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useTranslatedConfig } from '../../../hooks/useTranslatedConfig';
import { useFullScreenLoader } from '../../../providers';
import { text } from '../../../utils/localization';
import { FlexibilityDocument, useLoadProduct } from '../../Flexibility/common';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { decorationAreaStore } from '../store';

export const useLoadNewDecorationArea = () => {
    const { loadProduct } = useLoadProduct();
    const { productData } = useProductConfiguration();
    const { fireEvent } = useCustomEventPropagation();
    const { notifyCustomer } = useNotificationFramework();
    const { getTranslatedOptionName } = useTranslatedConfig();
    const { setFullScreenLoaderData } = useFullScreenLoader();
    const { activeDecorationArea, decorationAreaChoiceGroups, setOpenDecorationAreaModal } = decorationAreaStore;

    const loadNewDecorationArea = useCallback(
        async (flexibilityDocument: FlexibilityDocument | undefined) => {
            if (!productData || !activeDecorationArea || !decorationAreaChoiceGroups[activeDecorationArea]) {
                return;
            }

            const { productKey, productVersion } = productData;
            const productAttributes = decorationAreaChoiceGroups[activeDecorationArea];
            const areaName = getTranslatedOptionName(ProductAttributes.DecorationArea, activeDecorationArea);
            const messageToShowCustomer = text('decorationAreaChangeNotificationMessage', {
                areaName,
            });

            setFullScreenLoaderData({ showLoader: true });
            setOpenDecorationAreaModal(false);

            const loadedNewDecorationArea = await loadProduct({
                newCimDoc: flexibilityDocument?.cimDoc,
                newProduct: {
                    productKey,
                    productVersion,
                    productAttributes,
                },
            });

            setFullScreenLoaderData({ showLoader: false });

            if (!loadedNewDecorationArea) {
                return;
            }

            notifyCustomer(
                {
                    notificationType: 'positive',
                    messageToShowCustomer,
                },
                true,
                PRICING_NOTIFICATION_DISMISS_TIMEOUT
            );

            fireEvent({
                name: ACTION_NAME.EXPERIMENT_CLICKED,
                extraData: {
                    label: QUAD_TRACKING_EVENTS.CLICK_CHANGE_AREA_APPLY,
                    eventDetail: 'QUAD;QUAD;Apply change area',
                    navigationDetail: 'ChangeArea:Apply change area',
                    pageSection: PageSection.ChangeArea,
                    pageStage: PageStage.Design,
                    category: undefined,
                    experimentDetail: {
                        decorationArea: activeDecorationArea,
                    },
                },
            });
        },
        [productData, decorationAreaChoiceGroups, getTranslatedOptionName, loadProduct]
    );

    return {
        loadNewDecorationArea,
    };
};
