import React from 'react';
import {
    PRODUCT_OPTIONS_SELECTED_EVENT_NAME,
    ProductOptionsAttributeSelectedEventProperties,
    ProductOptionsSelectedEventProductOption,
    ProductOptionsSelectedEventProperties,
    trackAttributeSelected,
    trackProductOptionsSelected,
} from '@vp/product-options-tracking';
import { getEstimatedPricing } from '../api/pricing';
import { useProductConfiguration } from '../hooks/calcifer';
import { noticeError } from '../utils';

const CATEGORY = 'quad';
const PAGE_SECTION = 'Design Services';
const PAGE_STAGE = 'Design';

enum Update {
    UpSell = 'upsell',
    DownSell = 'downsell',
    NoChange = 'nochange',
}
// This dummy implementation is just here to prevent a type error; the context will be
// properly set by the time the downstream components need to access it.
const noOpContext: ProductOptionsTracking = {
    trackProductOptionsSelected() {
        // noop
    },
    trackAttributeSelected() {
        // noop
    },
};

const ProductOptionsTrackingContext = React.createContext<ProductOptionsTracking>(noOpContext);

export function useProductOptionsTracking() {
    return React.useContext(ProductOptionsTrackingContext);
}

export type ProductOptionsTracking = {
    trackProductOptionsSelected(): void;
    trackAttributeSelected(productOptionName: string, productOptionValue: string): void;
};

export type ProductOptionsTrackingProviderProps = {
    children: React.ReactNode;
};

export function ProductOptionsTrackingProvider(props: ProductOptionsTrackingProviderProps) {
    const productConfig = useProductConfiguration();
    const productData = productConfig.productData;
    const completedSelectedOptions = productData?.selectedOptions ?? {};
    const customerSelectedOptions = productData?.customerSelectedOptions ?? {};

    const [initialCustomerSelections] = React.useState(customerSelectedOptions);
    const [initialDiscountedPrice, setInitialDiscountedPrice] = React.useState(0);
    const [currentListPrice, setCurrentListPrice] = React.useState(0);
    const [currentDiscountedPrice, setCurrentDiscountedPrice] = React.useState(0);

    const currentQuantity = productData?.quantity ?? 0;

    React.useEffect(() => {
        // We'll update the prices whenever the selections change
        async function updatePrice() {
            try {
                // Grab the completed product and override it with any of the customer's
                // selections
                const completedOptions = { ...completedSelectedOptions, ...customerSelectedOptions };
                if (productData && currentQuantity) {
                    const price = await getEstimatedPricing(
                        productData.productKey,
                        completedOptions,
                        currentQuantity,
                        productData.productVersion
                    );

                    const currPrice = price.estimatedPrices[currentQuantity.toString() ?? '0'];

                    // For consistency's sake, we will only use the untaxed prices;
                    // for analytics purposes, we probably only care about the untaxed price
                    // anyway.
                    const discountedPrice = currPrice.totalDiscountedPrice.untaxed;
                    const listPrice = currPrice.totalListPrice.untaxed;
                    setCurrentDiscountedPrice(discountedPrice);
                    setCurrentListPrice(listPrice);

                    // If it hasn't been initialized, set the initial price.
                    if (initialDiscountedPrice === 0) {
                        setInitialDiscountedPrice(discountedPrice);
                    }
                }
            } catch (err) {
                reportError(err);
            }
        }

        updatePrice();
    }, [completedSelectedOptions, customerSelectedOptions]);

    function getUpsellOrDownsell() {
        if (currentDiscountedPrice > initialDiscountedPrice) {
            return 'upsell';
        } else if (currentDiscountedPrice < initialDiscountedPrice) {
            return 'downsell';
        } else {
            return 'nochange';
        }
    }

    const productOptionsTracking: ProductOptionsTracking = {
        trackProductOptionsSelected() {
            try {
                if (productData) {
                    const mpvId = productData.mpvId;
                    const coreProductId = productData.productKey;
                    const productName = productData.productName;
                    const quantity = currentQuantity;
                    const variant = '';

                    const props: ProductOptionsSelectedEventProperties = {
                        category: CATEGORY,
                        pageSection: PAGE_SECTION,
                        pageStage: PAGE_STAGE,
                        product_id: mpvId,
                        core_product_id: coreProductId,
                        label: PRODUCT_OPTIONS_SELECTED_EVENT_NAME,
                        name: productName,
                        productOptions: buildProductOptionsField(),
                        productUpdate: getUpsellOrDownsell(),
                        price: currentDiscountedPrice,
                        list_price: currentListPrice,
                        variant: variant,
                        sales_quantity: quantity,
                    };

                    try {
                        trackProductOptionsSelected(props);
                    } catch (err) {
                        noticeError(err, {
                            method: 'trackProductOptionSelected',
                            ...props,
                        });
                    }
                } else {
                    noticeError(new Error('Failed to trackProductOptionsSelected - could not get product data'), {
                        method: 'trackProductOptionSelected',
                    });
                }
            } catch (err) {
                noticeError(err, {
                    method: 'trackProductOptionSelected',
                });
            }
        },

        trackAttributeSelected(productOptionName: string, productOptionValue: string) {
            const mpvId = productData?.mpvId;

            if (mpvId) {
                // Send an event for each changed product option
                const props: ProductOptionsAttributeSelectedEventProperties = {
                    pageName: `${CATEGORY}:${mpvId}`,
                    pageSection: PAGE_SECTION,
                    pageStage: PAGE_STAGE,
                    category: CATEGORY,
                    label: productOptionName,
                    eventDetail: productOptionValue,
                };
                trackAttributeSelected(props);
            } else {
                noticeError(new Error('Failed to trackAttributeSelected - missing mpvId'), {});
            }
        },
    };

    // Map the current set of selections to the list format used by trackProductOptionsSelected
    function buildProductOptionsField(): ProductOptionsSelectedEventProductOption[] {
        // Include the quantity in the set of options
        const optionEntries = Object.entries(customerSelectedOptions).map(([key, value]) => {
            const initialValue = initialCustomerSelections?.[key];
            // If it's never been set or it's
            const isUpdated = initialValue === undefined || initialValue !== value;
            const update = isUpdated ? (value > initialValue ? Update.UpSell : Update.DownSell) : Update.NoChange;

            // TODO: Need to update the value of isVisible. Set it to false as not all selected options visible to customer.
            return { key, value, isUpdated, update, isVisible: false };
        });

        optionEntries.push({
            key: 'Quantity',
            value: currentQuantity.toString(),
            isUpdated: false,
            update: Update.NoChange,
            isVisible: false,
        });

        return optionEntries;
    }

    return (
        <ProductOptionsTrackingContext.Provider value={productOptionsTracking}>
            {props.children}
        </ProductOptionsTrackingContext.Provider>
    );
}
