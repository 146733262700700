import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useIsEligibleForAdjustColor } from './useIsEligibleForAdjustColor';
import { adjustContrastStore } from '../store';

export const useHandleAdjustContrastExperience = () => {
    const { setShowAdjustContrastModal } = adjustContrastStore;
    const { isEligibleForAdjustColor } = useIsEligibleForAdjustColor();

    const handleAdjustContrastExperience = (panel: PanelState) => {
        if (isEligibleForAdjustColor(panel)) {
            setShowAdjustContrastModal(true);
        }
    };

    return {
        handleAdjustContrastExperience,
    };
};
