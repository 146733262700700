import React from 'react';
import { FlexBox } from '@vp/swan';
import { HelpLink } from './HelpLink';
import { SpecsAndTemplateButton } from './SpecsAndTemplate';
import { TeamsButton } from './Teams/TeamsButton';

export const HeaderRightChildren = (): JSX.Element => {
    return (
        <FlexBox flexDirection="row" gap="4">
            <TeamsButton />
            <HelpLink />
            <SpecsAndTemplateButton />
        </FlexBox>
    );
};
