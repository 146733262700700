import { useEventCallback } from '@design-stack-vista/utility-react';
import { useKeyboardShortcut } from './useKeyboardShortcut';
import { useDeletePanelItems } from '../../../hooks/useDeletePanelItems';
import { BACKSPACE, DELETE } from '../keyboardEventKeys';

export function useKeyboardItemDeleter() {
    const { isDeleteable, deletePanelItems } = useDeletePanelItems();

    const canDelete = useEventCallback(() => isDeleteable, [isDeleteable]);

    useKeyboardShortcut({ shortcutKeys: [BACKSPACE], callback: deletePanelItems, isEnabled: canDelete });
    useKeyboardShortcut({ shortcutKeys: [DELETE], callback: deletePanelItems, isEnabled: canDelete });
}
