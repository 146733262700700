import React from 'react';
import { FlexBox, PopoverContent, Typography } from '@vp/swan';
import { ChangeFinishButtons } from './ChangeFinishButtons';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';

interface Props {
    textSelection: string;
    onFinishIconClick: () => void;
    onPopoverDeleteButtonClick: () => void;
    popoverDialogStyle?: string;
}

export const ChangeFinishDialog = ({
    textSelection,
    onFinishIconClick,
    onPopoverDeleteButtonClick,
    popoverDialogStyle,
}: Props) => {
    const { isSmall } = useScreenLayout();
    return (
        <PopoverContent
            hideArrow={false}
            dialog
            data-testid="premium-finish-popover-dialog"
            p="5"
            placement={isSmall ? 'top' : 'bottom'}
            className={popoverDialogStyle}
        >
            <FlexBox flexDirection="column" mb="6">
                <Typography className="title" fontSize="standard" fontWeight="bold" mb="2">
                    {text('premiumFinish')}
                </Typography>
                <Typography className="subTitle" fontSize="small">
                    {textSelection}
                </Typography>
            </FlexBox>
            <ChangeFinishButtons
                onFinishIconClick={onFinishIconClick}
                onPopoverDeleteButtonClick={onPopoverDeleteButtonClick}
            />
        </PopoverContent>
    );
};
