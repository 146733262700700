import React, { useState } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import {
    Button,
    FlexBox,
    Icon,
    Li,
    ModalDialog,
    ModalDialogBody,
    ModalDialogButtons,
    ModalDialogFooter,
    Typography,
    Ul,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { SentPdfProofModal } from './SentPdfProofModal';
import { StandardModal } from '../../components/Modal/components/StandardModal';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { text } from '../../utils/localization';
import { ACTION_NAME, QUAD_TRACKING_EVENTS } from '../TrackingEvents/constant';
import { GA_EVENTS } from '../TrackingEvents/GAEvents';

interface DesignPreviewModalProps extends React.ComponentProps<typeof ModalDialog> {
    generatePdf: () => Promise<undefined>;
    isGenerating: boolean;
}

export const PdfProofDownloadModal = observer((props: DesignPreviewModalProps): JSX.Element => {
    const { onRequestDismiss, generatePdf, isGenerating, isOpen, ...rest } = props;
    const [showDownloadProofModal, setShowDownloadProofModal] = useState(false);
    const { isSmall } = useScreenLayout();
    const { fireEvent } = useCustomEventPropagation();

    const onClose = () => {
        setShowDownloadProofModal(false);
        onRequestDismiss();
    };

    const openProofModal = () => {
        setShowDownloadProofModal(true);
        generatePdf();
        fireEvent({
            name: ACTION_NAME.DESIGN_TOOL_USED,
            extraData: {
                experimentLabel: QUAD_TRACKING_EVENTS.CLICK_DOWNLOAD_PDF_PROOF,
                eventDetail: GA_EVENTS.CLICK_PREVIEW_PANEL_DOWNLOAD.replace('[FILE TYPE]', 'pdf'),
            },
        });
        onRequestDismiss();
    };

    return (
        <>
            <StandardModal
                onClose={onRequestDismiss}
                isOpen={isOpen}
                fullBleed={true}
                positionAbsoluteNavigation={false}
                {...rest}
            >
                <ModalDialogBody>
                    <FlexBox pt={{ xs: '6', sm: '10' }} px={{ xs: '5', sm: '6' }} flexDirection="column">
                        <Typography fontSize="x2large" fontWeight="bold" mb="5">
                            {text('getPDF')}
                        </Typography>
                        <Typography mb="6">{text('reviewPDF')}</Typography>
                        <FlexBox flexDirection="row" flex-direction="start" flexWrap="wrap" mb="6">
                            {['spelling', 'textSize', 'alignment'].map((point) => (
                                <Ul key={point} mx="5">
                                    <Li>{text(`${point}Check`)}</Li>
                                </Ul>
                            ))}
                        </FlexBox>
                        <FlexBox gap="4" flexDirection="row" alignItems="flex-start" mb="6">
                            <Icon iconType="info" size="24p" mt="2" />
                            <Typography>{text('variationInfo')}</Typography>
                        </FlexBox>
                    </FlexBox>
                </ModalDialogBody>
                <ModalDialogFooter pinned m={{ xs: '5', sm: '6' }} mt="0" pt={{ xs: '5', sm: '6' }} p="0">
                    <ModalDialogButtons>
                        <Button
                            skin="primary"
                            width={isSmall ? 'full-width' : 'standard'}
                            onClick={openProofModal}
                            disabled={isGenerating}
                            data-testid="get-pdf-btn"
                        >
                            {text('getPdfBtn')}
                        </Button>
                    </ModalDialogButtons>
                </ModalDialogFooter>
            </StandardModal>
            <SentPdfProofModal
                isOpen={showDownloadProofModal}
                onRequestDismiss={onClose}
                data-testid="pdf-prepare-modal"
            />
        </>
    );
});
