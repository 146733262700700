import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const DropIcon = (props: SvgProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none" {...props}>
            <rect x="8" y="8" width="32" height="32" rx="16" stroke="white" strokeWidth="1.5" />
            <path
                d="M18.001 26V29.0301C17.9818 29.3534 18.2265 30 19.3587 30C20.4908 30 26.2453 30 28.981 30C29.3207 29.9924 30 29.7878 30 29.0301C30 28.2724 30 26.6943 30 26"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path d="M24 18L24.0012 26" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path
                d="M21 21L24 18L27 21"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
