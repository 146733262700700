/**
 * To be removed once PremiumFinishStore is refactored with usePricingData
 */
import { PricingType } from '../../../../config/constant';
import { formatPrice, isVatInclusive } from '../../../../utils/pricingUtilities';
import { ChoiceGroupsPricing, FlexibilityPrice, FlexibilityPriceResponse, PricingApiResponse } from '../types';

const flexibilityPrices = (
    includedPrice: string,
    differentialDiscountPrice: number,
    currency: string,
    locale: string
): FlexibilityPrice => {
    if (differentialDiscountPrice === 0) {
        return {
            includedPrice,
            increment: 'Included',
        };
    } else if (differentialDiscountPrice < 0) {
        return {
            includedPrice,
            increment: `-${formatPrice(-1 * differentialDiscountPrice, locale, currency)}`,
        };
    } else {
        return {
            includedPrice,
            increment: `+${formatPrice(differentialDiscountPrice, locale, currency)}`,
        };
    }
};

export const formatPricingData = (
    differentialPricingData: PricingApiResponse,
    locale: string
): FlexibilityPriceResponse => {
    const { choiceGroups, currency } = differentialPricingData;
    const { included, ...optionChoiceGroups } = choiceGroups;
    const priceToUse = isVatInclusive() ? PricingType.Taxed : PricingType.Untaxed;
    const includedPrice = included.totalDiscountPrice?.[priceToUse];
    const formattedIncludedPrice = includedPrice ? formatPrice(includedPrice, locale, currency) : '';
    const choiceGroupsPrices: ChoiceGroupsPricing = {};
    const { baseSelectionsListPrice, baseSelectionsDiscountedPrice } = differentialPricingData;
    const baseDiscountedPrice = baseSelectionsDiscountedPrice?.[priceToUse];
    const baseListPrice = baseSelectionsListPrice?.[priceToUse];
    Object.keys(optionChoiceGroups).forEach((choiceGroup) => {
        const flexibilityPriceResponse = flexibilityPrices(
            formattedIncludedPrice,
            optionChoiceGroups[choiceGroup].differentialDiscountPrice[priceToUse],
            currency,
            locale
        );
        const discountPrice = optionChoiceGroups[choiceGroup].totalDiscountPrice;
        const productDiscountPrice = discountPrice ? formatPrice(discountPrice[priceToUse], locale, currency) : '';
        choiceGroupsPrices[choiceGroup] = { ...flexibilityPriceResponse, productDiscountPrice };
    });
    return { choiceGroupsPricing: choiceGroupsPrices, basePrice: { baseDiscountedPrice, baseListPrice, currency } };
};
