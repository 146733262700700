import { fetchWrapper } from '../utils/fetchWrapper';

export const getPlaceHolderUrl = async ({
    culture,
    height,
    width,
}: {
    culture: string;
    width: string;
    height: string;
}) => {
    const url = `https://design-service-localization.design.vpsvc.com/api/v0/placeholder/fullbleedurl?culture=${culture}&height=${height}&width=${width}`;
    const placeholderUrl = await fetchWrapper(url, {
        method: 'GET',
        requestInitiator: 'getPlaceHolderUrl',
    });

    return placeholderUrl;
};
