import { CimDoc } from '@design-stack-ct/cdif-types';
import { Measurement } from '@design-stack-ct/utility-core';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { Tenants } from '../../../config/constant';
import { getInstructionsUrl } from '../../Flexibility/common';

const MAX_DIMENSION = 1000;
const CIMPRESS_SCENE_URL = `https://scenes.documents.cimpress.io`;
const CIMPRESS_RENDERING_URL = `https://rendering.documents.cimpress.io:443`;

interface GetPreviewUrlInput {
    panel: PanelState;
    cimDoc: CimDoc;
    width?: number;
    page?: number;
}

export const getPreviewUrl = ({ panel, cimDoc, width = MAX_DIMENSION, page = 1 }: GetPreviewUrlInput) => {
    const panelHeight = new Measurement(panel.panelProperties.height).mm;
    const panelWidth = new Measurement(panel.panelProperties.width).mm;
    const panelAspectRatio = panelWidth / panelHeight;

    const imgWidth = panelAspectRatio >= 1 ? width : Math.round(width * panelAspectRatio);
    const imgHeight = panelAspectRatio >= 1 ? Math.round(width / panelAspectRatio) : width;

    const instructionUrl = getInstructionsUrl(cimDoc);
    const sceneUrl = `${CIMPRESS_SCENE_URL}/v2/transient?width=${imgWidth}&height=${imgHeight}&page=${page}`;

    const previewUrl = `${CIMPRESS_RENDERING_URL}/v1/${
        Tenants.VistaprintProd
    }/preview?width=${imgWidth}&height=${imgHeight}&instructions_uri=${encodeURIComponent(
        instructionUrl
    )}${encodeURIComponent('&ignoreProjection=true')}&scene=${encodeURIComponent(sceneUrl)}`;

    return previewUrl;
};
