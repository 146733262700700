import React from 'react';

import { createDocument } from '@design-stack-ct/documents-sdk';
import { getPdfProof } from '@design-stack-ct/prepress-client';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useIdentityContext } from '@design-stack-vista/identity-provider';

import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import { PdfProofDownloadModal } from './PdfProofDownloadModal';

interface PdfProofDownloadModalWrapperProps {
    showModalDialog: boolean;
    setShowModalClose: () => void;
    isGenerating: boolean;
    setIsGenerating: (value: boolean) => void;
}

export const PdfProofDownloadModalWrapper = observer(
    ({ showModalDialog, setShowModalClose, isGenerating, setIsGenerating }: PdfProofDownloadModalWrapperProps) => {
        const designEngine = useDesignEngine();
        const { auth: vpAuth } = useIdentityContext();
        const authorizationHeader = vpAuth.getAuthorizationHeader();

        async function generatePdf() {
            if (!designEngine) {
                return undefined;
            }
            setIsGenerating(true);
            const { asJson } = designEngine.cimDocStore;
            const newCimDoc = { ...asJson, tenant: undefined };

            const data = await createDocument({
                cimDoc: newCimDoc,
                headers: {
                    Authorization: authorizationHeader,
                },
                deleteAfterDays: 1,
            });
            const {
                _links: {
                    drawingInstructions: { href: prepressInstructionsUrl },
                },
            } = data;
            const { PdfUrl } = await getPdfProof({
                prepressInstructionsUrl,
                headers: { Authorization: authorizationHeader },
            });

            saveAs(PdfUrl, 'pdf-proof');
            setIsGenerating(false);
        }

        return (
            <>
                <PdfProofDownloadModal
                    isOpen={showModalDialog}
                    onRequestDismiss={setShowModalClose}
                    generatePdf={generatePdf}
                    isGenerating={isGenerating}
                    data-testid="pdf-download-modal"
                />
            </>
        );
    }
);
