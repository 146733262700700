import qs from 'qs';
import { REQUESTOR } from '../config/constant';
import { fetchWrapper } from '../utils';

const TEAMS_PRODUCT_VERIFIER_URL = 'https://teams-product-verifier.apparelhub.vpsvc.com';

export async function verifyTeamsProduct(
    productId: string,
    productVersion: number | undefined | null,
    culture: string,
    selectedOptions: Record<string, string>
): Promise<boolean> {
    const query = qs.stringify({
        requestor: REQUESTOR,
        productVersion,
        selectedOptions: JSON.stringify(selectedOptions),
    });
    const url = `${TEAMS_PRODUCT_VERIFIER_URL}/v0/verifyTeamsProduct/vistaprint/${culture}/${productId}?${query}`;
    const response = await fetchWrapper(url, {
        method: 'GET',
        requestInitiator: 'verifyTeamsProduct',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
    return response.isTeamsProduct;
}
