import React, { useCallback, useEffect, useMemo } from 'react';
import { FlexBox, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { BestFitTag } from './BestFitTag';
import { SizeItem } from './SizeItem';
import { MobileExperience } from '../../../components/Experiences';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { useProductOptionsTracking } from '../../../providers';
import { text } from '../../../utils/localization';
import { FlexibilityOptions } from '../../Flexibility/common';
import { useAutoRecognitionTracking } from '../hooks';
import { changeSizeStore } from '../store';
import { getAutoRecognitionSetData } from '../utils';

export const AutoRecognitionSet = observer(() => {
    const { isSmall } = useScreenLayout();
    const { productData } = useProductConfiguration();
    const productOptionsTracking = useProductOptionsTracking();
    const { trackSizeSelection } = useAutoRecognitionTracking();
    const { sizeAttribute, activeSize, setActiveSize, activeOrientation, autoRecognitionData } = changeSizeStore;

    const { selected, isSelectedBestFit, bestFits, others } = useMemo(
        () => getAutoRecognitionSetData(productData, autoRecognitionData, sizeAttribute),
        [productData?.selectedOptions, autoRecognitionData, sizeAttribute]
    );

    const onClick = useCallback(
        (size: string) => {
            setActiveSize(size);
            trackSizeSelection({
                selectedSize: size,
                selectedOrientation: activeOrientation,
                userSelected: true,
            });

            productOptionsTracking.trackAttributeSelected(sizeAttribute, size);
        },
        [activeOrientation, sizeAttribute]
    );

    useEffect(() => {
        if (autoRecognitionData && !selected) {
            setActiveSize(autoRecognitionData.preSelected);
            trackSizeSelection({
                selectedSize: autoRecognitionData.preSelected,
                selectedOrientation: activeOrientation,
                userSelected: false,
            });
        }
    }, [autoRecognitionData, selected, activeOrientation]);

    return (
        <div>
            {selected && (
                <>
                    <FlexBox justifyContent="space-between">
                        <Typography mb="3" fontSize="xsmall" textColor="subtle">
                            {text('yourSelection')}
                        </Typography>
                        {isSelectedBestFit && (
                            <MobileExperience>
                                <BestFitTag />
                            </MobileExperience>
                        )}
                    </FlexBox>
                    <FlexibilityOptions>
                        <SizeItem
                            size={selected}
                            activeSize={activeSize}
                            onClick={onClick}
                            isBestFit={isSelectedBestFit}
                        />
                    </FlexibilityOptions>
                </>
            )}
            {bestFits && bestFits.length > 0 && (
                <FlexBox justifyContent="space-between" mt={isSmall ? '5' : '6'} mb="3">
                    <Typography mb="3" fontSize="xsmall" textColor="subtle">
                        {text('bestMatch')}
                    </Typography>
                    <MobileExperience>
                        <BestFitTag />
                    </MobileExperience>
                </FlexBox>
            )}
            <FlexibilityOptions>
                {bestFits.map((size) => (
                    <SizeItem key={size} size={size} activeSize={activeSize} onClick={onClick} isBestFit={true} />
                ))}
            </FlexibilityOptions>
            {others && others.length > 0 && (
                <Typography mt={isSmall ? '5' : '6'} mb="3" fontSize="xsmall" textColor="subtle">
                    {text('otherSizes')}
                </Typography>
            )}
            <FlexibilityOptions>
                {others.map((size) => (
                    <SizeItem key={size} size={size} activeSize={activeSize} onClick={onClick} />
                ))}
            </FlexibilityOptions>
        </div>
    );
});
