import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { InteractiveDesignEngine } from '@design-stack-vista/interactive-design-engine-core';
import { setColorToPanel } from '../commands/setColorToPanel';

export const applyColorToPanel = ({
    designEngine,
    color,
    panel,
}: {
    color: string;
    panel: PanelState;
    designEngine: InteractiveDesignEngine;
}) => {
    designEngine.executeCommand((cimDoc) => setColorToPanel({ cimDoc, panel, color }), {});
};
