import React from 'react';
import { CommonModal } from './CommonModal';
import { ModalVariants } from '../../../config/constant';
import { useScreenLayout } from '../../../hooks/useScreenLayout';

type PanelRightModalProps = Omit<React.ComponentProps<typeof CommonModal>, 'variant'> & {
    children?: React.ReactNode;
};

export const PanelRightModal = ({ children, ...rest }: PanelRightModalProps) => {
    const { isSmall } = useScreenLayout();

    return (
        <CommonModal variant={isSmall ? ModalVariants.PanelBottom : ModalVariants.PanelRight} {...rest}>
            {children}
        </CommonModal>
    );
};
