import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const PipeIcon = (props: SvgProps) => {
    return (
        <svg width="1" height="24" viewBox="0 0 1 24" fill="none" {...props}>
            <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="24" stroke="black" />
        </svg>
    );
};
