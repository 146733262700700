import config from '../config/quadProductConfig.json';

interface ConfigType {
    [productKey: string]: Record<string, boolean | string | number>;
}

export const useQuadProductConfig = () => {
    const customProductConfig = config as ConfigType;
    return { customProductConfig };
};
