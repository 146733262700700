import { useCallback, useEffect, useState } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { ImageInstantUploadExtension, isEngineProcessing } from '../../../features/InstantUpload';
import { useDesignReviewContext } from '../../../providers';
import { getAllItemsInCimdocPanels } from '../../../utils';
import { text } from '../../../utils/localization';
import { NotificationSections, useNotificationFramework } from '../../Notification';

// We must wait for 5sec before displaying the error message for slow instant upload when the design review page is opened
const NOTIFY_SLOW_UPLOAD_TIMEOUT = 5000;

export const useIsSlowInstantUpload = () => {
    const designEngine = useDesignEngine();
    const { notifyCustomer, removeAllNotifications } = useNotificationFramework();
    const { isOpenDesignReviewModal } = useDesignReviewContext();

    const [previewMessageDisplayed, setPreviewMessageDisplayed] = useState(false);
    const [showSlowImageUploadMessage, setShowSlowImageUploadMessage] = useState(false);

    const {
        cimDocStore: { panels },
        designExtensionSystem,
    } = designEngine;

    const panelItems = getAllItemsInCimdocPanels(panels);

    const isSlowInstantUpload = panelItems.some(
        (item) => designExtensionSystem.getExtension(item.iid, ImageInstantUploadExtension)?.isSlowAssetUpload
    );

    const sherbertUploadCompleted = panelItems.every(
        (item) => designExtensionSystem.getExtension(item.iid, ImageInstantUploadExtension)?.isUploading
    );

    const dismissSlowUploadMessage = () => {
        setShowSlowImageUploadMessage(false);
    };

    useEffect(() => {
        let slowUploadTimeout: NodeJS.Timeout | null = null;
        if (isSlowInstantUpload && isOpenDesignReviewModal && !showSlowImageUploadMessage && !previewMessageDisplayed) {
            slowUploadTimeout = setTimeout(() => {
                notifyCustomer({
                    notificationSections: NotificationSections.DESIGN_REVIEW,
                    notificationType: 'error',
                    messageToShowCustomer: text('slowInstantUploadDesignReview'),
                });
                setPreviewMessageDisplayed(true);
            }, NOTIFY_SLOW_UPLOAD_TIMEOUT);
        }

        return () => {
            if (slowUploadTimeout) {
                clearTimeout(slowUploadTimeout);
            }
        };
    }, [isSlowInstantUpload, isOpenDesignReviewModal, showSlowImageUploadMessage]);

    useEffect(() => {
        if (!sherbertUploadCompleted) {
            dismissSlowUploadMessage();
        }
    }, [sherbertUploadCompleted]);

    const checkForSlowUpload = useCallback(() => {
        if (isEngineProcessing(designEngine)) {
            removeAllNotifications();
            setShowSlowImageUploadMessage(true);
            return true;
        }

        return false;
    }, [designEngine]);

    return {
        checkForSlowUpload,
        showSlowImageUploadMessage,
        dismissSlowUploadMessage,
    };
};
