import React from 'react';
import { css } from '@emotion/css';
import { AlertBox, AlertBoxDismissButton, className, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { NotificationSections, useNotificationFramework } from '.';
import { NotificationHistoryEvent } from './NotificationProvider';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { text } from '../../utils/localization';

const notificationStyle = css`
    position: absolute;
    z-index: 1000;
    .swan-alert-box.swan-alert-box-toast {
        max-width: 100%;
    }
`;

interface NotificationToastsProps {
    propStyle?: string;
    section?: NotificationSections;
}

interface NotificationToastProps {
    notification: NotificationHistoryEvent;
}

const NotificationToast = ({ notification }: NotificationToastProps) => {
    const { isSmall } = useScreenLayout();
    const { removeNotification } = useNotificationFramework();
    const { notificationId, notificationType, messageToShowCustomer, notificationTitle } = notification;

    const onRequestDismiss = () => {
        removeNotification(notificationId);
    };

    return (
        <AlertBox skin={notificationType} onRequestDismiss={onRequestDismiss} toast mb="2">
            {notificationTitle && <Typography fontSize={isSmall ? 'xsmall' : 'small'}>{notificationTitle}</Typography>}
            <Typography fontSize={isSmall ? 'xsmall' : 'small'}>{messageToShowCustomer}</Typography>
            <AlertBoxDismissButton
                data-testid={`x-button-${notificationId}`}
                visuallyHiddenLabel={text('dismissAccessibilityLabel')}
            />
        </AlertBox>
    );
};

export const NotificationToasts = observer(
    ({ propStyle, section = NotificationSections.CANVAS }: NotificationToastsProps) => {
        const { notificationHistory } = useNotificationFramework();

        const notifications = notificationHistory.filter(
            (notification) => notification.notificationSections === section
        );

        if (!notifications.length) {
            return null;
        }

        return (
            <div className={className(notificationStyle, propStyle)}>
                {notifications.map((notification) => (
                    <NotificationToast key={notification.notificationId} notification={notification} />
                ))}
            </div>
        );
    }
);
