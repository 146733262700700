/**
 * Reference for getStockStatus -
 * {@link https://gitlab.com/vistaprint-org/large-assortment/electron/merchandising-inventory-service/-/blob/main/src/MerchandisingInventory.Library/Infrastructure/MerchantAvailabilityService/MerchantAvailabilityClient.cs?ref_type=heads#L294}
 *
 * Reference for getCombinationInventoryCount -
 * {@link https://gitlab.com/vistaprint-org/large-assortment/electron/merchandising-inventory-service/-/blob/main/src/MerchandisingInventory.Library/Infrastructure/MerchantAvailabilityService/MerchantAvailabilityClient.cs?ref_type=heads#L289}
 *
 * Reference for hasLowStock -
 * {@link https://gitlab.com/vistaprint-org/allteamscodebase/apparel-hub/inventory-utilities/-/blob/main/src/index.ts}
 */

import { LOW_STOCK_MIN_THRESHOLD, LOW_STOCK_MOQ_MULTIPLIER } from '../constants';
import { FilteredStockInfo, MerchandisingAvailability, StatusStates } from '../types';

export const getStockStatus = (statusStates: StatusStates) => {
    const deliverable = statusStates.IsDeliverable;
    const merchandisable = statusStates.IsMerchandisable;

    const combinationStock = deliverable && merchandisable;
    return combinationStock;
};

export const getCombinationInventoryCount = (inStockStatus: boolean, inventoryCount: number | undefined) => {
    return inStockStatus ? inventoryCount ?? Number.MAX_VALUE : 0;
};

export const hasLowStock = (inventory: number, minimumOrderQuantity: number): boolean => {
    if (inventory === 0) {
        return true;
    }

    const lowStockThreshold = Math.max(LOW_STOCK_MIN_THRESHOLD, minimumOrderQuantity * LOW_STOCK_MOQ_MULTIPLIER);

    return inventory <= lowStockThreshold;
};

export const filterStockInfoBySize = (
    stockInfo: MerchandisingAvailability,
    minimumOrderQuantity: number
): FilteredStockInfo => {
    const { facetedAvailabilityStates } = stockInfo;

    const sizesWithStock: string[] = [];
    const sizesWithLowStock: string[] = [];
    const sizesWithNoStock: string[] = [];
    const lowQuantityInfo: Record<string, number> = {};

    facetedAvailabilityStates.forEach((facetedAvailabilityState) => {
        const {
            attributeSelections,
            availabilityState: { statusStates, inventoryCount },
        } = facetedAvailabilityState;

        const size = attributeSelections[0].value;

        const inStockStatus = getStockStatus(statusStates);
        const inventory = getCombinationInventoryCount(inStockStatus, inventoryCount);

        if (inventory === 0) {
            sizesWithNoStock.push(size);
        } else if (hasLowStock(inventory, minimumOrderQuantity)) {
            sizesWithLowStock.push(size);
            lowQuantityInfo[size] = inventory;
        } else {
            sizesWithStock.push(size);
        }
    });

    return {
        sizesWithStock,
        sizesWithNoStock,
        sizesWithLowStock,
        lowQuantityInfo,
    };
};
