import React from 'react';
import { Hex } from '@design-stack-ct/utility-core';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { css } from '@emotion/css';
import { Button, ColorSwatch, Icon, Popover, PopoverContent, PopoverLauncher } from '@vp/swan';
import { DesktopExperience, MobileExperience } from '../../../components/Experiences';
import { ColorPalette } from '../../quad/components/ColorPalette';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { useAdjustContrast } from '../hooks/useAdjustContrast';

const popoverBodyStyles = css`
    &.swan-popover {
        width: 266px;
        border-radius: 8px;
        padding: 20px;
        & .swan-selection-set {
            & .swan-color-swatches {
                & input {
                    position: absolute;
                }
            }
        }
    }
`;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const AdjustContrastColorPicker = (): JSX.Element => {
    const { selectedColor, setSelectedColor, availablePaletteColors } = useAdjustContrast();
    const { fireEvent } = useCustomEventPropagation();
    const onColorChange = (newSelectedColor: Hex) => {
        const selectedPaletteColor = availablePaletteColors.find((color) => color.cssBackground === newSelectedColor);
        if (selectedPaletteColor) {
            setSelectedColor(selectedPaletteColor);

            fireEvent({
                name: ACTION_NAME.EXPERIMENT_CLICKED,
                extraData: {
                    label: QUAD_TRACKING_EVENTS.ADJUST_CONTRAST_PICK_COLOR,
                    eventDetail: 'QUAD;QUAD;Adjust contrast pick color',
                    navigationDetail: 'AdjustContrast:Pick color',
                    pageSection: PageSection.AdjustContrast,
                    pageStage: PageStage.Design,
                    category: undefined,
                },
            });
        }
    };

    return (
        <>
            <DesktopExperience>
                <Popover position="top">
                    <PopoverLauncher>
                        <Button size="mini" onClick={noop}>
                            <ColorSwatch primaryColor={selectedColor.cssBackground} />
                            <Icon iconType="caretDown" mx="0" />
                        </Button>
                    </PopoverLauncher>
                    <PopoverContent hideArrow={true} className={popoverBodyStyles}>
                        <ColorPalette
                            paletteColors={availablePaletteColors}
                            selectedColor={selectedColor}
                            onColorChange={onColorChange}
                        />
                    </PopoverContent>
                </Popover>
            </DesktopExperience>
            <MobileExperience>
                <ColorPalette
                    paletteColors={availablePaletteColors}
                    selectedColor={selectedColor}
                    onColorChange={onColorChange}
                />
            </MobileExperience>
        </>
    );
};
