import { useEffect } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useAsyncEffect } from '@design-stack-vista/utility-react';
import { toJS } from 'mobx';
import { useBackDesignTemplate } from './useBackDesignTemplate';
import { PanelSides } from '../../../config/constant';
import { useUploadPanels } from '../../../hooks/useUploadPanel';
import { getPanelByName } from '../../UploadPanel/components/UploadBackDesignModal/helper';
import { backDesignTemplateStore } from '../store/BackDesignTemplateStore';

export const useInitializeBackDesignTemplateStore = () => {
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const { designTemplates, selectedDesignTemplate, setSelectedDesignTemplate, setShowCanvasTemplateLoader } =
        backDesignTemplateStore;
    const { setTemplatePreviewsAndColors } = useBackDesignTemplate();
    const { onAddDesignTemplateToPanel } = useUploadPanels();

    const backPanel = getPanelByName(panels, PanelSides.Back);
    const backPanelId = backPanel?.id;
    const frontPanel = getPanelByName(panels, PanelSides.Front);

    useEffect(() => {
        if (frontPanel && frontPanel.items.length > 0) {
            if (selectedDesignTemplate) {
                setShowCanvasTemplateLoader(true);
            }
            setTemplatePreviewsAndColors();
        }
    }, [frontPanel]);

    useAsyncEffect(() => {
        (async () => {
            if (selectedDesignTemplate && backPanelId) {
                const selectedLayoutId = selectedDesignTemplate.layoutId;
                const template = designTemplates.find((design) => design.layoutId === selectedLayoutId);
                if (template) {
                    setSelectedDesignTemplate(template);
                    await onAddDesignTemplateToPanel(backPanelId, toJS(template.designCimDoc));
                }
                if (selectedDesignTemplate) {
                    setShowCanvasTemplateLoader(false);
                }
            }
        })();
    }, [designTemplates, selectedDesignTemplate, backPanelId]);
};
