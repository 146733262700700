import { useCallback } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { useNotifyShapeChange } from './useNotifyShapeChange';
import { useShapePrice } from './useShapePrice';
import { ProductAttributes } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useFullScreenLoader, useProductParams } from '../../../providers';
import { noticeError } from '../../../utils';
import { text } from '../../../utils/localization';
import { getResizedDocument, transformDocument, useLoadProduct } from '../../Flexibility/common';
import { useWaitForInstantUpload } from '../../InstantUpload';
import { ShapeKey } from '../constants';
import { shapeStore } from '../ShapeStore';

export const useChangeShape = () => {
    const designEngine = useDesignEngine();
    const { loadProduct } = useLoadProduct();
    const { auth: vpAuth } = useIdentityContext();
    const { notifyShapeChange } = useNotifyShapeChange();
    const { updateShapeOptionsAndPrices } = useShapePrice();
    const { waitForInstantUpload } = useWaitForInstantUpload();
    const { setFullScreenLoaderData } = useFullScreenLoader();
    const authorizationHeader = vpAuth.getAuthorizationHeader();
    const { locale, selectedQuantity: quantity } = useProductParams();
    const { productData, workId, secondaryConfig } = useProductConfiguration();
    const { userSelectedSize, shapeOptions, shapeOptionsPrices, closeShapeModal } = shapeStore;

    const loadProductWithNewShape = useCallback(
        async (newShape: string) => {
            try {
                if (!productData || !authorizationHeader) {
                    throw new Error('Could not get data to load new shape');
                }

                setFullScreenLoaderData({
                    showLoader: true,
                    loaderMessage: text('updatingShape'),
                    testId: 'shape-loader',
                });

                closeShapeModal();
                await waitForInstantUpload();

                const newProductOptions = shapeOptions[newShape];
                const newOptionPrices = shapeOptionsPrices[newShape];
                const { differentialDiscountPrice, totalDiscountPrice } = newOptionPrices;

                const { productKey, productVersion, selectedOptions, customerSelectedOptions, productName } =
                    productData;
                const newSelectedOptions = {
                    ...selectedOptions,
                    ...customerSelectedOptions,
                    ...newProductOptions,
                };
                const designDocument = await designEngine.getCimDoc();

                // For shape with square dimension, product lookup fails if orientation is passed as "Vertical"
                // So, consider orientation as "Horizontal" when Circle or Rounded Square is selected
                if (
                    newProductOptions[ProductAttributes.Shape] === ShapeKey.Circle ||
                    newProductOptions[ProductAttributes.Shape] === ShapeKey.RoundedSquare
                ) {
                    newSelectedOptions[ProductAttributes.Orientation] = 'Horizontal';
                }

                const targetDocument = await getResizedDocument({
                    designDocument,
                    selectedOptions: newSelectedOptions,
                    authorizationHeader,
                    productKey,
                    productVersion,
                    locale,
                });
                if (!targetDocument) {
                    throw new Error('Could not generate the cimdoc for new shape');
                }

                transformDocument(targetDocument);

                await loadProduct({
                    newProduct: {
                        productKey,
                        productVersion,
                        productAttributes: newSelectedOptions,
                    },
                    newCimDoc: targetDocument,
                });

                await updateShapeOptionsAndPrices({
                    productData,
                    secondaryConfig,
                    userSelectedSize,
                });

                setFullScreenLoaderData({ showLoader: false });
                notifyShapeChange(productName, differentialDiscountPrice, totalDiscountPrice, newSelectedOptions);
            } catch (error) {
                setFullScreenLoaderData({ showLoader: false });
                noticeError(error, {
                    method: 'loadProductWithNewShape - useChangeShape ',
                });
            }
        },
        [
            productData,
            authorizationHeader,
            workId,
            locale,
            quantity,
            designEngine,
            shapeOptions,
            shapeOptionsPrices,
            loadProduct,
            notifyShapeChange,
        ]
    );

    return {
        loadProductWithNewShape,
    };
};
