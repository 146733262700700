import React, { useCallback } from 'react';
import { isPlaceholderTemplateItem } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { Button, FlexBox, Link, StyleFontSize, tokens, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { PanelSides } from '../../../config/constant';
import { useRedirectURL } from '../../../hooks/useRedirectURL';
import { PageSection } from '../../TrackingEvents/constant';
import { getPanelByName } from '../../UploadPanel/components/UploadBackDesignModal/helper';
import { useTemplateData } from '../hooks';
import { backDesignTemplateStore } from '../store/BackDesignTemplateStore';

export enum PlaceholderInfoType {
    Normal = 'normal',
    Warning = 'warning',
}

const placeholderContainer = css`
    border-radius: ${tokens.SwanSemBorderRadiusContainer};
`;

interface TemplatePlaceholderListProps {
    showPanelName?: boolean;
    placeholderInfoType?: PlaceholderInfoType;
    fontSize?: StyleFontSize;
    isLink?: boolean;
    onClickPlaceholder?: () => void;
}

export const TemplatePlaceholderList = observer(
    ({
        showPanelName,
        placeholderInfoType = PlaceholderInfoType.Normal,
        fontSize = 'small',
        isLink,
        onClickPlaceholder,
    }: TemplatePlaceholderListProps) => {
        const {
            cimDocStore: { panels },
        } = useDesignEngine();
        const { closeMakeEditModal } = backDesignTemplateStore;
        const backPanel = getPanelByName(panels, PanelSides.Back);
        const { redirectToStudio } = useRedirectURL();
        const { templateMetaData, redirectText, panelNumber } = useTemplateData();

        const placeholderClickHandler = useCallback(() => {
            if (onClickPlaceholder) {
                onClickPlaceholder();
            } else {
                closeMakeEditModal();
                redirectToStudio({ pageSection: PageSection.QuickUploadAndDesign, ctaText: redirectText, panelNumber });
            }
        }, [panelNumber, redirectText, redirectToStudio]);

        if (!backPanel || !templateMetaData.length) {
            return null;
        }

        return (
            <FlexBox
                flexWrap="wrap"
                gap="3"
                p="6"
                backgroundColor={placeholderInfoType === PlaceholderInfoType.Warning ? 'error' : 'strong'}
                color={placeholderInfoType === PlaceholderInfoType.Warning ? 'error' : 'strong'}
                className={placeholderContainer}
            >
                {showPanelName && (
                    <Typography fontWeight="bold" fontSize={fontSize}>
                        {backPanel.panelProperties.name}:
                    </Typography>
                )}
                {templateMetaData.map((item, idx) =>
                    isLink ? (
                        <Link
                            key={`placeholderLink-${idx}`}
                            render={(p) => (
                                <Button skin="link" className={p.className} mt={0} onClick={placeholderClickHandler}>
                                    {p.children}
                                </Button>
                            )}
                            fontSize={fontSize}
                        >
                            {isPlaceholderTemplateItem(item) ? item.intent : item.label}
                            {idx === templateMetaData.length - 1 ? '' : ','}
                        </Link>
                    ) : (
                        <Typography key={`placeholder-${idx}`} fontSize={fontSize}>
                            {isPlaceholderTemplateItem(item) ? item.intent : item.label}
                            {idx === templateMetaData.length - 1 ? '' : ','}
                        </Typography>
                    )
                )}
            </FlexBox>
        );
    }
);
