import { buildTransientDocumentUri, createDocument } from '@design-stack-ct/documents-sdk';
import { calculateCoverPosition, Measurement } from '@design-stack-ct/utility-core';
import { CimDoc, ImageItem, Panel } from '@design-stack-vista/cdif-types';
import { resizeItem, ResizeItemArgs } from '@design-stack-vista/cimdoc-state-manager';
import { TRANSIENT_INSTRUCTIONS_SIZE_LIMIT } from './constants';
import { generateDesignViewsURL } from '../../../../api/designViews';
import { getResizedDocumentByDesignViews } from '../../../../api/transformationService';
import { getItemPositionFitOnRotation } from '../../../../utils/getItemPositionFitOnRotation';
import { ResizeDocumentProps } from '../types';

const UDS_STORAGE_V3_URL = 'https://storage.documents.cimpress.io/v3';

async function getTransientDocumentUrl(
    cimDoc: CimDoc,
    authorizationHeader: string,
    signal?: AbortSignal
): Promise<string> {
    const document = buildTransientDocumentUri(cimDoc);
    if (document.length < TRANSIENT_INSTRUCTIONS_SIZE_LIMIT) {
        return `${UDS_STORAGE_V3_URL}/documents/transient?document=${encodeURIComponent(document)}`;
    }
    const saveTransientDocument = await createDocument({
        cimDoc,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: authorizationHeader,
            'If-Match': '*',
        },
        deleteAfterDays: 1,
        abortSignal: signal,
    });
    return saveTransientDocument._links.documentRevision.href;
}

const getItemResizeArgs = (imageItem: ImageItem, panel: Panel): ResizeItemArgs => {
    const { rotationAngle } = imageItem;
    const rotation = Number(rotationAngle ?? 0);

    const panelDimension = {
        width: new Measurement(panel.width).mm,
        height: new Measurement(panel.height).mm,
    };

    const imageDimensions = {
        width: new Measurement(imageItem.position.width).mm,
        height: new Measurement(imageItem.position.height).mm,
    };

    const coverPosition =
        rotation === 90 || rotation === 270
            ? calculateCoverPosition(
                  {
                      width: imageDimensions.height,
                      height: imageDimensions.width,
                  },
                  panelDimension
              )
            : calculateCoverPosition(
                  {
                      width: imageDimensions.width,
                      height: imageDimensions.height,
                  },
                  panelDimension
              );

    const targetBoundingBox = {
        x: coverPosition.x,
        y: coverPosition.y,
        width: coverPosition.width,
        height: coverPosition.height,
    };

    const aspectRatio = imageDimensions.width / imageDimensions.height;

    const fitToBleedDimensions = getItemPositionFitOnRotation(targetBoundingBox, aspectRatio, rotation);

    const roundedDimensions = {
        id: imageItem.id,
        newHeight: fitToBleedDimensions.height,
        newWidth: fitToBleedDimensions.width,
        newX: fitToBleedDimensions.x,
        newY: fitToBleedDimensions.y,
    };

    return roundedDimensions;
};

const hasMultipleItems = (panel: Panel) => {
    const panelImages = panel.images ?? [];
    const panelShapes = panel.shapes ?? [];
    const panelTextAreas = panel.textAreas ?? [];
    const panelItemReferences = panel.itemReferences ?? [];

    const panelItems = [...panelImages, ...panelShapes, ...panelTextAreas, ...panelItemReferences];

    return panelItems.length > 1;
};

export function transformDocument(cimDoc: CimDoc) {
    const {
        document: { panels },
    } = cimDoc;

    panels.forEach((panel) => {
        const panelImages = panel.images ?? [];
        const isMultipleItems = hasMultipleItems(panel);

        if (panelImages.length && !isMultipleItems) {
            resizeItem(cimDoc, getItemResizeArgs(panelImages[0], panel));
        }
    });
}

export async function getResizedDocument(
    {
        designDocument,
        productKey,
        productVersion,
        selectedOptions,
        authorizationHeader,
        locale,
        cimDocUrl,
    }: ResizeDocumentProps,
    signal?: AbortSignal
) {
    const targetDoc = cimDocUrl ?? (await getTransientDocumentUrl(designDocument, authorizationHeader, signal));
    const designViewsURL = generateDesignViewsURL(productKey, productVersion, selectedOptions, locale);
    const resizedDocumentSources = await getResizedDocumentByDesignViews(targetDoc, designViewsURL, signal);
    return resizedDocumentSources;
}
