import React, { createContext, useContext, useMemo, useState } from 'react';

interface UploadProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

export type UploadModalContextType = {
    showUploadModal: boolean;
    validationMessage: string | null;
    autoMapped: boolean;
    showUploadLoader: boolean;
    showDeleteConfirmationModal: boolean;
    setShowUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
    setAutoMapped: React.Dispatch<React.SetStateAction<boolean>>;
    setValidationMessage: React.Dispatch<React.SetStateAction<string | null>>;
    setShowUploadLoader: React.Dispatch<React.SetStateAction<boolean>>;
    setShowDeleteConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UploadModalContext = createContext<UploadModalContextType | null>(null);

export const UploadModalProvider = (props: UploadProviderProps) => {
    const { children } = props;
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [autoMapped, setAutoMapped] = useState(false);
    const [showUploadLoader, setShowUploadLoader] = useState(true);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const value = useMemo(() => {
        return {
            showUploadModal,
            validationMessage,
            autoMapped,
            showUploadLoader,
            showDeleteConfirmationModal,
            setShowUploadModal,
            setValidationMessage,
            setAutoMapped,
            setShowUploadLoader,
            setShowDeleteConfirmationModal,
        };
    }, [showUploadModal, validationMessage, autoMapped, showUploadLoader, showDeleteConfirmationModal]);

    return <UploadModalContext.Provider value={value}>{children}</UploadModalContext.Provider>;
};

export const useUploadModal = () => {
    const value = useContext(UploadModalContext);
    if (!value) {
        throw new Error('useUploadModal must be used within a UploadModalProvider');
    }
    return value;
};
