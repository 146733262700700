import React, { useEffect, useState } from 'react';
import { FluidImage } from '@vp/swan';
import { createPortal } from 'react-dom';

export const QMPreview = () => {
    const [imgUrl, setImgUrl] = useState('');
    let canvas: HTMLCanvasElement | null = document.getElementsByClassName(
        'derc-item-preview__canvas'
    )[0] as HTMLCanvasElement | null;

    useEffect(() => {
        const interval = setInterval(() => {
            canvas = document.getElementsByClassName('derc-item-preview__canvas')[0] as HTMLCanvasElement | null;
            // Setting image quality low otherwise it will consume time to generate img as provided quality
            setImgUrl(canvas?.toDataURL('image/jpeg', 0.1) || '');
        }, 1000);

        return () => clearInterval(interval);
    });
    const QMImg = (
        <FluidImage
            src={imgUrl}
            qm-data-src={imgUrl}
            className="qm-image-mask"
            qm-display-in-replay="true"
            display={'none'}
            style={{
                position: 'absolute',
                height: `${canvas?.offsetHeight}px`,
                width: `${canvas?.offsetWidth}px`,
            }}
        />
    );
    const targetElement = document.getElementsByClassName('idaf-item-position')[0];
    if (!targetElement) return null;

    // It will put the QMImg to the targeted DOM element
    return createPortal(QMImg, targetElement);
};
