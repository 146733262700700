import { i18nColorName } from '@design-stack-vista/i18n-color-name';
import { PaletteColor } from '../../quad/components/ColorPalette';
import { ColorPaletteEntry } from '../../quad/types/colorPalette';

type SpotColor = { name: string };

const hexValues: PaletteColor = {
    'pantone(Violet C)': '#440099',
    'pantone(Reflex Blue C)': '#001489',
    'pantone(Process Blue C)': '#0085CA',
    'pantone(874 C)': '#8B6F4E',
    'pantone(877 C)': '#8A8D8F',
    'pantone(Black C)': '#2D2926',
    'pantone(116 C)': '#FFCD00',
    'pantone(1235 C)': '#FFB81C',
    'pantone(161 C)': '#603D20',
    'pantone(1655 C)': '#FC4C02',
    'pantone(179 C)': '#E03C31',
    'pantone(186 C)': '#C8102E',
    'pantone(202 C)': '#862633',
    'pantone(1935 C)': '#C5003E',
    'pantone(210 C)': '#F99FC9',
    'pantone(222 C)': '#6C1D45',
    'pantone(240 C)': '#C5299B',
    'pantone(289 C)': '#0C2340',
    'pantone(300 C)': '#005EB8',
    'pantone(320 C)': '#009CA6',
    'pantone(354 C)': '#00B140',
    'pantone(357 C)': '#215732',
    'pantone(430 C)': '#7C878E',
    'pantone(White)': '#FFFFFF',
};

export const getSingleColorColorPalette = (locale: string, availableSpotColors?: SpotColor[]): ColorPaletteEntry[] => {
    const availablePaletteColors = availableSpotColors?.reduce(
        (accumulator: ColorPaletteEntry[], currentValue: SpotColor) => {
            const { name: pColor } = currentValue;
            const pantoneValue = `pantone(${pColor.replace('PANTONE ', '')})`;
            const hexValue = hexValues[pantoneValue];
            if (hexValue) {
                accumulator.push({
                    cdifValue: pantoneValue,
                    cssBackground: hexValue,
                    name: i18nColorName(hexValue, { culture: locale }),
                });
            }
            return accumulator;
        },
        []
    );
    return availablePaletteColors || [];
};
