import React from 'react';
import { useUploadConfiguration } from '@design-stack-vista/upload-components';
import { Typography } from '@vp/swan';
import { useProductConfiguration } from '../../hooks/calcifer';
import { text } from '../../utils/localization';

export const UploadModalInfo = () => {
    const { productData } = useProductConfiguration();
    const { supportedFileTypes } = useUploadConfiguration();

    const productMinDpi = productData?.studioConfiguration.productMinDpi;

    return (
        <>
            {productMinDpi && (
                <Typography fontWeight="bold" mb="3">
                    {text('allowedUploadDpi', { productMinDpi })}
                </Typography>
            )}
            <Typography textColor="subtle" mb={{ xs: '5', sm: '6' }}>
                {supportedFileTypes.fileExtensionsAsString}
            </Typography>
        </>
    );
};
