import React, { useCallback, useEffect, useRef } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { usePreviewZoom } from '@design-stack-vista/preview-components';
import { preciseNumber } from './utils';
import { ZoomControlButtons } from './ZoomControlButtons';
import { Zoom } from '../../config/constant';
import { usePreview } from '../../providers/PreviewProvider';
import { ACTION_NAME } from '../TrackingEvents/constant';

export const PreviewZoomControls = () => {
    const { activePreview, is3DView } = usePreview();
    const { zoom, setZoom } = usePreviewZoom();
    const { fireEvent } = useCustomEventPropagation();
    const isEngagedZoomBar = useRef(false);

    useEffect(() => {
        setZoom(Zoom.DEFAULT_VALUE);
    }, [activePreview, is3DView]);

    const fireZoomEvent = () => {
        if (zoom !== 1) {
            if (!isEngagedZoomBar.current) {
                fireEvent({
                    name: ACTION_NAME.DESIGN_TOOL_USED,
                    extraData: {
                        eventDetail: 'engage:zoomBar',
                    },
                });
                isEngagedZoomBar.current = true;
            }
        }
    };

    useEffect(() => {
        if (zoom) {
            fireZoomEvent();
        }
    }, [zoom]);

    const handleZoom = (zoom: number) => {
        const closeZoomMultiple = preciseNumber(Math.round(zoom / Zoom.MODIFY_VALUE) * Zoom.MODIFY_VALUE);
        if (closeZoomMultiple > Zoom.MAX_VALUE || closeZoomMultiple < Zoom.MIN_VALUE) {
            return;
        }
        setZoom(closeZoomMultiple);
    };

    const handleZoomIn = useCallback(() => {
        const increaseZoom = preciseNumber(zoom + Zoom.MODIFY_VALUE);
        handleZoom(increaseZoom);
    }, [zoom]);

    const handleZoomOut = useCallback(() => {
        const decreaseZoom = preciseNumber(zoom - Zoom.MODIFY_VALUE);
        handleZoom(decreaseZoom);
    }, [zoom]);

    return (
        <ZoomControlButtons
            zoomLevel={Math.round(zoom * Zoom.SCALE_VALUE)}
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
        />
    );
};
