import { CimDoc, Panel, TemplateItem } from '@design-stack-vista/cdif-types/v2';

export const addOrUpdateMetadataDocumentSources = (
    cimDoc: CimDoc,
    { panelId, sourceType }: { panelId: string; sourceType: string }
) => {
    const panel = cimDoc.document.panels.find((panel) => panel.id === panelId);

    if (!panel) throw Error('Cannot find target panel');
    if (!cimDoc.metadata) {
        cimDoc.metadata = {};
    }
    if (!cimDoc.metadata.documentSources) {
        cimDoc.metadata.documentSources = {};
    }
    if (!cimDoc.metadata.documentSources.panels) {
        cimDoc.metadata.documentSources.panels = [];
    }

    const hasDocumentSourceForThePanel = cimDoc.metadata.documentSources.panels.find(
        (panelMetadata: { id: string }) => panelMetadata.id === panelId
    );

    if (hasDocumentSourceForThePanel) {
        // update the existing entry
        cimDoc.metadata.documentSources.panels = cimDoc.metadata.documentSources.panels.map(
            (panelMetadata: { id: string }) =>
                panelId === panelMetadata.id ? { ...panelMetadata, source: sourceType } : panelMetadata
        );
    } else {
        // add a new entry
        cimDoc.metadata.documentSources.panels = [
            ...cimDoc.metadata.documentSources.panels,
            {
                id: panelId,
                data: '',
                source: sourceType,
            },
        ];
    }
};

export const removeMetadataDocumentSources = (cimDoc: CimDoc, { panelId }: { panelId: string }) => {
    const panel = cimDoc.document.panels.find((panel) => panel.id === panelId);

    if (!panel) throw Error('Cannot find target panel');

    if (cimDoc.metadata) {
        if (cimDoc.metadata?.documentSources && cimDoc.metadata.documentSources.panels) {
            cimDoc.metadata.documentSources.panels = cimDoc.metadata.documentSources.panels.filter(
                (panel: Panel) => panel.id !== panelId
            );
        }
    }
};

export const addTemplateOrPlaceholderMetaData = (
    cimDoc: CimDoc,
    {
        templateMetaData,
        placeholdersMetaData,
    }: { templateMetaData?: TemplateItem[]; placeholdersMetaData?: Record<string, string> }
) => {
    if (!cimDoc.metadata) {
        cimDoc.metadata = {};
    }
    if (templateMetaData?.length) {
        if (!cimDoc.metadata.template) {
            cimDoc.metadata.template = [];
        }
        cimDoc.metadata.template = [...cimDoc.metadata.template, ...templateMetaData];
    }

    if (placeholdersMetaData && Object.keys(placeholdersMetaData)) {
        if (!cimDoc.metadata.placeholders) {
            cimDoc.metadata.placeholders = {};
        }
        cimDoc.metadata.placeholders = { ...cimDoc.metadata.placeholders, ...placeholdersMetaData };
    }
};

export const removeTemplateAndPlaceholderMetaData = (cimDoc: CimDoc) => {
    if (cimDoc.metadata?.template?.length) {
        cimDoc.metadata.template = [];
    }
    if (cimDoc.metadata?.placeholders && Object.keys(cimDoc.metadata.placeholders)) {
        delete cimDoc.metadata.placeholders;
    }
};
