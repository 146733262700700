import React, { useCallback } from 'react';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useLayoutContainers } from '@design-stack-vista/ida-framework';
import { css } from '@emotion/css';
import { className } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DesignAreaWarningIcon } from './DesignAreaWarningIcon';
import { useTrackExperimentClick } from '../../../hooks/useTrackExperimentClick';
import { PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { ValidationTypes } from '../Validations/type';
import { useValidation } from '../Validations/ValidationProvider';

// 24 offset values is the width of warningIcon
const offsetLeft = 24;
const margin = 2;

const iconPosition = (x = 0, y = 0) => css`
    position: absolute;
    top: ${y + margin}px;
    left: ${x - offsetLeft - margin}px;
`;

type IconPlacementWithZoomType = {
    x: number;
    y: number;
};

interface DesignAreaNotificationProps {
    itemId: string;
    panel: PanelState;
    validationType: ValidationTypes;
    iconPlacementWithZoom: IconPlacementWithZoomType;
}

export const DesignAreaNotification = observer(
    ({ panel, iconPlacementWithZoom, itemId, validationType }: DesignAreaNotificationProps) => {
        const {
            validationStore: { interactedWithValidation, validationIdentified },
        } = useValidation();

        const { trackExperimentClick } = useTrackExperimentClick();
        const { panContainer } = useLayoutContainers();
        const panAreaRect = panContainer?.getBoundingClientRect();

        const updateBetweenBoundValidationTrackingEvent = useCallback(() => {
            interactedWithValidation(itemId);
            trackExperimentClick({
                label: QUAD_TRACKING_EVENTS.BETWEEN_BOUND_IDENTIFIED,
                eventDetail: 'QUAD;QUAD;Image Validation;Between Bound',
                navigationDetail: 'Upload Image:Image Validation:Between Bound',
                pageSection: PageSection.BetweenBoundWarning,
                pageStage: PageStage.Design,
                experimentDetail: {
                    interacted: true,
                    panelName: panel.panelProperties.name,
                },
            });
        }, [interactedWithValidation, itemId, panel.panelProperties.name, trackExperimentClick]);

        const handleWarningIconInteraction = useCallback(() => {
            if (validationType === ValidationTypes.BetweenBound && !validationIdentified[itemId]?.interacted) {
                updateBetweenBoundValidationTrackingEvent();
            }
        }, [itemId, updateBetweenBoundValidationTrackingEvent, validationIdentified, validationType]);

        return (
            <div className={className(iconPosition(iconPlacementWithZoom.x, iconPlacementWithZoom.y), 'warning-icon')}>
                {panAreaRect && (
                    <DesignAreaWarningIcon
                        type={validationType}
                        iconPlacementWithZoom={iconPlacementWithZoom}
                        panAreaRect={panAreaRect}
                        onInteraction={handleWarningIconInteraction}
                    />
                )}
            </div>
        );
    }
);

DesignAreaNotification.displayName = 'DesignAreaNotification';
