import { useCallback } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useIdentityContext } from '@design-stack-vista/identity-provider';
import { usePostCard } from './usePostCard';
import { useNotificationFramework } from '../../../components/Notification';
import { PRICING_NOTIFICATION_DISMISS_TIMEOUT, REQUESTOR } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useQueryProductConfiguration } from '../../../hooks/calcifer/useQueryProductConfiguration';
import { useProductParams } from '../../../providers';
import { getCountryFromLocale } from '../../../utils';
import { getDefaultProductOptions } from '../../../utils/getDefaultProductOptions';
import { text } from '../../../utils/localization';
import { getResizedDocument, transformDocument } from '../../Flexibility/common';
import { POSTCARD_SUCCESS_MODAL_DELAY } from '../postcardConstants';
import { postCardStore } from '../PostCardStore';
import { updateUrl } from '../utils/updateUrl';

export const useMailingSwitch = () => {
    const { auth: vpAuth } = useIdentityContext();
    const { isMailingPostCard, switchProductTo, postcardPricingDifference } = usePostCard();
    const { productData, updateProductConfiguration } = useProductConfiguration();
    const designEngine = useDesignEngine();
    const { getProductConfiguration } = useQueryProductConfiguration();
    const { locale } = useProductParams();
    const { notifyCustomer } = useNotificationFramework();
    const {
        setIsMailingServiceSuccessModalOpen,
        setPostcardSwitchingInProgress,
        setIsFirstTimeSwitchFromMailablePostcard,
    } = postCardStore;

    const authorizationHeader = vpAuth.getAuthorizationHeader();

    const switchMSProduct = useCallback(
        async (isSwitchingToMS?: boolean) => {
            if (!productData || !switchProductTo) {
                return null;
            }

            setPostcardSwitchingInProgress(true);

            const currentDesignDocument = await designEngine.getCimDoc();

            const targetDocument = await getResizedDocument({
                designDocument: currentDesignDocument,
                selectedOptions: { ...productData.selectedOptions, ...productData.customerSelectedOptions },
                authorizationHeader,
                productKey: productData.productKey,
                productVersion: productData.productVersion,
                locale,
            });
            transformDocument(targetDocument);

            const { productKey, productVersion, quantity, compatibleCustomerSelectedProductOptions } =
                await getDefaultProductOptions({
                    productKey: switchProductTo,
                    customerSelectedProductOptions: productData.customerSelectedOptions,
                    quantity: productData.quantity,
                    locale,
                    authHeader: authorizationHeader,
                });

            const onError = () => {
                // TODO:handle error scenario here
            };
            const selectedOptions = JSON.stringify(compatibleCustomerSelectedProductOptions);
            const productConfig = await getProductConfiguration(
                {
                    productKey,
                    locale,
                    productVersion,
                    qty: quantity,
                    selectedOptions,
                    market: getCountryFromLocale(locale),
                    requestor: REQUESTOR,
                },
                onError
            );

            if (productConfig) {
                await updateProductConfiguration({ newCimDoc: targetDocument, productConfig });
                setPostcardSwitchingInProgress(false);
                updateUrl({
                    key: productKey,
                    productVersion,
                    selectedOptions,
                    mpvId: productConfig.mpvId,
                });
                showMailingSuccessMessage(postcardPricingDifference);
                if (isSwitchingToMS) {
                    setTimeout(() => {
                        setIsMailingServiceSuccessModalOpen(true);
                    }, POSTCARD_SUCCESS_MODAL_DELAY);
                } else {
                    setIsFirstTimeSwitchFromMailablePostcard(true);
                }
            } else {
                setPostcardSwitchingInProgress(false);
                // TODO: We need to show error message if some error happens
            }
        },
        [productData, postcardPricingDifference, authorizationHeader]
    );

    const showMailingSuccessMessage = useCallback(
        (formattedPrice?: string) => {
            notifyCustomer(
                {
                    notificationType: 'positive',
                    messageToShowCustomer: isMailingPostCard
                        ? text('mailingServiceRemoved', { formattedPrice: formattedPrice })
                        : text('mailingServiceActivated', { formattedPrice: formattedPrice }),
                },
                true,
                PRICING_NOTIFICATION_DISMISS_TIMEOUT
            );
        },
        [isMailingPostCard]
    );

    return { switchMSProduct };
};
