import { useCallback } from 'react';
import { useDesignEngine, useZoom } from '@design-stack-vista/core-features';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { UPLOAD_BACK_DESIGN_MODAL_TYPES } from './constants';
import { getUploadBackDesignModalConfig } from './helper';
import { BACK } from '../../../../config/constant';
import { useTrackExperimentClick } from '../../../../hooks';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';
import { useBackConfirmationContext, useUploadModal } from '../../../../providers';
import { useDesignReviewContext } from '../../../../providers/DesignReviewProvider';
import { useProductOptionsTracking } from '../../../../providers/ProductOptionsTrackingProvider';
import { ACTION_NAME, PageSection, QUAD_TRACKING_EVENTS } from '../../../TrackingEvents/constant';
import { GA_EVENTS } from '../../../TrackingEvents/GAEvents';
import { useTrackNewPage } from '../../../TrackingEvents/hooks';
import { useContinueButtonVCS } from '../../../VCS/hooks/useContinueButtonVCS';
import { isVCSConsumer } from '../../../VCS/utils/isVCSConsumer';

export const useUploadBackDesignModal = () => {
    const { isSmall } = useScreenLayout();
    const { setShowUploadModal } = useUploadModal();
    const { backDesignModalType, setModalConfig, setBackDesignModalType, setIsBackVisited, setOpenBackUploadModal } =
        useBackConfirmationContext();
    const { setIsOpenDesignReviewModal } = useDesignReviewContext();
    const productOptionsTracking = useProductOptionsTracking();

    const {
        layoutStore: { initialZoom },
        cimDocStore: { panels },
        idaStore: { setActiveDesignPanelId },
        layoutStore: { setVisiblePanelIds },
    } = useDesignEngine();
    const { trackExperimentClick } = useTrackExperimentClick();
    const { fireEvent } = useCustomEventPropagation();
    const { zoomTo } = useZoom();
    const { trackNewPage } = useTrackNewPage();
    const { handleFinishForVCS } = useContinueButtonVCS();

    const setModelConfiguration = useCallback(
        (backDesignModalType: UPLOAD_BACK_DESIGN_MODAL_TYPES) => {
            setBackDesignModalType(backDesignModalType);
            const modelConfiguration = getUploadBackDesignModalConfig(backDesignModalType);
            setModalConfig(modelConfiguration);
        },
        [backDesignModalType, isSmall]
    );

    // TODO: have to use this logic for handling products with different name or for multiple panels
    const backPanel = panels.find((panel) => panel.panelProperties.name === BACK);

    const closeUploadBackDesignModal = useCallback(() => {
        setOpenBackUploadModal(false);
        if (backDesignModalType === UPLOAD_BACK_DESIGN_MODAL_TYPES.REVIEW_CONFIRMATION) {
            const backPanelId = backPanel?.panelProperties.id;
            trackExperimentClick({
                label: QUAD_TRACKING_EVENTS.CLOSE_NUDGE_TO_BACK,
            });
            if (backPanelId) {
                setActiveDesignPanelId(backPanelId);
                setVisiblePanelIds([backPanelId]);
            }
        }

        fireEvent({
            name: ACTION_NAME.FLY_OUT_CLOSED,
            extraData: {
                label: GA_EVENTS.QUAD_BACK_DESIGN_FLY_OUT,
                category: PageSection.FlyOut,
            },
        });
    }, [backDesignModalType]);

    const showUploadBackDesignModal = useCallback((modalType: UPLOAD_BACK_DESIGN_MODAL_TYPES) => {
        setOpenBackUploadModal(true);
        setModelConfiguration(modalType);
        setIsBackVisited(true);
        fireEvent({
            name: ACTION_NAME.FLY_OUT_VIEWED,
            extraData: {
                category: PageSection.FlyOut,
                label: GA_EVENTS.QUAD_BACK_DESIGN_FLY_OUT,
            },
        });
    }, []);

    const onUploadButtonClick = useCallback(() => {
        setOpenBackUploadModal(false);
        setShowUploadModal(true);

        if (backDesignModalType === UPLOAD_BACK_DESIGN_MODAL_TYPES.REVIEW_CONFIRMATION) {
            fireEvent({
                name: ACTION_NAME.DESIGN_TOOL_USED,
                extraData: {
                    experimentLabel: QUAD_TRACKING_EVENTS.ADD_BACK_DESIGN,
                    eventDetail: GA_EVENTS.CLICK_NUDGE_PANEL.replace('[CTA TEXT]', 'Add the Back'),
                    pageSection: PageSection.NudgeToBackModal,
                },
            });
            const backPanelId = backPanel?.panelProperties.id;
            if (backPanelId) {
                setActiveDesignPanelId(backPanelId);
                setVisiblePanelIds([backPanelId]);
            }
            zoomTo(initialZoom);
        }
    }, [backDesignModalType]);

    const footerAction = useCallback(async () => {
        setOpenBackUploadModal(false);

        /**
         * Need to update as per requested tracking plan
         * {@link https://vistaprint.atlassian.net/wiki/spaces/DEUAPQ/pages/3486090994/QUAD+4.0+Tracking+request+for+GA}
         */
        if (backDesignModalType === UPLOAD_BACK_DESIGN_MODAL_TYPES.REVIEW_CONFIRMATION) {
            if (isVCSConsumer()) {
                await handleFinishForVCS();
                return;
            }
            const eventDetail = GA_EVENTS.FINISH_PDC_REDIRECTION.replace('[pagePath]', 'quick-upload-and-design')
                .replace('[destinationPath]', 'quick-upload-and-design:Review')
                .replace('[CTA TEXT]', 'Leave It Blank');

            fireEvent({
                name: ACTION_NAME.NAVIGATION_CLICKED,
                extraData: {
                    eventDetail,
                    navigationDetail: 'Leave It Blank',
                },
            });
            trackNewPage('Review');
            setIsOpenDesignReviewModal(true);
            productOptionsTracking.trackProductOptionsSelected();
        }
    }, [backDesignModalType, handleFinishForVCS]);

    return {
        closeUploadBackDesignModal,
        showUploadBackDesignModal,
        onUploadButtonClick,
        footerAction,
    };
};
