import { SUPPORTED_PREMIUM_PRD } from './config';
import { ProductData } from '../../hooks/calcifer/useProductConfiguration';
import { useQuadExperiment } from '../../hooks/useQuadExperiment';
import { useAbTestContext } from '../../providers/ABTestProvider';
import { getExperimentProperties } from '../../utils/getExperimentProperties';
import { useDebugFlag } from '../Debug/useDebugFlag';

const TEST_VARIATION = 'test';
const QUAD_FINISHES_BUSINESS_CARD_EXP_KEY = 'quad_finishes_business_cards_experiment';

export function usePremiumFinish(productData: ProductData) {
    const { isFlagEnabled } = useDebugFlag();
    const isPremiumFinishFlagEnabled = isFlagEnabled('quad_premium_finish');
    const experiment = useQuadExperiment();
    const ABTest = useAbTestContext();
    let shouldInitializePremiumFinishStore = false;
    const isPremiumProduct = SUPPORTED_PREMIUM_PRD[productData.productKey];
    if (ABTest) {
        const { experimentKey, variationKey } = getExperimentProperties(experiment, ABTest);
        const isPremiumFinishExpEnabled =
            experimentKey === QUAD_FINISHES_BUSINESS_CARD_EXP_KEY && variationKey === TEST_VARIATION;
        shouldInitializePremiumFinishStore = isPremiumProduct ? isPremiumFinishExpEnabled : isPremiumFinishFlagEnabled;
    }
    return { shouldInitializePremiumFinishStore };
}
