import React from 'react';
import {
    Button,
    FlexBox,
    ModalDialog,
    ModalDialogBody,
    ModalDialogButtons,
    ModalDialogFooter,
    Typography,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { StandardModal } from '../../components/Modal/components/StandardModal';
import { text } from '../../utils/localization';

type DesignPreviewModalProps = React.ComponentProps<typeof ModalDialog>;

export const SentPdfProofModal = observer((props: DesignPreviewModalProps): JSX.Element => {
    const { onRequestDismiss, isOpen, ...rest } = props;

    return (
        <StandardModal onClose={onRequestDismiss} isOpen={isOpen} {...rest} fullBleed={true}>
            <ModalDialogBody mx="0" pt={{ xs: '6', sm: '10' }} px={{ xs: '5', sm: '6' }}>
                <FlexBox flexDirection="column">
                    <Typography fontWeight="bold" fontSize="x2large" mb="5">
                        {text('preparingPDF')}
                    </Typography>
                    <Typography>
                        {text('fewMinutes')} <br />
                        {text('autoDownload')}
                    </Typography>
                </FlexBox>
            </ModalDialogBody>
            <ModalDialogFooter pinned m={{ xs: '5', sm: '6' }} mt="0" pt={{ xs: '5', sm: '6' }} p="0">
                <ModalDialogButtons>
                    <Button skin="primary" size="mini" onClick={onRequestDismiss} data-testid="continue-edit-btn">
                        {text('continueEditing')}
                    </Button>
                </ModalDialogButtons>
            </ModalDialogFooter>
        </StandardModal>
    );
});
