import React from 'react';
import { observer } from 'mobx-react-lite';
import { QuickToolsDesktop } from './QuickToolsDesktop';
import { QuickToolsMobile } from './QuickToolsMobile';

export const QuickTools = observer(() => {
    return (
        <>
            <QuickToolsDesktop />
            <QuickToolsMobile />
        </>
    );
});
