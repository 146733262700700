import React from 'react';
import { Button } from '@vp/swan';
import { useScreenLayout } from '../../hooks/useScreenLayout';

interface DesignApproveButtonProps {
    onClick: () => Promise<void>;
    title: string;
    className?: string;
}

export const DesignApproveButton = ({ onClick, title, className }: DesignApproveButtonProps) => {
    const { isSmall } = useScreenLayout();

    return (
        <Button
            skin="primary"
            size={isSmall ? 'mini' : 'standard'}
            width={isSmall ? 'wide' : 'full-width'}
            onClick={onClick}
            className={className}
            data-testid="design-approve-button"
            px="5"
        >
            {title}
        </Button>
    );
};
