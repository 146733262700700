import packageObj from "../../../package.json";
export const pushToSnowflake = (data) => {
  if (!data.host.includes("localhost")) {
    let cimbaUrl = process.env.CIMBA_URL;
    let versionId = process.env.VERSION_ID;
    let LibVersion = packageObj.version;
    data["versionId"] = versionId;
    data["LibVersion"] = LibVersion;
    const res = fetch(cimbaUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-version-id": versionId,
      },
      body: JSON.stringify([
        {
          streamId: process.env.CIMBA_STREAM_ID,
          jsonObj: data,
          appId: process.env.CIMBA_APP_ID,
          maxRetryCount: 3,
          retryDuration: 15,
        },
      ]),
    });
  }
};
