import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const ActiveOptionIcon = (props: SvgProps) => {
    return (
        <svg fill="none" viewBox="0 0 20 20" {...props}>
            <circle cx={10} cy={10} r={10} fill="#000" />
            <path
                d="m7 9.82 2.189 2.2L14.18 7"
                stroke="#fff"
                strokeWidth={1.333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
