import { MeasurementDimensions } from '@design-stack-ct/utility-core';
import { Dimensions, validateImageResolution } from '@design-stack-ct/validations';

const warningThreshold = 0.75;

export async function isLowResolutionImage(
    containerDimensions: MeasurementDimensions,
    imageDimensions: Dimensions,
    productMinDpi: number
) {
    const { payload } = await validateImageResolution({
        containerDimensions: containerDimensions,
        pixelDimensions: imageDimensions,
        minimumPpi: productMinDpi,
    });
    if (payload) {
        const ppiRatio = payload.ppi / productMinDpi;

        return ppiRatio < warningThreshold;
    }
    return false;
}
