import React from 'react';
import { css } from '@emotion/css';
import { FlexBox } from '@vp/swan';
import { TeamsPromoteModalStepsItem } from './TeamsPromoteModalStepsItem';

const teamsPromoteModalLineStyle = css`
    width: 240px;
    border: 1px solid #000;
`;

export const TeamsPromoteModalSteps = (): JSX.Element => {
    return (
        <FlexBox alignItems="center" justifyContent="center">
            <TeamsPromoteModalStepsItem stepsNumber={1} />
            <div className={teamsPromoteModalLineStyle}></div>
            <TeamsPromoteModalStepsItem stepsNumber={2} />
            <div className={teamsPromoteModalLineStyle}></div>
            <TeamsPromoteModalStepsItem stepsNumber={3} />
        </FlexBox>
    );
};
