import React from 'react';
import { css } from '@emotion/css';
import {
    Buttonbar,
    ButtonbarButton,
    ButtonbarButtonLabel,
    ButtonbarSelectedValue,
    FlexBox,
    tokens,
    Typography,
} from '@vp/swan';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';

const getButtonBarStyle = (isSmall: boolean) => css`
    width: 100%;
    position: relative;
    bottom: ${isSmall ? tokens.SwanSemSpaceNone : 'calc(-1 * (72px - 24px))'};
    &.swan-buttonbar {
        ${isSmall && 'position: absolute;'}
    }
`;

interface FlexibilitySwitcherProps {
    labels: (string | undefined)[];
    showPreviewSwitcher: boolean;
    selectedIndex: number;
    onSelectedValueChange: (newSelectedIndex: ButtonbarSelectedValue) => void;
}

export const FlexibilitySwitcher = ({
    labels,
    showPreviewSwitcher,
    selectedIndex,
    onSelectedValueChange,
}: FlexibilitySwitcherProps) => {
    const { isSmall } = useScreenLayout();

    if ((labels && labels.length < 2) || !showPreviewSwitcher) {
        return null;
    }

    return (
        <FlexBox justifyContent="center" px="5" pt="5" className={getButtonBarStyle(isSmall)}>
            <Buttonbar
                variant="single-select"
                defaultSelectedValue={String(selectedIndex)}
                onSelectedValueChange={onSelectedValueChange}
            >
                {labels?.map((label, index: number) => (
                    <ButtonbarButton value={index.toString()} key={label}>
                        <ButtonbarButtonLabel>
                            <Typography fontSize="small">{label}</Typography>
                        </ButtonbarButtonLabel>
                    </ButtonbarButton>
                ))}
            </Buttonbar>
        </FlexBox>
    );
};
