import React, { useEffect } from 'react';
import { Loader } from './Loader';
import { useFullScreenLoader } from '../../providers';

export const FullScreenLoader = () => {
    const { fullScreenLoaderData, setFullScreenLoaderData } = useFullScreenLoader();

    // This is to fix the bug on Safari where the loader persists when user does a browser back and returns to QUAD
    useEffect(() => {
        const handleLocationChange = () => {
            setFullScreenLoaderData({ showLoader: false });
        };

        window.addEventListener('pageshow', handleLocationChange, false);
        return () => {
            window.removeEventListener('pageshow', handleLocationChange);
        };
    }, []);

    return (
        <>
            {fullScreenLoaderData?.showLoader && (
                <Loader
                    accessibilityLabel={fullScreenLoaderData?.loaderMessage}
                    testId={fullScreenLoaderData?.testId}
                />
            )}
        </>
    );
};
