import { useEffect, useState } from 'react';
import { useProductConfiguration } from './calcifer';
import { useProductParams } from '../providers';
import { QuadExperiment } from '../types/quadExperiment';
import { getQUADExperiments } from '../utils/getQUADExperiment';

export const useQuadExperiment = () => {
    const { locale } = useProductParams();
    const { productData } = useProductConfiguration();
    const [experiment, setExperiment] = useState<QuadExperiment>();

    useEffect(() => {
        if (productData?.mpvId && locale) {
            const quadExperiment = getQUADExperiments(locale, productData.mpvId);
            setExperiment(quadExperiment);
        }
    }, [productData?.mpvId, locale]);

    return experiment;
};
