/**
 * Reference - {@Link https://gitlab.com/vistaprint-org/design-technology/studio/studio/-/blob/master/src/shared/features/Save/useLastSavedText.tsx}
 */
import { useMemo } from 'react';
import { useProductParams } from '../providers';
import { useSaveWork } from '../providers/SaveWorkProvider';
import { text } from '../utils/localization';

const millisecondsInADay = 86400000;

export function useLastSavedText(lastSaved: string | undefined, showEditedText?: boolean) {
    const { locale } = useProductParams();
    const { isWorkSaved } = useSaveWork();

    const lastSavedText = useMemo<string | null>(() => {
        if (!lastSaved) {
            return null;
        }
        const lastSavedLocal: Date = new Date(
            `${lastSaved[lastSaved.length - 1] === 'Z' ? lastSaved : `${lastSaved}Z`}`
        );
        const todayLocal = new Date(Date.now());

        const localTime = lastSavedLocal.toLocaleTimeString(locale ?? undefined, {
            hour: 'numeric',
            minute: 'numeric',
        });

        // if today
        if (todayLocal.toDateString() === lastSavedLocal.toDateString()) {
            return showEditedText
                ? text('lastEditedTodayText', { localTime: `${localTime}` })
                : text('lastSavedTodayText', { localTime: `${localTime}` });
        }

        const yesterdayLocal = new Date(Date.now() - millisecondsInADay);

        // if yesterday
        if (yesterdayLocal.toDateString() === lastSavedLocal.toDateString()) {
            return showEditedText
                ? text('lastEditedYesterdayText', { localTime: `${localTime}` })
                : text('lastSavedYesterdayText', { localTime: `${localTime}` });
        }

        const saveTime = lastSavedLocal.toLocaleDateString(locale ?? undefined);

        return showEditedText
            ? text('editedText', { saveTime: `${saveTime}` })
            : text('savedText', { saveTime: `${saveTime}` });
    }, [lastSaved, locale, showEditedText, isWorkSaved]);

    return lastSavedText;
}
