import React, { useCallback } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { css } from '@emotion/css';
import { Button, Link, tokens } from '@vp/swan';
import { DesktopExperience, MobileExperience } from '../../../components/Experiences';
import { InvertColorIcon } from '../../../components/SvgIcons';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { useAdjustContrast } from '../hooks/useAdjustContrast';

const invertColorIconStyle = css`
    width: 11px;
    height: 11px;
    margin-right: ${tokens.SwanSemSpace2};
`;

export const AdjustContrastInvertColor = (): JSX.Element => {
    const { isSmall } = useScreenLayout();
    const { isInverted, setIsInverted, isImageLoaded } = useAdjustContrast();
    const { fireEvent } = useCustomEventPropagation();

    const invertColor = useCallback(() => {
        setIsInverted(!isInverted);
        fireEvent({
            name: ACTION_NAME.EXPERIMENT_CLICKED,
            extraData: {
                label: QUAD_TRACKING_EVENTS.CLICK_ADJUST_CONTRAST_INVERT_COLOR,
                eventDetail: 'QUAD;QUAD;Adjust contrast invert color',
                navigationDetail: 'AdjustContrast:Invert color',
                pageSection: PageSection.AdjustContrast,
                pageStage: PageStage.Design,
                category: undefined,
            },
        });
    }, [isInverted]);

    return (
        <>
            <DesktopExperience>
                <Button
                    skin={isSmall ? 'link' : 'secondary'}
                    size="mini"
                    iconPosition="left"
                    onClick={invertColor}
                    disabled={!isImageLoaded}
                >
                    <InvertColorIcon className={invertColorIconStyle} />
                    {text('adjustContrastInvertColors')}
                </Button>
            </DesktopExperience>
            <MobileExperience>
                <Link
                    render={(p) => (
                        <Button skin="link" className={p.className} onClick={invertColor} disabled={!isImageLoaded}>
                            {p.children}
                        </Button>
                    )}
                >
                    {text('adjustContrastInvertColors')}
                </Link>
            </MobileExperience>
        </>
    );
};
