import { QUAD_HOSTNAMES } from '../constant';

/**
 * @returns website host origin
 * @returns Dev NextGen ProShop URL if VCS QUAD is on localhost/dev/stage
 */
export const getHost = () => {
    const hostname = globalThis.location.hostname;

    if (hostname === QUAD_HOSTNAMES.local || hostname === QUAD_HOSTNAMES.dev || hostname === QUAD_HOSTNAMES.stage) {
        return 'https://nextgen.dev.proshopnext.com/';
    }
    return `${globalThis.location.origin}/`;
};
