import qs from 'qs';
import { REQUESTOR, Tenants } from '../config/constant';
import { fetchWrapper, getCountryFromLocale } from '../utils';

const CATALOG_TRANSITION = 'https://catalog-transition.products.vpsvc.com';

export const getCatalogMerchandisingInfo = async <T>(
    productKey: string,
    productVersion: number,
    locale: string,
    signal?: AbortSignal
): Promise<T | undefined> => {
    const query = qs.stringify({
        merchant: Tenants.Vistaprint,
        market: getCountryFromLocale(locale),
        requestor: REQUESTOR,
    });

    const merchandisingURL = `${CATALOG_TRANSITION}/api/v3/products/${productKey}/${productVersion}/quantities/merchandising`;

    const merchandisingInfo = await fetchWrapper<T>(merchandisingURL, {
        method: 'GET',
        requestInitiator: 'getMinimumOrderQuantity',
        query,
        signal,
    });

    return merchandisingInfo;
};
