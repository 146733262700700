import { CimDoc } from '@design-stack-vista/cdif-types';
import { PanelState, removeItems, setBackgroundColor } from '@design-stack-vista/cimdoc-state-manager';
import { getPanelItemIds } from '../../../utils';
import { ColorMode } from '../../../utils/upsell';
import {
    addOrUpdateMetadataDocumentSources,
    removeTemplateAndPlaceholderMetaData,
} from '../../quad/commands/addPanelMetadata';
import { updatePanelColorMode } from '../../quad/commands/updatePanelColorMode';

export const setColorToPanel = ({ cimDoc, panel, color }: { cimDoc: CimDoc; panel: PanelState; color: string }) => {
    if (panel.items.length) {
        const itemIds = getPanelItemIds(panel);
        removeItems(cimDoc, { ids: itemIds });
    }
    removeTemplateAndPlaceholderMetaData(cimDoc);
    updatePanelColorMode(cimDoc, { panelId: panel.id, colorMode: ColorMode.Color });
    addOrUpdateMetadataDocumentSources(cimDoc, { panelId: panel.id, sourceType: 'FULLBLEED' });
    setBackgroundColor(cimDoc, {
        panelId: panel.id,
        color: `rgb(${color})`,
    });
};
