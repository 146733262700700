import { Resource } from 'i18next';
import dadk from './da-dk.json';
import dede from './de-de.json';
import engb from './en-gb.json';
import enus from './en-us.json';
import eses from './es-es.json';
import fifi from './fi-fi.json';
import frca from './fr-ca.json';
import frfr from './fr-fr.json';
import itit from './it-it.json';
import nbno from './nb-no.json';
import nlnl from './nl-nl.json';
import ptpt from './pt-pt.json';
import svse from './sv-se.json';

const resources: Resource = {
    'da-dk': dadk,
    'de-de': dede,
    'en-gb': engb,
    'en-us': enus,
    'es-es': eses,
    'fi-fi': fifi,
    'fr-ca': frca,
    'fr-fr': frfr,
    'it-it': itit,
    'nb-no': nbno,
    'nl-nl': nlnl,
    'pt-pt': ptpt,
    'sv-se': svse,
};

export default resources;
