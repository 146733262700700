import { useCallback, useEffect, useState } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { usePremiumFinishStore } from '../../../providers';
import { lowerCaseFirstLetter } from '../../../utils';
import { text } from '../../../utils/localization';
import { PREMIUM_FINISH_ASSETS, staticDataValue } from '../config';
import { FINISH_PREVIEW_FORMAT, FinishOption, LEVEL, PF_INSTRUCTIONS_TYPE } from '../constants';
import {
    FINISH_PREVIEW_SCENEType,
    FinishReviewInstructionType,
    PremiumFinishSelectionSetType,
    TransitionalPreviewModalPropsData,
} from '../type';

const initialTransitionalPreviewModalData = {
    footerKey: '',
    headerKey: '',
    subHeaderKey: '',
    previewScene: { sourceUrl: '', type: FINISH_PREVIEW_FORMAT.IMAGE },
    selectionSet: [],
};

export const usePremiumFinishData = () => {
    const { isPremiumCard, defaultPremiumFinish, pricing, supportsPremiumFinish, supportedPremiumVariants } =
        usePremiumFinishStore();

    const {
        cimDocStore: { asJson: cimdoc },
    } = useDesignEngine();

    const [level, setLevel] = useState<LEVEL>(LEVEL.level1);
    const [transitionalPreviewModalData, setTransitionalPreviewModalData] = useState<TransitionalPreviewModalPropsData>(
        initialTransitionalPreviewModalData
    );
    const [currentFinishSelection, setCurrentFinishSelection] = useState(defaultPremiumFinish);
    const [defaultFinishOptions, setDefaultFinishOptions] = useState('');
    const [optionalNotes, setOptionalNotes] = useState('');

    const updateLevel = useCallback((levelState: LEVEL) => {
        setLevel(levelState);
    }, []);

    const updateCurrentFinishSelection = useCallback((finishKey: string) => {
        setCurrentFinishSelection(finishKey);
    }, []);

    const getScenePreview = useCallback((finishKey: string) => {
        if (!finishKey) {
            finishKey = currentFinishSelection;
        }
        return staticDataValue[finishKey][LEVEL.level1].previewUrl;
    }, []);

    /**
     * This would be triggered only when there is an update to `reviewInstructions`.
     * In our case, when we when `addPremiumFinishReviewInstruction` or `removePremiumFinishReviewInstruction`
     * commands are called.
     *
     * `addPremiumFinishReviewInstruction` or `removePremiumFinishReviewInstruction` are called when there is
     * an update to be done to apply finish options or update finish instruction notes.
     *
     */
    useEffect(() => {
        if (cimdoc.metadata?.reviewInstructions) {
            const premiumInstructionReviewIndex: FinishReviewInstructionType[] =
                cimdoc.metadata?.reviewInstructions.filter(
                    (review: { instructionType: string }) => review.instructionType === PF_INSTRUCTIONS_TYPE
                );
            if (premiumInstructionReviewIndex.length > 0) {
                setOptionalNotes(premiumInstructionReviewIndex[0].optionalNotes);
                setDefaultFinishOptions(premiumInstructionReviewIndex[0].finishOption as FinishOption);
            } else {
                setOptionalNotes('');
                setDefaultFinishOptions('');
            }
        }
    }, [cimdoc.metadata?.reviewInstructions]);

    useEffect(() => {
        updateCurrentFinishSelection(defaultPremiumFinish);
    }, [defaultPremiumFinish, updateCurrentFinishSelection]);

    useEffect(() => {
        if (supportsPremiumFinish) {
            const currentLevel = isPremiumCard ? LEVEL.level2 : LEVEL.level1;
            updateLevel(currentLevel);
        }
    }, [isPremiumCard, supportsPremiumFinish, updateLevel]);

    const getConfigurationForFinish = useCallback(
        (finishKey: string) => {
            const finishKeyStaticValue = staticDataValue[finishKey];
            const finishKeyPricing = pricing[finishKey];

            const finishInformation = { ...finishKeyStaticValue.finishInformation, pricing: finishKeyPricing };
            const finishApplyOption = [...finishKeyStaticValue.finishApplyOption];

            return { finishInformation, finishApplyOption };
        },
        [pricing]
    );

    useEffect(() => {
        if (supportsPremiumFinish) {
            let footerKey = 'continueBtn';
            let headerKey = 'finishHeaderTitle';
            let subHeaderKey = '';
            let selection: PremiumFinishSelectionSetType[] = [];
            let scenePreview: FINISH_PREVIEW_SCENEType = {
                sourceUrl: '',
                type: FINISH_PREVIEW_FORMAT.IMAGE,
            };

            if (level === LEVEL.level1) {
                selection = supportedPremiumVariants.map((supportedPremiumVariant): PremiumFinishSelectionSetType => {
                    const finishConfigData = getConfigurationForFinish(supportedPremiumVariant);
                    const { finishInformation } = finishConfigData;
                    scenePreview = finishConfigData.finishInformation.previewUrl;
                    return {
                        id: supportedPremiumVariant,
                        title: finishInformation.title,
                        description: finishInformation.description,
                        pricing: finishInformation.pricing,
                        thumbnailUrl: finishInformation.thumbnail,
                    };
                });
            } else {
                const { finishApplyOption, finishInformation } = getConfigurationForFinish(currentFinishSelection);

                selection = finishApplyOption.map((finishOption): PremiumFinishSelectionSetType => {
                    const thumbnailKey = lowerCaseFirstLetter(
                        `${currentFinishSelection}${finishOption.id}Thumbnail`.replaceAll(' ', '') // Removing spaces from the keys
                    );
                    const thumbnailUrl = PREMIUM_FINISH_ASSETS[thumbnailKey];

                    /**
                     * setting return properties could be replaced with spread-operator
                     * but it wouldn't be readable.
                     *
                     * To maintain readability manually assigning the values to there property.
                     */
                    return {
                        id: finishOption.id,
                        description: finishOption.description,
                        title: finishOption.title,
                        thumbnailUrl,
                    };
                });

                scenePreview = finishInformation.previewUrl;
                headerKey = text('finishApplyHeader', {
                    finish: currentFinishSelection.toLowerCase(),
                });
                subHeaderKey = 'finishApplySubHeader';
                footerKey = 'saveSelectionBtn';
            }

            setTransitionalPreviewModalData({
                footerKey,
                headerKey,
                subHeaderKey,
                selectionSet: selection,
                previewScene: scenePreview,
            });
        }
    }, [
        level,
        pricing,
        currentFinishSelection,
        supportedPremiumVariants,
        getConfigurationForFinish,
        supportsPremiumFinish,
    ]);

    return {
        level,
        updateLevel,
        transitionalPreviewModalData,
        updateCurrentFinishSelection,
        getScenePreview,
        defaultFinishOptions,
        optionalNotes,
        currentFinishSelection,
    };
};
