import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { FlexBox } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DialogMessageKey, FINISH_TYPE, FinishOption } from './constants';
import { FinishButton } from './FinishButton';
import { MobileFinishDialog } from './MobileFinishDialog';
import { DeleteFinishConfirmationModal } from './Modal/DeleteFinishConfirmationModal';
import { usePremiumFinishData } from './Modal/usePremiumFinishData';
import { FinishPopover } from './PopoverModal/FinishPopover';
import { useUpdateFinishVariant } from './useUpdateFinishVariant';
import { updateExplorePopoverSeenStatus } from './utils/exploreFinishPopover';
import { DesktopExperience, MobileExperience } from '../../components/Experiences';
import { useNotificationFramework } from '../../components/Notification';
import { PipeIcon } from '../../components/SvgIcons';
import { PRICING_NOTIFICATION_DISMISS_TIMEOUT } from '../../config/constant';
import { useRenderingState } from '../../hooks/useRenderingState';
import { usePremiumFinishModal, usePremiumFinishStore } from '../../providers';
import { capitalizeFirstLetter, getSessionStorageKey, SESSION_STORAGE_KEY } from '../../utils';
import { text } from '../../utils/localization';
import { removePremiumFinishReviewInstruction } from '../quad/commands/removePremiumFinishReviewInstruction';

export const PremiumFinishButton = observer(() => {
    const { pricing, supportedPremiumVariants, defaultPremiumFinish, supportsPremiumFinish } = usePremiumFinishStore();
    const {
        executeCommand,
        cimDocStore: { panels },
        idaStore: { activeDesignPanelId },
    } = useDesignEngine();
    const { onFinishVariantUpdate } = useUpdateFinishVariant();
    const { notifyCustomer } = useNotificationFramework();
    const { defaultFinishOptions, currentFinishSelection } = usePremiumFinishData();
    const { openModal } = usePremiumFinishModal();

    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [openMobileDrawer, setOpenMobileDrawer] = useState(false);
    const { renderingStatus } = useRenderingState();
    const showPropoverContent = renderingStatus === 'COMPLETE';
    const [showExploreFinishPopover, setShowExploreFinishPopover] = useState(
        !getSessionStorageKey(SESSION_STORAGE_KEY.EXPLORE_PREMIUM_FINISHES_POPOVER_SHOWED) &&
            currentFinishSelection === FINISH_TYPE.none &&
            supportedPremiumVariants.length > 1
    );
    const hideExplorePopover = useCallback(() => {
        updateExplorePopoverSeenStatus();
        setShowExploreFinishPopover(false);
    }, []);

    const mobileOnFinishIconClick = () => {
        supportedPremiumVariants.length > 1 && currentFinishSelection !== FINISH_TYPE.none
            ? setOpenMobileDrawer(true)
            : onFinishIconClick();
    };

    const onMobileDrawerDeleteClick = () => {
        setOpenMobileDrawer(false);
        onPopoverDeleteButtonClick();
    };

    const onMobileDrawerChangeClick = () => {
        setOpenMobileDrawer(false);
        onFinishIconClick();
    };

    const onFinishIconClick = useCallback(() => {
        openModal();
    }, [openModal]);

    const onFinishDelete = async () => {
        const updatedCimdoc = executeCommand(removePremiumFinishReviewInstruction, {}).cimDoc;
        setShowDeleteConfirmationModal(false);
        if (supportedPremiumVariants.length > 1) {
            await onFinishVariantUpdate(FINISH_TYPE.none, updatedCimdoc);
        }

        const price = pricing[FINISH_TYPE.none].increment;

        if (price) {
            notifyCustomer(
                {
                    messageToShowCustomer: text('finishDeleteNotification', {
                        defaultPremiumFinish: capitalizeFirstLetter(defaultPremiumFinish),
                        price,
                    }),
                    notificationType: 'positive',
                },
                true,
                PRICING_NOTIFICATION_DISMISS_TIMEOUT
            );
        }
    };

    const onDeleteCancel = () => {
        setShowDeleteConfirmationModal(false);
    };

    const onPopoverDeleteButtonClick = () => {
        setShowDeleteConfirmationModal(true);
    };

    const finishIconDisabled = useMemo(() => {
        const frontPanel = panels[0];
        return frontPanel.id !== activeDesignPanelId;
    }, [activeDesignPanelId, panels]);

    const textDescription = useMemo(() => {
        const finish = capitalizeFirstLetter(currentFinishSelection.toLowerCase());
        return defaultFinishOptions
            ? `${finish} ${text(DialogMessageKey[defaultFinishOptions as FinishOption])}.`
            : `${finish}.`;
    }, [currentFinishSelection, defaultFinishOptions]);

    return (
        <Fragment>
            {supportsPremiumFinish && (
                <Fragment>
                    <DesktopExperience>
                        <Fragment>
                            {(supportedPremiumVariants.length > 1 && currentFinishSelection !== FINISH_TYPE.none) ||
                            finishIconDisabled ||
                            showExploreFinishPopover ? (
                                <FinishPopover
                                    finishIconDisabled={finishIconDisabled}
                                    onFinishIconClick={onFinishIconClick}
                                    onPopoverDeleteButtonClick={onPopoverDeleteButtonClick}
                                    textSelection={textDescription}
                                    showExploreFinishPopover={showExploreFinishPopover}
                                    hideExplorePopover={hideExplorePopover}
                                    showPropoverContent={showPropoverContent}
                                />
                            ) : (
                                <FinishButton onClick={onFinishIconClick} finishIconDisabled={finishIconDisabled} />
                            )}

                            <FlexBox alignItems="center">
                                <PipeIcon />
                            </FlexBox>
                        </Fragment>
                    </DesktopExperience>
                    <MobileExperience>
                        <>
                            {showExploreFinishPopover ? (
                                <FinishPopover
                                    finishIconDisabled={finishIconDisabled}
                                    onFinishIconClick={onFinishIconClick}
                                    onPopoverDeleteButtonClick={onPopoverDeleteButtonClick}
                                    textSelection={textDescription}
                                    showExploreFinishPopover={showExploreFinishPopover}
                                    hideExplorePopover={hideExplorePopover}
                                    showPropoverContent={showPropoverContent}
                                />
                            ) : (
                                <FinishButton
                                    onClick={mobileOnFinishIconClick}
                                    finishIconDisabled={finishIconDisabled}
                                />
                            )}
                            <MobileFinishDialog
                                descriptionMessage={textDescription}
                                isOpen={openMobileDrawer}
                                onChange={onMobileDrawerChangeClick}
                                onClose={() => setOpenMobileDrawer(false)}
                                onDelete={onMobileDrawerDeleteClick}
                            />
                        </>
                    </MobileExperience>
                    <DeleteFinishConfirmationModal
                        defaultPremiumFinish={defaultPremiumFinish}
                        onCancel={onDeleteCancel}
                        onDelete={onFinishDelete}
                        showModal={showDeleteConfirmationModal}
                    />
                </Fragment>
            )}
        </Fragment>
    );
});
