/* eslint-disable @typescript-eslint/no-explicit-any */
import type { RefObject } from 'react';
import { useEffect, useLayoutEffect, useRef } from 'react';

// Borrowed from https://github.com/jaredLunde/react-hook/blob/master/packages/resize-observer/src/index.tsx

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useLatest = <T extends any>(current: T) => {
    const storedValue = useRef(current);
    useEffect(() => {
        storedValue.current = current;
    });
    return storedValue;
};

export type UseResizeObserverCallback = (entry: ResizeObserverEntry, observer: ResizeObserver) => any;

function createResizeObserver() {
    let ticking = false;
    let allEntries: ResizeObserverEntry[] = [];

    const callbacks: Map<any, Array<UseResizeObserverCallback>> = new Map();

    const observer = new ResizeObserver((entries: ResizeObserverEntry[], obs: ResizeObserver) => {
        allEntries = allEntries.concat(entries);
        if (!ticking) {
            window.requestAnimationFrame(() => {
                const triggered = new Set<Element>();
                for (let i = 0; i < allEntries.length; i++) {
                    // eslint-disable-next-line no-continue
                    if (triggered.has(allEntries[i].target)) continue;
                    triggered.add(allEntries[i].target);
                    const cbs = callbacks.get(allEntries[i].target);
                    // eslint-disable-next-line no-loop-func
                    cbs?.forEach((cb) => cb(allEntries[i], obs));
                }
                allEntries = [];
                ticking = false;
            });
        }
        ticking = true;
    });

    return {
        observer,
        subscribe(target: HTMLElement, callback: UseResizeObserverCallback) {
            observer.observe(target);
            const cbs = callbacks.get(target) ?? [];
            cbs.push(callback);
            callbacks.set(target, cbs);
        },
        unsubscribe(target: HTMLElement, callback: UseResizeObserverCallback) {
            const cbs = callbacks.get(target) ?? [];
            if (cbs.length === 1) {
                observer.unobserve(target);
                callbacks.delete(target);
                return;
            }
            const cbIndex = cbs.indexOf(callback);
            if (cbIndex !== -1) cbs.splice(cbIndex, 1);
            callbacks.set(target, cbs);
        },
    };
}

// eslint-disable-next-line no-underscore-dangle
let _resizeObserver: ReturnType<typeof createResizeObserver>;

// eslint-disable-next-line no-return-assign
const getResizeObserver = () => (!_resizeObserver ? (_resizeObserver = createResizeObserver()) : _resizeObserver);

export function useResizeObserver<T extends HTMLElement>(
    target: RefObject<T> | T | null | undefined,
    callback: UseResizeObserverCallback
) {
    const resizeObserver = getResizeObserver();
    const storedCallback = useLatest(callback);

    useLayoutEffect(() => {
        let didUnsubscribe = false;
        const targetEl = target && 'current' in target ? target.current : target;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        if (!targetEl) return () => {};

        function cb(entry: ResizeObserverEntry, observer: ResizeObserver) {
            if (didUnsubscribe) return;
            storedCallback.current(entry, observer);
        }

        resizeObserver.subscribe(targetEl as HTMLElement, cb);

        return () => {
            didUnsubscribe = true;
            resizeObserver.unsubscribe(targetEl as HTMLElement, cb);
        };
    }, [target, resizeObserver, storedCallback]);

    return resizeObserver.observer;
}
