import React, { ChangeEvent, useCallback } from 'react';
import { css } from '@emotion/css';
import { FlexBox, Range } from '@vp/swan';
import { useAdjustContrast } from '../hooks';

const adjustContrastRangeStyle = css`
    width: 100%;
`;

export const AdjustContrastRange = (): JSX.Element => {
    const { threshold, setThreshold, isImageLoaded } = useAdjustContrast();

    const onRangeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setThreshold(Number(e.target.value)), []);

    return (
        <FlexBox px={{ xs: '4', sm: '12' }} mb="4" className={adjustContrastRangeStyle}>
            <Range min={1} max={100} value={threshold} onChange={onRangeChange} disabled={!isImageLoaded} />
        </FlexBox>
    );
};
