import { useScreenLayout } from '../../hooks/useScreenLayout';

/**
 * Used to wrap controls that should only appear for mobile
 */
export function MobileExperience({ children }: { children: JSX.Element }) {
    const { isSmall } = useScreenLayout();

    return isSmall ? children : null;
}
