import React from 'react';
import { observer } from 'mobx-react-lite';
import { QuickToolItem } from './QuickToolItem';
import { ShapeIcon } from '../../components/SvgIcons';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { text } from '../../utils/localization';
import { shapeStore, useShapeUtils } from '../Shapes';

export const QuickToolShape = observer((): JSX.Element | null => {
    const { openShapeModal } = shapeStore;
    const { isShapeFeatureEnable } = useShapeUtils();
    const { isSmall } = useScreenLayout();

    if (!isSmall || !isShapeFeatureEnable) {
        return null;
    }

    return (
        <QuickToolItem
            Icon={<ShapeIcon />}
            buttonLabel={text('shapes')}
            onClick={openShapeModal}
            dataTestId="quick-tool-shape"
        />
    );
});
