import React, { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

type ZoomContextType = {
    canvasZoomScale: number;
    setCanvasZoomScale: Dispatch<SetStateAction<number>>;
    canvasZoomFactor: number;
    setCanvasZoomFactor: Dispatch<SetStateAction<number>>;
};

const ZoomContext = createContext<ZoomContextType | null>(null);

export const useLayoutZoom = () => {
    const value = useContext(ZoomContext);
    if (!value) {
        throw new Error('useLayoutZoom must be used within a ZoomProvider');
    }
    return value;
};

export interface ZoomProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

export const ZoomProvider = ({ children }: ZoomProviderProps) => {
    const [canvasZoomScale, setCanvasZoomScale] = useState(1);
    const [canvasZoomFactor, setCanvasZoomFactor] = useState(1);
    const value = useMemo(() => {
        return {
            canvasZoomScale,
            setCanvasZoomScale,
            canvasZoomFactor,
            setCanvasZoomFactor,
        };
    }, [canvasZoomScale, canvasZoomFactor]);
    return <ZoomContext.Provider value={value}>{children}</ZoomContext.Provider>;
};
