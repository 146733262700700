import React, { useCallback } from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { css } from '@emotion/css';
import { FlexBox, ModalDialogBody, SelectionSet, tokens, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import FrontPanelPreview from './components/FrontPanelPreview';
import UploadButton from './components/UploadButton';
import { PanelSides } from '../../../../../../config/constant';
import { useAddItemEvents } from '../../../../../../hooks/useAddItemEvents';
import { usePanelTitle } from '../../../../../../hooks/usePanelTitle';
import { text } from '../../../../../../utils/localization';
import { BackColor, BackDesignTemplate } from '../../../../../BackDesignTemplate';
import { ACTION_NAME, PageSection } from '../../../../../TrackingEvents/constant';
import { GA_EVENTS } from '../../../../../TrackingEvents/GAEvents';
import { duplicateFrontDesign, getPanelByName, onClickUpload } from '../../helper';

const containerStyle = css`
    width: 100%;
    height: 100%;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

const selectLabelStyles = css`
    &.swan-selection-set.swan-selection-set-skin-tiles-horizontal {
        .swan-selection-set-label {
            padding: ${tokens.SwanSemSpace6} ${tokens.SwanSemSpace5};
            background: ${tokens.SwanSemColorBgStrong};
            justify-content: center;
            align-items: normal;
            height: 100%;
            min-width: auto;

            .swan-display-flex {
                width: 100%;
            }
        }
    }
`;

type Props = {
    closeModal: () => void;
    handleUploadButtonClick: () => void;
    subHeadingText: string;
};

const UploadBackDesignModalBody = observer((props: Props) => {
    const {
        cimDocStore: { panels },
        idaStore: { activeDesignPanelId },
        executeCommand,
    } = useDesignEngine();

    const { postAddItemEvents } = useAddItemEvents();

    const { getPanelTitle } = usePanelTitle();

    const { fireEvent } = useCustomEventPropagation();
    const activePanel = panels.find((panel) => panel.id === activeDesignPanelId);
    const { subHeadingText, closeModal, handleUploadButtonClick } = props;
    const backPanel = getPanelByName(panels, PanelSides.Back);
    const panelTitle = getPanelTitle(backPanel?.panelProperties.name)?.toLowerCase();
    const frontPanel = getPanelByName(panels, PanelSides.Front);

    const onUploadButtonClick = useCallback(() => {
        onClickUpload(closeModal, handleUploadButtonClick);
        fireEvent({
            name: ACTION_NAME.FLY_OUT_CLICKED,
            extraData: {
                label: GA_EVENTS.QUAD_BACK_DESIGN_FLY_OUT,
                category: PageSection.FlyOut,
                eventDetail: `modalpanel:upload:${activePanel?.panelProperties.name}`,
            },
        });
    }, [closeModal, handleUploadButtonClick, activePanel]);
    const onDuplicateFrontDesignClick = useCallback(async () => {
        await duplicateFrontDesign(frontPanel, backPanel, closeModal, executeCommand, postAddItemEvents);
        fireEvent({
            name: ACTION_NAME.FLY_OUT_CLICKED,
            extraData: {
                label: GA_EVENTS.QUAD_BACK_DESIGN_FLY_OUT,
                category: PageSection.FlyOut,
                eventDetail: `selectDuplicate:${activePanel?.panelProperties.name}`,
            },
        });
    }, [frontPanel, backPanel, closeModal, executeCommand, postAddItemEvents, activePanel, duplicateFrontDesign]);

    return (
        <ModalDialogBody pb="9" className={containerStyle}>
            <Typography fontSize="standard" mb="5" data-testid="add-back-modal-sub-header">
                {text(subHeadingText, { panelTitle })}
            </Typography>
            <SelectionSet className={selectLabelStyles} skin="tiles-horizontal" variant="single-select" mb="4">
                <FlexBox gap="4" flexDirection="row" justifyContent="space-between">
                    {frontPanel && (
                        <FrontPanelPreview
                            panel={frontPanel}
                            onDuplicateFrontDesignClick={onDuplicateFrontDesignClick}
                        />
                    )}
                    {backPanel && (
                        <UploadButton
                            panel={backPanel}
                            panelName={backPanel.panelProperties.name}
                            handleUploadButtonClick={onUploadButtonClick}
                        />
                    )}
                </FlexBox>
            </SelectionSet>
            <BackColor />
            <BackDesignTemplate />
        </ModalDialogBody>
    );
});

export default UploadBackDesignModalBody;
