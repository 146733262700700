import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';

import { useUpsellPrice } from '../providers';
import { ColorMode, SurfaceUpsell } from '../utils/upsell';

export function isBlankAndHasNoUpsells(panel: PanelState, surfaceUpsells: Record<string, SurfaceUpsell> | undefined) {
    const { name, colorMode } = panel.asJson;
    const isCanvasBlank = colorMode === ColorMode.Blank;
    return isCanvasBlank && !surfaceUpsells?.[name];
}

export function useDesignablePanels() {
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const { surfaceUpsells } = useUpsellPrice();

    return panels.filter((panel: PanelState) => !isBlankAndHasNoUpsells(panel, surfaceUpsells));
}
