import { CimDoc, DclMetadataItem } from '@design-stack-vista/cdif-types/v2';
import {
    getDclMetadataItemById,
    getItemById,
    initializeMetadata,
    isImageItem,
} from '@design-stack-vista/cimdoc-state-manager';

type ImageSharpenMetadataArguments = {
    id: string;
    appliedProcesses: string[];
};

export function changeAppliedProcess(cimdoc: CimDoc, args: ImageSharpenMetadataArguments) {
    const { id, appliedProcesses } = args;
    const item = getItemById(cimdoc, id);
    if (isImageItem(item)) {
        initializeMetadata(cimdoc, { initializeDclMetadata: true });
        const dclMetadataItem = getDclMetadataItemById(cimdoc, id);
        if (dclMetadataItem) {
            const { imageTransformations } = dclMetadataItem;
            if (imageTransformations) {
                imageTransformations.appliedProcesses = appliedProcesses;
            } else {
                dclMetadataItem.imageTransformations = { appliedProcesses };
            }
        } else {
            const newDclMetadata: DclMetadataItem = {
                id,
                imageTransformations: { appliedProcesses },
            };
            cimdoc.metadata?.dclMetadata?.push(newDclMetadata);
        }
    }
}
