import { ProductOption } from '../../../../api/designExperience';

export function mapProductOptionToDesignAttribute(designAttributeMappings: ProductOption[], productOption: string) {
    if (designAttributeMappings) {
        const possibleMapping = designAttributeMappings.find((mapping) => mapping.productOptionName === productOption);
        if (possibleMapping) {
            return possibleMapping.designAttributeName;
        }
    }
    return productOption;
}

export function mapDesignAttributeToProductOption(
    designAttributeMappings: ProductOption[],
    designAttributeName: string
) {
    if (designAttributeMappings) {
        const possibleMapping = designAttributeMappings.find(
            (mapping) => mapping.designAttributeName === designAttributeName
        );
        if (possibleMapping) {
            return possibleMapping.productOptionName;
        }
    }
    return designAttributeName;
}

export function mapDesignAttributeValueToProductOptionValue(
    designAttributeMappings: ProductOption[],
    designAttributeName: string,
    designAttributeValue: string
) {
    if (designAttributeMappings) {
        const possibleMapping = designAttributeMappings.find(
            (mapping) =>
                mapping.designAttributeName === designAttributeName &&
                mapping.designAttributeValue === designAttributeValue
        );
        if (possibleMapping) {
            return possibleMapping.productOptionValue;
        }
    }
    return designAttributeValue;
}

export function mapProductOptionValueToDesignAttributeValue(
    designAttributeMappings: ProductOption[],
    productOptionName: string,
    productOptionValue: string
) {
    if (designAttributeMappings) {
        const possibleMapping = designAttributeMappings.find(
            (mapping) =>
                mapping.productOptionName === productOptionName && mapping.productOptionValue === productOptionValue
        );
        if (possibleMapping) {
            return possibleMapping.designAttributeValue;
        }
    }
    return productOptionValue;
}
