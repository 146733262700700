import React, { useEffect } from 'react';
import { LayoutContainersProvider } from '@design-stack-vista/ida-framework';
import { DesignAreaSkeleton } from './DesignAreaSkeleton';
import { HeaderSkeleton } from './HeaderSkeleton';
import { useNotificationFramework } from '../Notification';

type LandingPageSkeletonProps = {
    initErrorMessage?: string;
};
export const LandingPageSkeleton = ({ initErrorMessage }: LandingPageSkeletonProps) => {
    const { notifyCustomer, removeAllNotifications } = useNotificationFramework();

    useEffect(() => {
        if (initErrorMessage) {
            removeAllNotifications();
            notifyCustomer(
                {
                    notificationType: 'error',
                    messageToShowCustomer: initErrorMessage,
                },
                false
            );
        }

        return () => {
            removeAllNotifications(); // clear all notification loader hide
        };
    }, [initErrorMessage]);

    return (
        <LayoutContainersProvider>
            <HeaderSkeleton />
            <DesignAreaSkeleton />
        </LayoutContainersProvider>
    );
};
