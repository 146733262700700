import { useCallback, useMemo } from 'react';
import { useAutoLoadProduct } from './useAutoLoadProduct';
import { useAutoRecognitionTracking } from './useAutoRecognitionTracking';
import { useLoadNewSize } from './useLoadNewSize';
import { FlexibilityDocuments } from '../../Flexibility/common';
import { changeSizeStore } from '../store';
import { getChangeSizeChoiceKey } from '../utils';

export const useChangeSizeModal = (changeSizeFlexibilityDocuments: FlexibilityDocuments | undefined) => {
    const { loadNewSize } = useLoadNewSize();
    const { loadAutoRecognitionProduct } = useAutoLoadProduct();
    const { trackCloseChangeSizeModal } = useAutoRecognitionTracking();
    const {
        activeSize,
        isOrientationFlexible,
        activeOrientation,
        setOpenChangeSizeModal,
        isChangeSizeModalAutoOpened,
        setIsChangeSizeModalAutoOpened,
    } = changeSizeStore;

    const activeProductChoiceKey = useMemo(() => {
        if (activeSize) {
            return getChangeSizeChoiceKey(isOrientationFlexible, activeOrientation, activeSize);
        }
    }, [activeSize, activeOrientation, isOrientationFlexible]);

    const flexibilityDocument = useMemo(() => {
        if (activeProductChoiceKey && changeSizeFlexibilityDocuments) {
            return changeSizeFlexibilityDocuments[activeProductChoiceKey];
        }
    }, [activeProductChoiceKey, changeSizeFlexibilityDocuments]);

    const onChangeSizeButtonClick = useCallback(
        async () => await loadNewSize(flexibilityDocument, activeProductChoiceKey),
        [activeProductChoiceKey, flexibilityDocument, loadNewSize]
    );

    const closeChangeSizeModal = useCallback(async () => {
        trackCloseChangeSizeModal();
        setOpenChangeSizeModal(false);
        if (isChangeSizeModalAutoOpened) {
            await loadAutoRecognitionProduct();
        }
        setIsChangeSizeModalAutoOpened(false);
    }, [isChangeSizeModalAutoOpened, loadAutoRecognitionProduct]);

    return {
        flexibilityDocument,
        closeChangeSizeModal,
        onChangeSizeButtonClick,
    };
};
