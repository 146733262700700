import React from 'react';
import { css } from '@emotion/css';
import { FlexBox, tokens } from '@vp/swan';

const teamsPromoteModalStepsItemStyle = css`
    border-radius: 50%;
    border: 1.25px solid ${tokens.SwanSemColorBorderSelected};
    height: 44px;
    width: 44px;
`;

export const TeamsPromoteModalStepsItem = ({ stepsNumber }: { stepsNumber: number }): JSX.Element => {
    return (
        <FlexBox justifyContent="center" alignItems="center" className={teamsPromoteModalStepsItemStyle}>
            <div>{stepsNumber}</div>
        </FlexBox>
    );
};
