import React from 'react';
import { SvgProps } from '../../features/quad/types';

export const CheckMarkIcon = (props: SvgProps) => {
    return (
        <svg viewBox="0 0 16 16" fill="none" {...props}>
            <path d="M0.700182 8.62614L5.15068 13.0719L15.2999 2.92969" stroke="#0A806B" strokeWidth="1.2" />
        </svg>
    );
};
