import React, { useCallback } from 'react';
import { FlexBox, Typography } from '@vp/swan';
import { BestFitTag } from './BestFitTag';
import { SizePrice } from './SizePrice';
import { DesktopExperience } from '../../../components/Experiences';
import { ProductAttributes } from '../../../config/constant';
import { useTranslatedConfig } from '../../../hooks/useTranslatedConfig';
import { OptionButton } from '../../Flexibility/common';

interface SizeItemProps {
    size: string;
    activeSize: string;
    onClick: (size: string) => void;
    isBestFit?: boolean;
}

export const SizeItem = ({ size, activeSize, onClick, isBestFit }: SizeItemProps) => {
    const { getTranslatedOptionName } = useTranslatedConfig();
    const label = getTranslatedOptionName(ProductAttributes.Size, size);

    const onOptionClick = useCallback(() => {
        onClick(size);
    }, [size, onClick]);

    return (
        <OptionButton selected={size === activeSize} onClick={onOptionClick} testId={label.toLowerCase()}>
            {isBestFit && (
                <DesktopExperience>
                    <BestFitTag />
                </DesktopExperience>
            )}
            <FlexBox flexDirection="column" alignItems="flex-start" className="button-content" gap="2">
                <Typography fontWeight="bold" fontSize="small" noWrap={true}>
                    {label}
                </Typography>
                <SizePrice size={size} />
            </FlexBox>
        </OptionButton>
    );
};
