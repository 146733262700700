import React from 'react';
import { css } from '@emotion/css';
import { className, FlexBox, Spinner, tokens } from '@vp/swan';
import { LoaderSize } from './constant';
import { text } from '../../utils/localization';

interface Props extends React.SVGProps<SVGSVGElement> {
    accessibilityLabel?: string;
    size?: LoaderSize;
    testId?: string;
    showText?: boolean;
    transparent?: boolean;
    styling?: string;
}

const getParentLoaderStyle = (transparent: boolean) => css`
    position: absolute;
    z-index: 9;
    background: ${transparent ? 'transparent' : 'rgba(248, 248, 248, 0.6)'};
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

const getLoaderStyle = (transparent: boolean) => css`
    border-radius: ${tokens.SwanSemBorderRadiusSubtle};
    min-width: ${transparent ? undefined : '120px'};
`;

export function Loader(props: Props) {
    const {
        accessibilityLabel = text('loadingLabel'),
        children,
        size = LoaderSize.STANDARD,
        testId,
        showText = true,
        transparent = false,
        styling,
    } = props;

    return (
        <FlexBox
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={className(getParentLoaderStyle(transparent), styling)}
            data-testid={testId}
        >
            <FlexBox
                flexDirection="column"
                p={transparent ? '0' : '4'}
                backgroundColor={transparent ? 'transparent' : 'standard'}
                gap="4"
                className={getLoaderStyle(transparent)}
            >
                <Spinner size={size} accessibleText={accessibilityLabel} showText={showText} />
                {children && <div className="route-loader-children">{children}</div>}
            </FlexBox>
        </FlexBox>
    );
}
