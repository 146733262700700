import { isSherbertAssetUrl } from '@design-stack-ct/assets-sdk';
import { fetchWrapper } from '../utils';

const SHERBERT_URL_V3 = 'https://api.sherbert.cimpress.io/v3';

interface ImageInfo {
    isError: boolean;
    format: string;
    height: number;
    width: number;
}

export const getImageInfo = async (assetId: string, authorizationHeader: string): Promise<ImageInfo | undefined> => {
    const url = `${SHERBERT_URL_V3}/assets/${assetId}/info/image`;

    if (assetId && isSherbertAssetUrl(url)) {
        const response = await fetchWrapper(url, {
            method: 'GET',
            requestInitiator: 'getImageInfo',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: authorizationHeader,
            },
        });

        return response;
    }
};

export const getPdfInfo = async (assetId: string, authorizationHeader: string, pageNum: number) => {
    const url = `${SHERBERT_URL_V3}/assets/${assetId}/page_${pageNum}/info/image/`;

    if (assetId && isSherbertAssetUrl(url)) {
        const response = await fetchWrapper(url, {
            method: 'GET',
            requestInitiator: 'getPdfInfo',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: authorizationHeader,
            },
        });

        return response;
    }
};

export const getImageColorInfo = async (
    asset: { assetId: string; imageURL: string },
    authorizationHeader: string,
    signal?: AbortSignal
) => {
    const { assetId, imageURL } = asset;
    const url = `${SHERBERT_URL_V3}/assets/${assetId}/info/image/colors`;

    if (assetId && isSherbertAssetUrl(url)) {
        const response = await fetchWrapper(url, {
            method: 'GET',
            requestInitiator: 'getImageColorInfo',
            data: { imageURL },
            signal,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: authorizationHeader,
            },
        });

        return response;
    }
};
