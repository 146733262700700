/**
 * https://github.com/darkskyapp/string-hash/blob/master/index.js
 * @param str
 */
export function hash(str: string): string {
    let val = 5381;
    let i = str.length;

    while (i) {
        val = (val * 33) ^ str.charCodeAt((i += -1));
    }

    /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
     * integers. Since we want the results to be always positive, convert the
     * signed int to an unsigned by doing an unsigned bitshift. */
    return (val >>> 0).toString();
}
