import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { usePremiumFinishStore } from '../../providers';
import { removePremiumFinishReviewInstruction } from '../quad/commands/removePremiumFinishReviewInstruction';

export const useResetReviewInstructions = () => {
    const {
        cimDocStore: { panels },
        executeCommand,
    } = useDesignEngine();
    const { isPremiumFinishProduct } = usePremiumFinishStore();

    const handlePremiumFinishReviewInstructions = (panel: PanelState) => {
        if (panel.id === panels[0].id && isPremiumFinishProduct) {
            executeCommand((cimDoc) => {
                removePremiumFinishReviewInstruction(cimDoc);
            }, {});
        }
    };

    return {
        handlePremiumFinishReviewInstructions,
    };
};
