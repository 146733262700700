import { fetchWrapper } from '../utils';

export interface CompatibleOptionsEntry {
    name: string;
    values: string[];
}

enum OptionValueType {
    numberLiteral = 'numberLiteral',
    stringLiteral = 'stringLiteral',
    range = 'range',
}

interface RangeValue {
    minimum: string;
    maximum?: string;
    increment?: string;
}

interface OptionValue {
    type: OptionValueType;
    [OptionValueType.numberLiteral]?: string;
    [OptionValueType.stringLiteral]?: string;
    [OptionValueType.range]?: RangeValue;
}

export interface Option {
    buckets: string[];
    key: string;
    type: string;
    values: OptionValue[];
}

const PRODUCT_SELECTOR_URL = 'https://selector-api.products.cimpress.io';
const PRODUCT_ORCHESTRATION_URL = 'https://merchant-orchestration-api.products.cimpress.io';

export async function getProductSupportedAttributes(
    productKey: string,
    studioSelectedProductOptions: Record<string, string>,
    productVersion: number,
    authHeader: string,
    signal?: AbortSignal
): Promise<CompatibleOptionsEntry[]> {
    const response = await fetchWrapper(
        `${PRODUCT_SELECTOR_URL}/v1/products/${productKey}/versions/${productVersion}:validate`,
        {
            method: 'POST',
            requestInitiator: 'getProductSupportedAttributes',
            signal,
            body: {
                selections: studioSelectedProductOptions,
            },
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: authHeader,
            },
        }
    );

    const remainingOptions = response?.remainingOptions ?? [];
    return formatDataToCompatibleOptions(remainingOptions);
}

const formatDataToCompatibleOptions = (options: Option[]) => {
    const compatibleOptions: CompatibleOptionsEntry[] = options.map((option) => ({
        name: option.key,
        values: option.values
            .map((value) => value[value.type])
            .filter((value) => typeof value === 'string') as string[],
    }));

    return compatibleOptions;
};

export async function getCurrentVersion(productKey: string, authHeader: string, signal?: AbortSignal) {
    const response = await fetchWrapper(`${PRODUCT_ORCHESTRATION_URL}/v3/products/${productKey}/versions/current`, {
        method: 'GET',
        requestInitiator: 'getCurrentVersion',
        headers: {
            Authorization: authHeader,
        },
        signal,
    });

    return response;
}
