import { useCallback } from 'react';
import { CimDoc } from '@design-stack-vista/cdif-types';
import { useDesignEngine } from '@design-stack-vista/core-features';
import {
    addPlaceholderMetadataToCimDoc,
    isUnreplacedPlaceholderImage,
} from '@design-stack-vista/studio-document-metadata-management';
import cloneDeep from 'lodash.clonedeep';

export const useGetDesignDocument = () => {
    const designEngine = useDesignEngine();

    const getDocumentWithoutPlaceholders = useCallback(() => {
        let newDocument = cloneDeep(designEngine.cimDocStore.asJson);
        newDocument = removeUnreplacedPlaceholdersFromCimDoc(newDocument);
        return newDocument;
    }, [designEngine]);

    return { getDocumentWithoutPlaceholders };
};

const removeUnreplacedPlaceholdersFromCimDoc = (cimDoc: CimDoc) => {
    let newCimdoc = addPlaceholderMetadataToCimDoc(cimDoc);
    newCimdoc = cloneDeep(newCimdoc);
    const placeholderImageIds = (newCimdoc.metadata?.template?.filter(isUnreplacedPlaceholderImage) ?? []).map(
        (imageTemplate) => imageTemplate.id
    );
    newCimdoc.document.panels.forEach((panel) => {
        if (panel.images?.length) {
            panel.images = panel.images.filter((image) => !placeholderImageIds.includes(image.id)) ?? [];
        }
    });
    return newCimdoc;
};
