/**
 *
 * Documentation - {@link https://vistaprint.atlassian.net/wiki/spaces/DIYP/pages/3211460624/Integration+of+ImageMind+API}
 */

import { textDetect } from '@design-stack-ct/image-mind-client';
import { Angle, Directions, ImageOrientations } from '../constants';
import { ProcessedImageMindInfo } from '../types';

enum TextOrientations {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
}

const clockwiseMetrics = {
    [ImageOrientations.Horizontal]: {
        orientation: ImageOrientations.Horizontal,
        direction: Directions.NoRotation,
        angle: Angle.Zero,
    },
    [ImageOrientations.Vertical]: {
        orientation: ImageOrientations.Vertical,
        direction: Directions.NoRotation,
        angle: Angle.Zero,
    },
    [ImageOrientations.HorizontalRotation]: {
        orientation: ImageOrientations.Horizontal,
        direction: Directions.AntiClockwise,
        angle: Angle.Ninety,
    },
    [ImageOrientations.VerticalRotation]: {
        orientation: ImageOrientations.Vertical,
        direction: Directions.AntiClockwise,
        angle: Angle.Ninety,
    },
};

const antiClockwiseMetrics = {
    [ImageOrientations.Horizontal]: {
        orientation: ImageOrientations.Horizontal,
        direction: Directions.Clockwise,
        angle: Angle.HundredEighty,
    },
    [ImageOrientations.Vertical]: {
        orientation: ImageOrientations.Vertical,
        direction: Directions.Clockwise,
        angle: Angle.HundredEighty,
    },
    [ImageOrientations.HorizontalRotation]: {
        orientation: ImageOrientations.Horizontal,
        direction: Directions.Clockwise,
        angle: Angle.Ninety,
    },
    [ImageOrientations.VerticalRotation]: {
        orientation: ImageOrientations.Vertical,
        direction: Directions.Clockwise,
        angle: Angle.Ninety,
    },
};

const calculateOrientation = (height: number, width: number) => {
    if (height > width) {
        return ImageOrientations.Vertical;
    }
    return ImageOrientations.Horizontal;
};

const getTextSpinData = (textSpin: Directions, orientation: ImageOrientations): ProcessedImageMindInfo => {
    let detectedOrientation: ProcessedImageMindInfo = {
        orientation: orientation,
        direction: Directions.NoRotation,
        angle: Angle.Zero,
    };

    if (textSpin === Directions.Clockwise) {
        detectedOrientation = clockwiseMetrics[orientation];
    }

    if (textSpin === Directions.AntiClockwise) {
        detectedOrientation = antiClockwiseMetrics[orientation];
    }

    return detectedOrientation;
};

export const getProcessedImageMindInfo = async (
    imageUrl: string,
    authorizationHeader: string
): Promise<ProcessedImageMindInfo | undefined> => {
    const imageData = await textDetect({ imageUrl, headers: { Authorization: authorizationHeader } });

    if (!imageData) {
        return undefined;
    }

    const { height, width } = imageData.imageSize;

    // in case of detectedTextOrientation being undefined it will return the orientation based on normal height and width calculation
    if (!imageData.detectedTextOrientation) {
        return {
            orientation: calculateOrientation(height, width),
            direction: Directions.NoRotation,
            angle: Angle.Zero,
        };
    }

    const textOrientation: string = imageData.detectedTextOrientation.maxOccuranceBased.orientation;
    const textSpin: Directions = imageData.textSpin as Directions;

    if (textOrientation === TextOrientations.Horizontal) {
        if (height > width) {
            return getTextSpinData(textSpin, ImageOrientations.Vertical);
        }

        return getTextSpinData(textSpin, ImageOrientations.Horizontal); // in case of height === width it should return horizontal
    }

    if (textOrientation === TextOrientations.Vertical) {
        if (width > height) {
            return getTextSpinData(textSpin, ImageOrientations.VerticalRotation);
        }

        return getTextSpinData(textSpin, ImageOrientations.HorizontalRotation); // in case of height === width it should return horizontal
    }

    // in case of TextDetect returning 'inBetween' it will return the orientation based on normal height and width calculation
    return { orientation: calculateOrientation(height, width), direction: Directions.NoRotation, angle: Angle.Zero };
};
