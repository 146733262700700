import { useCallback, useMemo } from 'react';
import { useLoadNewDecorationArea } from './useLoadNewDecorationArea';
import { FlexibilityDocuments } from '../../Flexibility/common';
import { decorationAreaStore } from '../store';

export const useDecorationAreaModal = (decorationAreaFlexibilityDocuments: FlexibilityDocuments | undefined) => {
    const { activeDecorationArea, setOpenDecorationAreaModal } = decorationAreaStore;
    const { loadNewDecorationArea } = useLoadNewDecorationArea();

    const flexibilityDocument = useMemo(() => {
        if (activeDecorationArea && decorationAreaFlexibilityDocuments) {
            return decorationAreaFlexibilityDocuments[activeDecorationArea];
        }
    }, [activeDecorationArea, decorationAreaFlexibilityDocuments]);

    const onChangeDecorationAreaClick = useCallback(
        async () => await loadNewDecorationArea(flexibilityDocument),
        [flexibilityDocument, loadNewDecorationArea]
    );

    const closeDecorationAreaModal = useCallback(() => {
        setOpenDecorationAreaModal(false);
    }, []);

    return {
        flexibilityDocument,
        onChangeDecorationAreaClick,
        closeDecorationAreaModal,
    };
};
