import { useScreenClass } from '@vp/swan';

/**
 * @note By default, it will match behavior of useScreeClass, resulting in: `{ isSmall: true, isMedium: false, isLarge: false }`
 * See https://vista.design/swan/components/responsive-system?tab=Develop#useIsScreenClassInitialized
 */
export function useScreenLayout() {
    const screenClass = useScreenClass();

    const [isSmall, isMedium] = [screenClass === 'xs', screenClass === 'sm'];

    return {
        isSmall,
        isMedium,
        isLarge: !isSmall && !isMedium,
    };
}
