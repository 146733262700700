import { windowExists } from './envUtils';
import { localStorageUtility } from './localstorage';
import QueryStringUtils, { removeQueryParam } from './queryStringUtils';
import { FlagType } from '../features/Debug/FlagsDebugTool';
import { DEBUG_FLAG_PREFIX } from '../features/Debug/useDebugFlag';

const VP_QUAD_DEBUG_MODE = 'vp-quad-debug-mode';
const debugParamKey = 'debugMode';

export function isDebugMode() {
    const { getQuadConfig, addQuadConfig, removeQuadConfig } = localStorageUtility;
    if (!windowExists()) {
        return false;
    }
    const debugParamValue = QueryStringUtils.getValueByKey(debugParamKey);

    if (debugParamValue === 'false' || debugParamValue === '0') {
        removeQuadConfig(VP_QUAD_DEBUG_MODE);
    }

    if (debugParamValue === 'true' || debugParamValue === '1') {
        addQuadConfig(VP_QUAD_DEBUG_MODE, 'true');
    }

    return getQuadConfig(VP_QUAD_DEBUG_MODE) === 'true';
}

export function disableDebugMode() {
    const { removeQuadConfig } = localStorageUtility;
    removeQuadConfig(VP_QUAD_DEBUG_MODE);
    window.location.href = removeQueryParam(window.location.href, debugParamKey);
}

export function toggleFlag(flagName: FlagType) {
    const { getQuadFlags, removeQuadFlag, addQuadFlags } = localStorageUtility;
    const localStorageFlagKey = DEBUG_FLAG_PREFIX + flagName;
    if (getQuadFlags(localStorageFlagKey) === 'true') {
        removeQuadFlag(localStorageFlagKey);
    } else {
        addQuadFlags(localStorageFlagKey, 'true');
    }
}
