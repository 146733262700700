import { useCallback, useEffect, useState } from 'react';
import { useNotificationFramework } from '../../../components/Notification';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useWorkEntityService } from '../../../hooks/useWorkEntityService';
import { useFullScreenLoader, useProductParams } from '../../../providers';
import { useSaveWork } from '../../../providers/SaveWorkProvider';
import { text } from '../../../utils/localization';

export const useContinueButtonVCS = () => {
    const { productData } = useProductConfiguration();
    const { notifyCustomer } = useNotificationFramework();
    const { saveCurrentProgress } = useWorkEntityService();
    const { setFullScreenLoaderData } = useFullScreenLoader();
    const { returnPath } = useProductParams();
    const { lastWorkEntity, isWorkSaved } = useSaveWork();

    const [shouldContinue, setShouldContinue] = useState(false);

    useEffect(() => {
        if (!isWorkSaved || !shouldContinue) {
            return;
        }
        const qty = (lastWorkEntity?.merchandising.quantity ?? 0).toString();
        if (productData) {
            productData.customerSelectedOptions['Quantity'] = qty;

            const redirectionUrl = new URL(`${window.location.origin}/${returnPath}`);
            redirectionUrl.searchParams.set('key', productData?.productKey as string);
            redirectionUrl.searchParams.set('version', productData?.productVersion.toString() as string);
            redirectionUrl.searchParams.set('selectedAttributes', JSON.stringify(productData?.customerSelectedOptions));
            redirectionUrl.searchParams.set('docRef', `${lastWorkEntity?.design.designUrl}/docref`);
            redirectionUrl.searchParams.set('workId', lastWorkEntity?.workId as string);
            redirectionUrl.searchParams.set('qty', qty);
            window.location.href = redirectionUrl.toString();
        }
    }, [productData, lastWorkEntity, shouldContinue, isWorkSaved]);

    const handleFinishForVCS = useCallback(async () => {
        if (!returnPath.length) {
            notifyCustomer(
                {
                    notificationType: 'error',
                    // TODO: need to localize this later.
                    messageToShowCustomer: 'Preview page URL not mentioned.',
                },
                true
            );
            return;
        }

        setFullScreenLoaderData({
            showLoader: true,
            loaderMessage: text('designReadyLabel'),
        });

        await saveCurrentProgress();
        setShouldContinue(true);
    }, [saveCurrentProgress, productData]);

    return {
        handleFinishForVCS,
    };
};
