import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Popover, PopoverLauncher } from '@vp/swan';
import { FinishPopoverContent } from './FinishPopoverContent';
import { FinishButton } from '../FinishButton';

interface Props {
    finishIconDisabled: boolean;
    textSelection: string;
    onFinishIconClick: () => void;
    onPopoverDeleteButtonClick: () => void;
    showPropoverContent: boolean;
    showExploreFinishPopover: boolean;
    hideExplorePopover: () => void;
}

export const FinishPopover = ({
    finishIconDisabled,
    textSelection,
    onFinishIconClick,
    onPopoverDeleteButtonClick,
    showExploreFinishPopover,
    hideExplorePopover,
    showPropoverContent,
}: Props) => {
    const onFinishButtonClick = useCallback(() => {
        if (showExploreFinishPopover) {
            onFinishIconClick();
            hideExplorePopover();
        }
    }, []);

    /**
     * It's swan recommended way to launch popover content automatically.
     * https://v2-vds-site.prod.merch.vpsvc.com/swan/components/popover/?tab=Usage#Expanded
     */
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    useEffect(() => {
        buttonRef.current?.click();
    }, []);

    return (
        <Popover data-testid="premium-finish-launcher">
            <PopoverLauncher>
                <div>
                    <Button ref={buttonRef} display="none" />
                    <FinishButton onClick={onFinishButtonClick} finishIconDisabled={finishIconDisabled} />
                </div>
            </PopoverLauncher>
            {showPropoverContent && (
                <FinishPopoverContent
                    finishIconDisabled={finishIconDisabled}
                    textSelection={textSelection}
                    onFinishIconClick={onFinishIconClick}
                    onPopoverDeleteButtonClick={onPopoverDeleteButtonClick}
                    hideExplorePopover={hideExplorePopover}
                    shouldShowExploreFinish={showExploreFinishPopover}
                />
            )}
        </Popover>
    );
};
