import React from 'react';
import { CustomEventPropagationProvider } from '@design-stack-vista/custom-event-propagation';
import { APPLICATION_NAME } from '../../../config/constant';
import { TrackingContext } from '../../TrackingEvents/TrackingProvider';

interface TrackingProviderProps {
    children: React.ReactNode | React.ReactNode[];
}

export const TrackingProvider = ({ children }: TrackingProviderProps) => {
    return (
        <TrackingContext.Provider value={null}>
            <CustomEventPropagationProvider name={`vcs-${APPLICATION_NAME}`}>{children}</CustomEventPropagationProvider>
        </TrackingContext.Provider>
    );
};
