import React, { useCallback, useEffect } from 'react';
import { useDesignEngine, useFocus, useSelectedItems, useZoom } from '@design-stack-vista/core-features';
import { observer } from 'mobx-react-lite';
import { preciseNumber } from './utils';
import { ZoomControlButtons } from './ZoomControlButtons';
import { Zoom } from '../../config/constant';
import { useLayoutZoom } from '../../providers/ZoomProvider';

export const CanvasZoomControls = observer(() => {
    const {
        layoutStore: { initialZoom, zoom },
        idaStore: { activeDesignPanelId },
    } = useDesignEngine();
    const { zoomTo } = useZoom();
    const currentZoomFactor = zoom / initialZoom;
    const { canvasZoomFactor, setCanvasZoomFactor } = useLayoutZoom();
    const { selectedItems } = useSelectedItems();
    const { focusDesignArea } = useFocus();

    useEffect(() => {
        // Fixes a problem where zoom keeps focus on selection on the canvas
        //  Tried to use a ref to accomplish this, but it wasn't working
        const elements = document?.getElementsByClassName('zoom-toolbar-thumb');
        if (elements?.length > 0) {
            (elements[0] as HTMLDivElement).blur();
        }
    }, [selectedItems]);

    const handleZoom = useCallback(
        (zoomFactor: number) => {
            const closeZoomMultiple = preciseNumber(Math.round(zoomFactor / Zoom.MODIFY_VALUE) * Zoom.MODIFY_VALUE);
            if (closeZoomMultiple > Zoom.MAX_VALUE || closeZoomMultiple < Zoom.MIN_VALUE) {
                return;
            }
            setCanvasZoomFactor(closeZoomMultiple);
            zoomTo(closeZoomMultiple * initialZoom);
            activeDesignPanelId && focusDesignArea(activeDesignPanelId, { changeZoom: false });
        },
        [activeDesignPanelId, initialZoom]
    );

    const handleZoomIn = useCallback(() => {
        const increaseZoomFactor = preciseNumber(canvasZoomFactor + Zoom.MODIFY_VALUE);
        handleZoom(increaseZoomFactor);
    }, [canvasZoomFactor, handleZoom]);

    const handleZoomOut = useCallback(() => {
        const decreaseZoomFactor = preciseNumber(canvasZoomFactor - Zoom.MODIFY_VALUE);
        handleZoom(decreaseZoomFactor);
    }, [canvasZoomFactor, handleZoom]);

    useEffect(() => {
        setCanvasZoomFactor(zoom / initialZoom);
    }, [currentZoomFactor, setCanvasZoomFactor]);
    return (
        <ZoomControlButtons
            zoomLevel={Math.round(canvasZoomFactor * Zoom.SCALE_VALUE)}
            onZoomIn={handleZoomIn}
            onZoomOut={handleZoomOut}
        />
    );
});
