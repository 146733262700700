import { useCallback } from 'react';
import { useProductPageViewed } from './useProductPageViewed';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { isGAProduct, sendPageTracking } from '../Analytics';
import { useTrackingAnalytics } from '../TrackingProvider';
import { AnalyticsPageData } from '../type';
import { getAnalyticsPageData } from '../utils';

export const useTrackNewPage = () => {
    const { productData } = useProductConfiguration();
    const trackingAnalytics = useTrackingAnalytics();
    const { trackProductView } = useProductPageViewed();

    const trackPage = useCallback(
        (page?: string) => {
            if (productData?.productKey && productData?.mpvId) {
                const trackingPage = page ?? null;
                trackingAnalytics?.setTrackingPage(trackingPage);
                const pageInfo: AnalyticsPageData = getAnalyticsPageData(productData.mpvId, trackingPage);
                if (isGAProduct(productData.productKey)) {
                    sendPageTracking(pageInfo);
                }
            }
        },
        [productData?.productKey, productData?.mpvId]
    );

    const trackNewPage = useCallback(
        (page?: string) => {
            trackPage(page);
            trackProductView();
        },
        [trackPage, trackProductView]
    );

    return { trackPage, trackNewPage };
};
