import React from 'react';
import { css } from '@emotion/css';
import { StudioHeader } from '@vp/diy-editor-ui';
import { tokens, tokensRaw } from '@vp/swan';
import { HeaderLeftChildren } from './HeaderLeftChildren';
import { HeaderMobileLeftChildren } from './HeaderMobileLeftChildren';
import { HeaderRightChildren } from './HeaderRightChildren';
import { VistaLogo } from './VistaLogo';
import { FinishButton } from '../../../features/UploadPanel/FinishButton';
import { Header as VcsHeader } from '../../../features/VCS/components/Header/Header';
import { isVCSConsumer } from '../../../features/VCS/utils/isVCSConsumer';
import { useScreenLayout } from '../../../hooks/useScreenLayout';

export const headerStyle = css`
    &._header_axrz6_10 {
        z-index: ${tokens.SwanSemZIndexRaised};
        height: 80px;
        @media only screen and (max-width: ${tokensRaw.SwanBaseBreakpointXsEnd}) {
            height: 60px;
        }
    }
`;

export function QuadHeader() {
    const isVCSHeader = isVCSConsumer();
    const { isSmall } = useScreenLayout();

    return (
        <>
            {isVCSHeader ? (
                <VcsHeader mobileRightChildren={isSmall && <FinishButton />} className={headerStyle} />
            ) : (
                <StudioHeader
                    px={{ xs: 5, sm: 6 }}
                    py={{ xs: 3, sm: 5 }}
                    className={headerStyle}
                    leadingNode={<HeaderLeftChildren />}
                    trailingNode={<HeaderRightChildren />}
                    leadingNodeSmallScreen={<HeaderMobileLeftChildren />}
                    trailingNodeSmallScreen={isSmall && <FinishButton />}
                    logoSmallScreen={<VistaLogo />}
                />
            )}
        </>
    );
}
