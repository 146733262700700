import React from 'react';
import { css } from '@emotion/css';
import { className, FlexBox, tokens, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DesignPreviewLoader } from './DesignPreviewLoader';
import { SparkleIcon } from '../../components/SvgIcons';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { usePremiumFinishStore } from '../../providers';
import { usePreview } from '../../providers/PreviewProvider';
import { text } from '../../utils/localization';
import { useWaitForInstantUpload } from '../InstantUpload';
import { ThreeDPreview, ThreeDPreviewController, ThreeDPreviewInstance } from '../ThreeDPreview';
import { TwoDPreview, TwoDPreviewController, TwoDPreviewInstance } from '../TwoDPreview';

const previewWindowStyle = css`
    position: relative;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
`;

const wrapperStyles = css`
    position: relative;
`;

const disclaimerContainer = css`
    position: absolute;
    bottom: 0;
    border-radius: 8px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
`;

const getPreviewControllerStyle = (isSmall: boolean, showBorderForSwitcher: boolean) => css`
    height: ${isSmall ? '66px' : '70px'};
    ${!isSmall && showBorderForSwitcher ? `border-top: 1px solid ${tokens.SwanSemColorBorderStandard}` : ''};
`;
interface DesignPreviewProps {
    style?: string;
    showBorderForSwitcher?: boolean;
}

export const DesignPreview = observer(({ style, showBorderForSwitcher = true }: DesignPreviewProps) => {
    const { is3DView } = usePreview();
    const { isSmall: isMobileDevice } = useScreenLayout();
    const { isPremiumFinishProduct } = usePremiumFinishStore();
    const { isInstantUploadProcessing } = useWaitForInstantUpload();
    const showFinishDisclaimer = !isMobileDevice && isPremiumFinishProduct;

    const preview = is3DView ? <ThreeDPreview /> : <TwoDPreview />;

    return (
        <ThreeDPreviewInstance>
            <TwoDPreviewInstance>
                <FlexBox justifyContent="center" alignItems="center" flexDirection="column" className={wrapperStyles}>
                    <FlexBox
                        justifyContent="center"
                        alignItems="center"
                        className={className(previewWindowStyle, style)}
                    >
                        {isInstantUploadProcessing ? <DesignPreviewLoader testId="design-preview-loader" /> : preview}
                    </FlexBox>
                    {showFinishDisclaimer && (
                        <FlexBox px="6" py="5" mb="5" backgroundColor="standard" className={disclaimerContainer}>
                            <SparkleIcon width={16} height={16} />
                            <Typography ml="4" fontSize="small">
                                {text('finishDisclaimer')}
                            </Typography>
                        </FlexBox>
                    )}
                </FlexBox>
                <FlexBox
                    pt="6"
                    alignItems="center"
                    className={getPreviewControllerStyle(isMobileDevice, showBorderForSwitcher)}
                >
                    {is3DView ? <ThreeDPreviewController /> : <TwoDPreviewController />}
                </FlexBox>
            </TwoDPreviewInstance>
        </ThreeDPreviewInstance>
    );
});
