import React from 'react';
import { css } from '@emotion/css';
import { VortexActions } from '@rendering/vortex-react';
import { FlexBox } from '@vp/swan';
import { ThreeDPreviewActionButton } from './ThreeDPreviewActionButton';
import { useScreenLayout } from '../../hooks/useScreenLayout';

const getContainerStyle = (isSmall: boolean) => css`
    width: 100%;
    ${isSmall &&
    `
        padding:0;
        border: none;
    `}
    button {
        text-transform: capitalize;
    }
`;

export const ThreeDPreviewController = (): JSX.Element => {
    const { isSmall } = useScreenLayout();

    return (
        <FlexBox justifyContent="center" alignItems="center" className={getContainerStyle(isSmall)}>
            <VortexActions cameraViews={true} animations={true}>
                {({ actions, actionRunning }) =>
                    actions.length > 0 && <ThreeDPreviewActionButton actions={actions} actionRunning={actionRunning} />
                }
            </VortexActions>
        </FlexBox>
    );
};
