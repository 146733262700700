import { addQueryParam } from '../../../utils';

type UpdateUrlProps = {
    key: string;
    productVersion: string;
    mpvId: string;
    selectedOptions: string;
    workId?: string;
    template?: string;
};

export function updateUrl(props: UpdateUrlProps) {
    Object.entries(props).forEach(([key, val]) => {
        window.history.replaceState('update-url', '', addQueryParam(window.location.href, key, val));
    });
}
