import React from 'react';
import { SelectionSet, SelectionSetLabel, SkeletonText, SkeletonTextLine, Typography } from '@vp/swan';
import { text } from '../../../utils/localization';
import { NUM_OF_LOADER } from '../constant';
import { selectLabelStyles } from '../style';

export const DesignTemplateSkeleton = () => {
    const skeletonItems = Array.from(Array(NUM_OF_LOADER).keys());

    return (
        <>
            <Typography fontWeight="bold" fontSize="large" mt="8" mb="4">
                {text('recommendations')}
            </Typography>
            <SelectionSet className={selectLabelStyles} skin="tiles-horizontal" variant="single-select">
                {skeletonItems.map((item) => (
                    <SelectionSetLabel key={`template-skeleton-${item}`}>
                        <SkeletonText style={{ width: '100%', height: '200px' }}>
                            <SkeletonTextLine style={{ height: '100%' }}></SkeletonTextLine>
                        </SkeletonText>
                    </SelectionSetLabel>
                ))}
            </SelectionSet>
        </>
    );
};
