import React from 'react';
import { Buttonbar, ButtonbarButton, ButtonbarButtonLabel, Typography } from '@vp/swan';
import { use2DPreviewSwitchers } from '../../DesignPreview/use2DPreviewSwitchers';

export const DesignPreviewButton = () => {
    const { selectedValue, handleSelection, switcherConfig } = use2DPreviewSwitchers();

    return (
        <Buttonbar variant="single-select" selectedValue={selectedValue} onSelectedValueChange={handleSelection}>
            {Object.keys(switcherConfig).map((key) => {
                return (
                    <ButtonbarButton key={key} value={key} data-testid={`2D-preview-switcher-${key}`}>
                        <ButtonbarButtonLabel>
                            <Typography fontSize="small">{switcherConfig[key]}</Typography>
                        </ButtonbarButtonLabel>
                    </ButtonbarButton>
                );
            })}
        </Buttonbar>
    );
};
