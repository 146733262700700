import React from 'react';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import { FlexBox, tokens } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DefaultQuickToolItems } from './DefaultQuickToolItems';
import { QuickToolItems } from './QuickToolItems';
import { MobileExperience } from '../../components/Experiences';

import { HorizontalScroller } from '../Scroller/HorizontalScroller';

const quickToolStyle = css`
    position: absolute;
    width: 100%;
    bottom: 0;
    border-top: 1px solid ${tokens.SwanSemColorBorderStandard};
    overflow: hidden;
`;

export const QuickToolsMobile = observer(() => {
    const {
        layoutStore: { visibleItems },
    } = useDesignEngine();

    const haveItems = visibleItems.length > 0;

    return (
        <MobileExperience>
            <FlexBox alignItems="center" p="3" backgroundColor="standard" className={quickToolStyle}>
                <HorizontalScroller>
                    <DefaultQuickToolItems />
                    {haveItems && <QuickToolItems />}
                </HorizontalScroller>
            </FlexBox>
        </MobileExperience>
    );
});
