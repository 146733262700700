/**
 * Reference - {@link https://gitlab.com/Cimpress-Technology/artwork-design/nitro/packaging-advanced/-/blob/version-5/packages/packaging-advanced/src/utility/controlsLayer/getItemPositionFitOnRotation.ts}
 */
import { UnitlessTransforms } from '@design-stack-ct/utility-core';
export type UnitlessBox = Omit<UnitlessTransforms, 'rotation'>;

import { getItemDimensionFitOnRotation } from './getItemDimensionFitOnRotation';

export function getItemPositionFitOnRotation(
    targetBoundingBox: UnitlessBox,
    itemAspectRatio: number,
    contentOrientationAngle: number,
    paddingPercentage = 0
): UnitlessBox {
    const widthAfterPadding = targetBoundingBox.width * (1 - paddingPercentage / 100);
    const heightAfterPadding = targetBoundingBox.height * (1 - paddingPercentage / 100);
    const { width, height } = getItemDimensionFitOnRotation({
        targetDimensions: { width: widthAfterPadding, height: heightAfterPadding },
        itemAspectRatio,
        contentOrientationAngle: Number(contentOrientationAngle),
    });

    const x = targetBoundingBox.x + (targetBoundingBox.width - width) / 2;
    const y = targetBoundingBox.y + (targetBoundingBox.height - height) / 2;

    return {
        width,
        height,
        x,
        y,
    };
}
