import React from 'react';
import { css } from '@emotion/css';
import { Button, ModalDialogBody, ModalDialogButtons, ModalDialogFooter, Spinner, tokens, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { StandardModal } from '../../../components/Modal/components/StandardModal';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { text } from '../../../utils/localization';
import { useWaitForInstantUpload } from '../../InstantUpload';

const desktopModalStyle = css`
    .swan-modal-dialog {
        width: 570px;
        .swan-modal-dialog-header {
            margin: 0;
        }
        .swan-modal-dialog-body + .swan-modal-dialog-footer {
            margin-top: ${tokens.SwanSemSpace6};
        }
        .swan-modal-dialog-buttons .swan-button {
            margin: 0;
        }
    }
`;

// This may not be required after upgrading to swan 3.VL
const mobileModalStyle = css`
    .swan-modal-dialog.swan-modal-dialog-panel.swan-modal-dialog-panel-bottom {
        border-radius: ${tokens.SwanSemBorderRadiusContainer} ${tokens.SwanSemBorderRadiusContainer} 0 0;
    }
`;

interface DeleteFinishConfirmationModalProps {
    showModal: boolean;
    defaultPremiumFinish: string;
    onDelete: () => Promise<void>;
    onCancel: () => void;
}

export const DeleteFinishConfirmationModal = observer(
    ({ onCancel, onDelete, showModal, defaultPremiumFinish }: DeleteFinishConfirmationModalProps) => {
        const { isSmall } = useScreenLayout();
        const { isInstantUploadProcessing } = useWaitForInstantUpload();
        return (
            <StandardModal
                data-testid="model-dialog"
                isOpen={showModal}
                onClose={onCancel}
                className={isSmall ? mobileModalStyle : desktopModalStyle}
                ariaLabel={text('optionMenuAriaLabel')}
            >
                <ModalDialogBody mb={{ xs: '6', sm: '7' }}>
                    <Typography fontSize="x2large" fontWeight="bold">
                        {text('finishDeletionDialogMsg', {
                            finish: defaultPremiumFinish.toLowerCase(),
                        })}
                    </Typography>
                </ModalDialogBody>
                <ModalDialogFooter>
                    <ModalDialogButtons>
                        <Button
                            data-testid="model-dialog-confirm"
                            skin="primary"
                            width={isSmall ? 'full-width' : 'standard'}
                            size={isSmall ? 'mini' : 'standard'}
                            onClick={onDelete}
                            mr={{ xs: '2', sm: '5' }}
                            mb={{ xs: '3', sm: '2' }}
                            disabled={isInstantUploadProcessing}
                        >
                            {isInstantUploadProcessing && <Spinner size="tiny" accessibleText={text('loadingLabel')} />}
                            <Typography fontSize="small">{text('confirmFinishDeletion')}</Typography>
                        </Button>
                        <Button
                            data-testid="model-dialog-cancel"
                            skin="secondary"
                            width={isSmall ? 'full-width' : 'standard'}
                            size={isSmall ? 'mini' : 'standard'}
                            onClick={onCancel}
                        >
                            <Typography fontSize="small">{text('cancelFinishDeletion')}</Typography>
                        </Button>
                    </ModalDialogButtons>
                </ModalDialogFooter>
            </StandardModal>
        );
    }
);
