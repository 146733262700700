import React, { useState } from 'react';
import { css } from '@emotion/css';
import { Button, Checkbox, FlexBox, ModalDialogBody, tokens, Typography } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { StandardModal } from '../../../components/Modal/components/StandardModal';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import { LOCAL_STORAGE_KEY, localStorageUtility } from '../../../utils';
import { text } from '../../../utils/localization';
import { SESSION_STORAGE_KEY, setSessionStorageKey } from '../../../utils/sessionStorage';
import { useMailingSwitch, usePostCard, usePostCardMailingModal } from '../hooks';
import { postCardStore } from '../PostCardStore';

const modalStyle = css`
    .swan-modal-dialog-nav {
        position: absolute;
        background: transparent;
        right: 0;

        .swan-modal-dialog-close-button {
            background: transparent;
        }
    }
`;

const imgContainerStyle = css`
    img {
        width: 100%;
    }
`;

const getButtonContainerStyle = (isSmall: boolean) => css`
    margin-top: ${tokens.SwanSemSpace4};
    gap: ${tokens.SwanSemSpace4};
    ${isSmall &&
    `
        flex-direction: column-reverse;
    `};
`;

const checkboxContainer = css`
    flex-direction: row;
    align-items: center;
    margin: ${tokens.SwanSemSpace4} 0;
    gap: ${tokens.SwanSemSpace4};
`;

export const PostCardMailingModal = observer((): JSX.Element => {
    usePostCardMailingModal();
    const { postcardPricingDifference } = usePostCard();
    const { showPostCardMailingModal, setShowPostCardMailingModal } = postCardStore;
    const [isChecked, setIsChecked] = useState(false);
    const { switchMSProduct } = useMailingSwitch();
    const { isSmall } = useScreenLayout();
    const mailingImg = `${process.env.PUBLIC_URL}/images/mailing.png`;

    const disableModal = () => {
        const { addQuadFlags } = localStorageUtility;
        if (isChecked) {
            addQuadFlags(LOCAL_STORAGE_KEY.NOT_SHOW_MAILING_MODAL_CHECKED, 'true');
        }
    };

    const onAcceptMailingService = async () => {
        await switchMSProduct(true);
        onClose();
    };

    const onClose = () => {
        setSessionStorageKey(SESSION_STORAGE_KEY.MAILING_SERVICE_MODAL_SHOWED, true);
        disableModal();
        setShowPostCardMailingModal(false);
    };

    return (
        <>
            <StandardModal
                isOpen={showPostCardMailingModal}
                onClose={onClose}
                fullBleed={true}
                data-testid="postcard-mailing-modal"
                className={modalStyle}
            >
                <FlexBox flexDirection="column">
                    <FlexBox className={imgContainerStyle} justifyContent="center" mb="4">
                        <img src={mailingImg} alt={text('mailingPostCardModalImgAltText')} />
                    </FlexBox>

                    <ModalDialogBody padding={{ xs: '7', md: '7' }}>
                        <Typography component="h2" fontSize="x2large">
                            {text('mailingPostCardModalTitle')}
                        </Typography>
                        <FlexBox flexDirection="column">
                            <Typography my="3">{text('mailingPostCardModalInfo')}</Typography>
                            <FlexBox className={checkboxContainer}>
                                <Checkbox ml="0" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                                <Typography>{text('mailingPostCardModalCheckboxLabel')}</Typography>
                            </FlexBox>
                            <FlexBox className={getButtonContainerStyle(isSmall)}>
                                <Button size={isSmall ? 'mini' : 'standard'} skin="secondary" onClick={onClose}>
                                    {text('mailingPostCardModalNoButtonLabel')}
                                </Button>
                                <Button
                                    size={isSmall ? 'mini' : 'standard'}
                                    skin="primary"
                                    onClick={onAcceptMailingService}
                                >
                                    {text('mailingPostCardModalYesButtonLabel', { postcardPricingDifference })}
                                </Button>
                            </FlexBox>
                        </FlexBox>
                    </ModalDialogBody>
                </FlexBox>
            </StandardModal>
        </>
    );
});
