import { v4 as uuid } from 'uuid';

export enum PageSection {
    /** Use this for any interaction inside Upload Modal for any surface */
    AssetContainer = 'AssetContainer',
    /** Use this for any interaction inside Specs and templates panel */
    SpecAndTemplates = 'SpecAndTemplates',
    /** Use this for any interaction inside help panel */
    Help = 'Help',
    /** Use this for any interaction inside Preview and Proof modal */
    PreviewAndProof = 'PreviewAndProof',
    /** Use this for any interaction used to edit design */
    DesignEditToolbar = 'DesignEditToolbar',
    /** Use this for any interaction which do not fall under above listed sections */
    QuickUploadAndDesign = 'QuickUploadAndDesign',
    /** Use this for interaction on nudge to back modal */
    NudgeToBackModal = 'QuadNudgeModal',
    /** Use this for interaction for exit prompt open */
    BrowserExitPrompt = 'BrowserExitPrompt',
    /** Use this for interaction for low resolution image warning */
    LowResolutionImageWarning = 'LowResolutionImageWarning',
    /** Using this for Validation */
    Validations = 'Validation',
    /** Using this for save changes */
    SaveChanges = 'SaveChanges',
    /** Using this for my project*/
    MyProjects = 'MyProjects',
    /** Using this for specs and templates file formate  */
    SpecsAndTemplatesFileFormat = 'SpecsAndTemplatesFileFormat',
    /** Using this for change size */
    ChangeSize = 'ChangeSize',
    /** Using this for change product size footer button */
    ChangeProductSize = 'ChangeSize',
    /** Using this for preview zoom control */
    ZoomControl = 'ZoomControl',
    /** Using this three D preview  */
    ThreeDPreview = '[Preview_Type] Preview',
    /** Using this for keyboard delete button */
    KeyboardDeleteButton = 'KeyboardDeleteButton',
    /** Using this for finish button click */
    FinishButton = 'FinishButton',
    /** Update cart button redirection quad-cart */
    UpdateCartButton = 'UpdateCartButton',
    /** Using this for Teams */
    Teams = 'Teams',
    QUAD = 'QUAD Page',
    /** Using this for Change Area */
    ChangeArea = 'ChangeArea',
    /** Using this for Adjust Contrast */
    AdjustContrast = 'AdjustContrast',
    /** Using this for Adjust Contrast */
    MaterialColor = 'MaterialColor',
    /** Using this for interaction for between bound warning */
    BetweenBoundWarning = 'BetweenBoundWarning',
    /** Using this for mailing postcard */
    MailingPostcard = 'MailingPostcard',
    /** Using it for design review modal*/
    DesignReviewModal = 'DesignReviewModal',
    /** Using it for fly out/modal*/
    FlyOut = 'Fly-Out',
    QUADStudioReview = 'QUAD Studio:Review',
    /** Using it for smart backside */
    SmartBackside = 'SmartBackSide',
    DeleteConfirmationModal = 'DeleteConfirmationModal',
}

export enum QUAD_TRACKING_EVENTS {
    AUTO_OPEN_UPLOAD_MODAL = 'Auto open upload modal',
    CLICK_UPLOAD_BUTTON = 'Clicked on Upload ${PANEL_NAME}',
    CLICK_UPLOAD_BUTTON_DRAWER = 'Click recently uploaded assets for ${PANEL_NAME} panel',
    CLICK_HELP_HERE = 'Clicked on Help is here',

    CLICK_CREATE_MY_DESIGN = 'Create my Design',
    CLICK_EDIT_MY_DESIGN = 'Edit my Design',
    CLICK_PREVIEW = 'Clicked on Preview',
    CLICK_DOWNLOAD_PDF_PROOF = 'Clicked on download pdf proof',
    CLICK_APPROVE = 'Clicked on Approve',
    CLICK_DESIGN_BACK = 'Design the back',
    CLICK_PANEL_SWITCHER_DESIGN_BACK = 'Clicked on design back',
    CLICK_CONFIRM_GOTO_STUDIO = 'Clicked on confirm to goto studio',
    CLICK_TEAMS_BUTTON = 'Clicked on teams button',
    CLICK_NEXT_REVIEW = 'Clicked next to go to review page',
    STUDIO_PROJECT_SAVED = 'Studio project saved',
    /**
     * Events for quad header button.
     */
    CLICK_SPECS_AND_TEMPLATES = 'Clicked on specs and templates',
    CLICK_SAVE_CHANGES = 'Clicked on save changes',
    CLICK_MY_PROJECTS = 'Clicked on my projects',
    CLICK_SPECS_AND_TEMPLATES_FILE_FORMAT = 'Clicked on specs and templates file format',
    /**
     * Events for quad 3D preview in panel.
     */
    CLICK_ZOOM_CONTROL = 'Clicked on zoom control',
    CLICK_THREE_D_PREVIEW = 'Clicked on preview toggle',
    THREE_D_PREVIEW_VIEWED = '[Preview_Type] preview viewed',

    CLICK_MODAL_UPLOAD_BUTTON = 'Upload ${PANEL_NAME} button click in modal',
    CLICK_UPLOAD_CTA = 'Upload ${PANEL_NAME} CTA clicked',

    ERROR = 'Uncaught Error',

    UPLOAD_ASSETS_STARTED = 'Asset Upload Started',
    UPLOAD_ASSETS_COMPLETED = 'Completed uploading an asset',

    UPLOAD_MUlTIPLE_ASSET = 'Uploaded Multiple assets',

    CLICK_DELETE_ASSET = 'Clicked remove asset',

    TOTAL_TIME_TAKEN_UPLOAD_PROCESS = 'Total time taken for upload completion',

    PRODUCT_VIEW = 'QUAD Product View',
    PRODUCT_VIEWED_EVENT = 'Quick Upload and Design Page View',
    TRAVERSING_TO_PDC = 'User moved to PDC page',
    TOGGLE_VISIBLE_PANELS = 'Toggle to ${PANEL_NAME}',
    OPEN_NUDGE_TO_BACK = 'Open nudge to back modal',
    CLOSE_NUDGE_TO_BACK = 'Close nudge to back modal',
    ADD_BACK_DESIGN = 'Add back design',
    LEAVE_BACK_BLANK = 'Leave back design blank',
    CLICK_ON_REDIRECT = 'Clicked on ${redirect}',

    AUTOMAPPING = 'Triggered Automapping',
    REVERSE_AUTOMAPPING = 'Reversed Automapping',

    EXIT_PROMPT = 'Exit Prompt',
    LOW_RESOLUTION_IMAGE_WARNING = 'Low resolution image warning',
    ROTATION = 'Clicked on Rotate button',
    FIT_TO_BLEED = 'Clicked on Fit To Bleed',
    FIT_TO_SAFE = 'Clicked on Fit To Safe',
    CLICK_DELETE_DESIGN = 'Clicked on Delete Design',

    CLICK_KEYBOARD_DELETE = 'Clicked on keyboard delete',

    SHARPENED_IMAGE = 'Sharpened image',
    BETWEEN_BOUND_VALIDATION = 'Between bound validation',

    CHANGE_SIZE_MODAL_OPENED = 'Change Size Modal Opened',
    CHANGE_SIZE_MODAL_CLOSED = 'Change Size Modal Closed',
    CLICK_CHANGE_SIZE = 'Clicked on Change size',
    SELECTED_SIZE = 'Clicked on select size',
    AUTO_RECOGNITION_TRIGGERED = 'Auto Recognition Triggered',
    /** User comes from cart to quad to edit the design */
    UPDATE_CART_BUTTON_CLICK = 'UPDATE_CART_BUTTON_CLICK',
    CLICK_TEAMS_GET_STARTED = 'Clicked teams get started',
    CLICK_CHANGE_AREA = 'Clicked change area',
    CLICK_CHANGE_AREA_APPLY = 'Change area CTA clicked',
    CLICK_HEAD_PANEL_ADJUST_CONTRAST = 'click:headpanel:quickTool:adjustContrast:[CANVAS FACE]',
    ADJUST_CONTRAST_MODAL_OPENED = 'Adjust Contrast Modal Opened',
    CLICK_ADJUST_CONTRAST = 'Clicked on adjust contrast',
    CLICK_ADJUST_CONTRAST_INVERT_COLOR = 'Click adjust contrast invert color',
    ADJUST_CONTRAST_PICK_COLOR = 'Adjust contrast pick color',
    APPLY_ADJUST_CONTRAST = 'Apply adjust contrast',
    CLICK_CHANGE_MATERIAL_COLOR = 'Clicked on change material color',
    BETWEEN_BOUND_IDENTIFIED = 'Between bound interacted',
    BETWEEN_BOUND_ON_FINISH = 'Between bound validation on Finish',
    BETWEEN_BOUND_ON_STUDIO = 'Between bound validation on Studio redirect',
    CLICK_MAILING_POSTCARD_TOGGLE = 'Clicked on mailing postcard toggle',
    SAVED_FROM_CONTINUE_BUTTON = 'Continue Button',
    REVIEW = 'Review',

    // Smart backside
    SMART_BACKSIDE = 'Smart backside',
    BACKSIDE = 'Backside',
    NO_BACKSIDE = 'No backside',
    TEMPLATE = 'Template',
}

export enum ACTION_NAME {
    EXPERIMENT_VIEWED = 'Experiment Viewed',
    EXPERIMENT_CLICKED = 'Experiment Clicked',
    NAVIGATION_CLICKED = 'Navigation Clicked',
    DESIGN_TOOL_USED = 'Design Tool Used',
    PRODUCT_VIEWED = 'Product Viewed',
    FLY_OUT_VIEWED = 'Fly-Out Viewed',
    FLY_OUT_CLOSED = 'Fly-Out Closed',
    FLY_OUT_CLICKED = 'Fly-Out Clicked',
    STUDIO_PROJECT_SAVED = 'Studio Project Saved',
}

export const GA_CATEGORY = 'Studio';

/**
 * @description The stage of the page where the interaction happened
 *
 * Reference - {@link https://vistaprint.atlassian.net/wiki/spaces/GA/pages/53903414/Page+name+nomenclature+and+categorization#Site-Stage}
 */
export enum PageStage {
    Discover = 'Discover',
    Design = 'Design',
    MyAccount = 'My Account',
    CustomerCare = 'Customer Care',
    Confirmation = 'QUAD Confirmation',
    AssetUpload = 'Assets Upload',
    Review = 'QUAD Review',
    DesignReview = 'QUAD Design Review',
    Other = 'Other',
}

export const TRACKING_TENANT = 'vistaprint';

export const ANALYTICS_TENANT = 'vistaprint'; //until our pages are in promotique, we are hard-coding this to vistaprint

export const USER_SESSION_ID = uuid();
export const QUAD_UNIQUE_SESSION_ID = uuid();
export const QUAD_INSTANCE = uuid();

// Google Analytics
export const LABELS = {
    DEFAULT: 'General',
    CONTENT_TEXT_LINK: 'Content:Text Link',
};

export const GA = {
    PAGE_NAME: '[Product ID]:Studio',
    PAGE_SECTION: 'Studio',
    PAGE_STAGE: 'Design',
    PAGE_DEPT: 'QUAD Studio',
    NAVIGATION_CATEGORY: 'QUAD Studio',
    LABELS,
};

export const GA_PRODUCT = [
    'PRD-IYXT1T3V',
    'PRD-F2EJ5DIT',
    'PRD-QJIISQLA',
    'PRD-RWLCHPA0',
    'PRD-GURNTEDA',
    'PRD-LKKFLWV6',
    'PRD-4OVSFBSP',
    'PRD-15BJSMMI',
    'PRD-5SP4HOJQ',
    'PRD-XFC0VAKV',
    'PRD-YKA4PY7C',
    'PRD-KKS53PHP',
];

export const DEFAULT_PRODUCT_QUANTITY = 1;

export const STABLE_QUAD_VERSION = 4; // rolled out version used for SBC, Flyers NA region
